import React, {useEffect} from "react";

const LocateStatusOverviewView = ({ statusOverview, handleSelectStatusSelect, selectedStatus }: any) => {

    useEffect(() => {        
        if( (selectedStatus == "In Review" && statusOverview?.inReviewCount == 0) || (selectedStatus == "OnHold" && statusOverview?.onHoldCount == 0) || (selectedStatus == "Completed" && statusOverview?.completedCount == 0) || (selectedStatus == "Rejected" && statusOverview?.rejectedCount == 0) || (selectedStatus == "Lock" && statusOverview?.lockCount == 0) || (selectedStatus == "Unlock" && statusOverview?.unlockCount == 0))
            {
                handleSelectStatusSelect("");                
            }
    }, [selectedStatus,statusOverview])
    return (
        <div className="d-flex justify-content-xl-end justify-content-start">
            <div className="d-flex info-group table-width-icon-top">
                <div className={`count_up table-header-icon-box topblue-btn text-center locate-admin-btn ${selectedStatus === "In Review" && statusOverview?.inReviewCount > 0 ? "active" : null}`} data-tooltip-id="my-tooltip" data-tooltip-content={`${statusOverview?.inReviewCount || '0'} In Review`} onClick={() => statusOverview?.inReviewCount > 0 ? handleSelectStatusSelect("In Review") : null} >
                    <svg version="1.1" id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px"
                        viewBox="0 0 1080 1080"
                        width={16}
                        enable-background="new 0 0 1080 1080;"
                        xmlSpace="preserve">
                        <g>
                            <path fill='#4e4851' d="M1027.4,976.9C976.1,926,925,874.8,873.9,823.7c-2.7-2.7-5.3-5.6-7.8-8.3c62-93.2,37.2-209.6-43.6-270.7
                                                c-78.7-59.5-189-51.9-258.8,17.8c-69.9,69.7-77.7,179.9-18.4,258.8c60.3,80.4,177,106.7,273.1,42.7c2,2.8,3.9,6.5,6.6,9.3
                                                c37.7,37.9,75.4,75.6,113.2,113.4c14.4,14.4,28.5,29.1,43.3,43c19.1,18,50,10.2,57.3-14.3C1043.3,1000.4,1038.4,987.9,1027.4,976.9
                                                z M703.6,830.1c-70.4,0.1-127.9-56.9-128.2-127c-0.2-70.8,57.1-128.6,127.7-128.7c70.9,0,128.1,57.3,128,128.2
                                                C830.9,773.3,774.2,830,703.6,830.1z"/>
                            <path fill='#4e4851' d="M594.5,971c-5.3-1.1-10.8-1.2-16.2-1.2c-118.5-0.1-237,0.1-355.5-0.2c-12.2,0-24.9-0.9-36.6-4.1
                                                c-46.7-12.7-75.7-53.1-75.8-104.5c-0.1-150.5,0-300.9-0.1-451.4c0-3.6-0.1-7.3-0.4-10.9c0.4-51.4,0.9-102.8,1.3-154.2
                                                c0.3-31.6-0.2-72,21.5-97.7c20.2-23.9,55.5-37.3,86.2-37.3c91.9-0.2,183.8-0.1,275.7,0c3.5,0,6.9,0.4,11,0.6
                                                c0,36.2-0.6,71.4,0.1,106.6c1.3,55.6,24.2,101,67.9,135.4c32,25.2,69,36.4,109.5,36.5c33.4,0.1,66.8,0,100.4,0c0.7,2.3,1,2.9,1,3.6
                                                c0.1,17.1,0.1,34.1,0.2,51.2c0.1,22.3,14.4,37.7,34.8,37.8c20.5,0,34.8-15.3,34.9-37.6c0.1-28.3-0.5-56.7,0.2-85
                                                c0.4-14.3-4.8-25-14.6-34.7c-23.1-22.6-45.8-45.6-68.7-68.5c-67.1-67-134.2-134-201.1-201.2c-10.4-10.5-21.8-16.5-36.8-14.6
                                                c-4.3,0.5-8.7,0.2-13.1,0.2c-103.9,0.1-207.8-0.9-311.7,0.5C139.7,41,77,86.5,52.2,150.6c-16.8,43.3-10.5,89.9-10.7,135.1
                                                c0,12.9-1.6,154.6-1,154.6c0,0,0,0,0.1,0c-0.1,66.5,0,133,0,199.4c0,73.4-0.2,146.8,0.2,220.3c0.1,14.1,0.8,28.5,3.8,42.2
                                                c18,81.6,86.7,136.9,171,137.2c122.5,0.5,245,0.1,367.5,0.1c2.2,0,4.4,0,6.5-0.2c17.3-1.3,30.8-14.6,32-31.5
                                                C622.8,989.8,611.9,974.6,594.5,971z M575.7,162c0.9-0.7,1.8-1.4,2.7-2.1c51.4,52.3,102.8,104.5,154.5,157
                                                c-24.5,0-48.3,2.4-71.4-0.5c-45.7-5.8-82.5-47.2-85.5-93.9C574.6,202.4,575.7,182.1,575.7,162z"/>
                            <path fill='#4e4851' d="M444.3,272c-75.5-0.2-151-0.3-226.5,0.3c-8.2,0.1-17.3,3.2-24.2,7.7c-12.2,7.8-16.3,24.1-11.8,37.8
                                                c4.6,14.1,17.2,23.7,33,23.8c38.8,0.3,77.7,0.1,116.5,0.1c0,0,0,0,0,0c37.8,0,75.5,0.1,113.3,0c22.6-0.1,37.6-14.2,37.6-34.8
                                                C482,286.5,466.8,272,444.3,272z"/>
                            <path fill='#4e4851' d="M217.9,807.2c75.5,0.2,151,0.2,226.5-0.1c6.7,0,13.9-1.7,19.8-4.6c14.1-7,20.8-23.6,17-38.9c-3.6-14.6-16.7-25.7-32.5-25.8
                                                c-39.2-0.4-78.4-0.1-117.6-0.1c0,0,0-0.1,0-0.1c-37.8,0-75.5-0.1-113.3,0c-22.8,0.1-37.6,14-37.6,34.6
                                                C180.2,792.6,195.5,807.1,217.9,807.2z"/>
                            <path fill='#4e4851' d="M443.2,504.7c-74.8,0-149.6,0-224.4,0c-23.2,0-38.6,14.1-38.6,35c0.1,20.7,15.6,34.7,38.9,34.7c37.4,0.1,74.8,0,112.2,0
                                                c37.4,0,74.8,0.1,112.2,0c23.1,0,38.7-14.3,38.6-35C482,518.7,466.5,504.7,443.2,504.7z"/>
                        </g>
                    </svg>
                    <p className="count">{statusOverview?.inReviewCount > 0 ? statusOverview?.inReviewCount : 0}</p>
                </div>
                <div className={`count_up table-header-icon-box yellow-btn text-center locate-admin-btn ${selectedStatus === "OnHold" && statusOverview?.onHoldCount > 0 ? "active" : null}`} data-tooltip-id="my-tooltip" data-tooltip-content={`${statusOverview?.onHoldCount || '0'} On Hold`} onClick={() => statusOverview?.onHoldCount > 0 ? handleSelectStatusSelect("OnHold") : null}>
                    <i className="fa-solid fa-hand"></i>
                    <p className="count">{statusOverview?.onHoldCount > 0 ? statusOverview?.onHoldCount : 0}</p>
                </div>
                <div className={`count_up table-header-icon-box green-btn text-center locate-admin-btn ${selectedStatus === "Completed" && statusOverview?.completedCount > 0 ? "active" : null}`} data-tooltip-id="my-tooltip" data-tooltip-content={`${statusOverview?.completedCount || '0'} Approved`} onClick={() => statusOverview?.completedCount > 0 ? handleSelectStatusSelect("Completed") : null}>
                    <i className="fa-solid fa-square-check"></i>
                    <p className="count">{statusOverview?.completedCount > 0 ? statusOverview?.completedCount : 0}</p>
                </div>
                <div className={`count_up table-header-icon-box red-btn text-center locate-admin-btn ${selectedStatus === "Rejected" && statusOverview?.rejectedCount > 0 ? "active" : null}`} data-tooltip-id="my-tooltip" data-tooltip-content={`${statusOverview?.rejectedCount || '0'} Rejected`} onClick={() => statusOverview?.rejectedCount > 0 ? handleSelectStatusSelect("Rejected") : null} >
                    <i className="fa-solid fa-rectangle-xmark"></i>
                    <p className="count">{statusOverview?.rejectedCount > 0 ? statusOverview?.rejectedCount : 0}</p>
                </div>
                {/* <div className="count_up table-header-icon-box blue-btn text-center" data-tooltip-id="my-tooltip" data-tooltip-content="View">
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                    <p className="count">15</p>
                                </div> */}
                <div className={`count_up table-header-icon-box red-btn text-center locate-admin-btn ${selectedStatus === "Lock" && statusOverview?.lockCount > 0 ? "active" : null}`} data-tooltip-id="my-tooltip" data-tooltip-content={`${statusOverview?.lockCount || '0'} Locked`} onClick={() => statusOverview?.lockCount > 0 ? handleSelectStatusSelect("Lock") : null}>
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    <p className="count">{statusOverview?.lockCount > 0 ? statusOverview?.lockCount : 0}</p>
                </div>
                <div className={`count_up table-header-icon-box green-btn text-center locate-admin-btn ${selectedStatus === "Unlock" && statusOverview?.unlockCount > 0 ? "active" : null}`} data-tooltip-id="my-tooltip" data-tooltip-content={`${statusOverview?.unlockCount || '0'} Unlocked`} onClick={() => statusOverview?.unlockCount > 0 ? handleSelectStatusSelect("Unlock") : null}>
                    <i className="fa fa-unlock" aria-hidden="true"></i>
                    <p className="count">{statusOverview?.unlockCount > 0 ? statusOverview?.unlockCount : 0}</p>
                </div>
            </div>
        </div>
    )
}

export default LocateStatusOverviewView