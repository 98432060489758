import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import EditPencil from "../../../../assets/images/edit.svg";
import ClientPortal from "../../../../components/ClientPortal";
import { getOnboardingQuestionAndAnswers, saveOnboardingQuestionAndAnswers } from "../../../../service/onboarding-service";
import warning from '../../../../assets/images/warning.png';
import swal from 'sweetalert';
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { Modules, ModulesAccessRights } from "../../../../utils/constant";


const OnboardingPreviewQAView = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [PreviewshowPortal9, PreviewsetShowPortal9] = useState(false);
    const [questions, setQuestions] = useState<any>([]);
    const [openAccordian, setAccordian] = useState<number | null>(null);
    const [openPreviewAccordian, setPreviewAccordian] = useState<number | null>(null);
    const {serviceId, serviceName} = useSelector((state: RootState) => state.onboardingService);
    const {isForPublished } = useSelector((state: RootState) => state.onboardingIsForPublished);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);

    const [showEditPage, setShowEditPage] = useState<any>(DataLogin?.userModel?.modules?.find((item:any)=>(item.moduleName === 'OnBoarding')))

    // const [isValid, setValid] = useState<boolean>(false)
    const [fetchUpdatedData, setFetchUpdatedData] = useState(false);
    const isMounted = useRef(false);

    const PreviewhandleModal9 = (event: any) => {
        event.preventDefault();
        PreviewsetShowPortal9((prevState) => !prevState);
    };
    useEffect(() => {
        if (PreviewshowPortal9) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [PreviewshowPortal9]);

    useImperativeHandle(ref, () => ({
        isValidated: true,
        backActionHandler: () => {
            // saveData();
        },
        nextActionHandler: () => {
            saveData();
        },
        triggerValidation: async () => {

        }
    }));

    // useEffect(()=>{
    //     setValid(questions.some((item: any) => item?.isChecked === true));
    // },[questions])

    const handleCheckboxChange = (index: any) => {
        const updatedQuestions = [...questions];
        if (updatedQuestions[index].answerText.trim() !== "") {
            updatedQuestions[index].isChecked = !updatedQuestions[index].isChecked;
            updatedQuestions[index].userModifiedChecked = true;
            setQuestions(updatedQuestions);
            // setValid(true)
        } else {
            // setValid(false)
        }
    };

    const handleTextareaChange = (index: any, event: any) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].answerText = event.target.value;
        if (event.target.value.trim().length === 0) {
            updatedQuestions[index].isChecked = false;
            updatedQuestions[index].userModifiedChecked = false
            // setValid(false);
        } else {
            if (!updatedQuestions[index].userModifiedChecked) {
                updatedQuestions[index].isChecked = true;
                // setValid(true);
            }
        }
        setQuestions(updatedQuestions);
    };    
    const toggleAccordion = (index: number) => {
        setAccordian(openAccordian === index ? null : index);
    };

    const togglePreviewAccordion = (index: number) => {
        setPreviewAccordian(openPreviewAccordian === index ? null : index);
    };



    const saveData = async () => {
        const payload = {
            serviceId: serviceId!,
            answers: questions.map(({ userModifiedChecked, ...rest }: any) => rest)
        };
       const res=  await saveOnboardingQuestionAndAnswers(payload);
        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            }).then(async (confirm: any) => {
                PreviewsetShowPortal9(false)    
            });
           
        } else {
            swal(res.message, {
                icon: 'error'
            }).then(async (confirm: any) => {
               
                
            });;
        }
        getData();
    }
    const submitalert = () => {
        swal({
            title: '',
            text: `Do you want to save the changes done to the Q&A section?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                saveData();
            } else {
            }
        });
    }
    useEffect(() => {
        if (!isMounted.current) {
            getData(); 
            isMounted.current = true;
        }
    }, []);

    useEffect(() => {
        if (fetchUpdatedData) {
            getData();
        }
    }, [fetchUpdatedData]);

    const getData = async () => {
        try {
            const response = await getOnboardingQuestionAndAnswers(serviceId!);
    
            if (response.status === true) {
                const questionsData = response?.data?.map((item: any) => ({
                    questionText: item?.questionText,
                    answerText: item?.answerText !== null ? item?.answerText : "",
                    isChecked: item?.isChecked,
                    questionId: item?.questionId,
                    answerId: item?.answerId,
                    userModifiedChecked: false
                }));
    
                setQuestions((prevQuestions:any) => {
                    const isDifferent = JSON.stringify(prevQuestions) !== JSON.stringify(questionsData);
                    return isDifferent ? questionsData : prevQuestions;
                });
    
                // const validStatus = response?.data?.some((item: any) => item?.isChecked === true);
                // setValid((prevValid) => (prevValid !== validStatus ? validStatus : prevValid));
    
                if (!fetchUpdatedData) {
                    setFetchUpdatedData(true);
                }
            } else {
            }
        } catch (error) {
        }
    };

    return (
        <div>
            <div className="service-detail-padding section-padding qa-accordion" id="question-answer-section">
                <div className="container-fluid container-fluid-service p-0">
                    <h4 className="text-center section-title mb-3 bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                        {t("QandA.Question_answer_description")}</h4>
                    <div className="d-flex align-items-start justify-content-center preview-small-title">
                        <p className="section-sub-title text-center mb-0 bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                            {t("QandA.Question_answer")}
                        </p>
                         {isForPublished ? 
                        showEditPage?.userAccess?.some((a: any) => a.accessCode === ModulesAccessRights.Onboarding.QaEditAccess.accessCode) ?
                        <div className="edit-icon-preview ms-2 cursor-pointer" onClick={PreviewhandleModal9} data-aos="fade-up" data-aos-duration="1500" data-aos-once="true"><img src={EditPencil} className="img-fluid" alt="edit" width={20} /></div>
                        :
                        <></>
                        :
                        <div className="edit-icon-preview ms-2 cursor-pointer" onClick={PreviewhandleModal9} data-aos="fade-up" data-aos-duration="1500" data-aos-once="true"><img src={EditPencil} className="img-fluid" alt="edit" width={20} /></div>}
                    </div>
                </div>
                <div className="container-fluid p-0">
                    <div className="question-answer-accordion mt-4 mb-4">
                        <div className="accordion qa-accordion" id="accordionExample">
                            {questions && questions?.length > 0 && questions?.map((item: any, index: any) => {
                                if(item?.isChecked){
                                    return (
                                        <div key={index} className="accordion-item d-flex">
                                            <div className="w-100">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button
                                                        type="button"
                                                        data-bs-toggle="collapsePreview"
                                                        data-bs-target="#collapseOne"
                                                        className={`accordion-button ${openPreviewAccordian === index ? '' : 'collapsed'}`}
                                                        onClick={() => togglePreviewAccordion(index)}
                                                        aria-expanded={openPreviewAccordian === index}
                                                        aria-controls={`collapsePreview-${index}`}
                                                    >
                                                        {item?.questionText}
                                                    </button>
                                                </h2>
    
                                                <div
                                                    data-bs-parent="#accordionExample" id={`collapse-${index}`}
                                                    className={`accordion-collapse collapse ${openPreviewAccordian === index ? 'show' : ''}`}
                                                    aria-labelledby={`heading-${index}`}>
                                                    <div className="accordion-body">{item?.answerText}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <ClientPortal selector="myportal" show={PreviewshowPortal9}>
                <div className="overlay" onClick={PreviewhandleModal9}></div>
                <div className="modal preview-modal nhs-body">
                    <div className="modal-content">
                        <div className="modal-header modal-header--sticky d-flex align-items-center justify-content-between border-bottom">
                            <h5 className="mb-0">Edit Questions & Answers</h5>
                            <a onClick={PreviewhandleModal9} className="removeButton">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="1em"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                        fill="#ffffff"
                                        d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                    />
                                </svg>
                            </a>
                        </div>
                        <div className="modal-body preview-modal-body float-button-sec-margin">
                            <div className="mt-3">
                                <div className="question-answer-accordion mt-4 mb-4">
                                    <div className="accordion qa-accordion" id="accordionExample2">
                                        {questions && questions?.length > 0 && questions?.map((item: any, index: any) => {
                                            return (
                                                <div key={index} className="accordion-item d-flex">
                                                    <div className="me-3 selection-box neumorphic-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="question-select form-check-input"
                                                            checked={item.isChecked}
                                                            onChange={() => handleCheckboxChange(index)}
                                                        />                                                </div>
                                                    <div className="w-100">
                                                        <h6 className="accordion-header" id="headingnine">
                                                            <button
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#collapseOne"
                                                                className={`accordion-button ${openAccordian === index ? '' : 'collapsed'}`}
                                                                onClick={() => toggleAccordion(index)}
                                                                aria-expanded={openAccordian === index}
                                                                aria-controls={`collapse-${index}`}
                                                            >
                                                                {item?.questionText}
                                                            </button>
                                                        </h6>
                                                        <div
                                                            id={`collapse-${index}`}
                                                            className={`accordion-collapse collapse ${openAccordian === index ? 'show' : ''}`}
                                                            aria-labelledby={`heading-${index}`}
                                                        >
                                                            <div className="accordion-body">
                                                                <div className="neumorphic-fieldset">
                                                                    <textarea
                                                                        className="form-control answer-textarea"
                                                                        value={item.answerText}
                                                                        onChange={(event) => handleTextareaChange(index, event)}
                                                                        placeholder={`${t('OnboardingQuestionAndAnswer.Answer_Textarea_Placeholder')}`}
                                                                    />                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer modal-footer--sticky d-flex justify-content-center align-items-center">
                            <div className="d-flex justify-content-center align-items-center"><button type="submit" onClick={submitalert} className="btn primary-btn mt-1 px-5">Save</button></div>
                        </div>
                    </div>
                </div>
            </ClientPortal>
        </div>
    );
});

export default OnboardingPreviewQAView;