import React, { useEffect, useMemo, useState, useRef, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import {
    MaterialReactTable,
    MRT_TableInstance,
    MRT_FullScreenToggleButton,
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_TablePagination,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToolbarAlertBanner,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_VisibilityState,
    type MRT_RowSelectionState,
    type MRT_GroupingState,
    MRT_Localization
} from 'material-react-table';
import { Box } from '@mui/material';
import PaginationContainer from '../../../components/PaginationComponent/indexContainer';
import SmartSearchDropdownContainer from '../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { Tooltip } from 'react-tooltip';
import ExportIntegrationContainer from '../../IntegrationInterface/IntegrationBottom/ExportIntegration/ExportIntegrationContainer';
import RenderModalContainer from '../../../components/RenderModal/indexContainer';
import ExportReportsModalContainer from '../../../components/ExportReportsModal/ExportReportsModalContainer';
import ExportReportsContainer from '../ExportReports/ExportReportsContainer';

import ClientPortal from '../../../components/ClientPortal';

export enum Source {
    Integration = 'integration',
    Admission = 'admission',
    Referral = 'referral'
}

const BedTransactionHistoryReportsView = (props: any) => {
    const { t } = useTranslation();
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'Organization',
                header: 'Organization',
                size: 100,
                Cell: ({ cell }) => (
                    <a href="#" className="strong-font primary-color" onClick={handleSecondModal}>
                        {cell.getValue()}
                    </a>
                )
            },
            {
                accessorKey: 'ServiceType',
                header: 'Service Type',
                size: 100
            },
            {
                accessorKey: 'ServiceName',
                header: 'Service Name',
                size: 100
            },
            {
                accessorKey: 'WardName',
                header: 'Ward Name',
                size: 100
            },
            {
                accessorKey: 'BedName',
                header: 'Bed Name',
                size: 100
            },
            {
                accessorKey: 'BedStatus',
                header: 'Bed Status',
                size: 100
            },
            {
                accessorKey: 'TotalWeeklyFee',
                header: 'Total Weekly Fee',
                size: 100
            },
            {
                accessorKey: 'Change',
                header: '% Change',
                size: 100,
                Cell: ({ cell }) => <span className="bolder table-green-color">{cell.getValue()}</span>
            }
        ],
        []
    );
    const Data = [
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        },
        {
            Organization: 'Health Care',
            ServiceType: 'Nursing Care',
            ServiceName: 'Ringe Wood',
            WardName: 'Ward 3',
            BedName: '0001',
            BedStatus: 'Occupied',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Alen Hall',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Alen Hall',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Occupied',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Alen Hall',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Alen Hall',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Alen Hall',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Alen Hall',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Alen Hall',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Alen Hall',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        }
    ];
    let [showModel, setShowModel] = useState<boolean>(false);
    const handleClickExport = () => {
        setShowModel(true);
    };
    const [showSecondPortal, setShowSecondPortal] = useState(false);
    useEffect(() => {
        if (showSecondPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showSecondPortal]);
    const handleSecondModal = (event: any) => {
        event.preventDefault();
        setShowSecondPortal((prevState) => !prevState);
    };

    const [showExportPortal, setShowExportPortal] = useState(false);
    useEffect(() => {
        if (showExportPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showExportPortal]);
    const handleExportModal = (event: any) => {
        event.preventDefault();
        setShowExportPortal((prevState) => !prevState);
    };

    const columnsModal = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'ServiceType',
                header: 'Service Type',
                size: 100
            },
            {
                accessorKey: 'ServiceName',
                header: 'Service Name',
                size: 100
            },
            {
                accessorKey: 'WardName',
                header: 'Ward Name',
                size: 100
            },
            {
                accessorKey: 'BedName',
                header: 'Bed Name',
                size: 100
            },
            {
                accessorKey: 'BedStatus',
                header: 'Bed Status',
                size: 100
            },
            {
                accessorKey: 'TotalWeeklyFee',
                header: 'Total Weekly Fee',
                size: 100
            },
            {
                accessorKey: 'Change',
                header: '% Change',
                size: 100,
                Cell: ({ cell }) => <span className="bolder table-green-color">{cell.getValue()}</span>
            }
        ],
        []
    );
    const DataModal = [
        {
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        },
        {
            Organization: 'Health Care',
            ServiceType: 'Nursing Care',
            ServiceName: 'Ringe Wood',
            WardName: 'Ward 3',
            BedName: '0001',
            BedStatus: 'Occupied',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        },
        {
            ServiceType: 'Hospital',
            ServiceName: 'Alen Hall',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        },
        {
            ServiceType: 'Hospital',
            ServiceName: 'Alen Hall',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Occupied',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        },
        {
            ServiceType: 'Hospital',
            ServiceName: 'Alen Hall',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100',
            Change: '100'
        }
    ];

    return (
        <>
            <div className="container-fluid">
                <div className="row p-0">
                    <div className="col-xl-12 col-lg-12 mb-4">
                        <div className="box-shadow p-0">
                            <div>
                                <div className="administrator-header ">
                                    <div className="row align-items-center justify-content-between h-100">
                                        <div className="col-md-6">
                                            <p className="bold lead-text purple-text">Bed Transaction History Report</p>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <a href="/reports">
                                                <button className="btn btn-white btn-sm">
                                                    <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="administrator-content">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="d-flex mb-3 flex-column">
                                            <label className="bold mb-2">Bed Status*</label>
                                            <div className="auto-complete-dropdown auto-complete-dropdown-cus mb-0">
                                                <SmartSearchDropdownContainer
                                                    smartSearchLabel={'Select Bed Status'}
                                                    defaultValue={'Helllo'}
                                                    onChangeSmartSearch={(value: any) => {
                                                        const event = new Event('change');
                                                        let obj = {
                                                            name: 'messageCode',
                                                            value: value?.id
                                                        };
                                                    }}
                                                    smartSearch={'Service'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="d-flex mb-3 flex-column">
                                            <label className="bold mb-2">Services*</label>
                                            <div className="auto-complete-dropdown auto-complete-dropdown-cus mb-0">
                                                <SmartSearchDropdownContainer
                                                    smartSearchLabel={'Select Services'}
                                                    defaultValue={'Helllo'}
                                                    onChangeSmartSearch={(value: any) => {
                                                        const event = new Event('change');
                                                        let obj = {
                                                            name: 'messageCode',
                                                            value: value?.id
                                                        };
                                                    }}
                                                    smartSearch={'Service'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="d-flex mb-3 flex-column">
                                            <label className="bold mb-2">Ward Name</label>
                                            <select className="form-control white">
                                                <option>Select Ward Name</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="d-flex mb-3 flex-column">
                                            <label className="bold mb-2">Bed Name</label>
                                            <select className="form-control white">
                                                <option>Select Bed Name</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="d-flex mb-3 flex-column">
                                            <label className="bold mb-2">Date Range*</label>
                                            <input type="text" className="form-control white " placeholder="Select Date Range" />
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-center">
                                        <button className="btn btn-secondary me-2">Clear</button>
                                        <button className="btn btn-primary me-2">Generate Report</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12 col-lg-12">
                        <div className="box-shadow p-0">
                            <div className="pt-3">
                                <div className="row pe-2 ps-2 d-flex justify-content-end">
                                    <div className="col-md-4 mb-3 d-flex justify-content-end">
                                        <div className="dropdown filter-dropdown me-2">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="">
                                                    <i className="fa fa-cog"></i> {t('TableManageColumns.Manage_Columns')}
                                                </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-columns report-dropdownmenu" aria-labelledby="navbarDropdownMenuLink">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Status Set On
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Length of Status
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Status Set By
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Est. Discharge Date
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Est. Date of Admission
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Est. Release Date
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="d-flex justify-content-end">
                                                            <label className="purple-text bold">
                                                                <i className="fa fa-undo me-1" aria-hidden="true"></i> Reset
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ExportReportsContainer onExportClick={handleClickExport} />
                                    </div>
                                </div>
                                <div className="invoice-table reports-table">
                                    <MaterialReactTable
                                        columns={columns}
                                        data={Data}
                                        enableGrouping
                                        enableColumnActions={false}
                                        enableBottomToolbar={false}
                                        enableTopToolbar={false}
                                        enableColumnDragging={false}
                                        manualPagination
                                        manualSorting
                                    />
                                </div>
                                <div className="administrator-content">
                                    <div className="row align-items-center mt-3 invoice-table-pagination">
                                        <div className="col-lg-6">
                                            <div className="d-flex align-items-center">
                                                <p className="purple-text bold">Displaying 1 - 2 of 10</p>
                                                <div className="d-flex align-items-center ms-3">
                                                    <label className="mb-0 bold nowrap me-2">Page Size:</label>
                                                    <select className={`form-control white page-select`} id="supportedPersoIntegrationPageSize">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 ">
                                            <PaginationContainer currentPage={1} totalCount={100} pageSize={10} onPageChange={(page: any) => {}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    <RenderModalContainer>
                        {showModel && <ExportReportsModalContainer title="Export Report Details" onClose={() => setShowModel(false)}></ExportReportsModalContainer>}
                    </RenderModalContainer>
                }

                <ClientPortal selector="myportal" show={showSecondPortal}>
                    <div className="overlay" onClick={handleSecondModal}></div>
                    <div className="modal userAdminModal p-0">
                        <div className="header admin-modal-header">
                            <h4 className="mb-0">Robert Little - 8989895867</h4>
                            <a onClick={handleSecondModal} className="removeButton">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                    <path
                                        fill="#ffffff"
                                        d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                    />
                                </svg>
                            </a>
                        </div>
                        <div className="body">
                            <div className="d-flex justify-content-between admin-modal-body">
                                <div className="d-flex">
                                    <div className="me-5">
                                        <table className="user-details-table">
                                            <tr>
                                                <td className="strong-font">Organization</td>
                                                <td>Valorum Care</td>
                                            </tr>
                                            <tr>
                                                <td className="strong-font">Service Type</td>
                                                <td>Hospital</td>
                                            </tr>
                                            <tr>
                                                <td className="strong-font">Service Name</td>
                                                <td>Magna House</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div>
                                        <table className="user-details-table">
                                            <tr>
                                                <td className="strong-font">Ward Name</td>
                                                <td>Ward 1</td>
                                            </tr>
                                            <tr>
                                                <td className="strong-font">Bed Name</td>
                                                <td>001</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div className="">
                                    <div>
                                        <a href="#" className="btn btn-white bold " data-tooltip-id="my-tooltip-export" data-tooltip-content="Export Report Details" onClick={handleExportModal}>
                                            <i className="fa fa-file-export"></i> Export
                                        </a>
                                        <Tooltip id="my-tooltip-export" className="my-tooltip" />
                                    </div>
                                </div>
                            </div>

                            <div className="admin-info-table">
                                <div className="box-shadow p-0">
                                    <div className="pt-3">
                                        <div className="row pe-2 ps-2 d-flex justify-content-end">
                                            <div className="col-md-4 mb-3 d-flex justify-content-end">
                                                <div className="dropdown filter-dropdown me-2">
                                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <div className="">
                                                            <i className="fa fa-cog"></i> {t('TableManageColumns.Manage_Columns')}
                                                        </div>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-columns report-dropdownmenu" aria-labelledby="navbarDropdownMenuLink">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div>
                                                                    <label>
                                                                        <input type="checkbox" name="" className="me-1" /> Status Set On
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <label>
                                                                        <input type="checkbox" name="" className="me-1" /> Length of Status
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <label>
                                                                        <input type="checkbox" name="" className="me-1" /> Status Set By
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div>
                                                                    <label>
                                                                        <input type="checkbox" name="" className="me-1" /> Est. Discharge Date
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <label>
                                                                        <input type="checkbox" name="" className="me-1" /> Est. Date of Admission
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <label>
                                                                        <input type="checkbox" name="" className="me-1" /> Est. Release Date
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="invoice-table reports-table">
                                            <MaterialReactTable
                                                columns={columnsModal}
                                                data={DataModal}
                                                enableGrouping
                                                enableColumnActions={false}
                                                enableBottomToolbar={false}
                                                enableTopToolbar={false}
                                                enableColumnDragging={false}
                                                manualPagination
                                                manualSorting
                                            />
                                        </div>
                                        <div className="administrator-content">
                                            <div className="row align-items-center mt-3 invoice-table-pagination">
                                                <div className="col-lg-6">
                                                    <div className="d-flex align-items-center">
                                                        <p className="purple-text bold">Displaying 1 - 2 of 10</p>
                                                        <div className="d-flex align-items-center ms-3">
                                                            <label className="mb-0 bold nowrap me-2">Page Size:</label>
                                                            <select className={`form-control white page-select`} id="supportedPersoIntegrationPageSize">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 ">
                                                    <PaginationContainer currentPage={1} totalCount={100} pageSize={10} onPageChange={(page: any) => {}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ClientPortal>

                <ClientPortal selector="myportal" show={showExportPortal}>
                    <div className="overlay" onClick={handleExportModal}></div>
                    <div className="modal modal-extrasmall">
                        <div className="modal-content">
                            <div className="modal-header">
                                <p className="modal-title">Excel Export</p>
                                <a onClick={handleExportModal} className="removeButton">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                        <path
                                            fill="#ffffff"
                                            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                        />
                                    </svg>
                                </a>
                            </div>

                            <div className="modal-body">
                                <div className=" mb-2">
                                    <h3 className="mb-2 report-modal-heading">Select Export Options</h3>
                                    <div className="row">
                                        <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                                            <input type="radio" className="me-2" defaultChecked />
                                            <label>Export Selected Columns</label>
                                        </div>
                                        <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                                            <input type="radio" className="me-2" />
                                            <label>Export All Columns</label>
                                        </div>
                                        <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                                            <input type="radio" className="me-2" />
                                            <label>Export CSV</label>
                                        </div>
                                        <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                                            <input type="radio" className="me-2" />
                                            <label>Excel for all reports</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer justify-content-center">
                                <div className="d-flex align-items-center ms-2">
                                    <button className="btn primary-btn me-2">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ClientPortal>
            </div>
        </>
    );
};
export default BedTransactionHistoryReportsView;
