    import React from 'react';
import CreateFunderView from './CreateFunderView';
    
    const CreateFunderContainer = () => {
        return (
            <div>
                <CreateFunderView/>
            </div>
        );
    };
    
    export default CreateFunderContainer;