import React from 'react';
import ProductManagementView from './ProductManagementView';

const ProductManagementContainer = () => {
    return (
        <div>
            <ProductManagementView/>
        </div>
    );
};

export default ProductManagementContainer;