import React from 'react';
import * as Yup from 'yup';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import SmartSearchDropdownContainer from '../../../../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { API_ENDPOINT, BASEURLDEV } from '../../../../../../service/ApiConstants';

const validationSchema = Yup.object().shape({
    referralSourceDescription: Yup.string().required(),
    referralSourceId: Yup.string().trim().strict(true).nonNullable().required()
});

const ContactDetailsView = (props: any) => {
    const { t } = useTranslation();
    const { register, control, handleSubmit, reset, getValues, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const [refSourceId, setrefSourceId] = useState<any>('');
    const { ShowClass, FormName, Data } = useSelector((state: RootState) => state.navigation);
    let [contactsSmartSearch, setContactSmartSearch] = useState<any>(undefined);

    let [defaultcontact, setdefaultcontact] = useState<any>(undefined);

    const { DataLeadInfo } = useSelector((state: RootState) => state.leadInformation);

    const isLeadCreatedByNHS = DataLeadInfo?.data?.isLeadCreatedByNhs === 1

    useEffect(() => {
        if (formState.isValid) {
            props.iscontacterror(true);
        } else {
            props.iscontacterror(false);
        }
    }, [formState]);

    useEffect(() => {
        if (props.leaddata?.data?.referralContactDetailModel !== undefined) {
            setValue(
                'referralSourceId',
                props?.leaddata?.data?.referralContactDetailModel?.referralSourceId == null ? '' : props.leaddata?.data?.referralContactDetailModel?.referralSourceId?.toString(),
                { shouldValidate: true }
            );
            setrefSourceId(props?.leaddata?.data?.referralContactDetailModel?.referralSourceId == null ? '' : props.leaddata?.data?.referralContactDetailModel?.referralSourceId?.toString());
            setValue('referralSourceDescription', props.leaddata?.data?.referralContactDetailModel?.referralSourceDescription, { shouldValidate: true });
            let objContactSmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetContactsList}`,
                extraPayload: {
                    defaultReferralResourceId: props?.leaddata?.data?.referralContactDetailModel?.referralSourceId
                }
            };
            setContactSmartSearch(objContactSmartSearch);
            setdefaultcontact(props?.leaddata?.data?.referralContactDetailModel?.referralSourceId);
        }
    }, [props.leaddata]);

    useEffect(() => {
        if (props.clearcontact !== undefined) {
            setdefaultcontact(undefined);
        }
    }, [props.clearcontact]);

    useEffect(() => {
        setValue('referralSourceId', '');
        let objContactSmartSearch = {
            url: `${BASEURLDEV}${API_ENDPOINT.GetContactsList}`
        };
        setContactSmartSearch(objContactSmartSearch);
    }, []);

    return (
        <div>
            {contactsSmartSearch !== undefined && (
                <div className="auto-complete-dropdown mb-2">
                    <SmartSearchDropdownContainer
                        smartSearchLabel={t('ReferralLead.Contact_Referral')}
                        disabled={isLeadCreatedByNHS && defaultcontact !== null}
                        defaultValue={defaultcontact}
                        errors={errors.kRelationship}
                        onChangeSmartSearch={(value: any) => {
                            const event = new Event('change');
                            let obj = {
                                name: 'referralSourceId',
                                value: value?.id?.toString()
                            };
                            //@ts-ignore
                            Object.defineProperty(event, 'target', { writable: false, value: obj });
                            setValue('referralSourceId', value?.id?.toString(), { shouldValidate: true });
                            if (value !== '') {
                                props.updateData(event);
                            } else {
                                setdefaultcontact(undefined);
                                let objContactSmartSearch = {
                                    url: `${BASEURLDEV}${API_ENDPOINT.GetContactsList}`
                                };
                                setContactSmartSearch(objContactSmartSearch);
                            }
                        }}
                        smartSearch={contactsSmartSearch}
                    />
                </div>
            )}
            <textarea
                readOnly={props.isReadOnly || (Data?.fleadstatus === 1 && DataLeadInfo === '') || DataLeadInfo?.data?.leadEditable === true ? false : true} rows={4} placeholder={`${t('ReferralLead.Contact_Referral_Desc')}`}
                {...register('referralSourceDescription', {
                    onChange: (e: any) => {
                        props.updateData(e);
                    }
                })}
                disabled={isLeadCreatedByNHS && getValues("referralSourceDescription") !== null}
                name={'referralSourceDescription' as const}
                className={`form-control white ${errors.referralSourceDescription ? 'is-invalid' : ''}`}></textarea>
        </div>
    );
};

export default ContactDetailsView;
