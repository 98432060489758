import React, { useMemo } from 'react';
import SmartSearchDropdownContainer from '../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {
    MaterialReactTable,
    MRT_TableInstance,
    MRT_FullScreenToggleButton,
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_TablePagination,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToolbarAlertBanner,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_VisibilityState,
    type MRT_RowSelectionState,
    type MRT_GroupingState,
    MRT_Localization
} from 'material-react-table';
import { Box } from '@mui/material';
import PaginationContainer from '../../../components/PaginationComponent/indexContainer';

const CreateServiceCardsView = () => {
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'title', //access nested data with dot notation
                header: 'Title',
                size: 50
            },
            {
                accessorKey: 'subservicedesc',
                header: 'Sub-Service Description',
                size: 180
            },
            {
                accessorKey: 'facesheet', //normal accessorKey
                header: 'Facesheet',
                size: 50,
                Cell: ({ cell }) => (
                    <div>
                        <a href="#">View</a>/<a href="#">Add</a>
                    </div>
                )
            },
            {
                accessorKey: 'version', //normal accessorKey
                header: 'Version',
                size: 50
            }
        ],
        []
    );
    const Data = [
        {
            id: 1,
            title: 'Bipolar 1',
            subservicedesc: 'ABC',
            version: 'V1'
        },
        {
            id: 2,
            title: 'Bipolar 2',
            subservicedesc: 'ABC',
            version: 'V2'
        }
    ];
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="middle_content" id="middle_content">
                        <div className="row mb-2">
                            <div className="col-6">
                                <a href="/servicecards">
                                    <button className="btn btn-white  btn-sm me-2 ">
                                        <i className="fa fa-chevron-left"></i> Back
                                    </button>
                                </a>
                            </div>
                            <div className="col-6 text-right"></div>
                        </div>
                    </div>
                    <div className="box-shadow p-0">
                        <div>
                            <div className="box-header">
                                <div className="row align-items-center h-100">
                                    <div className="col-md-4 flex-order-2 py-2">
                                        <div className=" ">
                                            <button className="btn btn-sm btn-secondary ms-2">Clear</button>
                                            <button className="btn btn-sm btn-primary ms-2">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="administrator-content">
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Region</label>
                                        <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                            <SmartSearchDropdownContainer
                                                smartSearchLabel={'Region'}
                                                defaultValue={'Helllo'}
                                                onChangeSmartSearch={(value: any) => {
                                                    const event = new Event('change');
                                                    let obj = {
                                                        name: 'messageCode',
                                                        value: value?.id
                                                    };
                                                }}
                                                smartSearch={'Organization'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Specialty</label>
                                        <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                            <SmartSearchDropdownContainer
                                                smartSearchLabel={'Specialty'}
                                                defaultValue={'Helllo'}
                                                onChangeSmartSearch={(value: any) => {
                                                    const event = new Event('change');
                                                    let obj = {
                                                        name: 'messageCode',
                                                        value: value?.id
                                                    };
                                                }}
                                                smartSearch={'Service'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Visit Type</label>
                                        <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                            <SmartSearchDropdownContainer
                                                smartSearchLabel={'Visit Type'}
                                                defaultValue={'Helllo'}
                                                onChangeSmartSearch={(value: any) => {
                                                    const event = new Event('change');
                                                    let obj = {
                                                        name: 'messageCode',
                                                        value: value?.id
                                                    };
                                                }}
                                                smartSearch={'Designation'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Section</label>
                                        <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                            <SmartSearchDropdownContainer
                                                smartSearchLabel={'Search by Role'}
                                                defaultValue={'Helllo'}
                                                onChangeSmartSearch={(value: any) => {
                                                    const event = new Event('change');
                                                    let obj = {
                                                        name: 'messageCode',
                                                        value: value?.id
                                                    };
                                                }}
                                                smartSearch={'Role'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Category</label>
                                        <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                            <SmartSearchDropdownContainer
                                                smartSearchLabel={'Category'}
                                                defaultValue={'Helllo'}
                                                onChangeSmartSearch={(value: any) => {
                                                    const event = new Event('change');
                                                    let obj = {
                                                        name: 'messageCode',
                                                        value: value?.id
                                                    };
                                                }}
                                                smartSearch={'Role'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 d-flex profileInfoChild">
                                    <div className="sec-frame">
                                        <div className="sec-frame-title">
                                            <p>1. Add Image</p>
                                        </div>
                                        <div className="grey-bg h-100 border-radius-8">
                                            <div className="upload-section justify-content-center d-flex align-items-center">
                                                <div className="upload-gallery-img cursor-pointer">
                                                    <i className="fa-solid fa-cloud-arrow-up mb-2"></i>
                                                    <p>Upload Photos</p>
                                                    <input type="file" accept="image/*" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 profileInfoChild">
                                    <div className="row">
                                        <div className="col-md-6 d-flex flex-column">
                                            <div className="sec-frame">
                                                <div className="sec-frame-title">
                                                    <p>2. Service Card Details</p>
                                                </div>
                                                <div className="d-flex mb-3 flex-column">
                                                    <label className="bold mb-2">Status</label>
                                                    <div className="d-flex align-items-center">
                                                        <label className="form--radio-label mt-2 d-flex align-items-center me-3">
                                                            <input name="status" type="radio" id="active" value="active" checked />
                                                            <span className="ms-1">Active</span>
                                                        </label>
                                                        <label className="form--radio-label mt-2 d-flex align-items-center">
                                                            <input name="status" type="radio" id="inactive" value="inactive" />
                                                            <span className="ms-1">Inactive</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-3 flex-column">
                                                    <label className="bold mb-2">Service Card Name</label>
                                                    <input type="text" name="name-email" className="form-control white" placeholder="Service Card Name" value="" />
                                                </div>
                                                <div className="d-flex mb-3 flex-column nhs-body">
                                                    <label className="bold mb-2">Service Card Description</label>
                                                    <div className="gradient-bg message-box description-slider position-relative">
                                                        <OwlCarousel
                                                            className="owl-theme all-owl owl-carousel"
                                                            dots={false}
                                                            margin={0}
                                                            mouseDrag={false}
                                                            touchDrag={false}
                                                            nav={true}
                                                            smartSpeed={800}
                                                            items={1}
                                                        >
                                                            <div className="description-item ">
                                                                <p>Add a personalized message for patients. This can be a welcoming note or any relevant information you want to share.</p>
                                                            </div>
                                                            <div className="description-item ">
                                                                <p>Add a personalized message for patients. This can be a welcoming note or any relevant information you want to share.</p>
                                                            </div>
                                                            <div className="description-item ">
                                                                <p>Add a personalized message for patients. This can be a welcoming note or any relevant information you want to share.</p>
                                                            </div>
                                                        </OwlCarousel>
                                                        <span className="fieldset-fixed-textarea">10/30 words</span>
                                                    </div>
                                                    <div className="text-end p-3 pe-2">
                                                        <button className="toggle-button max-width-btn variation3">
                                                            <span className="toggle-indicator"></span>
                                                            <span className="label">Generate</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-flex flex-column ">
                                            <div className="sec-frame h-100 servicecardsecbuilder nhs-body">
                                                <div className="sec-frame-title d-flex">
                                                    <p> 3. Use as Custom Card</p>
                                                    <div className="anim-switch ms-2">
                                                        <label className="switchts">
                                                            <input type="checkbox" />
                                                            <div className="sliderts">
                                                                <div className="circlets">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="10"
                                                                        height="10"
                                                                        className="checkmark"
                                                                        enable-background="new 0 0 512 512"
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="currentColor"
                                                                            d="M9.707 19.121a.997.997 0 01-1.414 0l-5.646-5.647a1.5 1.5 0 010-2.121l.707-.707a1.5 1.5 0 012.121 0L9 14.171l9.525-9.525a1.5 1.5 0 012.121 0l.707.707a1.5 1.5 0 010 2.121z"
                                                                            data-original="#000000"
                                                                        ></path>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <label className="bold mb-2">Service Name</label>
                                                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                                        <SmartSearchDropdownContainer
                                                            smartSearchLabel={'Service Name'}
                                                            defaultValue={'Helllo'}
                                                            onChangeSmartSearch={(value: any) => {
                                                                const event = new Event('change');
                                                                let obj = {
                                                                    name: 'messageCode',
                                                                    value: value?.id
                                                                };
                                                            }}
                                                            smartSearch={'Service'}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-3 flex-column">
                                                    <label className="bold mb-2">Locate Service ID</label>
                                                    <input type="text" name="name-email" className="form-control white" placeholder="Locate Service ID" value="" />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <label className="bold mb-2">Provider Name</label>
                                                    <input type="text" name="name-email" className="form-control white" placeholder="Provider Name" value="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 d-flex flex-column mt-3">
                                            <div className="sec-frame h-100 servicecardsecbuilder">
                                                <div className="sec-frame-title">
                                                    <p> 4. Sub-Service Details</p>
                                                </div>
                                                <div className="d-flex mb-3 align-items-center">
                                                    <div className="auto-complete-dropdown auto-complete-dropdown-cus w-50 mb-0">
                                                        <SmartSearchDropdownContainer
                                                            smartSearchLabel={'Sub-Service Name'}
                                                            defaultValue={'Helllo'}
                                                            onChangeSmartSearch={(value: any) => {
                                                                const event = new Event('change');
                                                                let obj = {
                                                                    name: 'messageCode',
                                                                    value: value?.id
                                                                };
                                                            }}
                                                            smartSearch={'Organization'}
                                                        />
                                                    </div>
                                                    <div className="w-25 ms-2">
                                                        <p className="cursor-pointer bolder">Add New</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xl-12">
                                                        <div className="services-list-table ">
                                                            <MaterialReactTable
                                                                columns={columns}
                                                                data={Data}
                                                                enableGrouping
                                                                enableColumnActions={false}
                                                                enableBottomToolbar={false}
                                                                enableTopToolbar={false}
                                                                enableColumnDragging={false}
                                                                // getRowId={(row) => row.integrationId}
                                                                manualPagination
                                                                manualSorting
                                                                // rowCount={rowCount}
                                                            />
                                                        </div>
                                                        <div className="row align-items-center invoice-table-pagination border-0">
                                                            <div className="col-lg-6">
                                                                <div className="d-flex align-items-center">
                                                                    <p className="purple-text bold">Displaying 1 - 2 of 10</p>
                                                                    <div className="d-flex align-items-center ms-3">
                                                                        <label className="mb-0 bold nowrap me-2">Page Size:</label>
                                                                        <select className={`form-control white page-select`} id="supportedPersoIntegrationPageSize">
                                                                            <option value="10">10</option>
                                                                            <option value="25">25</option>
                                                                            <option value="50">50</option>
                                                                            <option value="100">100</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 ">
                                                                <PaginationContainer currentPage={1} totalCount={100} pageSize={10} onPageChange={(page: any) => {}} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 d-flex flex-column mt-3">
                                            <div className="sec-frame h-100 servicecardsecbuilder">
                                                <div className="sec-frame-title">
                                                    <p> 5. Notes</p>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <textarea name="funderadd" className="form-control white" rows={2}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateServiceCardsView;
