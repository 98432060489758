import { AxiosError, AxiosResponse } from 'axios';
import { API_ENDPOINT, BASEURLDEV } from './ApiConstants';
import instance from './axiosinstance';
import { GetAllServicesPayloadType } from '../pages/NHSPage/HomeMap/types';
import eventEmitter from '../utils/eventEmitter';
import { DataResponseModel } from '../utils/model';


export const getAdminServiceClaimListApi = (Payload:any) => {
    eventEmitter.emit('eventSetLoader', true)
    return (
        instance
            .post(`${BASEURLDEV}${API_ENDPOINT.GetAdminServiceClaimList}`, Payload)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    eventEmitter.emit('eventSetLoader', false)
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    eventEmitter.emit('eventSetLoader', false)
                    return errorData;
                }
            })
    );
};

export const getAdminManagedColumns = () => {
    return (
        instance
            .get(`${BASEURLDEV}${API_ENDPOINT.GetManagedColumns}`)
            // return (
            //     instance
            // .get(`http://localhost:3007/api/amenity`)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    return errorData;
                }
            })
    );
};


export const UpdateAdminManageColumnsService = (payload: any) => {
    return instance
        .post(`${BASEURLDEV}${API_ENDPOINT.AdminUpdateMangedColumns}`, payload)
        .then((response: AxiosResponse) => {
            const resultData: any = response.data as DataResponseModel;
            return resultData;
        })
        .catch((error: AxiosError) => {
            if (error.code === 'ERR_NETWORK') {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: error.message,
                    totalCount: 0
                };
                const errorData: any = errorResponse as DataResponseModel;
                return errorData;
            } else {
                const errorResponse = {
                    status: false,
                    data: [],
                    message: 'Something went wrong!',
                    totalCount: 0
                };
                const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                return errorData;
            }
        });
};

export const getAdminVerificationOverviewList = (Payload:any) => {
        return (
            instance
                .get(`${BASEURLDEV}${API_ENDPOINT.GetAdminVerificationOverViewList}`)
                // return (
                //     instance
                // .get(`http://localhost:3007/api/amenity`)
                .then((response: AxiosResponse) => {
                    const resultData: any = response.data as DataResponseModel;
                    return resultData;
                })
                .catch((error: AxiosError) => {
                    if (error.code === 'ERR_NETWORK') {
                        const errorResponse = {
                            status: false,
                            data: [],
                            message: error.message,
                            totalCount: 0
                        };
                        const errorData: any = errorResponse as DataResponseModel;
                        return errorData;
                    } else {
                        const errorResponse = {
                            status: false,
                            data: [],
                            message: 'Something went wrong!',
                            totalCount: 0
                        };
                        const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                        return errorData;
                    }
                })
        );

};

export const SubmitVerificationDetailsLog = (Payload:any) => {
    eventEmitter.emit('eventSetLoader', true)
    return (
        instance
            .post(`${BASEURLDEV}${API_ENDPOINT.SaveServiceVerificationDetaillog}`, Payload)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    eventEmitter.emit('eventSetLoader', false)
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    eventEmitter.emit('eventSetLoader', false)
                    return errorData;
                }
            })
    );
};

export const SubmitVerificationLockDetailsLog = (Payload:any) => {
    eventEmitter.emit('eventSetLoader', true)
    return (
        instance
            .post(`${BASEURLDEV}${API_ENDPOINT.SaveVerificationisLockdetails}`, Payload)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    eventEmitter.emit('eventSetLoader', false)
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    eventEmitter.emit('eventSetLoader', false)
                    return errorData;
                }
            })
    );
};

export const SaveServiceVerificationInReview = (Payload:any) => {

    eventEmitter.emit('eventSetLoader', true)

    return (

        instance
            .post(`${BASEURLDEV}${API_ENDPOINT.SaveServiceVerificationInReview}`, Payload)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };

                    const errorData: any = errorResponse as DataResponseModel;
                    eventEmitter.emit('eventSetLoader', false)
                    return errorData;

                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };

                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    eventEmitter.emit('eventSetLoader', false)
                    return errorData;

                }
            })
    );
};
 