import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

const ManageColumnView = (props: any) => {
    const [columns, setColumns] = useState({
        CompanyName: false,
        CQCProviderId: false

    })
    const { t } = useTranslation();
    const eventHandler = () => {

    }

    useEffect(() => {
        if (props.managedColumnsList?.length > 0) {
            props.managedColumnsList.map((val: any) => {
                if (val.columnName === "CompanyName") {
                    setColumns((prevState: any) => ({
                        ...prevState,
                        CompanyName: true
                    }))
                }
                if (val.columnName === "CQCProviderId") {
                    setColumns((prevState: any) => ({
                        ...prevState,
                        CQCProviderId: true
                    }))
                }
            })
        }

    }, [props.managedColumnsList])

    const onChangeColumnHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, name } = e.target
        const columnsMapping: any = {
            CompanyName: "CompanyName",
            CQCProviderId: "CQCProviderId"
        }
        if (columnsMapping[name]) {
            setColumns((pevState: any) => ({
                ...pevState,
                [name]: checked
            }))
        }

        const obj = {
            ColumnName: columnsMapping[name],
            IsActive: checked
        }

        props.onChangeManageColumnHandler(obj)

    }
    return (
        <div>
            <div className="dropdown filter-dropdown me-2">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="legend-label">
                        <i className="fa fa-cog"></i> {t('TableManageColumns.Manage_Columns')}
                    </div>
                </a>
                <div className="dropdown-menu dropdown-menu-columns" onClick={eventHandler} aria-labelledby="navbarDropdownMenuLink">
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <label>
                                    <input type="checkbox" name="CompanyName" checked={columns.CompanyName} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> Company Name
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="CQCProviderId" checked={columns.CQCProviderId} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> CQC Provider ID
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageColumnView