import React from 'react';
import AddYourCommentModalView from './AddYourCommentModalView';

const AddYourCommentModalContainer = (props: any) => {    
    return (
        <div>
            <AddYourCommentModalView onClose={props?.onClose}  
                title={props?.title}   
                mode={props?.mode} 
                costingLists={props?.costingLists} 
                commentText={props?.commentText} 
                isFromRM={props?.isFromRM}
                line={props?.line}
                isDisabled={props?.isDisabled}
                commentId={props?.commentId}
                costingTypeNameIndex={props?.costingTypeNameIndex}
                formAdditionalValues={props.formAdditionalValues}
              />
        </div>
    );
};

export default AddYourCommentModalContainer;