import logo from '../../assets/logos/logo-white-website.png';
import abstractImg from '../../assets/images/abstract.png';
import signOneImg from '../../assets/images/sign-1.png';
import signTwoImg from '../../assets/images/sign-2.png';
import signThreeImg from '../../assets/images/billing-management.svg';
import { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { toast } from 'react-toastify';
import { removeErrorLoginAction } from '../../store/Auth/loginReducer';

const ForgotPasswordView = () => {
    return (
        <div className="login-signup">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-7 p-0">
                        <div className="login-signup-bg text-center">
                            <img src={logo} alt="logo" className="img-fluid logo" />

                            <div className="abstract-img">
                                <img src={abstractImg} className="img-fluid" alt="" />
                            </div>

                            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active carousel-css">
                                        <img src={signOneImg} className="img-fluid" alt="" />
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Locate Care Bed Management</h5>
                                        </div>
                                    </div>
                                    <div className="carousel-item carousel-css">
                                        <iframe title="locatecare" src={signThreeImg} width={500} height={500} />
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Locate Care Billing Management</h5>
                                        </div>
                                    </div>
                                    <div className="carousel-item carousel-css">
                                        <img src={signTwoImg} className="img-fluid" alt="" />
                                        <div className="carousel-caption d-none d-md-block">
                                            <h5>Locate Care Referral Management</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 p-0">
                        <form>
                            <div className="login-detail d-flex align-items-center justify-content-center">
                                <div className="login-custom-width">
                                    <h4 className="my-3 purple-text">Forgot Password</h4>
                                    <p className="mb-3">Enter the Email or Username associated with your account and we'll send you a link to reset your password.</p>
                                    <div className="custom-input-box">
                                        <div>
                                            <div className="custom-input input-group">
                                                <input type="text" placeholder="none" id="email" className="form-input" maxLength={50}/>
                                                <label htmlFor="email" className="form-label">
                                                    Email or Username
                                                </label>
                                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex'>
                                        <button type="submit" className="site-button outline-purple purple-background white-text mt-4 w-100">
                                            Submit
                                        </button>
                                        <button type="submit" className="site-button outline-gray gray-background white-text mt-4 w-100 ms-3">
                                            Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordView;
