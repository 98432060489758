import React from 'react';
import OnboardingPreviewAboutUsContainer from './OnboardingPreviewAboutus/OnboardingPreviewAboutusContainer';
import OnboardingPreviewCoordinatorContainer from './OnboardingPreviewCoordinator/OnboardingPreviewCoordinatorContainer';

const OnboardingPreviewOverviewDetailsView = (props: any) => {

    return (
        <div className="">
            <div className="row">
                <div className="col-lg-5 pe-4">
                    <OnboardingPreviewCoordinatorContainer serviceCoordinatorDetails={props.serviceCoordinatorDetails} getAllServices={props.getAllServiceList} />
                </div>
                <div className="col-lg-7 ps-4">
                    <OnboardingPreviewAboutUsContainer serviceDescription={props?.serviceDescription} serviceCoordinatorDetails={props?.serviceCoordinatorDetails} getAllServices={props.getAllServiceList} />
                </div>
            </div>
        </div>
    );
};

export default OnboardingPreviewOverviewDetailsView;
