import React from 'react';
import AccessRoleView from './AccessRoleView';

const AccessRoleContainer = () => {
    return (
        <div>
            <AccessRoleView/>
        </div>
    );
};

export default AccessRoleContainer;