import React, { useEffect, useMemo, useState, useRef, useReducer } from 'react';
import AccessRoleTopContainer from './AccessRoleTop/AccessRoleTopContainer';
import AccessRoleBottomContainer from './AccessRoleBottom/AccessRoleBottomContainer';

const AccessRoleView = () => {
    return (
        <div>
            <div>
                <div className="service-title text-center">Access Roles</div>
                <div className="box-shadow new-box-shadow">
                        <AccessRoleTopContainer/>
                        <AccessRoleBottomContainer/>
                </div>
            </div>
        </div>
    );
};

export default AccessRoleView;