import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

const ExportReportsView = (props:any) => {
    const { t } = useTranslation();
    return (
        <div><a href="#" className="btn btn-white bold " data-tooltip-id="my-tooltip-export" data-tooltip-content="Export Report Details" onClick={() => props.onExportClick()}><i className='fa fa-file-export'></i> Export</a>
        <Tooltip id="my-tooltip-export" className="my-tooltip" />
        </div>
        
    );
};

export default ExportReportsView;