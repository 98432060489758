import React, { useEffect, useState } from 'react';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { InvoiceFilterInvoiceStatus, InvoiceFilterPaymentStatus } from '../../../../utils/constant';

const TableFilterView = (props: any) => {
    const { t } = useTranslation();

    const { StatusGridFilterState, DataGridFilterState } = useSelector((state: RootState) => state.gridFilterState);

    const {myInvoices,status,paymentStatus,invoiceDateFrom,invoiceDateTo,dueDateFrom,dueDateTo,serviceDateFrom,serviceDateTo} = props?.invoiceListPayloadFilter;
    let [selected, setSelected] = useState<any>('');
    let [isInvoiceDate, setIsInvoiceDate] = useState<any>(false);
    let [isDueDate, setIsDueDate] = useState<any>(false);
    let [isServiceDate, setIsServiceDate] = useState<any>(false);

    let [invoiceDateToValue, setInvoiceDateToValue] = useState<any>('');
    let [invoiceDateFromValue, setInvoiceDateFromValue] = useState<any>('');

    let [dueDateToValue, setDueDateToValue] = useState<any>('');
    let [dueDateFromValue, setDueDateFromValue] = useState<any>('');

    let [serviceDateToValue, setServiceDateToValue] = useState<any>('');
    let [serviceDateFromValue, setserviceDateFromValue] = useState<any>('');

    let [myInvoiceChecked, setMyInvoiceChecked] = useState<any>(false);
    let [draftChecked, setdraftChecked] = useState<any>(false);
    let [postedChecked, setpostedChecked] = useState<any>(false);
    let [cancelledChecked, setcancelledChecked] = useState<any>(false);
    let [unpaidChecked, setunpaidChecked] = useState<any>(false);
    let [paidChecked, setpaidChecked] = useState<any>(false);
    let [inPaymentChecked, setinPaymentChecked] = useState<any>(false);
    let [overDueChecked, setoverDueChecked] = useState<any>(false);
    

    const handleclick = (event: any, value: any) => {
        event.stopPropagation();
        setSelected(value);
    };

    const eventHandler = (event: any) => {
        event.stopPropagation();
    };
    const onInvoiceDateSubmitHandler = () => {
        if(invoiceDateToValue !== '' && invoiceDateFromValue !== ''){
            setIsInvoiceDate(true);
            let data = {
                isInvoiceDate: true,
                invoiceDateTo: dayjs(invoiceDateToValue).format('YYYY-MM-DD'),
                invoiceDateFrom: dayjs(invoiceDateFromValue).format('YYYY-MM-DD')
            }
            props.changeDatePayloadHandler(data)
        }
    };
    const onDueDateSubmitHandler = () => {
        if(dueDateToValue !== '' && dueDateFromValue !== ''){
            setIsDueDate(true);
            let data = {
                isDueDate: true,
                dueDateTo: dayjs(dueDateToValue).format('YYYY-MM-DD'),
                dueDateFrom: dayjs(dueDateFromValue).format('YYYY-MM-DD')
            }
            props.changeDatePayloadHandler(data)
        }
    };
    const onServiceDateSubmitHandler = () => {
        if(serviceDateToValue !== '' && serviceDateFromValue !== ''){
            setIsServiceDate(true);
            let data = {
                isServiceDate: true,
                serviceDateTo: dayjs(serviceDateToValue).format('YYYY-MM-DD'),
                serviceDateFrom: dayjs(serviceDateFromValue).format('YYYY-MM-DD')
            }
            props.changeDatePayloadHandler(data)
        }
    };
    const onChangeInvoiceDateSubmitHandler = (e: any) => {
        setIsInvoiceDate(false);
        setInvoiceDateToValue('');
        setInvoiceDateFromValue('');
        let data = {
            isInvoiceDate: false
        }
        props.changeDatePayloadHandler(data)
    };
    const onChangeDueDateSubmitHandler = (e: any) => {
        setIsDueDate(false);
        setDueDateToValue('');
        setDueDateFromValue('');
        let data = {
            isDueDate: false
        }
        props.changeDatePayloadHandler(data)
    };
    const onChangeServiceDateSubmitHandler = (e: any) => {
        setIsServiceDate(false);
        setServiceDateToValue('');
        setserviceDateFromValue('');
        let data = {
            isServiceDate: false
        }
        props.changeDatePayloadHandler(data)
    };

    useEffect(() => {
        if(StatusGridFilterState) {
            if(DataGridFilterState?.filter?.isInvoiceDate === true && DataGridFilterState?.filter?.invoiceDateTo !== '' && DataGridFilterState?.filter?.invoiceDateFrom !== '') {
                setIsInvoiceDate(DataGridFilterState?.filter?.isInvoiceDate);
                setInvoiceDateToValue(dayjs(DataGridFilterState?.filter?.invoiceDateTo));
                setInvoiceDateFromValue(dayjs(DataGridFilterState?.filter?.invoiceDateFrom));
            }else{
                setIsInvoiceDate(false);
                setInvoiceDateToValue('');
                setInvoiceDateFromValue('');
            }

            if(DataGridFilterState?.filter?.isDueDate === true && DataGridFilterState?.filter?.dueDateTo !== '' && DataGridFilterState?.filter?.dueDateFrom !== '') {
                setIsDueDate(DataGridFilterState?.filter?.isDueDate);
                setDueDateToValue(dayjs(DataGridFilterState?.filter?.dueDateTo));
                setDueDateFromValue(dayjs(DataGridFilterState?.filter?.dueDateFrom));
            }else{
                setIsDueDate(false);
                setDueDateToValue('');
                setDueDateFromValue('');
            }

            if(DataGridFilterState?.filter?.isServiceDate === true && DataGridFilterState?.filter?.serviceDateTo !== '' && DataGridFilterState?.filter?.serviceDateFrom !== '') {
                setIsServiceDate(DataGridFilterState?.filter?.isServiceDate);
                setServiceDateToValue(dayjs(DataGridFilterState?.filter?.serviceDateTo));
                setserviceDateFromValue(dayjs(DataGridFilterState?.filter?.serviceDateFrom));
            }else{
                setIsServiceDate(false);
                setServiceDateToValue('');
                setserviceDateFromValue('');
            }

            if(DataGridFilterState?.filter?.myInvoices){
                setMyInvoiceChecked(true)
            } else {
                setMyInvoiceChecked(false)
            }

            if(DataGridFilterState?.filter?.status?.length > 0) {
                if(DataGridFilterState?.filter?.status.includes(InvoiceFilterInvoiceStatus.Draft)) {
                    setdraftChecked(true)
                } else {
                    setdraftChecked(false)
                }
                if(DataGridFilterState?.filter?.status.includes(InvoiceFilterInvoiceStatus.PostedOrConfirm)) {
                    setpostedChecked(true)
                } else {
                    setpostedChecked(false)
                }
                if(DataGridFilterState?.filter?.status.includes(InvoiceFilterInvoiceStatus.Cancelled)) {
                    setcancelledChecked(true)
                } else {
                    setcancelledChecked(false)
                }
            } else {
                setdraftChecked(false)
                setpostedChecked(false)
                setcancelledChecked(false)
            }

            if(DataGridFilterState?.filter?.paymentStatus?.length > 0) {
                if(DataGridFilterState?.filter?.paymentStatus.includes(InvoiceFilterPaymentStatus.Unpaid)) {
                    setunpaidChecked(true)
                } else {
                    setunpaidChecked(false)
                }
                if(DataGridFilterState?.filter?.paymentStatus.includes(InvoiceFilterPaymentStatus.Paid)) {
                    setpaidChecked(true)
                } else {
                    setpaidChecked(false)
                }
                if(DataGridFilterState?.filter?.paymentStatus.includes(InvoiceFilterPaymentStatus.InPayment)) {
                    setinPaymentChecked(true)
                } else {
                    setinPaymentChecked(false)
                }
                if(DataGridFilterState?.filter?.paymentStatus.includes(InvoiceFilterPaymentStatus.Overdue)) {
                    setoverDueChecked(true)
                } else {
                    setoverDueChecked(false)
                }
            } else {
                setunpaidChecked(false)
                setpaidChecked(false)
                setinPaymentChecked(false)
                setoverDueChecked(false)
            }
        }
    },[StatusGridFilterState, DataGridFilterState])

    const IsFilterApplied = () => {
        if(myInvoices === true || status.length > 0 || paymentStatus.length > 0 || isInvoiceDate === true && invoiceDateFrom !== '' && invoiceDateTo !== '' || isDueDate === true && dueDateFrom !== '' && dueDateTo !== '' || isServiceDate === true && serviceDateFrom !== '' && serviceDateTo !== '' ){
            return true
        }
        return false
     }
     
    return (
        <div>
            <div className="dropdown filter-dropdown me-2">
                <a className={`nav-link dropdown-toggle`} href="#" id="navbarDropdownMenuLink5" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="legend-label">
                        <i className="fa-solid fa-filter"></i> {t('TableFilter.Filter')}
                    </div>
                </a>
                <div className="dropdown-menu profile-dw-css" onClick={eventHandler} aria-labelledby="navbarDropdownMenuLink">
                    <div>
                        <label>
                            <input type="checkbox" name="myInvoice" checked={myInvoiceChecked} onChange={(event: any) => props.changePayloadHandler(event)} className="me-1" /> {t('TableFilter.My_Invoice')}
                        </label>
                    </div>
                    <div className="border-bottom mt-1 mb-1"></div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value={InvoiceFilterInvoiceStatus.Draft} name="draft" checked={draftChecked} className="me-1" /> {t('TableFilter.Draft')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value={InvoiceFilterInvoiceStatus.PostedOrConfirm} name="posted" checked={postedChecked} className="me-1" /> {t('TableFilter.Posted')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value={InvoiceFilterInvoiceStatus.Cancelled} name="cancelled" checked={cancelledChecked} className="me-1" /> {t('TableFilter.Cancelled')}
                        </label>
                    </div>
                    <div className="border-bottom mt-1 mb-1"></div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value={InvoiceFilterPaymentStatus.Unpaid} name="unpaid" checked={unpaidChecked} className="me-1" /> {t('TableFilter.Unpaid')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value={InvoiceFilterPaymentStatus.Paid} name="paid" checked={paidChecked} className="me-1" /> {t('TableFilter.Paid')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value={InvoiceFilterPaymentStatus.InPayment} name="inPayment" checked={inPaymentChecked} className="me-1" /> {t('TableFilter.In_Payment')}
                        </label>
                    </div>
                   
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value={InvoiceFilterPaymentStatus.Overdue} name="overDue" checked={overDueChecked} className="me-1" /> {t('TableFilter.Overdue')}
                        </label>
                    </div>
                    
                    <div className="border-bottom mt-1 mb-1"></div>

                    <div className="dropdown sub-dropdown">
                        <a
                            className={`nav-link dropdown-toggle  ${selected === 'invoice' ? 'show' : ''}`}
                            href="#"
                            onClick={(event) => handleclick(event, 'invoice')}
                            id="navbarDropdownMenuLink1"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <label>
                                <input type="checkbox" name="isInvoiceDate" checked={isInvoiceDate} onChange={(e: any) => onChangeInvoiceDateSubmitHandler(e)} className="me-1" /> {t('TableFilter.Invoice_Date')} <i className="fa-solid fa-caret-right ms-auto"></i>
                            </label>
                        </a>
                        <div className={`dropdown-menu dropdown-submenu dropdown-submenu-custom  ${selected === 'invoice' ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink1">
                            <div className="d-flex date-filter">
                            <div className="datepicker-input-css me-3">
                                    <p className=" mb-1">{t('TableFilter.From')}</p>
                                    <DatePicker onChange={(date: any) => setInvoiceDateFromValue(date)} value={invoiceDateFromValue} className="form-control white" label="" format="DD/MM/YYYY" views={['year', 'month', 'day']} slotProps={{actionBar: { actions: ['clear','today'] },}} />
                                </div>
                                <div className="datepicker-input-css  ">
                                    <p className=" mb-1">{t('TableFilter.To')}</p>
                                    <DatePicker onChange={(date: any) => setInvoiceDateToValue(date)} value={invoiceDateToValue} className="form-control white" label="" format="DD/MM/YYYY" views={['year', 'month', 'day']} slotProps={{actionBar: { actions: ['clear','today'] },}} />
                                </div>
                               
                            </div>
                            <div className="d-flex justify-content-center">
                                <a onClick={onInvoiceDateSubmitHandler} className="btn btn-primary btn-sm mt-3 me-2">
                                    Submit
                                </a>
                                <a onClick={onChangeInvoiceDateSubmitHandler} className="btn btn-secondary btn-sm mt-3">
                                    Clear
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="dropdown sub-dropdown">
                        <a
                            className={`nav-link dropdown-toggle  ${selected === 'due' ? 'show' : ''}`}
                            onClick={(event) => handleclick(event, 'due')}
                            href="#"
                            id="navbarDropdownMenuLink2"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <label>
                                <input type="checkbox" name="isDueDate" checked={isDueDate} onChange={(e: any) => onChangeDueDateSubmitHandler(e)} className="me-1" /> {t('TableFilter.Due_Date')} <i className="fa-solid fa-caret-right ms-auto"></i>
                            </label>
                        </a>
                        <div className={`dropdown-menu dropdown-submenu dropdown-submenu-custom  ${selected === 'due' ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink2">
                            <div className="d-flex date-filter">
                            <div className="datepicker-input-css me-3">
                                    <p className=" mb-1">{t('TableFilter.From')}</p>
                                    <DatePicker onChange={(date: any) => setDueDateFromValue(date)} value={dueDateFromValue} className="form-control white" label="" format="DD/MM/YYYY" views={['year', 'month', 'day']} slotProps={{actionBar: { actions: ['clear','today'] },}} />
                                </div>
                                <div className="datepicker-input-css ">
                                    <p className=" mb-1">{t('TableFilter.To')}</p>
                                    <DatePicker onChange={(date: any) => setDueDateToValue(date)} value={dueDateToValue} className="form-control white" label="" format="DD/MM/YYYY" views={['year', 'month', 'day']} slotProps={{actionBar: { actions: ['clear','today'] },}} />
                                </div>
                               
                            </div>
                            <div className="d-flex justify-content-center">
                                <a onClick={onDueDateSubmitHandler} className="btn btn-primary btn-sm mt-3 me-2">
                                    Submit
                                </a>
                                <a onClick={onChangeDueDateSubmitHandler} className="btn btn-secondary btn-sm mt-3">
                                    Clear
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="dropdown sub-dropdown">
                        <a
                            className={`nav-link dropdown-toggle  ${selected === 'service' ? 'show' : ''}`}
                            onClick={(event) => handleclick(event, 'service')}
                            href="#"
                            id="navbarDropdownMenuLink3"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <label>
                                <input type="checkbox" name="isServiceDate" checked={isServiceDate} onChange={(e: any) => onChangeServiceDateSubmitHandler(e)} className="me-1" /> {t('TableFilter.Service_Date')} <i className="fa-solid fa-caret-right ms-auto"></i>
                            </label>
                        </a>
                        <div className={`dropdown-menu dropdown-submenu dropdown-submenu-custom ${selected === 'service' ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink3">
                            <div className="d-flex date-filter">
                            <div className="datepicker-input-css me-3">
                                    <p className=" mb-1">{t('TableFilter.From')}</p>
                                    <DatePicker onChange={(date: any) => setserviceDateFromValue(date)} value={serviceDateFromValue} className="form-control white" label="" format="DD/MM/YYYY" views={['year', 'month', 'day']} slotProps={{actionBar: { actions: ['clear','today'] },}} />
                                </div>
                                <div className="datepicker-input-css ">
                                    <p className=" mb-1">{t('TableFilter.To')}</p>
                                    <DatePicker onChange={(date: any) => setServiceDateToValue(date)} value={serviceDateToValue} className="form-control white" label="" format="DD/MM/YYYY" views={['year', 'month', 'day']} slotProps={{actionBar: { actions: ['clear','today'] },}} />
                                </div>
                                
                            </div>
                            <div className="d-flex justify-content-center">
                                <a onClick={onServiceDateSubmitHandler} className="btn btn-primary btn-sm mt-3 me-2">
                                    Submit
                                </a>
                                <a onClick={onChangeServiceDateSubmitHandler} className="btn btn-secondary btn-sm mt-3">
                                    Clear
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableFilterView;
