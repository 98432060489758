import React from 'react';
import { useTranslation } from 'react-i18next';

const TableActionView = (props: any) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className="dropdown filter-dropdown me-2">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="legend-label"><i className="fa fa-ellipsis-vertical me-1"></i> {t('TableAction.Action')}</div>
                </a>
                <div className="dropdown-menu dropdown-menu-action"  aria-labelledby="navbarDropdownMenuLink">       
                    <a href='#' className='dropdown-item' onClick={() => props.onChangeActionHandler("Confirm")}>
                        {t('TableAction.Confirm')}
                    </a>
                    <a href='#' className='dropdown-item' onClick={() => props.onChangeActionHandler("Cancel")}>
                        {t('TableAction.Cancel')}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default TableActionView;