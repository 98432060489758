'use client';
import React, { useEffect, useState } from 'react';
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import ClientPortal from '../../../../../../../components/ClientPortal';
import { useTranslation } from 'react-i18next';

const AboutUsView = (props: any) => {
    const { t } = useTranslation();
    const [showFullMessage, setShowFullMessage] = useState<boolean>(false);
    const [showFullCoordinatorMessage, setShowFullCoordinatorMessage] = useState<boolean>(false);
    useEffect(() => {
        AOS.init();
    }, []);

    const handleSeeMoreModal = (event: any) => {
        event.preventDefault();
        setShowFullMessage((prevState) => !prevState);
    };
    const handleSeeMoreCoordinatorModal = (event: any) => {
        event.preventDefault();
        setShowFullCoordinatorMessage((prevState) => !prevState);
    };
    const viewMore = () => {
        setShowFullMessage(!showFullMessage);
    };
    const viewMoreCoordinator = () => {
        setShowFullCoordinatorMessage(!showFullCoordinatorMessage);
    };
    useEffect(() => {
        if (showFullMessage) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showFullMessage]);
    useEffect(() => {
        if (showFullCoordinatorMessage) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showFullCoordinatorMessage]);
    const splitFullName = (fullName: any) => {
        if (!fullName || typeof fullName !== 'string') {
            return { firstName: '', lastName: '' };
        }

        const nameParts = fullName.split(' ');
        if(nameParts.length > 1){
            const firstName = nameParts[0];
            const lastName = nameParts.slice(1).join(' ');  
            return { firstName, lastName };
        } else {
            const firstName = nameParts[0];    
            return { firstName, lastName: "" };
        }
    };

    const { firstName, lastName } = splitFullName(props?.serviceCoordinatorDetails?.name);
    return (
        <div>
            <h4 className="small-title indigo-text bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                {t('Overview.About_Us')}
            </h4>
            {props?.serviceDescription && props?.serviceDescription !== null && (
                <div className="about-max-content">
                    <p className="font15 mb-3">
                        {props?.serviceDescription}
                        {/* {props?.serviceDescription?.substring(0, 630) + (props?.serviceDescription?.length > 630 ? '...' : '')}
                    {props?.serviceDescription?.length > 630 && (
                        <a className="view-more-text bold hand-icon" onClick={viewMore}>
                            view more
                        </a>
                    )} */}
                    </p>
                </div>
            )}

            {/* <div className="mt-4">
                <div className="d-flex small-title align-items-center preview-small-title">
                    <h4 className="indigo-text bolder mb-0" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                        Message from your Host
                    </h4>
                </div>
                <p className="bolder coordinator-title">Message from your Coordinator</p>
                {props?.serviceCoordinatorDetails?.serviceCoordinatorMessage && props?.serviceCoordinatorDetails?.serviceCoordinatorMessage !== null && (
                    <p className="font15">
                        {props?.serviceCoordinatorDetails?.serviceCoordinatorMessage.substring(0, 600) + (props?.serviceCoordinatorDetails?.serviceCoordinatorMessage?.length > 600 ? '...' : '')}
                        {props?.serviceCoordinatorDetails?.serviceCoordinatorMessage?.length > 600 && (
                            <a className="view-more-text bold hand-icon" onClick={viewMoreCoordinator}>
                                view more
                            </a>
                        )}
                    </p>
                )}
                <p className="coordinator-img"><img src={openIcon.src} alt="open 24/7" width={20} className="me-2" />We are open 24/7 and 365 days</p>
            </div> */}
            <ClientPortal selector="myportal" show={showFullMessage}>
                <div className="overlay" onClick={handleSeeMoreModal}></div>
                <div className="modal">
                    <div className="header mb-3">
                        <h4 className="mb-0">About {props?.serviceCoordinatorDetails?.serviceName}</h4>
                        <a onClick={viewMore} className="removeButton">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body text-popup">
                        <p>{props?.serviceDescription}</p>
                    </div>
                </div>
            </ClientPortal>
            <ClientPortal selector="myportal" show={showFullCoordinatorMessage}>
                <div className="overlay" onClick={handleSeeMoreCoordinatorModal}></div>
                <div className="modal">
                    <div className="header mb-3">
                        <h4 className="mb-0">{firstName}'s Message</h4>
                        <a onClick={viewMoreCoordinator} className="removeButton">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body text-popup">
                        <p>{props?.serviceCoordinatorDetails?.serviceCoordinatorMessage}</p>
                    </div>
                </div>
            </ClientPortal>
        </div>
    );
};

export default AboutUsView;
