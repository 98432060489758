import { Autocomplete, TextField } from '@mui/material';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import { Tooltip } from 'react-tooltip';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import swal from 'sweetalert';
import airobot from '../../../assets/images/airobot.png';
import warning from '../../../assets/images/warning.png';
import ClientPortal from '../../../components/ClientPortal';
import LoaderContainer from '../../../components/Loader/loaderContainer';
import {
    addBedByWardId,
    applyAmenitiesToBed,
    deleteBed,
    editBedName,
    editWardName,
    getOnboardingAmenity,
    getOnboardingWardList,
    saveAmenitiesToBed
} from '../../../service/onboarding-setupWard';
import { RootState } from '../../../store';
import { OpenAienums } from '../../../utils/constant';
import WardCard from './WardCard';
import NameWardsContainer from '../NameWards/NameWardsContainer';
import OnboardingGenerateAnimationLoader from '../ServiceOnboardingPreview/OnboardingGenerateAnimationLoder';
import { setOnboardingPageValidity } from '../../../store/onboarding/onboardingPageValidityReducer';
import { generateOpenAiDescription } from '../../../service/onboarding-describeyourfacility-service';

interface Amenity {
    amenityId: number;
    amenityName: string;
    amenityIcon: string;
}

interface AmenityCategory {
    amenityCategoryId: number;
    amenityCategoryName: string;
    amenityCategoryIcon: string;
    amenities: Amenity[];
}

interface Bed {
    bedId: number;
    bedName: string;
    amenities: Amenity[];
}

interface Ward {
    wardId: number;
    wardName: string;
    status: boolean;
    bedPrefix: string;
    beds: Bed[];
}

interface AIHeadingType {
    airesponseId: number;
    generatedResponse: string;
    isSelected: boolean;
}

const SetupWardView = React.forwardRef((props, ref) => {
    const nameWardPage = useRef<any>();
    const location = useLocation();
    const dispatch = useDispatch();
    const wardSliderRefs = useRef<any[]>([]);
    const headingInputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const [showPortal, setShowPortal] = useState(false);
    const [categories, setCategories] = useState<AmenityCategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<AmenityCategory | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [wards, setWards] = useState<Ward[]>([]);
    const [selectedWardId, setSelectedWardId] = useState<number | null>(null);
    const [selectedBedId, setSelectedBedId] = useState<number | null>(null);
    const [selectedBedName, setSelectedBedName] = useState<string>('');
    const [selectedAmenities, setSelectedAmenities] = useState<Amenity[]>([]);
    const [editingWardId, setEditingWardId] = useState<number | null>(null);
    const [newWardName, setNewWardName] = useState<string>('');
    const [filteredAmenities, setFilteredAmenities] = useState<Amenity[]>([]);
    const [filteredCategories, setFilteredCategories] = useState<AmenityCategory[]>([]);
    const [headingOptions, setHeadingOptions] = useState<AIHeadingType[]>([]);
    const [headingOptionsUnTouched, setHeadingOptionsUnTouched] = useState<AIHeadingType[]>([]);
    const [selectedHeading, setSelectedHeading] = useState<AIHeadingType | null>(null);
    const [nonEditedSelectedHeading, setNonEditedSelectedHeading] = useState<AIHeadingType | null>(null);
    const [isFinalScreen, setIsFinalScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isManualGenerate, setIsManualGenerate] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showNameWards, setShowNameWards] = useState(false);
    const [headerPayload, setHeaderPayload] = useState<any>([]);
    const [showBedDeleteIcon, setShowBedDeleteIcon] = useState(true);
    const disclaimerCategories = ['Outdoors', 'Activities', 'Leisure & Well-being'];
    const restrictedCategories: string[] = ['Outdoors', 'Activities', 'Leisure & Well-being'];

    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);

    useEffect(() => {
        if (wards.length === 1 && wards[0].beds.length === 1) {
            setShowBedDeleteIcon(false);
        } else {
            setShowBedDeleteIcon(wards.length !== 0);
        }
    }, [wards]);

    useEffect(() => {
        setSelectedHeading(null);
        if (nonEditedSelectedHeading) {
            setHeadingOptions((previousList: AIHeadingType[]) => {
                return previousList.map((previousHeading: AIHeadingType) => {
                    if (previousHeading.airesponseId === nonEditedSelectedHeading.airesponseId) {
                        return { ...nonEditedSelectedHeading, isSelected: false };
                    }
                    return { ...previousHeading };
                });
            });
        }
    }, [nonEditedSelectedHeading]);

    useEffect(() => {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        const footer = document.querySelector('.stepfooter') as HTMLElement;

        if (isDropdownOpen) {
            document.body.classList.add('dropdown-setup-ward');
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            if (footer) {
                footer.style.paddingRight = `${scrollbarWidth}px`;
            }
        } else {
            document.body.classList.remove('dropdown-setup-ward');
            document.body.style.paddingRight = '0px';
            if (footer) {
                footer.style.paddingRight = '0px';
            }
        }

        return () => {
            document.body.classList.remove('dropdown-setup-ward');
            document.body.style.paddingRight = '0px';
            if (footer) {
                footer.style.paddingRight = '0px';
            }
        };
    }, [isDropdownOpen]);

    useEffect(() => {
        if (showPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showPortal]);

    useEffect(() => {
        if (showNameWards) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showNameWards]);

    useEffect(() => {
        if (location.pathname.includes('preview') || location.pathname.includes('publish')) {
            setIsFinalScreen(true);
        } else {
            setIsFinalScreen(false);
        }
    }, [location]);

    useEffect(() => {
        if (selectedWardId !== null) {
            loadWardList(selectedWardId);
        }
    }, [selectedWardId]);

    useEffect(() => {
        setIsLoading(true);
        const loadAmenities = async () => {
            try {
                const result = await getOnboardingAmenity(serviceId!);
                if (result.status) {
                    setCategories(result.data);
                    setFilteredAmenities(result.data.flatMap((category: AmenityCategory) => category.amenities));
                    setFilteredCategories(result.data);
                } else {
                }
            } catch (error) {}
        };

        loadAmenities();
        setTimeout(() => {
            loadWardList(null);
            setIsLoading(false);
        }, 2000);
    }, []);

    useEffect(() => {
        const searchValue = searchTerm.toLowerCase();
        const filteredCategoryMultiSearch = (selectedCategory ? selectedCategory.amenities : categories.flatMap((category: AmenityCategory) => category.amenities)).filter((amenity) =>
            amenity.amenityName.toLowerCase().includes(searchValue)
        );
        setFilteredAmenities(filteredCategoryMultiSearch);

        const filteredCategories = categories.filter((a: AmenityCategory) => a.amenities.filter((amenity) => amenity.amenityName.toLowerCase().includes(searchValue)).length > 0);

        if (!searchValue) {
            setFilteredCategories(categories);
            setFilteredAmenities(selectedCategory?.amenities || []);
        } else {
            setFilteredCategories(filteredCategories);
            if (filteredCategories.length > 0 && selectedCategory) {
                if (!filteredCategories.some((cat) => cat.amenityCategoryId === selectedCategory?.amenityCategoryId)) {
                    setSelectedCategory(filteredCategories[0]);
                }
            } else {
                setSelectedCategory(null);
            }
        }
    }, [searchTerm, selectedCategory, categories]);

    const loadWardList = async (WardId: number | null) => {
        try {
            const result = await getOnboardingWardList({ Service_Id: serviceId!, WardId: WardId });
            if (result.status) {
                const wardList = result?.data?.wardAndBedAmenityList.map((ward: any) => ({
                    ...ward,
                    beds: ward.beds.map((bed: any) => ({
                        bedId: bed.bedId,
                        bedName: bed.bedName,
                        amenities: bed.amenities.map((amenity: any) => ({
                            amenityId: amenity.amenityId,
                            amenityName: amenity.amenityName,
                            amenityIcon: amenity.amenityIcon
                        }))
                    }))
                }));
                setWards([...wardList]);
                if (!WardId && wardList.length > 0) {
                    setSelectedWardId(wardList[0].wardId);
                }
                setHeadingOptions([...result?.data?.openairesponse]);
                setHeadingOptionsUnTouched([...result?.data?.openairesponse]);
                const heading = result?.data?.openairesponse?.find((heading: AIHeadingType) => heading.isSelected === true);
                setSelectedHeading((prevHeading) => {
                    if (heading) {
                        return heading;
                    } else {
                        return prevHeading;
                    }
                });
            } else {
            }
        } catch (error) {}
    };

    const handleCategoryClick = useCallback(
        (category: AmenityCategory) => {
            if (selectedCategory && selectedCategory.amenityCategoryId === category.amenityCategoryId) {
                setSelectedCategory(null);
            } else {
                setSelectedCategory(category);
            }
        },
        [selectedCategory]
    );

    const getShortBedName = useCallback((wardName: string) => {
        const initials = wardName
            .split(' ')
            .map((word) => word.charAt(0))
            .join('');
        return initials;
    }, []);

    const getBedNameWithPrefix = (selectedWard: Ward, index: number) => {
        const prefix = selectedWard?.bedPrefix ? selectedWard?.bedPrefix : getShortBedName(selectedWard.wardName);
        return `${prefix}-${index + 1}`;
    };

    const handleModal = useCallback(
        (bedId: number, wardId: number) => {
            const selectedWard = wards.find((ward) => ward.wardId === wardId);
            if (selectedWard) {
                const selectedBed = selectedWard.beds.find((bed) => bed.bedId === bedId);
                if (selectedBed) {
                    setSelectedBedId(bedId);
                    setSelectedWardId(wardId);
                    setSelectedAmenities(selectedBed.amenities);
                    setSelectedBedName(getBedNameWithPrefix(selectedWard, selectedWard.beds.indexOf(selectedBed)));
                }
            }

            if (filteredCategories.length > 0) {
                setSelectedCategory(filteredCategories[0]);
            }

            setShowPortal(true);
        },
        [wards, filteredCategories]
    );

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }, []);

    const handleCheckboxChange = useCallback(
        (amenityId: number) => {
            setSelectedAmenities((prevSelected) =>
                prevSelected.find((amenity) => amenity.amenityId === amenityId)
                    ? prevSelected.filter((amenity) => amenity.amenityId !== amenityId)
                    : [...prevSelected, categories.flatMap((category) => category.amenities).find((amenity) => amenity.amenityId === amenityId)!]
            );
        },
        [categories]
    );

    const handleUpdateAmenities = useCallback(
        async (bedId: number, wardId: number, amenities: Amenity[], isDeletingAmenity?: boolean, deletedAmenityName?: string) => {
            const isMultipleWard = document.getElementsByClassName('showSection');
            syncAmenities(
                serviceId!,
                wardId,
                bedId,
                amenities.map((amenity) => amenity.amenityId),
                [],
                !(isMultipleWard.length === 0),
                isDeletingAmenity,
                deletedAmenityName
            ).then(async () => {
                await loadWardList(selectedWardId);
            });
            // if (isMultipleWard.length === 0) {
            //     setWards((prevWards) =>
            //         prevWards.map((ward) => ({
            //             ...ward,
            //             beds: ward.beds.map((bed) => (bed.bedId === bedId && ward.wardId === wardId ? { ...bed, amenities: Array.from(new Set(amenities)) } : bed))
            //         }))
            //     );
            // } else {
            //     setWards((prevWards) => {
            //         return prevWards.map((ward) => ({
            //             ...ward,
            //             beds: ward.beds.map((bed) => {
            //                 if (ward.wardId === wardId) {
            //                     if (bed.bedId === bedId) {
            //                         return { ...bed, amenities: Array.from(new Set(amenities)) }
            //                     } else {
            //                         const combinedAmenities = [...bed.amenities, ...amenities];
            //                         const updatedAmenities = combinedAmenities.reduce((acc: Amenity[], current: Amenity) => {
            //                             const isDuplicate = acc.find((item: Amenity) => item.amenityId === current.amenityId);
            //                             if (!isDuplicate) {
            //                                 acc.push(current);
            //                             }
            //                             return acc;
            //                         }, []);
            //                         return { ...bed, amenities: updatedAmenities };
            //                     }
            //                 }
            //                 else {
            //                     return { ...bed }
            //                 }
            //             })
            //         }))
            //     });
            // }
        },
        [wards, selectedWardId, loadWardList, serviceId]
    );

    const handleApply = useCallback(async () => {
        const checkedWardIds = Array.from(document.querySelectorAll('#wardSelectionForAmenities input:checked')).map((input) => parseInt((input as HTMLInputElement).value));

        if (checkedWardIds.length === 0) {
            if (selectedBedId !== null && selectedWardId !== null) {
                handleUpdateAmenities(selectedBedId, selectedWardId, selectedAmenities);
            }
        } else {
            const updatedWardIds = selectedWardId ? [...checkedWardIds, selectedWardId] : checkedWardIds;
            const isMultipleWard = document.getElementsByClassName('showSection');

            syncAmenities(
                serviceId!,
                0, // Adding zero because this filed is required in the backend and does'nt make sense when we send checkedWardIds
                selectedBedId!,
                selectedAmenities.map((amenity) => amenity.amenityId),
                updatedWardIds,
                !(isMultipleWard.length === 0)
            ).then(() => {
                loadWardList(selectedWardId);
            });
        }
        setShowPortal(false);
        recalculateWardStatuses();
    }, [selectedAmenities, selectedBedId, selectedWardId, handleUpdateAmenities]);

    const syncAmenities = async (
        Service_Id: number,
        WardId: number,
        BedId: number,
        AmenityIds: number[],
        WardIds: number[],
        IsMultipleWard: boolean,
        isDeletingAmenity?: boolean,
        deletedAmenityName?: string
    ) => {
        const res = await applyAmenitiesToBed({
            Service_Id,
            WardId,
            BedId,
            AmenityIds,
            WardIds,
            IsMultipleWard
        });
        if (isDeletingAmenity) {
            if (res.status) {
                showWarning(deletedAmenityName + ' removed successfully.', true);
            }
        }
        if (res.status === false) {
            showWarning(res.message);
        }
    };

    const handleShowNameWards = (event: any) => {
        event.preventDefault();
        setShowNameWards((prevState) => !prevState);
    };

    const handleSaveWards = async (event: any) => {
        if ((await nameWardPage.current.triggerValidation()) === false) {
            return;
        }
        nameWardPage.current.nextActionHandler!(true);
    };

    const recalculateWardStatuses = useCallback(() => {
        setWards((prevWards) =>
            prevWards.map((ward) => {
                if (ward.wardId === selectedWardId) {
                    const allBedsHaveAmenities = ward.beds.every((bed) => bed.amenities.length > 0);
                    return {
                        ...ward,
                        status: allBedsHaveAmenities ? true : false
                    };
                } else {
                    return { ...ward };
                }
            })
        );
    }, [wards, selectedWardId]);

    const addBed = useCallback(async () => {
        if (selectedWardId === null) return;

        const specificWard = wards.filter((ward) => ward.wardId === selectedWardId);
        const specificWardIndex = wards.findIndex((ward) => ward.wardId === selectedWardId);

        const bedName = `${specificWard[0]?.bedPrefix}${specificWard[0]?.beds?.length + 1}`;
        const res = await addBedByWardId({ Ward_Id: selectedWardId, BedName: bedName, Service_Id: serviceId! });
        if (res.status === true) {
            setWards((prevWards) =>
                prevWards.map((ward) =>
                    ward.wardId === selectedWardId
                        ? {
                              ...ward,
                              beds: [
                                  ...ward.beds,
                                  {
                                      bedId: res?.data?.bedId,
                                      bedName: `${ward.bedPrefix}${ward.beds?.length + 1}`,
                                      amenities: []
                                  }
                              ]
                          }
                        : ward
                )
            );
            if (specificWard[0]?.beds.length >= 4) {
                setTimeout(() => {
                    wardSliderRefs.current[specificWardIndex]?.slickGoTo(specificWard[0]?.beds.length - 1);
                }, 500);
            }
        } else {
            showWarning(res?.message);
        }
    }, [wards, selectedWardId, wardSliderRefs, serviceId]);

    const applyToAllWard = useCallback(() => {
        const element = document.getElementById('wardSelectionForAmenities');
        if (element) {
            element.classList.toggle('showSection');
        }
    }, []);

    const handleDelete1 = useCallback((wardId: number, bedId: number) => {
        setWards((prevWards) => {
            return prevWards.map((ward) => (ward.wardId === wardId ? { ...ward, beds: ward.beds.filter((bed) => bed.bedId !== bedId) } : ward));
        });
    }, []);

    const handleDelete = useCallback(
        async (wardId: number, bedId: number, bedName: string, serviceId: number) => {
            try {
                const currentWard = wards.find((ward) => ward.wardId === wardId);
                const currentWardIndex = wards.findIndex((ward) => ward.wardId === wardId);

                if (currentWard?.beds.length === 1) {
                    swal({
                        title: '',
                        text: `Deleting all the beds will delete the ${currentWard?.wardName}. Do you want to continue?`,
                        icon: warning,
                        buttons: ['No', 'Yes'],
                        dangerMode: true
                    }).then(async (confirm: any) => {
                        if (confirm) {
                            const response = await deleteBed({ BedId: bedId, Ward_Id: wardId, Service_Id: serviceId, BedName: bedName });

                            if (response.status) {
                                swal({
                                    title: '',
                                    text: `${currentWard?.wardName} and associated beds deleted successfully`,
                                    icon: 'success',
                                    dangerMode: true
                                }).then(() => {
                                    loadWardList(null);
                                });
                            } else {
                                showWarning(response?.message);
                            }
                        }
                    });
                } else {
                    const response = await deleteBed({ BedId: bedId, Ward_Id: wardId, Service_Id: serviceId, BedName: bedName });
                    if (currentWard && currentWard.beds.length > 4) {
                        wardSliderRefs?.current[currentWardIndex]?.slickPrev();
                    }
                    if (response.status) {
                        setWards((prevWards) => {
                            return prevWards.map((ward) => (ward.wardId === wardId ? { ...ward, beds: ward.beds.filter((bed) => bed.bedId !== bedId) } : ward));
                        });
                    } else {
                        showWarning(response?.message);
                    }
                }
            } catch (error) {}
        },
        [wards]
    );

    const handleBedNameChange = useCallback(
        async (wardId: number, bedId: number, newBedName: string) => {
            let newName = newBedName.trim();
            if (!newName) {
                const currentWard = wards.find((ward) => ward.wardId === wardId);
                const index = currentWard?.beds.findIndex((bed) => bed.bedId === bedId);
                if (currentWard && index !== undefined && index >= 0) {
                    newName = getBedNameWithPrefix(currentWard, index);
                }
            }
            setWards((prevWards) =>
                prevWards.map((ward) =>
                    ward.wardId === wardId
                        ? {
                              ...ward,
                              beds: ward.beds.map((bed) => (bed.bedId === bedId ? { ...bed, bedName: newName } : bed))
                          }
                        : ward
                )
            );
            const res = await editBedName({ BedId: bedId, BedName: newName, Ward_Id: wardId, Service_Id: serviceId! });
            if (res.status === false) {
                showWarning('Error saving new bed name.');
            }
        },
        [wards, getBedNameWithPrefix]
    );

    const handleEditWardName = useCallback((wardId: number, wardName: string) => {
        if (wardName.length <= 100) {
            setEditingWardId(wardId);
            setNewWardName(wardName);
        } else {
            showWarning(t('OnboardingSetupWard.Ward_Name_Length_Warning')!);
        }
    }, []);

    const handleSaveWardName = useCallback(
        async (event: any, wardId: number) => {
            setWards((prevWards) => prevWards.map((ward) => (ward.wardId === editingWardId ? { ...ward, wardName: newWardName } : ward)));
            setEditingWardId(null);
            if (event.target.value.length <= 100) {
                const res = await editWardName({ Service_Id: serviceId!, Ward_Id: wardId, WardName: event.target.value });
                if (res.status === false) {
                    showWarning('Error saving new ward name.');
                }
            } else {
                showWarning(t('OnboardingSetupWard.Ward_Name_Length_Warning')!);
            }
        },
        [editingWardId, newWardName]
    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>, wardId: number) => {
            if (event.key === 'Enter') {
                handleSaveWardName(event, wardId);
            }
        },
        [handleSaveWardName]
    );

    const showWarning = (message: string, success?: boolean) => {
        swal({
            title: '',
            text: message,
            icon: success ? 'success' : warning,
            dangerMode: true
        });
    };

    const handleGenerateHeadings = useCallback(async () => {
        //setIsLoading(true);
        setIsManualGenerate(true)
        const payload: any = {
            serviceId: serviceId!,
            pageId: OpenAienums.SaveAmenities
        }
        try {
            const response = await generateOpenAiDescription(payload);
            if (response.status) {
                const newHeadings = response?.data;
                setHeadingOptions((prevOptions) => [...newHeadings, ...prevOptions]);
                setHeadingOptionsUnTouched((prevOptions) => [...newHeadings, ...prevOptions]);
                focusHeadingAutocomplete();
            } else {
                showWarning(response.message);
            }
        } catch (error) {
            //setIsLoading(false);
            setIsManualGenerate(false);
            console.error('Error generating headings:', error);
        }

        //setIsLoading(false);
        setIsManualGenerate(false);
    }, [headingOptions, serviceId]);

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const isOccupiedWard = (ward: Ward, categories: AmenityCategory[]): boolean => {
        if (ward.wardId !== selectedWardId) {
            const item = wards.find((item) => item.wardId === ward.wardId);
            return item ? item.status === true : false;
        }

        return ward.beds.every((bed) => {
            const hasUnrestrictedAmenity = bed.amenities.some((amenity) => {
                const amenityCategory = categories.find((category) => category.amenities.some((a) => a.amenityId === amenity.amenityId));
                return !restrictedCategories.includes(amenityCategory?.amenityCategoryName || '');
            });
            return hasUnrestrictedAmenity;
        });
    };

    const handleNextClick = async (isPreview?: boolean) => {
        if (!allBedsHaveAmenities(wards)) {
            return;
        }
        const payloadWards = wards.map(({ wardId, beds }) => ({
            WardId: wardId,
            Beds: beds.map(({ bedId, amenities }) => ({
                BedId: bedId,
                AmenityIds: amenities.map(({ amenityId }) => amenityId)
            }))
        }));

        const latestHeadings = headingOptionsUnTouched.map((heading: AIHeadingType) => ({ ...heading, isSelected: false }));
        const openAIPayload = latestHeadings.slice(0, 20).map((item) => {
            if (item.airesponseId === selectedHeading?.airesponseId) {
                return {
                    ...item,
                    isSelected: true,
                    generatedResponse: selectedHeading.generatedResponse
                };
            }
            return { ...item };
        });

        const payload = {
            Service_Id: serviceId!,
            Step_Id: 5,
            Wards: payloadWards,
            openairesponse: [...openAIPayload]
        };
        const res = await saveAmenitiesToBed(payload);
        if (res.status === false) {
            showWarning(res?.message);
        } else {
            if (isPreview) {
                showWarning('Changes to amenities/ bed board saved successfully', true);
            }
        }
    };

    const allBedsHaveAmenities = (wards: Ward[]) => {
        const otherWards = wards.filter((ward) => ward.wardId !== selectedWardId).every((item) => item.status === true);
        if (!otherWards) {
            return otherWards;
        }

        return wards.every((ward) =>
            ward.beds.every((bed) => {
                const filteredAmenities = bed.amenities.filter((amenity) => {
                    const category = categories.find((category) => category.amenities.some((a) => a.amenityId === amenity.amenityId && !restrictedCategories.includes(category.amenityCategoryName)));
                    return category !== undefined;
                });
                return filteredAmenities.length > 0;
            })
        );
    };

    const changeServiceHeader = (e: any, item: AIHeadingType | null) => {
        const updatedOptions = headingOptions?.map((option: AIHeadingType) => (option.airesponseId === item?.airesponseId ? { ...option, isSelected: true } : { ...option, isSelected: false }));
        setHeadingOptions(updatedOptions);
        if (item) {
            setSelectedHeading({ ...item });
        }
    };

    const handleAIInputChange = (event: any, newInputValue: string) => {
        if (selectedHeading) {
            if (newInputValue.length > 100) {
                showWarning('The maximum number of characters allowed in the heading is 100.');
                return;
            }
            if (newInputValue.length === 0) {
                const nonEditedHeading = headingOptionsUnTouched.find((heading: AIHeadingType) => heading.airesponseId === selectedHeading.airesponseId);
                if (nonEditedHeading) {
                    setNonEditedSelectedHeading(nonEditedHeading);
                }
                return;
            }
            setSelectedHeading({
                ...selectedHeading,
                generatedResponse: newInputValue
            });

            const updatedOptions = headingOptions.map((option: AIHeadingType) => (option.airesponseId === selectedHeading.airesponseId ? { ...option, generatedResponse: newInputValue } : option));
            setHeadingOptions(updatedOptions);
        }
    };

    const focusHeadingAutocomplete = () => {
        if (headingInputRef.current) {
            headingInputRef.current.focus();
        }
    };
    useImperativeHandle(ref, () => ({
        isValidated: allBedsHaveAmenities(wards),
        backActionHandler: (isPreview?: boolean) => {
            handleNextClick(isPreview);
        },
        nextActionHandler: (isPreview?: boolean) => {
            handleNextClick(isPreview);
        },
        triggerValidation: async () => {
            if (!allBedsHaveAmenities(wards)) {
                showWarning('Please add at least 1 amenity to every bed of the service.');
            }
            return allBedsHaveAmenities(wards);
        }
    }));
    useEffect(() => {
        dispatch(setOnboardingPageValidity(allBedsHaveAmenities(wards)));
    }, [wards]);

    return (
        <div className="pb-115">
            {isLoading && <LoaderContainer />}
            {isManualGenerate && <OnboardingGenerateAnimationLoader showModal={isManualGenerate} previewMessage={t('GenerateHeadings.Ammenities_Header')} />}
            <div className="sec-frame">
                <div className="sec-frame-title">
                    <p>{isFinalScreen ? '1. Edit Heading' : '1. Select a Heading'}</p>
                </div>
                <div>
                    <div className="row">
                        <div className="col-xl-11">
                            <p className="hanzipen-font">
                                Use the AI tool to generate a heading and introduction for your amenities section. This helps create a professional and engaging description.
                            </p>
                            <p className="hanzipen-font">
                                Simply click the <span className="bolder">“Generate"</span> button to get AI-generated suggestions and select the one that best fits your needs.
                            </p>
                            <div className="d-flex align-items-center mt-2">
                                <div className="gradient-select-wrapper">
                                    {/* <select className="gradient-select">
                                        <option>Choose...</option> */}
                                    {/* {headingOptions.slice(0, (generateClickCount + 1) * 10).map((heading, index) => (
                                            <option key={index}>{heading}</option>
                                        ))} */}
                                    {/* {headingOptions.map((heading, index) => (
                                            <option key={index}>{heading}</option>
                                        ))}
                                    </select>
                                    <div className="gradient-select-arrow"></div> */}
                                    {/* <Autocomplete
                                        disablePortal
                                        open={isDropdownOpen} // Control the open state
                                        onOpen={() => setIsDropdownOpen(true)} // When dropdown opens
                                        onClose={() => setIsDropdownOpen(false)} // When dropdown closes
                                        options={headingOptions.slice(0, 20)}
                                        getOptionLabel={(option) => option.generatedResponse}
                                        value={selectedHeading}
                                        isOptionEqualToValue={(option, value) => option.airesponseId === value.airesponseId}
                                        onChange={changeServiceHeader}
                                        renderInput={(params) => (
                                            <TextField
                                                placeholder="Choose..."
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    classes: {
                                                        root: 'gradient-select',
                                                        input: `gradient-select-input`
                                                    }
                                                }}
                                                sx={{
                                                    input: {
                                                        '&::placeholder': {
                                                            opacity: '1 !important',
                                                            color: 'white !important'
                                                        }
                                                    }
                                                }}
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                    /> */}
                                    <Autocomplete
                                        disablePortal
                                        options={headingOptions.slice(0, 20)}
                                        getOptionLabel={(option: AIHeadingType) => option.generatedResponse}
                                        value={selectedHeading}
                                        isOptionEqualToValue={(option, value) => option.airesponseId === value.airesponseId}
                                        onChange={changeServiceHeader}
                                        onInputChange={handleAIInputChange}
                                        renderInput={(params) => (
                                            <TextField
                                                inputRef={headingInputRef}
                                                placeholder="Choose..."
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    classes: {
                                                        root: 'gradient-select',
                                                        input: `gradient-select-input`
                                                    }
                                                }}
                                                sx={{
                                                    input: {
                                                        '&::placeholder': {
                                                            opacity: '1 !important',
                                                            color: 'white !important'
                                                        }
                                                    }
                                                }}
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="ms-3">
                                    <button className="toggle-button max-width-btn border-radius-less variation3" onClick={handleGenerateHeadings}>
                                        <span className="toggle-indicator"></span>
                                        <span className="label">Generate</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-1">
                            <div>
                                <img src={airobot} alt="Place Image" className="img-fluid" width="80" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec-frame mt-4">
                <div className="sec-frame-title">
                    <p>{isFinalScreen ? '2. Edit Bed Board/Amenities' : '2. Add Amenities'}</p>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <p className="hanzipen-font">Properly detailing your amenities helps prospective residents and their families understand the range of services and comfort you provide.</p>
                        <p className="hanzipen-font">
                            <span className="green-text bolder">Green band </span>indicates all bed profiles are complete and the Red band indicates one or more bed profiles are incomplete.
                        </p>
                    </div>
                </div>

                <div className="mt-3">
                    <div className="row m-0">
                        <div className="col-md-12 p-0">
                            <p onClick={handleShowNameWards} className="addwardspreview">
                                Add Wards<i className="fa-solid fa-plus ms-2" aria-hidden="true"></i>
                            </p>
                        </div>
                        <div className="col-xl-2 p-0">
                            <ul className="nav nav-pills facility-pills setupward-scroll" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                {wards.map((ward) => (
                                    <li key={ward.wardId} className="nav-item">
                                        <a
                                            className={`onboarding-vacant-ward d-flex align-items-center justify-content-between nav-link ${ward.wardId === selectedWardId ? 'active' : ''} ${
                                                isOccupiedWard(ward, categories) ? 'onboarding-vacant-ward' : 'onboarding-occupied-ward'
                                            }`}
                                            id={`v-pills-${ward.wardId}-tab`}
                                            data-bs-toggle="pill"
                                            href={`#v-pills-${ward.wardId}`}
                                            role="tab"
                                            aria-controls={`v-pills-${ward.wardId}`}
                                            aria-selected={ward.wardId === selectedWardId}
                                            onClick={() => setSelectedWardId(ward.wardId)}
                                        >
                                            {editingWardId === ward.wardId ? (
                                                <input
                                                    type="text"
                                                    value={newWardName}
                                                    onChange={(e) => {
                                                        if (e.target.value.length <= 100) {
                                                            setNewWardName(e.target.value);
                                                        } else {
                                                            showWarning(t('OnboardingSetupWard.Ward_Name_Length_Warning')!);
                                                        }
                                                    }}
                                                    onBlur={(e) => handleSaveWardName(e, ward.wardId)}
                                                    onKeyDown={(e) => handleKeyDown(e, ward.wardId)}
                                                    className="form-control form-control-sm w-75"
                                                    autoFocus
                                                />
                                            ) : (
                                                <span data-tooltip-id="ward-name-tooltip" data-tooltip-content={ward.wardName} data-tooltip-place="top">
                                                    {ward.wardName.length > 12 ? `${ward.wardName.slice(0, 12)}..` : ward.wardName}
                                                </span>
                                            )}
                                            <span
                                                className="editRoomName"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEditWardName(ward.wardId, ward.wardName);
                                                }}
                                            >
                                                <i className="fa-regular fa-pen-to-square" data-tooltip-id="edit-ward-tooltip" data-tooltip-content="Edit Ward"></i>
                                            </span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-xl-10 p-0">
                            <div className="tab-content onboarding-tab-content position-relative" id="v-pills-tabContent" key={selectedWardId}>
                                {wards.map((ward, index) => (
                                    <div
                                        key={ward.wardId}
                                        className={`tab-pane fade ${ward.wardId === selectedWardId ? 'show active' : ''}`}
                                        id={`#v-pills-${ward.wardId}`}
                                        role="tabpanel"
                                        aria-labelledby={`v-pills-${ward.wardId}-tab`}
                                    >
                                        <div className={`wards-cards-container ${isFinalScreen ? '' : ''}`}>
                                            <div className="setup-slider">
                                                <Slider
                                                    ref={(ref) => (wardSliderRefs.current[index] = ref)}
                                                    {...{ ...sliderSettings, draggable: ward.beds.length > 4, swipeToSlide: ward.beds.length > 4, touchMove: ward.beds.length > 4 }}
                                                >
                                                    {ward.beds.map((bed, index) => (
                                                        <div key={bed.bedId}>
                                                            <WardCard
                                                                bedId={bed.bedId}
                                                                bedName={
                                                                    bed.bedName === null || bed.bedName === undefined || bed.bedName.trim() === '' ? getBedNameWithPrefix(ward, index) : bed.bedName
                                                                }
                                                                amenities={bed.amenities}
                                                                handleModal={handleModal}
                                                                handleDelete={(bedId) => handleDelete(ward.wardId, bedId, bed.bedName, serviceId!)}
                                                                handleBedNameChange={(oldName, newName) => handleBedNameChange(ward.wardId, bed.bedId, newName)}
                                                                handleUpdateAmenities={handleUpdateAmenities}
                                                                wardId={ward.wardId.toString()}
                                                                categories={categories}
                                                                showWarning={showWarning}
                                                                showBedDeleteIcon={showBedDeleteIcon}
                                                                bedPrefix={ward.bedPrefix}
                                                                wardName={ward.wardName}
                                                                index={index}
                                                            />
                                                        </div>
                                                    ))}
                                                </Slider>
                                                <div className="addWardButton" onClick={addBed} data-tooltip-id="my-tooltip" data-tooltip-content="Add Bed" data-tooltip-place="top">
                                                    <i className="fa-solid fa-plus classic-btn ms-2 blue-bg " aria-hidden="true"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ClientPortal selector="myportal" show={showPortal}>
                <div className="overlay" onClick={() => setShowPortal(false)}></div>
                <div className="modal modal-amenities">
                    <div className="header mb-4">
                        <h4 className="mb-0">{selectedBedName}</h4>
                        <div className="search-btn-group w-25 mx-auto position-relative">
                            <input
                                type="text"
                                className="form-control white search-onboarding"
                                placeholder="Search Amenity"
                                aria-label="Search Amenity"
                                aria-describedby="Search Amenity"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                            <i className="fa-sharp fa-solid fa-magnifying-glass onboarding-search-icon"></i>
                        </div>
                        <a onClick={() => setShowPortal(false)} className="removeButton">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="mb-4">
                        <p className="bolder">Tell guests what your place has to offer.</p>
                        <p>You can add more amenities after you publish your listing.</p>
                    </div>
                    <div className="body">
                        <ul className="nav nav-pills amenities-pill mb-2 pb-2 border-bottom" id="pills-tab" role="tablist">
                            {filteredCategories.map((category) => (
                                <li key={category.amenityCategoryId} className="nav-item" role="presentation">
                                    <button className={`nav-link ${selectedCategory?.amenityCategoryId === category.amenityCategoryId ? 'active' : ''}`} onClick={() => handleCategoryClick(category)}>
                                        {category.amenityCategoryName}
                                        <div className="icon ms-2">
                                            <img src={category.amenityCategoryIcon} alt={category.amenityCategoryName} className={'img-fluid'} style={{ width: '22px' }} />
                                        </div>
                                    </button>
                                </li>
                            ))}
                        </ul>
                        <div className="tab-content amenities-pill-conent setup-ward-amenities-popup" id="pills-tabContent">
                            {disclaimerCategories.includes(selectedCategory?.amenityCategoryName ?? '') && (
                                <p className="mb-2 mt-1 disclaimer-text">Disclaimer: These amenities are not specific to a bed. they are specific to service.</p>
                            )}
                            {filteredCategories?.map((category) => (
                                <div
                                    key={category.amenityCategoryId}
                                    className={`tab-pane fade ${selectedCategory?.amenityCategoryId === category.amenityCategoryId ? 'show active' : ''}`}
                                    role="tabpanel"
                                    tabIndex={0}
                                >
                                    <ul className={`list-unstyled amenities-modal-list ${filteredAmenities?.length > 9 ? 'more-than-nine' : ''}`}>
                                        {filteredAmenities
                                            ?.filter((amenity) => category.amenities.some((catAmenity) => catAmenity.amenityId === amenity.amenityId))
                                            .map((amenity) => (
                                                <li className="w-max" key={amenity.amenityId}>
                                                    <div key={amenity.amenityId} className="d-flex align-items-center gap-2">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input filter-input cursor-pointer"
                                                            id={`amenity-${amenity.amenityId}`}
                                                            onChange={() => handleCheckboxChange(amenity.amenityId)}
                                                            checked={selectedAmenities.some((selectedAmenity) => selectedAmenity.amenityId === amenity.amenityId)}
                                                        />
                                                        <label className="ms-2 cursor-pointer" htmlFor={`amenity-${amenity.amenityId}`}>
                                                            <img
                                                                src={amenity.amenityIcon}
                                                                alt="amenity-icon"
                                                                style={{ width: '20px', height: '20px' }}
                                                            />
                                                        <span className='ms-2 vertical-bottom'>{amenity.amenityName}</span>
                                                        </label>
                                                    </div>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                        <div className="d-flex applytoallbeds mt-2 align-items-center">
                            <p>Do you want to apply these amenities to all your beds in all the wards?</p>
                            <div className="applyallswitch">
                                <div className="d-flex">
                                    <input type="checkbox" id="applyToAllBedsCheck" onChange={applyToAllWard} />
                                    <label htmlFor="applyToAllBedsCheck">Toggle</label>
                                </div>
                            </div>
                        </div>
                        <div className="amenitiesToWardSelect mt-3" id="wardSelectionForAmenities">
                            <div className="d-flex flex-wrap">
                                {wards
                                    ?.filter((ward) => ward?.wardId !== selectedWardId)
                                    .map((ward: any) => (
                                        <div key={ward?.wardId} className="me-4 mb-3 d-flex justify-content-start align-items-center">
                                            <input type="checkbox" className="form-input-check me-2" value={ward?.wardId} />
                                            <label>{ward?.wardName}</label>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer pb-0">
                        <div className="d-flex justify-content-center mt-3">
                            <button className="btn primary-btn classic-btn" onClick={handleApply}>
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </ClientPortal>
            <ClientPortal selector="myportal" show={showNameWards}>
                <div className="overlay" onClick={handleShowNameWards}></div>
                <div className="modal modal-collage-image p-0 nhs-body">
                    <div className="header">
                        <a onClick={handleShowNameWards} className="d-flex align-items-center">
                            <i className="fa-solid fa-angle-left back-collage-btn"></i>
                            <h4 className="bolder mb-0 ms-2">Add Wards</h4>
                        </a>
                    </div>
                    <div className="body service-detail-padding">
                        <div className="container-fluid preview-service-fieldset-text float-button-sec-margin">
                            <NameWardsContainer
                                showBottomSection={(keepPopupVisible?: boolean) => {
                                    loadWardList(null);
                                    if (!keepPopupVisible) {
                                        setShowNameWards((prevState) => !prevState);
                                    }
                                }}
                                ref={nameWardPage}
                            />
                        </div>
                    </div>
                    <div className="modal-footer--sticky border-top pt-2 pb-2">
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="btn primary-btn px-5" onClick={handleSaveWards}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </ClientPortal>
            <Tooltip id="my-tooltip" className="my-tooltip" />
            <Tooltip id="ward-name-tooltip" />
            <Tooltip id="edit-ward-tooltip" />
        </div>
    );
});

export default SetupWardView;
