import React from 'react';
import ForgotPasswordView from './ForgotPasswordView';

const ForgotPasswordContainer = () => {
    return (
        <div>
            <ForgotPasswordView/>
        </div>
    );
};

export default ForgotPasswordContainer;