"use client";
import React, { useEffect, useRef, useState } from "react";
//@ts-ignore
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

const OnboardingPreviewTabView = () => {
    const [activeSection, setActiveSection] = useState<string>('overview-section');
    const { serviceName } = useSelector((state: RootState) => state.onboardingService);

    const sections = [
        { id: 'overview-section', label: 'Overview' },
        { id: 'amenities-section', label: 'Amenities' },
        { id: 'service-section', label: 'Services' },
        { id: 'location-section', label: 'Location' },
        { id: 'question-answer-section', label: 'Q&A' },
    ];

    const scrollToSection = (id: string) => {
        const target = document.getElementById(id);
        if (target) {
            window.$utils.scrollToDivById(id, 250);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                let mostVisibleSection = null;
                let highestVisibility = 0;

                entries.forEach((entry) => {
                    if (entry.intersectionRatio > highestVisibility) {
                        highestVisibility = entry.intersectionRatio;
                        mostVisibleSection = entry.target.id;
                    }
                });

                if (mostVisibleSection && mostVisibleSection !== activeSection) {
                    setActiveSection(mostVisibleSection);
                }
            },
            {
                threshold: Array.from({ length: 101 }, (_, i) => i / 100),
            }
        );

        sections.forEach(({ id }) => {
            const section = document.getElementById(id);
            if (section) observer.observe(section);
        });

        return () => observer.disconnect();
    }, [sections, activeSection]);

    useEffect(() => {
        const isSticky = () => {
            const header = document.querySelector('.stickey-header-section');
            const scrollTop = window.scrollY;
            const screenWidth = window.innerWidth;

            if (scrollTop >= 750 && screenWidth >= 1700) {
                header?.classList.add('is-sticky');
            } else if (scrollTop >= 500 && screenWidth <= 1699) {
                header?.classList.add('is-sticky');
            } else {
                header?.classList.remove('is-sticky');
            }
        };

        window.addEventListener('scroll', isSticky);
        return () => window.removeEventListener('scroll', isSticky);
    }, []);

    return (
        <div className="service-detail-padding stickey-header-section">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-lg-4">
                        <p className="servicename-stickey-bar">{serviceName}</p>
                    </div>
                    <div className="col-lg-8 d-flex align-items-center justify-content-end">
                        <ul className="list-unstyled d-flex tab-list header-section">
                            {sections.map(({ id, label }) => (
                                <li key={id}>
                                    <a
                                        onClick={() => scrollToSection(id)}
                                        className={activeSection === id ? 'active' : ''}
                                    >
                                        {label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnboardingPreviewTabView;