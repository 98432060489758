import React from 'react';
import DownloadReferralView from './DownloadReferralView';

const DownloadReferralContainer = (props: any) => {
    return (
        <div>
            <DownloadReferralView onClickHandlerDownloadReferral={props.onClickHandlerDownloadReferral} />
        </div>
    );
};

export default DownloadReferralContainer;