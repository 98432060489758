const ListSliderPaging = ({
  totalItems,
  currentItem,
}: {
  totalItems: number;
  currentItem: number;
}) => {
  const maxDots = 5;

  const getVisibleDots = () => {
    if (totalItems <= maxDots) {
      return Array.from({ length: totalItems }, (_, i) => i);
    }

    if (currentItem < 3) {
      return [0, 1, 2, 3, 4];
    }

    if (currentItem >= totalItems - 3) {
      return Array.from(
        { length: maxDots },
        (_, i) => totalItems - maxDots + i
      );
    }

    return [currentItem - 2, currentItem - 1, currentItem, currentItem + 1, currentItem + 2];
  };

  const visibleDots = getVisibleDots();

  return (
    <div className="slider-dots d-flex align-items-center justify-content-center">
      {visibleDots.map((dotIndex) => (
        <span
          key={dotIndex}
          className={dotIndex === currentItem ? 'active' : ''}
        ></span>
      ))}
    </div>
  );
};

export default ListSliderPaging;
