import React, { useMemo } from 'react';
import {
    MaterialReactTable,
    MRT_TableInstance,
    MRT_FullScreenToggleButton,
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_TablePagination,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToolbarAlertBanner,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_VisibilityState,
    type MRT_RowSelectionState,
    type MRT_GroupingState,
    MRT_Localization
} from 'material-react-table';
import { Box } from '@mui/material';
import PaginationContainer from '../../../components/PaginationComponent/indexContainer';

const AccessRoleBottomView = () => {
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'rolename', //access nested data with dot notation
                header: 'Access Role Name',
                size: 100,
            },
            {
                accessorKey: 'description',
                header: 'Description',
                size: 100,
            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 50,
            },
            {
                accessorKey: 'created_by',
                header: 'Created By',
                size: 100,
            }
        ],
        [],
    );
    const Data = [
        {
            id: 1,
            rolename: "Locate Administrator",
            description: "Manager",
            status: "Active",
            created_by:"Mihir Kabra",
        },
        {
            id: 2,
            rolename: "Service Administrator",
            description: "Manager",
            status: "Active",
            created_by:"Mihir Kabra",
        },
        {
            id: 3,
            rolename: "Referral Manager",
            description: "Manager",
            status: "Active",
            created_by:"Mihir Kabra",
        },
        {
            id: 4,
            rolename: "Service Manager",
            description: "Manager",
            status: "Active",
            created_by:"Mihir Kabra",
        },
        {
            id: 5,
            rolename: "Bed Manager",
            description: "Manager",
            status: "Active",
            created_by:"Mihir Kabra",
        },
        {
            id: 6,
            rolename: "Billing Manager",
            description: "Manager",
            status: "Active",
            created_by:"Mihir Kabra",
        },
        {
            id: 7,
            rolename: "User",
            description: "Manager",
            status: "Active",
            created_by:"Mihir Kabra",
        },
        {
            id: 8,
            rolename: "Billing Manager",
            description: "Manager",
            status: "Active",
            created_by:"Mihir Kabra",
        },
        {
            id: 9,
            rolename: "Bed Manager",
            description: "Manager",
            status: "Active",
            created_by:"Mihir Kabra",
        },
        {
            id: 10,
            rolename: "Service Manager",
            description: "Manager",
            status: "Active",
            created_by:"Mihir Kabra",
        },
    ]
    return (
            <div className="row">
                <div className="col-xl-12">
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="invoice-table">
                                    <MaterialReactTable
                                        columns={columns}
                                        data={Data}
                                        enableGrouping
                                        enableColumnActions={false}
                                        enableBottomToolbar={false}
                                        enableTopToolbar={false}
                                        enableColumnDragging={false}
                                        // getRowId={(row) => row.integrationId}
                                        manualPagination
                                        manualSorting
                                        // rowCount={rowCount}
                                        localization={{ actions: 'Actions' }}
                                        enableRowActions
                                        positionActionsColumn="last"
                                        renderRowActions={({
                                            row
                                        }) => <div className='d-flex'>
                                                <a className="status-icon justify-content-center">
                                                    <i className="fa-regular fa-pen-to-square"></i>
                                                </a>
                                                <a className="align-items-center justify-content-start d-flex grid-switch status-toggle">
                                                    <label className="switch-custom">
                                                        <input title="switch" type="checkbox" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </a>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center mt-3 invoice-table-pagination">
                            <div className="col-lg-6">
                                <div className="d-flex align-items-center">
                                    <p className="purple-text bold">
                                        Displaying 1 - 2 of 10
                                    </p>
                                    <div className="d-flex align-items-center ms-3">
                                        <label className="mb-0 bold nowrap me-2">Page Size:</label>
                                        <select className={`form-control white page-select`} id="supportedPersoIntegrationPageSize">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 ">
                                <PaginationContainer currentPage={1} totalCount={100} pageSize={10} onPageChange={(page: any) => { }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default AccessRoleBottomView;