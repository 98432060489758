"use client";
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import ClientPortal from "../../../../../../components/ClientPortal";
import { useTranslation } from 'react-i18next';
import EditPencil from "../../../../../../assets/images/edit.svg";
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import profile_photo from '../../../../../../assets/images/profile-photo.png';
import airobot from '../../../../../../assets/images/airobot.png';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GlobalConfigurations, OpenAienums, WorkingHoursData } from "../../../../../../utils/constant";
import { WorkingHour, WorkingHourResponse } from "../../../../FaceProfile/faceProfileTypes";
import { getOnboardingCoordinatorData, saveCoordinatorDetails, uploadCoordinatorImageToServer } from "../../../../../../service/onboarding-coordinator-service";
import moment from 'moment-timezone';
import WorkingHours from "../../../../FaceProfile/WorkingHours";
import swal from 'sweetalert';
import SendMessageToService from "./SendMessageToService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { CropperRef, FixedCropper, ImageRestriction } from "react-advanced-cropper";
import imageCompression from 'browser-image-compression';
import { ModulesAccessRights } from "../../../../../../utils/constant";
import LoaderContainer from "../../../../../../components/Loader/loaderContainer";
import Slider from "react-slick";
import { DescribeFacilityType } from "../../../../../../utils/model";
import { generateOpenAiDescription } from "../../../../../../service/onboarding-describeyourfacility-service";
import warning from "../../../../../../assets/images/warning.png"
import { getServiceCoordinatorDetailsByServiceId } from "../../../../../../service/onboarding-preview";
import OnboardingGenerateAnimationLoader from "../../../OnboardingGenerateAnimationLoder";
import { faL } from "@fortawesome/free-solid-svg-icons";
import OnboardingHeaderContainer from "../../../../../../layout/OnboardingHeader/OnboardingHeaderContainer";
import OnboardingPreviewEditModalFooter from "../../../OnboardingPreviewEditModalFooter";
const OnboardingPreviewCoordinatorView = (props: any) => {

    var settings = {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const imageInputRef = useRef<HTMLInputElement>(null);
    const sliderRef = useRef<Slider>(null);
    const headingInputRefs = useRef<Array<HTMLTextAreaElement | null>>([]);

    const [localTimeZone, setLocalTimeZone] = useState<string>("");
    const [remainingData, setRemainingData] = useState({
        workingSince: null,
        // description: null,
        image: null,
        displayContactInfo: false,
        ImagePath: ""
    })
    const [workingHoursData, setWorkingHoursData] = useState<WorkingHour[]>(WorkingHoursData);
    const [PreviewshowPortal3, PreviewsetShowPortal3] = useState(false);
    const { t } = useTranslation();
    const [showPortal, setShowPortal] = useState(false);
    const [showFullMessage, setShowFullMessage] = useState<boolean>(false);
    const [isLoading, setIsloading] = useState(false)
    const [isManualGenerate, setIsManualGenerate] = useState(false);
    const [isAnimationStart, setIsAnimationStart] = useState(false);
    const [selectedImg, setSelectedImg]: any = useState(null)
    const [showSecondPortal, setShowSecondPortal] = useState(false);
    const [descriptionData, setDescriptionData] = useState<any>([]);
    const [tempYear, setTempYear] = useState(null)
    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);
    const { isForPublished } = useSelector((state: RootState) => state.onboardingIsForPublished);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);

    const [showEditPage, setShowEditPage] = useState<any>(DataLogin?.userModel?.modules?.find((item: any) => (item.moduleName === 'OnBoarding')))

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(),
        designation: Yup.string().required(),
        email: Yup.string().trim()
            .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            .email('Email is Invalid')
            .required(),
        displayContactInfo: Yup.boolean().notRequired().nonNullable(),
        ContactNo: Yup.string().when('displayContactInfo', {
            is: (displayContactInfo: boolean) => displayContactInfo === true,
            then: (schema: Yup.StringSchema) =>
                schema.required('Mobile number is required').nonNullable()
                    .matches(/^(?:\+44|0)(?:\d\s?){9}\d$/, {message: 'Please enter a valid Mobile number'}),
            otherwise: (schema: Yup.StringSchema) => schema.notRequired().nullable().matches(/^(?:\+44|0)(?:\d\s?){9}\d$/, {message: 'Please enter a valid Mobile number', excludeEmptyString: true})
        })
    });

    const { register, formState, setValue, getValues, trigger, handleSubmit } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema),
    });
    const { errors, isValid: isFormValid } = formState;
    const [isTimeValid, setIsTimeValid] = useState(false);
    const [isDescValid, setIsDescValid] = useState<"valid" | "no-desc" | "none-selected" | "all-empty">("no-desc");
    const [wordLimitKeyContact, setWordLimitKeyContact] = useState<string | null>(null);

    const [responseData, setResponseData] = useState<any>(null)

    useEffect(() => {
        const isEmptyArr = descriptionData.length === 0;
        if(isEmptyArr){
            setIsDescValid("no-desc");
            return;
        }

        const isAnyDescFilled = descriptionData?.some((item: any) => item.wordCount > 0);
        if(!isAnyDescFilled){
            setIsDescValid("all-empty");
            return;
        }

        const isAnyDescSelected = descriptionData?.some((item: any) => item.isSelected);
        if(!isAnyDescSelected){
            setIsDescValid("none-selected");
            return;
        }
        
        setIsDescValid("valid");
    }, [descriptionData])

    const croppedImg = useRef<string | File>("");



    useEffect(() => {
        AOS.init();
    }, [])

    const handleModal = (event: any) => {
        event.preventDefault();
        setShowPortal((prevState) => !prevState);
    };

    useEffect(() => {
        if (showFullMessage) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showFullMessage]);

    useEffect(() => {
        if (showPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showPortal]);


    const PreviewhandleModal3 = (event: any) => {
        event.preventDefault();
        PreviewsetShowPortal3((prevState) => !prevState);
    };

    useEffect(() => {
        // if (PreviewshowPortal3) {
        //     document.body.classList.add('modal-open');
        // } else {
        //     document.body.classList.remove('modal-open');
        // }
        // return () => {
        //     document.body.classList.remove('modal-open');
        // };
        if (PreviewshowPortal3) {
            setTimeout(() => {
                const isAnyDescSelected = descriptionData?.some((item: any) => item.isSelected);
                if(isAnyDescSelected){
                    sliderRef.current?.slickGoTo(descriptionData.findIndex((item: any) => item.isSelected), true);
                }                        
            }, 10);
        }
    }, [PreviewshowPortal3]);

    useEffect(() => {
        if (showSecondPortal) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [showSecondPortal]);

    useEffect(() => {
        trigger('ContactNo');
    }, [remainingData?.displayContactInfo, trigger]);

    useEffect(() => {
        const fetchCoordinatorData = async () => {
            setIsloading(true)
            await window.$utils.getGlobalConfig(GlobalConfigurations.KeyContactWords, setWordLimitKeyContact);
            const response = await getOnboardingCoordinatorData(serviceId!)
            if (response.status) {
                setResponseData(response)
                setIsloading(false)
                setValue("name", response.data?.serviceCoordinatorDetails?.name ? toTitleCase(response.data?.serviceCoordinatorDetails?.name) : "", {
                    shouldValidate: true
                })
                setValue("designation", response.data?.serviceCoordinatorDetails?.designation ? toTitleCase(response.data?.serviceCoordinatorDetails?.designation) : "", {
                    shouldValidate: true
                })
                setValue("email", response.data?.serviceCoordinatorDetails?.email ? response.data?.serviceCoordinatorDetails?.email : "", {
                    shouldValidate: true
                })
                setValue("ContactNo", response.data?.serviceCoordinatorDetails?.contactNo ? response.data?.serviceCoordinatorDetails?.contactNo : "")

                setRemainingData((prevData) => ({
                    ...prevData,
                    workingSince: response.data?.serviceCoordinatorDetails?.workingSince,
                    description: response.data?.serviceCoordinatorDetails?.serviceDescription,
                    image: response.data?.serviceCoordinatorDetails?.imageUrl,
                    displayContactInfo: response.data?.serviceCoordinatorDetails?.displayContactInfo
                }))
                setValue("displayContactInfo", response.data?.serviceCoordinatorDetails?.displayContactInfo ? response.data?.serviceCoordinatorDetails?.displayContactInfo : false)
                setSelectedImg(response.data?.serviceCoordinatorDetails?.imageUrl,)

                const descriptionArr = response?.data?.generateResponse
                if (descriptionArr.length > 0) {
                    const updatedArr = descriptionArr?.map((item: any) => ({
                        ...item,
                        wordCount: calculateWordCount(item.generatedResponse)
                    }))
                    setDescriptionData(updatedArr)
                }

                if (response.data?.serviceCoordinatorDetails?.timeZone) {
                    setLocalTimeZone(response.data?.serviceCoordinatorDetails?.timeZone)
                } else {
                    setLocalTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
                }

                if (response?.data?.serviceCoordinatorDetails?.workingHours?.length > 0) {
                    const workHours = response.data?.serviceCoordinatorDetails?.workingHours
                    const newArr = workHours?.map((item: WorkingHourResponse) => ({
                        ...item,
                        startTime: item.fromTime,
                        endTime: item.toTime,
                        error: "",
                        active: item.fromTime ? true : false
                    }))
                    setWorkingHoursData(newArr);
                }
            }
            setIsloading(false)
        }
        fetchCoordinatorData()
    }, [])

    const splitFullName = (fullName: any) => {
        if (!fullName || typeof fullName !== 'string') {
            return { firstName: '', lastName: '' };
        }

        const nameParts = fullName.split(' ');
        const firstName = nameParts[0];
        const lastName = nameParts.slice(1).join(' ');

        return { firstName, lastName };
    };

    const handleSeeMoreModal = (event: any) => {
        event.preventDefault();
        setShowFullMessage((prevState) => !prevState);
    }

    const viewMore = () => {
        setShowFullMessage(!showFullMessage);
    };


    const handleUploadImage = async (e: any) => {
        e.preventDefault();


        if (!selectedImg) {

            swal('No file selected.', {
                icon: 'error',
            });
            return;
        }
        const allowedTypes = ['image/jpeg', 'image/png', "image/jpg"];
        const maxSize = 5 * 1024 * 1024;
        if (!allowedTypes.includes(selectedImg.type)) {
            // setImageError('Invalid file type. Only JPEG, PNG, JPG are allowed.');
            swal('Invalid file type. Only JPEG, PNG, JPG are allowed.', {
                icon: 'error',
            });
            return;
        }

        if (selectedImg.size > maxSize) {
            // setImageError('File size exceeds the 5 MB limit.');
            swal('File size exceeds the 5 MB limit.', {
                icon: 'error',
            });
            return;
        }

        const formData = new FormData()

        const file = window.$utils?.base64ToFile(croppedImg.current as string, "ServiceCoordinatorImage");
        const options = {
            maxSizeMB: 5,
            useWebWorker: true
        };

        const compressedBlob = await imageCompression(file, options);
        const compressedFile = new File([compressedBlob], file.name, { type: file.type });

        formData.append("ServiceCoordinatorImage", compressedFile)

        if (file) {
            // const payload = {
            //     ServiceCoordinatorImage: formData
            // }
            setIsloading(true)
            const response = await uploadCoordinatorImageToServer(formData)
            if (response.status) {
                setIsloading(false)
                setShowSecondPortal((prevState) => !prevState);
                setRemainingData((prevData) => ({
                    ...prevData,
                    image: response?.data?.url,
                    ImagePath: response?.data?.documentPath
                }))
                // getServiceCoordinatorDetailsByServiceId(serviceId!)
            } else {
                swal(response.message, {
                    icon: 'error',
                });
                setIsloading(false)
            }
        }
    }
    useEffect(() => {
        setRemainingData((prev: any) => ({
            ...prev,
            workingSince: tempYear ? tempYear : null
        }));
    }, [tempYear])

    const handleDateChange = (date: any) => {
        const formattedDate = date ? date.format('YYYY') : '';
        setTempYear(formattedDate)
    };

    const handleSecondModal = (event: any) => {
        event.preventDefault();

        setShowSecondPortal((prevState) => !prevState);
    };

    function calculateWordCount(text: string) {
        if(text.trim().length === 0){
            return 0;
        }
        return text?.trim().split(/\s+/).length;
    }

    const handleImageCropChange = (cropper: CropperRef) => {
        const lCroppedImg = cropper?.getCanvas()?.toDataURL();
        if (lCroppedImg) {
            croppedImg.current = lCroppedImg;
        }
    };

    const getImageUrl = useCallback((img: string | File | FileList): string => {
        if (typeof img === "string") {
            return img;
        } else if (img instanceof File) {
            return URL.createObjectURL(img);
        } else if (img instanceof FileList && img.length > 0) {
            return URL.createObjectURL(img[0]);
        }
        return "";
    }, [selectedImg])

    const getImageFiletype = (img: string | File | FileList) => {
        if (typeof img === "string") {
            return true;
        } else {
            return false
        }
    }

    const handleUploadClick = () => {
        if (imageInputRef.current) {
            imageInputRef.current.click();
        }
    };

    const handleActiveDescription = (id: number) => {
        const updatedData = descriptionData.map((item: any) => ({
            ...item,
            isSelected: item.airesponseId === id
        }));
        setDescriptionData(updatedData);
    };


    const handleChangeDescription = (event: any, descriptionId: number) => {
        const newDescription = event.target.value;
        const wordCount = calculateWordCount(newDescription);

        if (wordCount <= parseInt(wordLimitKeyContact!)) {
            const updatedDescriptions = descriptionData.map((item: any) => {
                if (item.airesponseId === descriptionId) {
                    return { ...item, isSelected: item.isSelected && wordCount > 0, generatedResponse: newDescription, wordCount };
                }
                return item;
            });
            setDescriptionData(updatedDescriptions);
        }
    };

    const handleGenerateOpenAi = async () => {
        setIsAnimationStart(false)
        if (!(isTimeValid && isFormValid)) {
            swal({
                title: '',
                text: "Please fill the required fields to generate a message.",
                icon: "warning",
                dangerMode: true
            })
            return;
        }
        const workingHours = workingHoursData?.map(({ startTime, endTime, error, active, ...rest }) => ({...rest, fromTime: startTime, toTime: endTime}));
        const finalAiResponse = descriptionData?.map(({ wardCount, ...rest }: any) => rest)
        const payload = {
            ...getValues(),
            ...remainingData,
            serviceId: serviceId,
            timeZone: localTimeZone,
            workingHours: workingHours,
            generateResponse: finalAiResponse
        }
        const params: DescribeFacilityType = {
            serviceId: serviceId!,
            pageId: OpenAienums.KeyContact
        }
        //setIsloading(true)
        setIsManualGenerate(true)
        const response = await generateOpenAiDescription(params, payload)
        if (response?.status) {
            //setIsloading(false)
            setIsManualGenerate(false)
            // swal({
            //     title: '',
            //     text: response.message,
            //     icon: "success",
            //     dangerMode: true
            // })
            const descriptionArr = response?.data
            const updatedArr = descriptionArr?.map((item: any) => ({
                ...item,
                wordCount: calculateWordCount(item?.generatedResponse)
            })).reverse()
            setDescriptionData(updatedArr)
            sliderRef.current?.slickGoTo(updatedArr.length);
            setTimeout(() => {
                sliderRef.current?.slickGoTo(updatedArr.length);
                setTimeout(() => {
                    setIsAnimationStart(true)
                }, updatedArr.length !== 1 ? 150 : 0);
                
            }, updatedArr.length !== 1 ? 100 : 0);
            // setClickCount((prev) => prev + 1)
        } else {
            //setIsloading(false)
            setIsManualGenerate(false)
            swal({
                title: '',
                text: response.message,
                icon: "error",
                dangerMode: true
            })
        }

    }

    const handleSubmitCoordinatorPreviewData = async (data: any) => {
        if (!(isFormValid && isTimeValid)) {
            swal({
                title: '',
                text: "Please fill required fields.",
                icon: "warning",
                dangerMode: true
            })
            return;
        }
        if (isDescValid !== 'valid') {
            if (isDescValid === "no-desc") {
                swal({
                    title: '',
                    text: "Message for Viewers is required. Please generate and click on Use this to select one.",
                    icon: "warning",
                    dangerMode: true
                })
                return;
            }
            if (isDescValid === "all-empty") {
                swal({
                    title: '',
                    text: "Message for Viewers is required. Please enter a message and click on Use this to select.",
                    icon: "warning",
                    dangerMode: true
                })
                return;
            }
            if (isDescValid === "none-selected") {
                swal({
                    title: '',
                    text: "Message for Viewers is required. Please click on Use this to select one.",
                    icon: "warning",
                    dangerMode: true
                })
                return;
            }
        }

        const workingHours = workingHoursData?.map(({ startTime, endTime, error, active, ...rest }) => ({...rest, fromTime: startTime, toTime: endTime}));
        const finalAiResponse = descriptionData?.map(({ wordCount, ...rest }: any) => rest)
        const payload = {
            ...data,
            ...remainingData,
            serviceId: serviceId,
            timeZone: localTimeZone,
            workingHours: workingHours,
            generateResponse: finalAiResponse
        }

        swal({
            title: '',
            text: "Do you want save the data?",
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: boolean) => {
            if (confirm) {
                setIsloading(true)
                const response = await saveCoordinatorDetails(payload)
                if (response.status) {
                    // setIsloading(false)
                    setIsloading(false)
                    swal({
                        title: '',
                        text: response.message,
                        icon: "success",
                        // buttons: ['No', 'Yes'],
                        dangerMode: true
                    }).then((confirm: boolean) => {
                        if (confirm) {
                            PreviewsetShowPortal3((prevState) => !prevState);

                        }
                    })
                    // getServiceCoordinatorDetailsByServiceId(serviceId!)
                    props.getAllServices()
                } else {
                    swal({
                        title: '',
                        text: response.message,
                        icon: "error",
                        // buttons: ['No', 'Yes'],
                        dangerMode: true
                    })
                    setIsloading(false)

                }
            }
        })
        // setIsloading(true)

        // setIsloading(false)
    }

    const { firstName, lastName } = splitFullName(props?.serviceCoordinatorDetails?.name);

    // const maxLength = 100;
    // const isTruncated = coordinatorData?.serviceCoordinatorMessage && coordinatorData?.serviceCoordinatorMessage.length > maxLength;
    // const truncatedMsg = isTruncated ? coordinatorData?.serviceCoordinatorMessage.substring(0, maxLength) + "..." : coordinatorData?.serviceCoordinatorMessage;
    // if (!isLoading) {
    //     return <OnboardingGenerateAnimationLoader showModal={!isLoading}  previewMessage={t('GenerateHeadings.Facility_Description')}/>
    // }

    const focusHeadingAutocomplete = (index: number) => {
        if (headingInputRefs && headingInputRefs?.current[index]) {
            headingInputRefs.current[index]!.focus();
            headingInputRefs.current[index]!.setSelectionRange(headingInputRefs.current[index]!.value.length, headingInputRefs.current[index]!.value.length);
            headingInputRefs.current[index]!.scrollTop = headingInputRefs.current[index]!.scrollHeight;
        }
    };

    const toTitleCase = (value: string) => {
        return value
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const TypewriterText = ({ item, index, typingSpeed = 100 }: { item: any; index: number; typingSpeed?: number }) => {
        const [displayedText, setDisplayedText] = useState('');
        const [isTypingComplete, setIsTypingComplete] = useState(false);

        const charsPerSecond = Math.ceil(item?.generatedResponse.lenght/typingSpeed)

        useEffect(() => {
          let index = 0;
          const intervalId = setInterval(() => {
            setDisplayedText((prev) => prev + item?.generatedResponse[index]);
            index++;
      
            if (index >= item?.generatedResponse.length) {
              clearInterval(intervalId);
              setIsTypingComplete(true); 
              setIsAnimationStart(false);              
            }
          }, charsPerSecond);
      
          // Clean up the interval when the component unmounts
          return () => clearInterval(intervalId);
        }, [item?.generatedResponse, typingSpeed]);

        useEffect(() => {
            if (isTypingComplete) {
                setTimeout(() => {
                    focusHeadingAutocomplete(descriptionData.length - 1)
                }, 100);
            }
        }, [isTypingComplete])
      
        return (
            <div className='generatedText generatedTextSmallHeight mb-4'>
                <textarea
                    ref={(ref) => (headingInputRefs.current[index] = ref)}
                    className="text-justify desc-textarea hideTextarea form-control mb-4"
                    name=""
                    id=""
                    value={item?.generatedResponse}
                    onChange={(event) => handleChangeDescription(event, item?.airesponseId)}
                />
            <div className='textareaAnimation '>
                {displayedText} {!isTypingComplete && <span className="dot-pulse"></span>}
            </div>
            
            </div>
          );
      };
    
    return (
        <div>
            <SendMessageToService showPortal={showPortal} setShowPortal={setShowPortal} handleModal={handleModal} coordinatorData={props?.serviceCoordinatorDetails} firstName={firstName} lastName={lastName} ServiveName={props?.serviceCoordinatorDetails?.serviceName} />

            {/* <div className="d-flex small-title align-items-center preview-small-title">
                <h4 className="indigo-text bolder mb-0" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                    {t('Overview.Meet_Your_Coordinator')}
                </h4>

                {isForPublished ? showEditPage?.userAccess?.some((a: any) => a.accessCode === ModulesAccessRights.Onboarding.AccreditationsAccess.accessCode) ?
                    <div className="edit-icon-preview ms-2 cursor-pointer" onClick={PreviewhandleModal3} data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                        <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                    </div>

                    :
                    <></>
                    :
                    <div className="edit-icon-preview ms-2 cursor-pointer" onClick={PreviewhandleModal3} data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                        <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                    </div>}
            </div> */}
            <div className="bg-gray coordinator-box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="400" data-aos-once="true">
                <div className="">
                    {
                        isForPublished ? showEditPage?.userAccess?.some((a: any) => a.accessCode === ModulesAccessRights.Onboarding.AccreditationsAccess.accessCode) ?
                            <div className="absolute-edit edit-icon-preview ms-2 cursor-pointer" onClick={PreviewhandleModal3} data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                                <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                            </div>
                            :
                            <></>
                            :
                            <div className="absolute-edit edit-icon-preview ms-2 cursor-pointer" onClick={PreviewhandleModal3} data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                                <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                            </div>
                    }

                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="text-center">
                                <div className="overview-profile-img">
                                    {/* {props?.serviceCoordinatorDetails?.imageUrl && <img src={props?.serviceCoordinatorDetails?.imageUrl} alt="Profile Image" width={100} />} */}
                                    <img src={props?.serviceCoordinatorDetails?.imageUrl ? props?.serviceCoordinatorDetails?.imageUrl : profile_photo} alt="Profile Image" width={100} />
                                </div>
                                <h6 className="mt-2 mb-1 lead bold">
                                    {firstName} {lastName}
                                </h6>
                                <p className="bold">{props?.serviceCoordinatorDetails?.designation}</p>
                                <p className="mt-4 hanzipen-font thanksmsg">{props?.serviceCoordinatorDetails?.serviceCoordinatorMessage}</p>
                                {/* <p className="mt-3 hanzipen-font thanksmsg">Feel free to contact me anytime between 9am to 5 pm (Mon – Fri) on <a href="mailto:julia@cloud.com">julia@cloud.com</a> or give me a shout on <a href="tel:9827405968">9827405968</a></p> */}
                            </div>

                        </div>
                        {/* <div className="col-lg-6 col-md-6">
                            <div className="coordinator-text border-bottom mb-0">
                                <span>{props?.serviceCoordinatorDetails?.yearsAt}</span>
                                <p className="bold">Years at {props?.serviceCoordinatorDetails?.serviceName}</p>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                    <a href="#" onClick={handleModal} className="btn primary-btn btn-overview mb-0">
                        Message Me
                    </a>

                </div>
            </div>


            <ClientPortal selector="myportal" show={PreviewshowPortal3}>
                {isLoading && <LoaderContainer />}
                {/* {isManualGenerate && <OnboardingGenerateAnimationLoader showModal={isManualGenerate} previewMessage={t('GenerateHeadings.Introduce_Keycontact')} />} */}
                <div className="overlay" onClick={PreviewhandleModal3}></div>
                <div className="modal modal-collage-image p-0 nhs-body">
                    <div>
                        <OnboardingHeaderContainer manageProfileValidationType='edit'/>
                    </div>
                    <div className="body service-detail-padding preview-service-fieldset-text pt-1 pb-5">
                        <div className="container-fluid p-0">
                            <div className="profileInfo float-button-sec-margin">
                                <div className="row">
                                    <div className="col-md-4 d-flex profileInfoChild">
                                        <div className="sec-frame">
                                            <div className="sec-frame-title">
                                                <p>1. Key Contact</p>
                                            </div>
                                            <div className="profileinfoblock d-flex flex-column justify-content-center mb-0">
                                                <div className="profilepic-upload text-center mb-5">
                                                    <label htmlFor="fileToUpload">
                                                        <div className="profile-pic mb-4 position-relative" onClick={(e) => handleSecondModal(e)}>
                                                            <img src={remainingData.image ? remainingData.image : profile_photo} alt="Place Image" className="img-fluid" />
                                                            {/* <input type="File" name="fileToUpload" id="fileToUpload" onChange={handleUploadImage} /> */}
                                                            {/* {imageError ? <p style={{ color: "red", fontSize: "10px" }}>{imageError}</p> : null} */}
                                                        </div>
                                                        <h6 className="hanzipen-font">
                                                            Add a Photo <br /> Make it more personable
                                                        </h6>
                                                    </label>
                                                </div>
                                                <div className="neumorphic-fieldset mb-3">
                                                    <label htmlFor="name">
                                                        <span className="label-style">Name*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors?.name ? 'is-invalid' : ''}`}
                                                        {...register('name', {
                                                            onChange: (e) => {
                                                                const value = toTitleCase(e.target.value);
                                                                e.target.value = value;
                                                                return e;
                                                            }
                                                        })}
                                                        onBlur={() => {
                                                            trigger('name');
                                                        }}
                                                    />

                                                </div>
                                                <div className="neumorphic-fieldset mb-3">
                                                    <label htmlFor="name">
                                                        <span className="label-style">Designation*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors?.designation ? 'is-invalid' : ''}`}
                                                        {...register('designation', {
                                                            onChange: (e) => {
                                                                const value = toTitleCase(e.target.value);
                                                                e.target.value = value;
                                                                return e;
                                                            }
                                                        })}
                                                        onBlur={() => trigger('designation')}
                                                    />

                                                </div>
                                                <div className="neumorphic-fieldset mb-3">
                                                    <label htmlFor="name">
                                                        <span className="label-style">Working here since</span>
                                                    </label>

                                                    <div className='form-control divinput'>
                                                        <DatePicker format="YYYY"
                                                            disableFuture
                                                            views={['year']}
                                                            slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                                            value={remainingData.workingSince ? moment(remainingData.workingSince, 'YYYY') : null}
                                                            onChange={handleDateChange}
                                                            onClose={() => {
                                                                setRemainingData((prevData) => ({
                                                                    ...prevData,
                                                                    workingSince: tempYear ? Number(tempYear) : responseData?.data?.serviceCoordinatorDetails?.workingSince
                                                                }));
                                                            }}
                                                            onOpen={() => {
                                                                setRemainingData((prevData) => ({
                                                                    ...prevData,
                                                                    workingSince: null,
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 profileInfoChild">
                                        <div className="row mb-3">
                                            <WorkingHours localTimeZone={localTimeZone} setLocalTimeZone={setLocalTimeZone} workingHoursData={workingHoursData} setWorkingHoursData={setWorkingHoursData} setIsValid={setIsTimeValid} />
                                            <div className="col-md-6 d-flex flex-column ">
                                                <div className="sec-frame h-100">
                                                    <div className="sec-frame-title">
                                                        <p> 3. Contact Details </p>
                                                    </div>
                                                    <div className="key-contact-fields">
                                                        <div className="mb-3">
                                                            <div className="neumorphic-fieldset">
                                                                <label htmlFor="keyEmail">
                                                                    <span className="label-style">Email*</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className={`form-control ${errors?.email ? 'is-invalid' : ''}`}
                                                                    id="keyEmail"
                                                                    {...register('email')}
                                                                    onBlur={() => trigger('email')}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <div className="neumorphic-fieldset">
                                                                <label htmlFor="keyMob">
                                                                    <span className="label-style">Mobile{remainingData?.displayContactInfo && '*'}</span>
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    className={`form-control ${errors?.ContactNo ? 'is-invalid' : ''}`}
                                                                    id="keyMob"
                                                                    {...register('ContactNo')}
                                                                    onBlur={() => trigger('ContactNo')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-4">
                                                        <div className="info-text-p ">
                                                            <p className="hanzipen-font-bold">Indicate if you want to display the key contact’s mobile and email.</p>
                                                        </div>
                                                        <div className="">
                                                            <div className="d-flex justify-content-end">
                                                                <div className="anim-switch">
                                                                    <label className="switchts">
                                                                        <input type="checkbox" onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                                                setRemainingData({ ...remainingData, displayContactInfo: e.target.checked })
                                                                                setValue("displayContactInfo", e.target.checked)
                                                                                if(!e.target.checked){
                                                                                    trigger('ContactNo')
                                                                                }
                                                                            }} checked={remainingData?.displayContactInfo} />
                                                                        <div className="sliderts">
                                                                            <div className="circlets">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="10"
                                                                                    height="10"
                                                                                    className="checkmark"
                                                                                    enable-background="new 0 0 512 512"
                                                                                    viewBox="0 0 24 24"
                                                                                >
                                                                                    <path
                                                                                        fill="currentColor"
                                                                                        d="M9.707 19.121a.997.997 0 01-1.414 0l-5.646-5.647a1.5 1.5 0 010-2.121l.707-.707a1.5 1.5 0 012.121 0L9 14.171l9.525-9.525a1.5 1.5 0 012.121 0l.707.707a1.5 1.5 0 010 2.121z"
                                                                                        data-original="#000000"
                                                                                    ></path>
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-10">
                                                <div className="sec-frame">
                                                    <div className="sec-frame-title">
                                                        <p>4. Message for Viewers</p>
                                                    </div>
                                                    {/* <div className="message-box gradient-bg position-relative message-slider">
                                                        <OwlCarousel
                                                            className="owl-theme all-owl owl-carousel"
                                                            dots={false}
                                                            margin={0}
                                                            mouseDrag={true}
                                                            touchDrag={true}
                                                            nav={true}
                                                            smartSpeed={800}
                                                            items={1}
                                                        >
                                                            <div className='reprase-item'>
                                                                <div className="position-relative">
                                                                    <textarea className="form-control message-textarea">
                                                                        Add a personalized message for patients. This can be a welcoming note or any relevant information you want to share.
                                                                    </textarea>
                                                                    <span className="fieldset-fixed-textarea">10/30 words</span>
                                                                </div>
                                                            </div>
                                                            <div className='reprase-item'>
                                                                <div className="position-relative">
                                                                    <textarea className="form-control message-textarea">
                                                                        Add a personalized message for patients. This can be a welcoming note or any relevant information you want to share.
                                                                    </textarea>
                                                                    <span className="fieldset-fixed-textarea">10/30 words</span>
                                                                </div>
                                                            </div>
                                                            <div className='reprase-item'>
                                                                <div className="position-relative">
                                                                    <textarea className="form-control message-textarea">
                                                                        Add a personalized message for patients. This can be a welcoming note or any relevant information you want to share.
                                                                    </textarea>
                                                                    <span className="fieldset-fixed-textarea">10/30 words</span>
                                                                </div>
                                                            </div>
                                                        </OwlCarousel>
                                                    </div> */}
                                                    <div className="message-box gradient-bg position-relative description-slider">
                                                        {
                                                            descriptionData?.length === 0 && (
                                                                <>
                                                                    <div className="default-desc-box">
                                                                        <p className="text-justify mb-3">Click on Generate to generate the description</p>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            descriptionData?.length === 1 && (
                                                                <>
                                                                    {descriptionData?.map((item: any, index: number) => (
                                                                        <div className="position-relative" key={item?.airesponseId}>
                                                                            <div className="description-item ">
                                                                                
                                                                                {isAnimationStart ? (
                                                                                    <TypewriterText 
                                                                                    item={item} 
                                                                                    index={index}
                                                                                    typingSpeed={5} />
                                                                                ) : (
                                                                                    <textarea
                                                                                    ref={(ref) => (headingInputRefs.current[index] = ref)}
                                                                                    className="text-justify desc-textarea form-control mb-4 msg-textarea"
                                                                                    name=""
                                                                                    id=""
                                                                                    value={item?.generatedResponse}
                                                                                    onChange={(event) => handleChangeDescription(event, item?.airesponseId)}
                                                                                    />
                                                                                )}
                                                                                <div className="d-flex justify-content-between">
                                                                                    <div className="me-2">
                                                                                        <div className="desc-count-btn">{item.wordCount}/{wordLimitKeyContact + " words"}</div>
                                                                                    </div>
                                                                                    {item?.wordCount > 0 && <div className="position-relative use-this-btn">
                                                                                        <input
                                                                                            className=""
                                                                                            type="radio"
                                                                                            checked={item.isSelected}
                                                                                            name="description"
                                                                                            id=""
                                                                                            onChange={() => handleActiveDescription(item?.airesponseId)}
                                                                                        />
                                                                                        <button className={`btn primary-btn ${item.isSelected ? "useThisBtn" : ""} `} >
                                                                                            <span>Use this</span>
                                                                                        </button>
                                                                                    </div>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            )
                                                        }
                                                        {descriptionData?.length > 1 ? (
                                                            <Slider {...settings} ref={sliderRef}>
                                                                {descriptionData?.map((item: any, index: number) => (
                                                                    <div className="position-relative" key={item?.airesponseId}>
                                                                        <div className="description-item ">
                                                                            
                                                                            {isAnimationStart ? (
                                                                                    <TypewriterText 
                                                                                    item={item} 
                                                                                    index={index}
                                                                                    typingSpeed={5} />
                                                                                ) : (
                                                                                    <textarea
                                                                                    ref={(ref) => (headingInputRefs.current[index] = ref)}
                                                                                    className="text-justify desc-textarea form-control mb-4 msg-textarea"
                                                                                    name=""
                                                                                    id=""
                                                                                    value={item?.generatedResponse}
                                                                                    onChange={(event) => handleChangeDescription(event, item?.airesponseId)}
                                                                                    />
                                                                            )}
                                                                            <div className="d-flex justify-content-between">
                                                                                <div className="me-2">
                                                                                    <div className="desc-count-btn">{item.wordCount}/{wordLimitKeyContact + " words"}</div>
                                                                                </div>
                                                                                <div className='d-flex align-items-center justify-content-center'>
                                                                                    <div className="desc-count-btn me-2">{index + 1 + "/" + descriptionData.length}</div>
                                                                                    {item?.wordCount > 0 && <div className="position-relative use-this-btn">
                                                                                        <input
                                                                                            className=""
                                                                                            type="radio"
                                                                                            checked={item.isSelected}
                                                                                            name="description"
                                                                                            id=""
                                                                                            onChange={() => handleActiveDescription(item?.airesponseId)}
                                                                                        />
                                                                                        <button className={`btn primary-btn ${item.isSelected ? "useThisBtn" : ""} `} >
                                                                                            <span>Use this</span>
                                                                                        </button>
                                                                                    </div>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </Slider>
                                                        ) : (
                                                            null
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-2 d-flex align-items-end">
                                                <div>
                                                    <div className="robot-animation mt-2">
                                                        <img src={airobot} className="img-fluid" alt="airboat" width={80} />
                                                    </div>
                                                    <button className="selectob-btn orange-bg mt-3 w-100">
                                                        <span>Generate</span>
                                                    </button>
                                                </div>
                                            </div> */}
                                            <div className="col-md-2 d-flex align-items-end">
                                                <div>
                                                    <div className="robot-animation mt-2">
                                                        <img src={airobot} className="img-fluid" alt="airboat" width={80} />
                                                    </div>
                                                    <div className="mt-3">
                                                        <button className={`toggle-button max-width-btn variation3 ${(isManualGenerate || isAnimationStart) ? 'disabled' : ''}`} disabled={(isManualGenerate || isAnimationStart)} onClick={(isManualGenerate || isAnimationStart) ? ()=>{} : handleGenerateOpenAi}>
                                                            <span className="toggle-indicator"></span>
                                                            <span className="label">Generate</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OnboardingPreviewEditModalFooter
                        backOnClick={PreviewhandleModal3} 
                        saveOnClick={handleSubmit(handleSubmitCoordinatorPreviewData, handleSubmitCoordinatorPreviewData)}
                        saveDisabled={isLoading}
                        saveType="submit"
                    />
                </div>
            </ClientPortal>
            <ClientPortal selector="myportal" show={showSecondPortal}>
                <div className="overlay" onClick={handleSecondModal}></div>
                <div className="modal userInfoModal">
                    <div className="header">
                        <h4 className="mb-0"></h4>
                        <a onClick={handleSecondModal} className="removeButton">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 384 512"
                            >
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body">
                        <p className="mb-3 text-center hanzipen-font-bold">Please upload Service Coordinator image here</p>
                        <form className="popup-box" >
                            <div className="row">
                                <div className="col-md-12 ">
                                    <div className="img-upload-box mb-4 d-flex align-items-center justify-content-center">
                                        <div className="text-center upload-logo-here">
                                            {selectedImg && showSecondPortal ? (
                                                <div style={{ height: "250px", width: "300px", marginBottom: "20px" }}>
                                                    {!getImageFiletype(selectedImg) ? <FixedCropper
                                                        src={getImageUrl(selectedImg)}
                                                        stencilSize={{ width: 300, height: 300 }}
                                                        stencilProps={{
                                                            handlers: false,
                                                            lines: false,
                                                            movable: false,
                                                            resizable: false
                                                        }}
                                                        onChange={handleImageCropChange}
                                                        imageRestriction={ImageRestriction.stencil}
                                                        className={'cropper'}
                                                    /> : <img style={{ height: "250px", width: "300px", marginBottom: "20px" }} src={selectedImg.toString() || profile_photo}></img>}
                                                </div>
                                            ) : (
                                                <>
                                                    <i className="fa-regular fa-image mb-3" onClick={handleUploadClick}></i>
                                                    <p className="mb-4">We recommend uploading or dragging in an image that is 300x300 pixels</p>
                                                </>
                                            )}
                                            <div className="position-relative upload-btn-field">
                                                <button className="btn classic-btn small-btn upload-btn">Upload from your computer</button>
                                                <input
                                                    type="file"
                                                    ref={imageInputRef}
                                                    className="upload-file-input"
                                                    onChange={(e: any) => {
                                                        if (e.target.files && e.target.files?.length > 0) {
                                                            setSelectedImg(e.target.files?.[0])
                                                            // setRemainingData((prevData) => ({
                                                            //     ...prevData,
                                                            //     image: getImageUrl(e.target.files?.[0])
                                                            // }))
                                                            const reader = new FileReader();
                                                            reader.onloadend = function () {
                                                                const base64String = reader?.result;
                                                                croppedImg.current = base64String as string;
                                                            };
                                                            reader.readAsDataURL(e.target.files?.[0]);
                                                        }
                                                    }}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex justify-content-center">
                                    <button className="btn primary-btn classic-btn" onClick={handleUploadImage}  >Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </ClientPortal>
            <ClientPortal selector="myportal" show={showFullMessage}>
                <div className="overlay" onClick={handleSeeMoreModal}></div>
                <div className="modal">
                    <div className="header mb-3">
                        <h4 className="mb-0">Test's Message</h4>
                        <a onClick={handleSeeMoreModal} className="removeButton">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 384 512"
                            >
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body">
                        <p>{props?.serviceCoordinatorDetails?.serviceCoordinatorMessage}</p>
                    </div>
                </div>
            </ClientPortal>
        </div>
    );
};

export default OnboardingPreviewCoordinatorView;
