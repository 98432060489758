import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PatientDetailContainer from '../../../../components/PatientDetailModal/indexContainer';
import SmartSearchDropdownContainer from '../../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import RenderModalContainer from '../../../../components/RenderModal/indexContainer';
import { Tooltip } from 'react-tooltip';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getInvoiceData, getInvoicePatientData, getinvoiceDocument } from '../../../../service/invoicedetail-service';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import dayjs from 'dayjs';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState, store } from '../../../../store';
import { API_ENDPOINT, BASEURLDEV } from '../../../../service/ApiConstants';
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const InvoiceDetailFormView = (props: any) => {

    let [invoicedetails, setinvoicedetails] = useState<any>();
    let [isservicedetail, setisservicedetail] = useState<boolean>(false);
    let [patientSmartSearch, setPatientSmartSearch] = useState<any>(undefined);
    let [funderSmartSearch, setFunderSmartSearch] = useState<any>(undefined);
    const [file, setFiles] = useState<any[]>([]);
    const [periodfrom, setperiodfrom] = useState<any>(null);
    const [periodto, setperiodto] = useState<any>(null);
    const [invoicedate, setinvoicedate] = useState<any>(null);
    const [duedate, setduedate] = useState<any>(null);
    const [minperiodfrom, setminperiodfrom] = useState<any>(null);
    const [invoicemindate, setinvoicemindate] = useState<any>(null);
    const [minDate, setmindate] = useState<any>('');
    let [patientid, setpatientid] = useState<any>();
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const [dispvalue, setdispvalue] = useState<boolean>(false);
    const state = store.getState();
    const token = state.login.DataLogin.jwtToken;
    const validationSchema = Yup.object().shape({
        Patient: Yup.string().required(),
        nhs: Yup.string().trim().strict(true).notRequired(),
        funder: Yup.string().notRequired(),
        funderadd: Yup.string().trim().strict(true).nonNullable().notRequired(),
        periodfrom: Yup.date().required(),
        periodto: Yup.date().required(),
        invoicedate: Yup.date().required(),
        duedate: Yup.date().notRequired(),
        paymentref: Yup.string().notRequired(),
        contractPDF: Yup.mixed().notRequired(),
        invoiceContract: Yup.string().notRequired(),
        notes: Yup.string().strict(true).notRequired()
    });
    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [fileres, setFileres] = useState<any>('');
    const handleClickModal = () => {
        setShowModal(true);
    };
    const toggle = () => {
        setShowModal(false);
    };

    const select = document.getElementById('Patient');

    select?.addEventListener('change', function handleChange(event: any) {
        setdispvalue(true);
    });
    select?.addEventListener('focus', function handleChange(event: any) {
        setdispvalue(false);
        //setValue('Patient','');
        select.blur();
    });

    useEffect(() => {
        if (props.resetform) {
            setValue('periodfrom', '');
            setperiodfrom('');
            setValue('periodto', '');
            setperiodto('');
            setValue('invoicedate', '');
            setinvoicedate('');
            setValue('duedate', undefined);
            setduedate(undefined);
            props.updateformdata();
        }
    }, [props.resetform])
    useEffect(() => {
        
        if (formState.isValid) {
            props.isdatavalid(true);
        } else {
            props.isdatavalid(false);
        }
    }, [formState]);
    async function invoicePatientData(id: any) {
        const result = await getInvoicePatientData(Number(id));
        if (result.status) {
            setinvoicedetails(result.data);
            if (result?.data?.admissionDetail?.admissionDate !== '') {
                setmindate(dayjs(window.$utils.dateFormat(result?.data?.admissionDetail?.admissionDate)));
            }
        }
    }

    useEffect(() => {
        if (props.invoicedata !== undefined) {

            setinvoicedetails(props.invoicedata);
        } else {
            invoicePatientData(props.patientid);
        }
    }, [props.invoicedata, props.patientid]);

    useEffect(() => {
        if (props.invoicefunderdetails !== undefined) {

        }
    }, [props.invoicefunderdetails]);

    useEffect(() => {
        if (props.invoicedata !== undefined) {
            if (props?.invoicedata?.admissionDetail?.admissionDate !== '') {
                setmindate(dayjs(window.$utils.dateFormat(props?.invoicedata?.admissionDetail?.admissionDate)));
            }
            setdispvalue(true);
            if (props.patientid !== undefined && props.patientdata !== undefined) {
                setValue('Patient', props.patientid.toString(), { shouldValidate: true });
                if (props.funderdata !== undefined) {
                    setValue('funder', props.invoicedata?.invoiceModel?.funderId !== null ? props.invoicedata?.invoiceModel?.funderId : -12, { shouldValidate: true });
                }
                setValue('invoiceContract', props.invoicedata?.invoiceModel?.documentPath);
                setFileres(props.invoicedata?.invoiceModel?.documentPath !== null ? props.invoicedata?.invoiceModel?.documentPath : '');
                // setValue('nhs', props.invoicedata?.patientDetail?.nhsId, { shouldValidate: true });
                props.patientdata.filter((data: any) => {
                    if (data.patientId === Number(props.patientid)) {
                        if (data.nhS_Id !== '') {
                            setValue('nhs', data.nhS_Id);
                        } else {
                            setValue('nhs', data.local_Id);
                        }
                    }
                });
                setValue('funderadd', props.invoicedata?.invoiceModel?.funderAddress, { shouldValidate: true });
                if (props.duplicateStatus === false) {
                    setValue('periodfrom', props.invoicedata?.invoiceModel?.periodFrom ? window.$utils.dateFormat(props.invoicedata?.invoiceModel?.periodFrom) : '', { shouldValidate: true });
                    let periodfrom = moment(props.invoicedata?.invoiceModel?.periodFrom, 'DD-MM-YYYY');
                    setperiodfrom(props.invoicedata?.invoiceModel?.periodFrom ? dayjs(new Date(periodfrom.toDate())) : '');

                    setValue('periodto', props.invoicedata?.invoiceModel?.periodTo ? window.$utils.dateFormat(props.invoicedata?.invoiceModel?.periodTo) : '', { shouldValidate: true });
                    let periodto = moment(props.invoicedata?.invoiceModel?.periodTo, 'DD-MM-YYYY');
                    setperiodto(props.invoicedata?.invoiceModel?.periodTo ? dayjs(new Date(periodto.toDate())) : '');

                    setValue('invoicedate', props.invoicedata?.invoiceModel?.invoiceDate ? window.$utils.dateFormat(props.invoicedata?.invoiceModel?.invoiceDate) : '', { shouldValidate: true });
                    let invoicedate = moment(props.invoicedata?.invoiceModel?.invoiceDate, 'DD-MM-YYYY');
                    setinvoicedate(props.invoicedata?.invoiceModel?.invoiceDate ? dayjs(new Date(invoicedate.toDate())) : '');

                    setValue('duedate', props.invoicedata?.invoiceModel?.dueDate ? window.$utils.dateFormat(props.invoicedata?.invoiceModel?.dueDate) : '', { shouldValidate: true });
                    let duedate = moment(props.invoicedata?.invoiceModel?.dueDate, 'DD-MM-YYYY');
                    setduedate(props.invoicedata?.invoiceModel?.dueDate ? dayjs(new Date(duedate.toDate())) : '');
                } else {
                    setperiodfrom('');
                    setperiodto('');
                    setValue('periodfrom', '', { shouldValidate: true });
                    setValue('periodto', '', { shouldValidate: true });
                    props.updateduplicatedata();
                    setValue('invoicedate', window.$utils.dateFormat(new Date(), 'DD-MM-YYYY'), { shouldValidate: true });
                    let invoicedate = moment(new Date(), 'DD-MM-YYYY');
                    setinvoicedate(dayjs(invoicedate.toDate()));

                    const thirtyDaysAdded = moment().add(30, 'days');
                    setValue('duedate', window.$utils.dateFormat(thirtyDaysAdded.toDate()), { shouldValidate: true });
                    setduedate(dayjs(thirtyDaysAdded.toDate()));
                }

                setValue('paymentref', props.invoicedata?.invoiceModel?.paymentReference, { shouldValidate: true });
                setValue('notes', props.invoicedata?.invoiceModel?.notes, { shouldValidate: true });
            } else {
                setValue('Patient', '');
                setValue('funder', '');
            }
            if (props.previousInvoiceDate !== undefined) {
                let invoicedate = moment(props.previousInvoiceDate, 'DD-MM-YYYY');
                setinvoicemindate(dayjs(new Date(invoicedate.toDate())));
            }
        } else if (props.invoicedata === undefined && props.patientid !== undefined && props.patientdata !== undefined) {
            setValue('Patient', props.patientid?.toString());
            if (props.invoicefunderdetails !== undefined) {
                setValue('funderadd', props.invoicefunderdetails[0]?.funderAddressLine1 || '');
            } else {
                setValue('funder', '');
                setValue('funderadd', '');
            }
            props.patientdata.filter((data: any) => {
                if (data.patientId === Number(props.patientid)) {
                    if (data.nhS_Id !== '') {
                        setValue('nhs', data.nhS_Id);
                    } else {
                        setValue('nhs', data.local_Id);
                    }
                }
            });
            if (props.previousInvoiceDate !== undefined) {
                let invoicedate = moment(props.previousInvoiceDate, 'DD-MM-YYYY');
                setinvoicemindate(dayjs(new Date(invoicedate.toDate())));
            }
        } else {
            if (props.patientid === undefined && props.patientdata === undefined) {
                setValue('Patient', '');
                setValue('funder', '');
            }
        }
    }, [props.funderdata, props.patientid, props.patientdata, props.invoicedata, props.duplicateStatus, props.invoicefunderdetails]);

    useEffect(() => {
        if (props.invoicedata !== undefined) {
            setpatientid(props.invoicedata?.patientDetail?.patientId);
            setTimeout(() => {
                let objPatientSmartSearch = {
                    url: `${BASEURLDEV}${API_ENDPOINT.GetDDLAddmittedPatient}`,
                    extraPayload: {
                        defaultPatientId: props.invoicedata?.patientDetail?.patientId,
                        serviceId: DataServiceDetail?.serviceId
                    }
                };
                setPatientSmartSearch(objPatientSmartSearch);

            }, 2000);
        }
    }, [props.invoicedata])

    useEffect(() => {
        props.updateDueDate(duedate, 'duedate');
    }, [duedate]);

    const openpdf = async (e: any) => {
        e.preventDefault();
        const payload = { path: fileres };
        const response = await getinvoiceDocument(payload);
        if (response.status === true) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = response.data;
            alink.click();
        }
    };
    useEffect(() => {
        let objPatientSmartSearch = {
            url: `${BASEURLDEV}${API_ENDPOINT.GetDDLAddmittedPatient}`,
            extraPayload: {
                serviceId: DataServiceDetail?.serviceId
            }
        };
        setPatientSmartSearch(objPatientSmartSearch);
    }, []);

    useEffect(() => {
        if (props.patientid !== undefined && props.patientid !== '') {
            let objFunderSmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetDDLFunderDetailsByPatientID}`,
                extraPayload: {
                    patientid: props.patientid,
                    serviceId: DataServiceDetail?.serviceId
                }
            };
            setFunderSmartSearch(objFunderSmartSearch);
        }
    }, [props.patientid]);

    return (
        <div className="row invoice-form p-3 pt-4 mt-2">
            <div className="col-lg-6 pe-xl-5 pe-lg-3 pe-md-4">
                <div className="d-flex mb-3 position-relative">
                    <label className="bold mb-0">{t('InvoiceDetail.Patient_Name')}</label>

                    {props.invoiceDraftStatus && props.isformsave && <input type="text" value="Ronald Gerald" className="form-control white" disabled />}
                    {props.invoiceDraftStatus && props.isformcancel && <input type="text" value="Ronald Gerald" className="form-control white" disabled />}

                    {props.invoiceDraftStatus && !props.isformsave && !props.isformcancel && (
                        <>
                            {/* <select
                                {...register('Patient', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'Patient' as const}
                                id="Patient"
                                //@ts-ignore
                                className={`form-control white ${errors.Patient ? 'is-invalid' : ''}`}
                                disabled={props.invoicedata !== undefined ? true : false}
                            >
                                <option disabled={true} value="">
                                    Select Patient
                                </option>
                                {props.patientdata !== undefined &&
                                    props.patientdata.map((patients: any, i: number) => {
                                        return (
                                            <option key={i} value={patients.patientId}>
                                                {dispvalue && patients.patientName}
                                                {!dispvalue && patients.nhS_Id !== '' && patients.nhS_Id + ' - ' + patients.patientName}
                                                {!dispvalue && patients.nhS_Id === '' && patients.local_Id + ' - ' + patients.patientName}
                                            </option>
                                        );
                                    })}
                            </select> */}
                            {patientSmartSearch !== undefined && (
                                <div className="auto-complete-dropdown invoice-ddl">
                                    <SmartSearchDropdownContainer
                                        defaultValue={patientid || undefined}
                                        disabled={props.invoicedata !== undefined ? true : false}
                                        smartSearchLabel="Select Patient"
                                        errors={errors.Patient}
                                        onChangeSmartSearch={(value: any) => {
                                            const event = new Event('change');
                                            let obj = {
                                                name: 'Patient',
                                                value: value
                                            };
                                            //@ts-ignore
                                            Object.defineProperty(event, 'target', { writable: false, value: obj });
                                            setValue('Patient', value?.id?.toString(), { shouldValidate: true });
                                            if (value !== undefined) {
                                                props.updateData(event);
                                            }
                                            else {
                                                setValue('nhs', '');
                                            }
                                        }}
                                        smartSearch={patientSmartSearch}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {!props.invoiceDraftStatus && <p>{props?.invoicedata?.patientDetail?.patientName || ''}</p>}
                    {props.invoiceDraftStatus && (
                        <a
                            href="#"
                            onClick={handleClickModal}
                            className={`info-btn ${props.isformsave ? 'hidefield' : ' ' && props.isformcancel ? 'hidefield' : ' '} ${props.patientid == undefined || Number(props.patientid) == 0 ? 'disabled' : ''
                                }`}
                            data-tooltip-id="tooltip-patient"
                            data-tooltip-content="Click to View Patient Detail"
                        >
                            <i className="fa fa-circle-info"></i>
                        </a>
                    )}
                </div>
                <div className="d-flex mb-3">
                    <label className="bold mb-0">{t('InvoiceDetail.NHSID_ClientID')}</label>
                    {!props.invoiceDraftStatus && (
                        <p>
                            {props?.invoicedata?.patientDetail?.nhsId !== ''
                                ? props?.invoicedata?.patientDetail?.nhsId
                                : props?.invoicedata?.patientDetail?.localId !== ''
                                    ? props?.invoicedata?.patientDetail?.localId
                                    : ''}
                        </p>
                    )}
                    {props.invoiceDraftStatus && (
                        <input
                            type="text"
                            {...register('nhs', {
                                // onChange: (e: any) => {
                                //     props.updateData(e);
                                // },
                                onBlur: (e: any) => {
                                    props.getpatientbynhs(e);
                                }
                            })}
                            disabled={true}
                            name={'nhs' as const}
                            //@ts-ignore
                            className={`form-control white ${errors.nhs ? 'is-invalid' : ''}`}
                        // disabled={props.invoicedata !== undefined ? true : false || props.isformsave === true ? true : false || props.isformcancel === true ? true : false}
                        />
                    )}
                </div>
                <div className="d-flex mb-3">
                    <label className="bold mb-0">Funder Name*</label>
                    {props.invoiceDraftStatus && props.isformsave && <input type="text" value="Ronald Gerald" className="form-control white" disabled />}
                    {props.invoiceDraftStatus && props.isformcancel && <input type="text" value="Ronald Gerald" className="form-control white" disabled />}

                    {props.invoiceDraftStatus && !props.isformsave && !props.isformcancel && (
                        <>
                            {/* <select
                                {...register('funder', {
                                    onChange: (e: any) => {
                                        props.updateData(e);
                                    }
                                })}
                                name={'funder' as const}
                                id="funder"
                                //@ts-ignore
                                className={`form-control white ${errors.funder ? 'is-invalid' : ''}`}
                                disabled={props.invoicedata !== undefined ? true : false}
                            >
                                <option disabled={true} value="">
                                    Select Funder
                                </option>
                                {props.funderdata !== undefined &&
                                    props.funderdata.map((funders: any) => {
                                        return (
                                            <option key={funders.funderId} value={funders.funderId}>
                                                {funders.funderName}
                                            </option>
                                        );
                                    })}
                                {props.invoicedata !== undefined && <option value="-12">Self</option>}
                            </select> */}
                           {props.invoicedata !== undefined ?<>
                                   <span className='grey-span-box disabled'>{props.invoicedata?.invoiceModel?.funderName}</span> 
                                   </>
                                :
                                <div className="auto-complete-dropdown invoice-ddl">
                                    <SmartSearchDropdownContainer
                                        defaultValue={props.invoicedata?.invoiceModel?.funderId === 0 ? null : props.invoicedata?.invoiceModel?.funderId}
                                        disabled={props.invoicedata !== undefined ? true : false}
                                        smartSearchLabel="Select Funder"
                                        errors={errors.funder}
                                        onChangeSmartSearch={(value: any) => {
                                            const event = new Event('change');
                                            let obj = {
                                                name: 'funder',
                                                value: value
                                            };
                                            //@ts-ignore
                                            Object.defineProperty(event, 'target', { writable: false, value: obj });
                                            setValue('funder', value?.id?.toString(), { shouldValidate: true });
                                            if (value !== '') {
                                                props.updateData(event);
                                            }
                                        }}
                                        // {...register('funder', {
                                        // onChange: (e: any) => {
                                        //     
                                        // }
                                        // })}
                                        // name={'funder' as const}
                                        smartSearch={funderSmartSearch}
                                    />
                                </div>}
                        </>
                    )}

                    {!props.invoiceDraftStatus && (
                        <p>
                            {props.funderdata !== undefined && props.funderdata.filter((val: any) => val.funderId === props.invoicedata?.invoiceModel?.funderId).length > 0
                                ? props.funderdata.filter((val: any) => val.funderId === props.invoicedata?.invoiceModel?.funderId)[0].funderName || null
                                : null}
                        </p>
                    )}
                </div>
                <div className="d-flex mb-3">
                    <label className="bold mb-0">{t('InvoiceDetail.Funder_Address')}</label>
                    {!props.invoiceDraftStatus && <p>{props.invoicedata?.invoiceModel?.funderAddress || ''}</p>}
                    {props.invoiceDraftStatus && (
                        <textarea
                            {...register('funderadd', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'funderadd' as const}
                            //@ts-ignore
                            className="form-control white"
                            rows={2}
                            disabled={props.invoicedata !== undefined ? true : false || props.isformsave === true ? true : false || props.isformcancel === true ? true : false}
                        ></textarea>
                    )}
                </div>
                <div className="d-flex mb-3 datepicker-input-css">
                    <label className="bold mb-0">{t('InvoiceDetail.Period_From')}</label>
                    {!props.invoiceDraftStatus && props.invoicedata !== undefined && props.invoicedata.invoiceModel !== undefined && (
                        <p>{props.invoicedata?.invoiceModel?.periodFrom.split(' ')[0] || ''}</p>
                    )}
                    {props.invoiceDraftStatus && (
                        <Controller
                            control={control}
                            name="periodfrom"
                            render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                return (
                                    <DatePicker
                                        {...fieldProps}
                                        className={`form-control white ${error ? 'is-invalid' : ''}`}
                                        label={t('InvoiceDetail.Period_From')}
                                        value={periodfrom}
                                        format="DD/MM/YYYY"
                                        disabled={props.isedit === 'edit' ? true : false}
                                        onChange={(date: any) => {
                                            fieldProps.onChange(date);
                                            // setmindate(dayjs(date));
                                            setperiodfrom(date);
                                            props.updateDate(date, 'periodfrom');
                                        }}
                                        minDate={minDate}
                                        maxDate={periodto}
                                        views={['year', 'month', 'day']}
                                        slotProps={{ actionBar: { actions: ['clear', 'today'] }, }}
                                    />
                                );
                            }}
                        />
                    )}
                </div>
                <div className="d-flex mb-3 datepicker-input-css">
                    <label className="bold mb-0">{t('InvoiceDetail.Period_To')}</label>
                    {!props.invoiceDraftStatus && props.invoicedata !== undefined && props.invoicedata.invoiceModel !== undefined && (
                        <p>{props.invoicedata?.invoiceModel?.periodTo.split(' ')[0] || ''}</p>
                    )}
                    {props.invoiceDraftStatus && (
                        <Controller
                            control={control}
                            name="periodto"
                            render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                return (
                                    <DatePicker
                                        {...fieldProps}
                                        className={`form-control white ${error ? 'is-invalid' : ''}`}
                                        label={t('InvoiceDetail.Period_To')}
                                        value={periodto}
                                        format="DD/MM/YYYY"
                                        disabled={props.isedit === 'edit' ? true : false}
                                        onChange={(date: any) => {
                                            fieldProps.onChange(date);
                                            setminperiodfrom(dayjs(date));
                                            setperiodto(date);
                                            props.updateDate(date, 'periodto');
                                        }}
                                        minDate={periodfrom}
                                        views={['year', 'month', 'day']}
                                        slotProps={{ actionBar: { actions: ['clear', 'today'] }, }}
                                    // disableFuture
                                    />
                                );
                            }}
                        />
                    )}
                </div>
            </div>
            <div className="col-lg-6 ps-xl-5 ps-lg-3 pe-md-4 ">
                <div className="d-flex mb-3 datepicker-input-css position-relative">
                    <label className="bold mb-0">{t('InvoiceDetail.Invoice_Date')}</label>
                    {!props.invoiceDraftStatus && props.invoicedata !== undefined && props.invoicedata.invoiceModel !== undefined && (
                        <p>{props.invoicedata?.invoiceModel?.invoiceDate.split(' ')[0] || ''}</p>
                    )}
                    {props.invoiceDraftStatus && (
                        <Controller
                            control={control}
                            name="invoicedate"
                            render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                return (
                                    <DatePicker
                                        {...fieldProps}
                                        className={`form-control white input-alert ${error ? 'is-invalid' : ''}`}
                                        label={t('InvoiceDetail.Invoice_Date')}
                                        value={invoicedate}
                                        format="DD/MM/YYYY"
                                        disabled={props.isedit === 'edit' || getValues('funder') === '' ? true : false}
                                        onChange={(date: any) => {
                                            fieldProps.onChange(date);

                                            let ddate = moment(new Date(date)).add(props.invoicefunderdetails !== undefined ? Number(props.invoicefunderdetails[0]?.paymentTerms) : 0, 'days');
                                            setduedate(ddate);
                                            setinvoicedate(date);
                                            props.updateDate(date, 'invoicedate');
                                        }}
                                        minDate={invoicemindate}
                                        views={['year', 'month', 'day']}
                                        slotProps={{ actionBar: { actions: ['clear', 'today'] }, }}
                                    // disableFuture
                                    />
                                );
                            }}
                        />
                    )}
                    {invoicedate > new Date() && (
                        <span className="alert-input" data-tooltip-id="tooltip-alert" data-tooltip-content="This date is on the future. Make sure it is what you expected.">
                            <i className="fa-solid fa-triangle-exclamation"></i>
                        </span>
                    )}
                </div>
                <div className="d-flex mb-3 datepicker-input-css">
                    <label className="bold mb-0">{t('InvoiceDetail.Due_Date')}</label>
                    {!props.invoiceDraftStatus && props.invoicedata !== undefined && props.invoicedata.invoiceModel !== undefined && (
                        <p>{props.invoicedata?.invoiceModel?.dueDate.split(' ')[0] || ''}</p>
                    )}
                    {props.invoiceDraftStatus && (
                        <Controller
                            control={control}
                            name="duedate"
                            render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                return (
                                    <DatePicker
                                        {...fieldProps}
                                        className={`form-control white ${error ? 'is-invalid' : ''}`}
                                        label={t('InvoiceDetail.Due_Date')}
                                        value={duedate}
                                        format="DD/MM/YYYY"
                                        disabled={true}
                                        // disabled={props.invoicedata !== undefined ? true : false || props.isedit === 'edit' ? true : false}
                                        onChange={(date: any) => {
                                            fieldProps.onChange(date);
                                            // setmindate(dayjs(date));
                                            setduedate(date);
                                            props.updateDate(date, 'duedate');
                                        }}
                                        views={['year', 'month', 'day']}
                                        slotProps={{ actionBar: { actions: ['clear', 'today'] }, }}
                                    // disableFuture
                                    />
                                );
                            }}
                        />
                    )}
                </div>
                <div className="d-flex mb-3">
                    <label className="bold mb-0">{t('InvoiceDetail.Payment_Reference')}</label>
                    {!props.invoiceDraftStatus && <p>{props.invoicedata?.invoiceModel?.paymentReference || ''}</p>}
                    {props.invoiceDraftStatus && (
                        <input
                            type="text"
                            {...register('paymentref', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'paymentref' as const}
                            //@ts-ignore
                            className={`form-control white ${errors.paymentref ? 'is-invalid' : ''}`}
                            disabled={props.isformsave === true ? true : false || props.isformcancel === true ? true : false}
                        />
                    )}
                </div>
                <div className="d-flex mb-3">
                    <label className="bold mb-0">{t('InvoiceDetail.Attach_Document')}</label>
                    <div className="w-70">
                        {!props.invoiceDraftStatus && <p>{props.invoicedata?.invoiceModel?.documentName || ''}</p>}

                        {fileres !== '' && (
                            <>
                                <div className="d-flex justify-content-between ">
                                    <div className="input-readonly">
                                        <textarea rows={2} {...register(`invoiceContract`, {})} name={`invoiceContract` as const} onClick={(e: any) => openpdf(e)} readOnly />
                                    </div>
                                    {/* onClick={(e: any) => removepdf(e)} */}
                                    {/* <button className="btn-icon red-background" name="btndelete">
                                            <i className="fa-solid fa-trash"></i>
                                        </button> */}
                                </div>
                            </>
                        )}

                        {fileres == '' && (
                            <>
                                {props.invoiceDraftStatus && (
                                    <Controller
                                        control={control}
                                        name="contractPDF"
                                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                            return (
                                                <FilePond
                                                    files={file}
                                                    onupdatefiles={setFiles}
                                                    // @ts-ignore
                                                    allowFileSizeValidation={true}
                                                    maxFileSize={5000000}
                                                    allowFileTypeValidation={true}
                                                    acceptedFileTypes={['application/pdf']}
                                                    labelMaxFileSizeExceeded={'File is too large'}
                                                    name={'contractPDF'}
                                                    labelIdle={`${t('VacantRightbar.pdftitle3')}<span class="filepond--label-action"> ${t('VacantRightbar.pdftitle2')}</span>`}
                                                    server={{
                                                        url: `${BASEURLDEV}${API_ENDPOINT.UploadInvoiceDocument}`,
                                                        process: {
                                                            headers: {
                                                                Authorization: `Bearer ${token}`
                                                            },
                                                            //@ts-ignore
                                                            onload: (res: any) => {
                                                                const fileres = JSON.parse(res);
                                                                let filedata = fileres.data;
                                                                setFileres(fileres.data.filePath);
                                                                setValue('invoiceContract', filedata.fileName, { shouldValidate: true });
                                                                props.updateFData({ file, filedata });
                                                            }
                                                        }
                                                    }}
                                                />
                                            );
                                        }}
                                    ></Controller>
                                )}
                            </>
                        )}

                        {props.invoiceDraftStatus && (
                            <p className={`small-text mt-2 w-100 ${props.isformsave || props.isformcancel ? 'hidefield' : ' '}`}>
                                <span className="bold">Note:</span> Maximum Upload File 5 MB.
                            </p>
                        )}
                    </div>
                </div>
                <div className="d-flex mb-3">
                    <label className="bold mb-0">{t('InvoiceDetail.Notes')}</label>
                    {!props.invoiceDraftStatus && <p>{props.invoicedata?.invoiceModel?.notes || ''}</p>}
                    {props.invoiceDraftStatus && (
                        <textarea
                            {...register('notes', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'notes' as const}
                            //@ts-ignore
                            className="form-control white"
                            rows={2}
                            disabled={props.isformsave === true ? true : false || props.isformcancel === true ? true : false}
                        ></textarea>
                    )}
                </div>
            </div>
            <RenderModalContainer>{showModal && <PatientDetailContainer invoicedetails={invoicedetails} onClose={toggle} />}</RenderModalContainer>

            <Tooltip id="tooltip-patient" className="my-tooltip" />
            <Tooltip id="tooltip-alert" className="my-tooltip" />
        </div>
    );
};

export default InvoiceDetailFormView;
