import ClientPortal from '../../../../../../components/ClientPortal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SendServiceCoordinatorEmails } from '../../../../../../service/service-details-service';
import sweetalert from 'sweetalert';
import WYSIWYGEditor from '../../../../../../components/WYSIWYGEditor/WYSIWYGEditor';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';

const initialValues = {
    name: '',
    subject: '',
    from: '',
    body: ''
};

const WORD_LIMIT = 500;

function calculateWordCount(text: string) {
    if (text.trim().length === 0) {
        return 0;
    }
    return text?.trim().split(/\s+/).length;
}

export const serviceCoordinatorMail = Yup.object().shape({
    name: Yup.string().required('Please enter name*'),
    subject: Yup.string().required('Please enter subject*'),
    from: Yup.string()
        .trim()
        .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Email is invalid')
        .email('Email is Invalid*')
        .required(),
    body: Yup.string()
        .test('wordCount', `Body cannot exceed ${WORD_LIMIT} words`, function (value) {
            if (!value) return true;
            return calculateWordCount(value) <= WORD_LIMIT;
        })
        .required('')
});

const SendMessageToService = ({ handleModal, showPortal, coordinatorData, firstName, lastName, ServiveName, setShowPortal }: any) => {
    const [initialBody, setInitialBody] = useState<string>('');
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);

    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        reset,
        watch,
        setValue,
        control,
        trigger
    } = useForm<any>({
        defaultValues: initialValues,
        resolver: yupResolver(serviceCoordinatorMail)
    });

    const serviceProviderEmailSubmit = async (data: any) => {
        const payload = {
            serviceSlug: coordinatorData?.serviceSlug,
            subject: data.subject,
            fromEmail: {
                email: data.from,
                name: data.name
            },
            body: data.body + `<br><p><a href=mailto:${data.from}>Click here to reply</a> ${data.name}.</p>`
        };
        const response = await SendServiceCoordinatorEmails(payload);
        if (response.status === true) {
            setShowPortal(false);
            sweetalert(response.message, {
                icon: 'success'
            });
            reset(initialValues);
        } else {
            sweetAlert(response.message, {
                icon: 'error',
                dangerMode: true
            });
            reset(initialValues);
        }
    };

    useEffect(() => {
        setValue('name', DataLogin?.userModel?.userName, { shouldValidate: true });
        setValue('from', DataLogin?.userModel?.email, { shouldValidate: true });
        setValue('subject', '');
        setValue('body', '');
    }, [showPortal]);
    return (
        <ClientPortal selector="myportal" show={showPortal}>
            <div className="overlay" onClick={handleModal}></div>
            <div className="modal  modal-550 nhs-body">
                <div className="header mb-3">
                    <h4 className="mb-0">
                        Send a message to {firstName} from {coordinatorData?.serviceName}
                    </h4>
                    <a onClick={handleModal} className="removeButton">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                            <path
                                fill="#ffffff"
                                d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                            />
                        </svg>
                    </a>
                </div>
                <div className="body message-popup p-4">
                    <form onSubmit={handleSubmit(serviceProviderEmailSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="floating-field w-100">
                                    <input
                                        // placeholder="Enter your Name*"
                                        className={`${errors.name ? 'form-control white mb-3 error-field' : 'form-control white mb-3'}`}
                                        {...register('name', { required: 'Name is required' })}
                                        onBlur={() => trigger('name')}
                                        name="name"
                                        placeholder=" "
                                    />
                                    <label className="floating-form-label">Name*</label>
                                </div>

                                {/* {errors.name && <p className="error-message">{errors.name?.message as String}</p>} */}
                            </div>
                            <div className="col-md-6">
                                <div className="floating-field w-100">
                                    <input
                                        // placeholder="Enter Your Email Address*"
                                        className={`${errors.from ? 'form-control white mb-3 overview-input error-field' : 'form-control white mb-3 overview-input'}`}
                                        {...register('from', { required: 'From email is required*' })}
                                        onBlur={() => trigger('from')}
                                        name="from"
                                        placeholder=" "
                                    />
                                    <label className="floating-form-label">Email Address*</label>
                                </div>

                                {/* {errors.from && <p className="error-message">{errors.from?.message as String}</p>} */}
                            </div>
                        </div>
                        <div className="floating-field w-100">
                            <input
                                type="text"
                                // placeholder="Enter email subject*"
                                className={`${errors.subject ? 'form-control white mb-3 error-field' : 'form-control white mb-3'}`}
                                {...register('subject', { required: 'Subject is required' })}
                                onBlur={() => trigger('subject')}
                                name="subject"
                                placeholder=" "
                            />
                            <label className="floating-form-label">Subject*</label>
                        </div>

                        <div className="floating-field w-100">
                            <textarea
                                name="body"
                                rows={8}
                                className={`${errors.body ? `form-control white error-field pt-4 ${calculateWordCount(watch('body')) > WORD_LIMIT ? '' : 'mb-4'}` : 'form-control white mb-4 pt-4'}`}
                                // {...register("body", { required: "Body is required" })}
                                onChange={(e) => setValue('body', e.target.value?.split('<p></p>')?.join('')?.trim(), { shouldValidate: true })}
                                onBlur={() => trigger('body')}
                                placeholder=" "
                            />
                            <label className="floating-form-label">Your Message*</label>
                        </div>
                        <div className="d-flex justify-content-end">
                            {errors.body && calculateWordCount(watch('body')) > WORD_LIMIT && <p className="error-message mb-4 ps-0">{errors.body?.message as String}</p>}
                            <span>
                                {calculateWordCount(watch('body'))}/{WORD_LIMIT}
                            </span>
                        </div>

                        <div className="w-full text-center">
                            <button disabled={!isValid} type="submit" className="btn primary-btn mt-1 px-5">
                                Send
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </ClientPortal>
    );
};

export default SendMessageToService;
