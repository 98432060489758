import React from 'react';
import FunderManagementBottomView from './FunderManagementBottomView';

const FunderManagementBottomContainer = () => {
    return (
        <div>
            <FunderManagementBottomView/>
        </div>
    );
};

export default FunderManagementBottomContainer;