'use client';
import React, { useEffect, useRef, useImperativeHandle, useState, useCallback } from 'react';
import axios from 'axios';
import ClientPortal from '../../../components/ClientPortal';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import airobot from '../../../assets/images/airobot.png';
import nav_arrow from '../../../assets/images/nav-arrow.png';
import toogle_img from '../../../assets/images/toogle-img.png';
import service_image1 from '../../../assets/images/service1.png';
import service_image2 from '../../../assets/images/service2.png';
import service_image3 from '../../../assets/images/service3.png';
import service_image4 from '../../../assets/images/service-1.png';
//@ts-ignore
import AOS from 'aos';

import 'aos/dist/aos.css';
import { getOnboardingServiceCards, saveServiceDetails, savePreviewData } from '../../../service/onboarding-servicesView';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import warning from '../../../assets/images/warning.png';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { OpenAienums } from '../../../utils/constant';
import { generateOpenAiDescription } from '../../../service/onboarding-describeyourfacility-service';
import LoaderContainer from '../../../components/Loader/loaderContainer';
import { Autocomplete, TextField } from '@mui/material';
import { ROUTES_DATA } from '../../../navigation/RoutesConstants';
import OnboardingGenerateAnimationLoader from '../ServiceOnboardingPreview/OnboardingGenerateAnimationLoder';
import { setOnboardingPageValidity } from '../../../store/onboarding/onboardingPageValidityReducer';
import Slider, { Settings } from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface Factsheet {
    factsheetId: number;
    factsheet: string;
    active: boolean;
}

interface ServiceCard {
    serviceTypeId: number;
    cardId: number;
    cardName: string;
    description: string;
    imagePath: string;
    factsheets: Factsheet[];
}
interface AIHeadingType {
    airesponseId: number;
    generatedResponse: string;
    isSelected: boolean;
}
const ServiceOfferView = React.forwardRef((props, ref) => {
    const headingInputRef = useRef<HTMLInputElement>(null);
    const [showPortal, setShowPortal] = useState(false);
    const [openServicePanel, SetOpenServicePanel] = useState(false);
    const [serviceCards, setServiceCards] = useState<ServiceCard[]>([]);
    const [filteredServiceCards, setFilteredServiceCards] = useState<ServiceCard[]>([]);
    const [selectedCard, setSelectedCard] = useState<ServiceCard | null>(null);
    const [selectedFactsheets, setSelectedFactsheets] = useState<{ [key: number]: string[] }>({});
    const [factsheetStates, setFactsheetStates] = useState<{ [key: number]: boolean }>({});
    const [expandedDescriptions, setExpandedDescriptions] = useState<{ [key: number]: boolean }>({});
    const [viewButtonDisabled, setViewButtonDisabled] = useState(false);
    const [showNoFactsheetsMessage, setShowNoFactsheetsMessage] = useState(false);
    const [filteredFactsheets, setFilteredFactsheets] = useState<{ [key: number]: string[] }>({});
    const [searchQuery, setSearchQuery] = useState('');
    const [generateClickCount, setGenerateClickCount] = useState<number>(0);
    const [headingOptions, setHeadingOptions] = useState<AIHeadingType[]>([]);
    const [headingOptionsUnTouched, setHeadingOptionsUnTouched] = useState<AIHeadingType[]>([]);
    const [nonEditedSelectedHeading, setNonEditedSelectedHeading] = useState<AIHeadingType | null>(null);
    const [showMaxClicksWarning, setShowMaxClicksWarning] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isManualGenerate, setIsManualGenerate] = useState(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [selectedHeading, setSelectedHeading] = useState<AIHeadingType | null>(null);
    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);
    const [selectedCardPayload, setSelectedCardPayload] = useState<ServiceCard[]>([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const url = `${ROUTES_DATA.PROVIDERSERVICEDETAIL}/`

    const serviceCardsSectionDiv = useRef<HTMLDivElement>(null);
    const viewSelectedServiceDiv = useRef<HTMLDivElement>(null);


    const settings: Settings = {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToScroll: 1,
        slidesToShow: 4,
        variableWidth: false,
        draggable: false,
        swipe: false,
        centerMode: false,
        responsive: [
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 2
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4
            },
          }
        ],
      };

    const validateForm = () => {
        const valid = true;
        setIsValid(valid);
    };

    useEffect(() => {
        validateForm();
    },);

    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(()=>{
        setSelectedHeading(null);
        if(nonEditedSelectedHeading){
            setHeadingOptions((previousList: AIHeadingType[])=>{
                return previousList.map((previousHeading: AIHeadingType)=>{
                    if(previousHeading.airesponseId === nonEditedSelectedHeading.airesponseId){
                        return {...nonEditedSelectedHeading, isSelected: false}
                    }
                    return {...previousHeading}
                })
            })
        }
    }, [nonEditedSelectedHeading])

    useEffect(() => {
        const fetchServiceCards = async () => {
            try {
                setIsLoading(true);
                const response = await getOnboardingServiceCards(serviceId!);
                if (response.status) {
                    const fetchedServiceCards = response?.data?.serviceCardModels;
                    setServiceCards(fetchedServiceCards);
                    setHeadingOptions(response?.data?.generatedResponses)
                    setHeadingOptionsUnTouched(response?.data?.generatedResponses)                    
                    setFilteredServiceCards(fetchedServiceCards);
                    setSelectedHeading(response?.data?.generatedResponses?.find(function (e: any) {
                        return e.isSelected == true;
                    }))
                    fetchedServiceCards?.map((card: any) => {
                        setSelectedFactsheets((prevState) => {
                            const activeFactsheets = card.factsheets
                                .filter((factsheet: any) => factsheet.active)
                                .map((factsheet: any) => ({
                                    Name: factsheet.factsheet,
                                    FactsheetMasterId: factsheet.factsheetId
                                }));
                            const updatedFactsheets = activeFactsheets.map((f:any) => f.Name);
                            return {
                                ...prevState,
                                [card.cardId]: [...updatedFactsheets]
                            };
                        });
                    })
                } else { }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        };
        fetchServiceCards();
    }, [serviceId]);

    const handleModal = (card: ServiceCard) => {
        setSelectedCard(card);
        setSelectedCardPayload((prevSelectedCards) => {
            if (prevSelectedCards.some((selectedCard) => selectedCard.cardId === card.cardId)) {
                return prevSelectedCards;
            }
            return [...prevSelectedCards, card];
        });
        const initialFactsheetStates = card.factsheets.reduce((acc, factsheet) => {
            acc[factsheet.factsheetId] = selectedFactsheets[card.cardId]?.includes(factsheet.factsheet) || factsheet.active;
            return acc;
        }, {} as { [key: number]: boolean });
        setFactsheetStates(initialFactsheetStates);

        setShowPortal(true);
    };

    useEffect(() => {
        if (showPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showPortal]);

    const handleServicePanel = (e: any) => {
        e.preventDefault();
        SetOpenServicePanel(true);
        setViewButtonDisabled(true);

        if (Object.keys(selectedFactsheets).length === 0 || Object.values(selectedFactsheets).every(value => Array.isArray(value) && value.length === 0)) {
            setShowNoFactsheetsMessage(true);
        } else {
            setShowNoFactsheetsMessage(false);
        }
        setTimeout(() => {
            window.$utils.scrollToRefDivAnimation(viewSelectedServiceDiv)
        }, 100);
    };

    const handleSearch = (query: string) => {
        const searchText = query.toLowerCase();
        const filteredCards = serviceCards.filter(
            (card) =>
                (card.cardName && card.cardName.toLowerCase().includes(searchText)) ||
                (card.description && card.description.toLowerCase().includes(searchText)) ||
                card.factsheets.some((factsheet) => factsheet.factsheet && factsheet.factsheet.toLowerCase().includes(searchText))
        );
        setFilteredServiceCards(filteredCards);
    };

    const handleSearchSelected = (query: string) => {
        const searchText = query.toLowerCase();

        const newFilteredFactsheets: { [key: number]: string[] } = {};
        Object.entries(selectedFactsheets).forEach(([cardId, factsheets]) => {
            const filteredFacts = factsheets.filter((factsheet) => factsheet.toLowerCase().includes(searchText));

            if (filteredFacts.length > 0) {
                newFilteredFactsheets[Number(cardId)] = filteredFacts;
            }
        });

        setFilteredFactsheets(newFilteredFactsheets);
    };

    const HandleClosePanel = (e: any) => {
        e.preventDefault();
        SetOpenServicePanel(false);
        setViewButtonDisabled(false);
        setTimeout(() => {
            window.$utils.scrollToRefDivAnimation(serviceCardsSectionDiv)
        }, 100);
    };

    const imageMap: { [key: number]: string } = {
        0: service_image1,
        1: service_image2,
        2: service_image3,
        3: service_image4
    };

    useEffect(() => {
        var dynamicDiv = document.getElementById('dynamicDiv4');
        dynamicDiv?.classList.remove('closed');
        var dynamicDivHeight = dynamicDiv?.clientHeight + 'px';
        document.documentElement.style.setProperty('--dynamic-div-height', dynamicDivHeight);
        dynamicDiv?.classList.add('closed');
    }, []);

    const handleSave = async () => {
        if (selectedCard) {
            const activeFactsheets = selectedCard.factsheets
                .filter((factsheet) => factsheetStates[factsheet.factsheetId])
                .map((factsheet) => ({
                    Name: factsheet.factsheet,
                    FactsheetMasterId: factsheet.factsheetId
                }));

            const payload = {
                Service_Id: serviceId,
                ServiceTypeId: selectedCard.serviceTypeId,
                ServiceCard: {
                    ServiceCardName: selectedCard.cardName,
                    ServiceCard_Id: selectedCard.cardId,
                    FactsheetList: activeFactsheets
                }
            };

            try {
                const response = await saveServiceDetails(payload);
                if (response.status) {
                } else {
                }
            } catch (error) {
            }

            setSelectedFactsheets((prevState) => {
                const updatedFactsheets = activeFactsheets.map(f => f.Name);
                return {
                    ...prevState,
                    [selectedCard.cardId]: [...updatedFactsheets]
                };
            });

            const selectedCardFactsheets = selectedCard.factsheets.map(factsheet => ({
                ...factsheet,
                active: factsheetStates[factsheet.factsheetId]
            }));

            setFilteredServiceCards(prevList =>
                prevList.map(card =>
                    card.cardId === selectedCard.cardId
                        ? { ...selectedCard, factsheets: [...selectedCardFactsheets] }
                        : { ...card }
                )
            );
            setShowPortal(false);
        }
    };

    const handleFactsheetChange = (factsheetId: number) => {
        setFactsheetStates((prevState) => ({
            ...prevState,
            [factsheetId]: !prevState[factsheetId]
        }));
    };

    const toggleDescription = (cardId: number) => {
        setExpandedDescriptions((prevState) => ({
            ...prevState,
            [cardId]: !prevState[cardId] 
        }));
    };

    const truncateText = (text: string, charLimit: number) => {
        if (text.length > charLimit) {
            return {
                truncated: text.substring(0, charLimit) + '...',
                isTruncated: true
            };
        }
        return {
            truncated: text,
            isTruncated: false
        };
    };

    const handleReadMore = (incomingObj: { index: number; desc: string; }) => {
        const { index, desc } = incomingObj;
        const parentCarousel = document.getElementById("service-cards-slick-slider");
        const carouselItems = parentCarousel?.querySelectorAll('.service-card-item');

        if (carouselItems && carouselItems.length > 0) {
            const currentItem = carouselItems[index];
            const contentElem = currentItem?.getElementsByTagName("p");

            if (contentElem && contentElem.length > 0) {
                const item = contentElem.item(0);
                if (item) {
                    const isReadMoreActive = item.innerHTML.includes("Read More");
                    if (isReadMoreActive) {
                        item.innerHTML = `<p>${desc} <span class="bold read-less" style="cursor: pointer;">Read Less</span></p>`;

                        const readLessElem = currentItem?.getElementsByClassName("read-less").item(0);
                        if (readLessElem) {
                            readLessElem.addEventListener("click", () => {
                                handleReadLess({ index, desc });
                            });
                        }
                    }
                }
            }
        }
    };

    const handleReadLess = (incomingObj: { index: number; desc: string; }) => {
        const { index, desc } = incomingObj;

        const parentCarousel = document.getElementById("service-cards-slick-slider");
        const carouselItems = parentCarousel?.querySelectorAll('.service-card-item');

        if (carouselItems && carouselItems.length > 0) {
            const currentItem = carouselItems[index];
            const contentElem = currentItem?.getElementsByTagName("p");

            if (contentElem && contentElem.length > 0) {
                const item = contentElem.item(0);
                if (item) {
                    const isReadLessActive = item.innerHTML.includes("Read Less");
                    if (isReadLessActive) {
                        item.innerHTML = `<p>${desc.slice(0, 50)}... <span class="bold read-more" style="cursor: pointer;">Read More</span></p>`;

                        const readMoreElem = currentItem?.getElementsByClassName("read-more").item(0);
                        if (readMoreElem) {
                            readMoreElem.addEventListener("click", () => {
                                handleReadMore({ index, desc });
                            });
                        }
                    }
                }
            }
        }
    };

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        if (query === '') {
            setFilteredFactsheets(selectedFactsheets);
        } else {
            const newFilteredFactsheets: { [key: number]: string[] } = {};
            Object.entries(selectedFactsheets).forEach(([cardId, factsheets]) => {
                const card = serviceCards.find((card) => card.cardId === Number(cardId));

                const filteredFacts = factsheets.filter((factsheet) => factsheet.toLowerCase().includes(query));

                const cardNameMatches = card?.cardName.toLowerCase().includes(query);

                if (cardNameMatches || filteredFacts.length > 0) {
                    newFilteredFactsheets[Number(cardId)] = filteredFacts.length > 0 ? filteredFacts : factsheets;
                }
            });

            setFilteredFactsheets(newFilteredFactsheets);
        }
    };

    useEffect(() => {
        setFilteredFactsheets(selectedFactsheets);
    }, [selectedFactsheets]);
    
    const submitServiceDetailsToServer = async () => {
        const serviceCardSheetMasterIds = filteredServiceCards.filter((sc: any) => (sc.factsheets.length > 0 && sc.factsheets.some((fs:any)=>(fs.active)))).map((sc: any)=>(sc.cardId));

        const latestHeadings = headingOptionsUnTouched.map((heading: AIHeadingType)=>({...heading, isSelected: false}));
        const openAIPayload = latestHeadings.slice(0, 20).map((item) => {
            if (item.airesponseId === selectedHeading?.airesponseId) {
                return {
                    ...item,
                    isSelected: true,
                    generatedResponse: selectedHeading.generatedResponse
                };
            }
            return { ...item };
        });

        const payload = {
            Service_Id: serviceId,
            ServiceCardSheetMasterIds: serviceCardSheetMasterIds,
            openairesponses: openAIPayload
        };

        try {
            await savePreviewData(payload);
        } catch (error) {
        }
    };

    useImperativeHandle(ref, () => ({
        isValidated: isValid,
        backActionHandler: () => {
            submitServiceDetailsToServer();
        },
        nextActionHandler: () => {
            submitServiceDetailsToServer();
        },
        triggerValidation: async () => {

        }

    }));
    useEffect(()=>{
        dispatch(setOnboardingPageValidity(isValid))
    }, [isValid])

    const showWarning = (message: string, success?: boolean) => {
        swal({
            title: '',
            text: message,
            icon: success ? 'success' : warning,
            dangerMode: true
        });
    };
    const changeServiceHeader = (e: any, item: AIHeadingType | null) => {
        const updatedOptions = headingOptions?.map((option: AIHeadingType) =>
            option.airesponseId === item?.airesponseId
                ? { ...option, isSelected: true }
                : { ...option, isSelected: false }
        );
        setHeadingOptions(updatedOptions);
        if (item) {
            setSelectedHeading({ ...item });
        }
    };
    const handleAIInputChange = (event: any, newInputValue: string) => {
        if (selectedHeading) {
            if(newInputValue.length > 100){
                showWarning("The maximum number of characters allowed in the heading is 100.");
                return;
            }
            if(newInputValue.length === 0){
                const nonEditedHeading = headingOptionsUnTouched.find((heading: AIHeadingType)=>(heading.airesponseId === selectedHeading.airesponseId))
                if(nonEditedHeading){
                    setNonEditedSelectedHeading(nonEditedHeading)
                }
                return;
            }
            setSelectedHeading({
                ...selectedHeading,
                generatedResponse: newInputValue,
            });
    
            const updatedOptions = headingOptions.map((option: AIHeadingType) =>
                option.airesponseId === selectedHeading.airesponseId
                    ? { ...option, generatedResponse: newInputValue }
                    : option
            );
            setHeadingOptions(updatedOptions);
        }
    };
    const handleGenerateOpenAi = async () => {
        const payload: any = {
            serviceId: serviceId!,
            pageId: OpenAienums.ServiceCards
        }
        //setIsLoading(true)
        setIsManualGenerate(true)
        const response = await generateOpenAiDescription(payload)
        if (response?.status) {
            //setIsLoading(false)
            setIsManualGenerate(false)
            const descriptionArr = response?.data
            const updatedArr = descriptionArr?.map((item: any) => ({
                ...item,
                wordCount: calculateWordCount(item?.generatedResponse)
            }))
            setHeadingOptions(updatedArr)
            setHeadingOptionsUnTouched(updatedArr)
            focusHeadingAutocomplete()
        } else {
            //setIsLoading(false)
            setIsManualGenerate(false)
            swal({
                title: '',
                text: response.message,
                icon: "error",
                dangerMode: true
            })
        }

    }
    function calculateWordCount(text: string) {
        if(text.trim().length === 0){
            return 0;
        }
        return text?.trim().split(/\s+/).length;
    }
    const focusHeadingAutocomplete = () => {
        if (headingInputRef.current) {
            headingInputRef.current.focus();
        }
    };

    if (isManualGenerate) {
        return <OnboardingGenerateAnimationLoader showModal={isManualGenerate}  previewMessage={t('GenerateHeadings.Service')}/>
    }
    if (isLoading) {
        return <LoaderContainer/>
    }
    return (
        <div ref={serviceCardsSectionDiv}>
            <div className="sec-frame">
                <div className="sec-frame-title">
                    <p>1. Select a Heading</p>
                </div>
                <div>
                    <div className="row">
                        <div className="col-md-11">
                            <p className="hanzipen-font">
                                Our AI tool generates a heading/introduction for your services that will appear on the profile page. <br></br> Simply select the best fit from the drop down
                                menu or click the <span className="bolder">“Generate"</span> button to get more suggestions.
                            </p>
                            <div className="d-flex align-items-center mt-2">
                                <div className="gradient-select-wrapper">
                                    <Autocomplete
                                        disablePortal
                                        options={headingOptions.slice(0, 20)}
                                        getOptionLabel={(option:AIHeadingType) => option.generatedResponse}
                                        value={selectedHeading ?? null}
                                        isOptionEqualToValue={(option, value) => option.airesponseId === value.airesponseId}
                                        onChange={changeServiceHeader}
                                        onInputChange={handleAIInputChange}
                                        renderInput={(params) => (
                                            <TextField
                                                inputRef={headingInputRef}
                                                placeholder="Choose..."
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    classes: {
                                                        root: 'gradient-select',
                                                        input: `gradient-select-input`
                                                    }
                                                }}
                                                sx={{
                                                    input: {
                                                        '&::placeholder': {
                                                            opacity: '1 !important',
                                                            color: 'white !important'
                                                        }
                                                    }
                                                }}
                                                variant="outlined"
                                                size="small"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="ms-3"><button className="toggle-button max-width-btn border-radius-less variation3" onClick={handleGenerateOpenAi}><span className="toggle-indicator"></span><span className="label">Generate</span></button></div>

                            </div>
                            {showMaxClicksWarning && <p className="text-danger mt-2">Maximum clicks reached.</p>}
                        </div>
                        <div className="col-md-1">
                            <div className="robot-animation ms-0 me-0">
                                <img src={airobot} alt="Place Image" className="img-fluid" width="80" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec-frame mt-4">
                <div className="sec-frame-title">
                    <p>2. Select Service Cards</p>
                </div>
                <div className="mt-3">
                    <div className="search-btn-group w-25 mx-auto mb-3 position-relative">
                        <input type="text" placeholder="Search" name="leadSmartSearch" className="form-control white search-onboarding" onChange={(e) => handleSearch(e.target.value)} />
                        <i className="fa-sharp fa-solid fa-magnifying-glass onboarding-search-icon"></i>
                    </div>
                    <div className="row m-0">
                        <div id={'service-cards-slick-slider'}>
                            {isLoading ? (
                                <p>Loading service cards...</p>
                            ) : (
                                // <OwlCarousel
                                //     key={Date.now()}
                                //     id="service-cards-carousel"
                                //     className="owl-theme right-owl-nav all-owl"
                                //     dots={false}
                                //     margin={10}
                                //     mouseDrag={false}
                                //     smartSpeed={800}
                                //     touchDrag={false}
                                //     items={4}
                                //     nav
                                //     responsive={{
                                //         '0': { items: 3 },
                                //         '1200': { items: 4 }
                                //     }}
                                // >
                                <Slider className='service-cards-slick-slider' {...settings}>
                                    <div className="onboarding-service-image onboarding-servicefirst item">
                                        <p className="mb-3">
                                            Services are pre-programmed based on your facility's registration, allowing you to easily select and showcase all the services with just a few
                                            clicks.
                                        </p>
                                        <p className="mb-3 bolder">To add services, follow these steps:</p>
                                        <ul className="mb-0 ps-3">
                                            <li className="list-style">
                                                <p className="normal mb-3">
                                                    Find <img src={nav_arrow} alt="Place Image" className="img-fluid" width="50" /> the relevant service card from the options provided.
                                                </p>
                                            </li>
                                            <li className="list-style">
                                                <p className="normal mb-3">
                                                    <span className="bolder">Click </span>"Add Services" button for each category you want to include.
                                                </p>
                                            </li>
                                            <li className="list-style">
                                                <p className="normal mb-3">
                                                    <span className="bolder">Select </span> <img src={toogle_img} alt="Place Image" className="img-fluid toggle-img" width="40" /> the
                                                    services listed under it to provide a comprehensive overview.
                                                </p>
                                            </li>
                                            <li className="list-style">
                                                <p className="normal mb-3">
                                                    <span className="bolder">Click </span>“View Selected Services" to check if you covered all the services you needed to.
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                    {filteredServiceCards.length > 0 ? (
                                        filteredServiceCards.map((card, index) => {
                                            const isServiceImage3 = imageMap[index % 4] === service_image3;

                                            return (
                                                <div key={card.cardId} className="onboarding-service-image item service-card-item">
                                                    <img src={imageMap[index % 4]} className="img-fluid" alt="list-image" />
                                                    <div className="onboarding-services-details">
                                                        <div className="onboarding-service-content">
                                                            <h5 style={{ color: isServiceImage3 ? 'black' : 'inherit' }}>{card.cardName}</h5>
                                                            {card?.description?.length > 50 ? (
                                                                <p style={{ color: isServiceImage3 ? 'black' : 'inherit' }}>{card?.description?.slice(0, 50) + "... "}
                                                                    <span className="bold" style={{ cursor: "pointer" }} onClick={() => handleReadMore({ desc: card?.description, index })}>Read More</span>
                                                                </p>
                                                            ) : (
                                                                <p style={{ color: isServiceImage3 ? 'black' : 'inherit' }}>{card?.description}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="onboarding-add-serc-btn">
                                                        <button className="selectob-btn bg-body bold" onClick={() => handleModal(card)}>
                                                            <span>Add Services</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <p>No service cards available.</p>
                                    )}
                                {/* </OwlCarousel> */}
                                </Slider>
                            )}
                            {/* <p className="bolder height-60 cursor-pointer"></p> */}
                            {/* Need Custom Service Cards ? */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="amenities-section-btn pb-4" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                <a href="#" className={`services-find-btn ${openServicePanel ? 'faded-btn' : ''}`} onClick={handleServicePanel} style={{ pointerEvents: viewButtonDisabled ? 'none' : 'auto' }}>
                    View Selected Services <span className="arrow"></span>
                </a>
            </div>
            <ClientPortal selector="myportal" show={showPortal}>
                <div className="modalParent nhs-body">
                    <div className="modalContainer">
                        <div className="overlay" onClick={() => setShowPortal(false)}></div>
                        <div className="modal endlife-popup">
                            <div className="modal-header mb-1 justify-content-end border-0 p-0">
                                <a className="removeButton" onClick={() => setShowPortal(false)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                        <path
                                            fill="#ffffff"
                                            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                        ></path>
                                    </svg>
                                </a>
                            </div>
                            <div className="modal-body endlife-popup-padding">
                                <div className="body-title mb-4">
                                    <p className="popup-subtitle text-center hanzipen-font">Select the services covered under {selectedCard?.cardName}, by your facility.</p>
                                </div>
                                <div className="endlife-popup-bg">
                                    <h4 className="text-center bolder mb-4">{selectedCard?.cardName}</h4>
                                    {selectedCard?.factsheets.map((factsheet) => (
                                        <div key={factsheet.factsheetId} className="d-flex justify-content-start align-items-center mb-2">
                                            <div className="anim-switch">
                                                <label className="switchts">
                                                    <input type="checkbox" checked={factsheetStates[factsheet.factsheetId]} onChange={() => handleFactsheetChange(factsheet.factsheetId)} />
                                                    <div className="sliderts">
                                                        <div className="circlets">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="10"
                                                                height="10"
                                                                className="checkmark"
                                                                enable-background="new 0 0 512 512"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M9.707 19.121a.997.997 0 01-1.414 0l-5.646-5.647a1.5 1.5 0 010-2.121l.707-.707a1.5 1.5 0 012.121 0L9 14.171l9.525-9.525a1.5 1.5 0 012.121 0l.707.707a1.5 1.5 0 010 2.121z"
                                                                    data-original="#000000"
                                                                ></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                            {/* <a className="custom-control-label ms-2 bold" href={`${url}${factsheet.factsheet}`} target="_blank" rel="noopener noreferrer"> */}
                                            <span className="custom-control-label ms-2 bold">
                                                {factsheet.factsheet}
                                            </span>
                                        </div>
                                    ))}
                                    <div className="text-center mt-5">
                                        <button className="classic-btn submit-btn" onClick={handleSave}>
                                            <span>Save</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="modal-footer border-0 p-0 mt-4 justify-content-center">
                                <p className="popup-subtitle text-center hanzipen-font ">
                                    <span className="bolder cursor-pointer">Click here </span>to let us know if something is missing !
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </ClientPortal>

            <div className="panel pb-115 review-margin" id="servicepanel" ref={viewSelectedServiceDiv}>
                <div className={`service-detail-panel position-relative px-2 ${openServicePanel ? 'opened' : 'closed'}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 position-relative">
                                <a href="#servicebtn" className="panelclosebtn" onClick={HandleClosePanel} title="close">
                                    <i className="fa-solid fa-xmark"></i>
                                </a>
                                <div className="search">
                                    <input type="text" className="form-control" placeholder="Search Services" value={searchQuery} onChange={handleSearchInputChange} />
                                    <a href="#" title="search" className="searchicon">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </a>
                                </div>
                                <div className="search-tags"></div>

                                {showNoFactsheetsMessage ? (
                                    <div className="text-center">
                                        <p className="mt-4 pb-5">Oops, no fact sheets are selected!</p>
                                    </div>
                                ) : (
                                    <>
                                        <ul className="list-unstyled service-detail-panel-main-list">
                                            {Object.entries(filteredFactsheets)?.map(([cardId, factsheets]) => {
                                                if (factsheets.length > 0) {
                                                    return (
                                                        <li key={cardId}>
                                                            <p className="mb-2">{serviceCards?.find((card) => card.cardId === Number(cardId))?.cardName}</p>
                                                            <ul className="list-unstyled service-detail-panel-list">
                                                                {factsheets?.map((factsheet) => (
                                                                    <li key={factsheet}>
                                                                        <a>
                                                                            {factsheet}
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </li>
                                                    )
                                                }
                                                return null;
                                            })}
                                        </ul>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ServiceOfferView;
