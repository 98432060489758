'use client';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';
import warning from '../../../assets/images/warning.png';
import { publishService } from '../../../service/onboarding-preview';
import { RootState } from '../../../store';
import OnboardingPreviewAmenitiesView from './OnboardingPreviewAmenities/OnboardingPreviewAmenitiesView';
import OnboardingPreviewGalleryView from './OnboardingPreviewGallery/OnboardingPreviewGalleryView';
import OnboardingPreviewHeaderView from './OnboardingPreviewHeader/OnboardingPreviewHeaderView';
import OnboardingPreviewLocationView from './OnboardingPreviewLocation/OnboardingPreviewLocationView';
import OnboardingPreviewOverviewView from './OnboardingPreviewOverview/OnboardingPreviewOverviewView';
import OnboardingPreviewQAView from './OnboardingPreviewQA/OnboardingPreviewQAView';
import OnboardingPreviewServicesView from './OnboardingPreviewServices/OnboardingPreviewServicesView';
import OnboardingPreviewTabView from './OnboardingPreviewTab/OnboardingPreviewTabView';
import ServiceOnboardingPreviewPreviewLoader from './ServiceOnboardingPreviewPreviewLoader';
import { useLocation } from 'react-router-dom';

const ServiceOnboardingPreviewView = () => {
    const { serviceId } = useSelector((state: RootState) => state.onboardingService);
    const { isForPublished } = useSelector((state: RootState) => state.onboardingIsForPublished);
    const location = useLocation();
    const [isDetailsScreen, setIsDetailsScreen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const [showEditPage, setShowEditPage] = useState<any>(DataLogin?.userModel?.modules?.find((item:any)=>(item.moduleName === 'OnBoarding')))
    const handlePublishService = async () => {
        const res = await publishService(serviceId!);
        if (res.status === false) {
            swal({
                title: '',
                text: res?.message,
                icon: warning,
                dangerMode: true
            });
        } else {
            setShowModal(true);
        }
    };

    const showPublishButton = () => {
        if(isDetailsScreen){
            return false;
        } else {
            if(!isForPublished){
                return true;
            } else {
                return showEditPage?.userAccess?.length > 0;
            }
        }
    }

    useEffect(() => {
        if (location.pathname.includes('service-detail')) {
            setIsDetailsScreen(true);
        } else {
            setIsDetailsScreen(false);
        }
    }, [location]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="service-detail-top-section float-button-sec-margin">
            <OnboardingPreviewTabView />
            <OnboardingPreviewHeaderView />
            <OnboardingPreviewGalleryView />
            <OnboardingPreviewOverviewView />
            <OnboardingPreviewAmenitiesView />
            <OnboardingPreviewServicesView />
            <OnboardingPreviewLocationView />
            <OnboardingPreviewQAView />
            {showPublishButton() && <a className="previewpublish-button" target="_blank">
                <button className="btn primary-btn pill-btn d-flex align-items-center ps-4 pe-4" onClick={handlePublishService}>
                    Publish
                </button>
            </a>}
            {showPublishButton() && showModal && <ServiceOnboardingPreviewPreviewLoader showModal={showModal} />}
        </div>
    );
};

export default ServiceOnboardingPreviewView;
