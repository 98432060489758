import React, { useEffect, useState } from 'react';
import { setNavigationAction } from '../../../../../store/dashboard/navigationReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../../store';
import UserDetailContainer from './UserDetail/UserDetailContainer';
import PenPictureContainer from './PenPicture/PenPictureContainer';
import ContractContainer from './ContractDetail/contractContainer';
import eventEmitter from '../../../../../utils/eventEmitter';
import { rightNavigationPage } from '../../../../../utils/constant';
import swal from 'sweetalert';
import warning from '../../../../../assets/images/warning.png';
import ContactDetailsContainer from './ContactDetails/ContactDetailsContainer';
import greenCheck from '../../../../../assets/images/green-check.svg';
import redIcon from '../../../../../assets/images/info-red.svg';

const CreateLeadView = (props: any) => {
    const { t } = useTranslation();
    const { ShowClass, FormName, Data } = useSelector((state: RootState) => state.navigation);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const [userformerror, setuserformerror] = useState();
    const [penformerror, setpenformerror] = useState();
    const [contractformerror, setcontractformerror] = useState();
    const { isNHSReferralTracker } = useSelector((state: RootState) => state.nhsLogin);
    const dispatch = useDispatch();
    const closeSideMenu = () => {
        let obj: any = {
            ShowClass: false,
            FormName: '',
            Data: '',
            ShowToggle: false
        };
        dispatch(setNavigationAction(obj));
    };

    const getuserformerror = (val: any) => {
        setuserformerror(val);
    };
    const getpenformerror = (val: any) => {
        setpenformerror(val);
    };
    const getcontractformerror = (val: any) => {
        setcontractformerror(val);
    };

    return (
        <div>
            {Data.isheader && (
                <>
                    <button onClick={closeSideMenu} className="close_right_content">
                        <i className="fa-solid fa-xmark"></i>{' '}
                    </button>
                    <h3 className="mb-4"> {t('ReferralLead.New_Lead')}</h3>
                </>
            )}
            <div className="referral-height">
                <div className="accordion" id="accordionLead">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button accordion-button-custom" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                {t('ReferralLead.User_Detail')}
                                <iframe src={props.referralId ? typeof DataLeadInfo === 'object' ? userformerror ? greenCheck : redIcon : '' : userformerror ? greenCheck : redIcon} className="ms-auto me-1" width="15" height="15" />

                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionLead">
                            <div className="accordion-body" style={{ pointerEvents: (Data?.fleadstatus === 1 && DataLeadInfo === '') || DataLeadInfo?.data?.leadEditable === true ? 'all' : 'none' }}>
                                <UserDetailContainer getuserdata={props.getuserdata} getuserdataerror={props.getuserdataerror} getuserformerror={getuserformerror} />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button accordion-button-custom collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                {t('ReferralLead.Pen_Picture')}
                                <iframe src={props.referralId ? typeof DataLeadInfo === 'object' ? penformerror ? greenCheck : redIcon : '' : penformerror ? greenCheck : redIcon} className="ms-auto me-1" width="15" height="15" />
                            </button>
                        </h2>
                        {/* style={{ pointerEvents: Data?.fleadstatus === 1 ? 'all' : 'none' }} */}
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionLead">
                            <div className="accordion-body">
                                <PenPictureContainer
                                    getpendata={props.getpendata}
                                    getpendataerror={props.getpendataerror}
                                    userdata={props.userdata}
                                    isenable={Data?.fleadstatus}
                                    getpenformerror={getpenformerror}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                {t('ReferralLead.Funder_Details')}
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionLead">
                            <div className="accordion-body">
                                <ContractContainer
                                    contractdata={props.getcontractdata}
                                    getcontracterror={props.getcontractdataerror}
                                    getfundelisterror={props.getfundelisterror}
                                    selfData={props.selfdata}
                                    deletefunderlist={props.deletefunderlist}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingContactDetails">
                            <button
                                className="accordion-button accordion-button-custom collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#CollapseContactDetails"
                                aria-expanded="false"
                                aria-controls="CollapseContactDetails"
                            >
                                {t('ReferralLead.Contact_Details')}
                                <iframe src={props.referralId ? typeof DataLeadInfo === 'object' ? contractformerror ? greenCheck : redIcon : '' : contractformerror ? greenCheck : redIcon} className="ms-auto me-1" width="15" height="15" />
                            </button>
                        </h2>
                        {/* style={{ pointerEvents: Data?.fleadstatus === 1 ? 'all' : 'none' }} */}
                        <div id="CollapseContactDetails" className="accordion-collapse collapse" aria-labelledby="headingContactDetails" data-bs-parent="#accordionLead">
                            <div className="accordion-body">
                                <ContactDetailsContainer
                                    getcontactdata={props.getcontactdata}
                                    getcontactdataerror={props.getcontactdataerror}
                                    isenable={Data?.fleadstatus}
                                    getcontractformerror={getcontractformerror}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`d-flex justify-content-between  right_content_btn ${(Data?.fleadstatus === 1 && DataLeadInfo === '') || DataLeadInfo?.data?.leadEditable === true ? 'disable-none' : 'disable-all'
                    } `}
                style={{ pointerEvents: (Data?.fleadstatus === 1 && DataLeadInfo === '') || DataLeadInfo?.data?.leadEditable === true ? 'all' : 'none' }}
            >
                {DataLeadInfo?.data?.patientDetail === undefined && (
                    <button disabled={!props.savedisable} onClick={props.addlead} className="btn btn-secondary flex-1 me-2">
                        {t('ReferralLead.Save_btn')}
                    </button>
                )}
                {DataLeadInfo?.data?.patientDetail !== undefined && (
                    <button disabled={!props.savedisable} onClick={props.updatelead} className="btn btn-secondary flex-1 me-2">
                        {t('ReferralLead.Update_btn')}
                    </button>
                )}
                <button
                    disabled={!props.pooldisable}
                    onClick={props.poollead}
                    style={{ pointerEvents: Data?.fleadstatus === 1 ? 'all' : 'none' }}
                    className={`btn btn-primary flex-1 me-2 ${Data?.fleadstatus === 1 ? 'disable-none' : 'disabled'}`}
                >
                    {t('ReferralLead.Pool_btn')} <i className="fa-solid fa-arrow-right ms-1"></i>
                </button>
            </div>
        </div>
    );
};

export default CreateLeadView;
