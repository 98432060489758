import React, { useState, useEffect, useRef } from 'react';
import userImg from '../assets/images/user.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setNavigationAction } from '../store/dashboard/navigationReducer';
import { setPageNavigationAction } from '../store/general/pageNavigationReducer';
import { setServiceDetailAction } from '../store/Auth/serviceDetailReducer';
import { setOrganizationDetailAction } from '../store/Auth/organizationDetailReducer';
import { setRoleSelectedAction } from '../store/Auth/roleSelectedReducer';
import { setLogoutAction } from '../store/Auth/loginReducer';
import { useNavigate } from 'react-router-dom';
import { ROUTES_DATA } from '../navigation/RoutesConstants';
import eventEmitter from '../utils/eventEmitter';
import { ModulesAccessRights, Roles, rightNavigationPage } from '../utils/constant';
import warning from '../assets/images/warning.png';
import service from '../assets/images/service.svg';
import swal from 'sweetalert';
import Nursingicon from '../assets/images/nursing.svg';
import Hospitalicon from '../assets/images/hospital.svg';
import Residentailicon from '../assets/images/Residential.svg';
import Supportedicon from '../assets/images/supported.svg';
import { ServiceTypeIconName } from '../utils/constant';
import { switchRoleService } from '../service/auth-service';
import { removeGridFilterStateAction } from '../store/general/gridFilterStateReducer';
import { removeIntigrationGridFilterStateAction } from '../store/general/integrationgridFilterStateReducer';
import { Tooltip } from 'react-tooltip';
import { setIsBellIconSwingable } from '../store/Notifications/notificationBellSwingReducer';
import { GetNotificationsCount } from '../service/notifications-services';
import ClientPortal from '../components/ClientPortal';


type IHeaderProps = {
    handleSidebar: () => void;
    className: any;
};

const Header: React.FunctionComponent = () => {
    const { ShowClass, FormName } = useSelector((state: RootState) => state.navigation);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { StatusOrganizationDetail, DataOrganizationDetail } = useSelector((state: RootState) => state.organizationDetail);
    const { StatusRoleSelected, DataRoleSelected } = useSelector((state: RootState) => state.roleSelected);
    const { PageName, Data } = useSelector((state: RootState) => state.pageNavigation);
    const { isBellIconSwingable } = useSelector((state: RootState) => state.notificationsBellSwing)
    const { DataNotificationsCount } = useSelector((state: RootState) => state.notificationsCount)
    const [userName, setUserName] = useState<any>('');
    const [editTrigger, setEditTrigger] = useState<any>(false);
    const [changeOrgDropdown, setChangeOrgDropdown] = useState<any>(false);
    const [serviceName, setServiceName] = useState<any>('');
    const [organizationName, setOrganizationName] = useState<any>('');
    const [serviceTypeIcon, setServiceTypeIcon] = useState<any>('');
    const [serviceList, setServiceList] = useState<any>('');
    const [filteredServiceList, setFilteredServiceList] = useState<any>([]);
    const [organizationList, setOrganizationList] = useState<any>('');
    const [roleSelected, setRoleSelected] = useState<any>('');
    const [hasModuleAccess, setHasModuleAccess] = useState<any>(false);

    const [key, setKey] = useState(0);
    useEffect(() => {
        setKey(parseInt(window.sessionStorage.getItem("notificationKey") ? window.sessionStorage.getItem("notificationKey")! : '0'));
    }, []);

    useEffect(() => {
        window.sessionStorage.setItem("notificationKey", key.toString());
    }, [key]);

    const [isCountFetched, setIsCountFetched] = useState(false);
    const bellIconRef: any = useRef(null);
    const notificationAmountRef: any = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSidebar = () => {
        if (!ShowClass) {
            let obj: any = {
                ShowClass: true,
                FormName: '',
                Data: '',
                ShowToggle: true
            };
            dispatch(setNavigationAction(obj));
        } else {
            if (FormName === rightNavigationPage.Vacant && editTrigger === true) {
                swal({
                    title: '',
                    text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true
                }).then(async (confirm: any) => {
                    if (confirm) {
                        setEditTrigger(false);
                        let obj: any = {
                            ShowClass: false,
                            FormName: '',
                            Data: '',
                            ShowToggle: false
                        };
                        dispatch(setNavigationAction(obj));
                    }
                });
            } else if (FormName === rightNavigationPage.Reserved && editTrigger === true) {
                swal({
                    title: '',
                    text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true
                }).then(async (confirm: any) => {
                    if (confirm) {
                        setEditTrigger(false);
                        let obj: any = {
                            ShowClass: false,
                            FormName: '',
                            Data: '',
                            ShowToggle: false
                        };
                        dispatch(setNavigationAction(obj));
                    }
                });
            } else if (FormName === rightNavigationPage.Occupied && editTrigger === true) {
                swal({
                    title: '',
                    text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true
                }).then(async (confirm: any) => {
                    if (confirm) {
                        setEditTrigger(false);
                        let obj: any = {
                            ShowClass: false,
                            FormName: '',
                            Data: '',
                            ShowToggle: false
                        };
                        dispatch(setNavigationAction(obj));
                    }
                });
            } else if (FormName === rightNavigationPage.Maintenance && editTrigger === true) {
                swal({
                    title: '',
                    text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                    icon: warning,
                    buttons: ['No', 'Yes'],
                    dangerMode: true
                }).then(async (confirm: any) => {
                    if (confirm) {
                        setEditTrigger(false);
                        let obj: any = {
                            ShowClass: false,
                            FormName: '',
                            Data: '',
                            ShowToggle: false
                        };
                        dispatch(setNavigationAction(obj));
                    }
                });
            } else {
                let obj: any = {
                    ShowClass: false,
                    FormName: '',
                    Data: '',
                    ShowToggle: false
                };
                dispatch(setNavigationAction(obj));
            }
        }
    };

    const logout = () => {
        window.$utils.signOutUser();
        navigate(ROUTES_DATA.LOGIN);
    };
    const ChangePassword = () => {
        navigate(ROUTES_DATA.CHANGEPASSWORD);
    };



    const organizationChangeHandler = (dataVal: any) => {
        let data: any = {
            organizationName: dataVal.label,
            organizationId: dataVal.value,
            isDefaultOrganization: dataVal.isDefaultOrganization,
            label: dataVal.label,
            value: dataVal.value,
            children: dataVal.children
        };

        let dataService: any = {
            serviceName: '',
            serviceId: '',
            isDefaultService: '',
            serviceTypeName: '',
            serviceTypeId: '',
            iconName: '',
            iqviaServiceEnabled: ''
        };
        data?.children.map((serviceType: any, serviceIndex: any) => {
            if (serviceIndex === 0) {
                serviceType?.children.map((service: any, index: any) => {
                    if (index === 0) {
                        dataService.serviceName = service.label
                        dataService.serviceId = service.value
                        dataService.isDefaultService = service.isDefault
                        dataService.serviceTypeName = serviceType.label
                        dataService.serviceTypeId = serviceType.value
                        dataService.iconName = serviceType.iconName
                        dataService.iqviaServiceEnabled = serviceType.isIQVIAEnabled
                    }
                })
            }
        })
        if (FormName === rightNavigationPage.Vacant && editTrigger === true) {
            swal({
                title: '',
                text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    setEditTrigger(false);
                    let obj: any = {
                        ShowClass: false,
                        FormName: '',
                        Data: '',
                        ShowToggle: false
                    };
                    setChangeOrgDropdown(true);
                    dispatch(setNavigationAction(obj));
                    setOrganizationName(data.organizationName);
                    dispatch(setOrganizationDetailAction(data));
                    setServiceName(dataService.serviceName);
                    setServiceList(data?.children)
                    dispatch(setServiceDetailAction(dataService));
                    setFilteredServiceList([]);
                }
            });
        } else if (FormName === rightNavigationPage.Reserved && editTrigger === true) {
            swal({
                title: '',
                text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    setEditTrigger(false);
                    let obj: any = {
                        ShowClass: false,
                        FormName: '',
                        Data: '',
                        ShowToggle: false
                    };
                    setChangeOrgDropdown(true);
                    dispatch(setNavigationAction(obj));
                    setOrganizationName(data.organizationName);
                    dispatch(setOrganizationDetailAction(data));
                    setServiceName(dataService.serviceName);
                    setServiceList(data?.children)
                    dispatch(setServiceDetailAction(dataService));
                    setFilteredServiceList([]);
                }
            });
        } else if (FormName === rightNavigationPage.Occupied && editTrigger === true) {
            swal({
                title: '',
                text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    setEditTrigger(false);
                    let obj: any = {
                        ShowClass: false,
                        FormName: '',
                        Data: '',
                        ShowToggle: false
                    };
                    setChangeOrgDropdown(true);
                    dispatch(setNavigationAction(obj));
                    setOrganizationName(data.organizationName);
                    dispatch(setOrganizationDetailAction(data));
                    setServiceName(dataService.serviceName);
                    setServiceList(data?.children)
                    dispatch(setServiceDetailAction(dataService));
                    setFilteredServiceList([]);
                }
            });
        } else if (FormName === rightNavigationPage.Maintenance && editTrigger === true) {
            swal({
                title: '',
                text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    setEditTrigger(false);
                    let obj: any = {
                        ShowClass: false,
                        FormName: '',
                        Data: '',
                        ShowToggle: false
                    };
                    setChangeOrgDropdown(true);
                    dispatch(setNavigationAction(obj));
                    setOrganizationName(data.organizationName);
                    dispatch(setOrganizationDetailAction(data));
                    setServiceName(dataService.serviceName);
                    setServiceList(data?.children)
                    dispatch(setServiceDetailAction(dataService));
                    setFilteredServiceList([]);
                }
            });
        } else {
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: '',
                ShowToggle: false
            };
            setChangeOrgDropdown(true);
            dispatch(setNavigationAction(obj));
            setOrganizationName(data.organizationName);
            dispatch(setOrganizationDetailAction(data));
            setServiceName(dataService.serviceName);
            setServiceList(data.children)
            dispatch(setServiceDetailAction(dataService));
            setFilteredServiceList([]);
        }
    };

    const serviceChangeHandler = (dataval: any, val: any) => {
        let data: any = {
            serviceName: dataval.label,
            serviceId: dataval.value,
            isDefaultService: dataval.isDefault,
            serviceTypeName: val.label,
            serviceTypeId: val.value,
            iconName: val.iconName,
            iqviaServiceEnabled: dataval.isIQVIAEnabled
        };
        if (FormName === rightNavigationPage.Vacant && editTrigger === true) {
            swal({
                title: '',
                text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    setEditTrigger(false);
                    let obj: any = {
                        ShowClass: false,
                        FormName: '',
                        Data: '',
                        ShowToggle: false
                    };
                    dispatch(setNavigationAction(obj));
                    setServiceName(data.serviceName);
                    dispatch(setServiceDetailAction(data));
                    setFilteredServiceList([]);
                }
            });
        } else if (FormName === rightNavigationPage.Reserved && editTrigger === true) {
            swal({
                title: '',
                text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    setEditTrigger(false);
                    let obj: any = {
                        ShowClass: false,
                        FormName: '',
                        Data: '',
                        ShowToggle: false
                    };
                    dispatch(setNavigationAction(obj));
                    setServiceName(data.serviceName);
                    dispatch(setServiceDetailAction(data));
                    setFilteredServiceList([]);
                }
            });
        } else if (FormName === rightNavigationPage.Occupied && editTrigger === true) {
            swal({
                title: '',
                text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    setEditTrigger(false);
                    let obj: any = {
                        ShowClass: false,
                        FormName: '',
                        Data: '',
                        ShowToggle: false
                    };
                    dispatch(setNavigationAction(obj));
                    setServiceName(data.serviceName);
                    dispatch(setServiceDetailAction(data));
                    setFilteredServiceList([]);
                }
            });
        } else if (FormName === rightNavigationPage.Maintenance && editTrigger === true) {
            swal({
                title: '',
                text: 'Data/Information entered will be lost. Are you sure you want to continue?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    setEditTrigger(false);
                    let obj: any = {
                        ShowClass: false,
                        FormName: '',
                        Data: '',
                        ShowToggle: false
                    };
                    dispatch(setNavigationAction(obj));
                    setServiceName(data.serviceName);
                    dispatch(setServiceDetailAction(data));
                    setFilteredServiceList([]);
                }
            });
        } else if (PageName === rightNavigationPage.InvoiceDetailPage) {
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: '',
                ShowToggle: false
            };
            dispatch(setNavigationAction(obj));
            setServiceName(data.serviceName);
            dispatch(setServiceDetailAction(data));
            dispatch(removeGridFilterStateAction());
            setFilteredServiceList([]);
            let objPage: any = {
                ShowClass: false,
                PageName: rightNavigationPage.InvoiceTablePage,
                Data: '',
                ShowToggle: false
            };
            dispatch(setPageNavigationAction(objPage));
        } else if (PageName === rightNavigationPage.InvoiceTablePage) {
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: '',
                ShowToggle: false
            };
            dispatch(setNavigationAction(obj));
            setServiceName(data.serviceName);
            dispatch(setServiceDetailAction(data));
            dispatch(removeGridFilterStateAction());
            setFilteredServiceList([]);
            let objPage: any = {
                ShowClass: false,
                PageName: rightNavigationPage.InvoiceTablePage,
                Data: '',
                ShowToggle: false
            };
            dispatch(setPageNavigationAction(objPage));
        }
        else {
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: '',
                ShowToggle: false
            };
            dispatch(setNavigationAction(obj));
            setServiceName(data.serviceName);
            dispatch(setServiceDetailAction(data));
            dispatch(removeIntigrationGridFilterStateAction());
            setFilteredServiceList([]);
        }
        // setServiceName(data.serviceName);
        // dispatch(setServiceDetailAction(data))
    };

    useEffect(() => {
        setUserName(DataLogin.userModel.userName);
        if (StatusLogin === true && StatusServiceDetail === false && DataLogin.userModel.userRoleId === Roles.ServiceManager) {
            // let defaultService = DataLogin.userServiceModels.filter((val: any) => val.isDefaultService === 1);
            let orgObj: any = {
                organizationName: '',
                organizationId: '',
                isDefaultOrganization: '',
                label: '',
                value: '',
                children: ''
            };

            let obj: any = {
                serviceName: '',
                serviceId: '',
                isDefaultService: '',
                serviceTypeName: '',
                serviceTypeId: '',
                iconName: '',
                iqviaServiceEnabled: ''
            };

            DataLogin.userOrganizationModels?.filter((val: any) => {
                if (val.isDefaultOrganization === true) {
                    orgObj.organizationName = val.label;
                    orgObj.organizationId = val.value;
                    orgObj.isDefaultOrganization = val.isDefaultOrganization;
                    orgObj.label = val.label;
                    orgObj.value = val.value;
                    orgObj.children = val.children;
                    val.children?.map((valType: any) => {
                        valType.children?.filter((serviceVal: any) => {
                            if (serviceVal.isDefault === 1) {
                                obj.serviceName = serviceVal.label;
                                obj.serviceId = serviceVal.value;
                                obj.isDefaultService = serviceVal.isDefault;
                                obj.serviceTypeName = valType.lable;
                                obj.serviceTypeId = valType.value;
                                obj.iconName = valType.iconName;
                                obj.iqviaServiceEnabled = serviceVal.isIQVIAEnabled;
                            }
                        });
                    });
                }
            })
            dispatch(setOrganizationDetailAction(orgObj));
            dispatch(setServiceDetailAction(obj));
            setOrganizationList(DataLogin.userOrganizationModels);
            setServiceList(orgObj.children);
            setFilteredServiceList([]);
        } else if (StatusLogin === true && StatusServiceDetail === true && changeOrgDropdown === false && DataLogin.userModel.userRoleId === Roles.ServiceManager) {
            setUserName(DataLogin.userModel.userName);
            setOrganizationList(DataLogin.userOrganizationModels);
            if (DataOrganizationDetail?.children?.length > 0) {
                setServiceList(DataOrganizationDetail?.children);
            } else {
                DataLogin.userOrganizationModels?.filter((val: any) => {
                    if (val.isDefaultOrganization === true) {
                        setServiceList(val.children);
                    }
                })
            }
            setOrganizationName(DataOrganizationDetail.organizationName);
            setServiceName(DataServiceDetail.serviceName);
            setServiceTypeIcon(DataServiceDetail.iconName);
        }
    }, [StatusLogin, DataLogin, StatusServiceDetail, StatusOrganizationDetail, DataOrganizationDetail, DataServiceDetail, DataLogin.userModel.userRoleId]);

    useEffect(() => {
        if (StatusRoleSelected === true) {
            setRoleSelected(DataRoleSelected);
        }
        if (StatusLogin === true && StatusRoleSelected === false) {
            if (DataLogin.userModel.roles.length > 0) {
                let roleObj: any = {
                    roleId: '',
                    roleName: '',
                    isDefaultRole: '',
                    modules: ''
                };
                let rolesData = DataLogin.userModel.roles.map((val: any) => {
                    if (val.isDefaultRole === true) {
                        roleObj.roleId = val.roleId;
                        roleObj.roleName = val.roleName;
                        roleObj.isDefaultRole = val.isDefaultRole;
                        roleObj.modules = DataLogin.userModel.modules;
                    }
                });
                // 
                dispatch(setRoleSelectedAction(roleObj));
            }
        }
    }, [StatusRoleSelected, DataRoleSelected, DataLogin.userModel.userRoleId]);
    const eventCloseEditSideMenu = async () => {
        setEditTrigger(true);
    };

    useEffect(() => {
        eventEmitter.on('eventCloseEditSideMenu', eventCloseEditSideMenu);
        return () => {
            eventEmitter.off('eventCloseEditSideMenu', eventCloseEditSideMenu);
        };
    });

    const eventCloseEditSideMenuOff = async () => {
        setEditTrigger(false);
    };

    useEffect(() => {
        eventEmitter.on('eventCloseEditSideMenuOff', eventCloseEditSideMenuOff);
        return () => {
            eventEmitter.off('eventCloseEditSideMenuOff', eventCloseEditSideMenuOff);
        };
    });

    var string = userName?.split(' ').slice(0, 2).join(' ');
    string = string
        .split(' ')
        .map((part: any[]) => part[0])
        .join('')
        .toUpperCase();

    const eventHandler = (event: any) => {
        event.stopPropagation();
    };

    const changeService = async (roleId: any) => {
        let obj: any = {
            ShowClass: false,
            FormName: '',
            Data: '',
            ShowToggle: false
        };
        dispatch(setNavigationAction(obj));
        await switchRoleService(roleId);
    };

    const onSearchServiceHandler = (event: any) => {
        if (event.target.value !== undefined && event.target.value !== '') {
            let tempServiceList: any = []
            serviceList.map((serviceTypeValue: any, serviceTypeIndex: any) => {
                let newChildren: any = []
                serviceTypeValue.children.map((serviceValue: any) => {
                    if (serviceValue.label.toLowerCase().includes(event.target.value.toLowerCase())) {
                        newChildren.push(serviceValue)
                    }
                })
                tempServiceList[serviceTypeIndex] = { ...serviceTypeValue, children: newChildren }
            })
            setFilteredServiceList(tempServiceList);
        } else {
            setFilteredServiceList([]);
        }
    }

    const handleNotifications = (pageName: any) => {
        if (pageName === rightNavigationPage.Notifications) {
            let obj: any = {
                ShowClass: true,
                FormName: rightNavigationPage.Notifications,
                Data: '',
                ShowToggle: false
            };
            dispatch(setNavigationAction(obj));
        }
    }

    useEffect(() => {
        if (DataNotificationsCount > 0) {
            const interval = setInterval(() => {
                dispatch(setIsBellIconSwingable({
                    isBellIconSwingable: true
                }))
            }, 60000);

            return () => clearInterval(interval);
        }
    }, [DataNotificationsCount]);

    useEffect(() => {
        if (isBellIconSwingable) {
            setKey(prevKey => prevKey + 1);
            setTimeout(() => {
                dispatch(setIsBellIconSwingable({
                    isBellIconSwingable: false
                }))
            }, 3000);
        }
    }, [isBellIconSwingable]);

    useEffect(() => {
        if (DataRoleSelected.roleId === Roles.ReferralManager) {
            setHasModuleAccess(DataLogin?.userModel?.modules?.some((item: any) => item.module === ModulesAccessRights.ReferralManagement.moduleName))
        } else if (DataRoleSelected.roleId === Roles.ServiceManager) {
            setHasModuleAccess(DataLogin?.userModel?.modules?.some((item: any) => item.module === ModulesAccessRights.BedManagement.moduleName))
        }
    }, [DataLogin, DataRoleSelected])

    useEffect(() => {
        GetNotificationCount();
    }, [DataServiceDetail, roleSelected.roleId])

    const GetNotificationCount = async () => {
        await GetNotificationsCount(DataServiceDetail.serviceId ? DataServiceDetail.serviceId : 0)
        setIsCountFetched(true);
    }


    return (
        <div>
            <div className={`${ShowClass ? 'topbar show' : 'topbar'}`}>
                <div className=" d-flex align-items-center justify-content-between top_toggle">
                    <a className="toggle-bar" onClick={handleSidebar}>
                        <i className="fa fa-bars" aria-hidden="true"></i>
                    </a>

                    <div className="d-flex align-items-center">
                        <div className="top-icons">
                            {/* <a href="#" className="icons-badge position-relative">
                                <i className="fa-solid fa-bell"></i>
                                <span className="icon-button__badge">2</span>
                            </a> */}
                            {/* <a href="#">
                                <i className="fa-solid fa-gear"></i>
                            </a>
                            <a href="#">
                                <i className="fa fa-file-export"></i>
                            </a> */}
                        </div>
                        {roleSelected.roleId === Roles.ServiceManager && (
                            <>
                                <div className="gray-text bold me-2">
                                    <ul className="navbar-nav">
                                        <div className="nav-item dropdown">
                                            <a
                                                className="nav-link dropdown-toggle service-dropdown"
                                                href="#"
                                                id="navbarDropdownMenuLink1"
                                                role="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                {organizationName}
                                            </a>
                                            <div className="dropdown-menu service-dropdown-menu" aria-labelledby="navbarDropdownMenuLink1">
                                                {organizationList !== undefined &&
                                                    organizationList.length > 0 &&
                                                    organizationList.map((valSer: any) => {
                                                        return (
                                                            <li key={valSer.value}>
                                                                <a href="#" onClick={() => organizationChangeHandler(valSer)}>
                                                                    {valSer.label}
                                                                </a>
                                                            </li>
                                                        );
                                                    })}

                                            </div>
                                        </div>
                                    </ul>
                                </div>
                                <div className="gray-text bold me-2">
                                    <ul className="navbar-nav">
                                        <div className="nav-item dropdown">
                                            <a
                                                className="nav-link dropdown-toggle service-dropdown"
                                                href="#"
                                                id="navbarDropdownMenuLink1"
                                                role="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                {serviceTypeIcon === ServiceTypeIconName.Hospital && (
                                                    <>
                                                        <iframe src={Hospitalicon} width="17" height="17" className="me-1"></iframe> {serviceName}
                                                    </>
                                                )}
                                                {serviceTypeIcon === ServiceTypeIconName.NursingCare && (
                                                    <>
                                                        <iframe src={Nursingicon} width="17" height="17" className="me-1"></iframe> {serviceName}
                                                    </>
                                                )}
                                                {serviceTypeIcon === ServiceTypeIconName.ResidentialCare && (
                                                    <>
                                                        <iframe src={Residentailicon} width="17" height="17" className="me-1"></iframe> {serviceName}
                                                    </>
                                                )}
                                                {serviceTypeIcon === ServiceTypeIconName.SupportedLiving && (
                                                    <>
                                                        <iframe src={Supportedicon} width="17" height="17" className="me-1"></iframe> {serviceName}
                                                    </>
                                                )}
                                            </a>
                                            <div className="dropdown-menu service-dropdown-menu" aria-labelledby="navbarDropdownMenuLink1">
                                                <div className='me-2 position-relative search-service'>
                                                    <input type="text" placeholder='Search' name="serviceSmartSearch" onChange={(event: any) => onSearchServiceHandler(event)} className='form-control white search-invoice ' />
                                                    <i className="fa-sharp fa-solid fa-magnifying-glass search-icon"></i>
                                                </div>
                                                {filteredServiceList.length === 0 && (
                                                    <div className="accordion" id="accordionExample">
                                                        {serviceList !== undefined &&
                                                            serviceList.length > 0 &&
                                                            serviceList.map((val: any) => {
                                                                if (val.iconName === ServiceTypeIconName.Hospital) {
                                                                    return (
                                                                        <div key={val.value} className="accordion-item">
                                                                            <h2 className="accordion-header" id="headingOne">
                                                                                <button
                                                                                    className="accordion-button collapsed"
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#collapseOne"
                                                                                    aria-expanded="true"
                                                                                    aria-controls="collapseOne"
                                                                                    onClick={eventHandler}
                                                                                >
                                                                                    <iframe src={Hospitalicon} width="17" height="17" className="me-2"></iframe> Hospital
                                                                                </button>
                                                                            </h2>
                                                                            <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                                <div className="accordion-body">
                                                                                    {val.children.length > 0 &&
                                                                                        val.children.map((valSer: any) => {
                                                                                            return (
                                                                                                <a href="#" key={valSer.value} onClick={() => serviceChangeHandler(valSer, val)}>
                                                                                                    {valSer.label}
                                                                                                </a>
                                                                                            );
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }

                                                                if (val.iconName === ServiceTypeIconName.NursingCare) {
                                                                    return (
                                                                        <div key={val.value} className="accordion-item">
                                                                            <h2 className="accordion-header" id="headingTwo">
                                                                                <button
                                                                                    className="accordion-button collapsed"
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#collapseTwo"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapseTwo"
                                                                                    onClick={eventHandler}
                                                                                >
                                                                                    <iframe src={Nursingicon} width="17" height="17" className="me-2"></iframe> Nursing Care
                                                                                </button>
                                                                            </h2>
                                                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                                <div className="accordion-body">
                                                                                    {val.children.length > 0 &&
                                                                                        val.children.map((valSer: any) => {
                                                                                            return (
                                                                                                <a href="#" key={valSer.value} onClick={() => serviceChangeHandler(valSer, val)}>
                                                                                                    {valSer.label}
                                                                                                </a>
                                                                                            );
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }

                                                                if (val.iconName === ServiceTypeIconName.ResidentialCare) {
                                                                    return (
                                                                        <div key={val.value} className="accordion-item">
                                                                            <h2 className="accordion-header" id="headingThree">
                                                                                <button
                                                                                    className="accordion-button collapsed"
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#collapseThree"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapseThree"
                                                                                    onClick={eventHandler}
                                                                                >
                                                                                    <iframe src={Residentailicon} width="17" height="17" className="me-2"></iframe> Residential Care
                                                                                </button>
                                                                            </h2>
                                                                            <div
                                                                                id="collapseThree"
                                                                                className="accordion-collapse collapse"
                                                                                aria-labelledby="headingThree"
                                                                                data-bs-parent="#accordionExample"
                                                                            >
                                                                                <div className="accordion-body">
                                                                                    {val.children.length > 0 &&
                                                                                        val.children.map((valSer: any) => {
                                                                                            return (
                                                                                                <a href="#" key={valSer.value} onClick={() => serviceChangeHandler(valSer, val)}>
                                                                                                    {valSer.label}
                                                                                                </a>
                                                                                            );
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }

                                                                if (val.iconName === ServiceTypeIconName.SupportedLiving) {
                                                                    return (
                                                                        <div key={val.value} className="accordion-item">
                                                                            <h2 className="accordion-header" id="headingFour">
                                                                                <button
                                                                                    className="accordion-button collapsed"
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#collapseFour"
                                                                                    aria-expanded="false"
                                                                                    aria-controls="collapseFour"
                                                                                    onClick={eventHandler}
                                                                                >
                                                                                    <iframe src={Supportedicon} width="17" height="17" className="me-2"></iframe> Supported Living
                                                                                </button>
                                                                            </h2>
                                                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                                <div className="accordion-body">
                                                                                    {val.children.length > 0 &&
                                                                                        val.children.map((valSer: any) => {
                                                                                            return (
                                                                                                <a key={valSer.value} href="#" onClick={() => serviceChangeHandler(valSer, val)}>
                                                                                                    {valSer.label}
                                                                                                </a>
                                                                                            );
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            })}
                                                    </div>
                                                )}
                                                {filteredServiceList.length > 0 && (
                                                    <div className="accordion" id="accordionExample">
                                                        {filteredServiceList !== undefined &&
                                                            filteredServiceList.length > 0 &&
                                                            filteredServiceList.map((val: any) => {
                                                                if (val.iconName === ServiceTypeIconName.Hospital) {
                                                                    return (
                                                                        <div key={val.value} className="accordion-item">
                                                                            <h2 className="accordion-header" id="headingOne">
                                                                                <button
                                                                                    className={`accordion-button ${val.children.length === 0 ? 'collapsed' : ''}`}
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#collapseOne"
                                                                                    aria-expanded={`${val.children.length === 0 ? 'false' : 'true'}`}
                                                                                    aria-controls="collapseOne"
                                                                                    onClick={eventHandler}
                                                                                >
                                                                                    <iframe src={Hospitalicon} width="17" height="17" className="me-2"></iframe> Hospital
                                                                                </button>
                                                                            </h2>
                                                                            <div id="collapseOne" className={`accordion-collapse collapse ${val.children.length === 0 ? '' : 'show'}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                                <div className="accordion-body">
                                                                                    {val.children.length > 0 &&
                                                                                        val.children.map((valSer: any) => {
                                                                                            return (
                                                                                                <a href="#" key={valSer.value} onClick={() => serviceChangeHandler(valSer, val)}>
                                                                                                    {valSer.label}
                                                                                                </a>
                                                                                            );
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }

                                                                if (val.iconName === ServiceTypeIconName.NursingCare) {
                                                                    return (
                                                                        <div key={val.value} className="accordion-item">
                                                                            <h2 className="accordion-header" id="headingTwo">
                                                                                <button
                                                                                    className={`accordion-button ${val.children.length === 0 ? 'collapsed' : ''}`}
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#collapseTwo"
                                                                                    aria-expanded={`${val.children.length === 0 ? 'false' : 'true'}`}
                                                                                    aria-controls="collapseTwo"
                                                                                    onClick={eventHandler}
                                                                                >
                                                                                    <iframe src={Nursingicon} width="17" height="17" className="me-2"></iframe> Nursing Care
                                                                                </button>
                                                                            </h2>
                                                                            <div id="collapseTwo" className={`accordion-collapse collapse ${val.children.length === 0 ? '' : 'show'}`} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                                                <div className="accordion-body">
                                                                                    {val.children.length > 0 &&
                                                                                        val.children.map((valSer: any) => {
                                                                                            return (
                                                                                                <a href="#" key={valSer.value} onClick={() => serviceChangeHandler(valSer, val)}>
                                                                                                    {valSer.label}
                                                                                                </a>
                                                                                            );
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }

                                                                if (val.iconName === ServiceTypeIconName.ResidentialCare) {
                                                                    return (
                                                                        <div key={val.value} className="accordion-item">
                                                                            <h2 className="accordion-header" id="headingThree">
                                                                                <button
                                                                                    className={`accordion-button ${val.children.length === 0 ? 'collapsed' : ''}`}
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#collapseThree"
                                                                                    aria-expanded={`${val.children.length === 0 ? 'false' : 'true'}`}
                                                                                    aria-controls="collapseThree"
                                                                                    onClick={eventHandler}
                                                                                >
                                                                                    <iframe src={Residentailicon} width="17" height="17" className="me-2"></iframe> Residential Care
                                                                                </button>
                                                                            </h2>
                                                                            <div
                                                                                id="collapseThree"
                                                                                className={`accordion-collapse collapse ${val.children.length === 0 ? '' : 'show'}`}
                                                                                aria-labelledby="headingThree"
                                                                                data-bs-parent="#accordionExample"
                                                                            >
                                                                                <div className="accordion-body">
                                                                                    {val.children.length > 0 &&
                                                                                        val.children.map((valSer: any) => {
                                                                                            return (
                                                                                                <a href="#" key={valSer.value} onClick={() => serviceChangeHandler(valSer, val)}>
                                                                                                    {valSer.label}
                                                                                                </a>
                                                                                            );
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }

                                                                if (val.iconName === ServiceTypeIconName.SupportedLiving) {
                                                                    return (
                                                                        <div key={val.value} className="accordion-item">
                                                                            <h2 className="accordion-header" id="headingFour">
                                                                                <button
                                                                                    className={`accordion-button ${val.children.length === 0 ? 'collapsed' : ''}`}
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target="#collapseFour"
                                                                                    aria-expanded={`${val.children.length === 0 ? 'false' : 'true'}`}
                                                                                    aria-controls="collapseFour"
                                                                                    onClick={eventHandler}
                                                                                >
                                                                                    <iframe src={Supportedicon} width="17" height="17" className="me-2"></iframe> Supported Living
                                                                                </button>
                                                                            </h2>
                                                                            <div id="collapseFour" className={`accordion-collapse collapse ${val.children.length === 0 ? '' : 'show'}`} aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                                <div className="accordion-body">
                                                                                    {val.children.length > 0 &&
                                                                                        val.children.map((valSer: any) => {
                                                                                            return (
                                                                                                <a key={valSer.value} href="#" onClick={() => serviceChangeHandler(valSer, val)}>
                                                                                                    {valSer.label}
                                                                                                </a>
                                                                                            );
                                                                                        })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </ul>
                                </div>

                                <div className="purple-text bold">|</div>

                            </>
                        )}
                        {hasModuleAccess && isCountFetched && <div key={key} className={`${isBellIconSwingable && DataNotificationsCount > 0 ? 'bell-icon' : 'bell-icon-without-animation'}`} onClick={() => handleNotifications(rightNavigationPage.Notifications)} data-tooltip-id="my-tooltip-notifications" data-tooltip-content="Notifications">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="30px" viewBox="0 0 50 30" enable-background="new 0 0 50 30" xmlSpace="preserve">
                                <g className="bell-icon__group">
                                    <path className="bell-icon__ball" id="ball" fill-rule="evenodd" stroke-width="1.5" clip-rule="evenodd" fill="none" stroke="#currentColor" stroke-miterlimit="10" d="M28.7,25 c0,1.9-1.7,3.5-3.7,3.5s-3.7-1.6-3.7-3.5s1.7-3.5,3.7-3.5S28.7,23,28.7,25z" />
                                    <path className="bell-icon__shell" id="shell" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" stroke="#currentColor" stroke-width="2" stroke-miterlimit="10" d="M35.9,21.8c-1.2-0.7-4.1-3-3.4-8.7c0.1-1,0.1-2.1,0-3.1h0c-0.3-4.1-3.9-7.2-8.1-6.9c-3.7,0.3-6.6,3.2-6.9,6.9h0 c-0.1,1-0.1,2.1,0,3.1c0.6,5.7-2.2,8-3.4,8.7c-0.4,0.2-0.6,0.6-0.6,1v1.8c0,0.2,0.2,0.4,0.4,0.4h22.2c0.2,0,0.4-0.2,0.4-0.4v-1.8 C36.5,22.4,36.3,22,35.9,21.8L35.9,21.8z" />
                                </g>
                            </svg>
                            {DataNotificationsCount > 0 && <div className={`${isBellIconSwingable && DataNotificationsCount > 0 && key <= 1 ? 'notification-amount' : 'without-notification-amount'}`}>
                                <span>{DataNotificationsCount > 9 ? '9+' : DataNotificationsCount}</span>
                                <span className="second"></span>
                                <span className="second two"></span>
                                <span className="second three"></span>
                                <span className="second four"></span>
                                <span className="second five"></span>
                            </div>}
                        </div>}
                        <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-notifications" />
                        <div className="user me-2">
                            <ul className="navbar-nav">
                                <div className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="user_img">{string}</div>
                                    </a>
                                    <div className="dropdown-menu profile-dw-css" aria-labelledby="navbarDropdownMenuLink">
                                        <a className="dropdown-item" href="#">
                                            <i className="fa-solid fa-user text-center"></i>
                                            <span className="ms-2">{userName || ''}</span>
                                        </a>
                                        <a className="dropdown-item" onClick={ChangePassword}>
                                        <svg
                                                enable-background="new 0 0 1080 1080"
                                                width="18"
                                                version="1.1"
                                                viewBox="0 0 1080 1080"
                                                id="Layer_1"
                                                xmlSpace="preserve"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <style type="text/css">{`
                                            .st0{fill:#242424;}
                                            `}</style>
                                                <g>
                                                    <path
                                                        d="M5.4,392.7C17,314,52.7,238.6,102.3,177c20.8-25.1,43.8-49.1,68-68.5c42.7-34.2,92.2-61.6,144.1-79.9
                                                                c63.4-24,128-33.1,194.9-27.4c160.3,13.7,283.6,89,370.1,221.5c41.5,62.8,63.4,133.6,70.3,207.8c3.5,36.5,2.3,71.9-3.5,108.5
                                                                c0,1.1,0,3.4-1.2,4.6c-21.9-3.4-43.8-6.8-64.6-10.3c17.3-153-31.1-280.8-153.3-379c-83-66.2-178.7-94.8-284.8-86.8
                                                                C228,84.5,63.1,262.6,64.2,476.1c1.2,123.3,48.4,226,143,307.1c93.4,81.1,204.1,110.7,326.3,94.8c3.5,21.7,6.9,43.4,10.4,65.1
                                                                c-9.2,1.1-18.4,2.3-27.7,3.4c-168.3,17.1-336.7-61.6-433.5-202.1c-34.6-49.1-56.5-102.7-71.5-159.8c-10.4-38.8-12.7-79.9-10.4-121
                                                                C0.8,440.7,2,416.7,5.4,392.7z"
                                                    />
                                                    <path
                                                        d="M1080,895.1c-17.3,3.4-33.4,6.8-50.7,10.3c-3.5,1.1-5.8,2.3-6.9,5.7c-2.3,8-5.8,14.8-9.2,22.8
                                                                c-1.2,3.4-1.2,5.7,1.2,8c9.2,12.6,17.3,25.1,26.5,37.7c5.8,8,4.6,12.6-1.2,19.4c-11.5,11.4-23.1,22.8-34.6,34.2
                                                                c-5.8,5.7-10.4,6.8-18.4,1.1c-13.8-9.1-26.5-18.3-40.4-27.4c-2.3-1.1-4.6-1.1-6.9-1.1c-8.1,3.4-16.1,5.7-24.2,10.3
                                                                c-2.3,1.1-4.6,3.4-4.6,5.7c-3.5,14.8-5.8,29.7-9.2,45.7c-2.3,10.3-5.8,12.6-16.1,12.6c-16.1,0-32.3,0-48.4,0
                                                                c-9.2,0-12.7-3.4-15-12.6c-2.3-14.8-5.8-30.8-8.1-45.7c-1.2-4.6-2.3-5.7-6.9-6.8c-6.9-2.3-13.8-5.7-20.8-9.1
                                                                c-3.5-1.1-5.8-1.1-9.2,1.1c-12.7,9.1-25.4,17.1-38,26.3c-8.1,5.7-12.7,5.7-19.6-1.1c-11.5-11.4-23.1-22.8-34.6-34.2
                                                                c-5.8-5.7-6.9-11.4-2.3-18.3c9.2-12.6,17.3-26.3,26.5-38.8c2.3-3.4,2.3-5.7,1.2-9.1c-3.5-6.8-6.9-14.8-9.2-21.7
                                                                c-1.2-3.4-3.5-4.6-6.9-5.7c-15-2.3-31.1-5.7-46.1-9.1c-9.2-1.1-12.7-5.7-12.7-13.7c0-16,0-33.1,0-49.1c0-9.1,3.5-12.6,12.7-13.7
                                                                c15-3.4,31.1-5.7,46.1-9.1c4.6-1.1,5.8-2.3,6.9-5.7c2.3-6.8,4.6-13.7,8.1-20.5c2.3-4.6,1.2-6.8-1.2-10.3
                                                                c-9.2-12.6-17.3-25.1-26.5-37.7c-5.8-8-4.6-12.6,2.3-19.4c11.5-11.4,23.1-22.8,34.6-34.2c5.8-5.7,11.5-6.8,18.4-2.3
                                                                c12.7,9.1,26.5,17.1,39.2,26.3c3.5,2.3,5.8,2.3,9.2,1.1c6.9-3.4,13.8-6.8,21.9-9.1c3.5-1.1,5.8-3.4,5.8-6.8
                                                                c2.3-14.8,5.8-30.8,8.1-45.7c1.2-9.1,5.8-12.6,15-12.6c16.1,0,32.3,0,48.4,0c10.4,0,13.8,3.4,16.1,12.6c2.3,14.8,5.8,30.8,8.1,45.7
                                                                c1.2,3.4,2.3,5.7,5.8,6.8c8.1,3.4,16.1,6.8,24.2,9.1c2.3,1.1,5.8,1.1,8.1-1.1c13.8-9.1,26.5-18.3,39.2-26.3
                                                                c8.1-5.7,12.7-4.6,19.6,1.1c11.5,11.4,23.1,22.8,34.6,34.2c5.8,6.8,6.9,11.4,1.2,18.3c-9.2,12.6-17.3,26.3-26.5,38.8
                                                                c-2.3,3.4-2.3,5.7-1.2,9.1c3.5,6.8,5.8,14.8,9.2,21.7c1.2,2.3,2.3,4.6,4.6,4.6c17.3,3.4,35.7,6.8,53,9.1
                                                                C1080,844.8,1080,869.9,1080,895.1z M943.9,857.4c0-45.7-38-82.2-84.2-82.2c-46.1,0-83,37.7-83,83.3s38,82.2,83,82.2
                                                                C907.1,940.7,943.9,903,943.9,857.4z"
                                                    />
                                                    <path
                                                        d="M451.6,709c-34.6,0-68-1.1-102.6,0c-47.3,2.3-78.4-36.5-78.4-76.5c0-61.6-1.2-123.3,0-186.1
                                                                c0-36.5,23.1-65.1,55.3-74.2c3.5-1.1,10.4-1.1,11.5-3.4c2.3-3.4,1.2-8,1.2-12.6c1.2-24,0-47.9,3.5-71.9
                                                                c4.6-33.1,18.4-62.8,42.7-85.6c21.9-19.4,47.3-28.5,76.1-30.8c26.5-2.3,53,0,77.2,12.6c31.1,14.8,50.7,41.1,63.4,73.1
                                                                c8.1,20.5,11.5,42.2,11.5,63.9c0,16,0,32,0,47.9c0,3.4,0,5.7,4.6,5.7c34.6,4.6,63.4,34.2,63.4,74.2c1.2,45.7,0,91.3,0,137
                                                                c0,2.3-2.3,5.7-3.5,6.8c-43.8,30.8-78.4,68.5-103.8,115.3c-2.3,3.4-4.6,3.4-8.1,3.4C526.6,709,489.7,709,451.6,709z M503.5,640.5
                                                                c0-2.3,0-4.6,0-6.8c0-21.7,0-42.2,0-63.9c0-9.1,1.2-14.8,10.4-19.4c5.8-2.3,10.4-8,15-12.6c13.8-13.7,17.3-30.8,12.7-50.2
                                                                c-12.7-47.9-74.9-65.1-111.8-32c-26.5,22.8-30,58.2-6.9,82.2c5.8,5.7,12.7,11.4,19.6,16c3.5,2.3,4.6,4.6,4.6,9.1
                                                                c0,24,0,46.8,0,70.8c0,2.3,0,4.6,0,8C465.5,640.5,483.9,640.5,503.5,640.5z M543.9,369.9c-1.2-27.4-1.2-54.8-2.3-82.2
                                                                c0-1.1,0-1.1,0-2.3c-5.8-26.3-21.9-44.5-48.4-47.9c-12.7-2.3-25.4-1.1-36.9,0c-20.8,3.4-35.7,14.8-42.7,35.4
                                                                c-3.5,10.3-5.8,21.7-5.8,33.1c-1.2,19.4,0,38.8,0,57.1c0,2.3,0,4.6,0,6.8C452.8,369.9,497.7,369.9,543.9,369.9z"
                                                    />
                                                </g>
                                            </svg>
                                            <span className="ms-2">Change Password </span>
                                        </a>
                                        <a className="dropdown-item" href="#" onClick={logout}>
                                            <i className="fa-solid fa-right-from-bracket text-center"></i>
                                            <span className="ms-2">Log Out</span>
                                        </a>
                                    </div>
                                </div>
                            </ul>
                        </div>
                        {DataLogin?.userModel?.roles?.some((item: any) => item.roleId === Roles.ReferralManager) === true && DataLogin?.userModel?.roles?.some((item: any) => item.roleId === Roles.ServiceManager) === true && (
                            <div className="user d-flex align-items-center me-3">
                                <ul className="navbar-nav">
                                    <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-notifications" />
                                    <div className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle bold" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div>
                                                {roleSelected.roleName !== undefined && roleSelected.roleName !== '' && roleSelected.roleName.trim().split(' ')[0] !== undefined
                                                    ? roleSelected.roleName.trim().split(' ')[0].charAt(0)
                                                    : ''}
                                                {roleSelected.roleName !== undefined && roleSelected.roleName !== '' && roleSelected.roleName.trim().split(' ')[1] !== undefined
                                                    ? roleSelected.roleName.trim().split(' ')[1].charAt(0)
                                                    : ''}
                                                <i className="fa-solid fa-chevron-down small-text ms-2"></i>
                                            </div>
                                        </a>
                                        <div className="dropdown-menu profile-dw-css" aria-labelledby="navbarDropdownMenuLink">
                                            {roleSelected.roleId !== Roles.ServiceManager && roleSelected.roleId !== Roles.NHS && (
                                                <a className="dropdown-item" href="#" onClick={() => changeService(Roles.ServiceManager)}>
                                                    <span className="ms-1">SM - Service Manager</span>
                                                </a>
                                            )}
                                            {roleSelected.roleId !== Roles.ReferralManager && roleSelected.roleId !== Roles.NHS && (
                                                <a className="dropdown-item" href="#" onClick={() => changeService(Roles.ReferralManager)}>
                                                    <span className="ms-1">RM - Referral Manager</span>
                                                </a>
                                            )}
                                            {DataLogin?.userModel?.roles?.some((item: any) => item.roleId === Roles.Admin) === true && roleSelected.roleId !== Roles.Admin && (
                                                <a className="dropdown-item" href="#" onClick={() => changeService(Roles.Admin)}>
                                                    <span className="ms-1">LA - Locate Admin</span>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
