import React, { useEffect, useState } from 'react';
import CreateLeadView from './CreateLeadView';
import swal from 'sweetalert';
import warning from '../../../../../assets/images/warning.png';
import { getPatientTypeData } from '../../../../../service/dashboard-service';
import { addLead } from '../../../../../service/leads';
import { useDispatch, useSelector } from 'react-redux';
import { setNavigationAction } from '../../../../../store/dashboard/navigationReducer';
import { RootState } from '../../../../../store';
import eventEmitter from '../../../../../utils/eventEmitter';

const CreateLeadContainer = (props: any) => {
    const [userdata, setuserdata] = useState<any>();
    const [userdataerror, setuserdataerror] = useState<any>();
    const [pendata, setpendata] = useState<any>();
    const [pendataerror, setpendataerror] = useState<any>();
    const [contractdata, setcontractdata] = useState<any>();
    const [contractdataerror, setcontractdataerror] = useState<any>();
    const [funderlisterror, setfunderlisterror] = useState<any>();
    const [savedisable, setsavedisable] = useState(false);
    const [pooldisable, setpooldisable] = useState(false);
    const [deletedfunderlist, setdeletedfunderlist] = useState<any>();
    const [contactdata, setcontactdata] = useState<any>();
    const [contactdataerror, setcontactdataerror] = useState<any>();
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const dispatch = useDispatch();
    useEffect(() => {
        async function getDropdowns() {
            await getPatientTypeData();
        }
        getDropdowns();
    }, []);

    const getuserdata = (userdata: any) => {

        setuserdata(userdata);
    };

    const getpendata = (pendata: any) => {

        setpendata(pendata);
    };

    const getcontractdata = (condata: any) => {

        setcontractdata(condata);
    };

    const getcontactdata = (contactdata: any) => {

        setcontactdata(contactdata);
    };
    const getuserdataerror = (userdataerror: any) => {
        setuserdataerror(userdataerror);
    };

    const getpendataerror = (pendataerror: any) => {
        setpendataerror(pendataerror);
    };

    const getcontractdataerror = (conerror: any) => {
        setcontractdataerror(conerror);
    };
    const getfundelisterror = (funerror: any) => {
        setfunderlisterror(funerror);
    };

    const getcontactdataerror = (contactdataerror: any) => {
        setcontactdataerror(contactdataerror);
    };

    useEffect(() => {


        if (userdataerror && pendataerror && contactdataerror) {
            setsavedisable(true);
            setpooldisable(true);
        } else {
            setsavedisable(false);
            setpooldisable(false);
        }
    }, [userdataerror, pendataerror, contactdataerror]);

    const savealert = () => {
        alertHandler(`Are you sure you want to save the lead?`, 'add');
    };

    const poolalert = () => {
        if (props.referralId != undefined) {
            alertHandler(`Are you sure you want to pool the lead ${props.referralId}?`, 'pool');
        }
        else {
            alertHandler(`Are you sure you want to pool the lead?`, 'pool');
        }
    };
    const updatealert = () => {
        alertHandler(`Are you sure you want to save the lead ${props.referralId}?`, 'update');
    };


    const alertHandler = (text: any, operation: any) => {
        swal({
            title: '',
            text: text,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                if (operation === 'add' || operation === 'update') {
                    addlead();
                } else {
                    poollead();
                }
            } else {
            }
        });
    };

    async function addlead() {
        

        let contactobj = {
            referralSourceId: Number(contactdata?.referralSourceId) || 0,
            referralSourceDescription: contactdata?.referralSourceDescription,
        };
        if (contractdata?.ListData?.length > 0) {
            var fundarr = [];
            for (let i = 0; i < contractdata?.ListData.length; i++) {
                let funderdata = {
                    funderId: contractdata?.ListData[i]?.funderData?.ftype === '4' ? null : Number(contractdata?.ListData[i]?.funderData?.fFunder),
                    funderEmail: contractdata?.ListData[i]?.funderData?.fEmailId || '',
                    funderPhoneNo: contractdata?.ListData[i]?.funderData?.fContactNo || '',
                    funderAddressLine1: contractdata?.ListData[i]?.funderData?.fAddress1 || '',
                    // "funderCountyId": Number(contractdata?.ListData[i]?.funderData?.fCity),
                    funderCountyId:
                        contractdata?.ListData[i]?.funderData?.fCity === '' || contractdata?.ListData[i]?.funderData?.fCity === undefined || Number(contractdata?.ListData[i]?.funderData?.fCity) === 0
                            ? null
                            : Number(contractdata?.ListData[i]?.funderData?.fCity),
                    funderName: contractdata?.ListData[i]?.funderData?.ftype === '4' ? contractdata?.ListData[i]?.funderData?.fFunderVal : '',
                    isSelfFunded: contractdata?.ListData[i]?.funderData?.ftype === '4' ? 1 : 0,
                    funderPostalCode: contractdata?.ListData[i]?.funderData?.fPoBox || '',
                    commissionerName: contractdata?.ListData[i]?.commisionerData?.cFunder || '',
                    commissionerEmail: contractdata?.ListData[i]?.commisionerData?.cEmailId || '',
                    commissionerPhoneNo: contractdata?.ListData[i]?.commisionerData?.cContactNo || '',
                    commissionerAddressLine1: contractdata?.ListData[i]?.commisionerData?.cAddress1 || '',
                    // "commissionerCountyId": Number(contractdata?.ListData[i]?.commisionerData?.cCity || ''),
                    commissionerCountyId:
                        contractdata?.ListData[i]?.commisionerData?.cCity === '' ||
                            contractdata?.ListData[i]?.commisionerData?.cCity === undefined ||
                            Number(contractdata?.ListData[i]?.commisionerData?.cCity) === 0
                            ? null
                            : Number(contractdata?.ListData[i]?.commisionerData?.cCity),
                    commissionerPostalCode: contractdata?.ListData[i]?.commisionerData?.cPoBox || '',
                    socialWorkerName: contractdata.ListData[i].commisionerData.sFunder,
                    socialWorkerEmail: contractdata.ListData[i].commisionerData.sEmailId,
                    socialWorkerPhoneNo: contractdata.ListData[i].commisionerData.sContactNo,
                    socialWorkerAddressLine1: contractdata.ListData[i].commisionerData.sAddress1,
                    socialWorkerCountyId: contractdata.ListData[i].commisionerData.sCity !== '' ? Number(contractdata.ListData[i].commisionerData.sCity) : null,
                    socialWorkerCountryId: null,
                    socialWorkerPostalCode: contractdata.ListData[i].commisionerData.sPoBox,
                    suid: contractdata.ListData[i]?.funderData?.fsuid,
                    note: contractdata.ListData[i]?.funderData?.fnote,
                    pO_Reference: contractdata.ListData[i]?.funderData?.fpo,
                };

                fundarr.push(funderdata);
            }
        }

        const addpayload = {
            patientDetail: {
                leadId: DataLeadInfo === undefined ? 0 : DataLeadInfo?.data?.patientDetail?.leadId,
                patientName: userdata?.name || '',
                patientTypeId: Number(userdata?.patienttype) || 0,
                serviceTypeId: Number(userdata?.servicetype) || 0,
                dateOfBirth: userdata?.dob ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(userdata.dob)) : null,
                leadDate: userdata?.leaddate ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(userdata.leaddate)) : '',
                gender: userdata?.gender !== 0 ? Number(userdata?.gender) : null,
                nhsId: userdata?.NhsId || '',
                localId: userdata?.LocalId || '',
                contactNo: userdata?.ContactNo || '',
                email: userdata?.EmailId || '',
                countyId: userdata?.City === undefined || Number(userdata?.City) === 0 ? null : Number(userdata?.City),
                pobox: userdata?.PoBox,
                addressLine1: userdata?.Address1,
                addressLine2: userdata?.Address2,
                penPictureDescription: 'string',
                submitToPool: false,
                organizationId: 1,
                estDateOfArrival: userdata?.estDateOfArrival ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(userdata.estDateOfArrival)) : null
            },
            penPictureDetailModel: {
                description: pendata?.desc?.desc,
                listPenPictureList: pendata?.attachments?.files ? pendata?.attachments?.files?.map((item: any) => ({
                    leadPenPicture_Id: item.leadPenPicture_Id,
                    documentPath: item?.filepath,
                    documentName: item?.filename
                })) : []
            },
            leadFunderDetailsList: contractdata?.ListData?.length === 0 ? [] : fundarr,
            referralContactDetailModel: contactobj
        };
        const res = await addLead(addpayload);

        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            });
            eventEmitter.emit('onAddNewLeadRM');
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: '',
                ShowToggle: false
            };
            dispatch(setNavigationAction(obj));
        } else {
            swal(res.message, {
                icon: 'error'
            });
        }
    }
    //@ts-ignore
    function isValidDate(d) {
        //@ts-ignore
        return d instanceof Date && !isNaN(d);
    }

    const deletefunderlist = (ddata: any) => {

        setdeletedfunderlist(ddata);
    }

    async function poollead() {
        let contactobj = {
            referralSourceId: Number(contactdata?.referralSourceId) || 0,
            referralSourceDescription: contactdata?.referralSourceDescription,
        };
        if (contractdata?.ListData?.length > 0) {
            var fundarr = [];
            for (let i = 0; i < contractdata?.ListData.length; i++) {
                let funderdata = {
                    funderId: contractdata?.ListData[i]?.funderData?.ftype === '4' ? null : Number(contractdata?.ListData[i]?.funderData?.fFunder),
                    funderEmail: contractdata?.ListData[i]?.funderData?.fEmailId || '',
                    funderPhoneNo: contractdata?.ListData[i]?.funderData?.fContactNo || '',
                    funderAddressLine1: contractdata?.ListData[i]?.funderData?.fAddress1 || '',
                    funderCountyId:
                        contractdata?.ListData[i]?.funderData?.fCity === '' || contractdata?.ListData[i]?.funderData?.fCity === undefined || Number(contractdata?.ListData[i]?.funderData?.fCity) === 0
                            ? null
                            : Number(contractdata?.ListData[i]?.funderData?.fCity),
                    funderName: contractdata?.ListData[i]?.funderData?.ftype === '4' ? contractdata?.ListData[i]?.funderData?.fFunderVal : '',
                    isSelfFunded: contractdata?.ListData[i]?.funderData?.ftype === '4' ? 1 : 0,
                    funderPostalCode: contractdata?.ListData[i]?.funderData?.fPoBox || '',
                    commissionerName: contractdata?.ListData[i]?.commisionerData?.cFunder || '',
                    commissionerEmail: contractdata?.ListData[i]?.commisionerData?.cEmailId || '',
                    commissionerPhoneNo: contractdata?.ListData[i]?.commisionerData?.cContactNo || '',
                    commissionerAddressLine1: contractdata?.ListData[i]?.commisionerData?.cAddress1 || '',
                    commissionerCountyId:
                        contractdata?.ListData[i]?.commisionerData?.cCity === '' ||
                            contractdata?.ListData[i]?.commisionerData?.cCity === undefined ||
                            Number(contractdata?.ListData[i]?.commisionerData?.cCity) === 0
                            ? null
                            : Number(contractdata?.ListData[i]?.commisionerData?.cCity),
                    commissionerPostalCode: contractdata?.ListData[i]?.commisionerData?.cPoBox || '',
                    socialWorkerName: contractdata.ListData[i].commisionerData.sFunder,
                    socialWorkerEmail: contractdata.ListData[i].commisionerData.sEmailId,
                    socialWorkerPhoneNo: contractdata.ListData[i].commisionerData.sContactNo,
                    socialWorkerAddressLine1: contractdata.ListData[i].commisionerData.sAddress1,
                    socialWorkerCountyId: contractdata.ListData[i].commisionerData.sCity !== '' ? Number(contractdata.ListData[i].commisionerData.sCity) : null,
                    socialWorkerCountryId: null,
                    socialWorkerPostalCode: contractdata.ListData[i].commisionerData.sPoBox,
                    suid: contractdata.ListData[i]?.funderData?.fsuid,
                    note: contractdata.ListData[i]?.funderData?.fnote,
                    pO_Reference: contractdata.ListData[i]?.funderData?.fpo,
                };

                fundarr.push(funderdata);
            }
        }

        const addpayload = {
            patientDetail: {
                leadId: DataLeadInfo === undefined ? 0 : DataLeadInfo?.data?.patientDetail?.leadId,
                patientName: userdata?.name || '',
                patientTypeId: Number(userdata?.patienttype) || 0,
                serviceTypeId: Number(userdata?.servicetype) || 0,
                dateOfBirth: userdata?.dob ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(userdata.dob)) : null,
                leadDate: userdata?.leaddate ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(userdata.leaddate)) : '',
                gender: userdata?.gender !== 0 ? Number(userdata?.gender) : null,
                nhsId: userdata?.NhsId || '',
                localId: userdata?.LocalId || '',
                contactNo: userdata?.ContactNo || '',
                email: userdata?.EmailId || '',
                countyId: userdata?.City === undefined || Number(userdata?.City) === 0 ? null : Number(userdata?.City),
                pobox: userdata?.PoBox,
                addressLine1: userdata?.Address1,
                addressLine2: userdata?.Address2,
                penPictureDescription: 'string',
                submitToPool: true,
                organizationId: 1,
                estDateOfArrival: userdata?.estDateOfArrival ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(userdata.estDateOfArrival)) : null
            },
            penPictureDetailModel: {
                description: pendata?.desc?.desc,
                listPenPictureList: pendata?.attachments?.files ? pendata?.attachments?.files?.map((item: any) => ({
                    leadPenPicture_Id: item.leadPenPicture_Id,
                    documentPath: item?.filepath,
                    documentName: item?.filename
                })) : []
            },
            leadFunderDetailsList: contractdata?.ListData?.length === 0 ? [] : fundarr,
            referralContactDetailModel: contactobj
        };

        const res = await addLead(addpayload);
        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            });
            eventEmitter.emit('onAddNewLeadRM');
            let obj: any = {
                ShowClass: false,
                FormName: '',
                Data: '',
                ShowToggle: false
            };
            dispatch(setNavigationAction(obj));
            // eventEmitter.emit("onClickRefreshData", 2,2)
        } else {
            swal(res.message, {
                icon: 'error'
            });
        }
    }

    return (
        <div>
            <CreateLeadView
                addlead={savealert}
                poollead={poolalert}
                updatelead={updatealert}
                getuserdata={getuserdata}
                getpendata={getpendata}
                getcontractdata={getcontractdata}
                getuserdataerror={getuserdataerror}
                getpendataerror={getpendataerror}
                getcontractdataerror={getcontractdataerror}
                getfundelisterror={getfundelisterror}
                savedisable={savedisable}
                pooldisable={pooldisable}
                userdata={userdata}
                isheader={props.isheader}
                selfdata={userdata}
                deletefunderlist={deletefunderlist}
                getcontactdata={getcontactdata}
                getcontactdataerror={getcontactdataerror}
                referralId={props.referralId}
            />
        </div>
    );
};

export default CreateLeadContainer;
