import React from 'react';
import FunderManagementTopbarContainer from './FunderManagementTopbar/FunderManagementTopbarContainer';
import FunderManagementBottomContainer from './FunderManagementBottom/FunderManagementBottomContainer';

const FunderManagementView = () => {
    return (
        <div>
            {/* <FunderManagementTopbarContainer/> */}
            <FunderManagementBottomContainer/>
        </div>
    );
};

export default FunderManagementView;