import { AxiosError, AxiosResponse } from "axios";
import eventEmitter from "../utils/eventEmitter";
import { DataResponseModel } from "../utils/model";
import instance from "./axiosinstance";
import { API_ENDPOINT, BASEURLDEV } from "./ApiConstants";

export const getPlanChangeReason = (payload: any) => {
    eventEmitter.emit('eventSetLoader', true)
    return (
        instance
            .get(`${BASEURLDEV}${API_ENDPOINT.GetReasons}${payload.id}`)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                eventEmitter.emit('eventSetLoader', false)
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    eventEmitter.emit('eventSetLoader', false)
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    eventEmitter.emit('eventSetLoader', false)
                    return errorData;
                }
            })
    );
};
