import React from 'react';
import { useTranslation } from 'react-i18next';
import SmartSearchDropdownContainer from '../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { Tooltip } from 'react-tooltip';

const AccessRoleTopView = () => {
    const { t } = useTranslation();
    return (
        <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="d-flex flex-wrap">
                                    <div>
                                        <a href="/create-access-role">
                                            <button className='btn btn-primary btn-create me-2 mb-3'>
                                                <i className="fa-solid fa-plus"></i> Add Access Role
                                            </button>
                                        </a>
                                    </div>
                                    <div className='me-2 position-relative'>
                                        <input type="text" placeholder='Search' name="leadSmartSearch"  className='form-control white search-invoice' />
                                        <i className="fa-sharp fa-solid fa-magnifying-glass search-icon"></i>
                                    </div>
                                    <div>
                                        <div className="dropdown filter-dropdown me-2">
                                            <a className={`nav-link dropdown-toggle`} href="#" id="navbarDropdownMenuLink5" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="">
                                                    <i className="fa-solid fa-filter"></i> Filter
                                                </div>
                                            </a>
                                            <div className="dropdown-menu profile-dw-css" aria-labelledby="navbarDropdownMenuLink">
                                                <div>
                                                    <label>
                                                        Status
                                                    </label>
                                                </div>
                                                <div className="border-bottom mt-1 mb-1"></div>
                                                <div>
                                                    <label>
                                                        <input type="checkbox" value="" name="open" className="me-1" /> Active
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input type="checkbox" value="" name="open" className="me-1" /> Inactive
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#" className="btn btn-white bold " data-tooltip-id="my-tooltip-export" data-tooltip-content="Export Access Roles">
                                            <i className="fa-sharp fa-solid fa-download"></i> Export
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <Tooltip id="my-tooltip-export" className="my-tooltip" />
                        </div>
    );
};

export default AccessRoleTopView;