import React from 'react'

interface OnboardingPreviewEditModalFooterProps {
    backOnClick: any
    saveOnClick: any
    saveDisabled: boolean
    saveType?: "button" | "submit" | "reset" | undefined
}

const OnboardingPreviewEditModalFooter = ({backOnClick, saveDisabled, saveOnClick, saveType}: OnboardingPreviewEditModalFooterProps) => {
    return (
        <div className="service-detail-padding modal-footer--sticky">
            <div className="container-fluid p-0">
                <div className="d-flex stepbuttons">
                    <button
                        onClick={backOnClick}
                        className="classic-btn black-btn"
                        style={{ background: '#4e4851' }}
                    >
                        Back
                    </button>
                    {saveType ? <button
                        type={saveType}
                        onClick={saveOnClick}
                        disabled={saveDisabled}
                        className="classic-btn"
                    >
                        Save
                    </button>
                        : <button
                            onClick={saveOnClick}
                            disabled={saveDisabled}
                            className="classic-btn"
                        >
                            Save
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

export default OnboardingPreviewEditModalFooter