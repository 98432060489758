import React, { useState, useEffect } from 'react';
import ContactDetailsView from './ContactDetailsView';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import eventEmitter from '../../../../../../utils/eventEmitter';
import { getContactReferralList } from '../../../../../../service/leads';

const ContactDetailsContainer = (props: any) => {
    const { ShowClass, FormName, Data } = useSelector((state: RootState) => state.navigation);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const [contactdata, setcontactdata] = useState<any>();
    const [cRefList, setcRefList] = useState<any>();

    const [clearcontact, setclearcontact] = useState<any>(undefined);

    useEffect(() => {
        if (DataLeadInfo?.data?.referralContactDetailModel !== undefined) {
            let obj = {

                referralSourceId: DataLeadInfo?.data?.referralContactDetailModel?.referralSourceId,
                referralSourceDescription: DataLeadInfo?.data?.referralContactDetailModel?.referralSourceDescription
            }
            props.getcontactdata(obj);
            setcontactdata(obj);
        }
    }, [DataLeadInfo])

    useEffect(() => {
        async function getcontactReferralList() {
            const res = await getContactReferralList();
            if (res.status) {
                setcRefList(res.data);
            }
        }
        getcontactReferralList();
    }, []);

    useEffect(() => {
        props.getcontactdata(contactdata);
    }, [contactdata])


    useEffect(() => {
        if (DataLeadInfo?.data?.referralContactDetailModel !== undefined) {
            let obj = {
                referralSourceId: DataLeadInfo?.data?.referralContactDetailModel?.referralSourceId,
                referralSourceDescription: DataLeadInfo?.data?.referralContactDetailModel?.referralSourceDescription
            };
            props.getcontactdata(obj);
            setcontactdata(obj);
        }
    }, [DataLeadInfo]);

    const updateData = (e: any) => {
        setcontactdata({
            ...contactdata,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        props.getcontactdata(contactdata);
    }, [contactdata])

    const setcontracterror = (val: any) => {
        props.getcontactdataerror(val);
        props.getcontractformerror(val);
    }
    return (
        <div>
            <ContactDetailsView updateData={updateData} leaddata={DataLeadInfo} iscontacterror={setcontracterror} referrancelist={cRefList} clearcontact={clearcontact} />
        </div>
    );
};

export default ContactDetailsContainer;