import React, { useEffect, useState } from 'react';
import logopurple from '../../../assets/logos/locate-care-new-white.png';
import { setSaveForLater } from '../../../store/SaveForLater/saveForLaterDrawerReducer';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { Modules, Roles } from '../../../utils/constant';
import { setRoleSelectedAction } from '../../../store/Auth/roleSelectedReducer';
import { setNavigationAction } from '../../../store/dashboard/navigationReducer';
import { switchRoleService } from '../../../service/auth-service';
import locationIcon from '../../../assets/images/locationIcon.svg';
import { addDays } from 'date-fns';
//@ts-ignore
// import { DateRangePicker } from 'react-date-range';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import CircularSlider from "@fseehawer/react-circular-slider";
import OwlCarousel from 'react-owl-carousel';
import { useNavigate } from 'react-router-dom';
import { ROUTES_DATA } from '../../../navigation/RoutesConstants';
import logo from '../../../assets/logos/logo-white-website.png';
import logoIcon from '../../../assets/logos/logo-icon.png';
import { resetIsNhsReferralTracker } from '../../../store/NHS/nhsLoginReducer';
import ChangePasswordModalContainer from '../../../components/ChangePasswordModal/ChangePasswordModalContainer';
import ClientPortal from '../../../components/ClientPortal';

const SearchHeaderView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);
    const { StatusRoleSelected, DataRoleSelected } = useSelector((state: RootState) => state.roleSelected);

    const [userName, setUserName] = useState<any>('');
    const [showPortal, setShowPortal] = useState(false);


    const ChangePassword = () => {
        navigate(ROUTES_DATA.CHANGEPASSWORD);
    };
    const handleModal = (event: any) => {
        event.preventDefault();
        setShowPortal((prevState) => !prevState);
    };

    const logout = () => {
        window.$utils.signOutUser();
        navigate(ROUTES_DATA.LOGIN);
    };

    const handleSaveForLaterDrawer = () => {
        dispatch(setSaveForLater({
            saveForLaterDrawerOpen: true
        }))
    }

    useEffect(() => {
        setUserName(DataLogin.userModel.userName);
        if (StatusLogin === true && StatusRoleSelected === false) {
            if (DataLogin.userModel.roles.length > 0) {
                let roleObj: any = {
                    roleId: '',
                    roleName: '',
                    isDefaultRole: '',
                    modules: ''
                };
                let rolesData = DataLogin.userModel.roles.map((val: any) => {
                    if (val.isDefaultRole === true) {
                        roleObj.roleId = val.roleId;
                        roleObj.roleName = val.roleName;
                        roleObj.isDefaultRole = val.isDefaultRole;
                        roleObj.modules = DataLogin.userModel.modules;
                    }
                });
                //
                dispatch(setRoleSelectedAction(roleObj));
            }
        }
    }, [DataLogin.userModel.userRoleId]);

    var string = userName?.split(' ').slice(0, 2).join(' ');
    string = string
        .split(' ')
        .map((part: any[]) => part[0])
        .join('')
        .toUpperCase();

    const changeModule = () => {
        navigate(ROUTES_DATA.NHS);
        dispatch(resetIsNhsReferralTracker());
    }

    useEffect(() => {
        if (showPortal) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [showPortal]);

    return (
        <div className="nhs-referral-header">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-6 order-1 ">
                        <a href="#" aria-label="Home redirection">
                            <img src={logo} className="logo-nhs-header mb-0" alt="logo" />
                        </a>
                    </div>

                    <div className="col-lg-6 col-6 order-2 order-lg-3 d-flex align-items-center justify-content-end">
                        <div className='me-2'>
                            <span className="white-text hand-cursor" onClick={() => changeModule()}>
                                View Map
                            </span>
                        </div>

                        <div className="user me-2 float-end">
                            <ul className="navbar-nav">
                                <div className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle nhs-user_img" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="user_img">{string}</div>
                                    </a>
                                    <div className="dropdown-menu profile-dw-css" aria-labelledby="navbarDropdownMenuLink">
                                        <a className="dropdown-item" href="#">
                                            <i className="fa-solid fa-user "></i>
                                            <span className="ms-2">{userName || ''}</span>
                                        </a>
                                        {/* <a className="dropdown-item" onClick={handleSaveForLaterDrawer}>
                                            <i className="fa-regular fa-heart"></i>
                                            <span className="ms-1">Save for later</span>
                                        </a> */}
                                        <a className="dropdown-item" onClick={(e) => handleModal(e)}>
                                        <svg
                                                    enable-background="new 0 0 1080 1080"
                                                    width="18"
                                                    version="1.1"
                                                    viewBox="0 0 1080 1080"
                                                    id="Layer_1"
                                                    xmlSpace="preserve"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <style type="text/css">{`
                                            .st0{fill:#242424;}
                                            `}</style>
                                                        <g>
                                                            <path d="M5.4,392.7C17,314,52.7,238.6,102.3,177c20.8-25.1,43.8-49.1,68-68.5c42.7-34.2,92.2-61.6,144.1-79.9
                                                                c63.4-24,128-33.1,194.9-27.4c160.3,13.7,283.6,89,370.1,221.5c41.5,62.8,63.4,133.6,70.3,207.8c3.5,36.5,2.3,71.9-3.5,108.5
                                                                c0,1.1,0,3.4-1.2,4.6c-21.9-3.4-43.8-6.8-64.6-10.3c17.3-153-31.1-280.8-153.3-379c-83-66.2-178.7-94.8-284.8-86.8
                                                                C228,84.5,63.1,262.6,64.2,476.1c1.2,123.3,48.4,226,143,307.1c93.4,81.1,204.1,110.7,326.3,94.8c3.5,21.7,6.9,43.4,10.4,65.1
                                                                c-9.2,1.1-18.4,2.3-27.7,3.4c-168.3,17.1-336.7-61.6-433.5-202.1c-34.6-49.1-56.5-102.7-71.5-159.8c-10.4-38.8-12.7-79.9-10.4-121
                                                                C0.8,440.7,2,416.7,5.4,392.7z"/>
                                                            <path d="M1080,895.1c-17.3,3.4-33.4,6.8-50.7,10.3c-3.5,1.1-5.8,2.3-6.9,5.7c-2.3,8-5.8,14.8-9.2,22.8
                                                                c-1.2,3.4-1.2,5.7,1.2,8c9.2,12.6,17.3,25.1,26.5,37.7c5.8,8,4.6,12.6-1.2,19.4c-11.5,11.4-23.1,22.8-34.6,34.2
                                                                c-5.8,5.7-10.4,6.8-18.4,1.1c-13.8-9.1-26.5-18.3-40.4-27.4c-2.3-1.1-4.6-1.1-6.9-1.1c-8.1,3.4-16.1,5.7-24.2,10.3
                                                                c-2.3,1.1-4.6,3.4-4.6,5.7c-3.5,14.8-5.8,29.7-9.2,45.7c-2.3,10.3-5.8,12.6-16.1,12.6c-16.1,0-32.3,0-48.4,0
                                                                c-9.2,0-12.7-3.4-15-12.6c-2.3-14.8-5.8-30.8-8.1-45.7c-1.2-4.6-2.3-5.7-6.9-6.8c-6.9-2.3-13.8-5.7-20.8-9.1
                                                                c-3.5-1.1-5.8-1.1-9.2,1.1c-12.7,9.1-25.4,17.1-38,26.3c-8.1,5.7-12.7,5.7-19.6-1.1c-11.5-11.4-23.1-22.8-34.6-34.2
                                                                c-5.8-5.7-6.9-11.4-2.3-18.3c9.2-12.6,17.3-26.3,26.5-38.8c2.3-3.4,2.3-5.7,1.2-9.1c-3.5-6.8-6.9-14.8-9.2-21.7
                                                                c-1.2-3.4-3.5-4.6-6.9-5.7c-15-2.3-31.1-5.7-46.1-9.1c-9.2-1.1-12.7-5.7-12.7-13.7c0-16,0-33.1,0-49.1c0-9.1,3.5-12.6,12.7-13.7
                                                                c15-3.4,31.1-5.7,46.1-9.1c4.6-1.1,5.8-2.3,6.9-5.7c2.3-6.8,4.6-13.7,8.1-20.5c2.3-4.6,1.2-6.8-1.2-10.3
                                                                c-9.2-12.6-17.3-25.1-26.5-37.7c-5.8-8-4.6-12.6,2.3-19.4c11.5-11.4,23.1-22.8,34.6-34.2c5.8-5.7,11.5-6.8,18.4-2.3
                                                                c12.7,9.1,26.5,17.1,39.2,26.3c3.5,2.3,5.8,2.3,9.2,1.1c6.9-3.4,13.8-6.8,21.9-9.1c3.5-1.1,5.8-3.4,5.8-6.8
                                                                c2.3-14.8,5.8-30.8,8.1-45.7c1.2-9.1,5.8-12.6,15-12.6c16.1,0,32.3,0,48.4,0c10.4,0,13.8,3.4,16.1,12.6c2.3,14.8,5.8,30.8,8.1,45.7
                                                                c1.2,3.4,2.3,5.7,5.8,6.8c8.1,3.4,16.1,6.8,24.2,9.1c2.3,1.1,5.8,1.1,8.1-1.1c13.8-9.1,26.5-18.3,39.2-26.3
                                                                c8.1-5.7,12.7-4.6,19.6,1.1c11.5,11.4,23.1,22.8,34.6,34.2c5.8,6.8,6.9,11.4,1.2,18.3c-9.2,12.6-17.3,26.3-26.5,38.8
                                                                c-2.3,3.4-2.3,5.7-1.2,9.1c3.5,6.8,5.8,14.8,9.2,21.7c1.2,2.3,2.3,4.6,4.6,4.6c17.3,3.4,35.7,6.8,53,9.1
                                                                C1080,844.8,1080,869.9,1080,895.1z M943.9,857.4c0-45.7-38-82.2-84.2-82.2c-46.1,0-83,37.7-83,83.3s38,82.2,83,82.2
                                                                C907.1,940.7,943.9,903,943.9,857.4z"/>
                                                            <path d="M451.6,709c-34.6,0-68-1.1-102.6,0c-47.3,2.3-78.4-36.5-78.4-76.5c0-61.6-1.2-123.3,0-186.1
                                                                c0-36.5,23.1-65.1,55.3-74.2c3.5-1.1,10.4-1.1,11.5-3.4c2.3-3.4,1.2-8,1.2-12.6c1.2-24,0-47.9,3.5-71.9
                                                                c4.6-33.1,18.4-62.8,42.7-85.6c21.9-19.4,47.3-28.5,76.1-30.8c26.5-2.3,53,0,77.2,12.6c31.1,14.8,50.7,41.1,63.4,73.1
                                                                c8.1,20.5,11.5,42.2,11.5,63.9c0,16,0,32,0,47.9c0,3.4,0,5.7,4.6,5.7c34.6,4.6,63.4,34.2,63.4,74.2c1.2,45.7,0,91.3,0,137
                                                                c0,2.3-2.3,5.7-3.5,6.8c-43.8,30.8-78.4,68.5-103.8,115.3c-2.3,3.4-4.6,3.4-8.1,3.4C526.6,709,489.7,709,451.6,709z M503.5,640.5
                                                                c0-2.3,0-4.6,0-6.8c0-21.7,0-42.2,0-63.9c0-9.1,1.2-14.8,10.4-19.4c5.8-2.3,10.4-8,15-12.6c13.8-13.7,17.3-30.8,12.7-50.2
                                                                c-12.7-47.9-74.9-65.1-111.8-32c-26.5,22.8-30,58.2-6.9,82.2c5.8,5.7,12.7,11.4,19.6,16c3.5,2.3,4.6,4.6,4.6,9.1
                                                                c0,24,0,46.8,0,70.8c0,2.3,0,4.6,0,8C465.5,640.5,483.9,640.5,503.5,640.5z M543.9,369.9c-1.2-27.4-1.2-54.8-2.3-82.2
                                                                c0-1.1,0-1.1,0-2.3c-5.8-26.3-21.9-44.5-48.4-47.9c-12.7-2.3-25.4-1.1-36.9,0c-20.8,3.4-35.7,14.8-42.7,35.4
                                                                c-3.5,10.3-5.8,21.7-5.8,33.1c-1.2,19.4,0,38.8,0,57.1c0,2.3,0,4.6,0,6.8C452.8,369.9,497.7,369.9,543.9,369.9z"/>
                                                        </g>
                                                </svg>
                                            <span className="ms-1">Change Password</span>
                                        </a>
                                        <a className="dropdown-item" href="#" onClick={logout}>
                                            <i className="fa-solid fa-right-from-bracket "></i>
                                            <span className="ms-1">Log Out</span>
                                        </a>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <ClientPortal 
                selector="myportal"
                show={showPortal}
                onClose={() => { setShowPortal((prevState) => !prevState); }}
            >
                <ChangePasswordModalContainer />
            </ClientPortal>
        </div>
    );
};

export default SearchHeaderView;
