import React, { useEffect, useState } from 'react';
import PenPictureView from './PenPictureView';
import { RootState } from '../../../../../../store';
import { useSelector } from 'react-redux';
import eventEmitter from '../../../../../../utils/eventEmitter';

const PenPictureContainer = (props:any) => {

    const [pendata, setpendata] = useState<any>();
    const [pendataattachments, setpendataattachments] = useState<any>([]);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);

    useEffect(()=>{
        if(DataLeadInfo?.data?.penPictureDetailModel !== undefined){
            setpendata({
                desc: DataLeadInfo?.data?.penPictureDetailModel?.description,
            });
            if(DataLeadInfo?.data?.penPictureDetailModel?.listPenPictureList?.length > 0){
                setpendataattachments({
                    files: DataLeadInfo?.data?.penPictureDetailModel?.listPenPictureList?.map((item: any) => ({
                        leadPenPicture_Id: item.leadPenPicture_Id,
                        filename: item.documentName,
                        filepath: item.documentPath,
                    }))
                })
            }
        
        }
    },[DataLeadInfo])

    const updateData = (e: any) => {
        setpendata({
                ...pendata,
                [e.target.name]: e.target.value
            });
            eventEmitter.emit("eventCloseEditLeadSideMenu");
    };

    useEffect(()=>{
        
        
     props.getpendata({desc : pendata, attachments : pendataattachments});
    },[pendata,pendataattachments])

    const updateFData = (e:any) =>{
        setpendataattachments((old: any) => ({
                ...(old ? old : {}),
                files: old && old.files ? [...old.files, e] : [e]
            }));
            eventEmitter.emit("eventCloseEditLeadSideMenu");
    }

    const deleteFData = (path: string) => {
        setpendataattachments((old: any) => ({
            ...(old ? old : {}),
            files: old && old.files ? old.files.filter((v: any) => v.filepath !== path) : old
        }));
    }

    const setpenerror = (val:any) =>{
     props.getpendataerror(val);
     props.getpenformerror(val);
    }

    return (
        <div>
            <PenPictureView updateData={updateData} userdata={props.userdata} getpendataerror={setpenerror} updateFData={updateFData} pendata={DataLeadInfo} deleteFData={deleteFData} />
        </div>
    );
};

export default PenPictureContainer;