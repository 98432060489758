import RenderModalContainer from "../../../components/RenderModal/indexContainer"
import SmartSearchDropdownContainer from "../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer"
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { API_ENDPOINT, BASEURLDEV } from "../../../service/ApiConstants";
import { Statusenums } from "../../../utils/constant";
import swal from 'sweetalert';
import { SubmitVerificationDetailsLog, SubmitVerificationLockDetailsLog } from "../../../service/Admin-service";
import warning from "../../../assets/images/warning.png"
import { rest } from "lodash";

interface ClaimRequestTextProps {
    from: 'Reopen' | 'OnHold' | 'Approve' | 'Reject' | 'Lock';
    serviceName: string;
}

const ReasonModalView = ({ showPortal, handleCloseModal, from, dataserviceId, serviceName, getServiceClaimList, getAdminverificationList }: any) => {

    const [reasonSmartSearch, setReasonSmartSearch] = useState<any>(undefined)

    const validationSchema = Yup.object().shape({
        reason: Yup.string().required(),
        desc: Yup.string().notRequired()
    });

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors, isValid } = formState;



    useEffect(() => {

        if (from === "Reopen") {
            let objReasonSmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetVerificationReasonList}?reasonType=13`
            };
            setReasonSmartSearch(objReasonSmartSearch)
        }

        if (from === "Approve") {
            let objReasonSmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetVerificationReasonList}?reasonType=9`
            };
            setReasonSmartSearch(objReasonSmartSearch)
        }

        if (from === "Reject") {
            let objReasonSmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetVerificationReasonList}?reasonType=10`
            };
            setReasonSmartSearch(objReasonSmartSearch)
        }

        if (from === "OnHold") {
            let objReasonSmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetVerificationReasonList}?reasonType=8`
            };
            setReasonSmartSearch(objReasonSmartSearch)
        }
        if (from === "Lock") {
            let objReasonSmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetVerificationReasonList}?reasonType=11`
            };
            setReasonSmartSearch(objReasonSmartSearch)
        }
        if (from === "Unlock") {
            let objReasonSmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetVerificationReasonList}?reasonType=12`
            };
            setReasonSmartSearch(objReasonSmartSearch)
        }

        return () => {
            reset()
        }

    }, [from])



    // const getText = () => {
    //     if (from === "Reopen") {
    //         return `Are you sure you want to reopen the claim request for ${serviceName}?`
    //     }
    //     if (from === "OnHold") {
    //         return `Are you sure you want to place the claim request for ${serviceName} on hold?`
    //     }
    //     if (from === "Approve") {
    //         return `Are you sure you want to approve the claim request for ${serviceName}?`
    //     }
    //     if (from === "Reject") {
    //         return `Are you sure you want to reject the claim request for ${serviceName}?`
    //     }
    //     if (from === "Lock") {
    //         return `The location pin for ${serviceName} will be removed from Locate Website. Do you want to continue?`
    //     }
    // }

    const getText = ({ from, serviceName }: ClaimRequestTextProps): string | null => {
        const messages: Record<string, string> = {
            Reopen: `Are you sure you want to reopen the claim request for ${serviceName}?`,
            OnHold: `Are you sure you want to place the claim request for ${serviceName} on hold?`,
            Approve: `Are you sure you want to approve the claim request for ${serviceName}?`,
            Reject: `Are you sure you want to reject the claim request for ${serviceName}?`,
            Lock: `The location pin for ${serviceName} will be removed from Locate Website. Do you want to continue?`,
            Unlock: `The location pin for ${serviceName} will be displayed on the Locate Website. Do you want to continue?`
        };

        return messages[from];
    };

    const handleClickCloseModal = () => {
        reset()
        handleCloseModal()
    }

    const submitReason = async (data: any) => {
        let payload: any = {}
        if (from === "Lock") {
            payload = {
                serviceId: dataserviceId,
                verificationId: Statusenums.Lock,
                reasonId: Number(data?.reason),
                reasonText: data?.desc,
                isLocks: true
            }
        } else if (from === "Unlock") {
            payload = {
                serviceId: dataserviceId,
                verificationId: Statusenums.Unlock,
                reasonId: Number(data?.reason),
                reasonText: data?.desc,
                isLocks: false
            }
        } else {
            payload = {
                serviceId: dataserviceId,
                verificationId: Statusenums.Reopen,
                reasonId: Number(data?.reason),
                reasonText: data?.desc
            }
        }


        if (from === "OnHold") {
            payload.verificationId = Statusenums.OnHold
        }
        if (from === "Approve") {
            payload.verificationId = Statusenums.Completed
        }
        if (from === "Reject") {
            payload.verificationId = Statusenums.Rejected
        }

        // if (from === "Unlock") {
        //     payload.verificationId = Statusenums.Unlock
        // }

        swal({
            title: '',
            text: getText({ from, serviceName }) || 'No message available.',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const response = await (from === "Lock" || from === "Unlock"
                    ? SubmitVerificationLockDetailsLog(payload)
                    : SubmitVerificationDetailsLog(payload));
                if (response.status === true) {
                    swal({
                        title: '',
                        text: response.message,
                        icon: 'success',
                        dangerMode: true
                    }).then(() => {
                        // Close the modal
                        handleClickCloseModal();
                        //get the service claim list
                        getServiceClaimList();
                        getAdminverificationList()
                    });
                } else {
                    swal({
                        title: '',
                        text: response.message,
                        icon: 'error',
                        dangerMode: true
                    })
                }
            }
        });
    }

    return (
        <RenderModalContainer>
            {showPortal && <div><div className="overlay" onClick={() => {
                reset()
                handleCloseModal()
            }}></div>
                <div className="modal modal-extrasmall">
                    <div className="modal-content">
                        <div className="modal-header">
                            {from === "Reopen" &&
                                <h4 className="modal-title mb-0">Reopen the {serviceName}</h4>
                            }
                            {from === "Approve" &&
                                <h4 className="modal-title mb-0">Approve the {serviceName}</h4>
                            }
                            {from === "OnHold" &&
                                <h4 className="modal-title mb-0">Hold the {serviceName}</h4>
                            }
                            {from === "Reject" &&
                                <h4 className="modal-title mb-0">Reject the {serviceName}</h4>
                            }
                            {from === "Lock" &&
                                <h4 className="modal-title mb-0">Lock the {serviceName}</h4>
                            }
                            {from === "Unlock" &&
                                <h4 className="modal-title mb-0">Unlock the {serviceName}</h4>
                            }
                            <a href="#" title="close" onClick={() => {
                                reset()
                                handleCloseModal()
                            }} className="removeButton">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                                </a>

                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-md-12">
                                    {/* Add here Dropdown */}
                                    <div className="auto-complete-dropdown invoice-ddl w-100 mb-3">
                                        <SmartSearchDropdownContainer
                                            smartSearchLabel="Reason*"
                                            errors={errors.reason}
                                            className="w-100"
                                            onChangeSmartSearch={(value: any) => {
                                                const event = new Event('change');
                                                let obj = {
                                                    name: 'reason',
                                                    value: value?.id?.toString()
                                                };
                                                //@ts-ignore
                                                Object.defineProperty(event, 'target', { writable: false, value: obj });
                                                setValue('reason', value?.id?.toString(), { shouldValidate: true });
                                                // if (value !== '') {
                                                //     props.updateData(event);
                                                // }
                                            }}
                                            smartSearch={reasonSmartSearch}
                                        />
                                    </div>

                                </div>
                                <div className="col-md-12">
                                    <textarea className="form-control white mb-3 " placeholder="Comments" rows={4} {...register("desc")}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button className="btn btn-primary" disabled={!isValid} onClick={handleSubmit(submitReason)}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            }
        </RenderModalContainer>
    )
}

export default ReasonModalView