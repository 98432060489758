import React, { useEffect, useMemo, useState, useRef, useReducer } from 'react';
import UserManagementTopbarContainer from './UserManagementTopbar/UserManagementTopbarContainer';
import UserManagementBottomContainer from './UserManagementBottom/UserManagementBottomContainer';

const UserManagementView = () => {
    const [showToolbar, setShowToolbar] = useState<any>(false);
    
    return (
        <div>
            {/* <UserManagementTopbarContainer/>
            <UserManagementBottomContainer/> */}

            <div>
                <div className="service-title text-center">User Management</div>
                <div className="box-shadow new-box-shadow">
                        
                    <UserManagementTopbarContainer/>
                    <UserManagementBottomContainer/>
                </div>
            </div>
        </div>
    );
};

export default UserManagementView;