import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    MaterialReactTable,
    MRT_TableInstance,
    MRT_FullScreenToggleButton,
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_TablePagination,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToolbarAlertBanner,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_VisibilityState,
    type MRT_RowSelectionState,
    type MRT_GroupingState,
    MRT_Localization
} from 'material-react-table';
import { Box } from '@mui/material';
import PaginationContainer from '../../../components/PaginationComponent/indexContainer';
import SmartSearchDropdownContainer from '../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';

const CreateAccessRoleView = () => {
    const { t } = useTranslation();
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'srno', //access nested data with dot notation
                header: 'Sr No',
                size: 30,
            },
            {
                accessorKey: 'organization', //access nested data with dot notation
                header: 'Organization',
                size: 50,
            },
            {
                accessorKey: 'servicename',
                header: 'Service Name',
                size: 100,
            },
            {
                accessorKey: 'view', //normal accessorKey
                header: 'Referral Privilages',
                size: 100,
                Cell: ({ cell }) => (
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <label className="form--radio-label d-flex align-items-center me-3">
                                <input name="view" type="radio" id="active" value="active" />
                                <span className="ms-1">View</span>
                            </label>
                            <label className="form--radio-label d-flex align-items-center">
                                <input name="edit" type="radio" id="inactive" value="inactive" />
                                <span className="ms-1">Edit</span>
                            </label>
                        </div>
                    </div>
                ),
            },
        ],
        [],
    );
    const Data = [
        {
            id: 1,
            organization:"Valorum Care",
            srno: "1",
            servicename: "Magna House",
        },
        {
            id: 2,
            organization:"Valorum Care",
            srno: "2",
            servicename: "Apple Hill",
        },
        {
            id: 3,
            organization:"Valorum Care",
            srno: "3",
            servicename: "Southern Hill Hospital",
        },
        {
            id: 4,
            organization:"Valorum Care",
            srno: "4",
            servicename: "The Riders",

        },
    ]

    return (
        <>
        <div className="container-fluid">
            <div className="row createUserScreen">
                <div className="col-xl-12 col-lg-12">
                    <div className="middle_content" id="middle_content">
                        <div className="row mb-2">
                            <div className="col-6">
                                <a href='/access-role'>
                                <button className="btn btn-white  btn-sm me-2 ">
                                    <i className="fa fa-chevron-left"></i> Back
                                </button>
                                </a>
                            </div>
                            <div className="col-6 text-right"></div>
                        </div>
                    </div>

                    <div className="box-shadow p-0">
                        <div>
                            <div className="box-header">
                                <div className="row align-items-center h-100">
                                    <div className="col-md-4 flex-order-2 py-2">
                                        <div className=" ">
                                            <button className="btn btn-sm btn-secondary ms-2">Clear</button>
                                            <button className="btn btn-sm btn-primary ms-2">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="administrator-content">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="modal-title-header mb-4">
                                        <h3>Access Role Detail</h3>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Name</label>
                                        <input type="text" name="name" className="form-control white" value="" />
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Description</label>
                                        <input type="text" name="name" className="form-control white" value="" />
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Status</label>
                                        <div className="d-flex align-items-center">
                                            <label className="form--radio-label mt-2 d-flex align-items-center me-3">
                                                <input name="status" type="radio" id="active" value="active" checked />
                                                <span className="ms-1">Active</span>
                                            </label>
                                            <label className="form--radio-label mt-2 d-flex align-items-center">
                                                <input name="status" type="radio" id="inactive" value="inactive" />
                                                <span className="ms-1">Inactive</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="administrator-content pt-0 define-access">
                            <div className="row">
                                <div className='col-xl-12 col-lg-12'>
                                    <div className="modal-title-header mb-4">
                                        <h3>Define Access</h3>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="d-flex mb-3 flex-column">
                                        <div className='d-flex align-items-center border-bottom mb-2'>
                                            <label className="bold pb-2 me-3">General</label>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="log_note" className="me-2" /> Log Note</label>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="log_note" className="me-2" /> Access Roles</label>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-3 flex-column">
                                        <div className='d-flex align-items-center border-bottom mb-2'>
                                            <label className="bold pb-2 me-3">Billing Management</label>
                                        </div>

                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="Posted" className="me-2" />Edit Posted Invoice</label>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="Start/Stop" className="me-2" />Start/Stop Invoice</label>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="Print" className="me-2" />Send & Print Invoice</label>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-3 flex-column">
                                        <div className='d-flex align-items-center border-bottom mb-2'>
                                            <label className="bold pb-2 me-3">Reports</label>
                                        </div>

                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="Transaction" className="me-2" /> Patient Transaction History Report</label>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="Detail" className="me-2" /> Patient Detail Report</label>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="Bed" className="me-2 mb-2" />Current Bed Status with Pricing</label>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="History" className="me-2 mb-2" />Bed Transaction History Report</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="d-flex mb-3 flex-column">
                                        <div className='d-flex align-items-center border-bottom mb-2'>
                                            <label className="bold pb-2 me-3">Administrator</label>
                                        </div>

                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="log_note" className="me-2" /> User Management</label>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="log_note" className="me-2" /> Integration Interface</label>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-3 flex-column">
                                        <div className='d-flex align-items-center border-bottom mb-2'>
                                            <label className="bold pb-2 me-3">Referral Management</label>
                                        </div>

                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="Transaction" className="me-2" /> Send & Print RT Costing</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        </>
    );
};

export default CreateAccessRoleView;