import React from 'react'
import ManageColumnView from './ManageColumnView'

const ManageColumnContainer = (props: any) => {
    return (
        <ManageColumnView managedColumnsList={props.managedColumnsList} onChangeManageColumnHandler={props.onChangeManageColumnHandler} />
    )
}

export default ManageColumnContainer
