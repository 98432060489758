import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import warning from '../../../../../assets/images/warning.png';
import RenderModalContainer from '../../../../../components/RenderModal/indexContainer';
import AssessmentModalContainer from '../../../../../components/AssessmentModal/indexContainer';
import ProgressBar from '@ramonak/react-progress-bar';
import General_Info from '../../../../../assets/images/general-info.svg';
import Progress_Note from '../../../../../assets/images/progress-note.svg';
import Info_gethering from '../../../../../assets/images/Info-gathering.svg';
import Strength from '../../../../../assets/images/strength.svg';
import Capacity from '../../../../../assets/images/capacity.svg';
import Care_Giver from '../../../../../assets/images/Care-Giver.svg';
import Next from '../../../../../assets/images/next.svg';
import Profile from '../../../../../assets/images/Profile.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, store } from '../../../../../store';
import { saveAssessmentDetailService } from '../../../../../service/assessment-service';
import eventEmitter from '../../../../../utils/eventEmitter';
import ScheduleAssessmentContainer from '../../../../../components/ScheduleAssessmentModal/indexContainer';
import RejectContainer from '../../../../../components/RejectModal/RejectContainer';

import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { API_ENDPOINT, BASEURLDEV } from '../../../../../service/ApiConstants';

import { deleteAssessmentDocument, getAssessmentDocument, getLeaddata } from '../../../../../service/leads';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from 'react-tooltip';

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const AssessmentView = (props: any) => {
    const { t } = useTranslation();

    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const { StatusAssessmentDetails, DataAssessmentDetails } = useSelector((state: RootState) => state.getAssessmentDetails);
    const { StatusOrganizationDetail, DataOrganizationDetail } = useSelector((state: RootState) => state.organizationDetail);
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all' });
    const [showModalSkip, setShowModalSkip] = useState(false);
    const [leadpoolingid, setleadpoolingid] = useState<any>();
    const [servicename, setservicename] = useState<any>();
    const [files, setFiles] = useState<any>([]);
    const [isError, setIsError] = useState<string | null>(null);
    const serviceAssessmentDetails = DataLeadInfo?.data?.assessment?.find((data: any) => data.serviceId == DataServiceDetail.serviceId);    

    const [showModal, setShowModal] = useState(false);
    const [leadPoolingId, setLeadPoolingId] = useState<any>();
    const [assessmentDetails, setAssessmentDetails] = useState<any>(undefined);
    const [orgName, setOrgName] = useState<any>(undefined);
    const [poolingId, setPoolingId] = useState<number | null>(null);
    const [isViewMode, setIsViewMode] = useState<boolean>(true);
    const state = store.getState();
    const token = state.login.DataLogin.jwtToken;
    const [savePayload, setSavePayload] = useState<any>({
        generalInformation: '',
        assessmentProgressNoteModel: '',
        assessmentServiceUserProfileModel: '',
        assessmentStrengthsBasedAsmtModel: '',
        assessmentCapacityLegalOrderModel: '',
        assessmentSupportWorkerModel: '',
        assessmentWhatHappeensNextModel: '',
        assessmentServiceUserDiversityProfileModel: ''
    });

    const handleClickModal = (event: any) => {
        setShowModal(true);
    };

    const closePopup = () => {
        setShowModal(false);
    };

    const toggleskip = () => {
        setShowModalSkip(false);
    };

    const toggle = () => {
        swal({
            title: '',
            text: 'Are you sure you want to close the Assessment Form?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                setShowModal(false);
                // dispatch(removeSplitFundingAction())
            }
        });
    };

    const removepdf = (e: any, path: any) => {
        e.preventDefault();
        swal({
            title: '',
            text: 'Are you sure you want to remove the attachment?',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const payload = { path: path };
                const response = await deleteAssessmentDocument(payload);
                if (response.status == true) {
                    getLeaddata(DataLeadInfo?.data?.leadId, 3);
                    setFiles((o: any) => o.filter((v: any) => v.documentPath !== path));
                } else {
                }
            }
        });
    };
    const openpdf = async (e: any, path: any) => {
        e.preventDefault();
        const payload = { path };
        const response = await getAssessmentDocument(payload);
        if (response.status === true) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = response.data;
            alink.click();
        }
    };

    const onCLickHandlerSubmitAssessment = async () => {
        let savePayloadTemp = savePayload;
        if (assessmentDetails.generalInformation !== '') {
            savePayloadTemp.generalInformation = {
                ...assessmentDetails.generalInformation.formData,
                supportedPersonDateOfBirth: window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetails.generalInformation.formData.supportedPersonDateOfBirth))
            };
        }
        if (assessmentDetails.assessmentProgressNoteModel !== '') {
            let assessmentProgressNotesDetailModelsTemp: any = [];
            assessmentDetails.assessmentProgressNoteModel.formData?.assessmentProgressNotesDetailModels.map((item: any) => {
                assessmentProgressNotesDetailModelsTemp.push({
                    apnId: item.apnId,
                    apndId: item.apndId,
                    comments: item.comments,
                    progressNoteDate: window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(item.progressNoteDate)),
                    staffInitials: item.staffInitials
                });
            });
            savePayloadTemp.assessmentProgressNoteModel = {
                ...assessmentDetails.assessmentProgressNoteModel.formData,
                dateAdded: window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetails.assessmentProgressNoteModel.formData.dateAdded)),
                assessmentProgressNotesDetailModels: assessmentProgressNotesDetailModelsTemp
            };
        }
        if (assessmentDetails.assessmentServiceUserProfileModel !== '') {
            savePayloadTemp.assessmentServiceUserProfileModel = assessmentDetails.assessmentServiceUserProfileModel.formData;
        }
        if (assessmentDetails.assessmentStrengthsBasedAsmtModel !== '') {
            savePayloadTemp.assessmentStrengthsBasedAsmtModel = {
                ...assessmentDetails.assessmentStrengthsBasedAsmtModel.formData,
                dateOfLastKnownChiropodyAppointment:
                    assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.dateOfLastKnownChiropodyAppointment != undefined &&
                    assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.dateOfLastKnownChiropodyAppointment != null
                        ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.dateOfLastKnownChiropodyAppointment))
                        : '',
                dateOfLastKnownDentistAppointment:
                    assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.dateOfLastKnownDentistAppointment != undefined &&
                    assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.dateOfLastKnownDentistAppointment != null
                        ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.dateOfLastKnownDentistAppointment))
                        : '',
                dateOfLastKnownGpVisit:
                    assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.dateOfLastKnownGpVisit != undefined &&
                    assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.dateOfLastKnownGpVisit != null
                        ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.dateOfLastKnownGpVisit))
                        : '',
                dateOfLastKnownOpticiansAppointment:
                    assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.dateOfLastKnownOpticiansAppointment != undefined &&
                    assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.dateOfLastKnownOpticiansAppointment != null
                        ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.dateOfLastKnownOpticiansAppointment))
                        : '',
                datesOfKnownFalls:
                    assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.datesOfKnownFalls != undefined &&
                    assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.datesOfKnownFalls != null
                        ? window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetails.assessmentStrengthsBasedAsmtModel.formData.datesOfKnownFalls))
                        : ''
            };
        }
        if (assessmentDetails.assessmentCapacityLegalOrderModel !== '') {
            savePayloadTemp.assessmentCapacityLegalOrderModel = assessmentDetails.assessmentCapacityLegalOrderModel.formData;
        }

        if (assessmentDetails.assessmentSupportWorkerModel !== '') {
            savePayloadTemp.assessmentSupportWorkerModel = assessmentDetails.assessmentSupportWorkerModel.formData;
        }

        if (assessmentDetails.assessmentWhatHappeensNextModel !== '') {
            savePayloadTemp.assessmentWhatHappeensNextModel = {
                ...assessmentDetails.assessmentWhatHappeensNextModel.formData,
                assessmentCompletedByDate: window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetails.assessmentWhatHappeensNextModel.formData.assessmentCompletedByDate)),
                personDate: window.$utils.dateFormatDDMMYYYY(window.$utils.dateFormat(assessmentDetails.assessmentWhatHappeensNextModel.formData.personDate))
            };
        }

        if (assessmentDetails.assessmentServiceUserDiversityProfileModel !== '') {
            savePayloadTemp.assessmentServiceUserDiversityProfileModel = assessmentDetails.assessmentServiceUserDiversityProfileModel.formData;
        }

        savePayloadTemp.generalInformation.assessmentStatus = 2;
        const response = await saveAssessmentDetailService(savePayloadTemp);
        if (response.status === true) {
            if (Number(response.data) > 0) {
                eventEmitter.emit('onSaveOrSubmitAssessment', response.data);
            }
            swal(response.message, {
                icon: 'success'
            });
        } else {
            swal(response.message, {
                icon: 'error',
                dangerMode: true
            });
        }
    };

    const skipAssessment = (poolid: any, servicename: any) => {
        setleadpoolingid(poolid);
        setservicename(servicename);
        setShowModalSkip(true);
    };
    useEffect(() => {
        
        if (files.length <= 5) {
            setIsError(null);
            eventEmitter.emit('eventSetLoader', false);
        }
    }, [files]);

    useEffect(() => {
        if (StatusAssessmentDetails && DataAssessmentDetails?.data !== null) {
            setAssessmentDetails(DataAssessmentDetails?.data);
        } else {
            setAssessmentDetails(undefined);
        }
    }, [StatusAssessmentDetails, DataAssessmentDetails]);

    useEffect(() => {        
        if (StatusLeadInfo === true && DataLeadInfo?.data?.assessment?.length > 0) {
            setLeadPoolingId(serviceAssessmentDetails.leadPoolingId);
        }

        if (serviceAssessmentDetails?.attachments?.length > 0) {
            setFiles(
                serviceAssessmentDetails?.attachments.map((item: any) => ({
                    documentPath: item?.documentPath,
                    documentFile: item?.documentFile,
                    isAddedByRm: item?.isAddedByRm
                }))
            );
        }
    }, [StatusLeadInfo, DataLeadInfo, StatusServiceDetail, DataServiceDetail]);
    useEffect(() => {
        setOrgName(DataOrganizationDetail.organizationName);
    }, [StatusOrganizationDetail, DataOrganizationDetail]);
    return (
        <div>
            {assessmentDetails === undefined && (
                <>
                    {DataLeadInfo?.data?.assessment?.length > 0 &&
                        DataLeadInfo?.data?.assessment
                            .filter((item: any) => item.serviceId === DataServiceDetail.serviceId)
                            ?.map((assessmentList: any) => (
                                <>
                                    <div className="status-box my-3" key={assessmentList.leadPoolingId}>
                                        {assessmentList?.referralLogs?.length > 0 &&
                                            assessmentList?.referralLogs?.map((assessment: any) => (
                                                <>
                                                    <div className="d-flex align-items-center">
                                                        <span className="ps-0">
                                                            <p>
                                                                <i>{assessment.logMessage}{' '}</i>
                                                                {assessment.status === 3 && (
                                                        <i
                                                            className="fa-solid fa-circle-info ms-2"
                                                            data-tooltip-id="my-tooltip-8"
                                                            data-tooltip-content={`${assessment?.reason} - ${assessment?.comment}`}
                                                        ></i>
                                                    )}
                                                            </p>
                                                        </span>
                                                    </div>
                                                   
                                                </>
                                            ))}

                                        {assessmentList.assessmentStatus !== 3 && (
                                            <i className="fa-solid fa-circle-info dark-text cursor-pointer" onClick={() => setPoolingId(assessmentList.leadPoolingId)}></i>
                                        )}
                                    </div>

                                    {files?.length > 0 && assessmentList.isSkippedByRm === false && (
                                        <>
                                            <p className="purple-text mt-2 mb-2 bold">{t('Generate_Contract.Attachments')}</p>
                                        </>
                                    )}
                                    {assessmentList.isSkippedByRm === false && (
                                        <>
                                            <div className="w-100">
                                                {files?.length > 0 &&
                                                    files
                                                        ?.filter((item: any) => item.isAddedByRm === false)
                                                        ?.map((file: any) => (
                                                            <div className="d-flex justify-content-between  mt-2 mb-2">
                                                                <div className="input-readonly" data-tooltip-id="my-tooltip-8" data-tooltip-content="Skip Assessment Document ">
                                                                    <textarea rows={1} value={file.documentFile} onClick={(e: any) => openpdf(e, file.documentPath)} readOnly />
                                                                </div>
                                                                {assessmentList.hasMovedToCosting === false && (
                                                                    <div>
                                                                        <button className="btn-icon red-background" name="btndelete" onClick={(e: any) => removepdf(e, file.documentPath)}>
                                                                            <i className="fa-solid fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                                <Tooltip id="my-tooltip-8" className="my-tooltip" />
                                                            </div>
                                                        ))}
                                                {assessmentList.hasMovedToCosting === false && (
                                                    <div id="penPictureUpload">
                                                        <Controller
                                                            control={control}
                                                            name="AssessmentDocument"
                                                            render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                                                return (
                                                                    <FilePond
                                                                        files={
                                                                            files?.length <= 5 &&
                                                                            files?.map((item: any) => ({
                                                                                source: item.documentName,
                                                                                options: {
                                                                                    type: 'local',
                                                                                    file: {
                                                                                        name: item.documentName,
                                                                                        size: 0
                                                                                    }
                                                                                }
                                                                            }))
                                                                        }
                                                                        // @ts-ignore
                                                                        allowFileSizeValidation={true}
                                                                        maxFileSize={1000 * 1000 * 5}
                                                                        allowFileTypeValidation={true}
                                                                        acceptedFileTypes={[
                                                                            'application/pdf',
                                                                            'image/*',
                                                                            'application/vnd.ms-excel',
                                                                            'application/vnd.ms-powerpoint',
                                                                            'application/msword',
                                                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                                        ]}
                                                                        labelMaxFileSizeExceeded={'Total size of all the attachments cannot exceed 5 MB'}
                                                                        fileValidateTypeLabelExpectedTypes={''}
                                                                        name={'AssessmentDocument'}
                                                                        labelIdle={`${t('VacantRightbar.pdftitle3')}<span class="filepond--label-action"> ${t('VacantRightbar.pdftitle2')}</span>`}
                                                                        allowMultiple
                                                                        maxFiles={5}
                                                                        maxParallelUploads={5}
                                                                        beforeAddFile={() => {
                                                                            eventEmitter.emit('eventSetLoader', true);
                                                                            return true;
                                                                        }}
                                                                        // @ts-ignore
                                                                        onerror={(props) => setIsError(props.main)}
                                                                        onwarning={(error) => {
                                                                            if (error.body === 'Max files') {
                                                                                setIsError('Maximum only 5 pen picture attachments are allowed');
                                                                            }
                                                                        }}
                                                                        server={{
                                                                            url: `${BASEURLDEV}${API_ENDPOINT.UploadAssessmentdoc}`,
                                                                            process: {
                                                                                headers: {
                                                                                    Authorization: `Bearer ${token}`
                                                                                },
                                                                                ondata: (formData) => {
                                                                                    formData.append('LeadPoolingDetails_Id', assessmentList.leadPoolingId);
                                                                                    return formData;
                                                                                },
                                                                                //@ts-ignore
                                                                                onload: (res: any) => {
                                                                                    if (files?.length === 5) {
                                                                                        setIsError('Maximum only 5 pen picture attachments are allowed');
                                                                                        eventEmitter.emit('eventSetLoader', false);
                                                                                        return;
                                                                                    }

                                                                                    const fileres = JSON.parse(res);
                                                                                    if (fileres?.status === false) {
                                                                                        eventEmitter.emit('eventSetLoader', false);
                                                                                        setIsError(fileres?.message);
                                                                                    } else {
                                                                                        eventEmitter.emit('eventSetLoader', false);
                                                                                        setIsError(null);
                                                                                        getLeaddata(DataLeadInfo?.data?.leadId, 3);
                                                                                    }
                                                                                    //   let filedata = fileres.data;
                                                                                    //   setFiles((o: any) => [
                                                                                    //       ...o,
                                                                                    //       {
                                                                                    //           filepath: filedata.documentPath,
                                                                                    //           filename: filedata.documentName
                                                                                    //       }
                                                                                    //   ]);
                                                                                    // props.updateFData({
                                                                                    //     leadPenPicture_Id: filedata.leadPenPicture_Id,
                                                                                    //     filepath: filedata.documentPath,
                                                                                    //     filename: filedata.documentName,
                                                                                    // });

                                                                                    setIsError(null);
                                                                                }
                                                                            },
                                                                            remove: (source, load, error) => {
                                                                                files?.length <= 5 && setIsError(null);
                                                                                error('oh my goodness');
                                                                                eventEmitter.emit('eventSetLoader', false);
                                                                                load();
                                                                            }
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            {assessmentList.hasMovedToCosting === false && (
                                                <>
                                                    {isError && <p className="text-danger ps-1">{isError}</p>}
                                                    <p className="small mt-2">
                                                        <strong>{t('VacantRightbar.note')}</strong> {t('VacantRightbar.note3')}
                                                    </p>
                                                    <p className="small mt-2">
                                                        <strong>{t('VacantRightbar.note')}</strong> {t('SM_Assessment.Notes')}
                                                    </p>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}

                    {serviceAssessmentDetails?.assessmentStatus !== 3 && (
                        <>
                            <p className="mb-3 mt-2">{t('SM_Assessment.click_paragraph')}</p>
                            <a className="btn btn-primary btn-block pt-2 pb-2 mb-3" onClick={(event) => handleClickModal(event)}>
                                {t('SM_Assessment.Start_Assessment_button')}
                            </a>
                            <a
                                className="btn btn-primary btn-block pt-2 pb-2 mb-3"
                                onClick={(event) => skipAssessment(serviceAssessmentDetails?.leadPoolingId, serviceAssessmentDetails?.serviceName)}
                            >
                                {t('SM_Assessment.Skip_Assessment_button')}
                            </a>
                        </>
                    )}
                </>
            )}
            {assessmentDetails !== undefined && (
                <>
                    {DataLeadInfo?.data?.assessment?.length > 0 &&
                        DataLeadInfo?.data?.assessment
                            .filter((item: any) => item.serviceId === DataServiceDetail.serviceId)
                            ?.map((assessmentList: any) => (
                                <>
                                    <div className="status-box my-3" key={assessmentList.leadPoolingId}>
                                        {assessmentList?.referralLogs?.length > 0 &&
                                            assessmentList?.referralLogs?.map((assessment: any) => (
                                                <>
                                                    <div className="d-flex align-items-center">
                                                        <span className="ps-0">
                                                            <p className="mb-1">
                                                                <i>{assessment.logMessage}</i>
                                                                {assessment.status === 3 && (
                                                        <i
                                                            className="fa-solid fa-circle-info ms-2"
                                                            data-tooltip-id="my-tooltip"
                                                            data-tooltip-content={`${assessment?.reason} - ${assessment?.comment}`}
                                                        ></i>
                                                    )}
                                                            </p>
                                                        </span>
                                                    </div>
                                                   
                                                </>
                                            ))}

                                        {assessmentList.assessmentStatus !== 3 && (
                                            <i className="fa-solid fa-circle-info dark-text cursor-pointer" onClick={() => setPoolingId(assessmentList.leadPoolingId)}></i>
                                        )}
                                    </div>
                                </>
                            ))}
                    <div className="status-box text-center mb-3 p-3">
                        {assessmentDetails !== undefined && assessmentDetails?.percentage === 100 && assessmentDetails?.generalInformation?.formData?.assessmentStatus === 1 && (
                            <>
                                <h1 className="success-text">
                                    <i className="fa-solid fa-circle-check"></i>
                                </h1>
                                <p className="bolder">{t('SM_Assessment.Assessment_saved')}</p>
                            </>
                        )}
                        {assessmentDetails !== undefined && assessmentDetails?.percentage < 100 && assessmentDetails?.generalInformation?.formData?.assessmentStatus === 1 && (
                            <>
                                <p className="bolder">{t('SM_Assessment.Assessment_started')}</p>
                                <br />
                            </>
                        )}

                        {assessmentDetails !== undefined && assessmentDetails?.percentage === 100 && assessmentDetails?.generalInformation?.formData?.assessmentStatus === 2 && (
                            <>
                                <h1 className="success-text">
                                    <i className="fa-solid fa-circle-check"></i>
                                </h1>
                                <p className="bolder">{t('SM_Assessment.Assessment_completed')}</p>
                            </>
                        )}

                        <div className="progress-bar mt-3">
                            <ProgressBar completed={DataAssessmentDetails?.data?.percentage ? DataAssessmentDetails?.data?.percentage : 0} />
                        </div>

                        {assessmentDetails !== undefined && assessmentDetails?.percentage === 100 && assessmentDetails?.generalInformation?.formData?.assessmentStatus === 1 && (
                            <>
                                <a href="#" onClick={onCLickHandlerSubmitAssessment} className="btn btn-primary mt-3">
                                    {t('SM_Assessment.Submit_Assessmen_button')}
                                </a>
                            </>
                        )}
                    </div>

                    {files?.length > 0 && serviceAssessmentDetails?.isSkippedByRm === false && (
                        <>
                            <p className="purple-text mt-2 mb-2 bold">{t('Generate_Contract.Attachments')}</p>
                        </>
                    )}
                    <div className="w-100">
                        {files?.length > 0 &&
                            files
                                ?.filter((item: any) => item.isAddedByRm === false)
                                ?.map((file: any) => (
                                    <div className="d-flex justify-content-between  mt-2 mb-2">
                                        <div className="input-readonly" data-tooltip-id="my-tooltip-9" data-tooltip-content="Skip Assessment Document ">
                                            <textarea rows={1} value={file.documentFile} onClick={(e: any) => openpdf(e, file.documentPath)} readOnly />
                                        </div>
                                        {serviceAssessmentDetails?.hasMovedToCosting === false && (
                                            <div>
                                                <button className="btn-icon red-background" name="btndelete" onClick={(e: any) => removepdf(e, file.documentPath)}>
                                                    <i className="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                        )}
                                        <Tooltip id="my-tooltip-9" className="my-tooltip" />
                                    </div>
                                ))}
                        {serviceAssessmentDetails?.hasMovedToCosting === false && serviceAssessmentDetails?.isSkippedByRm === false && (
                            <div id="penPictureUpload">
                                <Controller
                                    control={control}
                                    name="AssessmentDocument"
                                    render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                        return (
                                            <FilePond
                                                files={
                                                    files?.length < 5 &&
                                                    files?.map((item: any) => ({
                                                        source: item.documentName,
                                                        options: {
                                                            type: 'local',
                                                            file: {
                                                                name: item.documentName,
                                                                size: 0
                                                            }
                                                        }
                                                    }))
                                                }
                                                // @ts-ignore
                                                allowFileSizeValidation={true}
                                                maxFileSize={1000 * 1000 * 5}
                                                allowFileTypeValidation={true}
                                                acceptedFileTypes={[
                                                    'application/pdf',
                                                    'image/*',
                                                    'application/vnd.ms-excel',
                                                    'application/vnd.ms-powerpoint',
                                                    'application/msword',
                                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                ]}
                                                labelMaxFileSizeExceeded={'Total size of all the attachments cannot exceed 5 MB'}
                                                fileValidateTypeLabelExpectedTypes={''}
                                                name={'AssessmentDocument'}
                                                labelIdle={`${t('VacantRightbar.pdftitle3')}<span class="filepond--label-action"> ${t('VacantRightbar.pdftitle2')}</span>`}
                                                allowMultiple
                                                maxFiles={5}
                                                maxParallelUploads={5}
                                                beforeAddFile={() => {
                                                    eventEmitter.emit('eventSetLoader', true);
                                                    return true;
                                                }}
                                                // @ts-ignore
                                                onerror={(props) => setIsError(props.main)}
                                                onwarning={(error) => {
                                                    if (error.body === 'Max files') {
                                                        setIsError('Maximum only 5 pen picture attachments are allowed');
                                                    }
                                                }}
                                                server={{
                                                    url: `${BASEURLDEV}${API_ENDPOINT.UploadAssessmentdoc}`,
                                                    process: {
                                                        headers: {
                                                            Authorization: `Bearer ${token}`
                                                        },
                                                        ondata: (formData) => {
                                                            formData.append('LeadPoolingDetails_Id', serviceAssessmentDetails?.leadPoolingId);
                                                            return formData;
                                                        },
                                                        //@ts-ignore
                                                        onload: (res: any) => {
                                                            if (files?.length === 5) {
                                                                setIsError('Maximum only 5 pen picture attachments are allowed');
                                                                eventEmitter.emit('eventSetLoader', false);
                                                                return;
                                                            }

                                                            const fileres = JSON.parse(res);
                                                            if (fileres?.status === false) {
                                                                eventEmitter.emit('eventSetLoader', false);
                                                                setIsError(fileres?.message);
                                                            } else {
                                                                eventEmitter.emit('eventSetLoader', false);
                                                                setIsError(null);
                                                                getLeaddata(DataLeadInfo?.data?.leadId, 3);
                                                            }
                                                            //   let filedata = fileres.data;
                                                            //   setFiles((o: any) => [
                                                            //       ...o,
                                                            //       {
                                                            //           filepath: filedata.documentPath,
                                                            //           filename: filedata.documentName
                                                            //       }
                                                            //   ]);
                                                            // props.updateFData({
                                                            //     leadPenPicture_Id: filedata.leadPenPicture_Id,
                                                            //     filepath: filedata.documentPath,
                                                            //     filename: filedata.documentName,
                                                            // });

                                                            setIsError(null);
                                                        }
                                                    },
                                                    remove: (source, load, error) => {
                                                        files?.length <= 5 && setIsError(null);
                                                        error('oh my goodness');
                                                        eventEmitter.emit('eventSetLoader', false);
                                                        load();
                                                    }
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    {serviceAssessmentDetails?.hasMovedToCosting === false && (
                        <>
                            {isError && <p className="text-danger ps-1">{isError}</p>}
                            <p className="small mt-2">
                                <strong>{t('VacantRightbar.note')}</strong> {t('VacantRightbar.note3')}
                            </p>
                            <p className="small mt-2">
                                <strong>{t('VacantRightbar.note')}</strong> {t('SM_Assessment.Notes')}
                            </p>
                        </>
                    )}
                    {assessmentDetails !== undefined && assessmentDetails?.percentage === 100 && assessmentDetails?.generalInformation?.formData?.assessmentStatus === 2 && (
                        <>
                            <a href="#" className="btn btn-primary btn-block pt-2 pb-2 mb-3" onClick={(event) => handleClickModal(event)}>
                                {t('SM_Assessment.View_Assessment_button')}
                            </a>
                        </>
                    )}

                    {assessmentDetails !== undefined && assessmentDetails?.percentage < 100 && assessmentDetails?.generalInformation?.formData?.assessmentStatus === 1 && (
                        <>
                            {serviceAssessmentDetails?.assessmentStatus !== 3 && (
                                <>
                                    <a href="#" className="btn btn-primary btn-block pt-2 pb-2 mb-3" onClick={(event) => handleClickModal(event)}>
                                        {t('SM_Assessment.Continue_Assessment_button')}
                                    </a>
                                    <a
                                        className="btn btn-primary btn-block pt-2 pb-2 mb-3"
                                        onClick={(event) => skipAssessment(serviceAssessmentDetails?.leadPoolingId, serviceAssessmentDetails?.serviceName)}
                                    >
                                        {t('SM_Assessment.Skip_Assessment_button')}
                                    </a>
                                </>
                            )}
                        </>
                    )}
                    <div>
                        <a href="#general-info" className="status-box green w-100 d-flex align-items-center mb-2 p-2" onClick={(event) => handleClickModal(event)}>
                            <img src={General_Info} alt="" width={17} className="me-2 float-left" onClick={(event) => handleClickModal(event)} />
                            <span className="bold">{t('GeneralInfo.General_Information')}</span>
                            <span className={`section-staus large ms-auto ${DataAssessmentDetails?.data?.generalInformation?.formStatus ? 'green' : 'red'}`}></span>
                        </a>
                        <a href="#progress-note" className="status-box green w-100 d-flex align-items-center mb-2 p-2" onClick={(event) => handleClickModal(event)}>
                            <img src={Progress_Note} alt="" width={17} className="me-2 float-left" />
                            <span className="bold">{t('ProgressNote.Progress_Notes')}</span>
                            <span className={`section-staus large ms-auto ${DataAssessmentDetails?.data?.assessmentProgressNoteModel?.formStatus ? 'green' : 'red'}`}></span>
                        </a>
                        <a href="#service-profile" className="status-box green w-100 d-flex align-items-center mb-2 p-2" onClick={(event) => handleClickModal(event)}>
                            <img src={Info_gethering} alt="" width={17} className="me-2 float-left" />
                            <span className="bold">{t('ServiceProfile.Service_Profile')}</span>
                            <span className={`section-staus large ms-auto ${DataAssessmentDetails?.data?.assessmentServiceUserProfileModel?.formStatus ? 'green' : 'red'}`}></span>
                        </a>
                        <a href="#strength-assessment" className="status-box green w-100 d-flex align-items-center mb-2 p-2" onClick={(event) => handleClickModal(event)}>
                            <img src={Strength} alt="" width={23} className="me-2 float-left" />
                            <span className="bold">{t('Strengths_Assessment.Strengths_Assessment')}</span>
                            <span className={`section-staus large ms-auto ${DataAssessmentDetails?.data?.assessmentStrengthsBasedAsmtModel?.formStatus ? 'green' : 'red'}`}></span>
                        </a>
                        <a href="#capacity-info" className="status-box green w-100 d-flex align-items-center mb-2 p-2" onClick={(event) => handleClickModal(event)}>
                            <img src={Capacity} alt="" width={19} className="me-2 float-left" />
                            <span className="bold flex-1">{t('Capacity_Information.Capacity_Legal')}</span>
                            <span className={`section-staus large ms-auto ${DataAssessmentDetails?.data?.assessmentCapacityLegalOrderModel?.formStatus ? 'green' : 'red'}`}></span>
                        </a>
                        <a href="#support-care-giver" className="status-box green w-100 d-flex align-items-center mb-2 p-2" onClick={(event) => handleClickModal(event)}>
                            <img src={Care_Giver} alt="" width={17} className="me-2 float-left" />
                            <span className="bold">{t('Support_Care_Giver.Support_Giver')}</span>
                            <span className={`section-staus large ms-auto ${DataAssessmentDetails?.data?.assessmentSupportWorkerModel?.formStatus ? 'green' : 'red'}`}></span>
                        </a>
                        <a href="#what-next" className="status-box green w-100 d-flex align-items-center mb-2 p-2" onClick={(event) => handleClickModal(event)}>
                            <img src={Next} alt="" width={14} className="me-2 float-left" />
                            <span className="bold">{t('What_Happeens_Next.What_Happeens_Next_title')}</span>
                            <span className={`section-staus large ms-auto ${DataAssessmentDetails?.data?.assessmentWhatHappeensNextModel?.formStatus ? 'green' : 'red'}`}></span>
                        </a>
                        <a href="#diversity-profile" className="status-box green w-100 d-flex align-items-center mb-2 p-2" onClick={(event) => handleClickModal(event)}>
                            <img src={Profile} alt="" width={14} className="me-2 float-left" />
                            <span className="bold">{t('Service_User_Diversity_Profile.Service_User_Diversity_Profile')}</span>
                            <span className={`section-staus large ms-auto ${DataAssessmentDetails?.data?.assessmentServiceUserDiversityProfileModel?.formStatus ? 'green' : 'red'}`}></span>
                        </a>
                    </div>
                </>
            )}
            <Tooltip id={`my-tooltip-8`} className="my-tooltip" />
            <RenderModalContainer>
                {showModal && leadPoolingId !== '' && (
                    <AssessmentModalContainer
                        onClose={toggle}
                        closePopup={closePopup}
                        leadPoolingId={leadPoolingId}
                        assessmentDetails={assessmentDetails}
                        orgName={orgName}
                        status={serviceAssessmentDetails?.assessmentStatus}
                    />
                )}

                {showModalSkip && (
                    <RejectContainer
                        onClose={toggleskip}
                        leadid={DataLeadInfo?.data}
                        poolingId={leadpoolingid}
                        mname={'skipsm'}
                        title={`Skip Assessment for ${servicename}`}
                        referralid={props.referralid}
                    />
                )}
            </RenderModalContainer>
            <RenderModalContainer>
                {poolingId !== null && (
                    <ScheduleAssessmentContainer leadInfo={DataLeadInfo} onClose={() => setPoolingId(null)} poolingId={poolingId} isViewMode={isViewMode} allowEdit={false} />
                )}
            </RenderModalContainer>
        </div>
    );
};

export default AssessmentView;
