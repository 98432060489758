import { Navigate } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import LoginPage from '../pages/LoginPage/indexContainer';
import AccessDenied from '../components/AccessDenied';
import DashboardPage from '../pages/DashboardPage/indexContainer';
import Layout from '../layout/Layout';
import { ROUTES_DATA } from './RoutesConstants';
import BillingContainer from '../pages/BillingManagementPage/BillingContainer';
import HelpContainer from '../pages/Help/HelpContainer';
import ReferralManagementContainer from '../pages/ReferralManagementPage/ReferralManagementContainer';
import ContractSignedSuccess from '../components/ContractSignedSuccess';
import ChangePasswordContainer from '../pages/ChangePassword/ChangePasswordContainer';
import NHSContainer from '../pages/NHSPage/NHSContainer';
import IntegrationInterfaceContainer from '../pages/IntegrationInterface/IntegrationInterfaceContainer';
import NHSServiceDetailContainer from '../pages/NHSPage/NHSServiceDetail/NHSServiceDetailContainer';
import MapViewContainer from '../pages/ReferralManagementPage/ReferralManager/MapView/MapViewContainer';
import ProviderServiceDetailView from '../pages/NHSPage/NHSServiceDetail/ServiceTab/Services/ProviderServiceDetail/ProviderServiceDetailView';
import MapViewServiceContainer from '../pages/ReferralManagementPage/ServiceManager/MapViewService/MapViewServiceContainer';
import NHSReferralManagementContainer from '../pages/NHSPage/NHSReferralManagement/NHSReferralManagementContainer';
import GenericErrorComponentSplit from '../components/GenericErrorComponentSplit';
import OnboardingView from '../pages/Onboarding/OnboardingView';
import OnboardingContainer from '../pages/Onboarding/OnboardingContainer';
import WelcomeContainer from '../pages/Onboarding/Welcome/WelcomeContainer';
import ServiceOnboardingPreviewContainer from '../pages/Onboarding/ServiceOnboardingPreview/ServiceOnboardingPreviewContainer';
import LocateAdminContainer from '../pages/LocateAdmin/LocateAdminContainer';
import UserManagementContainer from '../pages/UserManagement/UserManagementContainer';
import CreateUserContainer from '../pages/UserManagement/CreateUser/CreateUserContainer';
import AccessRoleContainer from '../pages/AccessRole/AccessRoleContainer';
import ServiceCardsContainer from '../pages/ServiceCards/ServiceCardsContainer';
import CreateServiceCardsContainer from '../pages/ServiceCards/CreateServiceCards/CreateServiceCardsContainer';
import ReportsContainer from '../pages/Reports/ReportsContainer';
import FunderManagementContainer from '../pages/FunderManagement/FunderManagementContainer';
import CreateFunderContainer from '../pages/FunderManagement/CreateFunder/CreateFunderContainer';
import ProductManagementContainer from '../pages/ProductManagement/ProductManagementContainer';
import CreateProductContainer from '../pages/ProductManagement/CreateProduct/CreateProductContainer';
import CreateAccessRoleContainer from '../pages/AccessRole/CreateAccessRole/CreateAccessRoleContainer';
import ReferralReportView from '../pages/Reports/ReferralReport/ReferralReportView';
import ReservationReportView from '../pages/Reports/ReservationReport/ReservationReportView';
import DraftPatientsReportView from '../pages/Reports/DraftPatientsReport/DraftPatientsReportView';
import DischargeReportView from '../pages/Reports/DischargeReport/DischargeReportView';
import AdmissionsReportView from '../pages/Reports/AdmissionsReport/AdmissionsReportView';
import ActiveAdmissionsView from '../pages/Reports/ActiveAdmissions/ActiveAdmissionsView';
import BedTransactionHistoryReportsView from '../pages/Reports/BedTransactionHistoryReport/BedTransactionHistoryReportView';
import CurrentBedOccupancyReportView from '../pages/Reports/CurrentBedOccupancy/CurrentBedOccupancyReportView';
import CurrentBedStatusWithPricingView from '../pages/Reports/CurrentBedStatusWithPricingReport/CurrentBedStatusWithPricingView';
import PatientDetailsReportContainer from '../pages/Reports/PatientDetailsReport/PatientDetailsReportContainer';
import PatientTransactionHistoryReportContainer from '../pages/Reports/PatientTransactionHistoryReport/PatientTransactionHistoryReportContainer';
import ForgotPasswordContainer from '../components/ForgotPassword/ForgotPasswordContainer';
import ResetPasswordContainter from '../components/Reset-Password/ResetPasswordContainter';

const Routing = [
    {
        element: <PublicRoutes />,
        children: [
            {
                path: ROUTES_DATA.ROOT,
                element: <Navigate to={ROUTES_DATA.LOGIN} />
            },
            {
                path: ROUTES_DATA.LOGIN,
                element: <LoginPage />
            },
            {
                path: ROUTES_DATA.FORGOTPASSWORD,
                element: <ForgotPasswordContainer/>
            },
            {
                path: ROUTES_DATA.RESETPASSWORD,
                element: <ResetPasswordContainter/>
            },
        ]
    },
    {
        element: <PrivateRoutes />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: ROUTES_DATA.DASHBOARD,
                        element: <DashboardPage />
                    },
                    {
                        path: ROUTES_DATA.BILLINGMANAGEMENT,
                        element: <BillingContainer />
                    },
                    {
                        path: ROUTES_DATA.REFERRALMANAGEMENT,
                        element: <ReferralManagementContainer />
                    },
                    {
                        path: ROUTES_DATA.HELP,
                        element: <HelpContainer />
                    },
                    {
                        path: ROUTES_DATA.CHANGEPASSWORD,
                        element: <ChangePasswordContainer />
                    },
                    {
                        path: ROUTES_DATA.NHS,
                        element: <NHSContainer />
                    },
                    {
                        path: ROUTES_DATA.INTEGRATION,
                        element: <IntegrationInterfaceContainer />
                    },
                    {
                        path: ROUTES_DATA.INTEGRATION,
                        element: <IntegrationInterfaceContainer />
                    },
                    {
                        path: ROUTES_DATA.NHS,
                        element: <NHSContainer />
                    },
                    {
                        path: ROUTES_DATA.NHSREFERRAL,
                        element: <NHSReferralManagementContainer />
                    },
                    {
                        path: `${ROUTES_DATA.SERVICEDETAIL}/:serviceSlug`,
                        element: <NHSServiceDetailContainer />
                    },
                    {
                        path: ROUTES_DATA.REFERRALMANAGERVIEWMAP,
                        element: <MapViewContainer />
                    },
                    {
                        path: `${ROUTES_DATA.PROVIDERSERVICEDETAIL}/:id`,
                        element: <ProviderServiceDetailView />
                    },
                    {
                        path: ROUTES_DATA.SERVICEMANAGERVIEWMAP,
                        element: <MapViewServiceContainer />
                    },
                    {
                        path: ROUTES_DATA.ONBOARDING,
                        element: <OnboardingContainer />
                    },
                    {
                        path: ROUTES_DATA.ONBOARDINGDASHBOARD,
                        element: <WelcomeContainer />
                    },
                    {
                        path: ROUTES_DATA.ONBOARDINGPREVIEW,
                        element: <ServiceOnboardingPreviewContainer />
                    },
                    {
                        path: ROUTES_DATA.CLAIMREQUEST,
                        element: <LocateAdminContainer />
                    },
                    {
                        path: ROUTES_DATA.USERMANAGEMENT,
                        element: <UserManagementContainer />
                    },
                    {
                        path: ROUTES_DATA.CREATEUSER,
                        element: <CreateUserContainer/>
                    },
                    {
                        path: ROUTES_DATA.ACCESSROLE,
                        element: <AccessRoleContainer/>
                    },
                    {
                        path: ROUTES_DATA.SERVICECARDS,
                        element: <ServiceCardsContainer/>
                    },
                    {
                        path: ROUTES_DATA.CREATESERVICECARDS,
                        element: <CreateServiceCardsContainer/>
                    },
                    {
                        path: ROUTES_DATA.REPORTS,
                        element: <ReportsContainer />
                    },
                    {
                        path: ROUTES_DATA.FUNDERMANAGEMENT,
                        element: <FunderManagementContainer />
                    },
                    {
                        path: ROUTES_DATA.CREATEFUNDER,
                        element: <CreateFunderContainer/>
                    },
                    {
                        path: ROUTES_DATA.PRODUCTMANAGEMENT,
                        element: <ProductManagementContainer />
                    },
                    {
                        path: ROUTES_DATA.CREATEPRODUCT,
                        element: <CreateProductContainer/>
                    },
                    {
                        path: ROUTES_DATA.CREATEACCESSROLE,
                        element: <CreateAccessRoleContainer/>
                    },
                    {
                        path: ROUTES_DATA.PATIENTTRANSACTIONHISTORYREPORT,
                        element: <PatientTransactionHistoryReportContainer />
                    },
                    {
                        path: ROUTES_DATA.PATIENTDETAILSREPORT,
                        element: <PatientDetailsReportContainer />
                    },
                    {
                        path: ROUTES_DATA.CURRENTBEDSTATUSREPORT,
                        element: <CurrentBedStatusWithPricingView />
                    },
                    {
                        path: ROUTES_DATA.BEDTRANSACTIONHISTORYREPORTS,
                        element: <BedTransactionHistoryReportsView />
                    },
                    {
                        path: ROUTES_DATA.ACTIVEADMISSIONSREPORT,
                        element: <ActiveAdmissionsView />
                    },
                    {
                        path: ROUTES_DATA.CURRENYBEDOCCUPANCYREPORT,
                        element: <CurrentBedOccupancyReportView />
                    },
                    {
                        path: ROUTES_DATA.ADMISSIONREPORT,
                        element: <AdmissionsReportView />
                    },
                    {
                        path: ROUTES_DATA.DISCHARGEREPORT,
                        element: <DischargeReportView />
                    },
                    {
                        path: ROUTES_DATA.DRAFTPATIENTSEREPORT,
                        element: <DraftPatientsReportView />
                    },
                    {
                        path: ROUTES_DATA.RESERVATIONREPORT,
                        element: <ReservationReportView />
                    },
                    {
                        path: ROUTES_DATA.REFERRALREPORT,
                        element: <ReferralReportView />
                    },
                    
                ]
            }
        ]
    },
    {
        path: ROUTES_DATA.AccessDenied,
        element: <AccessDenied />
    },
    {
        path: ROUTES_DATA.ContractSigned,
        element: <ContractSignedSuccess />
    },
    {
        path: ROUTES_DATA.ERRORPAGE,
        element: <GenericErrorComponentSplit />
    }
];
export default Routing;
