import React, { useEffect, useMemo, useState, useRef, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import {
    MaterialReactTable,
    MRT_TableInstance,
    MRT_FullScreenToggleButton,
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_TablePagination,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToolbarAlertBanner,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_VisibilityState,
    type MRT_RowSelectionState,
    type MRT_GroupingState,
    MRT_Localization
} from 'material-react-table';
import { Box } from '@mui/material';
import PaginationContainer from '../../../components/PaginationComponent/indexContainer';
import SmartSearchDropdownContainer from '../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { Tooltip } from 'react-tooltip';
import ExportIntegrationContainer from '../../IntegrationInterface/IntegrationBottom/ExportIntegration/ExportIntegrationContainer';
import RenderModalContainer from '../../../components/RenderModal/indexContainer';
import ExportReportsModalContainer from '../../../components/ExportReportsModal/ExportReportsModalContainer';
import ExportReportsContainer from '../ExportReports/ExportReportsContainer';

import ClientPortal from '../../../components/ClientPortal';

export enum Source {
    Integration = 'integration',
    Admission = 'admission',
    Referral = 'referral'
}

const CurrentBedStatusWithPricingReportView = (props: any) => {
    const { t } = useTranslation();
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'Organization', //access nested data with dot notation
                header: 'Organization',
                size: 100
            },
            {
                accessorKey: 'ServiceType',
                header: 'Service Type',
                size: 100
            },
            {
                accessorKey: 'ServiceName',
                header: 'Service Name',
                size: 100
            },
            {
                accessorKey: 'WardName',
                header: 'Ward Name',
                size: 100
            },
            {
                accessorKey: 'BedName',
                header: 'Bed Name',
                size: 100
            },
            {
                accessorKey: 'BedStatus',
                header: 'Bed Status',
                size: 100
            },
            {
                accessorKey: 'TotalWeeklyFee',
                header: 'Total Weekly Fee',
                size: 100
            }
        ],
        []
    );
    const Data = [
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100'
        },
        {
            Organization: 'Health Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Occupied',
            TotalWeeklyFee: '£ 100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '0001',
            BedStatus: 'Reserved',
            TotalWeeklyFee: '£ 100'
        }
    ];
    let [showModel, setShowModel] = useState<boolean>(false);
    const handleClickExport = () => {
        setShowModel(true);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row p-0">
                    <div className="col-xl-12 col-lg-12 mb-4">
                        <div className="box-shadow p-0">
                            <div>
                                <div className="administrator-header ">
                                    <div className="row align-items-center justify-content-between h-100">
                                        <div className="col-md-6">
                                            <p className="bold lead-text purple-text">Current Bed Status with Pricing</p>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <a href="/reports">
                                                <button className="btn btn-white btn-sm">
                                                    <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="administrator-content">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="d-flex mb-3 flex-column">
                                            <label className="bold mb-2">Bed Status*</label>
                                            <select className="form-control white">
                                                <option>Select Bed Status</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="d-flex mb-3 flex-column">
                                            <label className="bold mb-2">Service*</label>
                                            <div className="auto-complete-dropdown auto-complete-dropdown-cus mb-0">
                                                <SmartSearchDropdownContainer
                                                    smartSearchLabel={'Select Service'}
                                                    defaultValue={'Helllo'}
                                                    onChangeSmartSearch={(value: any) => {
                                                        const event = new Event('change');
                                                        let obj = {
                                                            name: 'messageCode',
                                                            value: value?.id
                                                        };
                                                    }}
                                                    smartSearch={'Role'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="d-flex mb-3 flex-column">
                                            <label className="bold mb-2">Ward Name/Bed Name</label>
                                            <select className="form-control white">
                                                <option>Select Ward Name/Bed Name</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-center">
                                        <button className="btn btn-secondary me-2">Clear</button>
                                        <button className="btn btn-primary me-2">Generate Report</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12 col-lg-12">
                        <div className="box-shadow p-0">
                            <div className="pt-3">
                                <div className="row pe-2 ps-2 d-flex justify-content-end">
                                    <div className="col-md-4 mb-3 d-flex justify-content-end">
                                        <div className="dropdown filter-dropdown me-2">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="">
                                                    <i className="fa fa-cog"></i> {t('TableManageColumns.Manage_Columns')}
                                                </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-columns report-dropdownmenu" aria-labelledby="navbarDropdownMenuLink">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Status Set On
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Length of Status
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Status Set By
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Est. Discharge Date
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Est. Date of Admission
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Est. Release Date
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="d-flex justify-content-end">
                                                            <label className="purple-text bold">
                                                                <i className="fa fa-undo me-1" aria-hidden="true"></i> Reset
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="dropdown filter-dropdown me-2">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div className="" onClick={() => props.onExportClick()}>
                                                <i className="fa-solid fa-file-export"></i> Export
                                            </div> 
                                        </a>
                                    </div> */}
                                        <ExportReportsContainer onExportClick={handleClickExport} />
                                    </div>
                                </div>
                                <div className="invoice-table reports-table">
                                    <MaterialReactTable
                                        columns={columns}
                                        data={Data}
                                        enableGrouping
                                        enableColumnActions={false}
                                        enableBottomToolbar={false}
                                        enableTopToolbar={false}
                                        enableColumnDragging={false}
                                        manualPagination
                                        manualSorting
                                    />
                                </div>
                                <div className="administrator-content">
                                    <div className="row align-items-center mt-3 invoice-table-pagination">
                                        <div className="col-lg-6">
                                            <div className="d-flex align-items-center">
                                                <p className="purple-text bold">Displaying 1 - 2 of 10</p>
                                                <div className="d-flex align-items-center ms-3">
                                                    <label className="mb-0 bold nowrap me-2">Page Size:</label>
                                                    <select className={`form-control white page-select`} id="supportedPersoIntegrationPageSize">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 ">
                                            <PaginationContainer currentPage={1} totalCount={100} pageSize={10} onPageChange={(page: any) => {}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    <RenderModalContainer>
                        {showModel && <ExportReportsModalContainer title="Export Report Details" onClose={() => setShowModel(false)}></ExportReportsModalContainer>}
                    </RenderModalContainer>
                }
            </div>
        </>
    );
};
export default CurrentBedStatusWithPricingReportView;
