import swal from 'sweetalert';
import React, { ChangeEvent, DragEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import userlogo1 from "../../../assets/images/userlogo-1.png";
import userlogo2 from "../../../assets/images/userlogo-2.jpg";
import userlogo3 from "../../../assets/images/userlogo-3.jpg";
import userlogo4 from "../../../assets/images/userlogo-4.jpg";
import uploadLogo from "../../../assets/images/upload.svg";
// import ProgressFooterView from '../ProgressFooter/ProgressFooterView';
import ClientPortal from '../../../components/ClientPortal';
import PaginationContainer from '../../../components/PaginationComponent/indexContainer';
import {
    BEConfigureInterface,
    BEServiceLogoInterface,
    ConfigureUserNameInterface,
    FEConfigureInterface,
    FEServiceLogoInterface,
    OrganizationListResponse,
    OrganizationServiceListPayload,
    OrganizationServiceListResponse,
    ServicesInterface,
    WelcomeViewProps,
    UploadDocumentResponse
} from './types';
import { GetConfigurationsDetails, GetOrganizationServiceList, NewUserAssigningService, UploadServiceLogo, getConfigureUserNameEmail, getSiteUsername, uploadVerificationDocument, updateWelcomeManageColumns, getManageColumnsForWelcomeScreen, DownloadServiceList } from '../../../service/onboarding-service';
import { ApiResponse } from '../../../utils/model';
import { MAX_FILE_SIZE_IN_MB, MAX_UPLOAD_FILE, ProfileStatus, VerificationStatus } from '../../../utils/constant';
import { debounce, first } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { CropperRef, Cropper, FixedCropper, ImageRestriction, DraggableArea } from 'react-advanced-cropper';
import * as Yup from 'yup';
import 'react-advanced-cropper/dist/style.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { setOnboardingProgress } from '../../../store/onboarding/onboardingProgressReducer';
import { useNavigate } from 'react-router';
import { ROUTES_DATA } from '../../../navigation/RoutesConstants';
import { setOnboardingService } from '../../../store/onboarding/onboardingServiceReducer';
import imageCompression from 'browser-image-compression';
import { RootState } from '../../../store';
import { setOnboardingIsPublished } from '../../../store/onboarding/onboardingPublishedReducer';
import { setOnboardingWardFacility } from '../../../store/onboarding/onboardingWardFacilityReducer';
import useComponentVisible from '../../../hooks/useComponentVisible';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import docIcon from '../../../assets/images/document-ic.svg';
import { Tooltip } from 'react-tooltip';
import ManageColumnContainer from './ManageColumn/ManageColumnContainer';
import DownloadServicesContainer from './DownloadServices/DownloadServicesContainer';

const configureInitialValue: FEConfigureInterface = {
    UserName: '',
    Email: '',
    Password: '',
    ConfirmPassword: ''
};

const WelcomeView = ({ organizationList }: WelcomeViewProps) => {

    const settings = {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        draggable: false,
    };

    const { t } = useTranslation();
    const { onboardingStages } = useSelector((state: RootState) => state.onboardingProgressStages);

    const [hasMore, setHasMore] = useState<boolean>(true);
    const [selectedTabPanel, setSelectedTabPanel] = useState<number>(1);
    const [showPassPolicy, setShowPassPolicy] = useState<boolean>(false);
    const [selectedService, setSelectedService] = useState<ServicesInterface | null>(null);
    // const [orgServiceList, setOrgServiceList] = useState<OrganizationServiceListResponse | null>(null);
    const [orgServiceArr, setOrgServiceArr] = useState<ServicesInterface[]>([]);
    const [orgServicePayload, setOrgServicePayload] = useState<OrganizationServiceListPayload>({
        orgId: '',
        searchValue: '',
        serviceLogo: '',
        page: 1,
        pageSize: 10
    });

    const [showPortal, setShowPortal] = useState(false);
    const [matchedOrg, setMatchedOrg] = useState<number[]>([]);
    const onboardingServices = useSelector((state: RootState) => state.onboardingService);
    const [isDragging, setIsDragging] = useState(false);
    const [isConfigureUserLoading, setIsConfigureUserLoading] = useState(false);
    const [nonSearchData, setNonSearchData] = useState<any>([]); const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const [selectedDocumentFile, setSelectedDocumentFile] = useState<File | null>(null);
    const [selectedOrgName, setSelectedOrgName] = useState<string>("")
    const [docLimit, setDocLimit] = useState()
    const [columnVisibility, setColumnVisibility] = useState({
        CompanyName: false,
        CQCProviderId: false
    })
    const [managedColumnsList, SetManagedColumnsList] = useState<any>([]);

    const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files?.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    // const handleDocumentUpload = async (serviceId: number) => {
    //     if (!selectedDocumentFile) {
    //         swal('Please select a file to upload', { icon: 'warning' });
    //         return;
    //     }

    //     const formData = new FormData();
    //     formData.append('verification', selectedDocumentFile);
    //     formData.append('OrgId', String(orgServicePayload.orgId));
    //     formData.append('serviceId', String(serviceId));
    //     formData.append('CompanyName', selectedOrgName);
    //     formData.append('Type', 'Other');

    //     try {
    //         const res: UploadDocumentResponse = await uploadVerificationDocument(formData);
    //         if (res.status === true) {
    //             swal(res.message, { icon: 'success' }).then(() => {
    //                 fetchOrganizationServiceList(orgServicePayload);
    //             });
    //         } else {
    //             swal(res.message, { icon: 'error' });
    //         }
    //     } catch (error) {
    //         swal('An error occurred while uploading the file', { icon: 'error' });
    //     }
    // };

    const handleFileUpload = async () => {
        if (!selectedFile) {
            swal('Please select a file to upload', { icon: 'warning' });
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('serviceId', String(selectedService?.serviceId));

        try {
            const res: ApiResponse<null> = await UploadServiceLogo(formData);
            if (res.status) {
                swal(res.message, { icon: 'success' }).then(() => {
                    fetchOrganizationServiceList(orgServicePayload);
                });
            } else {
                swal(res.message, { icon: 'error' });
            }
        } catch (error) {
            swal('An error occurred while uploading the file', { icon: 'error' });
        }
    };

    const handleFileInputChange = async (event: ChangeEvent<HTMLInputElement>, serviceId: number, docLength: any) => {
        const file = event.target.files?.[0];
        if (file && file.size > MAX_UPLOAD_FILE * 1024 * 1024) {
            swal(`File size should not exceed ${MAX_UPLOAD_FILE} MB.`, { icon: 'error' });
            return;
        }

        if (docLength === docLimit) {
            swal(`You have exceed the documents limit.`, { icon: 'error' });
            return;
        }

        setSelectedDocumentFile(file || null);
        if (file) {
            // handleDocumentUpload(serviceId)
            const formData = new FormData();
            formData.append('verification', file);
            formData.append('OrgId', String(orgServicePayload.orgId));
            formData.append('serviceId', String(serviceId));
            formData.append('CompanyName', selectedOrgName);
            formData.append('Type', 'OtherDocs');

            try {
                const res: UploadDocumentResponse = await uploadVerificationDocument(formData);
                if (res.status === true) {
                    swal(res.message, { icon: 'success' }).then(() => {
                        fetchOrganizationServiceList(orgServicePayload);
                    });
                } else {
                    swal(res.message, { icon: 'error' });
                }
            } catch (error) {
                swal('An error occurred while uploading the file', { icon: 'error' });
            }
        } else {
            swal('Please select a file to upload', { icon: 'warning' });
        }
    };

    // Pagination Logic
    const [currentPage, setCurrentPage] = useState<{ orgId: number, page: number }[]>([]);
    const [totalCount, setTotalCount] = useState<any>();
    const [showFrom, setShowFrom] = useState<any>();
    const [showTo, setShowTo] = useState<any>();
    const [PageSize, setPageSize] = useState(10);

    const getCurrentPage = (page: any) => {
        // setCurrentPage(page);
        setCurrentPage((prevPage) => {
            return prevPage.map((item) => {
                if (item.orgId === selectedTabPanel) {
                    return { ...item, page: page }
                } else {
                    return item
                }
            })
        });
        let tempOrgListPayload: any = orgServicePayload;
        tempOrgListPayload.page = page;
        setOrgServicePayload(tempOrgListPayload);
        // getInvoiceListData();
        fetchOrganizationServiceList(tempOrgListPayload);
    };

    const handleSizeChange = (e: any) => {
        setPageSize(Number(e.target.value));

        let tempOrgListPayload: any = orgServicePayload;
        tempOrgListPayload.page = 1;
        tempOrgListPayload.pageSize = Number(e.target.value);
        setCurrentPage((prevPage) => {
            return prevPage.map((item) => {
                return { ...item, page: 1 }
            })
        });
        setOrgServicePayload(tempOrgListPayload);
        // getInvoiceListData();
        fetchOrganizationServiceList(tempOrgListPayload);
    };

    useEffect(() => {
        let showFromTemp = (currentPage.find((item) => (item.orgId === selectedTabPanel))?.page! - 1) * PageSize + 1;
        let showToTemp = showFromTemp + PageSize;
        showToTemp = showToTemp - 1 > Number(totalCount) ? Number(totalCount) : showToTemp - 1;
        setShowFrom(showFromTemp);
        setShowTo(showToTemp);
    }, [currentPage, totalCount, PageSize])


    const getManageColumns = async () => {
        const response = await getManageColumnsForWelcomeScreen(2)
        if (response.status === true && response.data !== undefined && response.data !== null && response.data.length > 0) {
            SetManagedColumnsList(response.data)
        }
    }

    const onClickHandlerDownloadServices = async (e: any) => {
        e.stopPropagation()
        e.preventDefault()
        const payload = {
            ...orgServicePayload,
            isExport: true
        }

        const response = await DownloadServiceList(payload);
        let mediaType = response?.data?.fileType;
        let useInput = response?.data?.csvData;
        // let csvContent = atob(response.data.csvData);
        // let data = new Blob([csvContent], { type: response.data.fileType });
        // let csvURL = window.URL.createObjectURL(data);
        let tempLink = document.createElement('a');
        // tempLink.href = csvURL;
        tempLink.href = mediaType + useInput;
        tempLink.setAttribute('download', `${response?.data?.fileName}.xlsx`);
        tempLink.click();
    };


    useEffect(() => {
        if (managedColumnsList?.length > 0) {
            managedColumnsList.map((val: any) => {
                if (val.columnName === "CompanyName") {
                    setColumnVisibility((prevState: any) => ({
                        ...prevState,
                        CompanyName: true
                    }))
                }
                if (val.columnName === "CQCProviderId") {
                    setColumnVisibility((prevState: any) => ({
                        ...prevState,
                        CQCProviderId: true
                    }))
                }
            })
        }

    }, [managedColumnsList])

    const onChangeManageColumnHandler = async (data: any) => {
        if (data.ColumnName === 'CompanyName' && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                CompanyName: true
            }));
        } else if (data.ColumnName === 'CompanyName' && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                CompanyName: false
            }));
        }

        if (data.ColumnName === 'CQCProviderId' && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                CQCProviderId: true
            }));
        } else if (data.ColumnName === 'CQCProviderId' && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                CQCProviderId: false
            }));
        }
        const payloadObj = {
            ColumnName: data.ColumnName,
            IsActive: data.IsActive,
            PageType: 2
        }
        await updateWelcomeManageColumns(payloadObj)
    };


    const croppedImg = useRef<string | File>('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [expandedServiceId, setExpandedServiceId] = useState(null);

    const handleViewDocClick = useCallback((serviceId) => {
        setExpandedServiceId((prevId) => (prevId === serviceId ? null : serviceId));
    }, []);

    const configureUserValidation = Yup.object().shape({
        UserName: Yup.string().required(),
        Email: Yup.string()
            .trim()
            .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Email is Invalid')
            .email('Email is Invalid')
            .required(),
        // Password: Yup.string().required('Please Enter your password.')
        //     .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'),
        // ConfirmPassword: Yup.string().required('Please Enter your confirm password.')
        //     .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character')
        //     .oneOf([Yup.ref("Password")], "Passwords do not match"),

        Password: Yup.string().when('Email', {
            is: (email: string) => (siteUsernameList?.find(option => option.email === email) ? true : false),
            then: (schema: any) => schema.notRequired(),
            otherwise: (schema: any) =>
                schema
                    .required('Please Enter your password.')
                    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, { message: 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character', excludeEmptyString: true })
        }),
        ConfirmPassword: Yup.string().when('Email', {
            is: (email: string) => (siteUsernameList?.find(option => option.email === email) ? true : false),
            then: (schema: any) => schema.notRequired(),
            otherwise: (schema: any) =>
                schema
                    .required('Please Enter your confirm password.')
                    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, { message: 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character', excludeEmptyString: true })
                    .oneOf([Yup.ref("Password")], "Passwords do not match")
        }),
    });

    const { register, control, handleSubmit, setValue, getValues, watch: configureWatch, formState: { errors, isDirty, isValid }, reset: resetConfigureUser } = useForm<FEConfigureInterface>({
        defaultValues: configureInitialValue,
        resolver: yupResolver(configureUserValidation),
        mode: 'all'
    });

    const {
        handleSubmit: logoSubmit,
        watch,
        setValue: setLogo,
        getValues: getLogoValue,
        formState: { isDirty: isImageDirty }
    } = useForm<FEServiceLogoInterface>({
        defaultValues: { logo: '' },
        mode: 'all'
    });

    const values = watch();

    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const logoInputRef = useRef<HTMLInputElement>(null);
    const handleModal = async (event: any, service?: ServicesInterface) => {
        event.preventDefault();
        if (service) {
            setSelectedService(service);

            const res: ApiResponse<ConfigureUserNameInterface> = await getConfigureUserNameEmail(service.serviceId);
            if (res.data) {
                setValue('Email', res.data.email);
                setValue('UserName', res.data.userName);
                setValue('Password', '');
                setValue('ConfirmPassword', '');
                delayedQueryForUsername(res.data.userName);
            }
        } else {
            resetConfigureUser(getValues())
            setValue("Email", "");
            setValue("UserName", "");
            setValue("Password", "");
            setValue("ConfirmPassword", "");
        }
        setShowPortal((prevState) => !prevState);
    };

    const handlePolicyModal = (e: any) => {
        e.preventDefault();
        setShowPassPolicy((prev) => !prev);
    };

    useEffect(() => {
        getManageColumns()
    }, [])

    useEffect(() => {
        if (showPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showPortal]);

    const [showSecondPortal, setShowSecondPortal] = useState(false);

    const handleSecondModal = async (event: any, service?: ServicesInterface) => {
        event.preventDefault();

        if (service) {
            setSelectedService(service);
            if (service.logopath) {
                setLogo('logo', service.logopath);
            } else {
                setLogo('logo', '');
            }
        } else {
            setLogo('logo', '');
        }

        setShowSecondPortal((prevState) => !prevState);
    };

    useEffect(() => {
        if (showSecondPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showSecondPortal]);

    // const carouselRef = useRef<OwlCarousel>(null);

    const [loading, setLoading] = useState(true);

    // const fetchOrganizationServiceList = async (payload: OrganizationServiceListPayload, reset = false) => {
    //     setLoading(true);      
    //     const res: ApiResponse<OrganizationServiceListResponse> = await GetOrganizationServiceList(payload);

    //     if (res.status) {
    //         setOrgServiceArr((prev) => {
    //             if (payload.searchValue && payload.page === 1) {
    //                 return res.data.services; 
    //             }
    //             if (payload.searchValue && payload.page > 1) {
    //                 return [...prev, ...res.data.services]; 
    //             }
    //             if (!payload.searchValue && payload.page > 1) {
    //                 return reset ? [...res.data.services] : [...prev, ...res.data.services];
    //             }
    //             if (payload.searchValue && payload.page === 1) {
    //                 return reset ? [...res.data.services] : [...prev, ...res.data.services];
    //             }
    //             return [...res.data.services]; 
    //         });
    //         setMatchedOrg(res.data.matchingOrganizations); 
    //         const totalLoaded = ((payload.page - 1) * orgServicePayload.pageSize) + res.data.services.length; 
    //         const remainingItems = res.totalCount - totalLoaded; 
    //         setHasMore(remainingItems > 0);
    //     }
    //     setLoading(false); 
    // };
    const fetchOrganizationServiceList = async (payload: OrganizationServiceListPayload, reset = false) => {
        setLoading(true);
        const res: ApiResponse<OrganizationServiceListResponse> = await GetOrganizationServiceList(payload);

        if (res.status) {
            setOrgServiceArr([...res.data.services]);
            setMatchedOrg(res.data.matchingOrganizations);
            setTotalCount(res.totalCount)
            // const totalLoaded = ((payload.page - 1) * orgServicePayload.pageSize) + res.data.services.length; 
            // const remainingItems = res.totalCount - totalLoaded; 
            // setHasMore(remainingItems > 0);
        }
        setLoading(false);
    };

    const handleScroll = () => {
        const container = scrollContainerRef.current;
        if (!container || !hasMore || loading) return;

        const { scrollTop, scrollHeight, clientHeight } = container;
        if ((scrollTop + clientHeight >= scrollHeight - 20) && hasMore) {
            fetchOrganizationServiceList({ ...orgServicePayload, page: orgServicePayload.page + 1 });
            setOrgServicePayload((prev) => ({ ...prev, page: prev.page + 1 }));
        }
    };

    // useEffect(() => {
    //     const payload: OrganizationServiceListPayload = {
    //         ...orgServicePayload,
    //         orgId: String(selectedTabPanel),
    //     };

    //     setOrgServicePayload(payload);
    //     fetchOrganizationServiceList(payload);
    // }, []);

    const handleTabClick = useCallback(
        async (org: OrganizationListResponse) => {
            setSelectedTabPanel(org.organizationId);
            setSelectedOrgName(org?.organizationName)

            const payload: OrganizationServiceListPayload = {
                ...orgServicePayload,
                orgId: String(org.organizationId),
                page: currentPage.find((item) => (item.orgId === org.organizationId))?.page!,
                pageSize: PageSize
            };

            setOrgServiceArr([]);
            setOrgServicePayload(payload);
            fetchOrganizationServiceList(payload);
        },
        [orgServicePayload, currentPage, PageSize]
    );

    const delayedQuery = useCallback(debounce((payload: OrganizationServiceListPayload) => {
        fetchOrganizationServiceList(payload)
    }, 500), []);


    const handleServiceSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value;
        const payload: OrganizationServiceListPayload = {
            ...orgServicePayload,
            searchValue,
            page: 1,
        };

        setOrgServicePayload(payload);

        if (searchValue.length === 0) {
            setOrgServiceArr([]);
            fetchOrganizationServiceList({ ...orgServicePayload, searchValue: "", page: 1 }, true);
            return;
        }

        if (searchValue?.length < 3) return;
        delayedQuery(payload);
    };

    // Function to convert base64 string to File object
    const base64ToFile = (base64String: string, filename: string) => {
        // Split the base64 string into data type and actual base64 data
        const [header, data] = base64String.split(',');
        const mimeType = header?.match(/:(.*?);/)?.[1]; // Extract MIME type from the header

        // Decode base64 data to binary
        const binaryString = atob(data);
        const len = binaryString?.length;
        const bytes = new Uint8Array(len);

        // Convert binary string to array of bytes
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a Blob from the bytes
        const blob = new Blob([bytes], { type: mimeType });

        // Convert Blob to File
        return new File([blob], filename, { type: mimeType });
    };

    const handleConfigureSubmit = useCallback(
        async (data: FEConfigureInterface) => {
            const payload: BEConfigureInterface = {
                Email: data.Email,
                Password: data.Password,
                UserName: data.UserName,
                OrgId: Number(orgServicePayload.orgId),
                ServiceId: selectedService ? selectedService?.serviceId : -1
            };
            const res: ApiResponse<null> = await NewUserAssigningService(payload);
            if (res.status) {
                swal(res.message, {
                    icon: 'success'
                }).then(async (confirm: any) => {
                    if (confirm) {
                        setShowPortal(false);
                        fetchOrganizationServiceList(orgServicePayload);
                    }
                });
            } else {
                swal(res.message, {
                    icon: 'error'
                });
            }
        },
        [selectedService, orgServicePayload]
    );

    const getImageUrl = useCallback(
        (img: string | File | FileList): string => {
            if (typeof img === 'string') {
                return img;
            } else if (img instanceof File) {
                return URL.createObjectURL(img);
            } else if (img instanceof FileList && img?.length > 0) {
                return URL.createObjectURL(img[0]);
            }
            return '';
        },
        [values.logo]
    );

    const getImageFiletype = (img: string | File | FileList) => {
        if (typeof img === 'string') {
            return true;
        } else {
            return false;
        }
    };

    const handleServiceLogoSubmit = async () => {
        const formData = new FormData();
        const file = base64ToFile(croppedImg.current as string, 'logo');

        const options = {
            maxSizeMB: 5,
            useWebWorker: true
        };
        const compressedBlob = await imageCompression(file, options);
        const compressedFile = new File([compressedBlob], file.name, { type: file.type });

        formData.append('serviceLogo', compressedFile);
        formData.append('serviceId', String(selectedService?.serviceId));

        const res: ApiResponse<null> = await UploadServiceLogo(formData);
        if (res.status) {
            swal(res.message, {
                icon: 'success'
            }).then(async (confirm: any) => {
                if (confirm) {
                    setShowSecondPortal((prevState) => !prevState);
                    fetchOrganizationServiceList(orgServicePayload);
                }
            });
        } else {
            swal(res.message, {
                icon: 'error'
            });
        }
    };

    const handleImageCropChange = (cropper: CropperRef) => {
        const lCroppedImg = cropper?.getCanvas()?.toDataURL();
        if (lCroppedImg) {
            croppedImg.current = lCroppedImg;
        }
    };

    const { DataLogin } = useSelector((state: RootState) => state.login);

    const name = useMemo(() => {
        // const [firstName] = DataLogin?.userModel?.userName?.split(' ').slice(0, 2).join(' ');
        // console.log(DataLogin?.userModel?.userName?.split(' ').slice(0, 2).join(' '));
        return DataLogin?.userModel?.userName?.split(' ').slice(0, 2).join(' ');
    }, []);

    const onSetupClick = (service: ServicesInterface) => {
        const { serviceId, serviceName, stepId, siteUserName, completion, isPublished, isWardFacility, slugName } = service;
        dispatch(setOnboardingService({ serviceId, serviceName, userName: siteUserName ?? name, slugName }));
        dispatch(setOnboardingWardFacility(isWardFacility));
        const currentStep = onboardingStages.find((value: any) => value.stageNumber === stepId);
        dispatch(setOnboardingIsPublished(false));
        if (completion === 100) {
            if (isPublished === 1) {
                navigate(ROUTES_DATA.ONBOARDINGPREVIEW);
                dispatch(setOnboardingIsPublished(true));
                return;
            }

            navigate(ROUTES_DATA.ONBOARDINGPREVIEW);
            return;
        }
        if (currentStep) {
            dispatch(setOnboardingIsPublished(false));
            if (stepId === 1 && completion === 0) {
                dispatch(setOnboardingProgress({ stageDescription: undefined, stageHeading: undefined, stageNumber: undefined }));
                dispatch(setOnboardingService({ serviceId, serviceName, userName: siteUserName ?? name, slugName }));
                navigate(ROUTES_DATA.ONBOARDING);
                return;
            }
            dispatch(setOnboardingProgress(currentStep));
            dispatch(setOnboardingService({ ...onboardingServices, serviceId, serviceName, userName: siteUserName ?? name, slugName }));
            navigate(ROUTES_DATA.ONBOARDING);
        }
    };

    const [siteUsernameList, setSiteUserNameList] = useState<ConfigureUserNameInterface[]>([]);
    const { ref } = useComponentVisible(true);

    const apiCallForUsernameSearch = async (username: string) => {
        setIsConfigureUserLoading(true);
        const res: ApiResponse<ConfigureUserNameInterface[]> = await getSiteUsername(username);
        if (res.data?.length > 0) {
            setSiteUserNameList(res.data);
            setIsConfigureUserLoading(false);
        } else {
            setIsConfigureUserLoading(false);
        }
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];

        if (file) {
            setLogo('logo', file, { shouldDirty: true });

            const reader = new FileReader();
            reader.onloadend = function () {
                const base64String = reader?.result;
                croppedImg.current = base64String as string;
            };
            reader.readAsDataURL(file);
        }

        setIsDragging(false);
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
    };

    const delayedQueryForUsername = useCallback(
        debounce((val: string) => apiCallForUsernameSearch(val), 500),
        []
    );

    const handleUploadClick = () => {
        if (logoInputRef.current) {
            logoInputRef.current.click();
        }
    };

    useEffect(() => {
        if (organizationList && organizationList.length > 0) {
            setSelectedTabPanel(organizationList[0]?.organizationId)
            const payload: OrganizationServiceListPayload = {
                ...orgServicePayload,
                orgId: String(organizationList[0]?.organizationId),
            };

            setOrgServicePayload(payload);
            setSelectedOrgName(organizationList[0]?.organizationName);
            fetchOrganizationServiceList(payload);
            setCurrentPage(organizationList.map((item) => {
                return { orgId: item.organizationId, page: 1 }
            }))
        }
        generateDynamicStyles()

        return () => {
            const styleElement = document.getElementById('dynamic-tab-styles');
            if (styleElement) {
                styleElement.remove();
            }
        };
    }, [organizationList])

    const generateDynamicStyles = () => {

        const style = document.createElement('style');
        style.id = 'dynamic-tab-styles';
        let dynamicStyles = '';

        const activeTabIndex = organizationList?.findIndex(tab => tab.organizationId === selectedTabPanel);
        const initialTranslateValue = activeTabIndex !== -1 ? activeTabIndex * 241 : 0;

        organizationList?.forEach((_, index) => {
            const translateValue = index * 241;
            dynamicStyles += `
                .group-tabs.glinderActive${index + 1} .slick-track:before {
                    transform: translateX(${translateValue}px);
                    transition: all ease 0.7s;
                }
            `;
        });

        dynamicStyles += `
            .group-tabs .slick-track:before {
                transform: translateX(${initialTranslateValue}px);
                transition: all ease 0.7s;
            }
        `;

        style.innerHTML = dynamicStyles;
        document.head.appendChild(style);
    };

    const handleTabSliding = (tabId: number) => {
        const tabIndex = organizationList?.findIndex((item) => (item.organizationId === tabId));
        const glider = document.querySelector('.group-tabs');

        if (tabIndex !== -1) {
            glider?.classList.remove(...Array.from({ length: organizationList?.length }, (_, i) => `glinderActive${i + 1}`));
            glider?.classList.add(`glinderActive${tabIndex + 1}`);
        }
    }

    useEffect(() => {
        if (organizationList) {
            handleTabSliding(selectedTabPanel)
        }
    }, [selectedTabPanel])

    return (
        <div className="getlocated-body onboarding-bg-gray service-detail-padding pb-115">
            <div className="container-fluid p-0">
                <div className="getlocated-section">
                    <div className="section-title-parent">
                        <h5 className="section-title">
                            <span className="gradient-text text-capitalize">Welcome {name}!</span>
                        </h5>
                    </div>

                    <div className="steps-headline">
                        <div className="steps-description text-left">
                            <ol>
                                <li>This dashboard provides an overview of your care group and individual care home sites.</li>
                                <li>Here, you can easily manage and configure each site's profile, track their completion status, and assign site users to ensure everything runs smoothly.</li>
                                <li>Let’s take a closer look at your sites and start setting up or edit their profiles to highlight the excellent care services you offer.</li>
                            </ol>
                        </div>
                    </div>

                    <div className='row mb-4'>
                        <div className='col-md-8'>
                            <div className="smartSearch ">
                                <input
                                    type="text"
                                    value={orgServicePayload?.searchValue}
                                    onChange={handleServiceSearch}
                                    className="form-control smartSearchField"
                                    placeholder="Search for the service"
                                    autoFocus
                                />
                                <i className="fa fa-magnifying-glass"></i>
                            </div>
                        </div>
                        <div className='col-md-4 d-flex justify-content-end align-items-end'>
                            <div className='welcome-action-column d-flex'>
                                <ManageColumnContainer managedColumnsList={managedColumnsList} onChangeManageColumnHandler={onChangeManageColumnHandler} />
                                <DownloadServicesContainer onClickHandlerDownloadServices={onClickHandlerDownloadServices} />
                            </div>
                        </div>
                    </div>

                    <div className="welcome-container gallery-tab mt-4">
                        <ul
                            className="nav-pills group-tabs welcome-slider mb-2"
                            id="group-pills-tab"
                            role="tablist"
                        >
                            {/* <OwlCarousel
                                ref={carouselRef}
                                className="owl-theme position-relative all-owl owl-carousel"
                                dots={false}
                                // margin={10}
                                key={organizationList?.length + Date.now()}
                                mouseDrag={false}
                                touchDrag={false}
                                nav={true}
                                smartSpeed={800}
                                items={3}
                            >
                                {organizationList?.length > 0 ? organizationList?.map((org, i) => (
                                    <li className="nav-item" key={org.organizationId} role="presentation">
                                        <button
                                            className={`nav-link ${org.organizationId === selectedTabPanel ? "active" : ""}`}
                                            id={`pills-group-${org.organizationId}`}
                                            data-bs-toggle="pill"
                                            data-bs-target={`#pills-group-${org.organizationId}-nav`}
                                            type="button"
                                            role="tab"
                                            aria-controls={`#pills-group-${org.organizationId}-nav`}
                                            aria-selected={org.organizationId === selectedTabPanel ? "true" : "false"}
                                            onClick={() => handleTabClick(org)}
                                            style={matchedOrg?.includes(org.organizationId) ? { color: "red", fontWeight: "bold" } : {}}
                                        >
                                            <span>{org.organizationName}</span>
                                        </button>
                                    </li>
                                )) : null}
                            </OwlCarousel> */}
                            <Slider {...settings}>
                                {organizationList?.length > 0 ? organizationList?.map((org, i) => (
                                    <div className="nav-item" key={org.organizationId} role="presentation">
                                        <button
                                            className={`nav-link ${org.organizationId === selectedTabPanel ? "active" : ""}`}
                                            id={`pills-group-${org.organizationId}`}
                                            data-bs-toggle="pill"
                                            data-bs-target={`#pills-group-${org.organizationId}-nav`}
                                            type="button"
                                            role="tab"
                                            aria-controls={`#pills-group-${org.organizationId}-nav`}
                                            aria-selected={org.organizationId === selectedTabPanel ? "true" : "false"}
                                            onClick={() => handleTabClick(org)}
                                            style={matchedOrg?.includes(org.organizationId) ? { color: "red", fontWeight: "bold" } : {}}
                                        >
                                            {org.organizationName.length > 30 ? <span data-tooltip-id="organizationName" data-tooltip-content={org.organizationName}>{org.organizationName.length < 30 ? org.organizationName : org.organizationName.slice(0, 27) + "..."}</span> :
                                                <span>{org.organizationName}</span>
                                            }
                                        </button>
                                    </div>
                                )) : null}
                            </Slider>
                            {/* <span className="glider"></span> */}
                            <Tooltip id="organizationName" className="my-tooltip" />
                        </ul>
                        <div className="tab-content bordered-tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id={`#pills-group-${selectedTabPanel}-nav serviceList`}
                                role="tabpanel"
                                aria-labelledby={`pills-group-${selectedTabPanel}`}
                                tabIndex={0}
                                ref={scrollContainerRef}
                                style={{ minHeight: "105px" }}
                            // onScroll={handleScroll}
                            >
                                <div className="tab-table welcome-tab-table table-responsive">
                                    <table className="table ">
                                        <thead className="thead-light welcome-table-header-fixed">
                                            <tr>
                                                <th className='bigCell'>Service Provider Name</th>
                                                <th className='bigCell'>CQC Location ID</th>
                                                {columnVisibility.CompanyName === true && <th className='bigCell'>Company Name</th>}
                                                {columnVisibility.CQCProviderId === true && <th className='bigCell'>CQC Provider ID</th>}
                                                <th>Verification</th>
                                                <th>Profile Status</th>
                                                <th>Completion</th>
                                                <th>Site User</th>
                                                <th>Logo</th>
                                                <th>Set Up</th>
                                                <th className="no-border">Documents</th>                                                
                                            </tr>
                                        </thead>
                                        <tbody className='tbodynodata'>
                                            {orgServiceArr && orgServiceArr?.length > 0 ? orgServiceArr?.map((service, index) => (
                                                <React.Fragment key={service.serviceId}>
                                                    <tr key={index}>
                                                        <td className='align-middle'>
                                                            <div className='d-flex align-items-center'>
                                                                <span className="table-row-number">{index + 1}</span>
                                                                <span className="ms-2 font-bold table-row-title"><a href="#">{service.serviceName}</a></span>
                                                            </div>
                                                        </td>
                                                        <td>{service.cqcLocationId ? service.cqcLocationId : "-"}</td>
                                                        {columnVisibility.CompanyName === true && (
                                                            <td>
                                                                {service?.companyName ? service?.companyName : "-"}
                                                            </td>
                                                        )}
                                                        {columnVisibility.CQCProviderId === true && (
                                                            <td>
                                                                {service?.cqcProviderId ? service?.cqcProviderId : "-"}
                                                            </td>
                                                        )}
                                                        <td>
                                                            <span className={service.verificationStatus === VerificationStatus.COMPLETED ? 'text-success' : service.verificationStatus === VerificationStatus.IN_REVIEW ? 'text-primary' : 'text-warning'}>
                                                                {service.verificationStatus}
                                                            </span>
                                                        </td>
                                                        <td>{service.profileStatus}</td>
                                                        <td>{service.completion ? service.completion + "%" : "-"}</td>
                                                        <td className="text-underline">
                                                            <a
                                                                href="#"
                                                                className={!service.isConfigured ? 'light-blue-text' : ''}
                                                                onClick={(e) => handleModal(e, service)}
                                                                style={service.verificationStatus !== VerificationStatus.COMPLETED ? { pointerEvents: "none" } : {}}
                                                            >
                                                                {!service.isConfigured ? "Configure" : service.siteUserName}
                                                            </a>
                                                        </td>
                                                        <td className="text-center">
                                                            {service.logopath ? (
                                                                <div className="position-relative upload-file-parent">
                                                                    <img src={service.logopath} alt="Logo" width="50" className='img-fluid upload-icon' style={service.verificationStatus !== VerificationStatus.COMPLETED ? { pointerEvents: "none" } : {}} onClick={(e) => handleSecondModal(e, service)} />
                                                                    {/* <input type="file" className="upload-field" /> */}
                                                                </div>
                                                            ) : (
                                                                <div style={service.verificationStatus !== VerificationStatus.COMPLETED ? { pointerEvents: "none" } : {}} onClick={(e) => service.verificationStatus === VerificationStatus.COMPLETED && handleSecondModal(e, service)} className='cursor-pointer'>
                                                                    <i className="fa-solid fa-cloud-arrow-up fa-xl"></i>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className={'btn btn-primary'}
                                                                onClick={() => onSetupClick(service)}
                                                                disabled={service.verificationStatus !== VerificationStatus.COMPLETED ? true : false}
                                                            >
                                                                {service.completion === 100 ? t('Service_Completion_BtnText.View_Edit') : t('Service_Completion_BtnText.Setup')}
                                                            </button>
                                                        </td>
                                                        <td>
                                                            {service.verificationDocs?.length === 0 || service.verificationDocs?.length <= 2 ? (
                                                                <div className="document-list-icons">
                                                                    <ul className="document-list-table d-flex">
                                                                        {/* Upload button next to document icons */}
                                                                        {service.verificationStatus === "Hold" && (
                                                                            <li>
                                                                                <label
                                                                                    className="status-icon me-2 align-items-center justify-content-center"
                                                                                    data-tooltip-id="my-tooltip"
                                                                                    data-tooltip-content="Upload Document"
                                                                                    htmlFor={`upload-${service.serviceId}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                >
                                                                                    <input
                                                                                        type="file"
                                                                                        onChange={(event) => handleFileInputChange(event, service.serviceId, service.verificationDocs?.length)}
                                                                                        className='hidden-file-input'
                                                                                        id={`upload-${service.serviceId}`}
                                                                                        title=""
                                                                                    />
                                                                                    <div>
                                                                                        <i className="fa-solid fa-upload"></i>
                                                                                    </div>
                                                                                </label>
                                                                                {/* <button onClick={() => handleDocumentUpload(service.serviceId)} className="btn btn-primary btn-sm">
                                                                                            Upload
                                                                                        </button> */}
                                                                            </li>
                                                                        )}
                                                                        {service.verificationDocs?.map((doc: any, index) => (
                                                                            <li key={index}>
                                                                                <a
                                                                                    href={doc.filePath}
                                                                                    className="status-icon me-2 align-items-center blue-btn justify-content-center"
                                                                                    target="_blank"
                                                                                    data-tooltip-id="my-tooltip"
                                                                                    data-tooltip-content={doc.fileName} rel="noreferrer"
                                                                                >
                                                                                    <i className="fa-solid fa-file-lines"></i>
                                                                                    {/* <img src={docIcon} className="doc-icon" alt="document icon" width={28} /> */}
                                                                                </a>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            ) : (
                                                                <div className="document-list-text">
                                                                    <p className="cursor-pointer" onClick={() => handleViewDocClick(service.serviceId)}>
                                                                        View Doc
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </td>
                                                        
                                                    </tr>
                                                    {expandedServiceId === service.serviceId && (
                                                        <tr className="docum-row">
                                                            <td colSpan={9}>
                                                                <div className="document-box">
                                                                    <ul className="document-list-table">
                                                                        {(service.verificationStatus === "Hold" && service.verificationDocs?.length < 11) && (
                                                                            <li>
                                                                                <label
                                                                                    className="status-icon me-2 align-items-center justify-content-center"
                                                                                    data-tooltip-id="my-tooltip"
                                                                                    data-tooltip-content="Upload Document"
                                                                                    htmlFor={`upload-${service.serviceId}`}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                >
                                                                                    <input
                                                                                        type="file"
                                                                                        onChange={(event) => handleFileInputChange(event, service.serviceId, service.verificationDocs?.length)}
                                                                                        className='hidden-file-input'
                                                                                        id={`upload-${service.serviceId}`}
                                                                                        title=""
                                                                                    />
                                                                                    <div>
                                                                                        <i className="fa-solid fa-upload"></i>
                                                                                    </div>
                                                                                </label>
                                                                            </li>
                                                                        )}
                                                                        {service.verificationDocs?.map((doc: any, index) => (
                                                                            <li key={index}>
                                                                                <a
                                                                                    href={doc.filePath}
                                                                                    className="me-2 align-items-center justify-content-center"
                                                                                    target="_blank"
                                                                                    data-tooltip-id="my-tooltip"
                                                                                    data-tooltip-content={doc.fileName} rel="noreferrer"
                                                                                >
                                                                                    <img src={docIcon} className="doc-icon" alt="document icon" width={28} />
                                                                                    {/* <i className="fa-solid fa-file-lines"></i> */}
                                                                                </a>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            )
                                            ) : (
                                                <div className='nodataDiv'>
                                                    <p className='nodatep'>No data found.</p>
                                                </div>
                                            )}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                            <div className="d-flex invoice-table-pagination p-3">
                                <div className='col-lg-6'>
                                    <div className='d-flex align-items-center'>
                                        <p className='purple-text bold font13'>{t('Page_Select_dropdown.Displaying')}  {showFrom}-{showTo} of {totalCount}</p>
                                        <div className='d-flex align-items-center ms-3'>
                                            <label className="mb-0 bold nowrap me-2">{t('Page_Select_dropdown.Page_Size')}:</label>
                                            <select value={PageSize} onChange={(e: any) => handleSizeChange(e)}
                                                className={`form-control white page-select`}
                                                id="supportedPersonGender"
                                            >
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 ignore-nhs-body">
                                    <PaginationContainer currentPage={currentPage.find((item) => (item.orgId === selectedTabPanel))?.page!} totalCount={totalCount} pageSize={PageSize} onPageChange={(page: any) => getCurrentPage(page)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ProgressFooterView /> */}

            <ClientPortal selector="myportal" show={showPortal}>
                <div className="overlay" onClick={handleModal}></div>
                <div className="modal userInfoModal">
                    <div className="header mb-4">
                        <h4 className="mb-0"></h4>

                        <a onClick={handleModal} className="removeButton">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body ">
                        <p className="mb-3 text-center hanzipen-font-bold">Please fill in the below details to configure your site user.</p>
                        <form className="popup-box" onSubmit={handleSubmit(handleConfigureSubmit)}>
                            <div className="row">
                                <div className="mb-3" ref={ref}>
                                    <Controller
                                        name="UserName"
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Autocomplete
                                                id="Username"
                                                loading={isConfigureUserLoading}
                                                options={siteUsernameList}
                                                size='small'
                                                value={siteUsernameList?.find(option => option.userName === value) ? siteUsernameList?.find(option => option.userName === value) : { email: getValues("Email"), userName: getValues("UserName") }}
                                                onChange={(_event, newValue) => {
                                                    if (newValue) {
                                                        setValue("UserName", newValue?.userName);
                                                        setValue("Email", newValue?.email, { shouldValidate: true });
                                                        delayedQueryForUsername(newValue?.userName);
                                                    } else {
                                                        setValue("UserName", "");
                                                        setValue("Email", "");
                                                    }
                                                    setValue("Password", "");
                                                    setValue("ConfirmPassword", "");
                                                }}
                                                getOptionLabel={(option) => option.userName || ''}
                                                renderOption={(props, option) => (
                                                    <li {...props}>
                                                        {`${option.userName}`}<br />{`${option.email.length <= 40 ? option.email : option.email.slice(0, 40) + "..."}`}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="User name"
                                                        value={configureWatch('UserName')}
                                                        onChange={(e) => {
                                                            const searchValue = e.target.value;
                                                            setValue('UserName', searchValue);
                                                            delayedQueryForUsername(searchValue);
                                                        }}
                                                        sx={{
                                                            background: '#ffffff',
                                                            paddingTop: '0',
                                                            height: '38px',
                                                            '& .MuiInputBase-root': {
                                                                height: '100%'
                                                            }
                                                        }}
                                                        InputLabelProps={{ shrink: false }}
                                                        inputRef={ref}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    {errors.UserName ? <p style={{ fontSize: '12px', color: 'red', marginTop: '3px', marginLeft: '5px' }}>{errors.UserName.message}</p> : null}
                                </div>
                                <div className="mb-3">
                                    <input {...register("Email")} className="form-control" placeholder="Email Address*" type="text" maxLength={50} />
                                    {errors.Email ? (
                                        <p style={{ fontSize: "12px", color: "red", marginTop: "3px", marginLeft: "5px" }}>{errors.Email.message}</p>
                                    ) : null}
                                    {
                                        (siteUsernameList?.find(option => option.email === getValues("Email")) ? true : false) && <p style={{ fontSize: "12px", marginTop: "5px", marginLeft: "5px", fontWeight: 500 }}><i className="fa-solid fa-circle-info"></i>&nbsp;Password fields disabled as an existing user is selected.</p>
                                    }
                                </div>
                                <div className="mb-3">
                                    <div style={{ display: "flex", alignItems: "center", justifyItems: "center" }}>
                                        <input {...register("Password")}
                                            className="form-control"
                                            placeholder={(siteUsernameList?.find(option => option.email === getValues("Email")) ? true : false) ? "Password" : "Password*"}
                                            type="password"
                                            disabled={(siteUsernameList?.find(option => option.email === getValues("Email")) ? true : false)} maxLength={50} />
                                        <span className="ms-2 cursor-pointer" onClick={handlePolicyModal}>
                                            <i className="fa-solid fa-circle-info"></i>
                                        </span>
                                    </div>
                                    {!(siteUsernameList?.find(option => option.email === getValues("Email")) ? true : false) && errors.Password ? (
                                        <p style={{ fontSize: "12px", color: "red", marginTop: "3px", marginLeft: "5px" }}>{errors.Password.message}</p>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <div style={{ display: "flex", alignItems: "center", justifyItems: "center" }}>
                                        <input {...register("ConfirmPassword")}
                                            className="form-control"
                                            placeholder={(siteUsernameList?.find(option => option.email === getValues("Email")) ? true : false) ? "Confirm Password" : "Confirm Password*"}
                                            type="password"
                                            disabled={(siteUsernameList?.find(option => option.email === getValues("Email")) ? true : false)} maxLength={50} />
                                    </div>
                                    {!(siteUsernameList?.find(option => option.email === getValues("Email")) ? true : false) && errors.ConfirmPassword ? (
                                        <p style={{ fontSize: "12px", color: "red", marginTop: "3px", marginLeft: "5px" }}>{errors.ConfirmPassword.message}</p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="d-flex justify-content-center">
                                    <button type="submit" disabled={!isValid} className="btn primary-btn classic-btn">Configure</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </ClientPortal>

            <ClientPortal selector="myportal" show={showPassPolicy}>
                <div className="overlay" onClick={handlePolicyModal}></div>
                <div className="modal userInfoModal" style={{ width: '300px' }}>
                    <div className="header mb-4">
                        <h4 className="mb-0"></h4>
                        <a onClick={handlePolicyModal} className="removeButton">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body">
                        <p className="bold mb-2"> {t('Change_Password.Password_contain')}</p>
                        <ul className="ps-3">
                            <li>{t('Change_Password.capital')}</li>
                            <li>{t('Change_Password.small_letter')}</li>
                            <li>{t('Change_Password.special_character')}</li>
                            <li>{t('Change_Password.number')}</li>
                            <li>{t('Change_Password.characters')}</li>
                        </ul>
                    </div>
                </div>
            </ClientPortal>

            <ClientPortal selector="myportal" show={showSecondPortal}>
                <div className="overlay" onClick={handleSecondModal}></div>
                <div className="modal userInfoModal">
                    <div className="header">
                        <h4 className="mb-0"></h4>
                        <a onClick={handleSecondModal} className="removeButton">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body">
                        <p className="mb-3 text-center hanzipen-font-bold">Please upload your logo image here</p>
                        <form onSubmit={logoSubmit(handleServiceLogoSubmit)} className="popup-box">
                            <div className="row">
                                <div className="col-md-12 ">
                                    <div
                                        className="img-upload-box mb-4 d-flex align-items-center justify-content-center"
                                        style={{
                                            border: '2px solid #ced4da'
                                        }}
                                        onDrop={handleDrop}
                                        onDragStart={() => setIsDragging(true)}
                                        onDragOver={handleDragOver}
                                    >
                                        <div className="text-center upload-logo-here">
                                            {values.logo && showSecondPortal ? (
                                                <div style={{ height: '200px', width: '300px', marginBottom: '20px' }}>
                                                    {!getImageFiletype(values.logo) ? (
                                                        <FixedCropper
                                                            src={getImageUrl(values.logo)}
                                                            stencilSize={{ width: 300, height: 300 }}
                                                            stencilProps={{
                                                                handlers: false,
                                                                lines: false,
                                                                movable: false,
                                                                resizable: false
                                                            }}
                                                            onChange={handleImageCropChange}
                                                            imageRestriction={ImageRestriction.stencil}
                                                            className={'cropper'}
                                                        />
                                                    ) : (
                                                        <img style={{ height: '200px', width: '300px', marginBottom: '20px' }} src={values.logo.toString()}></img>
                                                    )}
                                                </div>
                                            ) : (
                                                <>
                                                    <i className="fa-regular fa-image mb-3" onClick={handleUploadClick}></i>
                                                    <p className="mb-4">You can upload or drag & drop an image that is 300x300 pixels</p>
                                                </>
                                            )}
                                            <div className="position-relative upload-btn-field">
                                                <button className="btn classic-btn small-btn upload-btn">Upload from your computer</button>
                                                <input
                                                    type="file"
                                                    // {...registerLogo("logo")}
                                                    ref={logoInputRef}
                                                    className="upload-file-input"
                                                    onChange={(e) => {
                                                        if (e.target.files && e.target.files?.length > 0) {
                                                            // setImgModalVal(e.target.files?.[0]);
                                                            setLogo('logo', e.target.files?.[0], { shouldDirty: true });

                                                            const reader = new FileReader();
                                                            reader.onloadend = function () {
                                                                const base64String = reader?.result;
                                                                croppedImg.current = base64String as string;
                                                            };
                                                            reader.readAsDataURL(e.target.files?.[0]);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex justify-content-center">
                                    <button className="btn primary-btn classic-btn" disabled={!isImageDirty}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </ClientPortal>

            <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip" />
        </div>
    );
};

export default WelcomeView;
