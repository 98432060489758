import { Tooltip } from 'react-tooltip';
import { components } from 'react-select';

export const CustomClearIndicator = (props: any) => {
    return (
        <>
            <Tooltip id="clear-icon" className="my-tooltip" />
            <components.ClearIndicator className="react-select-custom-cross-indicator cursor-pointer" {...props}>
                <i className="fa-solid fa-xmark" data-tooltip-id="clear-icon" data-tooltip-content="Clear" data-tooltip-place="top"></i>
            </components.ClearIndicator>
        </>
    );
};
