import React from 'react';
import UserManagementView from './UserManagementView';

const UserManagementContainer = () => {
    return (
        <div>
            <UserManagementView/>
        </div>
    );
};

export default UserManagementContainer;