import React, { useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import {
    MaterialReactTable,
    MRT_TableInstance,
    MRT_FullScreenToggleButton,
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_TablePagination,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToolbarAlertBanner,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_VisibilityState,
    type MRT_RowSelectionState,
    type MRT_GroupingState,
    MRT_Localization,
    MRT_ExpandedState
} from 'material-react-table';

import { Box } from '@mui/material';
import PaginationContainer from '../../components/PaginationComponent/indexContainer';
import { Tooltip } from 'react-tooltip';
import ClientPortal from "../../components/ClientPortal";
import docIcon from '../../assets/images/document-ic.svg';
import LocateAdminTableManageColumnsContainer from "./TableManageColumns/LocateAdminTableManageColumnsContainer";
import { getAdminManagedColumns, getAdminServiceClaimListApi, getAdminVerificationOverviewList, SaveServiceVerificationInReview, UpdateAdminManageColumnsService } from "../../service/Admin-service";
import LoaderContainer from "../../components/Loader/loaderContainer";
import LocateStatusOverViewContainer from "./LocateStatusOverview/LocateStatusOverViewContainer";
import TimeDifferenceContainer from "../../components/TimeDifference/TimeDifferenceContainer";
import SearchServiceClaimContainer from "./SearchServiceClaim/SearchServiceClaimContainer";
import infoIcon from '../../assets/images/info-icon.svg';
import RenderModalContainer from "../../components/RenderModal/indexContainer";
import SmartSearchDropdownContainer from "../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer";
import ReasonModalContainer from "./ReasonModal/ReasonModalContainer";
import { Statusenums } from "../../utils/constant";
import swal from 'sweetalert';
import warning from "../../assets/images/warning.png"

const LocateAdminView = () => {
    const { t } = useTranslation();
    const [pageSize, setPageSize] = useState(10)
    const [data, setData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalCount, setTotalCount] = useState<any>()
    const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>({});
    const [managedColumnsList, SetManagedColumnsList] = useState<any>([]);
    const [statusOverview, setStatusOverview] = useState<any>({})
    const [showFrom, setShowFrom] = useState<any>();
    const [showTo, setShowTo] = useState<any>();
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [selectedStatus, setSelectedStatus] = useState("")
    const [dataserviceId, setDataServiceId] = useState<any>()
    const [serviceName, setServiceName] = useState<string>()
    const [expanded, setExpanded] = useState<MRT_ExpandedState>({});
    const [localTimeZone, setLocalTimeZone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);    // Keep track of the current expanded row (for specific logic, if needed)    
    const [claimListPayload, setClaimListPayload] = useState({
        searchValue: '',
        sortColumnName: '',
        sortOrder: '',
        page: 1,
        pageSize: pageSize,
        filter: {
            VerificationStatus: ""
        }
    })
    const [isLoading, setIsLoading] = useState(false)

    const expandButtonRefs = useRef<{ [key: number]: HTMLButtonElement | null }>({});


    const handleRowClick = (row: any) => {
        const { documents } = row.original;
        if (documents?.length > 2) {
            setExpanded((prevExpanded: any) => ({
                ...prevExpanded,
                [row.id]: !prevExpanded[row.id], // Toggle expansion state for the clicked row
            }));
        }
    };

    const [showPortal, setShowPortal] = useState(false);
    const [showInfoPortal, setShowInfoPortal] = useState(false);
    const [verificationReason, setVerificationReason] = useState<string>("")
    const [verificationComment, setVerificationComment] = useState<string>("")
    const [verificationStatus, setVerificationStatus] = useState<string>("")
    const [lockModal, setLockModal] = useState(false)
    const [unlockModal, setUnlockModal] = useState(false)

    const handleClickModal = () => {
        setShowPortal(true);
    };
    const handleCloseModal = () => {
        setShowPortal(false);
    };
    const handleClickInfoModal = () => {
        setShowInfoPortal(true);
    };
    const handleCloseInfoModal = () => {
        setShowInfoPortal(false);
        setVerificationReason("")
        setVerificationComment("")
    };

    // Handle the approve pop-up
    const [approveModal, setApproveModal] = useState(false);
    const handleClickApproveModal = () => {
        setApproveModal(true);
    }
    const handleCloseApproveModal = () => {
        setApproveModal(false);
    }

    // Handle the on hold pop-up
    const [onHoldModal, setOnHoldModal] = useState(false);
    const handleClickOnHoldModal = () => {
        setOnHoldModal(true);
    }
    const handleCloseOnHoldModal = () => {
        setOnHoldModal(false);
    }

    // Handle the rejected pop-up
    const [rejectModal, setRejectModal] = useState(false);
    const handleClickRejectModal = () => {
        setRejectModal(true);
    }

    const handleCloseRejectModal = () => {
        setRejectModal(false);
    }

    const handleClickLockModal = () => {
        setLockModal(true)
    }

    const handleCloseLockModal = () => {
        setLockModal(false)
    }

    const handleClickUnlockModal = () => {
        setUnlockModal(true)
    }

    const handleCloseUnlockModal = () => {
        setUnlockModal(false)
    }

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'service_Name', //access nested data with dot notation
                header: 'Service Name',
                size: 350,
            },
            {
                accessorKey: 'groupName',
                header: 'Group Name',
                size: 200,
            },
            {
                accessorKey: 'companyName',
                header: 'Company Name',
                size: 200,
            },
            {
                accessorKey: 'providerId',
                header: 'CQC Provider ID',
                size: 10,
            },
            {
                accessorKey: 'cqcLocationId',
                header: 'CQC Location ID',
                size: 10,
            },
            {
                accessorKey: 'profileStatus',
                header: 'Profile Status',
                size: 50,
            },
            {
                accessorKey: 'completion',
                header: '% Completion',
                size: 40,
            },
            {
                accessorKey:'verificationStatus',
                header: 'Verification',
                size: 50,
                Cell: ({ cell, row }) => {
                    const value = cell.getValue();
                    return (
                        
                        <div className="verification-cell">
                            {value !== "Pending" && value !== "In Review" ? (
                                <>
                                    <div className="d-flex">
                                        <span>{value === "OnHold" ? "On Hold" : value}</span>
                                        <div className="infoIcon ms-1 cursor-pointer" data-tooltip-id="my-tooltip" data-tooltip-content="Remarks" data-tooltip-place="top"
                                            onClick={() => {
                                                // set the reason and comment here
                                                setVerificationStatus(row.original.verificationStatus)
                                                setVerificationReason(row.original.reason)
                                                setVerificationComment(row.original.comment)
                                                handleClickInfoModal()
                                            }}>
                                            <i className="fa-solid fa-circle-info"></i>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <span>{value}</span>
                            )}
                        </div>
                    );
                }
            },
            {
                accessorKey: 'createdOn',
                header: 'TAT',
                size: 50,
                Cell: ({ cell, row }) => {
                    const { createdOn, verificationStatus, approvedOn, reopenedOn, rejectedOn, isReOpen } = row.original
                    return <TimeDifferenceContainer timestamp={createdOn} verificationStatus={verificationStatus} approvedOn={approvedOn} reopenedOn={reopenedOn} rejectedOn={rejectedOn} isReOpen={isReOpen} />
                }
            },
            {
                accessorKey: 'updatedBy',
                header: 'Updated By',
                size: 80,
            },
            {
                accessorKey: 'document', // normal accessorKey
                header: 'Documents',
                size: 50,
                enableSorting: false,
                Cell: ({ cell, row }: any) => {
                    const { documents } = row.original;
                    return (
                        <div className="d-flex align-items-center justify-content-start">
                            {documents?.length <= 2 ? (
                                documents.map((document: any, index: number) => (

                                    <a
                                        key={document?.documentId}
                                        data-tooltip-id="my-tooltip"
                                        className='status-icon me-2 align-items-center blue-btn justify-content-center'
                                        data-tooltip-content={document?.documentName}
                                        onClick={(e) => handleViewDocument(e, document?.documentPath)}
                                    >
                                        <i className="fa-solid fa-file-lines"></i>
                                    </a>

                                ))
                            ) : (
                                <>
                                    <a onClick={(e) => handleRowClick(row)} >
                                        <p className="cursor-pointer">View Doc</p>
                                    </a>
                                </>
                            )}
                        </div>
                    );
                },
            }
        ],
        [],
    );

    useEffect(() => {
        if (localTimeZone === "") {
            setLocalTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
        }

    }, [])

    useEffect(() => {
        let showFromTemp = (currentPage - 1) * pageSize + 1;
        let showToTemp = showFromTemp + pageSize;
        showToTemp = showToTemp - 1 > Number(totalCount) ? Number(totalCount) : showToTemp - 1;
        setShowFrom(showFromTemp);
        setShowTo(showToTemp);
    }, [currentPage, totalCount, pageSize])



    const getServiceClaimList = async () => {
        const payload = claimListPayload
        setIsLoading(true)
        const response = await getAdminServiceClaimListApi(payload)
        if (response.status === true && response?.data !== undefined) {
            setIsLoading(false)            
            setData([...response.data])
            setTotalCount(response.totalCount)

        } else {
            setIsLoading(false)
            setTotalCount(0)
            setData([])
        }
    }

    const getAdminManagedColumnsListData = async () => {
        const resultData = await getAdminManagedColumns();
        if (resultData.status === true && resultData.data !== undefined && resultData.data !== null && resultData.data.length > 0) {
            SetManagedColumnsList(resultData.data);
        } else {
            SetManagedColumnsList([]);
        }

    }

    const getAdminverificationList = async () => {
        const payload = claimListPayload
        const responce = await getAdminVerificationOverviewList(payload)
        if (responce?.status === true && responce?.data !== undefined && responce.data !== null) {
            setStatusOverview(responce.data)
        } else {
            setStatusOverview({})
        }
    }

    function handleViewDocument(event: any, documentPath: string) {
        event.preventDefault()
        event.stopPropagation()

        if (documentPath) {
            const a = document.createElement('a');
            a.target = '_blank';
            a.href = documentPath;
            document.body.append(a);
            a.click();
            document.body.removeChild(a);
        }

    }

    useEffect(() => {
        getServiceClaimList()
        getAdminManagedColumnsListData()
        getAdminverificationList()
        setColumnVisibility({
            groupName: false,
            companyName: false,
            providerId: false,
            profileStatus: false,
            completion: false,
            updatedBy: false,
            "mrt-row-expand": false
        })

    }, [])

    useEffect(() => {
        let tempClaimListPayload: any = claimListPayload;
        if (sorting?.length > 0) {
            if (sorting[0].desc) {
                tempClaimListPayload.sortColumnName = sorting[0].id
                tempClaimListPayload.sortOrder = "desc"
            } else {
                tempClaimListPayload.sortColumnName = sorting[0].id;
                tempClaimListPayload.sortOrder = 'asc';
            }           
        }else {
            tempClaimListPayload.sortColumnName = '';
            tempClaimListPayload.sortOrder = '';
        }
        setClaimListPayload(tempClaimListPayload)
        getServiceClaimList()
    }, [sorting])


    useEffect(() => {

        if (managedColumnsList?.length > 0) {
            managedColumnsList?.map((val: any) => {
                if (val.columnName === "ProviderId") {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        providerId: true
                    }))
                }

                if (val.columnName === "ProfileStatus") {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        profileStatus: true
                    }))
                }

                if (val.columnName === "Completion") {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        completion: true
                    }))
                }

                if (val.columnName === "UpdatedBy") {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        updatedBy: true
                    }))
                }

                if (val.columnName === "GroupName") {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        groupName: true
                    }))
                }

                if (val.columnName === "CompanyName") {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        companyName: true
                    }))
                }

            })
        }



    }, [managedColumnsList])

    const onChangeManageColumnHandler = async (data: any) => {
        const { ColumnName, IsActive } = data;
        if (data.ColumnName === "ProviderId" && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                providerId: true
            }))
        } else if (data.ColumnName === "ProviderId" && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                providerId: false
            }))
        }
        if (data.ColumnName === "ProfileStatus" && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                profileStatus: true
            }))
        } else if (data.ColumnName === "ProfileStatus" && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                profileStatus: false
            }))
        }
        if (data.ColumnName === "Completion" && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                completion: true
            }))
        } else if (data.ColumnName === "Completion" && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                completion: false
            }))
        }
        if (data.ColumnName === "UpdatedBy" && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                updatedBy: true
            }))
        } else if (data.ColumnName === "UpdatedBy" && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                updatedBy: false
            }))
        }
        if (data.ColumnName === "GroupName" && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                groupName: true
            }))
        } else if (data.ColumnName === "GroupName" && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                groupName: false
            }))
        }
        if (data.ColumnName === "CompanyName" && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                companyName: true
            }))
        } else if (data.ColumnName === "CompanyName" && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                companyName: false
            }))
        }
        await UpdateAdminManageColumnsService(data)
    };

    const getCurrentPage = (page: any) => {
        setCurrentPage(page);
        let tempClaimListPayload: any = claimListPayload;
        tempClaimListPayload.page = page;
        setExpanded({});
        setClaimListPayload(tempClaimListPayload);
        getServiceClaimList();

    };

    const handleSizeChange = (e: any) => {
        setPageSize(Number(e.target.value));

        let tempClaimListPayload: any = claimListPayload;
        tempClaimListPayload.page = 1;
        setExpanded({});
        tempClaimListPayload.pageSize = Number(e.target.value);
        setCurrentPage(1);
        setClaimListPayload(tempClaimListPayload);
        getServiceClaimList();
    };
    const lastCallTime = useRef(0);
    const [shouldFetch, setShouldFetch] = useState(false);
    const changeSearchValue = (event: any) => {
        if (event.target.name === 'invoiceSmartSearch') {
            const tempClaimListPayload = { ...claimListPayload }; // Create a shallow copy
            tempClaimListPayload.searchValue = event.target.value || ''; // Set search value
            tempClaimListPayload.page = 1;
    
            setCurrentPage(1);
            setExpanded({});
            setClaimListPayload(tempClaimListPayload);
    
            // Set flag to trigger API call
        setShouldFetch(true);
        }
    };

    useEffect(() => {
        if (shouldFetch) {
            getServiceClaimList();
            setShouldFetch(false); // Reset the flag
        }
    }, [claimListPayload, shouldFetch]);

    const handleSelectStatusSelect = (value: string) => {
        const temValue = value
        if (selectedStatus.toString() === temValue.toString()) {
            setSelectedStatus("")
            value = ""
            let tempClaimListPayload: any = claimListPayload;
            tempClaimListPayload.filter.VerificationStatus = ""
            tempClaimListPayload.page = 1;
            setExpanded({});
            setClaimListPayload(tempClaimListPayload);
        } else {
            setSelectedStatus(value)
            let tempClaimListPayload: any = claimListPayload;
            tempClaimListPayload.filter.VerificationStatus = value
            tempClaimListPayload.page = 1;
            setExpanded({});
            setClaimListPayload(tempClaimListPayload);
        }
        getServiceClaimList();
    }


    const handleInReviewClick = async (serviceId: number, serviceName: string) => {
        const payload = {
            serviceId: serviceId,
            verificationId: Statusenums.InReview
        }

        swal({
            title: '',
            text: `Are you sure you want to start the review process for ${serviceName} service ?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {

            if (confirm) {
                const response = await SaveServiceVerificationInReview(payload);
                if (response.status === true) {
                    swal({
                        title: '',
                        text: response.message,
                        icon: 'success',
                        dangerMode: true
                    }).then(() => {
                        getServiceClaimList()
                        getAdminverificationList()
                    });
                } else {
                    swal({
                        title: '',
                        text: response.message,
                        icon: 'error',
                        dangerMode: true
                    })
                }
            }
        });
    }    
    const tableKey = JSON.stringify(data); // Unique key based on data content
    if (isLoading) <LoaderContainer />



    return (
        <div className="container-fluid">
            <div className="service-title text-center big-text">Locate Admin Verification </div>
            <div className="box-shadow new-box-shadow">
                <div className="row">
                    <div className='col-xl-8'>
                        <div className="admin-verification-field">
                            <div className='d-flex align-items-center mb-3'>
                                <div className="me-2 position-relative">
                                    <SearchServiceClaimContainer searchValue={changeSearchValue} />
                                </div>
                                <LocateAdminTableManageColumnsContainer managedColumnsList={managedColumnsList} onChangeManageColumnHandler={onChangeManageColumnHandler} />

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <LocateStatusOverViewContainer statusOverview={statusOverview} handleSelectStatusSelect={handleSelectStatusSelect} selectedStatus={selectedStatus} />
                    </div>
                </div>
                <div className="">
                    <div className="col-lg-12 col-md-12 invoice-table ps-1 pe-1">
                        <MaterialReactTable
                            key={tableKey}
                            columns={columns}
                            data={data}
                            enableColumnActions={false}
                            enableGrouping
                            enableBottomToolbar={false}
                            enableTopToolbar={false}
                            enableColumnDragging={false}
                            enableExpanding={false}
                            enableExpandAll={false}

                            initialState={
                                {
                                    // grouping: ['invoice_Type'],
                                    // expanded: undefined
                                    // columnVisibility: {
                                    //     "mrt-row-expand": false,
                                    // }

                                }
                            }
                            state={{
                                columnVisibility,
                                sorting,
                                expanded, // Add expanded state
                            }}
                            manualSorting
                            onSortingChange={setSorting}
                            onExpandedChange={setExpanded} // Handle expanded state changes
                            // getSubRows={(originalRow) => originalRow.subRows || []}
                            muiTableBodyRowProps={{
                                className: "expaneded-row"
                            }}
                            renderDetailPanel={({ row }) => {
                                const { documents } = row.original
                                return (
                                    <div>
                                        {
                                            documents?.length > 2 && (
                                                <div className="d-flex mt-2 mb-2 document-row" >
                                                    {documents?.map((document: any) => (
                                                        <div className="me-2" key={document?.documentId} onClick={(e) => handleViewDocument(e, document?.documentPath)}>
                                                            <a data-tooltip-id="my-tooltip" data-tooltip-content={document?.documentName}>
                                                                <img src={docIcon} className="img-fluid" alt="document" width={30} />
                                                            </a>
                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                        }
                                    </div>
                                )

                            }
                            }
                            // enableRowSelection
                            getRowId={(row) => row.onboardingId}
                            manualPagination


                            // onPaginationChange={setPagination}
                            // muiTableBodyRowProps={({ row }) => ({
                            //     onClick: (event) => {
                            //
                            //     },
                            //     sx: {
                            //       cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                            //     },
                            //   })}
                            enableRowActions
                            positionActionsColumn="last"
                            renderRowActions={({ row }) => {
                                const { completion, verificationStatus, leadActivityStatusId, isMultiServiceProvider, canReopen, serviceId, service_Name, isLocked, slugName } = row.original;
                                return (
                                    <div className="d-flex">
                                        {verificationStatus === "Pending" && (
                                            <>
                                                <a
                                                    className={`status-icon topblue-btn align-items-center justify-content-center  ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                    data-tooltip-id="my-tooltip" data-tooltip-content="In Review"
                                                    onClick={() => { handleInReviewClick(row.original.serviceId, row.original.service_Name) }}
                                                >
                                                    <svg version="1.1" id="Layer_1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                                        x="0px" y="0px"
                                                        viewBox="0 0 1080 1080"
                                                        width={16}
                                                        enable-background="new 0 0 1080 1080;"
                                                        xmlSpace="preserve">
                                                        <g>
                                                            <path fill='#fff' d="M1027.4,976.9C976.1,926,925,874.8,873.9,823.7c-2.7-2.7-5.3-5.6-7.8-8.3c62-93.2,37.2-209.6-43.6-270.7
                                                    c-78.7-59.5-189-51.9-258.8,17.8c-69.9,69.7-77.7,179.9-18.4,258.8c60.3,80.4,177,106.7,273.1,42.7c2,2.8,3.9,6.5,6.6,9.3
                                                    c37.7,37.9,75.4,75.6,113.2,113.4c14.4,14.4,28.5,29.1,43.3,43c19.1,18,50,10.2,57.3-14.3C1043.3,1000.4,1038.4,987.9,1027.4,976.9
                                                    z M703.6,830.1c-70.4,0.1-127.9-56.9-128.2-127c-0.2-70.8,57.1-128.6,127.7-128.7c70.9,0,128.1,57.3,128,128.2
                                                    C830.9,773.3,774.2,830,703.6,830.1z"/>
                                                            <path fill='#fff' d="M594.5,971c-5.3-1.1-10.8-1.2-16.2-1.2c-118.5-0.1-237,0.1-355.5-0.2c-12.2,0-24.9-0.9-36.6-4.1
                                                    c-46.7-12.7-75.7-53.1-75.8-104.5c-0.1-150.5,0-300.9-0.1-451.4c0-3.6-0.1-7.3-0.4-10.9c0.4-51.4,0.9-102.8,1.3-154.2
                                                    c0.3-31.6-0.2-72,21.5-97.7c20.2-23.9,55.5-37.3,86.2-37.3c91.9-0.2,183.8-0.1,275.7,0c3.5,0,6.9,0.4,11,0.6
                                                    c0,36.2-0.6,71.4,0.1,106.6c1.3,55.6,24.2,101,67.9,135.4c32,25.2,69,36.4,109.5,36.5c33.4,0.1,66.8,0,100.4,0c0.7,2.3,1,2.9,1,3.6
                                                    c0.1,17.1,0.1,34.1,0.2,51.2c0.1,22.3,14.4,37.7,34.8,37.8c20.5,0,34.8-15.3,34.9-37.6c0.1-28.3-0.5-56.7,0.2-85
                                                    c0.4-14.3-4.8-25-14.6-34.7c-23.1-22.6-45.8-45.6-68.7-68.5c-67.1-67-134.2-134-201.1-201.2c-10.4-10.5-21.8-16.5-36.8-14.6
                                                    c-4.3,0.5-8.7,0.2-13.1,0.2c-103.9,0.1-207.8-0.9-311.7,0.5C139.7,41,77,86.5,52.2,150.6c-16.8,43.3-10.5,89.9-10.7,135.1
                                                    c0,12.9-1.6,154.6-1,154.6c0,0,0,0,0.1,0c-0.1,66.5,0,133,0,199.4c0,73.4-0.2,146.8,0.2,220.3c0.1,14.1,0.8,28.5,3.8,42.2
                                                    c18,81.6,86.7,136.9,171,137.2c122.5,0.5,245,0.1,367.5,0.1c2.2,0,4.4,0,6.5-0.2c17.3-1.3,30.8-14.6,32-31.5
                                                    C622.8,989.8,611.9,974.6,594.5,971z M575.7,162c0.9-0.7,1.8-1.4,2.7-2.1c51.4,52.3,102.8,104.5,154.5,157
                                                    c-24.5,0-48.3,2.4-71.4-0.5c-45.7-5.8-82.5-47.2-85.5-93.9C574.6,202.4,575.7,182.1,575.7,162z"/>
                                                            <path fill='#fff' d="M444.3,272c-75.5-0.2-151-0.3-226.5,0.3c-8.2,0.1-17.3,3.2-24.2,7.7c-12.2,7.8-16.3,24.1-11.8,37.8
                                                    c4.6,14.1,17.2,23.7,33,23.8c38.8,0.3,77.7,0.1,116.5,0.1c0,0,0,0,0,0c37.8,0,75.5,0.1,113.3,0c22.6-0.1,37.6-14.2,37.6-34.8
                                                    C482,286.5,466.8,272,444.3,272z"/>
                                                            <path fill='#fff' d="M217.9,807.2c75.5,0.2,151,0.2,226.5-0.1c6.7,0,13.9-1.7,19.8-4.6c14.1-7,20.8-23.6,17-38.9c-3.6-14.6-16.7-25.7-32.5-25.8
                                                    c-39.2-0.4-78.4-0.1-117.6-0.1c0,0,0-0.1,0-0.1c-37.8,0-75.5-0.1-113.3,0c-22.8,0.1-37.6,14-37.6,34.6
                                                    C180.2,792.6,195.5,807.1,217.9,807.2z"/>
                                                            <path fill='#fff' d="M443.2,504.7c-74.8,0-149.6,0-224.4,0c-23.2,0-38.6,14.1-38.6,35c0.1,20.7,15.6,34.7,38.9,34.7c37.4,0.1,74.8,0,112.2,0
                                                    c37.4,0,74.8,0.1,112.2,0c23.1,0,38.7-14.3,38.6-35C482,518.7,466.5,504.7,443.2,504.7z"/>
                                                        </g>
                                                    </svg>
                                                </a>
                                            </>
                                        )}

                                        {verificationStatus === "In Review" && (
                                            <>
                                                <a
                                                    className={`status-icon yellow-btn ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                    data-tooltip-id="my-tooltip" data-tooltip-content="On Hold" onClick={() => {
                                                        setDataServiceId(serviceId)
                                                        setServiceName(service_Name)
                                                        handleClickOnHoldModal()
                                                    }}
                                                >
                                                    <i className="fa-solid fa-hand"></i>
                                                </a>
                                                <a
                                                    className={`status-icon green-btn align-items-center justify-content-center  ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                    data-tooltip-id="my-tooltip" data-tooltip-content="Approve" onClick={() => {
                                                        setDataServiceId(serviceId)
                                                        setServiceName(service_Name)
                                                        handleClickApproveModal()
                                                    }}
                                                >
                                                    <i className="fa-solid fa-square-check" aria-hidden="true"></i>
                                                </a>
                                                <a
                                                    className={`status-icon red-btn align-items-center justify-content-center  ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                    data-tooltip-id="my-tooltip" data-tooltip-content="Reject" onClick={() => {
                                                        setDataServiceId(serviceId)
                                                        setServiceName(service_Name)
                                                        handleClickRejectModal()
                                                    }}
                                                >
                                                    {/* <i className="fa fa-times" aria-hidden="true"></i> */}
                                                    <i className="fa-solid fa-rectangle-xmark" aria-hidden="true"></i>
                                                </a>
                                            </>
                                        )}

                                        {verificationStatus === "OnHold" && (
                                            <>
                                                <a
                                                    className={`status-icon disabled-btn ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                    data-tooltip-id="my-tooltip" data-tooltip-content="On Hold" onClick={() => {
                                                    }}
                                                >
                                                    <i className="fa-solid fa-hand"></i>
                                                </a>
                                                <a
                                                    className={`status-icon green-btn align-items-center justify-content-center  ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                    data-tooltip-id="my-tooltip" data-tooltip-content="Approve" onClick={() => {
                                                        setDataServiceId(serviceId)
                                                        setServiceName(service_Name)
                                                        handleClickApproveModal()
                                                    }}
                                                >
                                                    <i className="fa-solid fa-square-check" aria-hidden="true"></i>
                                                </a>
                                                <a
                                                    className={`status-icon red-btn align-items-center justify-content-center  ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                    data-tooltip-id="my-tooltip" data-tooltip-content="Reject" onClick={() => {
                                                        setDataServiceId(serviceId)
                                                        setServiceName(service_Name)
                                                        handleClickRejectModal()
                                                    }}
                                                >
                                                    <i className="fa-solid fa-rectangle-xmark" aria-hidden="true"></i>
                                                </a>

                                            </>
                                        )}

                                        {(verificationStatus === "Rejected" && isMultiServiceProvider === true && canReopen === true) && (
                                            <>
                                                <a
                                                    className={`status-icon lightgreen-btn align-items-center justify-content-center  ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                    data-tooltip-id="my-tooltip" data-tooltip-content="Reopen" onClick={() => {
                                                        setDataServiceId(serviceId)
                                                        setServiceName(service_Name)
                                                        handleClickModal()
                                                    }}
                                                >
                                                    <i className="fa fa-undo" aria-hidden="true"></i>
                                                </a>
                                            </>
                                        )}

                                        {
                                            (verificationStatus === "Rejected" && isMultiServiceProvider === true && canReopen === false) && (
                                                <>
                                                    <a
                                                        className={`status-icon disabled-btn ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                        data-tooltip-id="my-tooltip" data-tooltip-content="On Hold" onClick={() => {
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-hand"></i>
                                                    </a>
                                                    <a
                                                        className={`status-icon disabled-btn align-items-center justify-content-center  ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                        data-tooltip-id="my-tooltip" data-tooltip-content="Approve"
                                                    >
                                                        <i className="fa-solid fa-square-check" aria-hidden="true"></i>
                                                    </a>
                                                    <a
                                                        className={`status-icon disabled-btn align-items-center justify-content-center  ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                        data-tooltip-id="my-tooltip" data-tooltip-content="Reject"
                                                    >
                                                        <i className="fa-solid fa-rectangle-xmark" aria-hidden="true"></i>
                                                    </a>
                                                </>
                                            )
                                        }

                                        {(verificationStatus === "Completed" && completion < 100) && (
                                            <>
                                                <a
                                                    className={`status-icon disabled-btn align-items-center justify-content-center  ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                    data-tooltip-id="my-tooltip" data-tooltip-content="View"
                                                >
                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                </a>
                                                <a
                                                    className={`status-icon disabled-btn align-items-center justify-content-center  ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                    data-tooltip-id="my-tooltip" data-tooltip-content="Lock"
                                                >
                                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                                </a>
                                            </>
                                        )
                                        }

                                        {(verificationStatus === "Completed" && completion === 100 && isLocked === false) && (
                                            <>
                                                <a
                                                    className={`status-icon topblue-btn align-items-center justify-content-center  ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                    data-tooltip-id="my-tooltip" data-tooltip-content="View"
                                                    onClick={() => {
                                                        // const slugName = service_Name.split(" ").join("")
                                                        const url = `${process.env.REACT_APP_PUBLIC_BASE_URL}/ServiceDetail/${slugName}`
                                                        window.open(url, "_blank");
                                                    }}
                                                >
                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                </a>
                                                <a
                                                    className={`status-icon red-btn align-items-center justify-content-center  ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                    data-tooltip-id="my-tooltip" data-tooltip-content="Lock"
                                                    onClick={() => {
                                                        setDataServiceId(serviceId)
                                                        setServiceName(service_Name)
                                                        handleClickLockModal()
                                                    }}
                                                >
                                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                                </a>
                                            </>
                                        )}

                                        {
                                            (verificationStatus === "Completed" && completion === 100 && isLocked === true) && (
                                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                <>
                                                    <a
                                                        className={`status-icon disabled-btn align-items-center justify-content-center  ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                        data-tooltip-id="my-tooltip" data-tooltip-content="View"

                                                    >
                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                    </a>
                                                    <a
                                                        className={`status-icon green-btn align-items-center justify-content-center  ${row.original.leadActivityStatusId >= 1 ? 'active' : ''} `}
                                                        data-tooltip-id="my-tooltip" data-tooltip-content="Unlock"
                                                        onClick={() => {
                                                            setDataServiceId(serviceId)
                                                            setServiceName(service_Name)
                                                            handleClickUnlockModal()
                                                        }}
                                                    >
                                                        <i className="fa fa-unlock" aria-hidden="true"></i>
                                                    </a>
                                                </>

                                            )
                                        }

                                    </div>
                                )
                            }}

                        // muiTableBodyProps={{ sx: () => ({ '& tr:nth-child(even)': { backgroundColor: '#f7f7f7' } }) }} muiTableBodyCellProps={{ sx: { border: 'none' } }}
                        />
                    </div>
                    <div className="row align-items-center mt-3 invoice-table-pagination">
                        <div className="col-lg-6">
                            <div className="d-flex align-items-center">
                                <p className="purple-text bold">
                                    Displaying {showFrom} - {showTo} of {totalCount}
                                </p>
                                <div className="d-flex align-items-center ms-3">
                                    <label className="mb-0 bold nowrap me-2">Page Size:</label>
                                    <select className={`form-control white page-select`} id="supportedPersoIntegrationPageSize" value={pageSize} onChange={(e: any) => handleSizeChange(e)}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <PaginationContainer currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} onPageChange={(page: any) => getCurrentPage(page)} />
                        </div>
                    </div>
                </div>

            </div>

            <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip" />
            {/* <ClientPortal selector="myportal" show={showPortal}>
                <div className="overlay" onClick={handleModal}></div>
                <div className="modal userInfoModal">
                    <div className="header mb-4">
                        <h4 className="mb-0">All Documents</h4>
                        <a onClick={handleModal} className="removeButton">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 384 512"
                            >
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body ">
                        <div>
                            <div className="document-list">
                                <div className="row">
                                    <div className="col-md-4 mb-3 d-flex align-items-center text-center">
                                        <div>
                                            <a href="#">
                                                <img src={docIcon} className="img-fluid" alt="document" width={50} />
                                            </a>
                                            <p className="mt-2">Document Name 1</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3 d-flex align-items-center text-center">
                                        <div>
                                            <a href="#">
                                                <img src={docIcon} className="img-fluid" alt="document" width={50} />
                                            </a>
                                            <p className="mt-2">Document Name 2</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3 d-flex align-items-center text-center">
                                        <div>
                                            <a href="#">
                                                <img src={docIcon} className="img-fluid" alt="document" width={50} />
                                            </a>
                                            <p className="mt-2">Document Name 3</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3 d-flex align-items-center text-center">
                                        <div>
                                            <a href="#">
                                                <img src={docIcon} className="img-fluid" alt="document" width={50} />
                                            </a>
                                            <p className="mt-2">Document Name 4</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3 d-flex align-items-center text-center">
                                        <div>
                                            <a href="#">
                                                <img src={docIcon} className="img-fluid" alt="document" width={50} />
                                            </a>
                                            <p className="mt-2">Document Name 5</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3 d-flex align-items-center text-center">
                                        <div>
                                            <a href="#">
                                                <img src={docIcon} className="img-fluid" alt="document" width={50} />
                                            </a>
                                            <p className="mt-2">Document Name 6</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ClientPortal> */}
            {showPortal && <ReasonModalContainer showPortal={showPortal} handleCloseModal={handleCloseModal} from={"Reopen"} dataserviceId={dataserviceId} serviceName={serviceName} getServiceClaimList={getServiceClaimList} getAdminverificationList={getAdminverificationList} />}
            {approveModal && <ReasonModalContainer showPortal={approveModal} handleCloseModal={handleCloseApproveModal} from={"Approve"} dataserviceId={dataserviceId} serviceName={serviceName} getServiceClaimList={getServiceClaimList} getAdminverificationList={getAdminverificationList} />}
            {rejectModal && <ReasonModalContainer showPortal={rejectModal} handleCloseModal={handleCloseRejectModal} from={"Reject"} dataserviceId={dataserviceId} serviceName={serviceName} getServiceClaimList={getServiceClaimList} getAdminverificationList={getAdminverificationList} />}
            {onHoldModal && <ReasonModalContainer showPortal={onHoldModal} handleCloseModal={handleCloseOnHoldModal} from={"OnHold"} dataserviceId={dataserviceId} serviceName={serviceName} getServiceClaimList={getServiceClaimList} getAdminverificationList={getAdminverificationList} />}
            {lockModal && <ReasonModalContainer showPortal={lockModal} handleCloseModal={handleCloseLockModal} from={"Lock"} dataserviceId={dataserviceId} serviceName={serviceName} getServiceClaimList={getServiceClaimList} getAdminverificationList={getAdminverificationList} />}
            {unlockModal && <ReasonModalContainer showPortal={unlockModal} handleCloseModal={handleCloseUnlockModal} from={"Unlock"} dataserviceId={dataserviceId} serviceName={serviceName} getServiceClaimList={getServiceClaimList} getAdminverificationList={getAdminverificationList} />}


            <RenderModalContainer>
                {showInfoPortal && <div><div className="overlay" onClick={handleCloseInfoModal}></div>
                    <div className="modal modal-extrasmall">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title mb-0">{verificationStatus === "OnHold" ? "On Hold" : verificationStatus}</h4>
                                <a href="#" title="close" onClick={handleCloseInfoModal} className="removeButton">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                                    </a>

                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-md-12">
                                        {/* {verificationReason} */}
                                        <input type="text" className="form-control white mb-3" disabled value={verificationReason} />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea name="desc" className="form-control white mb-3 " rows={4} disabled>{verificationComment}</textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center">
                            </div>
                        </div>
                    </div>
                </div>
                }
            </RenderModalContainer>
        </div>
    );
};

export default LocateAdminView;