import React from 'react';
import LeadDetailsView from './LeadDetailsView';

const LeadDetailsContainer = (props: {isheader?: boolean, referralId?: number, refreshData?: (stage: number, newflead?: number) => void}) => {
    return (
        <div>
            <LeadDetailsView {...props} />
        </div>
    );
};

export default LeadDetailsContainer;