import React from 'react'
import { Tooltip } from 'react-tooltip'

const DownloadServicesView = (props: any) => {
    return (
        <div>
            <a  onClick={props.onClickHandlerDownloadServices} data-tooltip-id="my-tooltip" data-tooltip-content="Download" className='btn btn-white bold'><i className="fa-sharp fa-solid fa-download"></i></a>
            <Tooltip id="my-tooltip" className="my-tooltip" />
        </div>
    )
}

export default DownloadServicesView