import React from 'react';
import ServiceCardsView from './ServiceCardsView';

const ServiceCardsContainer = () => {
    return (
        <div>
            <ServiceCardsView/>
        </div>
    );
};

export default ServiceCardsContainer;