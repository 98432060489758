import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  PolylineF,
  PolygonF,
  MarkerF,
  OverlayViewF,
  OVERLAY_MOUSE_TARGET,
  DirectionsService,
  DirectionsRenderer,
  MARKER_LAYER,
  Libraries,
} from "@react-google-maps/api";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import path from "path";
import { BASE_URL, optionMapStyles } from "../../utils/constant";
import TrianglAarrow from '../../assets/images/triangle.svg';
import laundry from '../../assets/images/Laundry.svg';
import park_garden from '../../assets/images/Park-Garden.svg';
import restaurant from '../../assets/images/Restaurant.svg';
import salon_barber from '../../assets/images/Salon-Barber.svg';
import supermarket from '../../assets/images/Supermarket.svg';
import airport from '../../assets/images/airport.png';
import bus from '../../assets/images/bus.svg';
import doctor from '../../assets/images/doctor.svg';
import hospital from '../../assets/images/hospital.svg';
import hotel from '../../assets/images/hotel.png';
import pharmacy from '../../assets/images/pharmacy.svg';
import railway from '../../assets/images/railway.svg';
import { saveForLaterModal } from "../../store/SaveForLater/saveForLaterModalReducer";
import { useDispatch } from "react-redux";
import { setHoveredService } from "../../store/general/hoveredServiceReducer";

const libraries: Libraries = ['places', 'geometry'];

const GoogleMapBaseComponent = (props: any) => {
  const dispatch = useDispatch();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
    libraries: libraries
  });

  /************** Poly Line and Polygon Code Start ***************/
  let lineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 1,
    strokeWeight: 3,
  };

  const POLYLINE_OPTIONS = {
    strokeColor: "#FF0000",
    strokeOpacity: 0,
    icons: [
      {
        icon: lineSymbol,
        offset: "0",
        repeat: "10px",
      },
    ],
  };

  const outerCoords = [
    { lat: 85, lng: 180 },
    { lat: 85, lng: 90 },
    { lat: 85, lng: 0 },
    { lat: 85, lng: -90 },
    { lat: 85, lng: -180 },
    { lat: 0, lng: -180 },
    { lat: -85, lng: -180 },
    { lat: -85, lng: -90 },
    { lat: -85, lng: 0 },
    { lat: -85, lng: 90 },
    { lat: -85, lng: 180 },
    { lat: 0, lng: 180 },
    { lat: 85, lng: 180 },
  ];
  const innerCoords = props.borderPoint;

  const BOTH_COORDS = [outerCoords, innerCoords];

  const sfPolygonOptions = {
    strokeColor: "#ffffff",
    strokeOpacity: 0,
    strokeWeight: 2,
    fillColor: "#000000",
    fillOpacity: 0.35,
    paths: BOTH_COORDS,
  };

  const [polylineOptions, setPolylineOptions] = React.useState(
    JSON.stringify(POLYLINE_OPTIONS)
  );

  const po = React.useMemo(() => {
    try {
      return JSON.parse(polylineOptions);
    } catch (e) {
      return POLYLINE_OPTIONS;
    }
  }, [polylineOptions]);
  /************** Poly Line and Polygon Code End ***************/

  const [overlayPane, setOverlayPane] = useState(OVERLAY_MOUSE_TARGET);

  const [activeMarker, setActiveMarker] = useState(null);

  const [markerPosition, setMarkerPosition] = useState<any>({ x: 0, y: 0 })

  const [activePlaceMarker, setActivePlaceMarker] = useState(null);

  const handleActiveMarker = (marker: any) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const closeOverLay = () => {
    setActiveMarker(null);
  };

  useEffect(() => {
    if (activeMarker === null) {
      dispatch(saveForLaterModal({
        saveForLaterModelOpen: false,
        serviceId: 0,
        serviceName: ''
      }))
    }
  }, [activeMarker]);

  function centerOverlayView(
    width: number,
    height: number
  ): { x: number; y: number } {
    return {
      x: -(width / 2),
      y: -(height / 2),
    };
  }

  const contentStyles = {
    background: `white`,
    border: `1px solid #CCC`,
    padding: 15,
  };

  const loadCallback = useCallback((overlayView: any) => {
    // console.log("OverlayView onLoad: ", overlayView);
  }, []);

  const unmountCallback = useCallback((overlayView: any) => {
    // console.log("OverlayView onUnmount", overlayView);
  }, []);

  const clickHandler = useCallback(() => {
    alert("You clicked overlay view");
  }, []);
  useEffect(() => {
    if (activeMarker) {
      const markerElement = document.querySelector(`[data-marker="${activeMarker}"]`);
      if (markerElement) {
        const markerRect = markerElement.getBoundingClientRect();
        const xOffset = markerRect.left + markerRect.width / 2;
        const yOffset = markerRect.top;
        setMarkerPosition({ x: xOffset, y: yOffset });
      }
    }
  }, [activeMarker]);

  const polygonCoords = [
    { lat: 37.772, lng: -122.214 },
    { lat: 21.291, lng: -157.821 },
    { lat: -18.142, lng: 178.431 },
    { lat: -27.467, lng: 153.027 },
  ];

  const polylineCoords = [
    { lat: 37.772, lng: -122.214 },
    { lat: 21.291, lng: -157.821 },
    { lat: -18.142, lng: 178.431 },
    { lat: -27.467, lng: 153.027 },
  ];

  const pathCoordinates = [
    { lat: 36.05298765935, lng: -112.083756616339 },
    { lat: 36.2169884797185, lng: -112.056727493181 }
  ];

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        // Call your function here
        // console.log('Clicked outside the div');
      }
    };

    const handleWindowClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };

    window.addEventListener('click', handleWindowClick);

    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);

  const hoverActivePlaceMarker = (marker: any) => {
    if (marker === activePlaceMarker) {
      return;
    }
    setActivePlaceMarker(marker);
  };

  const closeActiveOverLay = () => {
    setActivePlaceMarker(null);
  };

  const getPixelPositionOffset = (width: number, height: number) => {
    return {
      x: -(width / 2),
      y: -(height + 40)
    };
  };

  const getImageSrc = (place: string) => {
    switch (place) {
      case 'park':
        return park_garden;
      case 'laundry':
        return laundry;
      case 'restaurant':
        return restaurant;
      case 'train_station':
        return railway;
      case 'pharmacy':
        return pharmacy;
      case 'supermarket':
        return supermarket;
      case 'hair_care':
        return salon_barber;
      case 'bus_station':
        return bus;
      case 'doctor':
        return doctor;
      case 'hospital':
        return hospital;
      case 'airport':
        return airport;
      case 'lodging':
        return hotel;
      default:
        return hotel;
    }
  };
  const getTypeString = (place: string) => {
    switch (place) {
      case 'park':
        return '(Park/Garden)';
      case 'laundry':
        return '(Laundry)';
      case 'restaurant':
        return '(Restaurant)';
      case 'train_station':
        return '(Railway Station)';
      case 'pharmacy':
        return '(Pharmacy)';
      case 'supermarket':
        return '(Supermarket)';
      case 'hair_care':
        return '(Salon/Barber)';
      case 'bus_station':
        return '(Bus Stop)';
      case 'doctor':
        return '(GP)';
      case 'hospital':
        return '(Hospital)';
      case 'airport':
        return '(Airport)';
      case 'lodging':
        return '(Hotel)';
      default:
        return '';
    }
  };

  const truncateText = (text: string) => {
    if (text.length <= 70) {
      return text;
    }
    return text.slice(0, 70) + '...';
  };

  const closeProximityOverLay = () => {
    setActivePlaceMarker(null);
};

  return isLoaded && props?.centerPoint !== null ? (
    <GoogleMap
      mapContainerStyle={props?.containerStyle}
      center={props?.centerPoint}
      zoom={props?.zoom}
      options={{
        styles: optionMapStyles
      }}
    >
      <>
        {/* {props.showOutOfBorder === false && (<PolygonF paths={BOTH_COORDS} options={sfPolygonOptions} />)}
        <PolylineF path={props.borderPoint} options={po} /> */}
        {/* {props.showOutOfBorder === false && (<PolygonF paths={BOTH_COORDS} options={sfPolygonOptions} />)} */}
        <PolylineF path={props.borderPoint} options={po} />
        {props?.directions && <DirectionsRenderer directions={props?.directions
        } />}
        {props.markerPoint?.length > 0 &&
          props.markerPoint.map((val: any, index: any) => {
            let cord = {
              lat: Number(val.latitude),
              lng: Number(val.longitude),
            };
            return (
              <div key={val.serviceId}>
                <MarkerF
                  position={cord}
                  options={{
                    icon: {
                      url: `${BASE_URL}/available-pin.svg`,
                      scaledSize: new google.maps.Size(50, 50),
                      anchor: new google.maps.Point(Math.floor(50 / 2), 50)
                    }
                    // title: "Purple",
                  }}
                  onClick={() => handleActiveMarker(val.serviceId)}
                  onMouseOver={() => handleActiveMarker(val.serviceId)}
                />
                {activeMarker === val.serviceId ? (
                  <OverlayViewF
                    position={cord}
                    mapPaneName={overlayPane}
                    onLoad={loadCallback}
                    onUnmount={unmountCallback}
                    getPixelPositionOffset={centerOverlayView}
                  >
                    {/* <button type="button" onClick={closeOverLay}>
                      Close
                    </button> */}
                    {/* <button
                      type="button"
                      style={contentStyles}
                      onClick={clickHandler}
                    > */}
                    {/* <h1>{`${val.serviceName}`}</h1> */}

                    {props?.Infobox ?
                      <div
                      
                        onMouseLeave={closeOverLay}
                        style={{
                          position: "absolute",
                          top: markerPosition.y - 150,
                          left: markerPosition.x - 200,
                        }}
                      >
                        {props?.Infobox}
                      </div> : <></>}
                    {/* </button> */}
                  </OverlayViewF>
                ) : null}
              </div>
            );
          })}
          {props.nearbyMarkers &&
                props.nearbyMarkers.length > 0 &&
                props.nearbyMarkers.map((place: any, index: number) => {
                    if (place?.geometry?.location) {
                        let coordinates = { lat: place?.geometry?.location?.lat(), lng: place?.geometry?.location?.lng() };
    
                        return (
                            <div key={place?.place_id+''+index}>
                                <MarkerF
                                    options={{
                                        icon: {
                                            url: `${BASE_URL}/provider-pin.svg`,
                                            scaledSize: new google.maps.Size(30, 30),
                                            anchor: new google.maps.Point(Math.floor(30 / 2), 14)
                                        }
                                    }}
                                    position={coordinates}
                                    onMouseOver={() => {
                                        hoverActivePlaceMarker(place?.place_id+''+index);
                                    }}
                                    onMouseOut={() => {
                                        closeProximityOverLay();
                                    }}
                                    onClick={() => {
                                        hoverActivePlaceMarker(coordinates);
                                    }}
                                />
                                {activePlaceMarker === place?.place_id+''+index ? (
                                    <OverlayViewF position={coordinates} mapPaneName={OVERLAY_MOUSE_TARGET} getPixelPositionOffset={getPixelPositionOffset}>
                                        <div className="location-onboarding-box-pin">
                                            <div className="d-flex">
                                                <div>
                                                    <div className="location-image-box-onboarding">
                                                        <img src={getImageSrc(place?.type)} alt="Location Image" className="img-fluid" width={74} />
                                                    </div>
                                                    <div className="d-flex">
                                                        <p className="lead-text map-box-title-onboarding">
                                                            <span className="bolder">{truncateText(place?.name)}</span> <span className="darkgray-text">{`${getTypeString(place?.type)}`}</span>
                                                        </p>
                                                    </div>
                                                    <p className="darkgray-text">{truncateText(place?.vicinity)}</p>
                                                </div>
                                            </div>
                                            <img src={TrianglAarrow} alt="triangle arrow" className="box-bottom-arrow-onboarding" height={25} width={25} />
                                        </div>
                                    </OverlayViewF>
                                ) : null}
                            </div>
                        );
                    }
                })}
      </>

    </GoogleMap>
  ) : (
    <></>
  );
};

export default GoogleMapBaseComponent;
