import React from 'react';
import FunderListContainer from '../ReferralManagementPage/ReferralManager/ReferralSidebar/CreateLead/FunderList/funderListContainer';
import FunderManagementView from './FunderManagementView';

const FunderManagementContainer = () => {
    return (
        <div>
            <FunderManagementView/>
        </div>
    );
};

export default FunderManagementContainer;