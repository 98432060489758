import { Circle, GoogleMap, Libraries, MarkerF, OVERLAY_MOUSE_TARGET, OverlayViewF, useJsApiLoader } from '@react-google-maps/api';
import React, { useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import laundry from '../../assets/images/Laundry.svg';
import park_garden from '../../assets/images/Park-Garden.svg';
import restaurant from '../../assets/images/Restaurant.svg';
import salon_barber from '../../assets/images/Salon-Barber.svg';
import supermarket from '../../assets/images/Supermarket.svg';
import airport from '../../assets/images/airport.png';
import bus from '../../assets/images/bus.svg';
import doctor from '../../assets/images/doctor.svg';
import hospital from '../../assets/images/hospital.svg';
import hotel from '../../assets/images/hotel.png';
import pharmacy from '../../assets/images/pharmacy.svg';
import railway from '../../assets/images/railway.svg';
import TrianglAarrow from '../../assets/images/triangle.svg';
import warning from '../../assets/images/warning.png';
import { BASE_URL, optionMapStyles } from '../../utils/constant';

const libraries: Libraries = ['places', 'geometry'];

const OnboardingLocationMapComponent = React.forwardRef((props: any, ref: any) => {
    const { t } = useTranslation();
    const radius = 150; // Radius in meters

    const circleOptions = {
        strokeColor: '#000000',
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillOpacity: 0.2,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: radius,
        zIndex: 1
    };

    const center = { lat: props.location.lat, lng: props.location.lng }; // Initial map center

    const placeTypes = ['park', 'laundry', 'restaurant', 'train_station', 'pharmacy', 'supermarket', 'hair_care', 'bus_station', 'doctor', 'hospital', 'airport', 'lodging'];

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
        libraries: libraries
    });

    const [map, setMap] = useState(null);
    const [markerPosition, setMarkerPosition] = useState({ lat: props.location.lat, lng: props.location.lng }); // Initial position (New York City for example)

    const [places, setPlaces] = useState<any>([]);
    const [distances, setDistances] = useState<any>([]);

    const [activePlaceMarker, setActivePlaceMarker] = useState(null);

    const onLoad = (mapInstance: any) => {
        setMap(mapInstance);
    };

    const onDragEnd = (event: any) => {
        const newPosition = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        };
        if (isWithinRadius(center, newPosition, radius)) {
            setMarkerPosition(newPosition);
        } else {
            swal({
                title: '',
                text: t('OnboardingLocation.Location_Pin_Warning')!,
                icon: warning,
                dangerMode: true
            });
            setMarkerPosition({ lat: props.location.lat, lng: props.location.lng });
        }
    };

    const isWithinRadius = (center: any, position: any, radius: any) => {
        const distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(center.lat, center.lng), new google.maps.LatLng(position.lat, position.lng));
        return distance <= radius;
    };

    const handleActivePlaceMarker = (coordinates: { lat: any; lng: any }) => {
        let origin = markerPosition;
        let destination = coordinates;

        let url = process.env.REACT_APP_MAPS_DIRECTIONS_URL;

        if (origin) {
            const originStr = `${origin.lat},${origin.lng}`;
            url += `&origin=${originStr}`;
        }

        if (destination) {
            const destinationStr = `${destination.lat},${destination.lng}`;
            url += `&destination=${destinationStr}`;
        }

        window.open(url, '_blank');
    };

    const hoverActivePlaceMarker = (marker: any) => {
        if (marker === activePlaceMarker) {
            return;
        }
        setActivePlaceMarker(marker);
    };

    const closeOverLay = () => {
        setActivePlaceMarker(null);
    };

    const calculateDistances = (placesCoordinates: any[]) => {
        const origin = new window.google.maps.LatLng(markerPosition.lat, markerPosition.lng);

        const service = new window.google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
            {
                origins: [origin],
                destinations: placesCoordinates,
                travelMode: window.google.maps.TravelMode.DRIVING,
                unitSystem: window.google.maps.UnitSystem.METRIC
            },
            (response, status) => {
                if (status === 'OK') {
                    const results = response!.rows[0].elements;
                    setDistances([...results]);
                } else {
                    console.error('Error fetching distance matrix data', status);
                }
            }
        );
    };

    const fetchNearbyPlacesWithDistance = async (location: any) => {
        const service = new window.google.maps.places.PlacesService(map!);
        let results: any[] = [];

        const nearbySearch = (type: string) => {
            return new Promise((resolve, reject) => {
                const request = {
                    location: new window.google.maps.LatLng(location.lat, location.lng),
                    rankBy: window.google.maps.places.RankBy.DISTANCE,
                    type: type
                };

                service.nearbySearch(request, (places, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK && places!.length > 0) {
                        if(type === 'hospital' || type === 'doctor' || type === 'pharmacy'){
                            const filteredPlaces = places ? places.filter(place => {
                                const distance = haversineDistance(
                                    location.lat, 
                                    location.lng, 
                                  place.geometry?.location?.lat(), 
                                  place.geometry?.location?.lng()
                                );
                                return distance >= radius;
                              }) : [];
                              resolve(filteredPlaces.length > 0 ? filteredPlaces[0] : null);
                        } else {
                            resolve(places![0]);
                        }
                    } else {
                        resolve(null);
                    }
                });
            });
        };

        const promises = placeTypes.map((type) => nearbySearch(type));

        try {
            await Promise.all(promises).then((placesResults) => {
                results = placesResults.filter((place) => place !== null);
                setPlaces(() => {
                    return results.map((item: any, index: number) => {
                        return { ...item, type: placeTypes[index] };
                    });
                });
                let placesCoords: any[] = [];
                results.forEach((place) => {
                    let lat = place.geometry?.location?.lat();
                    let lng = place.geometry?.location?.lng();
                    if (lat !== undefined && lng !== undefined) {
                        placesCoords.push(new window.google.maps.LatLng(lat, lng));
                    }
                });
                calculateDistances(placesCoords);
            });
        } catch (error) {
            console.error('Error fetching nearby places:', error);
        }
    };

    function haversineDistance(lat1: any, lon1: any, lat2: any, lon2: any) {
        const R = 6371000; 
      
        const toRadians = (degrees: any) => degrees * (Math.PI / 180);
      
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);
      
        const a = 
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * 
          Math.sin(dLon / 2) * Math.sin(dLon / 2);
      
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      
        return R * c;
      }

    const getPixelPositionOffset = (width: number, height: number) => {
        return {
            x: -(width / 2),
            y: -(height + 40)
        };
    };

    const getImageSrc = (place: string) => {
        switch (place) {
            case 'park':
                return park_garden;
            case 'laundry':
                return laundry;
            case 'restaurant':
                return restaurant;
            case 'train_station':
                return railway;
            case 'pharmacy':
                return pharmacy;
            case 'supermarket':
                return supermarket;
            case 'hair_care':
                return salon_barber;
            case 'bus_station':
                return bus;
            case 'doctor':
                return doctor;
            case 'hospital':
                return hospital;
            case 'airport':
                return airport;
            case 'lodging':
                return hotel;
            default:
                return hotel;
        }
    };
    const getTypeString = (place: string) => {
        switch (place) {
            case 'park':
                return '(Park/Garden)';
            case 'laundry':
                return '(Laundry)';
            case 'restaurant':
                return '(Restaurant)';
            case 'train_station':
                return '(Railway Station)';
            case 'pharmacy':
                return '(Pharmacy)';
            case 'supermarket':
                return '(Supermarket)';
            case 'hair_care':
                return '(Salon/Barber)';
            case 'bus_station':
                return '(Bus Stop)';
            case 'doctor':
                return '(GP)';
            case 'hospital':
                return '(Hospital)';
            case 'airport':
                return '(Airport)';
            case 'lodging':
                return '(Hotel)';
            default:
                return '';
        }
    };

    const truncateText = (text: string) => {
        if (text?.length <= 70) {
            return text;
        }
        return text?.slice(0, 70) + '...';
    };

    useImperativeHandle(ref, () => ({
        markerPosition,
        fetchNearbyPlacesWithDistance,
        places,
        setPlaces,
        distances
    }));

    return isLoaded && props?.centerPoint !== null ? (
        <GoogleMap
            options={{
                styles: optionMapStyles
            }}
            mapContainerStyle={props?.containerStyle}
            center={markerPosition}
            zoom={14}
            onLoad={onLoad}
        >
            <>
            <MarkerF
                options={{
                    icon: {
                        url: `${BASE_URL}/available-pin.svg`,
                        scaledSize: new google.maps.Size(50, 50),
                        anchor: new google.maps.Point(Math.floor(50 / 2), 35)
                    }
                }}
                position={markerPosition}
                draggable={props.isPinDraggable}
                onDragEnd={onDragEnd}
            />
            {props.isPinDraggable && <Circle center={center} options={circleOptions} />}
            {places &&
                places.length > 0 &&
                places.map((place: any, index: number) => {
                    if (place.geometry?.location) {
                        let coordinates = { lat: place.geometry?.location?.lat(), lng: place.geometry?.location?.lng() };
                        return (
                            <div key={place.place_id + '' + index}>
                                <MarkerF
                                    options={{
                                        icon: {
                                            url: `${BASE_URL}/provider-pin.svg`,
                                            scaledSize: new google.maps.Size(30, 30),
                                            anchor: new google.maps.Point(Math.floor(30 / 2), 14)
                                        }
                                    }}
                                    position={coordinates}
                                    onMouseOver={() => {
                                        hoverActivePlaceMarker(place.place_id + '' + index);
                                    }}
                                    onMouseOut={() => {
                                        closeOverLay();
                                    }}
                                    onClick={() => {
                                        handleActivePlaceMarker(coordinates);
                                    }}
                                />
                                {activePlaceMarker === place.place_id + '' + index ? (
                                    <OverlayViewF position={coordinates} mapPaneName={OVERLAY_MOUSE_TARGET} getPixelPositionOffset={getPixelPositionOffset}>
                                        <div className="location-onboarding-box-pin">
                                            <div className="d-flex">
                                                <div>
                                                    <div className="location-image-box-onboarding">
                                                        <img src={getImageSrc(place.type)} alt="Location Image" className="img-fluid" width={74} />
                                                    </div>
                                                    <div className="d-flex">
                                                        <p className="lead-text map-box-title-onboarding">
                                                            <span className="bolder">{truncateText(place.name)}</span> <span className="darkgray-text">{`${getTypeString(place.type)}`}</span>
                                                        </p>
                                                    </div>
                                                    <p className="darkgray-text">{truncateText(place.vicinity)}</p>
                                                </div>
                                            </div>
                                            <img src={TrianglAarrow} alt="triangle arrow" className="box-bottom-arrow-onboarding" height={25} width={25} />
                                        </div>
                                    </OverlayViewF>
                                ) : null}
                            </div>
                        );
                    }
                })}

                {props?.nearbyMarkers &&
                    props?.nearbyMarkers.length > 0 &&
                    props?.nearbyMarkers.map((place: any, index: number) => {
                        if (place.geometry?.location) {
                            let coordinates = { lat: place.geometry?.location?.lat(), lng: place.geometry?.location?.lng() };
        
                            return (
                                <div key={place.place_id+''+index}>
                                    <MarkerF
                                        options={{
                                            icon: {
                                                url: `${BASE_URL}/provider-pin.svg`,
                                                scaledSize: new google.maps.Size(30, 30),
                                                anchor: new google.maps.Point(Math.floor(30 / 2), 14)
                                            }
                                        }}
                                        position={coordinates}
                                        onMouseOver={() => {
                                            hoverActivePlaceMarker(place.place_id+''+index);
                                        }}
                                        onMouseOut={() => {
                                            closeOverLay();
                                        }}
                                        onClick={() => {
                                            handleActivePlaceMarker(coordinates);
                                        }}
                                    />
                                    {/* {activePlaceMarker === place.place_id+''+index ? (
                                        <OverlayViewF position={coordinates} mapPaneName={OVERLAY_MOUSE_TARGET} getPixelPositionOffset={getPixelPositionOffset}>
                                            <div className="location-onboarding-box-pin">
                                                <div className="d-flex">
                                                    <div>
                                                        <div className="location-image-box-onboarding">
                                                            <img src={getImageSrc(place.type)} alt="Location Image" className="img-fluid" width={74} />
                                                        </div>
                                                        <div className="d-flex">
                                                            <p className="lead-text map-box-title-onboarding">
                                                                <span className="bolder">{truncateText(place.name)}</span> <span className="darkgray-text">{`${getTypeString(place.type)}`}</span>
                                                            </p>
                                                        </div>
                                                        <p className="darkgray-text">{truncateText(place.vicinity)}</p>
                                                    </div>
                                                </div>
                                                <img src={TrianglAarrow} alt="triangle arrow" className="box-bottom-arrow-onboarding" height={25} width={25} />
                                            </div>
                                        </OverlayViewF>
                                    ) : null} */}
                                </div>
                            );
                        }
                })}
                </>
        </GoogleMap>
    ) : (
        <></>
    );
});

export default OnboardingLocationMapComponent;
