import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    MaterialReactTable,
    MRT_TableInstance,
    MRT_FullScreenToggleButton,
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_TablePagination,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToolbarAlertBanner,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_VisibilityState,
    type MRT_RowSelectionState,
    type MRT_GroupingState,
    MRT_Localization
} from 'material-react-table';
import { Box } from '@mui/material';
import PaginationContainer from '../../../components/PaginationComponent/indexContainer';
import SmartSearchDropdownContainer from '../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';

const CreateUserView = () => {
    const { t } = useTranslation();
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'srno', //access nested data with dot notation
                header: 'Sr No',
                size: 30,
            },
            {
                accessorKey: 'organization', //access nested data with dot notation
                header: 'Organization',
                size: 50,
            },
            {
                accessorKey: 'servicetype', //access nested data with dot notation
                header: 'Service Type',
                size: 50,
            },
            {
                accessorKey: 'servicename',
                header: 'Service Name',
                size: 100,
            },
            {
                accessorKey: 'view', //normal accessorKey
                header: 'Referral Privileges',
                size: 100,
                Cell: ({ cell }) => (
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <label className="form--radio-label d-flex align-items-center me-3">
                                <input name="view" type="radio" id="active" value="active" />
                                <span className="ms-1">View</span>
                            </label>
                            <label className="form--radio-label d-flex align-items-center">
                                <input name="edit" type="radio" id="inactive" value="inactive" />
                                <span className="ms-1">Edit</span>
                            </label>
                        </div>
                    </div>
                ),
            },
        ],
        [],
    );
    const Data = [
        {
            id: 1,
            organization:"Valorum Care",
            servicetype:"XYZ",
            srno: "1",
            servicename: "Magna House",
        },
        {
            id: 2,
            organization:"Valorum Care",
            servicetype:"XYZ",
            srno: "2",
            servicename: "Apple Hill",
        },
        {
            id: 3,
            organization:"Valorum Care",
            servicetype:"XYZ",
            srno: "3",
            servicename: "Southern Hill Hospital",
        },
        {
            id: 4,
            organization:"Valorum Care",
            servicetype:"XYZ",
            srno: "4",
            servicename: "The Riders",

        },
    ]

    return (
        <>
        <div className="container-fluid">
            <div className="row createUserScreen">
                <div className="col-xl-12 col-lg-12">
                    <div className="middle_content" id="middle_content">
                        <div className="row mb-2">
                            <div className="col-6">
                                <a href='/user-management'>
                                <button className="btn btn-white  btn-sm me-2 ">
                                    <i className="fa fa-chevron-left"></i> Back
                                </button>
                                </a>
                            </div>
                            <div className="col-6 text-right"></div>
                        </div>
                    </div>

                    <div className="box-shadow p-0">
                        <div>
                            <div className="box-header">
                                <div className="row align-items-center h-100">
                                    <div className="col-md-4 flex-order-2 py-2">
                                        <div className=" ">
                                            <button className="btn btn-sm btn-secondary ms-2">Clear</button>
                                            <button className="btn btn-sm btn-primary ms-2">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="administrator-content userContentCols">
                            <div className="row">
                                <div className='col-md-6'>
                                    <div className='box-border'>
                                        <div className='userHeader modal-title-header mb-3 border-bottom p-3'>
                                            <h3 className='mb-0'>User Detail</h3>
                                        </div>
                                        <div className='row m-0'>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="d-flex mb-3 flex-column">
                                                    <label className="bold mb-2">Name*</label>
                                                    <input type="text" name="name" className="form-control white" value="" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="d-flex mb-3 flex-column">
                                                    <label className="bold mb-2">Designation</label>
                                                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                                        <SmartSearchDropdownContainer
                                                            smartSearchLabel={'Select Designation'}
                                                            defaultValue={"Helllo"}
                                                            onChangeSmartSearch={(value: any) => {
                                                                const event = new Event('change');
                                                                let obj = {
                                                                    name: 'messageCode',
                                                                    value: value?.id
                                                                };
                                                            }}
                                                            smartSearch={"Role"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="d-flex mb-3 flex-column">
                                                    <label className="bold mb-2">Username*</label>
                                                    <input type="text" name="name-email" className="form-control white "  value="" />
                                                </div>
                                            </div>
                                            
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="d-flex mb-3 flex-column">
                                                    <label className="bold mb-2">Email*</label>
                                                    <input type="text" name="name-email" className="form-control white " value="" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="d-flex mb-3 flex-column">
                                                    <label className="bold mb-2">Contact No</label>
                                                    <input type="text" name="name-email" className="form-control white " value="" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="d-flex mb-3 flex-column">
                                                    <label className="bold mb-2">Status</label>
                                                    <div className="d-flex align-items-center">
                                                        <label className="form--radio-label mt-2 d-flex align-items-center me-3">
                                                            <input name="status" type="radio" id="active" value="active" checked />
                                                            <span className="ms-1">Active</span>
                                                        </label>
                                                        <label className="form--radio-label mt-2 d-flex align-items-center">
                                                            <input name="status" type="radio" id="inactive" value="inactive" />
                                                            <span className="ms-1">Inactive</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='box-border'>
                                        <div className='userHeader modal-title-header mb-3 border-bottom p-3'>
                                            <h3 className='mb-0'>Assign Roles and Services</h3>
                                        </div>
                                        <div className='row m-0'>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="d-flex mb-3 flex-column">
                                                    <label className="bold mb-2">Role</label>
                                                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                                        <SmartSearchDropdownContainer
                                                            smartSearchLabel={'Select Role'}
                                                            defaultValue={"Helllo"}
                                                            onChangeSmartSearch={(value: any) => {
                                                                const event = new Event('change');
                                                                let obj = {
                                                                    name: 'messageCode',
                                                                    value: value?.id
                                                                };
                                                            }}
                                                            smartSearch={"Organization"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="d-flex mb-3 flex-column">
                                                    <label className="bold mb-2">Select Service*</label>
                                                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                                        <SmartSearchDropdownContainer
                                                            smartSearchLabel={'Select Service Type*'}
                                                            defaultValue={"Helllo"}
                                                            onChangeSmartSearch={(value: any) => {
                                                                const event = new Event('change');
                                                                let obj = {
                                                                    name: 'messageCode',
                                                                    value: value?.id
                                                                };
                                                            }}
                                                            smartSearch={"Designation"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="d-flex mb-3 flex-column">
                                                    <label className="bold mb-2">Default Service</label>
                                                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                                        <SmartSearchDropdownContainer
                                                            smartSearchLabel={'Select Service'}
                                                            defaultValue={"Helllo"}
                                                            onChangeSmartSearch={(value: any) => {
                                                                const event = new Event('change');
                                                                let obj = {
                                                                    name: 'messageCode',
                                                                    value: value?.id
                                                                };
                                                            }}
                                                            smartSearch={"Role"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="d-flex mb-3 flex-column">
                                                    <label className="bold mb-2">Module*</label>
                                                    <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                                        <SmartSearchDropdownContainer
                                                            smartSearchLabel={'Select Module'}
                                                            defaultValue={"Helllo"}
                                                            onChangeSmartSearch={(value: any) => {
                                                                const event = new Event('change');
                                                                let obj = {
                                                                    name: 'messageCode',
                                                                    value: value?.id
                                                                };
                                                            }}
                                                            smartSearch={"Role"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className="col-xl-12 col-lg-12">
                                    <div className="row">
                                        <div>
                                            <label className="bold mt-2 mb-2">Selected Services List</label>
                                            <div className="position-relative mb-2">
                                                <input type="text" placeholder="Search" name="leadSmartSearch" className="form-control white search-invoice" />
                                                <i className="fa-sharp fa-solid fa-magnifying-glass search-icon"></i>
                                            </div>
                                        </div>
                                        <div className="col-xl-8">
                                            <div className="services-list-table ">
                                                <MaterialReactTable
                                                    columns={columns}
                                                    data={Data}
                                                    enableGrouping
                                                    enableColumnActions={false}

                                                    enableBottomToolbar={false}
                                                    enableTopToolbar={false}
                                                    enableColumnDragging={false}
                                                    // getRowId={(row) => row.integrationId}
                                                    manualPagination
                                                    manualSorting
                                                    // rowCount={rowCount}
                                                    localization={{ actions: 'Actions' }}
                                                    enableRowActions
                                                    positionActionsColumn="last"
                                                    renderRowActions={({
                                                        row
                                                    }) => <div className='d-flex'>
                                                            <a className="status-icon justify-content-center">
                                                                <i className="fa-solid fa-trash danger-text"></i>
                                                            </a>
                                                        </div>
                                                    }
                                                />
                                                <p className='text-right cursor-pointer mt-2 bolder'>Add New Row</p>
                                            </div>
                                            <div className="row align-items-center invoice-table-pagination border-0">
                                                <div className="col-lg-6">
                                                    <div className="d-flex align-items-center">
                                                        <p className="purple-text bold">
                                                            Displaying 1 - 2 of 10
                                                        </p>
                                                        <div className="d-flex align-items-center ms-3">
                                                            <label className="mb-0 bold nowrap me-2">Page Size:</label>
                                                            <select className={`form-control white page-select`} id="supportedPersoIntegrationPageSize">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 ">
                                                    <PaginationContainer currentPage={1} totalCount={100} pageSize={10} onPageChange={(page: any) => { }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="administrator-content pt-0 define-access">
                            <div className="row">
                                <div className='col-xl-12 col-lg-12'>
                                    <div className="modal-title-header mb-4">
                                        <h3>Define Access</h3>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="d-flex mb-3 flex-column">
                                        <div className='d-flex align-items-center border-bottom mb-2'>
                                            <label className="bold pb-2 me-3">General</label>
                                            <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                                <SmartSearchDropdownContainer
                                                    className="w-50"
                                                    smartSearchLabel={''}
                                                    defaultValue={"Helllo"}
                                                    onChangeSmartSearch={(value: any) => {
                                                        const event = new Event('change');
                                                        let obj = {
                                                            name: 'messageCode',
                                                            value: value?.id
                                                        };
                                                    }}
                                                    smartSearch={"Designation"}
                                                />
                                            </div>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="log_note" className="me-2" /> Log Note</label>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-3 flex-column">
                                        <div className='d-flex align-items-center border-bottom mb-2'>
                                            <label className="bold pb-2 me-3">Billing Management</label>
                                            <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                                <SmartSearchDropdownContainer
                                                    smartSearchLabel={''}
                                                    defaultValue={"Helllo"}
                                                    onChangeSmartSearch={(value: any) => {
                                                        const event = new Event('change');
                                                        let obj = {
                                                            name: 'messageCode',
                                                            value: value?.id
                                                        };
                                                    }}
                                                    smartSearch={"Designation"}
                                                />
                                            </div>
                                        </div>

                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="Posted" className="me-2" />Edit Posted Invoice</label>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="Start/Stop" className="me-2" />Start/Stop Invoice</label>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="Print" className="me-2" />Send & Print Invoice</label>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-3 flex-column">
                                        <div className='d-flex align-items-center border-bottom mb-2'>
                                            <label className="bold pb-2 me-3">Reports</label>
                                            <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                                <SmartSearchDropdownContainer
                                                    smartSearchLabel={''}
                                                    defaultValue={"Helllo"}
                                                    onChangeSmartSearch={(value: any) => {
                                                        const event = new Event('change');
                                                        let obj = {
                                                            name: 'messageCode',
                                                            value: value?.id
                                                        };
                                                    }}
                                                    smartSearch={"Designation"}
                                                />
                                            </div>
                                        </div>

                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="Transaction" className="me-2" /> Patient Transaction History Report</label>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="Detail" className="me-2" /> Patient Detail Report</label>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="Bed" className="me-2 mb-2" />Current Bed Status with Pricing</label>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="History" className="me-2 mb-2" />Bed Transaction History Report</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="d-flex mb-3 flex-column">
                                        <div className='d-flex align-items-center border-bottom mb-2'>
                                            <label className="bold pb-2 me-3">Administrator</label>
                                            <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                                <SmartSearchDropdownContainer
                                                    smartSearchLabel={''}
                                                    defaultValue={"Helllo"}
                                                    onChangeSmartSearch={(value: any) => {
                                                        const event = new Event('change');
                                                        let obj = {
                                                            name: 'messageCode',
                                                            value: value?.id
                                                        };
                                                    }}
                                                    smartSearch={"Designation"}
                                                />
                                            </div>
                                        </div>

                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="log_note" className="me-2" /> User Management</label>
                                        </div>
                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="log_note" className="me-2" /> Integration Interface</label>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-3 flex-column">
                                        <div className='d-flex align-items-center border-bottom mb-2'>
                                            <label className="bold pb-2 me-3">Referral Management</label>
                                            <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                                <SmartSearchDropdownContainer
                                                    smartSearchLabel={''}
                                                    defaultValue={"Helllo"}
                                                    onChangeSmartSearch={(value: any) => {
                                                        const event = new Event('change');
                                                        let obj = {
                                                            name: 'messageCode',
                                                            value: value?.id
                                                        };
                                                    }}
                                                    smartSearch={"Designation"}
                                                />
                                            </div>
                                        </div>

                                        <div className='mb-2'>
                                            <label className='d-flex'><input type="checkbox" name="Transaction" className="me-2" /> Send & Print RT Costing</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        </>
    );
};

export default CreateUserView;