    import React from 'react';
import ResetPasswordView from './ResetPasswordView';
    
    const ResetPasswordContainter = () => {
        return (
            <div>
                <ResetPasswordView/>
            </div>
        );
    };
    
    export default ResetPasswordContainter;