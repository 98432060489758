import React, {useState, useEffect} from 'react';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { LeadFilterReferralStatus, LeadFilterServiceType, LeadFilterServiceUserType } from '../../../../../utils/constant';

const ServiceReferralTableFilterView = (props: any) => {
    const { t } = useTranslation();

    const { StatusGridFilterState, DataGridFilterState } = useSelector((state: RootState) => state.gridFilterState);

    let [isAssignedDate, setIsAssignedDate] = useState<any>(false);

    let [assignedDateToValue, setAssignedDateToValue] = useState<any>('');
    let [assignedDateFromValue, setAssignedDateFromValue] = useState<any>('');

    let [openChecked, setOpenChecked] = useState<any>(false);
    let [closedChecked, setClosedChecked] = useState<any>(false);
    let [D2AChecked, setD2AChecked] = useState<any>(false);
    let [respiteChecked, setRespiteChecked] = useState<any>(false);
    let [longTermChecked, setLongTermChecked] = useState<any>(false);

    const eventHandler = (event: any) => {
        event.stopPropagation();
    };

    const onCreationDateSubmitHandler = () => {
        if(assignedDateToValue !== '' && assignedDateFromValue !== ''){
            setIsAssignedDate(true);
            let data = {
                isAssignedDate: true,
                assignedDateTo: dayjs(assignedDateToValue).format('YYYY-MM-DD'),
                assignedDateFrom: dayjs(assignedDateFromValue).format('YYYY-MM-DD')
            }
            props.changeDatePayloadHandler(data)
        }
    };

    const onChangeCreationDateSubmitHandler = (e: any) => {
        setIsAssignedDate(false);
        setAssignedDateToValue('');
        setAssignedDateFromValue('');
        let data = {
            isAssignedDate: false
        }
        props.changeDatePayloadHandler(data)
    };

    useEffect(() => {
        if(StatusGridFilterState) {
            if(DataGridFilterState?.filter?.isAssignedDate === true && DataGridFilterState?.filter?.assignedDateTo !== '' && DataGridFilterState?.filter?.assignedDateFrom !== '') {
                setIsAssignedDate(DataGridFilterState?.filter?.isAssignedDate);
                setAssignedDateToValue(dayjs(DataGridFilterState?.filter?.assignedDateTo));
                setAssignedDateFromValue(dayjs(DataGridFilterState?.filter?.assignedDateFrom));
            }else{
                setIsAssignedDate(false);
                setAssignedDateToValue('');
                setAssignedDateFromValue('');
            }

            if(DataGridFilterState?.filter?.referralStatus?.length > 0) {
                if(DataGridFilterState?.filter?.referralStatus.includes(LeadFilterReferralStatus.Open)) {
                    setOpenChecked(true)
                } else {
                    setOpenChecked(false)
                }
                if(DataGridFilterState?.filter?.referralStatus.includes(LeadFilterReferralStatus.Closed)) {
                    setClosedChecked(true)
                } else {
                    setClosedChecked(false)
                }
            } else {
                setOpenChecked(false)
                setClosedChecked(false)
            }

            if(DataGridFilterState?.filter?.serviceUserType.length > 0) {
                if(DataGridFilterState?.filter?.serviceUserType.includes('D2A')) {
                    setD2AChecked(true)
                } else {
                    setD2AChecked(false)
                }
                if(DataGridFilterState?.filter?.serviceUserType.includes('Respite')) {
                    setRespiteChecked(true)
                } else {
                    setRespiteChecked(false)
                }
                if(DataGridFilterState?.filter?.serviceUserType.includes('Long Term')) {
                    setLongTermChecked(true)
                } else {
                    setLongTermChecked(false)
                }
            } else {
                setD2AChecked(false)
                setRespiteChecked(false)
                setLongTermChecked(false)
            }
        }
    },[StatusGridFilterState, DataGridFilterState])
    const IsFilterApplied = () => {
        if(DataGridFilterState?.filter?.referralStatus?.length > 0 || DataGridFilterState?.filter?.serviceUserType.length > 0 || DataGridFilterState?.filter?.isAssignedDate === true && DataGridFilterState?.filter?.assignedDateTo !== '' && DataGridFilterState?.filter?.assignedDateFrom !== '' ){
            return true
        }
        return false
     }
    return (
        <div>
             <div className="dropdown filter-dropdown me-2">
                <a className={`nav-link dropdown-toggle`} href="#" id="navbarDropdownMenuLink5" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="legend-label" data-tooltip-id="my-tooltip" data-tooltip-content={`Filter`}>
                        <i className="fa-solid fa-filter"></i>
                    </div>
                    <Tooltip id="my-tooltip" className="my-tooltip" />
                </a>
                <div className="dropdown-menu profile-dw-css" onClick={eventHandler} aria-labelledby="navbarDropdownMenuLink">
                <div className="dropdown sub-dropdown">
                        <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdownMenuLink1"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <label>
                                <input type="checkbox" name="isAssignedDate" checked={isAssignedDate} onChange={(e: any) => onChangeCreationDateSubmitHandler(e)} className='me-1' /> {t('ServiceReferralDetail.Assigned_Date')}<i className="fa-solid fa-caret-right ms-auto"></i>
                            </label>
                        </a>
                        <div className="dropdown-menu dropdown-submenu dropdown-submenu-custom" aria-labelledby="navbarDropdownMenuLink1">
                            <div className="d-flex date-filter">
                            <div className="datepicker-input-css me-3">
                                    <p className=" mb-1">{t('ReferralTable.From')}</p>
                                    <DatePicker onChange={(date: any) => setAssignedDateFromValue(date)} value={assignedDateFromValue} className="form-control white" label="" format="DD/MM/YYYY" views={['year', 'month', 'day']} slotProps={{actionBar: { actions: ['clear','today'] },}} />
                                </div>
                                <div className="datepicker-input-css  ">
                                    <p className=" mb-1">{t('ReferralTable.To')}</p>
                                    <DatePicker onChange={(date: any) => setAssignedDateToValue(date)} value={assignedDateToValue} className="form-control white" label="" format="DD/MM/YYYY" views={['year', 'month', 'day']} slotProps={{actionBar: { actions: ['clear','today'] },}} />
                                </div>
                               
                            </div>
                            <div className="d-flex justify-content-center">
                                <a onClick={onCreationDateSubmitHandler} className="btn btn-primary btn-sm mt-3 me-2">
                                {t('ReferralTable.Submit_btn')} 
                                </a>
                                <a onClick={onChangeCreationDateSubmitHandler} className="btn btn-secondary btn-sm mt-3">
                                {t('ReferralTable.Clear_btn')} 
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="border-bottom mt-1 mb-1"></div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value={LeadFilterReferralStatus.Open} name="open" checked={openChecked}  className="me-1" /> {t('ReferralTable.Open')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value={LeadFilterReferralStatus.Closed} name="closed" checked={closedChecked} className="me-1" /> {t('ReferralTable.Closed')}
                        </label>
                    </div>
                    <div className="border-bottom mt-1 mb-1"></div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value="D2A" name="D2A" checked={D2AChecked}  className="me-1" /> {t('ReferralTable.D2A')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value="Respite" name="respite" checked={respiteChecked} className="me-1" /> {t('ReferralTable.Respite')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" onChange={(event: any) => props.changePayloadHandler(event)} value="Long Term" name="longTerm" checked={longTermChecked} className="me-1" /> {t('ReferralTable.Long_Term')}
                        </label>
                    </div>                                                       
                </div>
            </div>
        </div>
    );
};

export default ServiceReferralTableFilterView;