import React from 'react'
import SearchServiceClaimView from './SearchServiceClaimView'

const SearchServiceClaimContainer = (props: any) => {
    return (
        <div>
            <SearchServiceClaimView searchValue={props.searchValue} />
        </div>
    )
}

export default SearchServiceClaimContainer