import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

const TableManageColumnsView = (props: any) => {
    const { t } = useTranslation();
    let [columns, setColumns] = useState<any>({
        DaysElapsed: false,
        LastAction: false,
        ClientNhsId: false,
        PatientType_Name: false,
        ReferralSourceName: false,
        CreatedBy: false,
    });

    const onChangeColumnHandler = (event: any) => {
        if(event.target.checked === true && event.target.name === 'DaysElapsed'){
            setColumns((state: any)=> ({
                ...state,
                DaysElapsed: true
             }));
            let obj = {
                ColumnName: "DaysElapsed",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'DaysElapsed') {
            setColumns((state: any)=> ({
                ...state,
                DaysElapsed: false
             }));
            let obj = {
                ColumnName: "DaysElapsed",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'LastAction'){
            setColumns((state: any)=> ({
                ...state,
                LastAction: true
             }));
            let obj = {
                ColumnName: "LastAction",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'LastAction') {
            setColumns((state: any)=> ({
                ...state,
                LastAction: false
             }));
            let obj = {
                ColumnName: "LastAction",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'ClientNhsId'){
            setColumns((state: any)=> ({
                ...state,
                ClientNhsId: true
             }));
            let obj = {
                ColumnName: "ClientNhsId",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'ClientNhsId') {
            setColumns((state: any)=> ({
                ...state,
                ClientNhsId: false
             }));
            let obj = {
                ColumnName: "ClientNhsId",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'PatientType_Name'){
            setColumns((state: any)=> ({
                ...state,
                PatientType_Name: true
             }));
            let obj = {
                ColumnName: "PatientType_Name",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'PatientType_Name') {
            setColumns((state: any)=> ({
                ...state,
                PatientType_Name: false
             }));
            let obj = {
                ColumnName: "PatientType_Name",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'ReferralSourceName'){
            setColumns((state: any)=> ({
                ...state,
                ReferralSourceName: true
             }));
            let obj = {
                ColumnName: "ReferralSourceName",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'ReferralSourceName') {
            setColumns((state: any)=> ({
                ...state,
                ReferralSourceName: false
             }));
            let obj = {
                ColumnName: "ReferralSourceName",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'CreatedBy'){
            setColumns((state: any)=> ({
                ...state,
                CreatedBy: true
             }));
            let obj = {
                ColumnName: "CreatedBy",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'CreatedBy') {
            setColumns((state: any)=> ({
                ...state,
                CreatedBy: false
             }));
            let obj = {
                ColumnName: "CreatedBy",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'EstDateOfArrival'){
            setColumns((state: any)=> ({
                ...state,
                EstDateOfArrival: true
             }));
            let obj = {
                ColumnName: "EstDateOfArrival",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'EstDateOfArrival') {
            setColumns((state: any)=> ({
                ...state,
                EstDateOfArrival: false
             }));
            let obj = {
                ColumnName: "EstDateOfArrival",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

       
    };

    useEffect(() => {
        if (props.managedColumnsList.length > 0) {
            props.managedColumnsList.map((val: any) => {
                if(val.columnName === "DaysElapsed") {
                    setColumns((state: any)=> ({
                        ...state,
                        DaysElapsed: true
                     }));
                }
        
                if(val.columnName === "LastAction") {
                    setColumns((state: any)=> ({
                        ...state,
                        LastAction: true
                     }));
                }
        
                if(val.columnName === "ClientNhsId") {
                    setColumns((state: any)=> ({
                        ...state,
                        ClientNhsId: true
                     }));
                }
        
                if(val.columnName === "PatientType_Name") {
                    setColumns((state: any)=> ({
                        ...state,
                        PatientType_Name: true
                     }));
                }
        
                if(val.columnName === "ReferralSourceName") {
                    setColumns((state: any)=> ({
                        ...state,
                        ReferralSourceName: true
                     }));
                }
        
                if(val.columnName === "CreatedBy") {
                    setColumns((state: any)=> ({
                        ...state,
                        CreatedBy: true
                     }));
                }

                if(val.columnName === "EstDateOfArrival") {
                    setColumns((state: any)=> ({
                        ...state,
                        EstDateOfArrival: true
                     }));
                }
        
            })
        } 
    }, [props.managedColumnsList]);

    

    const eventHandler = (event: any) => {
        event.stopPropagation();
    };
    return (
        <div>
            <div className="dropdown filter-dropdown me-2">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="legend-label" data-tooltip-id="my-tooltip" data-tooltip-content={`Manage Columns`}>
                        <i className="fa fa-cog"></i>
                    </div>
                    <Tooltip id="my-tooltip" className="my-tooltip" />
                </a>
                <div className="dropdown-menu dropdown-menu-columns" onClick={eventHandler} aria-labelledby="navbarDropdownMenuLink">
                    <div className="row">
                        <div className="col-md-6">
                            <div>
                                <label>
                                    <input type="checkbox" name="DaysElapsed" checked={columns.DaysElapsed} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('SMTableManageColumns.Days_Elapsed')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="LastAction" checked={columns.LastAction} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('SMTableManageColumns.Last_Action')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="ClientNhsId" checked={columns.ClientNhsId} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('SMTableManageColumns.NHS_ID')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="PatientType_Name" checked={columns.PatientType_Name} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('SMTableManageColumns.Service_User_Type')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="ReferralSourceName" checked={columns.ReferralSourceName} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('SMTableManageColumns.Contact_Details')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="CreatedBy" checked={columns.CreatedBy} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('SMTableManageColumns.Created_By')}
                                </label>
                            </div>
                            <div>
                               
                            </div>                            
                        </div>
                        <div className="col-md-6">
                        <div>
                                <label>
                                    <input type="checkbox" name="EstDateOfArrival" checked={columns.EstDateOfArrival} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('RMTableManageColumns.Est_Date_Arrival')}
                                </label>
                            </div>
                            <div>
                               
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableManageColumnsView;
