import React from 'react';
import CreateAccessRoleView from './CreateAccessRoleView';

const CreateAccessRoleContainer = () => {
    return (
        <div>
            <CreateAccessRoleView/>
        </div>
    );
};

export default CreateAccessRoleContainer;