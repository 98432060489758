'use client';
import imageCompression from 'browser-image-compression';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { ChangeEvent, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
// import OwlCarousel from 'react-owl-carousel';
import swal from 'sweetalert';
import arrowRight from '../../../assets/images/arrow-right.png';
import warning from '../../../assets/images/warning.png';
import LoaderContainer from '../../../components/Loader/loaderContainer';
import { deleteOnboardingGalleryImage, getOnboardingServiceImages, reorderOnboardingGalleryImages, uploadOnboardingGalleryImage } from '../../../service/onboarding-gallery';
import { MAX_FILE_SIZE_IN_MB, MAX_FILES } from '../../../utils/constant';
import DraggableItem from './DraggableItem';
import DroppableImageComponent from './DroppableImageComponent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Tooltip } from 'react-tooltip';
import { Box, CircularProgress, Typography } from '@mui/material';
import CircularProgressBarContainer from '../../../components/CircularProgressBar/CircularProgressbarContainer';
import { setOnboardingPageValidity } from '../../../store/onboarding/onboardingPageValidityReducer';

export interface GalleryItem {
    imageId: number;
    imagePath: string;
    imageName: string;
    order: number;
}

export interface TopImagesData {
    imageId: number;
    imagePath: string;
    imageName: string;
    order: number;
    isTopImage: boolean | null;
    topOrderIndex?: number;
}

export interface CategoryImagesData {
    serviceDetailImageCategoryId: number;
    categoryName: string;
    categoryImageUrl: string;
    serviceImage: GalleryItem[];
}

export interface Data {
    serviceDetailImageCategories: CategoryImagesData[];
    topImages: TopImagesData[];
}

export interface GalleryResponse {
    status: boolean;
    message: string | null;
    data: Data;
    totalCount: number;
}

const GalleryView = React.forwardRef((props, ref) => {
    useEffect(() => {
        AOS.init();
    }, []);
    const { t } = useTranslation();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);
    const dispatch = useDispatch();

    const image1 = useRef<any>();
    const image2 = useRef<any>();
    const image3 = useRef<any>();
    const image4 = useRef<any>();
    const image5 = useRef<any>();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<GalleryResponse | null>(null);
    const [items, setItems] = useState<GalleryItem[][]>([]);
    const [category, setCategory] = useState<{ id: number; index: number } | null>({ id: 1, index: 0 });
    const [files, setFiles] = useState<File[]>([]);
    const [isValid, setIsValid] = useState(false);
    const [activeTab, setActiveTab] = useState('Pool'.toLowerCase().replace(/\s+/g, '-'));
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const settings = {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 2,
        arrows: true,
        adaptiveHeight: true,
        draggable: false,
    };

    const handleTabClick = (tabId: any, category: { id: number; index: number } | null) => {
        setActiveTab(tabId);
        setCategory(category);
    };

    const getTabClass = (tabId: any) => (tabId === activeTab ? 'nav-link active' : 'nav-link');

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const moveItemInNestedArray = (subArrayIndex: number, fromIndex: number, toIndex: number): void => {
        const newArray = items.map((subArray) => [...subArray]);
        const subArray = newArray[subArrayIndex];
        const [movedItem] = subArray.splice(fromIndex, 1);
        subArray.splice(toIndex, 0, movedItem);

        setItems([...newArray]);
    };

    const addItemToNestedArray = (subArrayIndex: number, newItem: GalleryItem): void => {
        setItems((prevItems) => {
            const newArray = prevItems.map((subArray) => [...subArray]);
            const subArray = newArray[subArrayIndex];
            subArray.push(newItem);
            return newArray;
        });
    };

    const deleteItemFromNestedArray = (subArrayIndex: number, itemIndex: number): void => {
        swal({
            title: '',
            text: t('OnboardingGallery.Delete_Image_Warning')!,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                setIsLoading(true);
                const res = await deleteOnboardingGalleryImage(items[subArrayIndex][itemIndex].imageId);
                if (res.status === true) {
                    const newArray = items.map((subArray) => [...subArray]);
                    const subArray = newArray[subArrayIndex];
                    subArray.splice(itemIndex, 1);
                    setItems([...newArray]);
                    setIsLoading(false);
                    showWarning(t('OnboardingGallery.Delete_Image_Success_Message')!, true);
                } else {
                    setIsLoading(false);
                    showWarning(t('OnboardingGallery.Delete_Image_Error_Warning')!);
                }
            }
        });
    };

    const getKebabCaseString = (str: string) => {
        return str.toLowerCase().replace(/\s+/g, '-');
    };

    const showWarning = (message: string, success?: boolean) => {
        swal({
            title: '',
            text: message,
            icon: success ? 'success' : warning,
            dangerMode: true
        });
    };

    const handleTopImageSwap = (from: number, image: any) => {
        const updatedImage = image ? { ...image, topIndex: from } : null;

        const imageRefs = [image1, image2, image3, image4, image5];
        const targetImageRef = imageRefs[from - 1];

        if (targetImageRef?.current) {
            targetImageRef.current.setImage(updatedImage);
        }
    };

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>, id: number, index: number) => {
        const selectedFiles = event.target.files;
        if (!selectedFiles) return;

        const fileArray = Array.from(selectedFiles);
        const totalFilesSelected = fileArray.length + items.flat().length;

        let compressedFiles: File[] = [];
        let errorMessage: string | null = null;

        if (totalFilesSelected > MAX_FILES) {
            errorMessage = `You can only upload up to ${MAX_FILES} images.`;
            showWarning(errorMessage);
            setError(errorMessage);
            return;
        }

        const options = {
            maxSizeMB: MAX_FILE_SIZE_IN_MB,
            useWebWorker: true
        };

        try {
            for (const file of fileArray) {
                const compressedBlob = await imageCompression(file, options);
                const compressedFile = new File([compressedBlob], file.name, { type: file.type });
                compressedFiles.push(compressedFile);
            }
        } catch (error) {
            errorMessage = 'An error occurred while compressing the images.';
            showWarning(errorMessage);
            setError(errorMessage);
            return;
        }

        setFiles([...compressedFiles]);
        setError(null);
    };

    const handleUpload = async () => {
        if (files.length === 0) return;
        if (category === undefined) return;
        try {
            setIsImageUploading(true);        
            setProgress(10);
            setTimeout(() => setProgress(20), 500);
            setTimeout(() => setProgress(40), 1000);
            setTimeout(() => setProgress(60), 1500);
            setTimeout(() => setProgress(80), 2000);
            setTimeout(() => setProgress(90), 2500);
            setTimeout(async () => {
                for (const file of files) {
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('categoryId', category?.id + '');
                    formData.append('serviceId', serviceId! + '');
                    formData.append('orderIndex', items[category!.index].length + '');
    
                    const response = await uploadOnboardingGalleryImage(formData);
    
                    if (response.status === true) {
                        //setTimeout(() => setProgress(90), 500);
                        setProgress(100);
                        addItemToNestedArray(category!.index, {
                            imagePath: response.data.imageUrl,
                            imageId: response.data.imageId,
                            imageName: response.data.imagePath.split('/').pop(),
                            order: response.data.orderIndex
                        });
                    }
                }
    
                setFiles([]);
                setIsImageUploading(false);
            }, 2500);
        } catch (error) {
            setFiles([]);
            setIsImageUploading(false);
        }
    };

    const handleReorder = (payload: any, isPreview?: boolean) => {
        const reorderImages = async () => {
            setIsLoading(true);
            const response = await reorderOnboardingGalleryImages(payload);
            if (response.status === false) {
                showWarning(response.message);
            } else {
                if (isPreview) {
                    showWarning(t('OnboardingGallery.Reorder_Success_Message')!, true);
                }
            }
            setIsLoading(false);
        };
        reorderImages();
    };

    const generateReorderPayload = () => {
        const topImage1 = image1.current ? (image1.current.image ? { ImageId: image1.current.image.id, OrderIndex: 0 } : undefined) : undefined;
        const topImage2 = image2.current ? (image2.current.image ? { ImageId: image2.current.image.id, OrderIndex: 1 } : undefined) : undefined;
        const topImage3 = image3.current ? (image3.current.image ? { ImageId: image3.current.image.id, OrderIndex: 2 } : undefined) : undefined;
        const topImage4 = image4.current ? (image4.current.image ? { ImageId: image4.current.image.id, OrderIndex: 3 } : undefined) : undefined;
        const topImage5 = image5.current ? (image5.current.image ? { ImageId: image5.current.image.id, OrderIndex: 4 } : undefined) : undefined;

        let arr: { ImageId: number; OrderIndex: number }[] = [];
        items.forEach((item) => {
            item.forEach((innerItem, innerIndex) => {
                arr.push({ ImageId: innerItem.imageId, OrderIndex: innerIndex });
            });
        });

        return {
            topImages: [topImage1, topImage2, topImage3, topImage4, topImage5].filter((image) => image !== undefined),
            lowerSectionImages: arr
        };
    };

    const generateDynamicStyles = (categoryTabs: string[]) => {
        const windowWidth = window.innerWidth;
        const tabWidth = windowWidth > 1200 ? 153 : 127

        const tabs = [...categoryTabs];
        tabs.unshift('all');

        const style = document.createElement('style');
        style.id = 'dynamic-tab-styles';
        let dynamicStyles = '';

        const activeTabIndex = tabs.findIndex(tab => tab.toLowerCase().replace(/\s+/g, '-') === activeTab);
        const initialTranslateValue = activeTabIndex !== -1 ? activeTabIndex * tabWidth : 0;

        tabs.forEach((_, index) => {
            const translateValue = index * tabWidth;
            dynamicStyles += `
                .group-tabs.glinderActive${index + 1} .slick-track:before {
                    transform: translateX(${translateValue}px);
                    transition: all ease 0.7s;
                }
            `;
        });

        dynamicStyles += `
            .group-tabs .slick-track:before {
                transform: translateX(${initialTranslateValue}px);
                transition: all ease 0.7s;
            }
        `;

        style.innerHTML = dynamicStyles;
        document.head.appendChild(style);
    };

    const handleTabSliding = (tabId: string) => {
        if (data) {
            const tabs = data.data.serviceDetailImageCategories.map((item) => {
                return item.categoryName
            })
            tabs.unshift("all")
            const tabIndex = tabs.findIndex((item) => (getKebabCaseString(item) === tabId));
            const glider = document.querySelector('.group-tabs');

            if (tabIndex !== -1) {
                glider?.classList.remove(...Array.from({ length: tabs.length }, (_, i) => `glinderActive${i + 1}`));
                glider?.classList.add(`glinderActive${tabIndex + 1}`);
            }
        }
    };

    useImperativeHandle(ref, () => ({
        isValidated: isValid,
        backActionHandler: (isPreview?: boolean) => {
            handleReorder(generateReorderPayload(), isPreview);
        },
        nextActionHandler: (isPreview?: boolean) => {
            handleReorder(generateReorderPayload(), isPreview);
        },
        triggerValidation: async () => {
            if (!isValid) {
                showWarning(t('OnboardingGallery.Validation_Warning')!);
            }
            return isValid;
        }
    }));

    useEffect(() => {
        if (data && data.data?.serviceDetailImageCategories?.length > 0) {
            generateDynamicStyles(data.data.serviceDetailImageCategories.map((item) => {
                return item.categoryName
            }));
            setTimeout(() => {
                handleTabClick(activeTab, {...category!});
            }, 1000);
        }
    }, [window.innerWidth])

    useEffect(()=>{
        dispatch(setOnboardingPageValidity(isValid))
    }, [isValid])

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const response = await getOnboardingServiceImages(serviceId!);
            if (response.status === true) {
                setData(response);
            }
            setIsLoading(false);
        };
        getData();
    }, []);

    useEffect(() => {
        if (data && data.data?.serviceDetailImageCategories?.length > 0) {
            setActiveTab(data?.data?.serviceDetailImageCategories[0]?.categoryName.toLowerCase().replace(/\s+/g, '-'))
            setCategory({id: data?.data?.serviceDetailImageCategories[0]?.serviceDetailImageCategoryId, index: 0});
            const arr = data.data?.serviceDetailImageCategories?.map((item: CategoryImagesData, index: number) => {
                return item.serviceImage;
            });
            setItems(arr);

            data.data?.topImages.forEach((item, index) => {
                const img = {
                    id: item.imageId,
                    index: item.isTopImage ? item.topOrderIndex : item.order,
                    imagePath: item.imagePath,
                    imageName: item.imageName,
                    topIndex: index + 1
                };

                const imageRefs = [image1, image2, image3, image4, image5];
                const targetIndex = item.isTopImage ? item.topOrderIndex! : item.order;

                if (imageRefs[targetIndex]?.current) {
                    imageRefs[targetIndex].current.setImage(img);
                }
            });
            generateDynamicStyles(data.data.serviceDetailImageCategories.map((item) => {
                return item.categoryName
            }));
        }
        return () => {
            const styleElement = document.getElementById('dynamic-tab-styles');
            if (styleElement) {
                styleElement.remove();
            }
        };
    }, [data]);

    useEffect(() => {
        const totalItems = items.flat();
        if (totalItems.length >= 5 && totalItems.length <= MAX_FILES) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [items]);

    useEffect(() => {
        if (files.length > 0 && files.length <= MAX_FILES) handleUpload();
    }, [files]);

    useEffect(() => {
        handleTabSliding(activeTab);
    }, [activeTab]);
    return (
        <DndProvider backend={HTML5Backend}>
            {isLoading && <LoaderContainer />}
            
            <div className="gallery-grid m-0 service-preview-gallery-edit pb-115">
                <div className="d-flex">
                    <div className="gallery-instruction-part">
                        {items.flat().length > 0 && (
                            <div className="d-flex align-items-center justify-content-center text-center h-100" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="2000" data-aos-once="true">
                                <div className="hanzipen-font info-block">
                                    <p className="mb-5">
                                        2. <br /> Now choose the top 5 images that best represent your facility.{' '}
                                    </p>
                                    <p>These images will be prominently displayed on your main profile page to give viewers a great first impression. Simply drag & Drop here from below.</p>
                                </div>
                                <div className="arrow-img img-arrow-block">
                                    <img src={arrowRight} alt="arrow" className="img-fluid" width={50} />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="gallery-layout-part">
                        <div className="gallery-item">
                            <div className="gallery-image-collage service-image-collage">
                                <div className="d-flex position-relative">
                                    <div className="placeholder-left">
                                        <div className="image-placeholder-1 no-hover big-collage-img bg-image">
                                            <DroppableImageComponent swapImages={handleTopImageSwap} topIndex={1} ref={image1} />
                                        </div>
                                    </div>
                                    <div className="placeholder-middle">
                                        <div className="image-placeholder-2 no-hover bg-image">
                                            <DroppableImageComponent swapImages={handleTopImageSwap} topIndex={2} ref={image2} />
                                        </div>
                                        <div className="image-placeholder-3 no-hover bg-image">
                                            <DroppableImageComponent swapImages={handleTopImageSwap} topIndex={3} ref={image3} />
                                        </div>
                                    </div>
                                    <div className="placeholder-right">
                                        <div className="image-placeholder-2 no-hover bg-image">
                                            <DroppableImageComponent swapImages={handleTopImageSwap} topIndex={4} ref={image4} />
                                        </div>
                                        <div className="image-placeholder-3 no-hover bg-image">
                                            <DroppableImageComponent swapImages={handleTopImageSwap} topIndex={5} ref={image5} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex mt-5">
                    <div className="gallery-instruction-part">
                        <div className="d-flex align-items-center justify-content-center text-center h-100" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                            <div className="hanzipen-font info-block">
                                <p className="mb-5">
                                    1. <br /> Select a category and upload high-quality images for all applicable categories.{' '}
                                </p>
                                <p>Your are allowed a total of 25 photo’s.</p>
                            </div>
                            <div className="arrow-img img-arrow-block">
                                <img src={arrowRight} alt="arrow" className="img-fluid" width={50} />
                            </div>
                        </div>
                    </div>
                    <div className="gallery-layout-part">
                        <div className="gallery-item">
                            <div className="gallery-tab">
                                <nav className="mb-5">
                                    <ul className="nav-pills group-tabs mb-2 nav-tabs" id="nav-tab" role="tablist">
                                         <Slider {...settings}>
                                         <div className="nav-item">
                                                <button
                                                    className={getTabClass('all')}
                                                    id="nav-all-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#nav-all"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="nav-all"
                                                    aria-selected={activeTab === 'all'}
                                                    onClick={() => handleTabClick('all', null)}
                                                    disabled={items.flat().length === 0}
                                                >
                                                    All
                                                </button>
                                            </div>
                                            {data?.data?.serviceDetailImageCategories?.map((item, index) => {
                                                const pageLabel = getKebabCaseString(item.categoryName);
                                                return (
                                                    <div className="nav-item" key={item.serviceDetailImageCategoryId}>
                                                        <button
                                                            className={getTabClass(pageLabel)}
                                                            id={`nav-${pageLabel}-tab`}
                                                            data-bs-toggle="tab"
                                                            data-bs-target={`#nav-${pageLabel}`}
                                                            type="button"
                                                            role="tab"
                                                            aria-controls={`nav-${pageLabel}`}
                                                            aria-selected={activeTab === pageLabel}
                                                            onClick={() => handleTabClick(pageLabel, { id: item.serviceDetailImageCategoryId, index })}
                                                        >
                                                            {item.categoryName.length > 11 ? <span data-tooltip-id="long-nav-text" data-tooltip-content={item.categoryName} data-tooltip-place="top">
                                                                {item.categoryName.length > 11 ? `${item.categoryName.slice(0, 10)}..` : item.categoryName}
                                                            </span> : item.categoryName}
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                    </ul>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={`tab-pane fade ${activeTab === 'all' ? 'show active' : ''}`} id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab">
                                        <div className="tab-content">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row thumbnail-imag-container all-image-thumbnails">
                                                        {data?.data.serviceDetailImageCategories?.map((item, index) =>
                                                            items[index]?.map((innerItem, innerIndex) => (
                                                                <DraggableItem
                                                                    subArrayIndex={index}
                                                                    key={innerItem.imageId}
                                                                    index={innerIndex}
                                                                    item={innerItem}
                                                                    moveItem={(subArrayIndex: number, dragIndex: number, hoverIndex: number) => {}}
                                                                    deleteItem={deleteItemFromNestedArray}
                                                                />
                                                            ))
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {data?.data?.serviceDetailImageCategories?.map((item, index) => {
                                        const pageLabel = getKebabCaseString(item.categoryName);
                                        return (
                                            <div
                                                className={`tab-pane fade ${activeTab === pageLabel ? 'show active' : ''}`}
                                                id={`nav-${pageLabel}`}
                                                role="tabpanel"
                                                aria-labelledby={`nav-${pageLabel}-tab`}
                                                key={item.serviceDetailImageCategoryId}
                                            >
                                                <div className="tab-content">
                                                    {
                                                        isImageUploading ? <CircularProgressBarContainer progressValue={progress} />: <div className="row">
                                                        <div className="col-md-2">
                                                            <div className="upload-section justify-content-center d-flex align-items-center" onClick={handleUploadClick}>
                                                                <div className="upload-gallery-img cursor-pointer">
                                                                    <i className="fa-solid fa-cloud-arrow-up mb-2"></i>
                                                                    <p>Upload Photos</p>
                                                                    <input
                                                                        ref={fileInputRef}
                                                                        style={{ display: 'none' }}
                                                                        type="file"
                                                                        accept="image/*"
                                                                        multiple
                                                                        onChange={(e) => {
                                                                            handleFileChange(e, item.serviceDetailImageCategoryId, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-10">
                                                            <div className="row thumbnail-imag-container">
                                                                <DndProvider backend={HTML5Backend}>
                                                                    {items[index]?.map((i, ii) => (
                                                                        <DraggableItem
                                                                            subArrayIndex={index}
                                                                            key={i.imageId}
                                                                            index={ii}
                                                                            item={i}
                                                                            moveItem={moveItemInNestedArray}
                                                                            deleteItem={deleteItemFromNestedArray}
                                                                        />
                                                                    ))}
                                                                </DndProvider>
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
            <Tooltip id="long-nav-text" />
        </DndProvider>
    );
});
export default GalleryView;
