    import React from 'react';
import UserManagementTopbarView from './UserManagementTopbarView';
    
    const UserManagementTopbarContainer = () => {
        return (
            <div>
                <UserManagementTopbarView/>
            </div>
        );
    };
    
    export default UserManagementTopbarContainer;