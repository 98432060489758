import React from 'react';
import ServiceOnboardingPreviewView from './ServiceOnboardingPreviewView';
import OnboardingHeaderContainer from '../../../layout/OnboardingHeader/OnboardingHeaderContainer';

const ServiceOnboardingPreviewContainer = (props: {noHeader?:boolean}) => {

    const showHeader = () => {
        if(props.noHeader){
            return false;
        } else {
            return true;
        }
    }
    
    return (
        <div className="nhs-body">
            {showHeader() &&<OnboardingHeaderContainer manageProfileValidationType='none'/>}
            <ServiceOnboardingPreviewView />
        </div>
    );
};

export default ServiceOnboardingPreviewContainer;