import React, { useState, useEffect } from 'react';
import { setNavigationAction } from '../../../../../store/dashboard/navigationReducer';
import { useDispatch, useSelector } from 'react-redux';
import PaginationContainer from '../../../../../components/PaginationComponent/indexContainer';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import warning from '../../../../../assets/images/warning.png';
import { rightNavigationPage } from '../../../../../utils/constant';
import { RootState } from '../../../../../store';
import { GetPendingReferral, getPendingReferralDetails } from '../../../../../service/lead-patient';
import { removeLeadInformationAction } from '../../../../../store/lead/getLeaddataReducer';
let PageSize = 5;
const ReferralListView = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState<any>('');
    const handlerSearchValue = (e: any) => {
        setSearchValue(e.target.value);
    };
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalCount, setTotalCount] = useState<any>();
    const [listPatient, setLisPatient] = useState<any>();
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);

    const closeSideMenu = () => {
        let obj: any = {
            ShowClass: false,
            FormName: '',
            Data: '',
            ShowToggle: false
        };
        dispatch(setNavigationAction(obj));
    };

    async function getPendingReferraldata(lpid: any) {
        const payload: any = { leadPoolingDetailsId: lpid, serviceTypeId: DataServiceDetail.serviceTypeId };
        await getPendingReferralDetails(payload);
    }

    const handleContinue = (leadPoolingId: number, referral_Id: string, nhsid: string, localid: string) => {

        swal({
            title: '',
            text: `Are you sure you want to process Referral - ${referral_Id} to admission/reservation?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                dispatch(removeLeadInformationAction());
                const data = {
                    isReferralAdd: true,
                    leadPoolingId: leadPoolingId,
                    leadnhsid: nhsid,
                    leadlocalid: localid,
                }
                getPendingReferraldata(leadPoolingId).then((res: any) => {
                    let obj: any = {
                        ShowClass: true,
                        FormName: rightNavigationPage.Vacant,
                        Data: data,
                        ShowToggle: false
                    };
                    dispatch(setNavigationAction(obj));
                });
            }
        });
    };

    const getCurrentPage = (page: any) => {
        setCurrentPage(page);
        if (searchValue !== undefined && searchValue !== '') {
            let data = {
                SearchValue: searchValue,
                page: page
            };
            getPendingReferralList(data);
        } else {
            let data = {
                page: page
            };
            getPendingReferralList(data);
        }
    };

    const getPendingReferralList = async (data: any = '') => {
        let payload = {
            serviceId: DataServiceDetail.serviceId,
            SearchValue: searchValue !== undefined ? searchValue : '',
            PageNo: data.page !== undefined ? data.page : '',
            PageSize: PageSize
        };
        const response = await GetPendingReferral(payload);

        if (response.status === true) {
            setLisPatient(response.data);
            setTotalCount(response.totalCount);
        }
    };

    useEffect(() => {
        async function getReferrals() {
            let data = {
                page: currentPage
            };
            await getPendingReferralList(data);
        }
        getReferrals();
    }, []);
    useEffect(() => {
        if (searchValue !== undefined && searchValue !== '') {
            let data = {
                SearchValue: searchValue,
                page: 1
            };
            setCurrentPage(1);
            getPendingReferralList(data);
        } else {
            let data = {
                page: currentPage
            };
            getPendingReferralList(data);
        }
    }, [searchValue]);

    return (
        <div>
            <button onClick={closeSideMenu} className="close_right_content">
                <i className="fa-solid fa-xmark"></i>{' '}
            </button>
            <h3 className="mb-3">
                {t('Referral_List.Referral_List_Title')} -
                <span className="ms-1 purple-text">{totalCount}</span>
            </h3>
            <div className="search-input position-relative w-100 me-2">
                <input className="form-control white" type="text" placeholder="Search" id="example-search-input" onChange={handlerSearchValue} />
                <i className="fa fa-search"></i>
            </div>
            <div className="right_content-height ">
                <div className="accordion mt-3" id="referrallist">
                    {listPatient !== undefined &&
                        listPatient !== null &&
                        listPatient.length > 0 &&
                        listPatient.map((referral: any, index: any) => {
                            var patientName = referral.patientName
                                .split(' ')
                                .map((part: any[]) => part[0])
                                .join('')
                                .toUpperCase();
                            return (
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id={`heading${index}`}>
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse` + index}
                                            aria-expanded="true"
                                            aria-controls={`collapse` + index}
                                        >
                                            <span className="user-name">{patientName}</span>
                                            <p className="patient-name">{referral.patientName}</p>
                                        </button>
                                    </h2>
                                    <div
                                        id={`collapse` + index}
                                        className={`accordion-collapse collapse ${index == 0 ? 'show' : ''}`}
                                        aria-labelledby={`heading${index}`}
                                        data-bs-parent="#referrallist"
                                    >
                                        <div className="accordion-body">
                                            <p className="mb-1">
                                                {t('Referral_List.NHS_ID')} : <span className="bold">{referral.nhS_Id}</span>{' '}
                                            </p>
                                            <p className="mb-1">
                                                {t('Referral_List.Client_ID')} : <span className="bold">{referral.local_Id}</span>{' '}
                                            </p>
                                            <p className="mb-1">
                                                {t('Referral_List.Referral_ID')} : <span className="bold">{referral.referral_Id}</span>{' '}
                                            </p>
                                            <p className="mb-1">
                                                {t('Referral_List.Service_User_Type')} : <span className="bold">{referral.serviceUserType}</span>{' '}
                                            </p>
                                            <p className="mb-1">
                                                {t('Referral_List.Referral_Date')} : <span className="bold">{referral.referralDate}</span>{' '}
                                            </p>
                                            <p className="mb-1">
                                                {t('Referral_List.Referred_Since')} : <span className="bold">{referral.referredSince}</span>{' '}
                                            </p>
                                            <div className="text-center mt-2">
                                                {DataServiceDetail?.iqviaServiceEnabled === false && (
                                                    <a className="btn btn-primary btn-sm" onClick={() => handleContinue(referral.leadPoolingId, referral.referral_Id, referral.nhS_Id, referral.local_Id)}>
                                                        {t('Referral_List.Continue_btn')}{' '}
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            {currentPage > 0 && totalCount > 0 && PageSize > 0 && (
                <PaginationContainer currentPage={currentPage} totalCount={totalCount} pageSize={PageSize} onPageChange={(page: any) => getCurrentPage(page)} />
            )}
        </div>
    );
};

export default ReferralListView;