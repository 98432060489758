import React from 'react';
import ProductManagementBottomView from './ProductManagementBottomView';

const ProductManagementBottomContainer = () => {
    return (
        <div>
            <ProductManagementBottomView/>
        </div>
    );
};

export default ProductManagementBottomContainer;