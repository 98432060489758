import React from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { BASEURLDEV, API_ENDPOINT } from '../../../../../../service/ApiConstants';
import { RootState, store } from '../../../../../../store';
import swal from 'sweetalert';
import warning from '../../../../../../assets/images/warning.png';
import { deletePictureDocument, getPenDocument } from '../../../../../../service/leads';
import { useSelector } from 'react-redux';
import LoaderContainer from '../../../../../../components/Loader/loaderContainer';
import eventEmitter from '../../../../../../utils/eventEmitter';
import { Tooltip } from 'react-tooltip';
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const validationSchema = Yup.object().shape({
    desc: Yup.string().required(),
});

const PenPictureView = (props: any) => {
    const { register, control, handleSubmit, reset, getValues, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const state = store.getState();
    const token = state.login.DataLogin.jwtToken;

    const [files, setFiles] = useState<any>([]);

    const [isError, setIsError] = useState<string | null>(null);

    const { ShowClass, FormName, Data } = useSelector((state: RootState) => state.navigation);

    const { DataLeadInfo } = useSelector((state: RootState) => state.leadInformation);

    useEffect(() => {
        if (props.pendata?.data?.penPictureDetailModel !== undefined) {
            setValue('desc', props.pendata?.data?.penPictureDetailModel?.description, { shouldValidate: true });

            if (props?.pendata?.data?.penPictureDetailModel?.listPenPictureList && props?.pendata?.data?.penPictureDetailModel?.listPenPictureList?.length > 0) {
                setFiles(props?.pendata?.data?.penPictureDetailModel?.listPenPictureList?.map((item: any) => ({
                    filepath: item.documentPath,
                    filename: item.documentName
                })));
            }
        }
    }, [props.pendata])

    useEffect(() => {
        
    }, [props.userdata]);

    const removepdf = (e: any, path: any) => {
        e.preventDefault();
        swal({
            title: '',
            text: 'Are you sure you want to remove the attachment?',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                const payload = { path: path, leadId: DataLeadInfo?.data?.patientDetail?.leadId || "" };
                const response = await deletePictureDocument(payload);
                if (response.status == true) {
                    setFiles((o: any) => o.filter((v: any) => v.filepath !== path));
                    props.deleteFData(path);

                } else {
                }
            }
        });
    };
    const openpdf = async (e: any, path: any) => {
        e.preventDefault();
        const payload = { path };
        const response = await getPenDocument(payload);
        if (response.status === true) {
            let alink = document.createElement('a');
            alink.target = '_blank';
            alink.href = response.data;
            alink.click();
        }
    };

    useEffect(() => {
        
        if (formState.isValid) {
            props.getpendataerror(true);
        }
        else {
            props.getpendataerror(false);
        }

    }, [formState])

    useEffect(() => {
        if (files.length <= 5) {
            setIsError(null)
            eventEmitter.emit('eventSetLoader', false);
        }
    }, [files])

    return (
        <div>
            <textarea
                {...register('desc', {
                    onChange: (e: any) => {
                        props.updateData(e);
                    }
                })}
                readOnly={((Data?.fleadstatus === 1 && DataLeadInfo === '') || (DataLeadInfo?.data?.leadEditable === true)) ? false : true}
                name={'desc' as const}
                className={`form-control white mb-3 ${errors.desc ? 'is-invalid' : ''}`}
                placeholder="Description*"
                rows={4}
            ></textarea>
            <div className="d-flex" style={{ pointerEvents: ((props.userdata?.name != '' && props.userdata?.name != undefined) || (DataLeadInfo?.data?.leadEditable === true)) ? 'all' : 'none' }}>
                <div className="w-100">
                    {files?.map((file: any) => (
                        <div className="d-flex justify-content-between  mt-2 mb-2" >
                            <div className="input-readonly" data-tooltip-id="my-tooltip-pen" data-tooltip-content="PenPicture Document ">
                                <textarea rows={1} value={file.filename} onClick={(e: any) => openpdf(e, file.filepath)} readOnly />
                            </div>
                            <div style={{ pointerEvents: ((Data?.fleadstatus === 1 && DataLeadInfo === '') || (DataLeadInfo?.data?.leadEditable === true)) ? 'all' : 'none' }}>
                            <button className="btn-icon red-background" name="btndelete" onClick={(e: any) => removepdf(e, file.filepath)}>
                                <i className="fa-solid fa-trash"></i>
                            </button>
                            </div>
                            <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-pen" />
                        </div>
                    ))}
                    <>
                        <div style={{ pointerEvents: ((Data?.fleadstatus === 1 && DataLeadInfo === '') || (DataLeadInfo?.data?.leadEditable === true)) ? 'all' : 'none' }} id="penPictureUpload">
                            <Controller
                                control={control}
                                name="PenPictureDocument"
                                render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                    return (
                                        <FilePond
                                            files={files?.length <= 5 && files?.map((item: any) => ({
                                                source: item.filename,
                                                options: {
                                                    type: 'local',
                                                    file: {
                                                        name: item.filename,
                                                        size: 0,
                                                    }
                                                }
                                            }))}
                                            // @ts-ignore
                                            allowFileSizeValidation={true}
                                            maxFileSize={1000 * 1000 * 5}
                                            allowFileTypeValidation={true}
                                            acceptedFileTypes={[
                                                'application/pdf',
                                                'image/*',
                                                'application/vnd.ms-excel',
                                                'application/vnd.ms-powerpoint',
                                                'application/msword',
                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                            ]}
                                            labelMaxFileSizeExceeded={'Total size of all the attachments cannot exceed 5 MB'}
                                            fileValidateTypeLabelExpectedTypes={''}
                                            name={'PenPictureDocument'}
                                            labelIdle={`${t('VacantRightbar.pdftitle3')}<span class="filepond--label-action"> ${t('VacantRightbar.pdftitle2')}</span>`}
                                            allowMultiple
                                            maxFiles={5}
                                            maxParallelUploads={5}
                                            beforeAddFile={() => {
                                                eventEmitter.emit('eventSetLoader', true);
                                                return true;
                                            }}
                                            // @ts-ignore
                                            onerror={(props) => setIsError(props.main)}

                                            onwarning={(error) => {
                                                if (error.body === "Max files") {
                                                    setIsError("Maximum only 5 pen picture attachments are allowed");
                                                }
                                            }}

                                            server={{
                                                url: `${BASEURLDEV}${API_ENDPOINT.UploadPenDoc}`,
                                                process: {
                                                    headers: {
                                                        Authorization: `Bearer ${token}`
                                                    },
                                                    ondata: (formData) => {
                                                        formData.append('patientName', props.userdata.name)
                                                        return formData;
                                                    },
                                                    //@ts-ignore
                                                    onload: (res: any) => {
                                                        if (files?.length === 5) {
                                                            setIsError("Maximum only 5 pen picture attachments are allowed");
                                                            eventEmitter.emit('eventSetLoader', false);
                                                            return;
                                                        }
                                                        
                                                        const fileres = JSON.parse(res);
                                                        let filedata = fileres.data;
                                                        setFiles((o: any) => [
                                                            ...o,
                                                            {
                                                                filepath: filedata.documentPath,
                                                                filename: filedata.documentName,
                                                            }
                                                        ]);
                                                        props.updateFData({
                                                            leadPenPicture_Id: filedata.leadPenPicture_Id,
                                                            filepath: filedata.documentPath,
                                                            filename: filedata.documentName,
                                                        });

                                                        setIsError(null);
                                                    }
                                                },
                                                remove: (source, load, error) => {
                                                    files?.length <= 5 && setIsError(null)
                                                    error('oh my goodness');
                                                    eventEmitter.emit('eventSetLoader', false);
                                                    load();
                                                }
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                        {isError && <p className='text-danger ps-1'>{isError}</p>}
                        <p className="small mt-2">
                            <strong>{t('VacantRightbar.note')}</strong> {t('VacantRightbar.note3')}
                        </p>
                    </>
                </div>
            </div>
        </div>
    );
};

export default PenPictureView;
