import React from 'react';
import { useTranslation } from 'react-i18next';
import SmartSearchDropdownContainer from '../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import cardbuilder1 from '../../assets/images/service1.png';
import cardbuilder2 from '../../assets/images/service-1.png';
import cardbuilder3 from '../../assets/images/service2.png';
import cardbuilder4 from '../../assets/images/service3.png';
import PaginationContainer from '../../components/PaginationComponent/indexContainer';

const ServiceCardsView = () => {
    return (
        <div>
            <div className="service-title text-center">Service Cards</div>
            <div className="box-shadow new-box-shadow">
                <div className=" service-cards-sticky">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex flex-column">
                                <label className="bold mb-2">Region</label>
                                <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                    <SmartSearchDropdownContainer
                                        smartSearchLabel={'Region'}
                                        defaultValue={'Helllo'}
                                        onChangeSmartSearch={(value: any) => {
                                            const event = new Event('change');
                                            let obj = {
                                                name: 'messageCode',
                                                value: value?.id
                                            };
                                        }}
                                        smartSearch={'Organization'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-column">
                                <label className="bold mb-2">Specialty</label>
                                <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                    <SmartSearchDropdownContainer
                                        smartSearchLabel={'Specialty'}
                                        defaultValue={'Helllo'}
                                        onChangeSmartSearch={(value: any) => {
                                            const event = new Event('change');
                                            let obj = {
                                                name: 'messageCode',
                                                value: value?.id
                                            };
                                        }}
                                        smartSearch={'Service'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-column">
                                <label className="bold mb-2">Visit Type</label>
                                <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                    <SmartSearchDropdownContainer
                                        smartSearchLabel={'Visit Type'}
                                        defaultValue={'Helllo'}
                                        onChangeSmartSearch={(value: any) => {
                                            const event = new Event('change');
                                            let obj = {
                                                name: 'messageCode',
                                                value: value?.id
                                            };
                                        }}
                                        smartSearch={'Designation'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-column">
                                <label className="bold mb-2">Section</label>
                                <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                    <SmartSearchDropdownContainer
                                        smartSearchLabel={'Search by Role'}
                                        defaultValue={'Helllo'}
                                        onChangeSmartSearch={(value: any) => {
                                            const event = new Event('change');
                                            let obj = {
                                                name: 'messageCode',
                                                value: value?.id
                                            };
                                        }}
                                        smartSearch={'Role'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex flex-column">
                                <label className="bold mb-2">Category</label>
                                <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                    <SmartSearchDropdownContainer
                                        smartSearchLabel={'Category'}
                                        defaultValue={'Helllo'}
                                        onChangeSmartSearch={(value: any) => {
                                            const event = new Event('change');
                                            let obj = {
                                                name: 'messageCode',
                                                value: value?.id
                                            };
                                        }}
                                        smartSearch={'Role'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="d-flex align-items-center justify-content-center">
                                <button className="btn btn-secondary me-2">Clear</button>
                                <button className="btn btn-primary me-2">Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row row-cols-5">
                    <div className="col">
                        <div className="onboarding-service-image onboarding-servicebuilder d-flex align-items-center justify-content-center mt-3">
                            <a href="/createservicecards">
                                <button className="selectob-btn orange-bg bold">
                                    <span>Add Services</span>
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="col">
                        <div className="onboarding-service-image">
                            <img src={cardbuilder1} alt="" className="warning-logo-error w-100" />
                            <div className="onboarding-services-details">
                                <div className="onboarding-service-content">
                                    <h5>Medical Condition</h5>
                                    <p>Service provides support and relief to family caregivers experiencing physical and emotional fatigue.</p>
                                </div>
                            </div>
                            <div className="onboarding-add-serc-btn">
                                <button className="trans-button bold">
                                    <span>Mental Health</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="onboarding-service-image ">
                            <img src={cardbuilder2} alt="" className="warning-logo-error w-100" />
                            <div className="onboarding-services-details">
                                <div className="onboarding-service-content">
                                    <h5>End of Life Care</h5>
                                    <p>Service provides support and relief to family caregivers experiencing physical and emotional fatigue.</p>
                                </div>
                            </div>
                            <div className="onboarding-add-serc-btn">
                                <button className="trans-button bold">
                                    <span>Mental Health</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="onboarding-service-image">
                            <img src={cardbuilder3} alt="" className="warning-logo-error w-100" />
                            <div className="onboarding-services-details">
                                <div className="onboarding-service-content">
                                    <h5>Anxiety Disorder</h5>
                                    <p>Service provides support and relief to family caregivers experiencing physical and emotional fatigue.</p>
                                </div>
                            </div>
                            <div className="onboarding-add-serc-btn">
                                <button className="trans-button bold">
                                    <span>Mental Health</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="onboarding-service-image">
                            <img src={cardbuilder4} alt="" className="warning-logo-error w-100" />
                            <div className="onboarding-services-details">
                                <div className="onboarding-service-content onboarding-service-content-black">
                                    <h5>High Risk of Relapse</h5>
                                    <p>Service provides support and relief to family caregivers experiencing physical and emotional fatigue.</p>
                                </div>
                            </div>
                            <div className="onboarding-add-serc-btn">
                                <button className="trans-button bold">
                                    <span>Mental Health</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="onboarding-service-image mt-3">
                            <img src={cardbuilder1} alt="" className="warning-logo-error w-100" />
                            <div className="onboarding-services-details">
                                <div className="onboarding-service-content">
                                    <h5>Advanced Age</h5>
                                    <p>Service provides support and relief to family caregivers experiencing physical and emotional fatigue.</p>
                                </div>
                            </div>
                            <div className="onboarding-add-serc-btn">
                                <button className="trans-button bold">
                                    <span>Mental Health</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="onboarding-service-image mt-3">
                            <img src={cardbuilder2} alt="" className="warning-logo-error w-100" />
                            <div className="onboarding-services-details">
                                <div className="onboarding-service-content">
                                    <h5>Psychiatric Ward</h5>
                                    <p>Service provides support and relief to family caregivers experiencing physical and emotional fatigue.</p>
                                </div>
                            </div>
                            <div className="onboarding-add-serc-btn">
                                <button className="trans-button bold">
                                    <span>Mental Health</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="onboarding-service-image mt-3">
                            <img src={cardbuilder3} alt="" className="warning-logo-error w-100" />
                            <div className="onboarding-services-details">
                                <div className="onboarding-service-content">
                                    <h5>Bipolar Disorders</h5>
                                    <p>Service provides support and relief to family caregivers experiencing physical and emotional fatigue.</p>
                                </div>
                            </div>
                            <div className="onboarding-add-serc-btn">
                                <button className="trans-button bold">
                                    <span>Mental Health</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="onboarding-service-image mt-3">
                            <img src={cardbuilder4} alt="" className="warning-logo-error w-100" />
                            <div className="onboarding-services-details">
                                <div className="onboarding-service-content onboarding-service-content-black">
                                    <h5>Mid-Stage Dementia</h5>
                                    <p>Service provides support and relief to family caregivers experiencing physical and emotional fatigue.</p>
                                </div>
                            </div>
                            <div className="onboarding-add-serc-btn">
                                <button className="trans-button bold">
                                    <span>Mental Health</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="onboarding-service-image mt-3">
                            <img src={cardbuilder1} alt="" className="warning-logo-error w-100" />
                            <div className="onboarding-services-details">
                                <div className="onboarding-service-content">
                                    <h5>Substance Use Issues</h5>
                                    <p>Service provides support and relief to family caregivers experiencing physical and emotional fatigue.</p>
                                </div>
                            </div>
                            <div className="onboarding-add-serc-btn">
                                <button className="trans-button bold">
                                    <span>Mental Health</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="administrator-content">
                    <div className="row align-items-center mt-3 invoice-table-pagination">
                        <div className="col-lg-6">
                            <div className="d-flex align-items-center">
                                <p className="purple-text bold">Displaying 1 - 2 of 10</p>
                                <div className="d-flex align-items-center ms-3">
                                    <label className="mb-0 bold nowrap me-2">Page Size:</label>
                                    <select className={`form-control white page-select`} id="supportedPersoIntegrationPageSize">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <PaginationContainer currentPage={1} totalCount={100} pageSize={10} onPageChange={(page: any) => {}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceCardsView;
