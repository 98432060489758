import React from 'react';
import { useTranslation } from 'react-i18next';
import SmartSearchDropdownContainer from '../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';

const UserManagementTopbarView = () => {
    const { t } = useTranslation();
    return (
            <div className="row">
                            <div className="col-xl-9 col-lg-12">
                                <div className="d-flex flex-wrap">
                                    <div>
                                        <a href='/create-user'>
                                            <button className='btn btn-primary btn-create me-2 mb-3'>
                                                <i className="fa-solid fa-plus"></i> Create User
                                            </button>
                                        </a>
                                    </div>
                                    <div className='me-2 position-relative'>
                                        <input type="text" placeholder='Search' name="leadSmartSearch"  className='form-control white search-invoice' />
                                        <i className="fa-sharp fa-solid fa-magnifying-glass search-icon"></i>
                                    </div>
                                    <div>
                                        <div className="dropdown filter-dropdown me-2">
                                            <a className={`nav-link dropdown-toggle`} href="#" id="navbarDropdownMenuLink5" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="">
                                                    <i className="fa-solid fa-filter"></i> Filter
                                                </div>
                                            </a>
                                            <div className="dropdown-menu profile-dw-css" aria-labelledby="navbarDropdownMenuLink">
                                                <div>
                                                    <label>
                                                        Status
                                                    </label>
                                                </div>
                                                <div className="border-bottom mt-1 mb-1"></div>
                                                <div>
                                                    <label>
                                                        <input type="checkbox" value="" name="open" className="me-1" /> Active
                                                    </label>
                                                </div>
                                                <div>
                                                    <label>
                                                        <input type="checkbox" value="" name="open" className="me-1" /> Inactive
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="me-2 organization-dropdown">
                                        <div>
                                            <div id="rdts2" className="react-dropdown-tree-select">
                                                <div className="dropdown">
                                                    <a className="dropdown-trigger arrow bottom" id="rdts2_trigger" role="button" aria-haspopup="tree" aria-expanded="false">
                                                        <ul className="tag-list">
                                                            <li className="tag-item">
                                                                <span className="placeholder">Select Services</span>
                                                            </li>
                                                        </ul>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <a href="#" className="btn btn-white bold " data-tooltip-id="my-tooltip-export" data-tooltip-content="Export Referrals">
                                            <i className="fa-sharp fa-solid fa-download"></i> Export
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
    );
};

export default UserManagementTopbarView;