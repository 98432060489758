import { useSelector } from "react-redux";
import ModalWrapperContainer from "../ModalWrapper/indexContainer";
import { RootState } from "../../store";
import { useEffect, useState } from "react";
import { t } from "i18next";
import { Autocomplete, Checkbox, TextField, createFilterOptions } from "@mui/material";
import { ExportAdmission } from "../../service/dashboard-service";
import { Controller, useForm } from "react-hook-form";
import { ExportOdooIntegration } from '../../service/integration-service';
import { Source } from '../../utils/constant';
import { SubmitHandler } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ExportReferrals } from "../../service/leads";
type ExportAdmissionPayload = {
   serviceId : any;
   organizationId : number 
};
type ExportReferralPayload = {
  serviceId : any;
};

type ExportIntegrationPayload = {
  serviceId : any;  
  dateFrom: any;
  dateTo: any;
  reference: string;
  messageCode: string;
  status: any;  
};

const ExportReportsModalView = (props: any) => {

  
    const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { DataOrganizationDetail } = useSelector((state: RootState) => state.organizationDetail);
    const { DataLogin } = useSelector((state: RootState) => state.login);
    const [services,setServices] = useState<any>([])
    const [serviceId,setServiceId] = useState<any>([]);
    const [exportAdmissionPayload, setExportAdmissionPayload] = useState<ExportAdmissionPayload>({
             serviceId : [],
             organizationId : DataOrganizationDetail.organizationId,
    });

    const [exportReferralPayload, setExportReferralPayload] = useState<ExportReferralPayload>({
      serviceId : [],
});

    const validationSchema = Yup.object().shape({
      exporttype: Yup.string().required()
  });

    const {
      register,
      handleSubmit,
      reset,
      setValue,
      control,
      formState: { errors, isValid }
  } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });

    const [exportIntegrationPayload, setExportIntegrationPayload] = useState<ExportIntegrationPayload>({
      serviceId : [],      
      dateFrom: props?.Integration?.dateFrom,
      dateTo: props?.Integration?.dateTo,
      reference: props?.Integration?.reference,
      messageCode: props?.Integration?.messageCode,
      status: props?.Integration?.status,      
    });

    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const [allChecked, setAllChecked] = useState<any>(false);
    const [exportvalue, setexportvalue] = useState<any>();
  
    const allSelected = services.length === selectedOptions.length;
  
    const handleToggleOption = (selectedOp:any) =>{
        setSelectedOptions(selectedOp);
    } 
        
    const handleClearOptions = () => {
        setSelectedOptions([]);
        setServiceId([])
        setAllChecked(false)
    }
  
    const handleSelectAll = (isSelected:any) => {
      if (isSelected) {
        setSelectedOptions(services);
      } else {
        handleClearOptions();
      }
    };
  
    const handleToggleSelectAll = () => {
      handleSelectAll && handleSelectAll(!allSelected);
    };
  
    const handleChange = (event:any, selectedOps:any, reason:any) => {
      if (reason === "selectOption" || reason === "removeOption") {
        if (selectedOps.find((option:any) => option.label === "Select all")) {
          handleToggleSelectAll();
          const result = services.filter((el:any) => el.label !== "Select all");
          setSelectedOptions(result)
          setServiceId(result.map((opt:any)=> opt.value))
          setAllChecked(true)
          if(selectedOps.find((option:any) => option.label === "Select all") && allSelected == true){
            setAllChecked(false)
            setServiceId([]);
            setSelectedOptions([]);
          }
        } else {
          handleToggleOption && handleToggleOption(selectedOps);
          setServiceId(selectedOps.map((i:any)=>i.value))  
        }
      } else if (reason === "clear") {
        handleClearOptions && handleClearOptions();
      }
    };
  
    const filter = createFilterOptions();

    let serviceOptions: any = [];

    useEffect(()=>{
        let serviceObj = DataLogin.userServiceModels.map((ser: any) => ser.servicesList).flat();
        serviceOptions = [];
        for (let i = 0; i < serviceObj.length; i++) {
            serviceOptions.push({ value: serviceObj[i].serviceId, label: serviceObj[i].serviceName });
        }
        let opt = serviceOptions;
        setServices(opt);
        setValue('exporttype','admissions');
        setexportvalue(1);

    },[])

    useEffect(()=>{      
      if(props?.source == Source.Integration)
      {
        let tempExportIntegrationPayload: any = exportIntegrationPayload;
        tempExportIntegrationPayload.serviceId = serviceId;
        setExportIntegrationPayload(tempExportIntegrationPayload);
      }else if(props?.source == Source.Admission){
        let tempExportAdmissionPayload: any = exportAdmissionPayload;
        tempExportAdmissionPayload.serviceId = serviceId;
        setExportAdmissionPayload(tempExportAdmissionPayload);
      } else if(props?.source == Source.Referral){
        let tempExportAdmissionPayload: any = exportReferralPayload;
        tempExportAdmissionPayload.serviceId = serviceId;
        setExportReferralPayload(tempExportAdmissionPayload);
      }
        
    },[serviceId])

    useEffect(()=>{
      let tempExportAdmissionPayload: any = exportAdmissionPayload;
      tempExportAdmissionPayload.exportType = Number(exportvalue);
        setExportAdmissionPayload(tempExportAdmissionPayload);
    },[exportvalue])


    const handleRadioChange = (e: any) => {
      let value = e.target.value;
      if (value === 'admissions') {
        setexportvalue(1);
      } else {
        setexportvalue(2);
      }
  };

    const onClickHandlerAdmission = async () => {
      var response;
      if(props?.source == Source.Integration)
      {
        response = await ExportOdooIntegration(exportIntegrationPayload);
      }else if(props?.source == Source.Admission){
        response = await ExportAdmission(exportAdmissionPayload);
      } else if(props?.source == Source.Referral){
        response = await ExportReferrals(exportReferralPayload);
      }
        
        let mediaType = response.data.fileType;
        let useInput = response.data.csvData;
        let tempLink = document.createElement('a');
        tempLink.href = mediaType + useInput;
        tempLink.setAttribute('download', `${response.data.fileName}.xlsx`);
        tempLink.click();
        props.onClose();
    };

    return (
        <div>
            <ModalWrapperContainer
                title={props?.title}
                onClose={props.onClose}
                className="modal modal-extrasmall exportreportModal"
                content={
                  <>
                 <div className=" mb-2">
                    <h3 className="mb-2 report-modal-heading">Select Export Options</h3>
                    <div className="row">
                      <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                        <input type="radio" className="me-2" name="exportSelect" defaultChecked/>
                        <label>Export Selected Columns</label>
                      </div>
                      <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                        <input type="radio" className="me-2" name="exportSelect" />
                        <label>Export All Columns</label>
                      </div>

                      <div className="selection-group mb-2 mt-3 col-md-12">
                        <h3 className="mb-2 report-modal-heading">Export As</h3>
                        <select className="form-control">
                          <option>Excel</option>
                          <option>CSV</option>
                        </select>
                      </div>

                      {/* <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                        <input type="radio" className="me-2"/>
                        <label>Export CSV</label>
                      </div>
                      <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                        <input type="radio" className="me-2"/>
                        <label>Excel for all reports</label>
                      </div> */}
                    </div>
                 </div>
                    
                </>
                }
                footer = {
                  <div className="d-flex align-items-center ms-2">
                    <button className='btn primary-btn me-2'>Submit</button>
                  </div>
                }
            />
        </div>
    );
}

export default ExportReportsModalView;