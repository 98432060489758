import React, {useState, useEffect} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Tooltip } from 'react-tooltip';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import SmartSearchDropdownContainer from '../../../../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { API_ENDPOINT, BASEURLDEV } from '../../../../../../service/ApiConstants';
import { ReasonList } from '../../../../../../utils/constant';
import eventEmitter from '../../../../../../utils/eventEmitter';

const DischargeTabDetail = (props: any) => {
    const { t } = useTranslation();
    const [disableButton, setDisableButton] = useState<any>(false);
    const [dischargeDateValue, setdischargeDateValue] = useState<any>(null);
    let [reasonSmartSearch, setReasonSmartSearch] = useState<any>(undefined);
    const validationSchema = Yup.object().shape({
        dischargeDate: Yup.date().required('Discharge date is required'),
        financialDischarge: Yup.boolean().oneOf([true], 'Financial discharge is required'),
        clinicallyDischarge: Yup.boolean().oneOf([true], 'Clinically discharge is required'),
        reason_Id: Yup.string().required('Reason is required'),
        remark: Yup.string().notRequired()
    });
    const [dtype, setdtype] = useState<any>('');
    const { register, handleSubmit, reset, resetField, control, formState: { errors, isValid }, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });

    const onSubmitHandler: SubmitHandler<any> = (data) => {
        props.onSubmitHandler(data);
    };
    const disableMin = props.patientData !== undefined ? dayjs(props.patientData?.admissionDetails?.admissionDate) : '';

    useEffect(() => {
        eventEmitter.on('eventSmartSearch', eventSmartSearch);
        return () => {
            eventEmitter.off('eventSmartSearch', eventSmartSearch);
        };
    });

    const eventSmartSearch = () =>{
        setValue('reason_Id','',{shouldValidate: true});
        setReasonSmartSearch(undefined);
      
    }

    useEffect(() => {
        setdischargeDateValue(null)
        reset();
        setdtype('');
    }, [props.patientData]);

    useEffect(() => {
        if(isValid) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }
    }, [isValid]);

    useEffect(() => {
       if(reasonSmartSearch === undefined){
        let objReasonSmartSearch = {
            url: `${BASEURLDEV}${API_ENDPOINT.GetDDLReasons}/${ReasonList.Discharge}`
        };
        setReasonSmartSearch(objReasonSmartSearch);
       }
    }, [reasonSmartSearch]);
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="input-group date  mb-3 datepicker-input-css" id="datepicker">
                    <Controller
                        control={control}
                        name="dischargeDate"
                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                            return <DatePicker value={dischargeDateValue} className={`form-control white ${error ? 'is-invalid' : ''}`} label="Discharge Date*" format="DD/MM/YYYY" {...fieldProps} minDate={disableMin} onChange={(date: any) => {props.updateData(date);setdischargeDateValue(date);fieldProps.onChange(date);}} views={['year', 'month', 'day']} slotProps={{actionBar: { actions: ['clear','today'] },}} disableFuture />;
                        }}
                    />
            </div>
            <div className="form-check me-2">
                <input {...register('financialDischarge', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })} className={`form-check-input ${errors.financialDischarge ? 'is-invalid' : ''}`} type="checkbox" value="" id="flexCheckDefault" />
                <label className="form-check-label">{t('OccupiedRightBar.Financial_Discharge')}</label>
                <a href="#" className='ms-1' data-tooltip-id="my-tooltip1" data-tooltip-content={`"Financial Discharge" indicates the completion of all financial obligations related to a patient/service user’s services. It signifies that all charges for treatments, medications, tests, and other services have been settled. It ensures that the service user’s account balance is reviewed, and all necessary payments, including Funding and personal payments, have been processed."`}><i className="fa-solid fa-circle-info"></i></a>
            </div>
            <div className="form-check">
                <input {...register('clinicallyDischarge', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })} className={`form-check-input ${errors.clinicallyDischarge ? 'is-invalid' : ''}`} type="checkbox" value="" id="flexCheckDefault" />
                <label className="form-check-label">{t('OccupiedRightBar.Clinically_Discharge')}</label>
                <a href="#" className='ms-1' data-tooltip-id="my-tooltip1" data-tooltip-content={`"Clinical Discharge" indicates that the treatment and care for the patient/service user have been completed. It represents the conclusion of the clinical aspect of the stay, signifying that the patient/service user is ready to transition out of the provider setting."`}><i className="fa-solid fa-circle-info"></i></a>
            </div>
            {/* <select {...register('reason_Id', {
                        onChange: (e: any) => {
                            setdtype(e.target.value);
                            props.updateData(e);
                        }
                    })} value={dtype} id="" className={`form-control mt-2 white mb-2 ${errors.reason_Id ? 'is-invalid' : ''}`}>
            <option disabled={true} value="">Select Reason*</option>
                {
                    props.listReasons !== undefined
                    && props.listReasons !== null
                    && props.listReasons !== ''
                    && props.listReasons.length > 0
                    && props.listReasons.map((val: any) => {
                        return (<option key={val.reasonId} value={val.reasonId}>{val.reasonText}</option>)
                    })
                }

            </select> */}
            {reasonSmartSearch !== undefined && (
                <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                    <SmartSearchDropdownContainer
                        smartSearchLabel="Select Reason*"
                        errors={errors.reason_Id}
                        onChangeSmartSearch={(value: any) => {
                            const event = new Event('change');
                            let obj = {
                                name: 'reason_Id',
                                value: value?.id?.toString()
                            };
                            //@ts-ignore
                            Object.defineProperty(event, 'target', { writable: false, value: obj });
                            setValue('reason_Id', value?.id?.toString(), { shouldValidate: true });
                            setdtype(value?.id);
                            if (value !== '') {
                                props.updateData(event);
                            }
                        }}
                        smartSearch={reasonSmartSearch}
                    />
                </div>
            )}
            <textarea {...register('remark', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })} placeholder="Remark" className={`form-control white`}></textarea>
            <div className="mt-2 text-center right_content_btn">
                {/* <a href="#" className="btn btn-primary flex-1 me-2" id="save-funder1">
                    {t('OccupiedRightBar.Discharge_btn')}
                </a> */}
                <button type="submit" disabled={!disableButton} className="btn btn-primary  flex-1 me-2">
                {t('OccupiedRightBar.Discharge_btn')}
                </button>
            </div>
            </form>
            <Tooltip id="my-tooltip1" className="my-tooltip tooltip-custom-width" />
        </div>
    );
};

export default DischargeTabDetail;
