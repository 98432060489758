import React from 'react';
import ProductManagementTopbarContainer from './ProductManagementTopbar/ProductManagementTopbarContainer';
import ProductManagementBottomContainer from './ProductManagementBottom/ProductManagementBottomContainer';

const ProductManagementView = () => {
    return (
        <div>
            {/* <ProductManagementTopbarContainer/> */}
            <ProductManagementBottomContainer/>
        </div>
    );
};

export default ProductManagementView;