import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react'

function TimeDifferenceView({ timestamp, verificationStatus, approvedOn, reopenedOn, rejectedOn, isReOpen }: any) {
    const [timeDiff, setTimeDiff] = useState<string>('');
    const [localTimeZone, setLocalTimeZone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);

    useEffect(() => {
        const calculateTimeDiff = () => {
            const currentTime = moment.utc();
            let pastTime = moment.utc(timestamp, "DD/MM/YYYY hh:mm:ssA");
            const approvedTime = moment.utc(approvedOn, "DD/MM/YYYY hh:mm:ssA")
            const reopenTime = moment.utc(reopenedOn, "DD/MM/YYYY hh:mm:ssA")
            const rejectTime = moment.utc(rejectedOn, "DD/MM/YYYY hh:mm:ssA")

            if (!pastTime.isValid()) {
                setTimeDiff('Invalid date');
                return;
            }

            let targetedTime = currentTime;
            if (approvedOn) {
                if (reopenedOn) {
                    pastTime = reopenTime
                    targetedTime = approvedTime;
                }
                targetedTime = approvedTime;
            } else if (rejectedOn) {
                if (reopenedOn) {
                    pastTime = reopenTime
                    targetedTime = rejectTime;
                }
                targetedTime = rejectTime;
            } else if (reopenedOn) {
                targetedTime = currentTime;
                pastTime = reopenTime;
            }

            const years = targetedTime.diff(pastTime, 'years');
            const months = targetedTime.diff(pastTime.add(years, 'years'), 'months');
            const days = targetedTime.diff(pastTime.add(months, 'months'), 'days');
            const hours = targetedTime.diff(pastTime.add(days, 'days'), 'hours');
            const minutes = targetedTime.diff(pastTime.add(hours, 'hours'), 'minutes');
            const seconds = targetedTime.diff(pastTime.add(minutes, 'minutes'), 'seconds');

            let displayTime: string;

            if (years > 0) {
                displayTime = `${years}Y`;
            } else if (months > 0) {
                displayTime = `${months}M: ${days}D`;
            } else if (days > 0) {
                displayTime = `${days}D: ${hours}H`;
            } else if (hours > 0) {
                displayTime = `${hours}H: ${minutes}M`;
            } else if (minutes > 0) {
                displayTime = `${minutes}M: ${seconds}S`;
            } else {
                displayTime = `${seconds}s`;
            }

            if (verificationStatus === "Pending" && years > 0) {
                setTimeDiff("> a year");

            } else {
                setTimeDiff(displayTime);
            }
        };

        calculateTimeDiff();
        const interval = setInterval(calculateTimeDiff, 1000); // Update every second

        return () => clearInterval(interval); // Cleanup on unmount
    }, [timestamp, approvedOn, reopenedOn, rejectedOn]);

    return <span>{timeDiff}</span>;
}

export default TimeDifferenceView