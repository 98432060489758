import React, { useEffect, useState } from 'react';
import ReportsView from './ReportsView';

const ReportsContainer = () => {
return (
    <>
        <ReportsView/>
    </>
);
};


export default ReportsContainer;