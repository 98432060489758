import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from "moment";
import dayjs from 'dayjs';
import SmartSearchDropdownContainer from '../../../../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { API_ENDPOINT, BASEURLDEV } from '../../../../../../service/ApiConstants';

const UserDetailView = (props:any) => {
    const { t } = useTranslation();
    let [leaddate,setleaddate] = useState<any>(null);
    let [dob1,setdob1] = useState<any>(null);
    const [ptype, setptype] = useState<any>('');
    let [patientTypeSmartSearch, setPatientTypeSmartSearch] = useState<any>(undefined);
    let [serviceTypeSmartSearch, setServiceTypeSmartSearch] = useState<any>(undefined);
    let [citySmartSearch, setCitySmartSearch] = useState<any>(undefined);

    let [defaultpatient, setdefaultpatient] = useState<any>(undefined);
    let [defaultservice, setdefaultservice] = useState<any>(undefined);
    let [defaultcity, setdefaultcity] = useState<any>(undefined);
    const [estDateOfArrival, setEstDateOfArrival] = useState<any>(null);

    let [cityid,setcityid] = useState<any>();

    useEffect(() =>{
        if(props.clearpatient !== undefined){
            setdefaultpatient(undefined);
        }
        if(props.clearcity !== undefined){
            setdefaultcity(undefined);
        }
        if(props.clearservice !== undefined){
            setdefaultservice(undefined);
        }

    },[props.clearpatient,props.clearcity,props.clearservice])


    const validationSchema = Yup.object().shape({
        lddate: Yup.date().required('date is required'),
        NhsId:Yup.string().trim().matches(/^$|\b\d{3}\s?\d{3}\s?\d{4}\b$/).strict(true).nonNullable().notRequired(),
        LocalId:Yup.string().trim().strict(true).nonNullable().notRequired(),
        patienttype: Yup.string().trim().strict(true).nonNullable().required(),
        servicetype: Yup.string().notRequired(),
        name: Yup.string().trim().strict(true).nonNullable().required(),
        dob: Yup.mixed().nullable().notRequired(),
        Address1: Yup.string().trim().strict(true).nonNullable().notRequired(),
        Address2: Yup.string().trim().strict(true).nonNullable().notRequired(),
        estDateOfArrival: Yup.mixed().nullable().notRequired(),
        City: Yup.string().strict(true).nonNullable().notRequired(),
        gender: Yup.string().strict(true).nullable().notRequired(),
        PoBox: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][A-Z]{2}$/)
            .nonNullable()
            .notRequired(),
        ContactNo: Yup.string()
            .trim()
            .strict(true)
            .matches(/^$|^(?:\+44|0)(?:\d\s?){9}\d$/)
            .nonNullable()
            .notRequired(),
        EmailId: Yup.string().matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).strict(true).nonNullable().nullable().notRequired()
    },[['NhsId','LocalId']]);

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;

    useEffect(()=>{
      if(props.leaddata?.data?.patientDetail !== undefined){
        setValue('NhsId',props.leaddata?.data?.patientDetail?.nhsId);
        setValue('LocalId',props.leaddata?.data?.patientDetail?.localId);
        setValue('patienttype',props.leaddata?.data?.patientDetail?.patientTypeId?.toString());
        setdefaultpatient(props.leaddata?.data?.patientDetail?.patientTypeId);
        setptype(props.leaddata?.data?.patientDetail?.patientTypeId?.toString());
        setValue('servicetype',props.leaddata?.data?.patientDetail?.serviceTypeId?.toString());
        setdefaultservice(props.leaddata?.data?.patientDetail?.serviceTypeId)
        setValue('name',props.leaddata?.data?.patientDetail?.patientName);
        let date1 = props.leaddata?.data?.patientDetail?.dateOfBirth ? moment(props.leaddata?.data?.patientDetail?.dateOfBirth, 'DD-MM-YYYY') : null;
        
        let date2 = moment(props.leaddata?.data?.patientDetail.leadDate, 'DD-MM-YYYY');
        setleaddate(dayjs(new Date(date2.toDate())));
        setValue('lddate',window.$utils.dateFormat(props.leaddata?.data?.patientDetail?.leadDate),{shouldValidate : true});

        date1 ?  setdob1(dayjs(new Date(date1.toDate()))) : setdob1(null);
        setValue('dob',window.$utils.dateFormat(props.leaddata?.data?.patientDetail?.dateOfBirth),{shouldValidate : true});

        const estDate = props.leaddata?.data?.patientDetail?.estDateOfArrival ? moment(props.leaddata?.data?.patientDetail?.estDateOfArrival, 'DD-MM-YYYY') : null;
        estDate ?  setEstDateOfArrival(dayjs(new Date(estDate.toDate()))) : setEstDateOfArrival(null);
        setValue('estDateOfArrival', window.$utils.dateFormat(props.leaddata?.data?.patientDetail?.estDateOfArrival), {shouldValidate : true});
        setValue('Address1',props.leaddata?.data?.patientDetail?.addressLine1);
        setValue('Address2',props.leaddata?.data?.patientDetail?.addressLine2);
        setValue('City',props.leaddata?.data?.patientDetail?.countyId?.toString());
        setcityid(props.leaddata?.data?.patientDetail?.countyId);
        let objCitySmartSearch = {
            url: `${BASEURLDEV}${API_ENDPOINT.GetDDLCityList}`,
            extraPayload: {
                defaultCityId: props.leaddata?.data?.patientDetail?.countyId || ''
            }
        };
        setdefaultcity(props.leaddata?.data?.patientDetail?.countyId);
        setCitySmartSearch(objCitySmartSearch);
        setValue('gender',props.leaddata?.data?.patientDetail?.gender?.toString());
        setValue('PoBox',props.leaddata?.data?.patientDetail?.pobox);
        setValue('ContactNo',props.leaddata?.data?.patientDetail?.contactNo);
        setValue('EmailId',props.leaddata?.data?.patientDetail?.email);
      }else{        
        setleaddate(dayjs(new Date()));
        setValue('lddate',dayjs(new Date()),{shouldValidate : true});        
        props.getleadDate(dayjs(new Date()));
      }
    },[props.leaddata])
    useEffect(()=>{
     if(formState.isValid){
        props.iserror(true);
     }
     else{
        props.iserror(false);
     }
    },[formState])

    useEffect(()=>
    {
        setValue('servicetype','');
        let objPatientTypeSmartSearch = {
            url: `${BASEURLDEV}${API_ENDPOINT.GetDDLpatienttypelist}`,
        };
        setPatientTypeSmartSearch(objPatientTypeSmartSearch);
        let objServiceTypeSmartSearch = {
            url: `${BASEURLDEV}${API_ENDPOINT.GetDDLServiceTypeList}`,
        };
        setServiceTypeSmartSearch(objServiceTypeSmartSearch);
        let objCitySmartSearch = {
            url: `${BASEURLDEV}${API_ENDPOINT.GetDDLCityList}`,
            extraPayload: {
                defaultCityId: ''
            }
        };
        setCitySmartSearch(objCitySmartSearch);
    },[])

    return (
        <div>
            <div className="input-group date mb-2 datepicker-input-css" id="datepicker">
                <Controller
                        control={control}
                        name="lddate"
                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                            return <DatePicker className={`form-control white ${error ? 'is-invalid' : ''}`}
                            label={t('VacantRightbar.leaddate')}
                              value={leaddate} 
                              format="DD/MM/YYYY" 
                              {...fieldProps} 
                            onChange={(date:any) => {
                                fieldProps.onChange(date);
                                props.getleadDate(date);
                            }
                            } 
                             disableFuture
                             views={['year', 'month', 'day']} 
                             slotProps={{actionBar: { actions: ['clear','today'] },}}
                            />;
                        }}
                    />
            </div>
            <div className="d-flex justify-content-between">
            <input
                        type="text"
                        {...register('NhsId', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'NhsId' as const}
                        //@ts-ignore
                        // disabled={(props.isedit === 'edit' && props.draftData?.nhsId !== '') ? true : false}
                        className={`form-control white mb-2 me-2 ${errors.NhsId ? 'is-invalid' : ''}`}
                        placeholder={`${t('VacantRightbar.nhs')}`}
                    />
                <p className="mt-2 nowrap">- or - </p>
                <input
                        type="text"
                        {...register('LocalId', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'LocalId' as const}
                        //@ts-ignore
                        // disabled={(props.isedit === 'edit' && props.draftData?.localId !== '') ? true : false}
                        className={`form-control white mb-2 ms-2 ${errors.LocalId ? 'is-invalid' : ''}`}
                        placeholder={`${t('VacantRightbar.local')}`}
                    />
            </div>
    
            {patientTypeSmartSearch !== undefined && (
                        <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                            <SmartSearchDropdownContainer
                                smartSearchLabel={t('VacantRightbar.ptype')}
                                defaultValue={defaultpatient}
                                errors={errors.patienttype}
                                onChangeSmartSearch={(value: any) => {
                                    const event = new Event('change');
                                    let obj = {
                                        name: 'patienttype',
                                        value: value?.id?.toString()
                                    };
                                    //@ts-ignore
                                    Object.defineProperty(event, 'target', { writable: false, value: obj });
                                    setptype(value?.id?.toString());
                                    setValue('patienttype', value?.id?.toString(), { shouldValidate: true });
                                    if (value !== '') {
                                        props.updateData(event);
                                    }
                                }}
                                smartSearch={patientTypeSmartSearch}
                            />
                        </div>
                    )}
           
            <input type='text'  {...register('name', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'name' as const} placeholder="Full Name*" className={`form-control white mb-2 ${errors.name ? 'is-invalid' : ''}`} />
            <div className="input-group date mb-2 datepicker-input-css" id="datepicker">
                <Controller
                        control={control}
                        name="dob"
                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                            return <DatePicker className={`form-control white ${error ? 'is-invalid' : ''}`}
                             label={t('VacantRightbar.dob_non_mendatory')}
                              value={dob1} 
                              format="DD/MM/YYYY" 
                              {...fieldProps} 
                            onChange={(date:any) => {
                                fieldProps.onChange(date);
                                props.getDate(date);
                            }
                            } 
                             disableFuture
                             views={['year', 'month', 'day']} 
                             slotProps={{ 
                                actionBar: { actions: ['clear','today'] }, 
                                popper: { 
                                    disablePortal: true, 
                                    placement: 'bottom-end', 
                                    modifiers: [
                                      {
                                        name: "flip",
                                        enabled: false
                                      }
                                    ],
                                    sx: {
                                      "& .MuiYearCalendar-root": {
                                        minHeight: 200,
                                        alignItems: "baseline"
                                      }
                                    }
                                }
                            }}
                            />;
                        }}
                    />
            </div>
            <div className="d-flex">
                    <label className="mb-2 me-2">{t('VacantRightbar.gender_non_mendatory')}</label>
                    <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                        <input
                            type="radio"
                            {...register('gender', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'gender' as const}
                            id="male"
                            value="1"
                            // onChange={props.updateData}
                        />
                        <span className="ms-1">{t('VacantRightbar.male')}</span>
                    </label>

                    <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                        <input
                            type="radio"
                            {...register('gender', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'gender' as const}
                            id="female"
                            value="2"
                            // onChange={props.updateData}
                        />
                        <span className="ms-1">{t('VacantRightbar.female')}</span>
                    </label>

                    <label className="form--radio-label mb-2 d-flex align-items-center me-2">
                        <input
                            type="radio"
                            {...register('gender', {
                                onChange: (e: any) => {
                                    props.updateData(e);
                                }
                            })}
                            name={'gender' as const}
                            id="other"
                            value="3"
                            // onChange={props.updateData}
                        />
                        <span className="ms-1"> {t('VacantRightbar.other')}</span>
                    </label>
                </div>
                <input
                    type="text"
                    {...register('Address1', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    name={'Address1' as const}
                    //@ts-ignore
                    className={`form-control white mb-2 ${errors.Address1 ? 'is-invalid' : ''}`}
                    placeholder={`${t('VacantRightbar.add1')}`}
                />
                <input
                    type="text"
                    {...register('Address2', {
                        onChange: (e: any) => {
                            props.updateData(e);
                        }
                    })}
                    name={'Address2' as const}
                    //@ts-ignore
                    className={`form-control white mb-2 ${errors.Address2 ? 'is-invalid' : ''}`}
                    placeholder={`${t('VacantRightbar.add2')}`}
                />

            <div className="d-flex justify-content-between">
            {citySmartSearch !== undefined && (
                        <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                            <SmartSearchDropdownContainer
                                smartSearchLabel={t('VacantRightbar.county')}
                                defaultValue={defaultcity}
                                errors={errors.City}
                                onChangeSmartSearch={(value: any) => {
                                    const event = new Event('change');
                                    let obj = {
                                        name: 'City',
                                        value: value?.id?.toString()
                                    };
                                    //@ts-ignore
                                    Object.defineProperty(event, 'target', { writable: false, value: obj });
                                    setptype(value?.id?.toString());
                                    setValue('City', value?.id?.toString(), { shouldValidate: true });
                                    if (value !== '') {
                                        props.updateData(event);
                                    }
                                    else{
                                        setdefaultcity(undefined);
                                        let objCitySmartSearch = {
                                            url: `${BASEURLDEV}${API_ENDPOINT.GetDDLCityList}`,
                                            extraPayload: {
                                                defaultCityId: ''
                                            }
                                        };
                                        setCitySmartSearch(objCitySmartSearch);
                                    }
                                }}
                                smartSearch={citySmartSearch}
                            />
                        </div>
                    )}
                    {/* <select
                         {...register('City', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'City' as const}
                        id="city"
                        //@ts-ignore
                        className={`form-control white mb-2  ${errors.City ? 'is-invalid' : ''}`}
                    >
                        <option value="">{t('VacantRightbar.county')}</option>
                        {props.citylist !== undefined &&
                            props.citylist?.data?.map((cities: any) => {
                                return (
                                    <option key={cities.key} value={cities.key}>
                                        {cities.value}
                                    </option>
                                );
                            })}
                    </select> */}
                    <input
                        type="text"
                        {...register('PoBox', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'PoBox' as const}
                        //@ts-ignore
                        className={`form-control white mb-2 ms-2 ${errors.PoBox ? 'is-invalid' : ''}`}
                        placeholder={`${t('VacantRightbar.postal')}`}
                    />
                </div>
                <input type="text" {...register('ContactNo', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'ContactNo' as const} placeholder={`${t('VacantRightbar.contact')}`} className={`form-control white mb-2 ${errors.ContactNo ? 'is-invalid' : ''}`} />
            <input type="text" {...register('EmailId', {
                            onChange: (e: any) => {
                                props.updateData(e);
                            }
                        })}
                        name={'EmailId' as const} placeholder={`${t('VacantRightbar.email')}`} className={`form-control white mb-2 ${errors.EmailId ? 'is-invalid' : ''}`} />

                        
                <div className="input-group date mb-2 datepicker-input-css" id="datepicker">
                    <Controller
                        control={control}
                        name="estDateOfArrival"
                        render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                            return <DatePicker className={`form-control white ${error ? 'is-invalid' : ''}`}
                             label={t('VacantRightbar.estDateOfArrival')}
                              value={estDateOfArrival} 
                              format="DD/MM/YYYY" 
                              {...fieldProps} 
                            onChange={(date:any) => {
                                fieldProps.onChange(date);
                                props.getEstDate(date);
                            }
                            } 
                            disablePast
                             views={['year', 'month', 'day']} 
                             slotProps={{ 
                                actionBar: { actions: ['clear','today'] }, 
                                popper: { 
                                    disablePortal: true, 
                                    placement: 'bottom-end', 
                                    modifiers: [
                                      {
                                        name: "flip",
                                        enabled: false
                                      }
                                    ],
                                    sx: {
                                      "& .MuiYearCalendar-root": {
                                        minHeight: 200,
                                        alignItems: "baseline"
                                      }
                                    }
                                }
                            }}
                            />;
                        }}
                    />
                </div>
        </div>
    );
};

export default UserDetailView;
