import React, { useEffect, useMemo, useState, useRef, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import {
    MaterialReactTable,
    MRT_TableInstance,
    MRT_FullScreenToggleButton,
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_TablePagination,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToolbarAlertBanner,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_VisibilityState,
    type MRT_RowSelectionState,
    type MRT_GroupingState,
    MRT_Localization
} from 'material-react-table';
import { Box } from '@mui/material';
import PaginationContainer from '../../../components/PaginationComponent/indexContainer';
import SmartSearchDropdownContainer from '../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { Tooltip } from 'react-tooltip';
import ExportIntegrationContainer from '../../IntegrationInterface/IntegrationBottom/ExportIntegration/ExportIntegrationContainer';
import RenderModalContainer from '../../../components/RenderModal/indexContainer';
import ExportReportsModalContainer from '../../../components/ExportReportsModal/ExportReportsModalContainer';
import ExportReportsContainer from '../ExportReports/ExportReportsContainer';

import ClientPortal from '../../../components/ClientPortal';

export enum Source {
    Integration = "integration",
    Admission = "admission",
    Referral = "referral"
}

const CurrentBedOccupancyReportView  = (props: any) => {
    const { t } = useTranslation();
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'Organization', //access nested data with dot notation
                header: 'Organization',
                size: 100,
            },
            {
                accessorKey: 'ServiceType',
                header: 'Service Type',
                size: 100,
            },
            {
                accessorKey: 'ServiceName',
                header: 'Service Name',
                size: 100,
            },
            {
                accessorKey: 'RegisteredBed',
                header: 'Registered Bed',
                size: 50,
            },
            {
                accessorKey: 'OperationBeds',
                header: 'Operation Beds',
                size: 50,
            },
            {
                accessorKey: 'OccupiedBeds',
                header: 'Occupied Beds',
                size: 50,
            },
            {
                accessorKey: 'ReservedBeds',
                header: 'Reserved Beds',
                size:50,
            },
            {
                accessorKey: 'VacantBeds',
                header: 'Vacant Beds',
                size: 50,
            },
            {
                accessorKey: 'BedsinMaintenance',
                header: 'Beds in Maintenance',
                size: 100,
            },
            {
                accessorKey: 'OperationalOccupancy',
                header: 'Operational Occupancy',
                size: 200,
            },
            {
                accessorKey: 'RegisteredOccupancy',
                header: 'Registered Occupancy',
                size: 200,
            },
        ],
        [],
    );
    const Data = [
        {
            Organization: "Valorum Care",
            ServiceType: "Hospital",
            ServiceName: "Mangna House",
            RegisteredBed:"4",
            OperationBeds:"5",
            OccupiedBeds:"10",
            ReservedBeds:"20",
            VacantBeds:"40",
            BedsinMaintenance:"15",
            OperationalOccupancy:"83%",
            RegisteredOccupancy:"54%"
        },
        {
            Organization: "Valorum Care",
            ServiceType: "Hospital",
            ServiceName: "Mangna House",
            RegisteredBed:"4",
            OperationBeds:"5",
            OccupiedBeds:"10",
            ReservedBeds:"20",
            VacantBeds:"40",
            BedsinMaintenance:"15",
            OperationalOccupancy:"83%",
            RegisteredOccupancy:"54%"
        },
        {
            Organization: "Valorum Care",
            ServiceType: "Hospital",
            ServiceName: "Mangna House",
            RegisteredBed:"4",
            OperationBeds:"5",
            OccupiedBeds:"10",
            ReservedBeds:"20",
            VacantBeds:"40",
            BedsinMaintenance:"15",
            OperationalOccupancy:"83%",
            RegisteredOccupancy:"54%"
        },
    ]
    let [showModel, setShowModel] = useState<boolean>(false);
    const handleClickExport = () => {
        setShowModel(true);
    };
    const [showSecondPortal, setShowSecondPortal] = useState(false);
    useEffect(() => {
        if (showSecondPortal) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [showSecondPortal]);
    const handleSecondModal = (event: any) => {
        event.preventDefault();
        setShowSecondPortal((prevState) => !prevState);
    };

    const [showExportPortal, setShowExportPortal] = useState(false);
    useEffect(() => {
        if (showExportPortal) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
        return () => {
            document.body.classList.remove("modal-open");
        };
    }, [showExportPortal]);
    const handleExportModal = (event: any) => {
        event.preventDefault();
        setShowExportPortal((prevState) => !prevState);
    };
    

    const columnsModal = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'Organization', //access nested data with dot notation
                header: 'Organization',
                size: 100,
            },
            {
                accessorKey: 'ServiceType',
                header: 'Service Type',
                size: 100,
            },
            {
                accessorKey: 'ServiceName',
                header: 'Service Name',
                size: 100,
            },
            {
                accessorKey: 'WardName',
                header: 'Ward Name | Bed Name',
                size: 200,
            },
            {
                accessorKey: 'patientType',
                header: 'Patient Type',
                size: 100,
            },
            {
                accessorKey: 'patientContactNumber',
                header: 'Patient Contact Number',
                size: 200,
            },
            {
                accessorKey: 'patientEmail',
                header: 'Patient Email',
                size: 120,
            },
            {
                accessorKey: 'NextOfKin',
                header: 'Next Of Kin',
                size: 120,
            },
            {
                accessorKey: 'KinRelationship',
                header: 'Kin Relationship',
                size: 120,
            }
        ],
        [],
    );
    const DataModal = [
        {
            Organization: "Valorum Care",
            ServiceType: "Hospital",
            ServiceName: "Mangna House",
            WardName: "Ward A | 23",
            patientType : "Respite",
            patientContactNumber: "07700123456",
            patientEmail: "robert@gmail.com",
            NextOfKin :"John Little",
            KinRelationship: "Sister"
        },
        {
            Organization: "Health Care",
            ServiceType: "Hospital",
            ServiceName: "Mangna House",
            WardName: "Ward A | 23",
            patientType : "Respite",
            patientContactNumber: "07700123456",
            patientEmail: "robert@gmail.com",
            NextOfKin :"John Little",
            KinRelationship: "Sister"
        },
        {
            Organization: "Valorum Care",
            ServiceType: "Hospital",
            ServiceName: "Mangna House",
            WardName: "Ward A | 23",
            patientType : "Respite",
            patientContactNumber: "07700123456",
            patientEmail: "robert@gmail.com",
            NextOfKin :"John Little",
            KinRelationship: "Sister"
        },
    ]

    return (
        <>
        <div className="container-fluid">
            <div className="row p-0">
                <div className="col-xl-12 col-lg-12 mb-4">
                    <div className="box-shadow p-0">
                        <div>
                            <div className="administrator-header ">
                                <div className="row align-items-center justify-content-between h-100">
                                    <div className="col-md-6">
                                        <p className="bold lead-text purple-text">Current Bed Occupancy Report</p>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <a href="/reports"><button className="btn btn-white btn-sm"><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="administrator-content">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Service*</label>
                                        <div className="auto-complete-dropdown auto-complete-dropdown-cus mb-0">
                                            <SmartSearchDropdownContainer
                                                smartSearchLabel={'Select Service'}
                                                defaultValue={"Helllo"}
                                                onChangeSmartSearch={(value: any) => {
                                                    const event = new Event('change');
                                                    let obj = {
                                                        name: 'messageCode',
                                                        value: value?.id
                                                    };
                                                }}
                                                smartSearch={"Role"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="d-flex align-items-center justify-content-center">
                                    <button
                                        className="btn btn-secondary me-2"
                                    >
                                        Clear
                                    </button>
                                    <button
                                        className="btn btn-primary me-2"
                                    >
                                        Generate Report
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-12 col-lg-12">
                    <div className="box-shadow p-0">
                        <div className='pt-3'>
                            <div className='row pe-2 ps-2 d-flex justify-content-end'>
                                <div className='col-md-4 mb-3 d-flex justify-content-end'>
                                    <ExportReportsContainer onExportClick={handleClickExport} />
                                </div>
                            </div>
                            <div className="invoice-table reports-table"> 
                                <MaterialReactTable
                                    columns={columns}
                                    data={Data}
                                    enableGrouping
                                    enableColumnActions={false}
                                    enableBottomToolbar={false}
                                    enableTopToolbar={false}
                                    enableColumnDragging={false}
                                    manualPagination
                                    manualSorting
                                />
                            </div>
                            <div className='administrator-content'>
                            <div className="row align-items-center mt-3 invoice-table-pagination">
                                <div className="col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <p className="purple-text bold">
                                            Displaying 1 - 2 of 10
                                        </p>
                                        <div className="d-flex align-items-center ms-3">
                                            <label className="mb-0 bold nowrap me-2">Page Size:</label>
                                            <select className={`form-control white page-select`} id="supportedPersoIntegrationPageSize">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <PaginationContainer currentPage={1} totalCount={100} pageSize={10} onPageChange={(page: any) => { }} />
                                </div>
                            </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            {
                <RenderModalContainer>
                    {showModel && <ExportReportsModalContainer title="Export Report Details" onClose={() => setShowModel(false)}></ExportReportsModalContainer>}
                </RenderModalContainer>
            } 

            <ClientPortal selector="myportal" show={showSecondPortal}>  
                <div className="overlay" onClick={handleSecondModal}></div>
                <div className="modal userAdminModal p-0">
                    <div className="header admin-modal-header">
                        <h4 className="mb-0">Robert Little - 8989895867</h4>
                        <a onClick={handleSecondModal} className="removeButton">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 384 512"
                            >
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body">
                        <div className='d-flex justify-content-between admin-modal-body'>
                            <div className='d-flex'>
                                <div className='me-5'>
                                    <table className='user-details-table'>
                                        <tr>
                                            <td className='strong-font'>NHS ID</td>
                                            <td>8676777777</td>
                                        </tr>
                                        <tr>
                                            <td className='strong-font'>Local ID</td>
                                            <td>LL003</td>
                                        </tr>
                                        <tr>
                                            <td className='strong-font'>Patient Name</td>
                                            <td>Robert Little</td>
                                        </tr>
                                    </table>
                                </div>
                                <div>
                                    <table className='user-details-table'>
                                        <tr>
                                            <td className='strong-font'>Patient DOB</td>
                                            <td>20/01/1990</td>
                                        </tr>
                                        <tr>
                                            <td className='strong-font'>Gender</td>
                                            <td>Male</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className=''>
                                <div>
                                    <a href="#" className="btn btn-white bold " data-tooltip-id="my-tooltip-export" data-tooltip-content="Export Report Details" onClick={handleExportModal}>
                                        <i className='fa fa-file-export'></i> Export
                                    </a>
                                    <Tooltip id="my-tooltip-export" className="my-tooltip" />
                                </div>
                            </div>
                        </div>

                        <div className='admin-info-table'>
                            <div className="box-shadow p-0">
                                <div className='pt-3'>
                                    <div className='row pe-2 ps-2 d-flex justify-content-end'>
                                        <div className='col-md-4 mb-3 d-flex justify-content-end'>
                                            <div className="dropdown filter-dropdown me-2">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <div className="">
                                                        <i className="fa fa-cog"></i> {t('TableManageColumns.Manage_Columns')}
                                                    </div>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-columns report-dropdownmenu" aria-labelledby="navbarDropdownMenuLink">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Local ID
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Patient DOB
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Gender
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Patient Address
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Kin Contact No.
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Kin Address
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Est. Discharge Date
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Length of Stay
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Funder Address
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Commissioner Name
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Commissioner Contact No.
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Commissioner Email
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Commissioner Address
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                        <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> 1 : 1 Hourly Rate
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> 1 : 1 Weekly Inclusion
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> 1 : 2 Hourly Rate
                                                                </label>
                                                            </div>   
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> 1 : 2 Weekly Inclusion
                                                                </label>
                                                            </div> 
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> 1 : 3 Hourly Rate
                                                                </label>
                                                            </div> 
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> 1 : 3 Hourly Rate
                                                                </label>
                                                            </div> 
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> 1 : 3 Weekly Inclusion
                                                                </label>
                                                            </div>   
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> 1 : 4 Hourly Rate
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> 1 : 4 Weekly Inclusion
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Admitted By
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Discharged By
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="" className="me-1" /> Transferred By
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="invoice-table reports-table"> 
                                        <MaterialReactTable
                                            columns={columnsModal}
                                            data={DataModal}
                                            enableGrouping
                                            enableColumnActions={false}
                                            enableBottomToolbar={false}
                                            enableTopToolbar={false}
                                            enableColumnDragging={false}
                                            manualPagination
                                            manualSorting
                                        />
                                    </div>
                                    <div className='administrator-content'>
                                    <div className="row align-items-center mt-3 invoice-table-pagination">
                                        <div className="col-lg-6">
                                            <div className="d-flex align-items-center">
                                                <p className="purple-text bold">
                                                    Displaying 1 - 2 of 10
                                                </p>
                                                <div className="d-flex align-items-center ms-3">
                                                    <label className="mb-0 bold nowrap me-2">Page Size:</label>
                                                    <select className={`form-control white page-select`} id="supportedPersoIntegrationPageSize">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 ">
                                            <PaginationContainer currentPage={1} totalCount={100} pageSize={10} onPageChange={(page: any) => { }} />
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </ClientPortal>


            <ClientPortal selector="myportal" show={showExportPortal}>  
                <div className="overlay" onClick={handleExportModal}></div>
                <div className="modal modal-extrasmall">
                    <div className='modal-content'>
                        <div className="modal-header">
                            <p className='modal-title'>Excel Export</p>
                            <a onClick={handleExportModal} className="removeButton">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="1em"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                        fill="#ffffff"
                                        d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                    />
                                </svg>
                            </a>
                        </div>
                    
                        <div className="modal-body">
                            <div className=" mb-2">
                                <h3 className="mb-2 report-modal-heading">Select Export Options</h3>
                                <div className="row">
                                    <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                                        <input type="radio" className="me-2" defaultChecked/>
                                        <label>Export Selected Columns</label>
                                    </div>
                                    <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                                        <input type="radio" className="me-2"/>
                                        <label>Export All Columns</label>
                                    </div>
                                    <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                                        <input type="radio" className="me-2"/>
                                        <label>Export CSV</label>
                                    </div>
                                    <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                                        <input type="radio" className="me-2"/>
                                        <label>Excel for all reports</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="modal-footer justify-content-center">
                            <div className="d-flex align-items-center ms-2">
                                <button className="btn primary-btn me-2">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ClientPortal>


        </div>
        </>
    );
};
export default CurrentBedOccupancyReportView;