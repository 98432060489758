import React, { useEffect, useState } from 'react';
import cqc from '../../../../../assets/images/CQC.png';
import star from '../../../../../assets/images/star.png';
import { Tooltip } from 'react-tooltip';
import { ServicesListsType } from '../types';
import availablebed from "../../../../../assets/images/outline-bed.svg"

const ListDetailsView = (props: ServicesListsType) => {

    const [serviceType,setServiceType] = useState<any>();
    useEffect(()=>{
        setServiceType(props?.serviceTypeName?.split(','))
    },[])
    return (
        <div className='list-content' onClick={props.clickFn}>
            <div className='list-middle'>
                <div className='d-flex justify-content-between align-items-center'>
                    {/* <h5 className='list-title mb-0'>{props.serviceName}</h5> */}
                    <h5 className='list-title mb-0' data-tooltip-id={`my-tooltip`} data-tooltip-content={props?.address !== '' ? `${props?.serviceName}, ${props?.address}` : `${props?.serviceName}`}>
                        <span className='medium-text'>{props?.serviceName}, </span>{props?.address !== "" ? ` ${props?.address}` : ''}
                    </h5>
                    {/* <p><i className="fa-solid fa-heart text-small"></i> 3.4</p> */}
                </div>
                <ul className='list-unstyled d-flex service-type-list'>
                    {serviceType && serviceType.map((service:any,key:any)=>{
                        return(
                            <li key={key} className='py-1 pe-1'><a href="#">{service.trim()}</a></li>
                        )
                    })}
                    
                </ul>
                {/* <p className='list-text mb-1'>{props.address}</p> */}
                <p className='list-text mb-1'><span className='bolder'>Funding Accepted: </span>{props?.fundingType === 'NA' ? 'N/A' : props?.fundingType.replaceAll(',', ', ')}</p>
                <div className='d-flex align-items-center'>
                    <span className={`${props.bedAvailability === "Occupied" ? "occupied occupied-bg occupied-border" : ""} avail-bed me-2`}>
                        {props.bedAvailability}
                    </span>
                    <img src={availablebed} alt="Available Bed" width={33} />
                <p className='list-text ms-1'>{props.availableBedCount}</p>
                </div>
            </div>
            {/* <div className='list-footer mt-1 d-flex align-items-center justify-content-between'>
                <a className='d-flex align-items-center'>
                    <img src={cqc} className='img-fluid me-2' alt="cqc" width={60} />
                    <p className='bold d-flex align-items-center green-text'>{props.isOutOfArea} <span className='dot-round ms-1'></span></p>
                </a>
                <p>
                    <img src={star} className='img-fluid me-2' alt="star" />
                    <span className='bolder'>{!props.cqcRating ? 0 : props.cqcRating} (100)</span>
                </p>
            </div> */}

            <Tooltip id="my-tooltip" className="my-tooltip" />
        </div>
    );
};

export default ListDetailsView;
