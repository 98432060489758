import React, { useEffect, useMemo, useState, useRef, useReducer } from 'react';
import listimage5 from '../../../../../assets/images/listimage5.png';
import { ListContainerProps, ServicesListsType } from '../types';
import { saveForLaterModal } from '../../../../../store/SaveForLater/saveForLaterModalReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Tooltip } from 'react-tooltip';
import { BASE_URL } from '../../../../../utils/constant';
import ListSliderPaging from './ListSliderPaging';

const ListSliderView = ({ serviceImages, cqcRating, serviceId, serviceName, clickFn }: ListContainerProps) => {
    const dispatch = useDispatch();

    const carouselRef = useRef<Slider>(null);

    const [isBoxHovered, setIsBoxHovered] = useState<boolean>(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isCarouselChanged, setIsCarouselChanged] = useState(false);

    const [imageList, setImageList] = useState<{
        imageURL: string;
    }[]>([]);
    const [imageKey, setImageKey] = useState<number>(0);
    const [isWishListed, setWishListed] = useState<boolean>(false)
    const { saveForLaterWishlistOpen } = useSelector((state: RootState) => state.saveForLaterWishlistModal);
    const { SaveForLaterServicesList } = useSelector((state: RootState) => state.saveForLaterServicesList);

    useEffect(() => {
        if (serviceImages.length <= 0) {
            setImageList(
                [
                    { imageURL: `${BASE_URL}/${listimage5}` }
                ]
            )
        } else {
            setImageList([...serviceImages])
        }
        setImageKey(imageKey+1)
    }, [serviceImages])

    const handleClickModal = (e: any) => {
        e.stopPropagation();
        dispatch(saveForLaterModal({
            saveForLaterModelOpen: true,
            serviceId: serviceId,
            serviceName: serviceName
        }))
    }

    useEffect(() => {
        if(saveForLaterWishlistOpen){
            setWishListed(SaveForLaterServicesList?.data?.wishlistedService?.some((service: any) => service?.serviceId === serviceId))
        }else{
            setWishListed(false)
        }
    }, [saveForLaterWishlistOpen,SaveForLaterServicesList?.data?.wishlistedService?.length])

    const goToPrevSlide = () => {
        if (carouselRef.current) {
            carouselRef.current.slickPrev();
            setIsCarouselChanged(true);
        }
    };

    const goToNextSlide = () => {
        if (carouselRef.current) {
            carouselRef.current.slickNext();
            setIsCarouselChanged(true);
        }
    };

    const handleBoxHoveredIn = () => {
        setIsBoxHovered(true)
    }

    const handleBoxHoveredOut = () => {
        setIsBoxHovered(false)
        setIsCarouselChanged(false);
    }

    const handleSlideChange = (next: number) => setCurrentSlide(next);

    const showPrevButton = () => {
        if (isBoxHovered) {
            if (currentSlide > 0) {
                return true;
            } else {
                if (isCarouselChanged) {
                    return true
                } else {
                    return false
                }
            }
        } else {
            return false;
        }
    }
    const showNextButton = () => {
        if (isBoxHovered) {
            if (currentSlide < imageList.length - 1) {
                return true;
            } else {
                if (isCarouselChanged) {
                    return true
                } else {
                    return false
                }
            }
        } else {
            return false;
        }
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 1,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (_: any, next: any) => handleSlideChange(next),
        arrows: false
      };

    return (
        <div 
            className="image-owl" 
            key={imageKey}
            onMouseEnter={handleBoxHoveredIn}
            onMouseLeave={handleBoxHoveredOut}
        >
            <div className="carousel-container" onClick={(e: any) => e.stopPropagation()}>
                {showPrevButton() && (
                    <div
                        className="nav-div nav-div-prev-button list-carousel"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {isBoxHovered && currentSlide > 0 && <button className="nav-button prev-button" onClick={goToPrevSlide}>
                            <i className="fa fa-chevron-left"></i>
                        </button>}
                    </div>
                )}
                <Slider {...settings} ref={carouselRef} className='map-list-slider'>
                    {imageList?.map((img, i) => (
                        <div className="list-image item" key={img.imageURL + i} onClick={clickFn}>
                            <img src={img.imageURL} className="img-fluid img-slick-map-list" alt="list-image" loading="lazy" />
                            <span className="overlay-list"></span>
                            <div className='list-box-top'>
                                <p className='rate-div good d-flex align-items-center'>
                                    <span className='bold text-ellipsis'>CQC Rating</span>
                                    <span
                                        className="d-flex align-items-center ms-2 text-ellipsis"
                                        data-tooltip-id={`my-tooltip-${serviceName}-${i}`}
                                        data-tooltip-content={(cqcRating.length > 4) ? cqcRating : ''}
                                    >
                                        {cqcRating}
                                        {(cqcRating.length > 4) && (
                                            <Tooltip
                                                id={`my-tooltip-${serviceName}-${i}`}
                                                className="my-tooltip"
                                                style={{
                                                    whiteSpace: "pre-line",
                                                    textAlign: "center",
                                                    textTransform: "capitalize",
                                                    fontSize: 10,
                                                    fontWeight: 600
                                                }}
                                            />
                                        )}
                                    </span>
                                    <span
                                        className={`rate-dot ${cqcRating.toLowerCase() === "good"
                                                ? "good"
                                                : cqcRating.toLowerCase() === "requires improvement"
                                                    ? "requiresImprovement"
                                                    : cqcRating.toLowerCase() === "inadequate"
                                                        ? "inadequate"
                                                        : cqcRating.toLowerCase() === "outstanding"
                                                            ? "outstanding"
                                                            : "na"
                                            } ms-1`}
                                    ></span>
                                </p>
                                <span className='hand-cursor heart-icon' onClick={handleClickModal}>
                                    <svg
                                        version="1.1"
                                        id="Layer_2"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        width={25}
                                        y="0px"
                                        viewBox="0 0 3154.5 2836.4"
                                        xmlSpace="preserve"
                                    >
                                        <style>{`"enable-background:new 0 0 3154.5 2836.4;"`} </style>
                                        <g className="st0" style={{ opacity: 1 }}>
                                            <path className="st1" fill={saveForLaterWishlistOpen && SaveForLaterServicesList?.data?.wishlistedService?.some((service: any) => service?.serviceId === serviceId) ? '#070044' : '#FFFFFF'} d="M923.6,159c-283.4,12.6-757,222.5-777.8,853.7c-7.4,224.7,166.8,903.5,1426.1,1678.2
C2828.3,1918,3017,1238.1,3011.6,1012.7c-15.2-631.5-493.1-841.1-777.8-853.7c-466.2-20.7-655.2,321.5-655.2,321.5
S1389.9,138.3,923.6,159z"/>
                                        </g>
                                        <g>
                                            <path className="st2" fill="#010101;" d="M1571.8,2788.4l-43.5-26.8C107.2,1887.3,58.2,1149.1,62.8,1010c23.4-709.7,571-921.3,857.1-934l3.7,83l-3.7-83
c352.6-15.2,558.3,152.4,658.7,265.6C1679,228.5,1886.3,60.5,2237.5,76c288.9,12.8,839.9,224.7,857.1,934.7
c2.1,84.8-21.9,854.3-1479.3,1750.9L1571.8,2788.4z M963.8,241.2c-12,0-24.2,0.2-36.5,0.8c-233.1,10.4-679.2,185.3-698.5,773.6
c-2.6,74.6,14.6,746.3,1342.9,1577.6c1083.5-678.5,1363.4-1309.7,1356.7-1578.4C2914.4,427.4,2465.4,252.4,2230,242
c-399.7-16.1-571.9,266.8-578.9,279L1578,650.3l-72-129.7C1499.4,509.2,1343.2,241.2,963.8,241.2z"/>
                                        </g>
                                    </svg>

                                </span>
                            </div>
                        </div>
                    ))}
                </Slider>
                <ListSliderPaging currentItem={currentSlide} totalItems={imageList.length} />
                {showNextButton() && <div className="nav-div nav-div-next-button list-carousel" onClick={(e) => { e.stopPropagation() }}>
                    {isBoxHovered && currentSlide < imageList.length - 1 && <button className="nav-button slider-next-button" onClick={goToNextSlide}>
                        <i className="fa fa-chevron-right"></i>
                    </button>}
                </div>}
            </div>
        </div>
    );
};

export default ListSliderView;



