import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';

const TableManageColumnsView = (props: any) => {
    const { t } = useTranslation();
    let [columns, setColumns] = useState<any>({
        NHS_Id: false,
        Admission_date: false,
        Est_Discharge_Date: false,
        Period_From: false,
        Period_To: false,
        Due_Date: false,
        Payment_Reference: false,
        Product_Code: false,
        Quantity: false,
        PaymentStatus: false
    });

    const onChangeColumnHandler = (event: any) => {
        if(event.target.checked === true && event.target.name === 'NHS_Id'){
            setColumns((state: any)=> ({
                ...state,
                NHS_Id: true
             }));
            let obj = {
                ColumnName: "NHS_Id",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'NHS_Id') {
            setColumns((state: any)=> ({
                ...state,
                NHS_Id: false
             }));
            let obj = {
                ColumnName: "NHS_Id",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'Admission_date'){
            setColumns((state: any)=> ({
                ...state,
                Admission_date: true
             }));
            let obj = {
                ColumnName: "Admission_date",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'Admission_date') {
            setColumns((state: any)=> ({
                ...state,
                Admission_date: false
             }));
            let obj = {
                ColumnName: "Admission_date",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'Est_Discharge_Date'){
            setColumns((state: any)=> ({
                ...state,
                Est_Discharge_Date: true
             }));
            let obj = {
                ColumnName: "Est_Discharge_Date",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'Est_Discharge_Date') {
            setColumns((state: any)=> ({
                ...state,
                Est_Discharge_Date: false
             }));
            let obj = {
                ColumnName: "Est_Discharge_Date",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'Period_From'){
            setColumns((state: any)=> ({
                ...state,
                Period_From: true
             }));
            let obj = {
                ColumnName: "Period_From",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'Period_From') {
            setColumns((state: any)=> ({
                ...state,
                Period_From: false
             }));
            let obj = {
                ColumnName: "Period_From",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'Period_To'){
            setColumns((state: any)=> ({
                ...state,
                Period_To: true
             }));
            let obj = {
                ColumnName: "Period_To",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'Period_To') {
            setColumns((state: any)=> ({
                ...state,
                Period_To: false
             }));
            let obj = {
                ColumnName: "Period_To",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'Due_Date'){
            setColumns((state: any)=> ({
                ...state,
                Due_Date: true
             }));
            let obj = {
                ColumnName: "Due_Date",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'Due_Date') {
            setColumns((state: any)=> ({
                ...state,
                Due_Date: false
             }));
            let obj = {
                ColumnName: "Due_Date",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'Payment_Reference'){
            setColumns((state: any)=> ({
                ...state,
                Payment_Reference: true
             }));
            let obj = {
                ColumnName: "Payment_Reference",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'Payment_Reference') {
            setColumns((state: any)=> ({
                ...state,
                Payment_Reference: false
             }));
            let obj = {
                ColumnName: "Payment_Reference",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'Product_Code'){
            setColumns((state: any)=> ({
                ...state,
                Product_Code: true
             }));
            let obj = {
                ColumnName: "Product_Description",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'Product_Code') {
            setColumns((state: any)=> ({
                ...state,
                Product_Code: false
             }));
            let obj = {
                ColumnName: "Product_Description",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'Quantity'){
            setColumns((state: any)=> ({
                ...state,
                Quantity: true
             }));
            let obj = {
                ColumnName: "Quantity",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'Quantity') {
            setColumns((state: any)=> ({
                ...state,
                Quantity: false
             }));
            let obj = {
                ColumnName: "Quantity",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }

        if(event.target.checked === true && event.target.name === 'PaymentStatus'){
            setColumns((state: any)=> ({
                ...state,
                PaymentStatus: true
             }));
            let obj = {
                ColumnName: "PaymentStatus",
                IsActive: true
            }
            props.onChangeManageColumnHandler(obj)
        } else if(event.target.checked === false && event.target.name === 'PaymentStatus') {
            setColumns((state: any)=> ({
                ...state,
                PaymentStatus: false
             }));
            let obj = {
                ColumnName: "PaymentStatus",
                IsActive: false
            }
            props.onChangeManageColumnHandler(obj)
        }
    };

    useEffect(() => {
        if (props.managedColumnsList.length > 0) {
            props.managedColumnsList.map((val: any) => {
                if(val.columnName === "NHS_Id") {
                    setColumns((state: any)=> ({
                        ...state,
                        NHS_Id: true
                     }));
                }
        
                if(val.columnName === "Admission_date") {
                    setColumns((state: any)=> ({
                        ...state,
                        Admission_date: true
                     }));
                }
        
                if(val.columnName === "Est_Discharge_Date") {
                    setColumns((state: any)=> ({
                        ...state,
                        Est_Discharge_Date: true
                     }));
                }
        
                if(val.columnName === "Period_From") {
                    setColumns((state: any)=> ({
                        ...state,
                        Period_From: true
                     }));
                }
        
                if(val.columnName === "Period_To") {
                    setColumns((state: any)=> ({
                        ...state,
                        Period_To: true
                     }));
                }
        
                if(val.columnName === "Due_Date") {
                    setColumns((state: any)=> ({
                        ...state,
                        Due_Date: true
                     }));
                }
        
                if(val.columnName === "Payment_Reference") {
                    setColumns((state: any)=> ({
                        ...state,
                        Payment_Reference: true
                     }));
                }
        
                if(val.columnName === "Product_Description") {
                    setColumns((state: any)=> ({
                        ...state,
                        Product_Code: true
                     }));
                }
        
                if(val.columnName === "Quantity") {
                    setColumns((state: any)=> ({
                        ...state,
                        Quantity: true
                     }));
                }
        
                if(val.columnName === "PaymentStatus") {
                    setColumns((state: any)=> ({
                        ...state,
                        PaymentStatus: true
                     }));
                } 
            })
        } 
    }, [props.managedColumnsList]);

    

    const eventHandler = (event: any) => {
        event.stopPropagation();
    };
    return (
        <div>
            <div className="dropdown filter-dropdown me-2">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="legend-label">
                        <i className="fa fa-cog"></i> {t('TableManageColumns.Manage_Columns')}
                    </div>
                </a>
                <div className="dropdown-menu dropdown-menu-columns" onClick={eventHandler} aria-labelledby="navbarDropdownMenuLink">
                    <div className="row">
                        <div className="col-md-6">
                            <div>
                                <label>
                                    <input type="checkbox" name="NHS_Id" checked={columns.NHS_Id} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('TableManageColumns.NHS_Client_ID')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="Admission_date" checked={columns.Admission_date} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('TableManageColumns.Date_Admit')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="Est_Discharge_Date" checked={columns.Est_Discharge_Date} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('TableManageColumns.Date_Discharge')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="Period_From" checked={columns.Period_From} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('TableManageColumns.Period_From')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="Period_To" checked={columns.Period_To} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('TableManageColumns.Period_To')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="Due_Date" checked={columns.Due_Date} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('TableManageColumns.Due_Date')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="Payment_Reference" checked={columns.Payment_Reference} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('TableManageColumns.Payment_Reference')}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div>
                                <label>
                                    <input type="checkbox" name="Product_Code" checked={columns.Product_Code} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('TableManageColumns.Product')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="Quantity" checked={columns.Quantity} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('TableManageColumns.Quantity')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="PaymentStatus" checked={columns.PaymentStatus} onChange={(event: any) => (onChangeColumnHandler(event))} className="me-1" /> {t('TableManageColumns.Payment_Status')}
                                </label>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableManageColumnsView;
