import React, { useEffect, useMemo, useState, useRef, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import {
    MaterialReactTable,
    MRT_TableInstance,
    MRT_FullScreenToggleButton,
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_TablePagination,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToolbarAlertBanner,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_VisibilityState,
    type MRT_RowSelectionState,
    type MRT_GroupingState,
    MRT_Localization
} from 'material-react-table';
import { Box } from '@mui/material';
import PaginationContainer from '../../../components/PaginationComponent/indexContainer';
import SmartSearchDropdownContainer from '../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { Tooltip } from 'react-tooltip';
import ExportIntegrationContainer from '../../IntegrationInterface/IntegrationBottom/ExportIntegration/ExportIntegrationContainer';
import RenderModalContainer from '../../../components/RenderModal/indexContainer';
import ExportReportsModalContainer from '../../../components/ExportReportsModal/ExportReportsModalContainer';
import ExportReportsContainer from '../ExportReports/ExportReportsContainer';

import ClientPortal from '../../../components/ClientPortal';

export enum Source {
    Integration = 'integration',
    Admission = 'admission',
    Referral = 'referral'
}

const DischargeReportView = (props: any) => {
    const { t } = useTranslation();
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'Organization', //access nested data with dot notation
                header: 'Organization',
                size: 100
            },
            {
                accessorKey: 'ServiceType',
                header: 'Service Type',
                size: 100
            },
            {
                accessorKey: 'ServiceName',
                header: 'Service Name',
                size: 100
            },
            {
                accessorKey: 'WardName',
                header: 'Ward Name',
                size: 100
            },
            {
                accessorKey: 'BedName',
                header: 'Bed Name',
                size: 100
            },
            {
                accessorKey: 'patientName',
                header: 'Patient Name',
                size: 100
            },
            {
                accessorKey: 'nhsId',
                header: 'NHS ID',
                size: 100,
            },
            {
                accessorKey: 'AdmissionDate',
                header: 'Admission Date',
                size: 100
            },
            {
                accessorKey: 'DischargeDate',
                header: 'Discharge Date',
                size: 100
            },
            {
                accessorKey: 'FunderType',
                header: 'Funder Type',
                size: 100
            },
            {
                accessorKey: 'FunderName',
                header: 'Funder Name',
                size: 100
            },
            {
                accessorKey: 'TotalWeeklyFees',
                header: 'Total Weekly Fees',
                size: 100
            },
            {
                accessorKey: 'WeeklyShareValue',
                header: 'Weekly Share Value',
                size: 100
            },
            {
                accessorKey: 'PlanName',
                header: 'Plan Name',
                size: 100
            },
            {
                accessorKey: 'PlanStartDate',
                header: 'Plan Start Date',
                size: 100
            },
            {
                accessorKey: 'PlanEndDate',
                header: 'Plan End Date',
                size: 100
            }
        ],
        []
    );
    const Data = [
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '23',
            patientName: 'Robert Little',
            nhsId: '8676777777',
            WeeklyShareValue: '427',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            AdmissionDate:'04/10/2024',
            DischargeDate:'14/06/2024',
            FunderType:'LA',
            FunderName:'NHS South East London CCG',
            TotalWeeklyFees:'4200.00'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '23',
            patientName: 'Robert Little',
            nhsId: '8676777777',
            WeeklyShareValue: '427',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            AdmissionDate:'04/10/2024',
            DischargeDate:'14/06/2024',
            FunderType:'ICB',
            FunderName:'BURY CCG',
            TotalWeeklyFees:'4200.00'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '23',
            patientName: 'Robert Little',
            nhsId: '8676777777',
            WeeklyShareValue: '427',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            AdmissionDate:'04/10/2024',
            DischargeDate:'14/06/2024',
            FunderType:'ICB',
            FunderName:'BURY CCG',
            TotalWeeklyFees:'4200.00'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '23',
            patientName: 'Robert Little',
            nhsId: '8676777777',
            WeeklyShareValue: '427',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            AdmissionDate:'04/10/2024',
            DischargeDate:'14/06/2024',
            FunderType:'ICB',
            FunderName:'BURY CCG',
            TotalWeeklyFees:'4200.00'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '23',
            patientName: 'Robert Little',
            nhsId: '8676777777',
            WeeklyShareValue: '427',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            AdmissionDate:'04/10/2024',
            DischargeDate:'14/06/2024',
            FunderType:'ICB',
            FunderName:'BURY CCG',
            TotalWeeklyFees:'4200.00'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '23',
            patientName: 'Robert Little',
            nhsId: '8676777777',
            WeeklyShareValue: '427',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            AdmissionDate:'04/10/2024',
            DischargeDate:'14/06/2024',
            FunderType:'ICB',
            FunderName:'BURY CCG',
            TotalWeeklyFees:'4200.00'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '23',
            patientName: 'Robert Little',
            nhsId: '8676777777',
            WeeklyShareValue: '427',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            AdmissionDate:'04/10/2024',
            DischargeDate:'14/06/2024',
            FunderType:'ICB',
            FunderName:'BURY CCG',
            TotalWeeklyFees:'4200.00'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '23',
            patientName: 'Robert Little',
            nhsId: '8676777777',
            WeeklyShareValue: '427',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            AdmissionDate:'04/10/2024',
            DischargeDate:'14/06/2024',
            FunderType:'ICB',
            FunderName:'BURY CCG',
            TotalWeeklyFees:'4200.00'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '23',
            patientName: 'Robert Little',
            nhsId: '8676777777',
            WeeklyShareValue: '427',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            AdmissionDate:'04/10/2024',
            DischargeDate:'14/06/2024',
            FunderType:'ICB',
            FunderName:'BURY CCG',
            TotalWeeklyFees:'4200.00'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A',
            BedName: '23',
            patientName: 'Robert Little',
            nhsId: '8676777777',
            WeeklyShareValue: '427',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            AdmissionDate:'04/10/2024',
            DischargeDate:'14/06/2024',
            FunderType:'ICB',
            FunderName:'BURY CCG',
            TotalWeeklyFees:'4200.00'
        }
    ];
    let [showModel, setShowModel] = useState<boolean>(false);
    const handleClickExport = () => {
        setShowModel(true);
    };
    const [showSecondPortal, setShowSecondPortal] = useState(false);
    useEffect(() => {
        if (showSecondPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showSecondPortal]);
    const handleSecondModal = (event: any) => {
        event.preventDefault();
        setShowSecondPortal((prevState) => !prevState);
    };

    const [showExportPortal, setShowExportPortal] = useState(false);
    useEffect(() => {
        if (showExportPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showExportPortal]);
    const handleExportModal = (event: any) => {
        event.preventDefault();
        setShowExportPortal((prevState) => !prevState);
    };

    // const columnsModal = useMemo<MRT_ColumnDef<any>[]>(
    //     () => [
    //         {
    //             accessorKey: 'Organization', //access nested data with dot notation
    //             header: 'Organization',
    //             size: 100
    //         },
    //         {
    //             accessorKey: 'ServiceType',
    //             header: 'Service Type',
    //             size: 100
    //         },
    //         {
    //             accessorKey: 'ServiceName',
    //             header: 'Service Name',
    //             size: 100
    //         },
    //         {
    //             accessorKey: 'WardName',
    //             header: 'Ward Name | Bed Name',
    //             size: 200
    //         },
    //         {
    //             accessorKey: 'patientType',
    //             header: 'Patient Type',
    //             size: 100
    //         },
    //         {
    //             accessorKey: 'patientContactNumber',
    //             header: 'Patient Contact Number',
    //             size: 200
    //         },
    //         {
    //             accessorKey: 'patientEmail',
    //             header: 'Patient Email',
    //             size: 120
    //         },
    //         {
    //             accessorKey: 'NextOfKin',
    //             header: 'Next Of Kin',
    //             size: 120
    //         },
    //         {
    //             accessorKey: 'KinRelationship',
    //             header: 'Kin Relationship',
    //             size: 120
    //         }
    //     ],
    //     []
    // );
    // const DataModal = [
    //     {
    //         Organization: 'Valorum Care',
    //         ServiceType: 'Hospital',
    //         ServiceName: 'Mangna House',
    //         WardName: 'Ward A | 23',
    //         patientType: 'Respite',
    //         patientContactNumber: '07700123456',
    //         patientEmail: 'robert@gmail.com',
    //         NextOfKin: 'John Little',
    //         KinRelationship: 'Sister'
    //     },
    //     {
    //         Organization: 'Health Care',
    //         ServiceType: 'Hospital',
    //         ServiceName: 'Mangna House',
    //         WardName: 'Ward A | 23',
    //         patientType: 'Respite',
    //         patientContactNumber: '07700123456',
    //         patientEmail: 'robert@gmail.com',
    //         NextOfKin: 'John Little',
    //         KinRelationship: 'Sister'
    //     },
    //     {
    //         Organization: 'Valorum Care',
    //         ServiceType: 'Hospital',
    //         ServiceName: 'Mangna House',
    //         WardName: 'Ward A | 23',
    //         patientType: 'Respite',
    //         patientContactNumber: '07700123456',
    //         patientEmail: 'robert@gmail.com',
    //         NextOfKin: 'John Little',
    //         KinRelationship: 'Sister'
    //     }
    // ];

    return (
        <>
            <div className="container-fluid">
                <div className="row p-0">
                    <div className="col-xl-12 col-lg-12 mb-4">
                        <div className="box-shadow p-0">
                            <div>
                                <div className="administrator-header ">
                                    <div className="row align-items-center justify-content-between h-100">
                                        <div className="col-md-6">
                                            <p className="bold lead-text purple-text">Discharge Report</p>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <a href="/reports">
                                                <button className="btn btn-white btn-sm">
                                                    <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="administrator-content">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="d-flex mb-3 flex-column">
                                            <label className="bold mb-2">Service*</label>
                                            <div className="auto-complete-dropdown auto-complete-dropdown-cus mb-0">
                                                <SmartSearchDropdownContainer
                                                    smartSearchLabel={'Select Service'}
                                                    defaultValue={'Helllo'}
                                                    onChangeSmartSearch={(value: any) => {
                                                        const event = new Event('change');
                                                        let obj = {
                                                            name: 'messageCode',
                                                            value: value?.id
                                                        };
                                                    }}
                                                    smartSearch={'Role'}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-center">
                                        <button className="btn btn-secondary me-2">Clear</button>
                                        <button className="btn btn-primary me-2">Generate Report</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12 col-lg-12">
                        <div className="box-shadow p-0">
                            <div className="pt-3">
                                <div className="row pe-2 ps-2 d-flex justify-content-end">
                                    <div className="col-md-4 mb-3 d-flex justify-content-end">
                                        <div className="dropdown filter-dropdown me-2">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="">
                                                    <i className="fa fa-cog"></i> {t('TableManageColumns.Manage_Columns')}
                                                </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-columns report-dropdownmenu report-thirdcolumns-width" aria-labelledby="navbarDropdownMenuLink">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Patient Type
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Client ID
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Patient DOB
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Gender
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Patient Contact No
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Patient Email
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Patient Address
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Discharge Done On
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> LOS
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Funder Contact No
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Funder Email
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Funder Address
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Commissioner Name
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Commissioner Contact No.
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Commissioner Email
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Commissioner Address
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Daily Total Fee
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> CoreFee
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Continence Care Fee
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Continence Included in Care fee
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> FNC Care Fee
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> FNC Included in Care fee
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> 1_to_1_Rate
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> 1_to_1 Weekly Inclusion
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> 1_to_1 Included in Core Fee
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> 1_to_2_Rate
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> 1_to_2 Weekly Inclusion
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> 1_to_2 Included in Core Fee
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> 1_to_3_Rate
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> 1_to_3 Weekly Inclusion
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> 1_to_3 Included in Core Fee
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> 1_to_4_Rate
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> 1_to_4 Weekly Inclusion
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> 1_to_4 Included in Core Fee
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="" className="me-1" /> Contract Available
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="d-flex justify-content-end">
                                                            <label className="purple-text bold">
                                                                <i className="fa fa-undo me-1" aria-hidden="true"></i> Reset
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="dropdown filter-dropdown me-2">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div className="" onClick={() => props.onExportClick()}>
                                                <i className="fa-solid fa-file-export"></i> Export
                                            </div> 
                                        </a>
                                    </div> */}
                                        <ExportReportsContainer onExportClick={handleClickExport} />
                                    </div>
                                </div>
                                <div className="invoice-table reports-table">
                                    <MaterialReactTable
                                        columns={columns}
                                        data={Data}
                                        enableGrouping
                                        enableColumnActions={false}
                                        enableBottomToolbar={false}
                                        enableTopToolbar={false}
                                        enableColumnDragging={false}
                                        manualPagination
                                        manualSorting
                                    />
                                </div>
                                <div className="administrator-content">
                                    <div className="row align-items-center mt-3 invoice-table-pagination">
                                        <div className="col-lg-6">
                                            <div className="d-flex align-items-center">
                                                <p className="purple-text bold">Displaying 1 - 2 of 10</p>
                                                <div className="d-flex align-items-center ms-3">
                                                    <label className="mb-0 bold nowrap me-2">Page Size:</label>
                                                    <select className={`form-control white page-select`} id="supportedPersoIntegrationPageSize">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 ">
                                            <PaginationContainer currentPage={1} totalCount={100} pageSize={10} onPageChange={(page: any) => {}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    <RenderModalContainer>
                        {showModel && <ExportReportsModalContainer title="Export Report Details" onClose={() => setShowModel(false)}></ExportReportsModalContainer>}
                    </RenderModalContainer>
                }

                
                <ClientPortal selector="myportal" show={showExportPortal}>
                    <div className="overlay" onClick={handleExportModal}></div>
                    <div className="modal modal-extrasmall">
                        <div className="modal-content">
                            <div className="modal-header">
                                <p className="modal-title">Excel Export</p>
                                <a onClick={handleExportModal} className="removeButton">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                        <path
                                            fill="#ffffff"
                                            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                        />
                                    </svg>
                                </a>
                            </div>

                            <div className="modal-body">
                                <div className=" mb-2">
                                    <h3 className="mb-2 report-modal-heading">Select Export Options</h3>
                                    <div className="row">
                                        <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                                            <input type="radio" className="me-2" defaultChecked />
                                            <label>Export Selected Columns</label>
                                        </div>
                                        <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                                            <input type="radio" className="me-2" />
                                            <label>Export All Columns</label>
                                        </div>
                                        <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                                            <input type="radio" className="me-2" />
                                            <label>Export CSV</label>
                                        </div>
                                        <div className="selection-group mb-2 col-md-6 d-flex align-items-center">
                                            <input type="radio" className="me-2" />
                                            <label>Excel for all reports</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer justify-content-center">
                                <div className="d-flex align-items-center ms-2">
                                    <button className="btn primary-btn me-2">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ClientPortal>
            </div>
        </>
    );
};
export default DischargeReportView;
