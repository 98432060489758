import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

const ServiceReferralTableGroupByView = (props: any) => {
    const { t } = useTranslation();
    const { StatusGridFilterState, DataGridFilterState } = useSelector((state: RootState) => state.gridFilterState);
    
    let [RMGroupByStatus, setRMGroupByStatus] = useState<any>(false);
    let [RMGroupByCurrentStage, setRMGroupByCurrentStage] = useState<any>(false);
    let [count, setCount] = useState<any>(0);

    const eventHandler = (event: any) => {
        event.stopPropagation();
    };

    const onChangeGroupByEvent = (event: any) => {
        
        if (event.target.checked === true && event.target.name === 'groupByStatus') {
            setRMGroupByStatus(true);
            setCount(count + 1)
        } else if (event.target.checked === false && event.target.name === 'groupByStatus') {
            setRMGroupByStatus(false);
            setCount(count - 1)
        }

        if (event.target.checked === true && event.target.name === 'groupByCurrentStage') {
            setRMGroupByCurrentStage(true);
            setCount(count + 1)
        } else if (event.target.checked === false && event.target.name === 'groupByCurrentStage') {
            setRMGroupByCurrentStage(false);
            setCount(count - 1)
        }
    };

    const onClickRemoveAllGroupBy = () => {
        setRMGroupByStatus(false);
        setRMGroupByCurrentStage(false);
        setCount(0);
        props.onRemoveAllGroupBy(false)
    }

    useEffect(() => {
        if(StatusGridFilterState) {
            if(DataGridFilterState?.grouping?.groupByStatus) {
                setRMGroupByStatus(true);
                if(count !== 1 && count !== 2) {
                    setCount(count + 1)
                }                
                let obj: any = {
                    "status": true,
                    "name": 'groupByStatus'
                }
                props.onStateGroupByHandler(obj)
            }
            // else {
            //     setRMGroupByStatus(false);
            //     setCount(count - 1)
            // }
            if(DataGridFilterState?.grouping?.groupByCurrentStage) {
                setRMGroupByCurrentStage(true);
                if(count !== 2) {
                    setCount(count + 1)
                }   
                let obj: any = {
                    "status": true,
                    "name": 'groupByCurrentStage'
                }
                props.onStateGroupByHandler(obj)
            }
            // else {
            //     setRMGroupByCurrentStage(false);
            //     setCount(count - 1)
            // }

            if(DataGridFilterState?.grouping?.groupByStatus == false && DataGridFilterState?.grouping?.groupByCurrentStage == false) {
                setRMGroupByStatus(false);
                setRMGroupByCurrentStage(false);
                setCount(0);
            }
        }
    },[StatusGridFilterState, DataGridFilterState])

    return (
        <div>
            <div className="dropdown filter-dropdown me-2 mb-3">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="legend-label" data-tooltip-id="my-tooltip" data-tooltip-content={`Group By`}><i className="fa-solid fa-layer-group"></i>
                    {/* <span className='applied-filter'> Status <i className="fa-solid fa-chevron-right"></i> Current Stage <i className="fa-solid fa-chevron-right"></i> Service Type <a href='#' className='ms-2'><i className="fa-solid fa-xmark white-text" ></i></a></span> */}
                    { count > 0 && <span className='applied-filter'> {RMGroupByStatus === true && <>Status</>} { count > 1 && (RMGroupByStatus === true && RMGroupByCurrentStage === true) && <i className="fa-solid fa-chevron-right"></i>} {RMGroupByCurrentStage === true && <>Current Stage</>} <button className='ms-2 close-small-btn' ><i className="fa-solid fa-xmark white-text" onClick={onClickRemoveAllGroupBy}></i></button></span> }
                    </div>
                    <Tooltip id="my-tooltip" className="my-tooltip" />
                </a>
                <div className="dropdown-menu profile-dw-css" onClick={eventHandler} aria-labelledby="navbarDropdownMenuLink">
                    <div>
                        <label>
                            <input type="checkbox" name="groupByStatus" checked={RMGroupByStatus} onChange={(event: any) => {props.onChangeGroupByHandler(event); onChangeGroupByEvent(event)}}  className="me-1" /> {t('ReferralTable.Status')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input type="checkbox" name="groupByCurrentStage" checked={RMGroupByCurrentStage} onChange={(event: any) => {props.onChangeGroupByHandler(event); onChangeGroupByEvent(event)}} className="me-1" /> {t('ReferralTable.Current_Stage')}
                        </label>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default ServiceReferralTableGroupByView;