import React, { useEffect, useMemo, useState, useRef, useReducer } from 'react';
import warning from '../../../assets/images/warning.png';
import { useSelector, useDispatch } from 'react-redux';
import { rightNavigationPage, InvoiceStatisticsFrequency } from '../../../utils/constant';
import { setPageNavigationAction } from '../../../store/general/pageNavigationReducer';
import { RootState } from '../../../store';
import { Tooltip } from 'react-tooltip';
import eventEmitter from '../../../utils/eventEmitter';
import { useTranslation } from 'react-i18next';
import RenderModalContainer from '../../../components/RenderModal/indexContainer';
import DoughnutChartContainer from '../../../components/DoughnutChart/DoughnutChartContainer';
import BarChartContainer from '../../../components/BarChart/BarChartContainer';

import {
    MaterialReactTable,
    MRT_TableInstance,
    MRT_FullScreenToggleButton,
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_TablePagination,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToolbarAlertBanner,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_VisibilityState,
    type MRT_RowSelectionState,
    type MRT_GroupingState
} from 'material-react-table';
import { Box } from '@mui/material';
import TableFilterContainer from './TableFilter/TableFilterContainer';
import TableGroupByContainer from './TableGroupBy/TableGroupByContainer';
import TableManageColumnsContainer from './TableManageColumns/TableManageColumnsContainer';
import TableActionContainer from './TableAction/TableActionContainer';
import CreateNewButtonContainer from './CreateNewButton/CreateNewButtonContainer';
import SearchInvoiceContainer from './SearchInvoice/SearchInvoiceContainer';
import DownloadInvoiceContainer from './DownloadInvoice/DownloadInvoiceContainer';
import PaginationContainer from '../../../components/PaginationComponent/indexContainer';
import { getListInvoicesService, getInvoiceStatisticsService, getManagedColumnsService, UpdateManageColumnsService, UpdateInvoiceStatusService, DownloadInvoicesService } from '../../../service/invoicelist-service';
import { InvoiceFilterInvoiceStatus, InvoiceFilterPaymentStatus } from '../../../utils/constant';
import { setGridFilterStateAction } from '../../../store/general/gridFilterStateReducer';
// import {
//     getListInvoicesService,
//     getInvoiceStatisticsService,
//     getManagedColumnsService,
//     UpdateManageColumnsService,
//     UpdateInvoiceStatusService,
//     DownloadInvoicesService
// } from '../../../service/invoicelist-service';
// import { InvoiceFilterInvoiceStatus } from '../../../utils/constant';
import swal from 'sweetalert';
import { InvoiceFilterInvoiceType } from '../../../utils/constant';

//let PageSize = 10;

type Invoice = {
    invoice_Pattern: string;
    invoice_Type: any;
    nhS_Id: any;
    patient_Name: string;
    funder_Name: string;
    admission_date: string;
    est_Discharge_Date: string;
    period_From: string;
    period_To: string;
    invoice_Date: string;
    due_Date: string;
    payment_Reference: string;
    payment_Status: string;
    status: string;
    amount: string;
    product_Description: string;
    quantity: string;
};

type invoiceListPayload = {
    searchValue: string;
    sortBy: string;
    sortOrder: string;
    page: any;
    pageSize: any;
    frequency: any;
    filter: {
        myInvoices: boolean;
        status: any;
        paymentStatus: any;
        isInvoiceDate: boolean;
        invoiceDateTo: any;
        invoiceDateFrom: any;
        isDueDate: boolean;
        dueDateTo: any;
        dueDateFrom: any;
        isServiceDate: boolean;
        serviceDateTo: any;
        serviceDateFrom: any;
        isInvoiceType: boolean;
        invoiceType: any;
        serviceId: any;
    };
    grouping: {
        groupByFunder: boolean;
        groupByPatient: boolean;
        typeOfInvoice: {
            groupByInterimInvoice: boolean;
            groupByDischargeInvoice: boolean;
            groupByReserveInvoice: boolean;
        };
    }
};

const InvoiceTableView = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { StatusServiceDetail, DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
    const { StatusGridFilterState, DataGridFilterState } = useSelector((state: RootState) => state.gridFilterState);
    const [showToolbar, setShowToolbar] = useState<any>(false);
    const [patientName, setPatientName] = useState<any>();
    const [data, setData] = useState<Invoice[]>([]);
    const tableInstanceRef = useRef<MRT_TableInstance<Invoice>>(null);
    const rerender = useReducer(() => ({}), {})[1];
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [PageSize, sepPageSize] = useState(10);

    const [invoiceListPayload, setInvoiceListPayload] = useState<invoiceListPayload>({
        searchValue: '',
        sortBy: '',
        sortOrder: '',
        page: 1,
        pageSize: PageSize,
        frequency: InvoiceStatisticsFrequency.CurrentYear,
        filter: {
            myInvoices: false,
            status: [],
            paymentStatus: [],
            isInvoiceDate: false,
            invoiceDateTo: null,
            invoiceDateFrom: null,
            isDueDate: false,
            dueDateTo: null,
            dueDateFrom: null,
            isServiceDate: false,
            serviceDateTo: null,
            serviceDateFrom: null,
            isInvoiceType: false,
            invoiceType: [],
            serviceId: DataServiceDetail.serviceId
        },
        grouping: {
            groupByFunder: false,
            groupByPatient: false,
            typeOfInvoice: {
                groupByInterimInvoice: false,
                groupByDischargeInvoice: false,
                groupByReserveInvoice: false
            }
        }
    });
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [totalCount, setTotalCount] = useState<any>();
    const [showFrom, setShowFrom] = useState<any>();
    const [showTo, setShowTo] = useState<any>();
    const [managedColumnsList, SetManagedColumnsList] = useState<any>([]);
    const [groupByOrder, setGroupByOrder] = useState<any>([
        {
            order: 1,
            value: ''
        },
        {
            order: 2,
            value: ''
        },
        {
            order: 3,
            value: ''
        }
    ]);

    //table state
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [grouping, setGrouping] = useState<MRT_GroupingState>([]);
    // const [pagination, setPagination] = useState<MRT_PaginationState>({
    //     pageIndex: 0,
    //     pageSize: 10
    // });
    const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>({});
    const { PageName, Data } = useSelector((state: RootState) => state.pageNavigation);

    useEffect(()=>{
       
    },[columnVisibility])

    const handleSizeChange = (e: any) => {
        sepPageSize(Number(e.target.value));

        let tempInvoiceListPayload: any = invoiceListPayload;
        tempInvoiceListPayload.page = 1;
        tempInvoiceListPayload.pageSize = Number(e.target.value);
        setCurrentPage(1);
        setInvoiceListPayload(tempInvoiceListPayload);
        getInvoiceListData();
    };

    //Set table columns
    const columns = useMemo<MRT_ColumnDef<Invoice>[]>(
        () => [
            {
                accessorKey: 'invoice_Pattern',
                header: 'Invoice Number',
                muiTableBodyCellProps: ({ row, cell }) => ({
                    className: 'purple-text',
                    onClick: () => {
                        if (row?.original?.invoice_Pattern) {
                            onClickInvoiceNoHandler(row.original);
                        }
                    },
                    sx: {
                        color: 'secondary',
                        cursor: 'pointer'
                    }
                }),


                size: 50
            },
            {
                accessorKey: 'nhS_Id',
                header: 'NHS ID/ Client ID',
                size: 50
            },
            {
                accessorKey: 'invoice_Type',
                header: 'Type of Invoice',
                enableHiding: true,
                size: 50
            },
            {
                accessorKey: 'patient_Name',
                header: 'Patient Name',
                size: 180,
                muiTableBodyCellProps: ({ row, cell }) => ({
                    className: 'nowrap'
                }),
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={cell.getValue<string>()}
                        sx={() => ({
                            maxWidth: '130px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'inline-block',
                            width: 'auto',
                            float: 'left'
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                )
            },
            {
                accessorKey: 'funder_Name',
                header: 'Funder Name',
                size: 180,
                muiTableBodyCellProps: ({ row, cell }) => ({
                    className: 'nowrap'
                }),
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={cell.getValue<string>()}
                        sx={() => ({
                            maxWidth: '130px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'inline-block',
                            width: 'auto',
                            float: 'left'
                        })}
                    >
                        {cell.getValue()}

                    </Box>
                ),



            },
            {
                accessorKey: 'admission_date',
                header: 'Date of Admit',
                size: 50
            },
            {
                accessorKey: 'est_Discharge_Date',
                header: 'Date of Discharge',
                size: 50
            },
            {
                accessorKey: 'period_From',
                header: 'Period From',
                size: 50
            },
            {
                accessorKey: 'period_To',
                header: 'Period To',
                size: 50
            },
            {
                accessorKey: 'invoice_Date',
                header: 'Invoice Date',
                size: 50
            },
            {
                accessorKey: 'due_Date',
                header: 'Due Date',
                size: 50
            },
            {
                accessorKey: 'payment_Reference',
                header: 'Payment Reference'
            },
            {
                accessorKey: 'product_Description',
                header: 'Product',
                aggregationFn: 'sum',
                size: 170,
                AggregatedCell: ({ cell, table }) => (
                    <>
                        <Box sx={{ color: '#7c4c8e', fontWeight: 'bold' }}>
                            {/* {cell.getValue()} days */}
                            {cell.getValue()}
                        </Box>
                    </>
                ),
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={cell.getValue<string>()}
                        sx={() => ({
                            maxWidth: '150px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'inline-block',
                            width: 'auto',
                            float: 'left'
                        })}
                    >
                        {cell.getValue()}
                    </Box>
                )
            },
            {
                accessorKey: 'quantity',
                header: 'Quantity',
                aggregationFn: 'sum',
                size: 100,
                AggregatedCell: ({ cell, table }) => (
                    <>
                        <Box sx={{ color: '#7c4c8e', fontWeight: 'bold' }}>
                            {/* {cell.getValue()} days */}
                            {cell.getValue()}
                        </Box>
                    </>
                ),
                Cell: ({ cell }) => (
                    <Box component="span">
                        {cell.getValue()}
                        {/* {cell.getValue()} days */}

                    </Box>
                ),


            },
            {
                accessorKey: 'amount',
                header: 'Amount',
                aggregationFn: 'sum',
                size: 30,
                muiTableHeadCellProps: {
                    align: 'right',
                    className: 'padding-right-30',
                },

                AggregatedCell: ({ cell, table }) => (
                    <>
                        <Box sx={{ color: '#7c4c8e', fontWeight: 'bold', textAlign: 'right', paddingRight: '20px' }}>
                            £{' '}
                            {cell.getValue<number>()?.toLocaleString?.('en-US', {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}
                        </Box>
                    </>
                ),
                Cell: ({ cell }) => (
                    <Box
                        sx={{ textAlign: 'right', paddingRight: '20px' }}
                    >
                        £ {cell.getValue<number>()?.toLocaleString?.('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })}

                    </Box>
                )
            },
            {
                accessorKey: 'payment_Status',
                header: 'Payment Status',
                size: 50,
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={() => ({
                            backgroundColor:
                                cell.getValue() === "Overdue"
                                    ? '#ffddce'
                                    : cell.getValue() === "Unpaid"
                                        ? '#ffe0e0'
                                        : cell.getValue() === "Paid"
                                            ? '#C3F3DB'
                                            : cell.getValue() === "In Payment"
                                                ? '#F2F3A1'
                                                : '',
                            borderRadius: '33px',
                            fontSize: '12px',
                            p: '4px 8px',
                            fontWeight: 'bold',
                            color:
                                cell.getValue() === "Overdue"
                                    ? '#8d604c'
                                    : cell.getValue() === "Unpaid"
                                        ? '#CF544D'
                                        : cell.getValue() === "Paid"
                                            ? '#14804A'
                                            : cell.getValue() === "In Payment"
                                                ? '#878901'
                                                : '',
                        })}
                    >
                        {cell.getValue()}

                    </Box>
                ),


            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 50,
                Cell: ({ cell }) => (
                    <Box
                        component="span"
                        sx={() => ({
                            backgroundColor:
                                cell.getValue() === "Draft"
                                    ? '#D7D7F4'
                                    : cell.getValue() === "Posted"
                                        ? '#C3F3DB'
                                        : cell.getValue() === "Cancelled"
                                            ? '#dddddd'
                                            : '',
                            borderRadius: '33px',
                            fontSize: '12px',
                            p: '4px 8px',
                            fontWeight: 'bold',
                            color:
                                cell.getValue() === "Draft"
                                    ? '#5856a9'
                                    : cell.getValue() === "Posted"
                                        ? '#14804A'
                                        : cell.getValue() === "Cancelled"
                                            ? '#4E4851'
                                            : '',
                        })}
                    >
                        {cell.getValue()}

                    </Box>
                )
            }
        ],
        []
    );

    const getInvoiceListData = async () => {
        let apiPayload: any = JSON.parse(JSON.stringify(invoiceListPayload));
        delete apiPayload["grouping"];
        await getInvoiceStatisticsService(apiPayload);
        const resultData = await getListInvoicesService(apiPayload);

        let payload = JSON.parse(JSON.stringify(invoiceListPayload));
        dispatch(setGridFilterStateAction(payload))
        if (resultData.status === true && resultData.data !== undefined && resultData.data !== null && resultData.data.length > 0) {
            setTotalCount(resultData.totalCount);
            setData(resultData.data);
        } else {
            setTotalCount(0);
            setData([]);
        }
    };

    const getManagedColumnsListData = async () => {
        const resultData = await getManagedColumnsService();
        if (resultData.status === true && resultData.data !== undefined && resultData.data !== null && resultData.data.length > 0) {
            SetManagedColumnsList(resultData.data);
        } else {
            SetManagedColumnsList([]);
        }
    };

    const changeSearchValue = (event: any) => {
        if (event.target.name === 'invoiceSmartSearch') {
            if (event.target.value !== '') {
                let tempInvoiceListPayload: any = invoiceListPayload;
                tempInvoiceListPayload.searchValue = event.target.value;
                tempInvoiceListPayload.page = 1;
                setCurrentPage(1);
                setInvoiceListPayload(tempInvoiceListPayload);
            } else {
                let tempInvoiceListPayload: any = invoiceListPayload;
                tempInvoiceListPayload.searchValue = '';
                tempInvoiceListPayload.page = 1;
                setCurrentPage(1);
                setInvoiceListPayload(tempInvoiceListPayload);
            }
            getInvoiceListData();
        }
    };

    const changeDatePayloadHandler = (data: any) => {
        let tempInvoiceListPayload: any = invoiceListPayload;
        if (data.isInvoiceDate === true && data.invoiceDateTo !== '' && data.invoiceDateFrom !== '') {
            tempInvoiceListPayload.filter.isInvoiceDate = data.isInvoiceDate;
            tempInvoiceListPayload.filter.invoiceDateTo = data.invoiceDateTo;
            tempInvoiceListPayload.filter.invoiceDateFrom = data.invoiceDateFrom;
            setInvoiceListPayload(tempInvoiceListPayload);
        } else if (data.isInvoiceDate === false) {
            tempInvoiceListPayload.filter.isInvoiceDate = data.isInvoiceDate;
            tempInvoiceListPayload.filter.invoiceDateTo = null;
            tempInvoiceListPayload.filter.invoiceDateFrom = null;
            setInvoiceListPayload(tempInvoiceListPayload);
        }

        if (data.isDueDate === true && data.dueDateTo !== '' && data.dueDateFrom !== '') {
            tempInvoiceListPayload.filter.isDueDate = data.isDueDate;
            tempInvoiceListPayload.filter.dueDateTo = data.dueDateTo;
            tempInvoiceListPayload.filter.dueDateFrom = data.dueDateFrom;
            setInvoiceListPayload(tempInvoiceListPayload);
        } else if (data.isDueDate === false) {
            tempInvoiceListPayload.filter.isDueDate = data.isDueDate;
            tempInvoiceListPayload.filter.dueDateTo = null;
            tempInvoiceListPayload.filter.dueDateFrom = null;
            setInvoiceListPayload(tempInvoiceListPayload);
        }

        if (data.isServiceDate === true && data.serviceDateTo !== '' && data.serviceDateFrom !== '') {
            tempInvoiceListPayload.filter.isServiceDate = data.isServiceDate;
            tempInvoiceListPayload.filter.serviceDateTo = data.serviceDateTo;
            tempInvoiceListPayload.filter.serviceDateFrom = data.serviceDateFrom;
            setInvoiceListPayload(tempInvoiceListPayload);
        } else if (data.isServiceDate === false) {
            tempInvoiceListPayload.filter.isServiceDate = data.isServiceDate;
            tempInvoiceListPayload.filter.serviceDateTo = null;
            tempInvoiceListPayload.filter.serviceDateFrom = null;
            setInvoiceListPayload(tempInvoiceListPayload);
        }
        getInvoiceListData();
    };

    const changePayloadHandler = (event: any) => {
        let tempInvoiceListPayload: any = invoiceListPayload;
        tempInvoiceListPayload.page = 1;
        setCurrentPage(1);
        if (event.target.checked === true && event.target.name === 'myInvoice') {
            tempInvoiceListPayload.filter.myInvoices = true;
            setInvoiceListPayload(tempInvoiceListPayload);
        } else if (event.target.checked === false && event.target.name === 'myInvoice') {
            tempInvoiceListPayload.filter.myInvoices = false;
            setInvoiceListPayload(tempInvoiceListPayload);
        }
        if (event.target.checked === true && (event.target.name === 'draft' || event.target.name === 'posted' || event.target.name === 'cancelled')) {
            tempInvoiceListPayload?.filter.status.push(Number(event.target.value));
            setInvoiceListPayload(tempInvoiceListPayload);
        } else if (event.target.checked === false && (event.target.name === 'draft' || event.target.name === 'posted' || event.target.name === 'cancelled')) {
            const index = tempInvoiceListPayload?.filter.status.indexOf(Number(event.target.value));
            if (index > -1) {
                tempInvoiceListPayload?.filter.status.splice(index, 1);
                setInvoiceListPayload(tempInvoiceListPayload);
            }
        }
        if (event.target.checked === true && (event.target.name === 'unpaid' || event.target.name === 'paid' || event.target.name === 'overDue' || event.target.name === 'inPayment')) {
            tempInvoiceListPayload?.filter.paymentStatus.push(Number(event.target.value));
            setInvoiceListPayload(tempInvoiceListPayload);
        } else if (event.target.checked === false && (event.target.name === 'unpaid' || event.target.name === 'paid' || event.target.name === 'overDue' || event.target.name === 'inPayment')) {
            const index = tempInvoiceListPayload?.filter.paymentStatus.indexOf(Number(event.target.value));
            if (index > -1) {
                tempInvoiceListPayload?.filter.paymentStatus.splice(index, 1);
                setInvoiceListPayload(tempInvoiceListPayload);
            }
        }
        getInvoiceListData();
    };

    const onChangeTypeInvoiceGroupByHandler = (data: any, ids: any) => {
        let tempInvoiceListPayload: any = invoiceListPayload;
        if (data.typeInvoice === true) {
            setGroupingHandler('invoice_Type', true);
            if (ids.includes(InvoiceFilterInvoiceType.Interim)) {
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByInterimInvoice = true
            } else {
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByInterimInvoice = false
            }
            if (ids.includes(InvoiceFilterInvoiceType.Discharge)) {
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByDischargeInvoice = true
            } else {
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByDischargeInvoice = false
            }
            if (ids.includes(InvoiceFilterInvoiceType.Reserve)) {
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByReserveInvoice = true
            } else {
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByReserveInvoice = false
            }
            if (ids.length > 0) {
                tempInvoiceListPayload.filter.isInvoiceType = true;
                tempInvoiceListPayload.filter.invoiceType = ids;
                let payload = JSON.parse(JSON.stringify(tempInvoiceListPayload));
                dispatch(setGridFilterStateAction(payload))
                setInvoiceListPayload(tempInvoiceListPayload);
                getInvoiceListData();
            }
        } else {
            if (ids.length > 0) {
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByInterimInvoice = false
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByDischargeInvoice = false
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByReserveInvoice = false

                tempInvoiceListPayload.filter.isInvoiceType = false;
                tempInvoiceListPayload.filter.invoiceType = [];
                let payload = JSON.parse(JSON.stringify(tempInvoiceListPayload));
                dispatch(setGridFilterStateAction(payload))
                setInvoiceListPayload(tempInvoiceListPayload);
                getInvoiceListData();
            } else {
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByInterimInvoice = false
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByDischargeInvoice = false
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByReserveInvoice = false
                tempInvoiceListPayload.filter.isInvoiceType = false;
                tempInvoiceListPayload.filter.invoiceType = [];
                let payload = JSON.parse(JSON.stringify(tempInvoiceListPayload));
                dispatch(setGridFilterStateAction(payload))
                setInvoiceListPayload(tempInvoiceListPayload);
                getInvoiceListData();
            }
            setGroupingHandler('invoice_Type', false);
        }
        // setInvoiceListPayload(tempInvoiceListPayload)
    };
    const onChangeManageColumnHandler = async (data: any) => {
        if (data.ColumnName === 'NHS_Id' && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                nhS_Id: true
            }));
        } else if (data.ColumnName === 'NHS_Id' && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                nhS_Id: false
            }));
        }

        if (data.ColumnName === 'Admission_date' && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                admission_date: true
            }));
        } else if (data.ColumnName === 'Admission_date' && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                admission_date: false
            }));
        }

        if (data.ColumnName === 'Est_Discharge_Date' && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                est_Discharge_Date: true
            }));
        } else if (data.ColumnName === 'Est_Discharge_Date' && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                est_Discharge_Date: false
            }));
        }

        if (data.ColumnName === 'Period_From' && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                period_From: true
            }));
        } else if (data.ColumnName === 'Period_From' && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                period_From: false
            }));
        }

        if (data.ColumnName === 'Period_To' && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                period_To: true
            }));
        } else if (data.ColumnName === 'Period_To' && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                period_To: false
            }));
        }

        if (data.ColumnName === 'Due_Date' && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                due_Date: true
            }));
        } else if (data.ColumnName === 'Due_Date' && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                due_Date: false
            }));
        }

        if (data.ColumnName === 'Payment_Reference' && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                payment_Reference: true
            }));
        } else if (data.ColumnName === 'Payment_Reference' && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                payment_Reference: false
            }));
        }

        if (data.ColumnName === 'Product_Description' && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                product_Description: true
            }));
        } else if (data.ColumnName === 'Product_Description' && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                product_Description: false
            }));
        }

        if (data.ColumnName === 'Quantity' && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                quantity: true
            }));
        } else if (data.ColumnName === 'Quantity' && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                quantity: false
            }));
        }

        if (data.ColumnName === 'PaymentStatus' && data.IsActive === true) {
            setColumnVisibility((state: any) => ({
                ...state,
                payment_Status: true
            }));
        } else if (data.ColumnName === 'PaymentStatus' && data.IsActive === false) {
            setColumnVisibility((state: any) => ({
                ...state,
                payment_Status: false
            }));
        }
        const result = await UpdateManageColumnsService(data);
    };
    const onRemoveAllGroupBy = (status: any) => {
        let tempInvoiceListPayload: any = invoiceListPayload;
        tempInvoiceListPayload.grouping.groupByFunder = false
        tempInvoiceListPayload.grouping.groupByPatient = false
        tempInvoiceListPayload.grouping.typeOfInvoice.groupByInterimInvoice = false
        tempInvoiceListPayload.grouping.typeOfInvoice.groupByDischargeInvoice = false
        tempInvoiceListPayload.grouping.typeOfInvoice.groupByReserveInvoice = false
        tempInvoiceListPayload.filter.isInvoiceType = false;
        tempInvoiceListPayload.filter.invoiceType = [];
        setGroupingHandler('invoice_Type', false);
        setGroupingHandler('funder_Name', false);
        setGroupingHandler('patient_Name', false);
        setInvoiceListPayload(tempInvoiceListPayload)
        let payload = JSON.parse(JSON.stringify(tempInvoiceListPayload));
        dispatch(setGridFilterStateAction(payload))
        getInvoiceListData();
    };
    const onChangeGroupByHandler = (event: any) => {
        let tempInvoiceListPayload: any = invoiceListPayload;
        if (event.target.checked === true && event.target.name === 'groupByFunder') {
            tempInvoiceListPayload.grouping.groupByFunder = true
            setGroupingHandler('funder_Name', true);
        } else if (event.target.checked === false && event.target.name === 'groupByFunder') {
            tempInvoiceListPayload.grouping.groupByFunder = false
            setGroupingHandler('funder_Name', false);
        }

        if (event.target.checked === true && event.target.name === 'groupByPatient') {
            tempInvoiceListPayload.grouping.groupByPatient = true
            setGroupingHandler('patient_Name', true);
        } else if (event.target.checked === false && event.target.name === 'groupByPatient') {
            tempInvoiceListPayload.grouping.groupByPatient = false
            setGroupingHandler('patient_Name', false);
        }
        setInvoiceListPayload(tempInvoiceListPayload)
        let payload = JSON.parse(JSON.stringify(tempInvoiceListPayload));
        dispatch(setGridFilterStateAction(payload))
    };

    const onStateGroupByHandler = (data: any) => {
        let tempInvoiceListPayload: any = invoiceListPayload;
        if (data.status === true && data.name === 'groupByFunder') {
            setGroupingHandler('funder_Name', true);
        } else if (data.status === false && data.name === 'groupByFunder') {
            setGroupingHandler('funder_Name', false);
        }

        if (data.status === true && data.name === 'groupByPatient') {
            setGroupingHandler('patient_Name', true);
        } else if (data.status === false && data.name === 'groupByPatient') {
            setGroupingHandler('patient_Name', false);
        }

        if (data.status === true && data.name === 'groupByInvoiceType') {
            let ids: any = []
            if (DataGridFilterState?.grouping?.typeOfInvoice?.groupByInterimInvoice) {
                ids.push(InvoiceFilterInvoiceType.Interim)
            }
            if (DataGridFilterState?.grouping?.typeOfInvoice?.groupByDischargeInvoice) {
                ids.push(InvoiceFilterInvoiceType.Discharge)
            }
            if (DataGridFilterState?.grouping?.typeOfInvoice?.groupByReserveInvoice) {
                ids.push(InvoiceFilterInvoiceType.Reserve)
            }
            // tempInvoiceListPayload.filter.isInvoiceType = true;
            // tempInvoiceListPayload.filter.invoiceType = ids;
            // setInvoiceListPayload(tempInvoiceListPayload);
            // getInvoiceListData();
            setGroupingHandler('invoice_Type', true);
        } else if (data.status === false && data.name === 'groupByInvoiceType') {
            setGroupingHandler('invoice_Type', false);
        }
    };

    const getCurrentPage = (page: any) => {
        setCurrentPage(page);
        let tempInvoiceListPayload: any = invoiceListPayload;
        tempInvoiceListPayload.page = page;
        setInvoiceListPayload(tempInvoiceListPayload);
        getInvoiceListData();
    };

    useEffect(() => {
        if (managedColumnsList.length > 0) {
            managedColumnsList.map((val: any) => {
                if (val.columnName === 'NHS_Id') {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        nhS_Id: true
                    }));
                }

                if (val.columnName === 'Admission_date') {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        admission_date: true
                    }));
                }

                if (val.columnName === 'Est_Discharge_Date') {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        est_Discharge_Date: true
                    }));
                }

                if (val.columnName === 'Period_From') {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        period_From: true
                    }));
                }

                if (val.columnName === 'Period_To') {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        period_To: true
                    }));
                }

                if (val.columnName === 'Due_Date') {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        due_Date: true
                    }));
                }

                if (val.columnName === 'Payment_Reference') {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        payment_Reference: true
                    }));
                }

                if (val.columnName === 'Product_Description') {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        product_Description: true
                    }));
                }

                if (val.columnName === 'Quantity') {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        quantity: true
                    }));
                }

                if (val.columnName === 'PaymentStatus') {
                    setColumnVisibility((state: any) => ({
                        ...state,
                        payment_Status: true
                    }));
                }
            });
        }
    }, [managedColumnsList]);

    useEffect(() => {
        if (tableInstanceRef.current !== null) {
            setShowToolbar(true);
        } else {
            setShowToolbar(false);
        }
    }, [tableInstanceRef]);

    // useEffect(() => {
    //     let tempInvoiceListPayload: any = invoiceListPayload;
    //     if (sorting.length > 0) {
    //         if (sorting[0].desc) {
    //             tempInvoiceListPayload.sortBy = sorting[0].id;
    //             tempInvoiceListPayload.sortOrder = 'desc';
    //             setInvoiceListPayload(tempInvoiceListPayload);
    //         } else {
    //             tempInvoiceListPayload.sortBy = sorting[0].id;
    //             tempInvoiceListPayload.sortOrder = 'asc';
    //             setInvoiceListPayload(tempInvoiceListPayload);
    //         }
    //     } else {
    //         tempInvoiceListPayload.sortBy = '';
    //         tempInvoiceListPayload.sortOrder = '';
    //         setInvoiceListPayload(tempInvoiceListPayload);
    //     }
    //     
    // }, [sorting]);

    useEffect(() => {
        let payload = {
            searchValue: '',
            sortBy: '',
            sortOrder: '',
            page: currentPage,
            pageSize: PageSize,
            frequency: InvoiceStatisticsFrequency.CurrentYear,
            filter: {
                myInvoices: false,
                status: [],
                paymentStatus: [],
                isInvoiceDate: false,
                invoiceDateTo: null,
                invoiceDateFrom: null,
                isDueDate: false,
                dueDateTo: null,
                dueDateFrom: null,
                isServiceDate: false,
                serviceDateTo: null,
                serviceDateFrom: null,
                isInvoiceType: false,
                invoiceType: [],
                serviceId: DataServiceDetail.serviceId
            },
            grouping: {
                groupByFunder: DataGridFilterState?.grouping?.groupByFunder ? true : false,
                groupByPatient: DataGridFilterState?.grouping?.groupByPatient ? true : false,
                typeOfInvoice: {
                    groupByInterimInvoice: DataGridFilterState?.grouping?.typeOfInvoice?.groupByInterimInvoice ? true : false,
                    groupByDischargeInvoice: DataGridFilterState?.grouping?.typeOfInvoice?.groupByDischargeInvoice ? true : false,
                    groupByReserveInvoice: DataGridFilterState?.grouping?.typeOfInvoice?.groupByReserveInvoice ? true : false,
                }
            }
        };
        setInvoiceListPayload(payload);
        // getInvoiceListData();
        getManagedColumnsListData();
        setColumnVisibility({
            nhS_Id: false,
            admission_date: false,
            est_Discharge_Date: false,
            period_From: false,
            period_To: false,
            due_Date: false,
            payment_Reference: false,
            product_Description: false,
            quantity: false,
            payment_Status: false
        });
    }, []);
    var count = 0;
    useEffect(() => {
        let tempInvoiceListPayload: any = invoiceListPayload;
        if (DataServiceDetail.serviceId != invoiceListPayload.filter.serviceId) {
            setGroupingHandler('invoice_Type', false);
            setGroupingHandler('funder_Name', false);
            setGroupingHandler('patient_Name', false);
            tempInvoiceListPayload.searchValue = '';
            tempInvoiceListPayload.sortBy = '';
            tempInvoiceListPayload.sortOrder = '';
            tempInvoiceListPayload.page = 1;
            tempInvoiceListPayload.pageSize = PageSize;
            tempInvoiceListPayload.frequency = InvoiceStatisticsFrequency.CurrentYear;
            tempInvoiceListPayload.filter.myInvoices = false;
            tempInvoiceListPayload.filter.status = [];
            tempInvoiceListPayload.filter.paymentStatus = [];
            tempInvoiceListPayload.filter.isInvoiceDate = false;
            tempInvoiceListPayload.filter.invoiceDateTo = null;
            tempInvoiceListPayload.filter.invoiceDateFrom = null;
            tempInvoiceListPayload.filter.isDueDate = false;
            tempInvoiceListPayload.filter.dueDateTo = null;
            tempInvoiceListPayload.filter.dueDateFrom = null;
            tempInvoiceListPayload.filter.isServiceDate = false;
            tempInvoiceListPayload.filter.serviceDateTo = null;
            tempInvoiceListPayload.filter.serviceDateFrom = null;
            tempInvoiceListPayload.filter.isInvoiceType = false;
            tempInvoiceListPayload.filter.invoiceType = [];
            tempInvoiceListPayload.grouping.groupByFunder = false;
            tempInvoiceListPayload.grouping.groupByPatient = false;
            tempInvoiceListPayload.grouping.typeOfInvoice.groupByInterimInvoice = false;
            tempInvoiceListPayload.grouping.typeOfInvoice.groupByDischargeInvoice = false;
            tempInvoiceListPayload.grouping.typeOfInvoice.groupByReserveInvoice = false;
        }

        if (Data.patientName !== undefined && Data.patientName !== '') {
            tempInvoiceListPayload.searchValue = Data.patientName;
            setPatientName(Data.patientName);
            let obj: any = {
                ShowClass: false,
                PageName: rightNavigationPage.InvoiceTablePage,
                Data: '',
                ShowToggle: false
            };
            dispatch(setPageNavigationAction(obj));
            // getInvoiceListData();
        } else {
            setPatientName(undefined);
        }
        if (Data.isDraft !== undefined && Data.isDraft === true) {
            tempInvoiceListPayload?.filter.status.push(Number(InvoiceFilterInvoiceStatus.Draft));
            let obj: any = {
                ShowClass: false,
                PageName: rightNavigationPage.InvoiceTablePage,
                Data: '',
                ShowToggle: false
            };
            dispatch(setPageNavigationAction(obj));
        }
        if (sorting.length > 0) {

            if (sorting[0].desc) {
                tempInvoiceListPayload.sortBy = sorting[0].id;
                tempInvoiceListPayload.sortOrder = 'desc';
            } else {
                tempInvoiceListPayload.sortBy = sorting[0].id;
                tempInvoiceListPayload.sortOrder = 'asc';
            }
        } else {
            tempInvoiceListPayload.sortBy = '';
            tempInvoiceListPayload.sortOrder = '';
        }
        tempInvoiceListPayload.filter.serviceId = DataServiceDetail.serviceId;

        if (StatusGridFilterState) {
            if (DataGridFilterState?.filter?.isInvoiceDate === true && DataGridFilterState?.filter?.invoiceDateTo !== '' && DataGridFilterState?.filter?.invoiceDateFrom !== '') {
                tempInvoiceListPayload.filter.isInvoiceDate = DataGridFilterState?.filter?.isInvoiceDate ? DataGridFilterState?.filter?.isInvoiceDate : false
                tempInvoiceListPayload.filter.invoiceDateTo = DataGridFilterState?.filter?.invoiceDateTo ? DataGridFilterState?.filter?.invoiceDateTo : null
                tempInvoiceListPayload.filter.invoiceDateFrom = DataGridFilterState?.filter?.invoiceDateFrom ? DataGridFilterState?.filter?.invoiceDateFrom : null
            }

            if (DataGridFilterState?.filter?.isDueDate === true && DataGridFilterState?.filter?.dueDateTo !== '' && DataGridFilterState?.filter?.dueDateFrom !== '') {
                tempInvoiceListPayload.filter.isDueDate = DataGridFilterState?.filter?.isDueDate ? DataGridFilterState?.filter?.isDueDate : false
                tempInvoiceListPayload.filter.dueDateTo = DataGridFilterState?.filter?.dueDateTo ? DataGridFilterState?.filter?.dueDateTo : null
                tempInvoiceListPayload.filter.dueDateFrom = DataGridFilterState?.filter?.dueDateFrom ? DataGridFilterState?.filter?.dueDateFrom : null
            }

            if (DataGridFilterState?.filter?.isServiceDate === true && DataGridFilterState?.filter?.serviceDateTo !== '' && DataGridFilterState?.filter?.serviceDateFrom !== '') {
                tempInvoiceListPayload.filter.isServiceDate = DataGridFilterState?.filter?.isServiceDate ? DataGridFilterState?.filter?.isServiceDate : false
                tempInvoiceListPayload.filter.serviceDateTo = DataGridFilterState?.filter?.serviceDateTo ? DataGridFilterState?.filter?.serviceDateTo : null
                tempInvoiceListPayload.filter.serviceDateFrom = DataGridFilterState?.filter?.serviceDateFrom ? DataGridFilterState?.filter?.serviceDateFrom : null
            }

            if (DataGridFilterState?.filter?.status != undefined && DataGridFilterState?.filter?.status.length > 0) {
                tempInvoiceListPayload.filter.status = []
                if (DataGridFilterState?.filter?.status.includes(InvoiceFilterInvoiceStatus.Draft)) {
                    tempInvoiceListPayload.filter.status.push(InvoiceFilterInvoiceStatus.Draft)
                }
                if (DataGridFilterState?.filter?.status.includes(InvoiceFilterInvoiceStatus.PostedOrConfirm)) {
                    tempInvoiceListPayload.filter.status.push(InvoiceFilterInvoiceStatus.PostedOrConfirm)
                }
                if (DataGridFilterState?.filter?.status.includes(InvoiceFilterInvoiceStatus.Cancelled)) {
                    tempInvoiceListPayload.filter.status.push(InvoiceFilterInvoiceStatus.Cancelled)
                }
            }
            if (DataGridFilterState?.filter?.paymentStatus?.length > 0) {
                tempInvoiceListPayload.filter.paymentStatus = []
                if (DataGridFilterState?.filter?.paymentStatus.includes(InvoiceFilterPaymentStatus.Unpaid)) {
                    tempInvoiceListPayload.filter.paymentStatus.push(InvoiceFilterPaymentStatus.Unpaid)
                }
                if (DataGridFilterState?.filter?.paymentStatus.includes(InvoiceFilterPaymentStatus.Paid)) {
                    tempInvoiceListPayload.filter.paymentStatus.push(InvoiceFilterPaymentStatus.Paid)
                }
                if (DataGridFilterState?.filter?.paymentStatus.includes(InvoiceFilterPaymentStatus.InPayment)) {
                    tempInvoiceListPayload.filter.paymentStatus.push(InvoiceFilterPaymentStatus.InPayment)
                }
                if (DataGridFilterState?.filter?.paymentStatus.includes(InvoiceFilterPaymentStatus.Overdue)) {
                    tempInvoiceListPayload.filter.paymentStatus.push(InvoiceFilterPaymentStatus.Overdue)
                }
            }

            if (DataGridFilterState?.filter?.myInvoices) {
                tempInvoiceListPayload.filter.myInvoices = true
            }

            if (DataGridFilterState?.grouping?.groupByFunder) {
                tempInvoiceListPayload.grouping.groupByFunder = true
            }
            if (DataGridFilterState?.grouping?.groupByPatient) {
                tempInvoiceListPayload.grouping.groupByPatient = true
            }
            if (DataGridFilterState?.grouping?.typeOfInvoice?.groupByInterimInvoice) {
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByInterimInvoice = true
            }
            if (DataGridFilterState?.grouping?.typeOfInvoice?.groupByDischargeInvoice) {
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByDischargeInvoice = true
            }
            if (DataGridFilterState?.grouping?.typeOfInvoice?.groupByReserveInvoice) {
                tempInvoiceListPayload.grouping.typeOfInvoice.groupByReserveInvoice = true
            }

            if (DataGridFilterState?.searchValue !== '') {
                tempInvoiceListPayload.searchValue = DataGridFilterState?.searchValue
            }

            // if(DataGridFilterState?.sortBy !== '') {
            //     tempInvoiceListPayload.sortBy = DataGridFilterState?.sortBy
            // }

            // if(DataGridFilterState?.sortOrder !== '') {

            //     tempInvoiceListPayload.sortOrder = DataGridFilterState?.sortOrder
            // }

            if (DataGridFilterState?.page !== '') {
                setCurrentPage(DataGridFilterState?.page)
                tempInvoiceListPayload.page = DataGridFilterState?.page
            }

            if (DataGridFilterState?.pageSize !== '') {
                sepPageSize(DataGridFilterState?.pageSize)
                tempInvoiceListPayload.pageSize = DataGridFilterState?.pageSize
            }
        }
        setInvoiceListPayload(tempInvoiceListPayload);
        getInvoiceListData();
    }, [DataServiceDetail.serviceId, Data.patientName, sorting]);

    useEffect(() => {
        let showFromTemp = (currentPage - 1) * PageSize + 1;
        let showToTemp = showFromTemp + PageSize;
        showToTemp = showToTemp - 1 > Number(totalCount) ? Number(totalCount) : showToTemp - 1;
        setShowFrom(showFromTemp);
        setShowTo(showToTemp);
    }, [currentPage, totalCount, PageSize])
    // useEffect(() => {
    //     if (Data.patientName !== undefined && Data.patientName !== '') {
    //         
    //         let tempInvoiceListPayload: any = invoiceListPayload;
    //         tempInvoiceListPayload.searchValue = Data.patientName;
    //         setPatientName(Data.patientName);
    //         setInvoiceListPayload(tempInvoiceListPayload);
    //         getInvoiceListData();
    //     } else {
    //         setPatientName(undefined);
    //     }
    // }, [Data.patientName]);

    const setGroupingHandler = (groupe: any, status: any) => {
        let groupArray = grouping;
        let tempArray: any = [];
        if (groupArray.length > 0) {
            if (groupe === 'invoice_Type' && status === true) {
                groupByOrder[0].value = 'invoice_Type';
                setGroupByOrder(groupByOrder);
            } else if (groupe === 'invoice_Type' && status === false) {
                groupByOrder[0].value = '';
                setGroupByOrder(groupByOrder);
            }
            if (groupe === 'funder_Name' && status === true) {
                groupByOrder[1].value = 'funder_Name';
                setGroupByOrder(groupByOrder);
            } else if (groupe === 'funder_Name' && status === false) {
                groupByOrder[1].value = '';
                setGroupByOrder(groupByOrder);
            }
            if (groupe === 'patient_Name' && status === true) {
                groupByOrder[2].value = 'patient_Name';
                setGroupByOrder(groupByOrder);
            } else if (groupe === 'patient_Name' && status === false) {
                groupByOrder[2].value = '';
                setGroupByOrder(groupByOrder);
            }
            groupByOrder.map((val: any) => {
                if (val.value !== '') {
                    tempArray = [...tempArray, val.value];
                }
            });
        } else {
            tempArray.push(groupe);
            if (groupe === 'invoice_Type' && status === true) {
                groupByOrder[0].value = 'invoice_Type';
            } else if (groupe === 'invoice_Type' && status === false) {
                groupByOrder[0].value = '';
            }
            if (groupe === 'funder_Name' && status === true) {
                groupByOrder[1].value = 'funder_Name';
            } else if (groupe === 'funder_Name' && status === false) {
                groupByOrder[1].value = '';
            }
            if (groupe === 'patient_Name' && status === true) {
                groupByOrder[2].value = 'patient_Name';
            } else if (groupe === 'patient_Name' && status === false) {
                groupByOrder[2].value = '';
            }
            if (groupByOrder[0].value == '' && groupByOrder[1].value == '' && groupByOrder[2].value == '') {
                tempArray = [];
            }
        }
        setGrouping(tempArray);
    };

    const onChangeActionHandler = async (data: any) => {
        let selectedRows = tableInstanceRef?.current?.getSelectedRowModel().rows;
        let ids: any = [];
        const selectedInvoiceCount = selectedRows?.length;
        if (selectedInvoiceCount === undefined || selectedInvoiceCount === 0) {
            swal('No invoice is selected.', {
                icon: 'error',
                dangerMode: true
            });
        }
        if (selectedInvoiceCount !== undefined && selectedInvoiceCount > 0 && data === 'Confirm') {
            swal({
                title: '',
                text: 'Do you wish to Confirm selected invoices?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    selectedRows?.map((val: any) => {
                        ids.push(Number(val.original.invoiceHeader_Id));
                    });
                    let payload = {
                        InvoiceHeaderId: ids,
                        status: InvoiceFilterInvoiceStatus.PostedOrConfirm
                    };
                    const response = await UpdateInvoiceStatusService(payload);
                    if (response.status == true) {
                        swal(response.message, {
                            icon: 'success'
                        });
                        getInvoiceListData();
                        tableInstanceRef?.current?.setRowSelection({});
                    } else {
                        swal(response.message, {
                            icon: 'error',
                            dangerMode: true
                        });
                    }
                }
            });
        }
        if (selectedInvoiceCount !== undefined && selectedInvoiceCount > 0 && data === 'Cancel') {
            swal({
                title: '',
                text: 'Do you wish to Cancel selected invoices?',
                icon: warning,
                buttons: ['No', 'Yes'],
                dangerMode: true
            }).then(async (confirm: any) => {
                if (confirm) {
                    selectedRows?.map((val: any) => {
                        ids.push(Number(val.original.invoiceHeader_Id));
                    });
                    let payload = {
                        InvoiceHeaderId: ids,
                        status: InvoiceFilterInvoiceStatus.Cancelled
                    };
                    const response = await UpdateInvoiceStatusService(payload);
                    if (response.status == true) {
                        swal(response.message, {
                            icon: 'success'
                        });
                        getInvoiceListData();
                    } else {
                        swal(response.message, {
                            icon: 'error',
                            dangerMode: true
                        });
                    }
                }
            });
        }
    };

    const onClickInvoiceNoHandler: Function = async (data: any) => {
        let obj: any = {
            ShowClass: false,
            PageName: rightNavigationPage.InvoiceDetailPage,
            Data: data,
            ShowToggle: false
        };
        dispatch(setPageNavigationAction(obj));
    };

    const onClickCreateNewInvoiceNoHandler: Function = async () => {
        let selectedRows = tableInstanceRef?.current?.getSelectedRowModel().rows;
        if (selectedRows !== undefined && selectedRows?.length > 0) {
            if (selectedRows?.length > 1) {
                swal("Multiple invoices selected, please select any one invoice to create a new invoice.", {
                    icon: warning,
                    dangerMode: true
                });
            } else if (selectedRows?.length == 1 && selectedRows[0]?.original?.status == t('InvoiceDetail.Cancelled')) {
                swal("New invoice cannot be created from cancelled invoice.", {
                    icon: warning,
                    dangerMode: true
                });
            }
            else {
                let dataObj = selectedRows[0].original;
                // @ts-ignore
                dataObj.PageStatus = 'duplicate';
                let obj: any = {
                    ShowClass: false,
                    PageName: rightNavigationPage.InvoiceDetailPage,
                    Data: dataObj,
                    ShowToggle: false
                };
                dispatch(setPageNavigationAction(obj));
            }
        } else {
            let obj: any = {
                ShowClass: false,
                PageName: rightNavigationPage.InvoiceDetailPage,
                Data: '',
                ShowToggle: false
            };
            dispatch(setPageNavigationAction(obj));
        }
    };

    const onClickHandlerDownloadInvoices = async () => {
        const response = await DownloadInvoicesService(invoiceListPayload);
        let mediaType = response.data.fileType;
        let useInput = response.data.csvData;
        // let csvContent = atob(response.data.csvData);
        // let data = new Blob([csvContent], { type: response.data.fileType });
        // let csvURL = window.URL.createObjectURL(data);
        let tempLink = document.createElement('a');
        // tempLink.href = csvURL;
        tempLink.href = mediaType + useInput;
        tempLink.setAttribute('download', `${response.data.fileName}.xlsx`);
        tempLink.click();
    };

    const eventChangeFrequency = async (frequency: any) => {
        let tempInvoiceListPayload: any = invoiceListPayload;
        tempInvoiceListPayload.frequency = frequency;
        setInvoiceListPayload(tempInvoiceListPayload);
        getInvoiceListData();
    };

    useEffect(() => {
        eventEmitter.on('eventChangeFrequency', eventChangeFrequency);
        return () => {
            eventEmitter.off('eventChangeFrequency', eventChangeFrequency);
        };
    });

    const [showPortal, setShowPortal] = useState(false);
    const handleClickModal = () => {
        setShowPortal(true);
    };
    const handleCloseModal = () => {
        setShowPortal(false);
    };
    const [selectedFrequency, setSelectedFrequency] = useState<any>(1);
    const onChangeFrequency = (selected: any) => {
        setSelectedFrequency(selected)
        eventEmitter.emit('eventChangeFrequency', selected)
    }

    const paid = props.invoiceStatistics?.paymentStatus?.paidCount || 0
    const inPaymentCount = props.invoiceStatistics?.paymentStatus?.inPaymentCount || 0
    const unPaidCount = props.invoiceStatistics?.paymentStatus?.unPaidCount || 0
    const paymentLabels = ['Paid', 'In Payment', 'Unpaid']
    const paymentValues = [paid, inPaymentCount, unPaidCount]
    const paymentColor = ['#2ed883', '#eef064', '#f37b74']


    const draft = props.invoiceStatistics?.invoiceStatus?.draftCount || 0
    const cancelled = props.invoiceStatistics?.invoiceStatus?.cancelledCount || 0
    const posted = props.invoiceStatistics?.invoiceStatus?.postedCount || 0
    const invoiceStatuslabels = ['Posted', 'Draft', 'Cancelled']
    const invoiceStatusValues = [posted, draft, cancelled]
    const invoiceStatusColor = ['#2ed883', '#A3A3EE', '#999898']

    return (
        <div>
            <div className="service-title text-center">{DataServiceDetail.serviceName}</div>
            <div className="box-shadow mt-2 new-box-shadow">
                {/* <button onClick={() => handleDownloadRows(tableInstanceRef?.current?.getSelectedRowModel().rows)}>Click</button> */}
                {showToolbar && (
                    <div className="row">
                        <div className="col-xl-8 col-lg-12">
                            <div className="d-flex flex-wrap">
                                <div className='info-group'>
                                    <div className='count_up table-header-icon-box count-graph'>
                                        <div onClick={handleClickModal} data-tooltip-id="my-tooltip" data-tooltip-content={`Billing Information`} >
                                            <svg
                                                id="Layer_1"
                                                enable-background="new 0 0 1737.1 1737.1;"
                                                width="16"
                                                version="1.1"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 1737.1 1737.1"
                                                xmlSpace="preserve"
                                                xmlns="http://www.w3.org/2000/svg"

                                            >

                                                <style type="text/css">{`
                            .st0{fill:#4B4B4B;}
                            `}</style>

                                                <g>
                                                    <path className="st0" d="M1628.6,0c19.7,8.2,21.7,24.8,21.7,43.4c-0.2,468.6-0.2,937.1-0.2,1405.7c0,44.7,0.1,89.3-0.1,134
		c-0.1,28.7-10,38.4-39,38.5c-89.9,0.1-179.7,0.1-269.6,0c-28.5,0-38.5-10.3-38.5-39.1c0-514.4,0-1028.7-0.3-1543.1
		c0-17.7,5.4-30.6,20.6-39.3C1425,0,1526.8,0,1628.6,0z M1362,58.6c0,502.2,0,1003.2,0,1503.8c77.2,0,153.2,0,229.1,0
		c0-501.9,0-1002.5,0-1503.8C1514.4,58.6,1438.9,58.6,1362,58.6z"/>
                                                    <path className="st0" d="M0,1679.5c579,0,1158.1,0,1737.1,0c0,19.2,0,38.4,0,57.6c-579,0-1158.1,0-1737.1,0C0,1717.9,0,1698.7,0,1679.5
		z"/>
                                                    <path className="st0" d="M492.1,984c0-197.9,0-395.7,0-593.6c0-34.3,8.8-43.1,43.2-43.2c87.1,0,174.1,0,261.2,0
		c34.7,0,43.1,8.4,43.1,43.1c0,395.7,0,791.5,0,1187.2c0,35.7-8.3,44-43.8,44c-87.6,0-175.3,0-262.9,0c-30.9,0-40.7-9.7-40.7-40.5
		C492.1,1382,492.1,1183,492.1,984z M780.2,1562.8c0-386,0-770.9,0-1156.3c-77.3,0-153.3,0-228.8,0c0,386.3,0,771.4,0,1156.3
		C628.4,1562.8,704,1562.8,780.2,1562.8z"/>
                                                    <path className="st0" d="M897.6,1156.9c0-139.1,0-278.2,0-417.3c0-37.4,7.2-44.5,44.7-44.5c87.6,0,175.3,0,262.9,0
		c30.2,0,39.8,9.4,39.8,39.3c0,282.7,0,565.4,0,848.2c0,28.7-10.2,38.9-38.9,38.9c-89.3,0.1-178.7,0.1-268,0
		c-31,0-40.5-9.4-40.5-40.6C897.6,1439.6,897.6,1298.3,897.6,1156.9z M956.6,753.6c0,270.5,0,539.3,0,809.2c76.9,0,152.9,0,229.5,0
		c0-270.1,0-539.4,0-809.2C1109.4,753.6,1033.8,753.6,956.6,753.6z"/>
                                                    <path className="st0" d="M434.3,1331.6c0,83.1,0,166.2,0,249.2c0,31-9.5,40.7-40.4,40.7c-88.7,0.1-177.5,0.1-266.2,0
		c-31.1,0-40.6-9.3-40.6-40.3c-0.1-166.2-0.1-332.3,0-498.5c0-31.2,9.4-40.7,40.2-40.7c88.7-0.1,177.5-0.1,266.2,0
		c31,0,40.8,9.7,40.8,40.4C434.3,1165.5,434.3,1248.5,434.3,1331.6z M145.8,1562.4c78,0,153.9,0,229.4,0c0-154.7,0-308,0-461
		c-77.2,0-153.1,0-229.4,0C145.8,1255.7,145.8,1408.6,145.8,1562.4z"/>
                                                </g>

                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <CreateNewButtonContainer onClickCreateNewInvoiceNoHandler={onClickCreateNewInvoiceNoHandler} />
                                <SearchInvoiceContainer searchValue={changeSearchValue} />
                                <TableFilterContainer changePayloadHandler={changePayloadHandler} changeDatePayloadHandler={changeDatePayloadHandler} invoiceListPayloadFilter={invoiceListPayload?.filter} />
                                <TableGroupByContainer
                                    onChangeGroupByHandler={onChangeGroupByHandler}
                                    onStateGroupByHandler={onStateGroupByHandler}
                                    onChangeTypeInvoiceGroupByHandler={onChangeTypeInvoiceGroupByHandler}
                                    onRemoveAllGroupBy={onRemoveAllGroupBy}
                                />
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-12 ">
                            <div className="d-flex justify-content-xl-end justify-content-start">
                                <TableManageColumnsContainer managedColumnsList={managedColumnsList} onChangeManageColumnHandler={onChangeManageColumnHandler} />
                                <TableActionContainer onChangeActionHandler={onChangeActionHandler} />
                                <DownloadInvoiceContainer onClickHandlerDownloadInvoices={onClickHandlerDownloadInvoices} />
                                <div className='d-flex align-items-center info-group  '>
                                    <div className={`count_up table-header-icon-box bed-header-icon-mb-0 paid-box`} data-tooltip-id="my-tooltip" data-tooltip-content={'Paid'} >
                                        <h3 className="d-flex justify-content-between mb-0 ">
                                            <i className="fa-solid fa-square-check"></i>
                                            <p className="count">
                                                {(props.invoiceStatistics?.paymentStatus?.paidCount || 0) > 99 ? "99+" : (props.invoiceStatistics?.paymentStatus?.paidCount || 0) || '0'}
                                            </p>
                                        </h3>

                                    </div>
                                    <div className={`count_up table-header-icon-box bed-header-icon-mb-0 payment-box`} data-tooltip-id="my-tooltip" data-tooltip-content={'Payment'}>
                                        <h3 className="d-flex justify-content-between mb-0">
                                            <i className="fa-solid fa-clock"></i>
                                            <p className="count">
                                                {(props.invoiceStatistics?.paymentStatus?.inPaymentCount || 0) > 99 ? "99+" : (props.invoiceStatistics?.paymentStatus?.inPaymentCount || 0) || '0'}
                                            </p>
                                        </h3>

                                    </div>
                                    <div className={`count_up table-header-icon-box bed-header-icon-mb-0 unpaid-box`} data-tooltip-id="my-tooltip" data-tooltip-content={'Unpaid'}>
                                        <h3 className="d-flex justify-content-between mb-0">
                                            <i className="fa-solid fa-rectangle-xmark"></i>
                                            <p className="count">
                                                {(props.invoiceStatistics?.paymentStatus?.unPaidCount || 0) > 99 ? "99+" : (props.invoiceStatistics?.paymentStatus?.unPaidCount || 0) || '0'}
                                            </p>
                                        </h3>

                                    </div>
                                    <div className={`count_up table-header-icon-box bed-header-icon-mb-0 draft-box`} data-tooltip-id="my-tooltip" data-tooltip-content={'Draft'}>
                                        <h3 className="d-flex justify-content-between mb-0">
                                            <i className="fa-solid fa-tag"></i>
                                            <p className="count">
                                                {(props.invoiceStatistics?.invoiceStatus?.draftCount || 0) > 99 ? "99+" : (props.invoiceStatistics?.invoiceStatus?.draftCount || 0) || '0'}
                                            </p></h3>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-lg-12 col-md-12 invoice-table ps-1 pe-1">
                        <MaterialReactTable
                            columns={columns}
                            data={data}
                            enableExpanding
                            enableExpandAll
                            enableColumnActions={false}
                            enableGrouping
                            enableBottomToolbar={false}
                            enableTopToolbar={false}
                            enableColumnDragging={false}
                            initialState={
                                {
                                    // grouping: ['invoice_Type'],
                                    // expanded: true
                                }
                            }
                            enableRowSelection
                            getRowId={(row) => row.invoice_Pattern}
                            manualPagination
                            manualSorting
                            muiToolbarAlertBannerProps={
                                isError
                                    ? {
                                        color: 'error',
                                        children: 'Error loading data'
                                    }
                                    : undefined
                            }
                            // onPaginationChange={setPagination}
                            onSortingChange={setSorting}
                            onRowSelectionChange={(updater) => {
                                setRowSelection((prev) => (updater instanceof Function ? updater(prev) : updater));
                                queueMicrotask(rerender); //hack to rerender after state update
                            }}
                            // muiTableBodyRowProps={({ row }) => ({
                            //     onClick: (event) => {
                            //       
                            //     },
                            //     sx: {
                            //       cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                            //     },
                            //   })}
                            rowCount={rowCount}
                            state={{
                                grouping,
                                columnVisibility,
                                isLoading,
                                rowSelection,
                                // pagination,
                                showAlertBanner: isError,
                                showProgressBars: isRefetching,
                                sorting
                            }}
                            tableInstanceRef={tableInstanceRef}
                        // muiTableBodyProps={{ sx: () => ({ '& tr:nth-child(even)': { backgroundColor: '#f7f7f7' } }) }} muiTableBodyCellProps={{ sx: { border: 'none' } }}
                        />
                    </div>
                </div>
                <div className="row align-items-center mt-3 invoice-table-pagination">
                    <div className='col-lg-6'>
                        <div className='d-flex align-items-center'>
                            <p className='purple-text bold'>{t('Page_Select_dropdown.Displaying')}  {showFrom}-{showTo} of {totalCount}</p>
                            <div className='d-flex align-items-center ms-3'>
                                <label className="mb-0 bold nowrap me-2">{t('Page_Select_dropdown.Page_Size')}:</label>
                                <select value={PageSize} onChange={(e: any) => handleSizeChange(e)}
                                    className={`form-control white page-select`}
                                    id="supportedPersonGender"
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <PaginationContainer currentPage={currentPage} totalCount={totalCount} pageSize={PageSize} onPageChange={(page: any) => getCurrentPage(page)} />
                    </div>
                </div>
            </div>
            <Tooltip id="my-tooltip" className="my-tooltip" />
            <RenderModalContainer>
                {showPortal && <div><div className="overlay" onClick={handleCloseModal}></div>
                    <div className="modal modal-large">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title mb-0">Billing Information</h4>
                                <a href="#" title="close" onClick={handleCloseModal} className="removeButton">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                                    </a>
                            </div>
                            <div className="modal-body graph-modal-body pt-3">
                                <div className='row'>
                                    <div className='col-lg-3 col-md-6 col-6 mb-3 mb-lg-0'>
                                        <div className='box-shadow p-0 pb-0 h-100'>
                                            <h3 className="d-block box-title mb-2 ps-3 pe-3 pt-3">Payment Status</h3>
                                            <DoughnutChartContainer labels={paymentLabels} data={paymentValues} statuscolor={paymentColor} />
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-6 mb-3 mb-lg-0'>
                                        <div className='box-shadow p-0  pb-0 h-100'>
                                            <h3 className="d-block box-title ps-3 pe-3 mb-2 pt-3">Invoice Status</h3>
                                            <DoughnutChartContainer labels={invoiceStatuslabels} data={invoiceStatusValues} statuscolor={invoiceStatusColor} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-12 col-12'>
                                        <div className='box-shadow p-3 pb-1 h-100'>
                                            <div className='d-flex justify-content-between'>
                                                <h3 className="d-block box-title">Payment Details</h3>
                                                <ul className="navbar-nav">
                                                    <div className="nav-item dropdown year-dropdown">
                                                        <a className="nav-link dropdown-toggle service-dropdown" href="#" id="navbarDropdownMenuLink1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="fa-solid fa-calendar-days me-2"></i>
                                                            <span className='me-2'>{selectedFrequency === InvoiceStatisticsFrequency.CurrentYear ? `This Year` : selectedFrequency === InvoiceStatisticsFrequency.LastYear ? `Last Year` : selectedFrequency === InvoiceStatisticsFrequency.CurrentMonth ? `This Month` : selectedFrequency === InvoiceStatisticsFrequency.LastMonth ? `Last Month` : ''}</span>
                                                        </a>
                                                        <div className="dropdown-menu service-dropdown-menu" aria-labelledby="navbarDropdownMenuLink1">
                                                            <li><a href="#" onClick={() => onChangeFrequency(InvoiceStatisticsFrequency.CurrentYear)}>This Year</a></li>
                                                            <li><a href="#" onClick={() => onChangeFrequency(InvoiceStatisticsFrequency.LastYear)}>Last Year</a></li>
                                                            <li><a href="#" onClick={() => onChangeFrequency(InvoiceStatisticsFrequency.CurrentMonth)}>This Month</a></li>
                                                            <li><a href="#" onClick={() => onChangeFrequency(InvoiceStatisticsFrequency.LastMonth)}>Last Month</a></li>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                            <BarChartContainer invoiceStatistics={props.invoiceStatistics} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-center"></div>
                        </div>
                    </div>
                </div>
                }
            </RenderModalContainer>
        </div>
    );
};

export default InvoiceTableView;
