'use client';
import React, { useEffect, useState } from 'react';
import ClientPortal from '../../../../components/ClientPortal';
import MentalHealthHospital from '../../../../assets/images/mental.svg';
import NursingCare from '../../../../assets/images/nusring-care.svg';
import ResidentialCare from '../../../../assets/images/Residential-Care.svg';
import SupportedLivingHome from '../../../../assets/images/Supported-Living-Home.svg';
import AcquiredBrainInjury from '../../../../assets/images/Acquired-Brain-Injury.svg';
import AdolescentCare from '../../../../assets/images/Adolescent-Care.svg';
import AddictionCenter from '../../../../assets/images/Addiction-Center.svg';
import EatingDisorderCenter from '../../../../assets/images/Eating-Disorder-Center.svg';
import PerinatalCareCenter from '../../../../assets/images/Perinatal-Care-Center.svg';
import PsychiatricICU from '../../../../assets/images/Psychiatric-ICU.svg';
import infoIcon from '../../../../assets/images/info-icon.svg';
import selectedIcon from '../../../../assets/images/selected-icon.svg';
import EditPencil from '../../../../assets/images/edit.svg';
import availablebed from '../../../../assets/images/available-bed.svg';
import outlinebed from '../../../../assets/images/outline-bed.svg';
import occupiedbed from '../../../../assets/images/occupied-bed.svg';
import fillingfastbed from '../../../../assets/images/fillingfast-bed.svg';
import { getCategoriesById, getOnboardingFacilityCategory, getOnboardingFacilityCategoryById, saveFacilityDetails } from '../../../../service/onboarding-facilityCategory';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import warning from '../../../../assets/images/warning.png';
import swal from 'sweetalert';
import { Tooltip } from 'react-tooltip';
import { Roles, ServiceType } from '../../../../utils/constant';
import { ModulesAccessRights } from "../../../../utils/constant";
import { GetServiceDetails } from '../../../../service/service-details-service';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { saveForLaterModal } from '../../../../store/SaveForLater/saveForLaterModalReducer';
import ShareOptionsContainer from '../../../../components/ShareOptions/ShareOptionsContainer';
import SaveForLaterModalContainer from '../../../NHSPage/HomeList/ListBox/SaveForLaterModal/SaveForLaterModalContainer';
import OnboardingHeaderContainer from '../../../../layout/OnboardingHeader/OnboardingHeaderContainer';
import OnboardingPreviewEditModalFooter from '../OnboardingPreviewEditModalFooter';
import eventEmitter from '../../../../utils/eventEmitter';

interface Facility {
    serviceTypeId: number;
    serviceTypeName: string;
    serviceTypeIconName: string | null;
    serviceTypeIconUrl: string | null;
    serviceTypeDescription: string;
    isSelected: boolean
}

interface Category {
    serviceCareLevelId: number;
    serviceCareLevelName: string;
    serviceTypes: Facility[];
}

const OnboardingPreviewHeaderView = () => {

    const params = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const [serviceTypes, setServiceTypes] = useState<ServiceType[][]>([]);
    const [PreviewshowPortal1, PreviewsetShowPortal1] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedCareType, setSelectedCareType] = useState<number | null>(null);
    const [serviceDetails, setServiceDetails] = useState<any>(null);
    const [showShareModal, setShowShareModal] = useState<boolean>(false)
    const [isDetailsScreen, setIsDetailsScreen] = useState(false);
    const { saveForLaterModelOpen } = useSelector((state: RootState) => state.saveForLaterModal);

    const {isForPublished } = useSelector((state: RootState) => state.onboardingIsForPublished);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);

    const [showEditPage, setShowEditPage] = useState<any>(DataLogin?.userModel?.modules?.find((item:any)=>(item.moduleName === 'OnBoarding')))
    const PreviewhandleModal1 = (event: any) => {
        event.preventDefault();
        PreviewsetShowPortal1((prevState) => !prevState);
    };

    const GetServiceDetailsList = async () => {
        const result = await GetServiceDetails(params?.serviceSlug);
        setServiceDetails(result);
    };

    useEffect(() => {
        if (location.pathname.includes('service-detail')) {
            setIsDetailsScreen(true);
        } else {
            setIsDetailsScreen(false);
        }
    }, [location]);

    useEffect(() => {
        if (PreviewshowPortal1) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [PreviewshowPortal1]);

    const [facilities, setFacilities] = useState<Facility[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedFacilities, setSelectedFacilities] = useState<{ [key: number]: number[] }>({});
    const [selectedServiceNames, setSelectedServiceNames] = useState<string[]>([]);
    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);
    const imageMap: { [key: string]: string } = {
        MentalHealthHospital: MentalHealthHospital,
        NursingCare: NursingCare,
        ResidentialCare: ResidentialCare,
        SupportedLivingHome: SupportedLivingHome,
        AcquiredBrainInjury: AcquiredBrainInjury,
        AdolescentCare: AdolescentCare,
        AddictionCenter: AddictionCenter,
        EatingDisorderCenter: EatingDisorderCenter,
        PerinatalCareCenter: PerinatalCareCenter,
        PsychiatricICU: PsychiatricICU
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getOnboardingFacilityCategory(serviceId);
                const fetchedCategories = response?.data;
                setCategories(fetchedCategories);
                // setFacilities(response?.data?.flatMap((careLevel: any) => careLevel?.serviceTypes));
                 if(Array.isArray(fetchedCategories)){
                    setFacilities(response?.data?.flatMap((careLevel: any) => careLevel?.serviceTypes));
                }else{
                    setFacilities([]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        GetServiceDetailsList();
    }, []);

    const handleFacilitySelection = (categoryId: number, facilityId: number) => {
        setSelectedFacilities((prevSelectedFacilities) => {
            const selectedForCategory = prevSelectedFacilities[categoryId] || [];

            const isSelected = selectedForCategory.includes(facilityId);
            const updatedSelection = isSelected ? selectedForCategory.filter((id) => id !== facilityId) : [...selectedForCategory, facilityId];

            return {
                ...prevSelectedFacilities,
                [categoryId]: updatedSelection
            };
        });
    };
    const saveData = async () => {
        const selectedNames = facilities?.filter((facility: any) => Object.values(selectedFacilities).some((facilityIds: any) => facilityIds?.includes(facility?.serviceTypeId)))
            .map((facility: any) => facility?.serviceTypeName);
        const payload = {
            ServiceCare: Object.entries(selectedFacilities).map(([serviceCareLevelId, serviceTypeIds]) => ({
                serviceCareLevelId: parseInt(serviceCareLevelId, 10),
                serviceTypeIds,
            })),
            Service_Id: serviceId,
        };
        const res = await saveFacilityDetails(payload);

        setSelectedServiceNames(selectedNames);
        PreviewsetShowPortal1(false);
        if (res.status === true) {
            swal(res.message, {
                icon: 'success'
            }).then(async (confirm: any) => {
                const response = await getOnboardingFacilityCategory(serviceId);
                const fetchedCategories = response.data;
                setCategories(fetchedCategories);
                setFacilities(response?.data?.flatMap((careLevel: any) => careLevel?.serviceTypes));
                eventEmitter.emit('getServiceImagesOnboardingPreview');
                eventEmitter.emit('getServiceAmenitiesOnboardingPreview');
                eventEmitter.emit('getServiceCardsOnboardingPreview');
                setShowConfirmation(false)
            });

        } else {
            swal(res.message, {
                icon: 'error'
            }).then(async (confirm: any) => {


            });;
        }
    }

    const submitalert = () => {
        swal({
            title: '',
            text: `Do you want to save the changes done to Facility Type?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                saveData();
            } else {
            }
        });
    }

    const isSaveDisabled = Object.values(selectedFacilities).every((facilityIds) => facilityIds.length === 0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getOnboardingFacilityCategory(serviceId);
                const fetchedCategories = response?.data;
                setCategories(fetchedCategories);

                if (fetchedCategories?.length > 0) {
                    setSelectedCareType(fetchedCategories[0]?.serviceCareLevelId);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const handleCareTypeChange = (careTypeId: number | null) => {
        setSelectedCareType(careTypeId);
    };
    const filteredFacilities = selectedCareType
        ? categories?.find((category: any) => category?.serviceCareLevelId === selectedCareType)?.serviceTypes || []
        : [];

    const isFacilityAvailable = facilities?.some((fc: any) => fc.isSelected === true)
    useEffect(() => {
        const fetchData = async () => {
            const categoryResponse = await getOnboardingFacilityCategory(serviceId);

            if (categoryResponse && categoryResponse?.data) {
                const fetchedCategories = categoryResponse?.data;
        
                if (fetchedCategories && fetchedCategories?.length > 0) {
                    setCategories(fetchedCategories);
                    setSelectedCareType(fetchedCategories[0]?.serviceCareLevelId);
                } }

            if (serviceId) {
                const facilitiesResponse = await getOnboardingFacilityCategoryById(serviceId);
                if (facilitiesResponse?.data) {
                    const serviceCares = facilitiesResponse?.data;
                    let fetchedFacilities = []; 
                    if (Array.isArray(serviceCares))
                    {
                        fetchedFacilities = serviceCares?.flatMap((serviceCare: any) => 
                            serviceCare?.serviceTypes
                                ?.filter((serviceType: any) => serviceType?.isSelected)
                                .map((serviceType: any) => ({
                                    categoryId: serviceCare?.serviceCareLevelId,
                                    facilityId: serviceType?.serviceTypeId,
                                }))
                        );
                    } 
                    const formattedSelectedFacilities = fetchedFacilities.reduce(
                        (acc: { [key: number]: number[] }, facility: { categoryId: number, facilityId: number,isSelected: boolean }) => {
                            const { categoryId, facilityId, isSelected } = facility;
                            acc[categoryId] = acc[categoryId] ? [...acc[categoryId], facilityId] : [facilityId];
                            return acc;
                        },
                        {} as { [key: number]: number[] }
                    );
                    setSelectedFacilities(formattedSelectedFacilities); 
                }
            }
        };
        fetchData();
    }, [serviceId]);

    const handleClickModal = (e: any) => {
        e.stopPropagation();
        dispatch(
            saveForLaterModal({
                saveForLaterModelOpen: true,
                serviceId: serviceDetails?.data?.serviceId,
                serviceName: serviceDetails?.data?.serviceName
            })
        );
    };
    const handleOpenShare = () => {
        setShowShareModal(true)
    }
    const handleModal = (event: any) => {
        event.preventDefault();
        setShowShareModal((prevState) => !prevState);
    };
    useEffect(() => {
        if (showShareModal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showShareModal]);
    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.key === 'Escape') {
                handleModal(event);
            }
        };

        if (showShareModal) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showShareModal, handleModal]);

    return (
        <div>
            <div className="service-detail-padding">
                <div className="container-fluid mt-3 mb-3 p-0">
                    <div className="row d-flex align-items-start">
                        <div className="col-lg-9 col-9">
                            <div>
                                <p className="service-title d-flex align-items-center">{serviceName}</p>
                                <div className="mt-2 d-flex align-items-center flex-wrap">
                                    {isFacilityAvailable ? (
                                        facilities.map((fc: any, index: any) => {
                                            if (fc?.isSelected) {
                                                return (
                                                    <span key={index} className="service-type me-2 mb-2">
                                                        {fc?.serviceTypeName}
                                                    </span>
                                                )

                                            }
                                        })
                                    ) : (
                                        <div className="" onClick={PreviewhandleModal1}>
                                            <p className='purple-text bold'>Select Service</p>
                                        </div>
                                    )}
                                    {isForPublished ? (
                                        showEditPage?.userAccess?.some((a: any) => a.accessCode === ModulesAccessRights.Onboarding.ServiceTypeEditAccess.accessCode) ? (
                                            <div className="edit-icon-preview ms-2 cursor-pointer" onClick={PreviewhandleModal1} data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                                                <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    ) : (
                                        <div className="edit-icon-preview ms-2 cursor-pointer" onClick={PreviewhandleModal1} data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                                            <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {isDetailsScreen && <div className="col-lg-3 col-3">
                            <div className="d-flex flex-column align-items-end">
                                <div className="d-flex align-items-center">
                                    <div className="mb-3 d-flex align-items-center">
                                        <div className="text-center me-3">
                                            <img src={outlinebed} alt="Outline Bed" width={30} /> <span className="bold lead-text vertical-middle">{serviceDetails?.data?.totalBedCount}</span>
                                            <p className="purple-text bold">Total Beds</p>
                                        </div>
                                    </div>
                                    {serviceDetails?.data?.availability === 'Occupied' && (
                                        <p className="occupied occupied-bg occupied-border avail-bed-detail d-flex align-items-center ">
                                            <img src={occupiedbed} alt="Occupied Bed" className="me-2" width={25} />
                                            {t(serviceDetails?.data?.availability)}
                                        </p>
                                    )}

                                    {serviceDetails?.data?.availability === 'Available' && (
                                        <p className="avail-bed-detail d-flex align-items-center ">
                                            {serviceDetails?.data?.availableBedCount > 0 && <span className="purple-text bold vertical-middle me-1">{serviceDetails?.data?.availableBedCount}</span>}
                                            <img src={availablebed} alt="Available Bed" className="me-2" width={25} />
                                            {t(serviceDetails?.data?.availability)}
                                        </p>
                                    )}

                                    {serviceDetails?.data?.availability === 'Filling Fast' && (
                                        <p className="filing-fast filing-fast-bg filing-fast-border filling-fast-bed-detail d-flex align-items-center">
                                            <img src={fillingfastbed} alt="Filling Fast Bed" className="me-2" width={25} />
                                            {t(serviceDetails?.data?.availability)}
                                        </p>
                                    )}
                                </div>
                                <div className="d-flex align-items-center justify-content-end">
                                    {DataLogin?.userModel?.userRoleId === Roles.NHS && (
                                        <span className="hand-cursor me-3 heart-icon" onClick={handleClickModal} style={{zIndex: 8}}>
                                            <svg
                                                version="1.1"
                                                id="Layer_2"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                width={23}
                                                y="0px"
                                                viewBox="0 0 3154.5 2836.4"
                                                xmlSpace="preserve"
                                            >
                                                <style>{`"enable-background:new 0 0 3154.5 2836.4;"`} </style>
                                                <g className="st0" style={{ opacity: 1 }}>
                                                    <path
                                                        className="st1"
                                                        fill="#FFFFFF"
                                                        d="M923.6,159c-283.4,12.6-757,222.5-777.8,853.7c-7.4,224.7,166.8,903.5,1426.1,1678.2
C2828.3,1918,3017,1238.1,3011.6,1012.7c-15.2-631.5-493.1-841.1-777.8-853.7c-466.2-20.7-655.2,321.5-655.2,321.5
S1389.9,138.3,923.6,159z"
                                                    />
                                                </g>
                                                <g>
                                                    <path
                                                        className="st2"
                                                        fill="#070044"
                                                        d="M1571.8,2788.4l-43.5-26.8C107.2,1887.3,58.2,1149.1,62.8,1010c23.4-709.7,571-921.3,857.1-934l3.7,83l-3.7-83
c352.6-15.2,558.3,152.4,658.7,265.6C1679,228.5,1886.3,60.5,2237.5,76c288.9,12.8,839.9,224.7,857.1,934.7
c2.1,84.8-21.9,854.3-1479.3,1750.9L1571.8,2788.4z M963.8,241.2c-12,0-24.2,0.2-36.5,0.8c-233.1,10.4-679.2,185.3-698.5,773.6
c-2.6,74.6,14.6,746.3,1342.9,1577.6c1083.5-678.5,1363.4-1309.7,1356.7-1578.4C2914.4,427.4,2465.4,252.4,2230,242
c-399.7-16.1-571.9,266.8-578.9,279L1578,650.3l-72-129.7C1499.4,509.2,1343.2,241.2,963.8,241.2z"
                                                    />
                                                </g>
                                            </svg>
                                        </span>
                                    )}

                                    <a onClick={handleOpenShare} className="indigo-text  lead-text hover-gray-btn cursor-pointer">
                                        <i className="fa-solid lead-text fa-share-nodes me-2"></i>
                                        <span className="underline" data-tooltip-id="my-tooltip" data-tooltip-content="Share">{t("ServiceDetail.Share")}</span>
                                    </a>
                                    {/* <a
                                    href="#"
                                    className="indigo-text ms-3  lead-text hover-gray-btn"
                                >
                                    <i className="fa-solid fa-heart me-1"></i> 3.8
                                </a> */}
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

            <ClientPortal selector="myportal" show={PreviewshowPortal1}>
                <div className="overlay" onClick={PreviewhandleModal1}></div>
                <div className="modal modal-collage-image p-0 nhs-body">
                    <div>
                        <OnboardingHeaderContainer manageProfileValidationType='edit'/>
                    </div>
                    <div className="body service-detail-padding py-1">
                        <div className="container-fluid p-0">
                            <div className='cards-section onboard-location float-button-sec-margin'>
                                <div className="row m-0">
                                    <div className="col-xl-2 p-0">
                                        <ul className="nav flex-column nav-pills facility-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            {categories?.map((category: any) => (
                                                <li key={category.serviceCareLevelId} className="nav-item">
                                                    <a
                                                        className={`nav-link ${selectedCareType === category.serviceCareLevelId ? 'active' : ''}`}
                                                        id={`v-pills-${category.serviceCareLevelId}-tab`}
                                                        data-bs-toggle="pill"
                                                        href={`#v-pills-${category.serviceCareLevelId}`}
                                                        role="tab"
                                                        aria-controls={`v-pills-${category.serviceCareLevelId}`}
                                                        aria-selected={selectedCareType === category.serviceCareLevelId}
                                                        onClick={() => handleCareTypeChange(category.serviceCareLevelId)}
                                                    >
                                                        {category.serviceCareLevelName}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="col-xl-10 p-0">
                                        <div className="tab-content facility-tab-content" id="v-pills-tabContent">
                                            <div className="tab-pane fade show active" id="v-pills-adult" role="tabpanel" aria-labelledby="v-pills-adult-tab">
                                                <div className="row justify-content-center">
                                                    <div className="">
                                                        <div className="facility-cards-parent p-0 d-flex flex-wrap justify-content-start">
                                                            {filteredFacilities.map((facility: any, index: any) => (
                                                                <div key={index} className="facility-card classic-card selected-facility">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="selection-box"
                                                                        checked={selectedFacilities[selectedCareType || 0]?.includes(facility.serviceTypeId) || false}
                                                                        onChange={() => handleFacilitySelection(selectedCareType || 0, facility.serviceTypeId)}
                                                                    />
                                                                    <div className="selectedButton">
                                                                        <img src={selectedIcon} alt="selected icon" className="img-fluid" />
                                                                    </div>
                                                                    {facility.serviceTypeDescription ? (
                                                                        <div className="infoIcon" data-tooltip-id="my-tooltip" data-tooltip-content={facility.serviceTypeDescription} data-tooltip-place="top">
                                                                            <img src={infoIcon} alt="info icon" className="img-fluid" />
                                                                        </div>
                                                                    ) : null}
                                                                    <img
                                                                        src={facility.serviceTypeIconUrl}
                                                                        alt={facility.serviceTypeName}
                                                                        className="img-fluid"
                                                                    />
                                                                    <p className="light-blue-text">{facility.serviceTypeName}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OnboardingPreviewEditModalFooter backOnClick={PreviewhandleModal1} saveOnClick={submitalert} saveDisabled={isSaveDisabled} saveType='submit'/>
                    <Tooltip id="my-tooltip" className="my-tooltip" />
                </div>
            </ClientPortal>
            <ClientPortal selector="myportal" show={showShareModal}>
                <div className="overlay" onClick={handleModal}></div>
                <div className="modal website-small-modal">
                    <div className="header mb-3">
                        <h4 className="mb-0">{t("ServiceDetail.Share_this_Service")}</h4>
                        <a onClick={handleModal} className="removeButton">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 384 512"
                            >
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body message-popup-share">
                        <ShareOptionsContainer serviceDetails={serviceDetails} url={window.location.href} />
                    </div>
                </div>
            </ClientPortal>
            {saveForLaterModelOpen && <SaveForLaterModalContainer />}
        </div>
    );
};

export default OnboardingPreviewHeaderView;
