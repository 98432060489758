export const ROUTES_DATA = {
    ROOT: '/',
    LOGIN: '/login',
    CHANGEPASSWORD: '/change-password',
    DASHBOARD: '/dashboard',
    BILLINGMANAGEMENT: '/billing-management',
    REFERRALMANAGEMENT: '/referral-management',
    HELP: '/help',
    AccessDenied: 'access-denied',
    ContractSigned: '/contract-signed',
    NHS: '/NHS',
    NHSREFERRAL : '/NHS/referral-management',
    Administrator : '/administrator',
    INTEGRATION: '/Integration',
    LOCATEADMIN: "/admin-verification",
    SERVICEDETAIL: '/service-detail',
    VIEWMAP: '/viewmap',
    PROVIDERSERVICEDETAIL : '/provider-service-detail',
    REFERRALMANAGERVIEWMAP: '/referral-manager/viewmap',
    SERVICEMANAGERVIEWMAP: '/service-manager/viewmap',
    ERRORPAGE:'/error',
    ONBOARDING:'/onboarding',
    ONBOARDINGDASHBOARD:'/onboarding-dashboard',
    ONBOARDINGPREVIEW:'/onboarding/preview',
    CLAIMREQUEST: '/claim-management',
    FUNDERMANAGEMENT:'/funder-management',
    PRODUCTMANAGEMENT:'/product-management',
    CREATEFUNDER:'/create-funder',
    CREATEPRODUCT:'/create-product',
    CREATEACCESSROLE:'/create-access-role',
    FORGOTPASSWORD:'/forgot-password',
    RESETPASSWORD:'/reset-password',
    ACTIVEADMISSIONSREPORT:"/ActiveAdmissionsView",
    CURRENYBEDOCCUPANCYREPORT:"/CurrentBedOccupancyReportView",
    DISCHARGEREPORT:"/DischargeReportView",
    ADMISSIONREPORT:"/AdmissionsReportView",
    DRAFTPATIENTSEREPORT:"/DraftPatientsReportView",
    RESERVATIONREPORT:"/ReservationReportView",
    REFERRALREPORT:"/ReferralReportView",
    USERMANAGEMENT:'/user-management',
    CREATEUSER:'/create-user',
    ACCESSROLE:'/access-role',
    SERVICECARDS:'/servicecards',
    CREATESERVICECARDS:'/createservicecards',
    REPORTS:"/reports",
    PATIENTTRANSACTIONHISTORYREPORT:"/PatientTransactionHistoryReportView",
    PATIENTDETAILSREPORT:"/PatientDetailsReportView",
    CURRENTBEDSTATUSREPORT:"/CurrentBedStatusWithPricingView",
    BEDTRANSACTIONHISTORYREPORTS:"/BedTransactionHistoryReportsView",
};
