'use client'
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import ClientPortal from "../../../../../components/ClientPortal";
import EditPencil from "../../../../../assets/images/edit.svg";
import AccreditationsDetailView from "../../../Accreditations/AccreditationsDetail/AccreditationsDetailView";
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { downloadAccreditationFile, getOnboardingAccreditationData, SubmitAccreditationMapping } from "../../../../../service/onboarding-accreditation-service";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import swal from 'sweetalert';
import LoaderContainer from "../../../../../components/Loader/loaderContainer";
import { ModulesAccessRights } from "../../../../../utils/constant";
import OnboardingPreviewAccreditaionPortalVeiw from "./OnboardingPreviewAccreditaionPortalVeiw";
import eventEmitter from "../../../../../utils/eventEmitter";
import { Tooltip } from "react-tooltip";


const OnboardingPreviewAccreditationView = (props: any) => {
    var settings = {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
    };
    const [accreditations, setAccreditations] = useState<any>([]);
    const [cqcRating, setCQCRating] = useState('');
    const [isLoading, setIsloading] = useState(false)


    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);
    const { isForPublished } = useSelector((state: RootState) => state.onboardingIsForPublished);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);

    const [showEditPage, setShowEditPage] = useState<any>(DataLogin?.userModel?.modules?.find((item:any)=>(item.moduleName === 'OnBoarding')))

    useEffect(() => {
        AOS.init();
    }, []);
    const { t } = useTranslation();
    const [previewShowPortal, setPreviewShowPortal] = useState(false);
    const handlePreviewShowPortal = (event: any) => {
        event.preventDefault();
        setPreviewShowPortal((prevState) => !prevState);
    };
    useEffect(() => {
        const handleHidePreviewPortal = () => {
          setPreviewShowPortal(false);
        };
        eventEmitter.on('hidePreviewPortal', handleHidePreviewPortal);
        return () => {
          eventEmitter.off('hidePreviewPortal', handleHidePreviewPortal);
        };
      }, []);
    useEffect(() => {
        const GetAccreditationData = async () => {
            setIsloading(true)
            const response = await getOnboardingAccreditationData(serviceId!);
            if (response?.status) {
                setIsloading(false)
                // setAccreditations(response?.data);
                setAccreditations(response?.data?.accreditations);
                setCQCRating(response?.data?.cqcRating);
                // setRefresh((prevState) => prevState + 1);
            } else {
                setIsloading(false)
            }
        };
        GetAccreditationData();
    }, []);

    useEffect(() => {
        if (previewShowPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [previewShowPortal]);




    const handleViewFile = async (path: string) => {
        const filePath = path
        if (filePath) {
            setIsloading(true)
            const response = await downloadAccreditationFile(filePath);
            if (response?.status) {
                setIsloading(false)
                const a = document.createElement('a');
                a.target = '_blank';
                a.href = response.data;
                document.body.append(a);
                a.click();
                document.body.removeChild(a);
            }
            setIsloading(false)
        }
    };


    if (isLoading) {
        return <LoaderContainer />
    }

    return (
        <div>
            <div className="d-flex small-title align-items-center preview-small-title">
                <h4 className="indigo-text bolder mb-0" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                    {t('Accreditations.Accreditations')}
                </h4>
                {isForPublished ? 
                    showEditPage?.userAccess?.some((a: any) => a.accessCode === ModulesAccessRights.Onboarding.CoordinatorAccess.accessCode) ?
                    <div className="edit-icon-preview ms-2 cursor-pointer" onClick={handlePreviewShowPortal}><img src={EditPencil} className="img-fluid" alt="edit" width={20} /></div>

                    :
                    <></>
                    :
                    <div className="edit-icon-preview ms-2 cursor-pointer" onClick={handlePreviewShowPortal}><img src={EditPencil} className="img-fluid" alt="edit" width={20} /></div>}
            </div>

            <div className="container-fluid p-0" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300" data-aos-once="true">
                <div className="getlocated-section">
                    <div className="cards-section accreditation-slider">
                        {
                            accreditations?.length > 0 && (
                                <Slider {...settings} >
                                    {accreditations.filter((accreditation:any, index: number) => (accreditation.isSelected || index === 0))?.map((item: any, index: number) => {
                                        if (index !== 0) {
                                            return (
                                                <div className="accred-cards">
                                                    <div className="classic-card no-hover accreditationsbox line-accridations">
                                                        <h6>{item.title}</h6>
                                                        <p className='accreditations-text text-small'>
                                                            {item.description}
                                                        </p>
                                                        <div className="acc-img d-flex align-items-center justify-content-center">
                                                            <img src={item.imagePath ? item.imagePath : ""} className="img-fluid" alt="map image" />
                                                        </div>
                                                        <div>
                                                            <ul className="list-unstyled text-small mb-0">
                                                                {item?.overall && <li>Overall: <span className="lightblue">{item.overall ? item.overall : ""} <span className="dot-round acc-dot me-1"></span></span></li>}
                                                                <li>Date of Issue : <span className="lightblue underline">{item.issuedOn}</span></li>
                                                                <li>Date of Expiry : <span className="lightblue underline">{item.validity}</span></li>
                                                                {item?.certificatePath ? (
                                                                    <li >
                                                                        <a className="lightblue underline" onClick={() => handleViewFile(item?.certificatePath)} >
                                                                            View Certificate
                                                                        </a>
                                                                    </li>
                                                                ) : (<li><a></a></li>)}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className="accred-cards">
                                                    <div className="classic-card no-hover accreditationsbox line-accridations">
                                                        <h6>{item.title}</h6>
                                                        <p className='accreditations-text text-small'>
                                                            {item.description}
                                                        </p>
                                                        <div className="acc-img d-flex align-items-center justify-content-center">
                                                            <img src={item.imagePath ? item.imagePath : ""} className="img-fluid" alt="map image" />
                                                        </div>
                                                        <div>
                                                            <ul className="list-unstyled text-small mb-0">
                                                                {item?.overall && <li>Overall: <span className="lightblue">{item.overall ? item.overall : ""} <span className="dot-round acc-dot me-1"></span></span></li>}
                                                                <li>Date of Issue : <span className="lightblue underline">{item.issuedOn}</span></li>
                                                                <li className='rate-div-onboarding d-flex'>CQC Rating :&nbsp;<span className="lightblue underline d-flex align-items-center">
                                                                    <span data-tooltip-id="cqc-acc-rating" data-tooltip-content={cqcRating} data-tooltip-place="top">
                                                                        {cqcRating?.length > 10 ? `${cqcRating?.slice(0, 10)}..` : cqcRating}
                                                                    </span>
                                                                    <span
                                                                        className={`rate-dot ${cqcRating?.toLowerCase() === "good"
                                                                            ? "good"
                                                                            : cqcRating?.toLowerCase() === "requires improvement"
                                                                                ? "requiresImprovement"
                                                                                : cqcRating?.toLowerCase() === "inadequate"
                                                                                    ? "inadequate"
                                                                                    : cqcRating?.toLowerCase() === "outstanding"
                                                                                        ? "outstanding"
                                                                                        : "na"
                                                                            } ms-1`}
                                                                    ></span></span></li>
                                                                {item?.certificatePath ? (
                                                                    <li >
                                                                        <a className="lightblue underline" onClick={() => handleViewFile(item?.certificatePath)} >
                                                                            View Certificate
                                                                        </a>
                                                                    </li>
                                                                ) : (<li><a></a></li>)}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                    )}
                                </Slider>
                            )
                        }
                    </div>
                </div>
            </div>

            {/* <ClientPortal selector="myportal" show={previewShowPortal}>
                <div className="overlay" onClick={handlePreviewShowPortal}></div>
                <div className="modal modal-collage-image p-0 nhs-body">
                    <div className="header">
                        <a onClick={handlePreviewShowPortal} className="d-flex align-items-center">
                            <i className="fa-solid fa-angle-left back-collage-btn"></i>
                            <h4 className="bolder mb-0 ms-2">Update Accreditations</h4>
                        </a>
                    </div>
                    <div className="body service-detail-padding">
                        <div className="container-fluid service-slider mt-3 float-button-sec-margin">
                            <div className="accred-slider">
                                {accreditations?.length > 0 && (
                                    <Slider {...settings} >
                                        {accreditations.map((item: any, index: number) => (
                                            <div className="accred-cards" key={item?.accreditationId}>
                                                <div className="classic-card no-hover accreditationsbox line-preview-accridations">
                                                    <h6>{item.title}</h6>
                                                    <p className='accreditations-text text-small'>
                                                        {item?.description}
                                                    </p>
                                                    <div className="acc-img d-flex align-items-center justify-content-center">
                                                        <img src={item?.imagePath} className="img-fluid" alt="map image" />
                                                    </div>
                                                    <AccreditationsDetailView accreditations={accreditations} setAccreditations={setAccreditations} item={item} setIsloading={setIsloading} />
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer--sticky p-2">
                        <div className="d-flex justify-content-center"><button type="submit" className="btn primary-btn mt-1 px-5" onClick={handleSaveAccreditationsMapping} >Save</button></div>
                    </div>
                </div>
            </ClientPortal> */}


            <OnboardingPreviewAccreditaionPortalVeiw accreditations={accreditations} setAccreditations={setAccreditations} previewShowPortal={previewShowPortal} setPreviewShowPortal={setPreviewShowPortal} handlePreviewShowPortal={handlePreviewShowPortal} cqcRating={cqcRating} />
            <Tooltip id="cqc-acc-rating" className="my-tooltip" />
        </div>
    );
};

export default OnboardingPreviewAccreditationView;
