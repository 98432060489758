import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    MaterialReactTable,
    MRT_TableInstance,
    MRT_FullScreenToggleButton,
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_TablePagination,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToolbarAlertBanner,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_VisibilityState,
    type MRT_RowSelectionState,
    type MRT_GroupingState,
    MRT_Localization
} from 'material-react-table';
import { Box } from '@mui/material';
import PaginationContainer from '../../../components/PaginationComponent/indexContainer';
import SmartSearchDropdownContainer from '../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';

const CreateProductView = () => {
    const { t } = useTranslation();
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'srno', //access nested data with dot notation
                header: 'Sr No',
                size: 50
            },
            {
                accessorKey: 'servicename',
                header: 'Service Name',
                size: 100
            }
        ],
        []
    );
    const Data = [
        {
            id: 1,
            srno: '1',
            servicename: 'Magna House'
        },
        {
            id: 2,
            srno: '2',
            servicename: 'Apple Hill'
        },
        {
            id: 3,
            srno: '3',
            servicename: 'Southern Hill Hospital'
        },
        {
            id: 4,
            srno: '4',
            servicename: 'The Riders'
        }
    ];
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="middle_content" id="middle_content">
                        <div className="row mb-2">
                            <div className="col-6">
                                <a href="/product-management">
                                    <button className="btn btn-white  btn-sm me-2 ">
                                        <i className="fa fa-chevron-left"></i> Back
                                    </button>
                                </a>
                            </div>
                            <div className="col-6 text-right"></div>
                        </div>
                    </div>
                    <div className="box-shadow p-0">
                        <div>
                            <div className="box-header">
                                <div className="row align-items-center h-100">
                                    <div className="col-md-4 flex-order-2 py-2">
                                        <div className=" ">
                                            <button className="btn btn-sm btn-secondary ms-2">Clear</button>
                                            <button className="btn btn-sm btn-primary ms-2">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="administrator-content">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="modal-title-header mb-4">
                                        <h3>Product Detail</h3>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Code</label>
                                        <input type="text" name="name" className="form-control white" value="" />
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Description</label>
                                        <input type="text" name="name" className="form-control white" value="" />
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">UOM</label>
                                        <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                            <SmartSearchDropdownContainer
                                                smartSearchLabel={'Select UOM*'}
                                                defaultValue={'Helllo'}
                                                onChangeSmartSearch={(value: any) => {
                                                    const event = new Event('change');
                                                    let obj = {
                                                        name: 'messageCode',
                                                        value: value?.id
                                                    };
                                                }}
                                                smartSearch={'Designation'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Unit Price</label>
                                        <input type="text" name="name" className="form-control white" value="" />
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Tax Rate</label>
                                        <input type="text" name="name-email" className="form-control white " value="" />
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Status</label>
                                        <div className="d-flex align-items-center">
                                            <label className="form--radio-label mt-2 d-flex align-items-center me-3">
                                                <input name="status" type="radio" id="active" value="active" checked />
                                                <span className="ms-1">Active</span>
                                            </label>
                                            <label className="form--radio-label mt-2 d-flex align-items-center">
                                                <input name="status" type="radio" id="inactive" value="inactive" />
                                                <span className="ms-1">Inactive</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="administrator-content pt-0">
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="modal-title-header mb-4">
                                        <h3>Assign Services</h3>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4">
                                    <div className="d-flex mb-3 flex-column">
                                        <label className="bold mb-2">Service</label>
                                        <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                                            <SmartSearchDropdownContainer
                                                smartSearchLabel={'Select Service'}
                                                defaultValue={'Helllo'}
                                                onChangeSmartSearch={(value: any) => {
                                                    const event = new Event('change');
                                                    let obj = {
                                                        name: 'messageCode',
                                                        value: value?.id
                                                    };
                                                }}
                                                smartSearch={'Organization'}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-12 col-lg-6">
                                    <div className="row">
                                        <div>
                                            <label className="bold mt-2 mb-2">Selected Services List</label>
                                            <div className="position-relative mb-2">
                                                <input type="text" placeholder="Search" name="leadSmartSearch" className="form-control white search-invoice" />
                                                <i className="fa-sharp fa-solid fa-magnifying-glass search-icon"></i>
                                            </div>
                                        </div>
                                        <div className="col-xl-8">
                                            <div className="services-list-table ">
                                                <MaterialReactTable
                                                    columns={columns}
                                                    data={Data}
                                                    enableGrouping
                                                    enableColumnActions={false}
                                                    enableBottomToolbar={false}
                                                    enableTopToolbar={false}
                                                    enableColumnDragging={false}
                                                    // getRowId={(row) => row.integrationId}
                                                    manualPagination
                                                    manualSorting
                                                    // rowCount={rowCount}
                                                    localization={{ actions: 'Actions' }}
                                                    enableRowActions
                                                    positionActionsColumn="last"
                                                    renderRowActions={({ row }) => (
                                                        <div className="d-flex">
                                                            <a className="status-icon justify-content-center">
                                                                <i className="fa-solid fa-trash danger-text"></i>
                                                            </a>
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="row align-items-center invoice-table-pagination border-0">
                                                <div className="col-lg-6">
                                                    <div className="d-flex align-items-center">
                                                        <p className="purple-text bold">Displaying 1 - 2 of 10</p>
                                                        <div className="d-flex align-items-center ms-3">
                                                            <label className="mb-0 bold nowrap me-2">Page Size:</label>
                                                            <select className={`form-control white page-select`} id="supportedPersoIntegrationPageSize">
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 ">
                                                    <PaginationContainer currentPage={1} totalCount={100} pageSize={10} onPageChange={(page: any) => {}} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateProductView;
