import React, { useEffect, useState } from 'react';
import PatientDetailsReportsView from './PatientDetailsReportView';

const PatientDetailsReportsContainer  = (props: any) => {
return (
    <>
        <PatientDetailsReportsView/>
    </>
);
};


export default PatientDetailsReportsContainer;