import { useSelector } from "react-redux";
import ModalWrapperContainer from "../ModalWrapper/indexContainer";
import { RootState } from "../../store";
import { useEffect, useState } from "react";
import { t } from "i18next";
import { Autocomplete, Checkbox, TextField, createFilterOptions } from "@mui/material";
import { ExportAdmission } from "../../service/dashboard-service";
import { Controller, useForm } from "react-hook-form";
import { ExportOdooIntegration } from '../../service/integration-service';
import { Roles, Source } from '../../utils/constant';
import { SubmitHandler } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ExportReferrals } from "../../service/leads";
import swal from 'sweetalert';
import warning from "../../assets/images/warning.png"
type ExportAdmissionPayload = {
  serviceId: any;
  organizationId: number
};
type ExportReferralPayload = {
  serviceId: any;
};

type ExportIntegrationPayload = {
  serviceId: any;
  dateFrom: any;
  dateTo: any;
  reference: string;
  messageCode: string;
  status: any;
};

const ExportBedAndRelatedDataModalView = (props: any) => {

  const [open, setOpen] = useState(false);
  const { DataServiceDetail } = useSelector((state: RootState) => state.serviceDetail);
  const { DataOrganizationDetail } = useSelector((state: RootState) => state.organizationDetail);
  const { DataLogin } = useSelector((state: RootState) => state.login);
  const [services, setServices] = useState<any>([])
  const [serviceId, setServiceId] = useState<any>([]);
  const [exportAdmissionPayload, setExportAdmissionPayload] = useState<ExportAdmissionPayload>({
    serviceId: [],
    organizationId: DataOrganizationDetail.organizationId,
  });

  const [exportReferralPayload, setExportReferralPayload] = useState<ExportReferralPayload>({
    serviceId: [],
  });

  const validationSchema = Yup.object().shape({
    exporttype: Yup.string().required()
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isValid }
  } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });

  const [exportIntegrationPayload, setExportIntegrationPayload] = useState<ExportIntegrationPayload>({
    serviceId: [],
    dateFrom: props?.Integration?.dateFrom,
    dateTo: props?.Integration?.dateTo,
    reference: props?.Integration?.reference,
    messageCode: props?.Integration?.messageCode,
    status: props?.Integration?.status,
  });

  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [allChecked, setAllChecked] = useState<any>(false);
  const [exportvalue, setexportvalue] = useState<any>();

  const allSelected = services.length === selectedOptions.length;

  const handleToggleOption = (selectedOp: any) => {
    setSelectedOptions(selectedOp);
  }

  const handleClearOptions = () => {
    setSelectedOptions([]);
    setServiceId([])
    setAllChecked(false)
  }

  const handleSelectAll = (isSelected: any) => {
    if (isSelected) {
      setSelectedOptions(services);
    } else {
      handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (event: any, selectedOps: any, reason: any) => {
    if (reason === "selectOption") {
      setOpen(false);
    }

    if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOps.find((option: any) => option.label === "Select all")) {
        handleToggleSelectAll();
        const result = services.filter((el: any) => el.label !== "Select all");
        setSelectedOptions(result)
        setServiceId(result.map((opt: any) => opt.value))
        setAllChecked(true)
        if (selectedOps.find((option: any) => option.label === "Select all") && allSelected == true) {
          setAllChecked(false)
          setServiceId([]);
          setSelectedOptions([]);
        }
      } else {
        handleToggleOption && handleToggleOption(selectedOps);
        setServiceId(selectedOps.map((i: any) => i.value))
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };

  const filter = createFilterOptions();

  let serviceOptions: any = [];

  useEffect(() => {
    let serviceObj = DataLogin.userServiceModels.map((ser: any) => ser.servicesList).flat();
    let filterserviceObj = serviceObj.filter((records:any) => records.organizationId === DataOrganizationDetail.organizationId)
    serviceOptions = [];
    const uniqueServiceIds = new Set();
    
    for (let i = 0; i < filterserviceObj.length; i++) {
      if (!uniqueServiceIds.has(filterserviceObj[i].serviceId)) {
        uniqueServiceIds.add(filterserviceObj[i].serviceId);
        serviceOptions.push({ value: filterserviceObj[i].serviceId, label: filterserviceObj[i].serviceName });
      }
    }
    let opt = serviceOptions;
    setServices(opt);
    setValue('exporttype', 'admissions');
    setexportvalue(1);

  }, [])

  useEffect(() => {
    if (props?.source == Source.Integration) {
      let tempExportIntegrationPayload: any = exportIntegrationPayload;
      tempExportIntegrationPayload.serviceId = serviceId;
      setExportIntegrationPayload(tempExportIntegrationPayload);
    } else if (props?.source == Source.Admission) {
      let tempExportAdmissionPayload: any = exportAdmissionPayload;
      tempExportAdmissionPayload.serviceId = serviceId;
      setExportAdmissionPayload(tempExportAdmissionPayload);
    } else if (props?.source == Source.Referral) {
      let tempExportAdmissionPayload: any = exportReferralPayload;
      tempExportAdmissionPayload.serviceId = serviceId;
      setExportReferralPayload(tempExportAdmissionPayload);
    }

  }, [serviceId])

  useEffect(() => {
    let tempExportAdmissionPayload: any = exportAdmissionPayload;
    tempExportAdmissionPayload.exportType = Number(exportvalue);
    setExportAdmissionPayload(tempExportAdmissionPayload);
  }, [exportvalue])


  const handleRadioChange = (e: any) => {
    let value = e.target.value;
    if (value === 'admissions') {
      setexportvalue(1);
    } else if (value === 'discharged') {
      setexportvalue(2);
    } else if (value === 'reservation') {
      setexportvalue(3);
    } else if (value === 'draft_patients') {
      setexportvalue(4);
    }
  };

  const onClickHandlerAdmission = async () => {
    var response;
    if (props?.source == Source.Integration) {
      response = await ExportOdooIntegration(exportIntegrationPayload);
    } else if (props?.source == Source.Admission) {
      response = await ExportAdmission(exportAdmissionPayload);
    } else if (props?.source == Source.Referral) {
      response = await ExportReferrals(exportReferralPayload);
    }
    if (response.status === true) {
      let mediaType = response?.data?.fileType;
      let useInput = response?.data?.csvData;
      let tempLink = document.createElement('a');
      tempLink.href = mediaType + useInput;
      tempLink.setAttribute('download', `${response?.data?.fileName}.xlsx`);
      tempLink.click();
      props.onClose();
    } else {
      swal({
        title: '',
        text: response?.message,
        icon: warning,
        // buttons: ['No', 'Yes'],
        dangerMode: true
      })
    }

  };

  return (
    <div className="export-popup-mui">
      <ModalWrapperContainer
        title={props?.title}
        onClose={props.onClose}
        className="modal modal-extrasmall"
        content={
          <>
            {props.source === Source.Admission &&
              <div className="d-flex mt-3 mb-2 ms-2">
                <label className="form--radio-label mb-2 d-flex align-items-center me-3">
                  <input
                    {...register('exporttype', {
                      onChange: (e: any) => {
                        handleRadioChange(e);
                      }
                    })}
                    type="radio"
                    name="exporttype"
                    id="admissions"
                    value="admissions"
                  />
                  <span className="ms-1">{t('BedSection.Admissions')}</span>
                </label>
                <label className="form--radio-label mb-2 d-flex align-items-center me-3">
                  <input
                    {...register('exporttype', {
                      onChange: (e: any) => {
                        handleRadioChange(e);
                      }
                    })}
                    type="radio"
                    name="exporttype"
                    id="discharged"
                    value="discharged"
                  />
                  <span className="ms-1">{t('BedSection.Discharged')}</span>
                </label>
                <label className="form--radio-label mb-2 d-flex align-items-center me-3">
                  <input
                    {...register('exporttype', {
                      onChange: (e: any) => {
                        handleRadioChange(e);
                      }
                    })}
                    type="radio"
                    name="exporttype"
                    id="reservation"
                    value="reservation"
                  />
                  <span className="ms-1">{t('BedSection.Reservation')}</span>
                </label>
                <label className="form--radio-label mb-2 d-flex align-items-center">
                  <input
                    {...register('exporttype', {
                      onChange: (e: any) => {
                        handleRadioChange(e);
                      }
                    })}
                    type="radio"
                    name="exporttype"
                    id="draft-patients"
                    value="draft_patients"
                  />
                  <span className="ms-1">{t('BedSection.Draft_Patients')}</span>
                </label>
              </div>
            }

            <div className="d-flex align-items-center ms-2">
              <Controller
                control={control}
                name="MultiSelect"
                rules={{
                  required: ""
                }}
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    size="small"
                    id="checkboxes-tags-export"
                    multiple
                    disableCloseOnSelect
                    options={services}
                    value={selectedOptions}
                    limitTags={2}
                    getOptionLabel={(option: any) => option.label}
                    filterOptions={(options: any, params: any) => {
                      const filtered = filter(options, params);
                      return [{ id: 0, label: "Select all" }, ...filtered];
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    style={{ width: 500 }}
                    renderOption={(props: any, option: any, { selected }) => {
                      const selectAllProps =
                        option.label === "Select all" ? { checked: allChecked } : {};
                      return (
                        <li {...props}>
                          <Checkbox checked={selected} {...selectAllProps} />
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder={serviceId.length > 0 ? "" : "Select service"} />
                    )}
                    onChange={handleChange}
                  />
                )}
              />
            </div>
          </>
        }
        footer={
          <div className="d-flex align-items-center ms-2">
            <button className='swal-button swal-button--cancel me-2' onClick={props.onClose}>{t('ExportBedData.Cancel')}</button>
            <button className='swal-button swal-button--confirm swal-button--danger' disabled={serviceId.length > 0 ? false : true} onClick={onClickHandlerAdmission}>{t('ExportBedData.Export')}</button>
          </div>
        }
      />
    </div>
  );
}

export default ExportBedAndRelatedDataModalView;