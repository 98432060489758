import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { updateLeadToAssessment } from '../../../../../../service/assessment-service';
import swal from 'sweetalert';
import eventEmitter from '../../../../../../utils/eventEmitter';
import { useDispatch } from 'react-redux';
import { setNavigationAction } from '../../../../../../store/dashboard/navigationReducer';
import { getFacilitydata, getLeaddata } from '../../../../../../service/leads';
import { removeFacilityInformationAction } from '../../../../../../store/lead/getFacility';
import RenderModalContainer from '../../../../../../components/RenderModal/indexContainer';
import ScheduleAssessmentContainer from '../../../../../../components/ScheduleAssessmentModal/indexContainer';
import RejectContainer from '../../../../../../components/RejectModal/RejectContainer';
import SmartSearchDropdownContainer from '../../../../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { BASEURLDEV, API_ENDPOINT } from '../../../../../../service/ApiConstants';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { NHSAcceptRejectPooledService } from '../../../../../../service/nhs-user';
import warning from '../../../../../../assets/images/warning.png';
import CostRejectModalContainer from '../../../../../../components/CostRejectModal/CostRejectModalContainer';
import { getCostingRejectiondata } from '../../../../../../service/costing';
import { Roles } from '../../../../../../utils/constant';
import { CustomClearIndicator } from '../../../../../../components/PoolingViewCustomComponents/CustomClearIndicator';
import { CustomDropdownIndicator } from '../../../../../../components/PoolingViewCustomComponents/CustomDropdownIndicator';
import { CustomMultiValueRemove } from '../../../../../../components/PoolingViewCustomComponents/CustomMultiValueRemove';

const customStyles = {
    option: (base: any, { data, isDisabled, isFocused, isSelected }: any) => {
        return {
            ...base,
            backgroundColor: isFocused ? '#e9e9e9' : '#ffffff'
        };
    }
};
const showWarning = (message: string, success?: boolean) => {
    swal({
        title: '',
        text: message,
        icon: success ? 'success' : warning,
        dangerMode: true
    });
};
const PoolingView = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showCostingModal, setShowCostingModal] = useState<boolean>(false);
    const [leadpoolingid, setleadpoolingid] = useState<any>();
    let [serviceTypeSmartSearch, setServiceTypeSmartSearch] = useState<any>(undefined);
    let [defaultservice,setdefaultservice] = useState<any>();
    const [servicename,setservicename] = useState<any>();
    const [selectedOption, setSelectedOption] = useState();
    let [options, setoptions] = useState<any>([]);
    let [poolinglist, setpoolinglist] = useState<any>();
    const [servicecount, setservicecount] = useState<Number>(0);
    const [serviceids, setserviceids] = useState<any>([]);
    const { StatusFacilityInfo, DataFacilityInfo, MessageFacilityInfo, ErrorFacilityInfo } = useSelector((state: RootState) => state.facilityInformation);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    let facility: any = [];

    const [poolingDataFromMap, setPoolingDataFromMap] = useState<any>(null);
    const [poolingService, setPoolingService] = useState<any[]>([]);

    const [costingdata, setcostingdata] = useState(null);

    const { Data } = useSelector((state: RootState) => state.navigation);

    const [poolingId, setPoolingId] = useState<number | null>(null);

    const [poolingIdForNHSReject, setPoolingIdForNHSReject] = useState<number | null>(null);
    
    const { StatusRoleSelected, DataRoleSelected } = useSelector((state: RootState) => state.roleSelected);
    
    const { AppliedFilterData } = useSelector((state: RootState) => state.appliedFilter);

    const validationSchema = Yup.object().shape({
        servicetype: Yup.string().trim().strict(true).nonNullable().required(),
    });

    const { register, control, handleSubmit, watch, getValues, reset, formState, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;

    async function getDropdowns() {
        await getFacilitydata(props.serviceid);
    }

    useEffect(() => {
        dispatch(removeFacilityInformationAction());
        // getDropdowns();
        setpoolinglist(DataLeadInfo?.data?.leadPoolingDetailsList);
    }, [DataLeadInfo]);

    useEffect(() => {
        if (poolingDataFromMap && Data !== '' && Data.leadstatus === 2 && props.nhs) {
            (async () => {
                await getFacilitydata(poolingDataFromMap.serviceId);
                const newArr = [...poolingService]
                const existingObj = newArr.find(obj => obj.value === poolingDataFromMap.serviceId);
                const existingInPooling = poolinglist.find((obj: any) => obj.service_Id === poolingDataFromMap.serviceId)
                if (!existingObj && !existingInPooling) {
                    newArr.push({ value: poolingDataFromMap.serviceId, label: poolingDataFromMap.serviceName });
                } else {
                    showWarning(`${poolingDataFromMap.serviceName} is already selected`)
                }
                onOptionChange([...newArr]);
            })();
        }
    }, [poolingDataFromMap, Data, props.nhs]);

    useEffect(() => {
        eventEmitter.on("PoolServiceActionFromMap", (data) => setPoolingDataFromMap(data));
        return () => {
            eventEmitter.off("PoolServiceActionFromMap", (data) => setPoolingDataFromMap(data));
        }
    }, []);
   
    useEffect(() => {
        if (options.length > 0) {
            if (options?.length > 0 && DataLeadInfo?.data?.leadPoolingDetailsList?.length > 0) {
                for (var i = options.length - 1; i >= 0; i--) {
                    for (var j = 0; j < DataLeadInfo?.data?.leadPoolingDetailsList.length; j++) {
                        if (
                            options[i] &&
                            options[i].label === DataLeadInfo?.data?.leadPoolingDetailsList[j].serviceName &&
                            DataLeadInfo?.data?.leadPoolingDetailsList[j].poolingStatus !== 'Rejected'
                        ) {
                            options.splice(i, 1);
                        }
                    }
                }
                setoptions(options);
            }
        }
    }, [options]);

    useEffect(()=>{
        if(props?.leadid !== undefined && props?.leadid > 0) {
            setServiceTypeSmartSearch(undefined);
            setserviceids([]);
            setPoolingService([]);
            setservicecount(0);
        }        
    },[props.leadid])

    useEffect(()=>{
        if(serviceTypeSmartSearch === undefined){
            let objServiceTypeSmartSearch = {
                url: `${BASEURLDEV}${API_ENDPOINT.GetDDLServiceTypeList}`,
            };
            setServiceTypeSmartSearch(objServiceTypeSmartSearch);
        }
    },[serviceTypeSmartSearch])

    useEffect(() => {
        // setoptions([]);
        // setdefaultservice(undefined);
        // let objServiceTypeSmartSearch = {
        //     url: `${BASEURLDEV}${API_ENDPOINT.GetDDLServiceTypeList}`,
        // };
        // setServiceTypeSmartSearch(objServiceTypeSmartSearch);
        // setdefaultservice(DataLeadInfo?.data?.leadPoolingDetailsList[j].serviceName)
        if (StatusFacilityInfo) {
            if (DataFacilityInfo.data.length > 0) {
                facility = [];
                for (let i = 0; i < DataFacilityInfo.data.length; i++) {
                    facility.push({ value: DataFacilityInfo.data[i].key, label: DataFacilityInfo.data[i].value });
                }
                let opt = facility;
                setoptions(opt);
            }
        }
    }, [DataFacilityInfo, StatusFacilityInfo, DataLeadInfo, poolingDataFromMap]);


    const toggle = () => {
        setShowModal(false);
    };

    const onOptionChange = (options: any) => {
        setservicecount(options.length);
        setPoolingService(options);
        if (options.length > 0) {
            const diifer = options.length <= serviceids.length;
            if (diifer) {
                // item is removed
            }
            var arr = options.map((object: any) => object.value);
            setserviceids(arr);
        } else {
            setserviceids([]);
        }
    };

    const openskippopup = (poolid:any,servicename:any) =>{
        setleadpoolingid(poolid);
        setservicename(servicename);
        setShowModal(true);
    }

    const refreshAfterReschedule = async () => {
        const nextStage = props.finalStatus > 3 ? props.finalStatus : 3;
        props.refreshData(3, nextStage);
    };

    useEffect(() => {
        props.serviceids(serviceids);
    }, [serviceids]);

    const onAddPartSelect = (i: number) => {
        const updatedData = poolinglist.map((item: any, idx: number) => {
            if (item.loadPoolingId === i) {
                return {
                    ...item,
                    itemPartToggle: !item.itemPartToggle
                };
            }
            return item;
        });
        setpoolinglist(updatedData);
    };

    const handleAcceptService = (pooldata: any) => {
        swal({
            title: '',
            text: `Are you sure you want to accept the lead ${props.referralId}?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: false
        }).then(async (confirm: any) => {
            if (confirm) {
                acceptPooledService(pooldata);
            }
        });
    }

    const acceptPooledService = async (pooldata: any) => {
        const payload = {
            poolingId: pooldata.loadPoolingId,
            nhsPoolingStatus: 1,
            rejectionReasonId: null,
            remarks: ""
        };

        const response = await NHSAcceptRejectPooledService(payload);
        if (response?.status === true) {
            swal(response?.message, {
                icon: 'success'
            }).then(async () => {
                getLeaddata(Data.leadid, Data.leadstatus);
            });
        } else {
            swal("Something went wrong.", {
                icon: 'error'
            });
        }
    }

    const handleRejectService = async (pooldata: any) => {
        setShowCostingModal(true);
        const res = await getCostingRejectiondata(6);
        setcostingdata(res.data);
        setPoolingIdForNHSReject(pooldata.loadPoolingId);
    }
   

    useEffect(()=>{
        if(props.clearservice !== undefined){
            setdefaultservice(undefined);
        }
    },[props.clearservice])

    useEffect(() => {
        (async () => {
            setValue('servicetype', AppliedFilterData.serviceTypeId?.toString());
            if (AppliedFilterData?.serviceTypeId?.toString()) {
                await getFacilitydata(AppliedFilterData.serviceTypeId);
            } else {
                await getFacilitydata();
            }
        })();
    }, [AppliedFilterData.serviceTypeId, setValue]);

    const customComponents = {
        ClearIndicator: CustomClearIndicator,
        DropdownIndicator: CustomDropdownIndicator,
        MultiValueRemove: CustomMultiValueRemove
    };

    const showSelectedServices = () => {
        if(options.length > 0){
            return <Select key={poolingDataFromMap} value={poolingService} placeholder="Select Services" className="mt-3 selectbox" options={options} isMulti styles={customStyles} onChange={onOptionChange} components={customComponents} />
        } else {
            if(poolingService.length > 0){
                return <Select key={poolingDataFromMap} value={poolingService} placeholder="Select Services" className="mt-3 selectbox" options={options} isMulti styles={customStyles} onChange={onOptionChange} components={customComponents} />
            } else {
                return <></>
            }
        }
    }

    const handlePoolClick = async () => {
        props.pooling(() => {
            setPoolingService([]);
            setserviceids([]);
            setservicecount(0);
            if(!props.nhs){
                setServiceTypeSmartSearch(undefined);
                setValue('servicetype', 0, { shouldValidate: true });
            }
        });
    }
    
    return (
        <div>
            <div>
                {/* <label className="bold">{t('ReferralLead.Service_Type')}</label>
                <input type="text" placeholder={`${props.servicetype}`} className="form-control white mt-2" disabled /> */}
                 {serviceTypeSmartSearch !== undefined && !props.nhs && (
                        <div className="auto-complete-dropdown auto-complete-dropdown-cus">
                            <SmartSearchDropdownContainer
                                smartSearchLabel={t('LeadSidebar.service')}
                                errors={errors.servicetype}
                                onChangeSmartSearch={(value: any) => {
                                    const event = new Event('change');
                                    let obj = {
                                        name: 'servicetype',
                                        value: value?.id?.toString()
                                    };
                                    //@ts-ignore
                                    Object.defineProperty(event, 'target', { writable: false, value: obj });
                                    // setptype(value?.id?.toString());
                                    // console.log(value);
                                    setValue('servicetype', value?.id?.toString(), { shouldValidate: true });
                                    if (value !== '') {
                                        props.updateData(event);
                                    }
                                    else{
                                        props.updateData(event);
                                        if(serviceids.length === 0){
                                        setoptions([]);
                                        setserviceids([]);
                                        setservicecount(0);
                                        }
                                        else{
                                            setoptions([]);
                                        }
                                    }
                                }}
                                smartSearch={serviceTypeSmartSearch}
                                valFromMap={poolingDataFromMap?.serviceTypeName}
                                nhs={props.nhs}
                            />
                        </div>
                    )}
            </div>
            {/* {options.length > 0 && <Select key={poolingDataFromMap} value={poolingService} placeholder="Select Services" className="mt-3 selectbox" options={options} isMulti styles={customStyles} onChange={onOptionChange} />} */}
            {(props?.servicetypeid !== undefined && getValues('servicetype') !== 0) && <>
            <div className='react-select-multi-select'>
                {showSelectedServices()}
            </div>
            </>}
            {props.nhs && <>
                <div className='react-select-multi-select'>
                {showSelectedServices()}
            </div>
            </>}

            {/* {DataLeadInfo?.data?.leadPoolingDetailsList?.length > 0 && <p className="bold purple-text mt-4 mb-0">{t('ReferralLead.Selected_Services')}</p>}
            {DataLeadInfo?.data?.leadPoolingDetailsList?.length > 0 &&
                DataLeadInfo?.data?.leadPoolingDetailsList.map((pooldata: any) => {
                    return (
                        <>
                            {pooldata.poolingStatus === 'Pending' && (
                                <div className="status-box mt-3">
                                    <div className="d-flex justify-content-between mb-2 align-items-start">
                                        <p className="bold">{pooldata.serviceName}</p>
                                        <span className="referral-status yellow-status">{t('ReferralLead.Pending')}</span>
                                    </div>
                                    <p>
                                        <i> {pooldata.pooledOn}</i>
                                    </p>
                                </div>
                            )}
                            {pooldata.poolingStatus === 'Rejected' && (
                                <div className="status-box mt-3">
                                    <div className="d-flex justify-content-between mb-2 align-items-start">
                                        <p className="bold">{pooldata.serviceName}</p>
                                        <span className="referral-status red-status">{t('ReferralLead.Rejected')}</span>
                                    </div>
                                    <p>
                                        <i>{pooldata.pooledOn}</i>
                                    </p>
                                    <p className="mt-1">
                                        <i>
                                            {pooldata.aceeptRejectOn}{' '}
                                            <i className="fa-solid fa-circle-info ms-1" data-tooltip-id="my-tooltip-delete" data-tooltip-content={`${pooldata?.reason} - ${pooldata?.rejectRemarks}`}></i>
                                        </i>
                                    </p>
                                </div>
                            )}
                            {pooldata.poolingStatus === 'Accepted' && (
                                <div className="status-box mt-3">
                                    <div className="d-flex justify-content-between mb-2 align-items-start">
                                        <p className="bold">{pooldata.serviceName}</p>
                                        <span className="referral-status green-status">{t('ReferralLead.Accepted')}</span>
                                    </div>
                                    <p>
                                        <i>{pooldata.pooledOn}</i>
                                    </p>
                                    <p className="mt-1">
                                        <i>
                                            {pooldata.aceeptRejectOn}
                                        </i>
                                    </p>
                                    {pooldata.canEdit && (
                                        <div className="mt-2 text-center">
                                            <button
                                                disabled={pooldata.hasMovedToAssessment}
                                                className="btn btn-primary btn-sm"
                                                // onClick={() => onClickHandlerMoveToAssessment(pooldata)}
                                                onClick={() => setPoolingId(pooldata.loadPoolingId)}
                                                data-tooltip-id="my-tooltip-Assessment"
                                                data-tooltip-content="Schedule Assessment"
                                            >
                                                {t('ReferralLead.Schedule_Assessment')} <i className="fa-solid fa-arrow-right ms-1"></i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    );
                })} */}

            {poolinglist?.length > 0 && <p className="bold purple-text mt-4 mb-0">{t('ReferralLead.Selected_Services')}</p>}

            {poolinglist?.length > 0 &&
                poolinglist.map((pooldata: any,i:number) => {
                    return (
                        <>
                            {pooldata.poolingStatus === 'Pending' && (
                                <div className="status-box mt-3 bg-white">
                                    <div>
                                        <div className="d-flex justify-content-between mb-2 align-items-start">
                                            <p className="bold">{pooldata.serviceName}</p>
                                            <span className="referral-status yellow-status">{t('ReferralLead.Pending')}</span>
                                        </div>

                                        {/* <i> {pooldata.pooledOn}</i> */}
                                        {pooldata.referralLogList?.length > 0 &&
                                            pooldata.itemPartToggle &&
                                            pooldata.referralLogList.map((logs: any) => {
                                                return (
                                                    <>
                                                        {logs.reason === null && (
                                                            <p>
                                                                <i>{logs.logMessage}</i>
                                                            </p>
                                                        )}

                                                        <p>
                                                            {logs.reason !== null && (
                                                                <i>
                                                                    {logs.logMessage}{' '}
                                                                    <i
                                                                        className="fa-solid fa-circle-info ms-1"
                                                                        data-tooltip-id="my-tooltip-delete"
                                                                        data-tooltip-content={`${logs?.reason} - ${logs?.comment}`}
                                                                    ></i>
                                                                </i>
                                                            )}
                                                        </p>
                                                    </>
                                                );
                                            })}

                                        {pooldata.referralLogList?.length > 0 &&
                                            !pooldata.itemPartToggle &&
                                            pooldata.referralLogList.slice(0, 3).map((logs: any) => {
                                                return (
                                                    <>
                                                        {logs.reason === null && (
                                                            <p>
                                                                <i>{logs.logMessage}</i>
                                                            </p>
                                                        )}

                                                        <p>
                                                            {logs.reason !== null && (
                                                                <i>
                                                                    {logs.logMessage}{' '}
                                                                    <i
                                                                        className="fa-solid fa-circle-info ms-1"
                                                                        data-tooltip-id="my-tooltip-delete"
                                                                        data-tooltip-content={`${logs?.reason} - ${logs?.comment}`}
                                                                    ></i>
                                                                </i>
                                                            )}
                                                        </p>
                                                    </>
                                                );
                                            })}
                                    </div>

                                    {pooldata.referralLogList?.length > 3 && (
                                        <div className="text-right mt-3">
                                            {!pooldata.itemPartToggle && (
                                                <a href="#" className="bold" onClick={() => onAddPartSelect(pooldata.loadPoolingId)}>
                                                    <i className="fa-solid fa-clock-rotate-left"></i> View History
                                                </a>
                                            )}
                                            {pooldata.itemPartToggle && (
                                                <a href="#" className="bold" onClick={() => onAddPartSelect(pooldata.loadPoolingId)}>
                                                    <i className="fa-solid fa-clock-rotate-left"></i> Hide History
                                                </a>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}

                            {pooldata.poolingStatus === 'Rejected' && (
                                <div className="status-box mt-3 bg-white">
                                    <div>
                                        <div className="d-flex justify-content-between mb-2 align-items-start">
                                            <p className="bold">{pooldata.serviceName}</p>
                                            <span className="referral-status red-status">{t('ReferralLead.Rejected')}</span>
                                        </div>
                                        {pooldata.referralLogList?.length > 0 &&
                                            pooldata.itemPartToggle &&
                                            pooldata.referralLogList.map((logs: any) => {
                                                return (
                                                    <>
                                                        {logs.reason === null && (
                                                            <p>
                                                                <i>{logs.logMessage}</i>
                                                            </p>
                                                        )}

                                                        <p>
                                                            {logs.reason !== null && (
                                                                <i>
                                                                    {logs.logMessage}{' '}
                                                                    <i
                                                                        className="fa-solid fa-circle-info ms-1"
                                                                        data-tooltip-id="my-tooltip-delete"
                                                                        data-tooltip-content={`${logs?.reason} - ${logs?.comment}`}
                                                                    ></i>
                                                                </i>
                                                            )}
                                                        </p>
                                                    </>
                                                );
                                            })}

                                        {pooldata.referralLogList?.length > 0 &&
                                            !pooldata.itemPartToggle &&
                                            pooldata.referralLogList.slice(0, 3).map((logs: any) => {
                                                return (
                                                    <>
                                                        {logs.reason === null && (
                                                            <p>
                                                                <i>{logs.logMessage}</i>
                                                            </p>
                                                        )}

                                                        <p>
                                                            {logs.reason !== null && (
                                                                <i>
                                                                    {logs.logMessage}{' '}
                                                                    <i
                                                                        className="fa-solid fa-circle-info ms-1"
                                                                        data-tooltip-id="my-tooltip-delete"
                                                                        data-tooltip-content={`${logs?.reason} - ${logs?.comment}`}
                                                                    ></i>
                                                                </i>
                                                            )}
                                                        </p>
                                                    </>
                                                );
                                            })}
                                    </div>

                                    {pooldata.referralLogList?.length > 3 && (
                                        <div className="text-right mt-3">
                                            {!pooldata.itemPartToggle && (
                                                <a href="#" className="bold" onClick={() => onAddPartSelect(pooldata.loadPoolingId)}>
                                                    <i className="fa-solid fa-clock-rotate-left"></i> View History
                                                </a>
                                            )}
                                            {pooldata.itemPartToggle && (
                                                <a href="#" className="bold" onClick={() => onAddPartSelect(pooldata.loadPoolingId)}>
                                                    <i className="fa-solid fa-clock-rotate-left"></i> Hide History
                                                </a>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                             {pooldata.poolingStatus === 'Accepted' && (
                            <div className="status-box mt-3 bg-white">
                                    <div>
                                        <div className="d-flex justify-content-between mb-2 align-items-start">
                                            <p className="bold">{pooldata.serviceName}</p>
                                            <span className="referral-status green-status">{t('ReferralLead.Accepted')}</span>
                                        </div>
                                        {pooldata.referralLogList?.length > 0 &&
                                            pooldata.itemPartToggle &&
                                            pooldata.referralLogList.map((logs: any) => {
                                                return (
                                                    <>
                                                        {logs.reason === null && (
                                                            <p>
                                                                <i>{logs.logMessage}</i>
                                                            </p>
                                                        )}

                                                        <p>
                                                            {logs.reason !== null && (
                                                                <i>
                                                                    {logs.logMessage}{' '}
                                                                    <i
                                                                        className="fa-solid fa-circle-info ms-1"
                                                                        data-tooltip-id="my-tooltip-delete"
                                                                        data-tooltip-content={`${logs?.reason} - ${logs?.comment}`}
                                                                    ></i>
                                                                </i>
                                                            )}
                                                        </p>
                                                    </>
                                                );
                                            })}

                                        {pooldata.referralLogList?.length > 0 &&
                                            !pooldata.itemPartToggle &&
                                            pooldata.referralLogList.slice(0, 3).map((logs: any) => {
                                                return (
                                                    <>
                                                        {logs.reason === null && (
                                                            <p>
                                                                <i>{logs.logMessage}</i>
                                                            </p>
                                                        )}

                                                        <p>
                                                            {logs.reason !== null && (
                                                                <i>
                                                                    {logs.logMessage}{' '}
                                                                    <i
                                                                        className="fa-solid fa-circle-info ms-1"
                                                                        data-tooltip-id="my-tooltip-delete"
                                                                        data-tooltip-content={`${logs?.reason} - ${logs?.comment}`}
                                                                    ></i>
                                                                </i>
                                                            )}
                                                        </p>
                                                    </>
                                                );
                                            })}
                                        {pooldata.canEdit && !props.nhs && (
                                            <div className="mt-2">
                                                 <button
                                                    disabled={(pooldata.hasMovedToAssessment || DataLeadInfo?.data?.referralCloseLog !== null)}
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => openskippopup(pooldata.loadPoolingId,pooldata.serviceName)}
                                                    data-tooltip-id="my-tooltip-Assessment"
                                                    data-tooltip-content="Skip Assessment"
                                                >
                                                    {t('ReferralLead.Skip_Assessment')} <i className="fa-solid fa-arrow-right ms-1"></i>
                                                </button>
                                                <button
                                                    disabled={(pooldata.hasMovedToAssessment || DataLeadInfo?.data?.referralCloseLog !== null)}
                                                    className="btn btn-primary btn-sm ms-1"
                                                    // onClick={() => onClickHandlerMoveToAssessment(pooldata)}
                                                    onClick={() => setPoolingId(pooldata.loadPoolingId)}
                                                    data-tooltip-id="my-tooltip-Assessment"
                                                    data-tooltip-content="Schedule Assessment"
                                                >
                                                    {t('ReferralLead.Schedule_Assessment')} <i className="fa-solid fa-arrow-right ms-1"></i>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                {pooldata.referralLogList?.length > 3 && (
                                    <div className="text-right mt-3">
                                        {!pooldata.itemPartToggle && (
                                            <a href="#" className="bold" onClick={() => onAddPartSelect(pooldata.loadPoolingId)}>
                                                <i className="fa-solid fa-clock-rotate-left"></i> View History
                                            </a>
                                        )}
                                        {pooldata.itemPartToggle && (
                                            <a href="#" className="bold" onClick={() => onAddPartSelect(pooldata.loadPoolingId)}>
                                                <i className="fa-solid fa-clock-rotate-left"></i> Hide History
                                            </a>
                                        )}
                                    </div>
                                )}
                            </div>
                            )}

                            {pooldata.poolingStatus === "Pending Approval" && (
                                <div className="status-box mt-3 bg-white">
                                    <div>
                                        <div className="d-flex justify-content-between mb-2 align-items-start">
                                            <p className="bold">{pooldata.serviceName}</p>
                                            <span className="referral-status yellow-status">{pooldata.poolingStatus}</span>
                                        </div>

                                        {pooldata.referralLogList?.length > 0 &&
                                            pooldata.itemPartToggle &&
                                            pooldata.referralLogList.map((logs: any) => {
                                                return (
                                                    <>
                                                        {logs.reason === null && (
                                                            <p>
                                                                <i>{logs.logMessage}</i>
                                                            </p>
                                                        )}

                                                        <p>
                                                            {logs.reason !== null && (
                                                                <i>
                                                                    {logs.logMessage}{' '}
                                                                    <i
                                                                        className="fa-solid fa-circle-info ms-1"
                                                                        data-tooltip-id="my-tooltip-delete"
                                                                        data-tooltip-content={`${logs?.reason} - ${logs?.comment}`}
                                                                    ></i>
                                                                </i>
                                                            )}
                                                        </p>
                                                    </>
                                                );
                                            })}

                                        {pooldata.referralLogList?.length > 0 &&
                                            !pooldata.itemPartToggle &&
                                            pooldata.referralLogList.slice(0, 3).map((logs: any) => {
                                                return (
                                                    <>
                                                        {logs.reason === null && (
                                                            <p>
                                                                <i>{logs.logMessage}</i>
                                                            </p>
                                                        )}

                                                        <p>
                                                            {logs.reason !== null && (
                                                                <i>
                                                                    {logs.logMessage}{' '}
                                                                    <i
                                                                        className="fa-solid fa-circle-info ms-1"
                                                                        data-tooltip-id="my-tooltip-delete"
                                                                        data-tooltip-content={`${logs?.reason} - ${logs?.comment}`}
                                                                    ></i>
                                                                </i>
                                                            )}
                                                        </p>
                                                    </>
                                                );
                                            })}
                                    </div>
                                    {DataRoleSelected?.roleId === Roles.NHS && (
                                        <div className='mt-3 border-top'>
                                            <div className='w-100 d-flex justify-content-end mt-2'>
                                                <span><i onClick={() => handleAcceptService(pooldata)} className="fa-regular fa-circle-check fs-4 me-2 cursor-pointer" style={{ color: "#00dc2d" }}></i></span>
                                                <span><i onClick={() => handleRejectService(pooldata)} className="fa-regular fa-circle-xmark fs-4 cursor-pointer" style={{ color: "#fa1100" }}></i></span>
                                            </div>
                                        </div>
                                    )}

                                    {pooldata.referralLogList?.length > 3 && (
                                        <div className="text-right mt-3">
                                            {!pooldata.itemPartToggle && (
                                                <a href="#" className="bold" onClick={() => onAddPartSelect(pooldata.loadPoolingId)}>
                                                    <i className="fa-solid fa-clock-rotate-left"></i> View History
                                                </a>
                                            )}
                                            {pooldata.itemPartToggle && (
                                                <a href="#" className="bold" onClick={() => onAddPartSelect(pooldata.loadPoolingId)}>
                                                    <i className="fa-solid fa-clock-rotate-left"></i> Hide History
                                                </a>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    );
                })}
            {/* <div className='status-box mt-3'>
                <div className='d-flex justify-content-between mb-2 align-items-start'>
                    <p className='bold'>Magna House</p>
                    <span className='referral-status green-status'>{t('ReferralLead.Accepted')}</span>
                </div>
                <p><i>{t('ReferralLead.Pooled_on')} 17/07/2023 2:00 PM</i></p>
                <p  className='mt-1'><i>{t('ReferralLead.Accepted_on')} 18/07/2023 2:00 PM</i></p>
                <div className='mt-2 text-center'>
                <a href="#" className='btn btn-primary btn-sm'>{t('ReferralLead.Assessment')} <i className="fa-solid fa-arrow-right ms-1"></i></a>
                </div>
            </div>
            <div className='status-box mt-3'>
                <div className='d-flex justify-content-between mb-2 align-items-start'>
                    <p className='bold'>Champion House</p>
                    <span className='referral-status red-status'>{t('ReferralLead.Rejected')}</span>
                </div>
                <p><i>{t('ReferralLead.Pooled_on')} 17/07/2023 2:00 PM</i></p>
                <p className='mt-1'><i>{t('ReferralLead.Rejected_on')} 18/07/2023 2:00 PM <i className="fa-solid fa-circle-info ms-1" data-tooltip-id="my-tooltip-delete" data-tooltip-content="Rejection Reason - Lorem Ipsum is simply dummy text of the printing."></i></i></p>
            </div> */}
            <div className="d-flex justify-content-center  right_content_btn">
                {DataLeadInfo?.data?.leadPoolingDetailsList?.length == 0 && (
                    <button disabled={(!props.ispooling || DataLeadInfo?.data?.referralCloseLog !== null)} onClick={handlePoolClick} className="btn btn-primary">
                        Pool {servicecount} Services
                    </button>
                )}
                {DataLeadInfo?.data?.leadPoolingDetailsList?.length > 0 && (
                    <button disabled={(!props.ispooling || Data.fleadstatus > 2 || DataLeadInfo?.data?.referralCloseLog !== null)} onClick={handlePoolClick} className="btn btn-primary">
                        Add {servicecount} Service to Pool
                    </button>
                )}
                {/* <button className="btn btn-primary flex-1 me-2">
                    Add 1 Service to Pool
                </button> */}
            </div>
            <Tooltip id="my-tooltip-Assessment" className="my-tooltip" />

            <RenderModalContainer>
                {poolingId !== null && (
                    <ScheduleAssessmentContainer leadInfo={DataLeadInfo} onClose={() => setPoolingId(null)} poolingId={poolingId} refreshAfterReschedule={refreshAfterReschedule} allowEdit />
                )}
            {showModal && <RejectContainer  onClose={toggle} leadid={DataLeadInfo?.data} poolingId={leadpoolingid} mname={'skiprm'} title={`Skip Assessment for ${servicename}`}  referralid={props.referralId} refreshAfterReschedule={refreshAfterReschedule}/> }
            </RenderModalContainer>

            <RenderModalContainer>
                {showCostingModal && (
                    <CostRejectModalContainer 
                        costingData={costingdata}
                        onClose={() => setShowCostingModal(false)} 
                        referralid={props.referralId} 
                        nhsUserPooledLeadForReject={true}
                        poolingId={poolingIdForNHSReject}
                        setShowCostingModal={setShowCostingModal}
                    />
                )}
            </RenderModalContainer>
        </div>
    );
};

export default PoolingView;
