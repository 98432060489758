import React, { useEffect, useState } from 'react';
import ServiceTabListContainer from './ServiceTabList/ServiceTabListContainer';
import OverviewContainer from './Overview/OverviewContainer';
import AmenitiesContainer from './Amenities/AmenitiesContainer';
import LocationContainer from './Location/LocationContainer';
import ServicesContainer from './Services/ServicesContainer';
import PackagesContainer from './Packages/PackagesContainer';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { GetLowerSectionAvailability, GetServiceDetails } from '../../../../service/service-details-service';
import QuestionAnswerContainer from '../QuestionAnswer/QuestionAnswerContainer';
import { ROUTES_DATA } from '../../../../navigation/RoutesConstants';

const ServiceTabView = () => {
    const [serviceSection, SetServiceSection] = useState<any>(null)
    const { serviceSlug } = useParams();
    const [servicedata, SetServicedata] = useState<any>()
    const navigate = useNavigate();
    useEffect(() => {
        GetProviderServiceDetailsList();
        getservicedata();
    }, [])

    const GetProviderServiceDetailsList = async () => {
        const result = await GetLowerSectionAvailability(serviceSlug)
        SetServiceSection(result?.data)
    }
    const getservicedata = async () =>{
        const result = await GetServiceDetails(serviceSlug);
        if(result?.data?.isLock === true){
        navigate(ROUTES_DATA.NHS);
        }
        SetServicedata(result);
      }
    return (
        <div>
            {servicedata !== undefined && servicedata?.data?.isLock === false && <>
            <ServiceTabListContainer serviceSection={serviceSection} serviceName={servicedata?.data?.serviceName} />
            <OverviewContainer serviceSection={serviceSection}/>
            {serviceSection?.amenities && <AmenitiesContainer />}
            {serviceSection?.services && <ServicesContainer />}
            {serviceSection?.packages && <PackagesContainer />}
            <LocationContainer />
            {serviceSection?.faqs && <QuestionAnswerContainer />}
            </>}
        </div>
    );
};

export default ServiceTabView;
