import React from 'react';
import LocateAdminView from './LocateAdminView';

const LocateAdminContainer = () => {
    return (
        <div>
            <LocateAdminView/>
        </div>
    );
};

export default LocateAdminContainer;