import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import swal from 'sweetalert';
import warning from '../../../../../assets/images/warning.png';
import { useFieldArray, useForm } from 'react-hook-form';
import { getadditionalfields } from '../../../../../service/costing';
import RenderModalContainer from '../../../../../components/RenderModal/indexContainer';
import AddYourCommentModalContainer from '../../../../../components/AddYourCommentModal/AddYourCommentModalContainer';
import eventEmitter from '../../../../../utils/eventEmitter';

const CostingView = (props: any) => {
    const costingStatusDetails = props?.costingDetails?.referralLogList != null ? props?.costingDetails?.referralLogList[0] : null;
    const { t } = useTranslation();
    let [datavalid, setdatavalid] = useState<boolean>(false);
    const commonStringValidator = Yup.string()
        .matches(/^(?:\d*\.\d{1,2}|\d+)$/)
        .required('Is required');
    let [amountChanged, setAmountChanged] = useState<Number>(0);
    const [total, set_total] = useState<number>();
    const [isDisabled, setDisabled] = useState(true);
    const [disableSave, setdisableSave] = useState(false);
    const [iseditDisabled, setEditDisabled] = useState(false);
    let [additionalField, setadditionalField] = useState<any>([]);
    let [line, setline] = useState<any>([]);
    let [additionalvalues, setadditionalvalues] = useState<any>([]);
    const [selectedValue, setSelectedValue] = useState<any>([]);
    const [showAddCommentModal, setShowAddCommentModal] = useState<boolean>(false);
    const [commentText, setCommentText] = useState<{ [key: number]: string }>({});
    const [commentId, setCommentId] = useState<any>(null);
    const [costingTypeNameIndex, setCostingTypeNameIndex] = useState<any>(null);

    const [values, set_values] = useState({
        accommodation: '',
        baselineStaffCost: '',
        enhancedStaffingCost: '',
        mdt: '',
        transport: '',
        managementAndAdministration: '',
        total: 0
    });
    const validationSchema = Yup.object().shape({
        serviceUserName: Yup.string().notRequired(),
        serviceUserId: Yup.string().notRequired(),
        serviceLocation: Yup.string().notRequired(),
        accommodation: commonStringValidator,
        baselineStaffCost: commonStringValidator,
        enhancedStaffingCost: commonStringValidator,
        mdt: commonStringValidator,
        transport: commonStringValidator,
        managementAndAdministration: commonStringValidator,
        total: Yup.string().notRequired(),
        // additionalfield: Yup.array().of(
        //     Yup.object().shape({
        //         costingTypeId: Yup.string().required(),
        //         amount: commonStringValidator
        //     })
        // )
        additionalfield: Yup.array().of(
            Yup.object().shape({
                costingTypeId: Yup.string()
                    .required()
                    .test('is-not-empty', 'costingTypeId cannot be empty', value => value !== ''),
                amount: commonStringValidator
            }))
    });
    const { register, reset, formState, setValue, control, getValues, trigger } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({ name: 'additionalfield', control });

    useEffect(() => {
        if (formState.isValid) {
            setdatavalid(true);
        } else {
            setdatavalid(false);
        }
    }, [formState]);

    useEffect(() => { }, [errors]);

    async function GetAdditionalfields() {
        const result = await getadditionalfields();
        if (result.status === true) {
            setadditionalField(result.data.result);
        }
    }

    useEffect(() => {
        GetAdditionalfields();
    }, []);

    useEffect(() => {
        // Calling the method to sum the value
        calc_total(values, additionalvalues);
    }, [amountChanged, line]);

    const values_handler = (e: any, i: any) => {
        let name = e.target.name;
        let value = e.target.value;

        const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);

        if (!validated) {
            var splitVal = value.split('.');
            if (splitVal.length == 2 && splitVal[1].length > 2) {
                // user entered invalid input
                e.target.value = splitVal[0] + '.' + splitVal[1].substr(0, 2);
            }

            //e.target.value = '';
            return;
        }
        if (isNaN(value) || value == '') {
            const newValues = {
                ...values,
                [name]: ''
            };
            set_values(newValues);
        } else {
            const newValues = {
                ...values,
                [name]: value
            };

            set_values(newValues);
        }
        setAmountChanged(value);
    };

    const calc_total = (newValues: any, additional: any) => {
        const { accommodation, baselineStaffCost, enhancedStaffingCost, mdt, transport, managementAndAdministration } = newValues;
        const newTotal = Number(accommodation) + Number(baselineStaffCost) + Number(enhancedStaffingCost) + Number(mdt) + Number(transport) + Number(managementAndAdministration);
        var additionalsum: number = 0;
        if (line.length > 0) {
            for (let i = 0; i < line?.length; i++) {
                if (line[i]?.amount !== '') {
                    additionalsum = additionalsum + Number(line[i]?.amount);
                }
            }
        }
        set_total(Number(newTotal + additionalsum));

        set_values({
            ...values,
            total: Number(newTotal + additionalsum)
        });
        if (props.costingDetails?.costingStatusName === t('Referral_Costing.Revise_Costing_Initiated') && iseditDisabled === true) {
            setDisabled(false);
        }

        setdisableSave(false);

        setAmountChanged(newTotal);
    };


    useEffect(() => {
        if (props.costingData !== undefined && props.costingData !== '') {
            setValue('serviceUserName', props.costingData.serviceUserName || '');
            setValue('serviceUserId', props.costingData.serviceUserId || '');
            setValue('serviceLocation', props.costingData.serviceLocation || '');
            if (props.costingData?.rcId > 0) {
                setValue('accommodation', props.costingData.accommodation || '', { shouldValidate: props.costingData.accommodation > 0 ? true : false });
                setValue('baselineStaffCost', props.costingData.baselineStaffCost || '', { shouldValidate: props.costingData.baselineStaffCost > 0 ? true : false });
                setValue('enhancedStaffingCost', props.costingData?.enhancedStaffingCost || '', { shouldValidate: props.costingData.enhancedStaffingCost > 0 ? true : false });
                setValue('mdt', props.costingData.mdt || '', { shouldValidate: props.costingData.mdt > 0 ? true : false });
                setValue('transport', props.costingData.transport || '', { shouldValidate: props.costingData.transport > 0 ? true : false });
                setValue('managementAndAdministration', props.costingData.managementAndAdministration || '', { shouldValidate: props.costingData.managementAndAdministration > 0 ? true : false });
                setValue('total', props.costingData?.total?.toFixed(2) || '');
                set_total(props.costingData?.total);
                setline(props.costingData?.costingLists);
                setDisabled(true);
            } else {
                setDisabled(false);
            }

            const newValues = {
                ...values,
                rcId: props.costingData?.rcId || 0,
                loadPoolingId: props.costingData?.loadPoolingId,
                serviceUserName: props.costingData?.serviceUserName,
                serviceUserId: props.costingData?.serviceUserId,
                serviceLocation: props.costingData?.serviceLocation,
                accommodation: props.costingData?.accommodation || 0,
                baselineStaffCost: props.costingData?.baselineStaffCost || 0,
                enhancedStaffingCost: props.costingData?.enhancedStaffingCost || 0,
                mdt: props.costingData?.mdt || 0,
                transport: props.costingData?.transport || 0,
                managementAndAdministration: props.costingData?.managementAndAdministration || 0,
                total: props.costingData?.total || '',
                isRevisionStatus: false,
                isRevised: false
            };
            setadditionalvalues(props.costingData?.costingLists);
            set_values(newValues);

            setEditDisabled(!props.costingDetails?.canEdit || props.costingData?.leadEditable == false);
        } else {
            reset();
        }
    }, [props.costingData]);

    const saveUpdateCosting = () => {
        swal({
            title: '',
            text: `Are you sure you want to submit the costing for lead ${props.referralid} ? No further changes will be allowed once submitted.`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                props.saveCosting(values, getValues(`additionalfield`));
            } else {
                setdisableSave(false);
            }
        });
    };
    const no_Change = () => {
        swal({
            title: '',
            text: `Are you sure you want to re-submit the costing for lead ${props.referralid} without any change? `,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                setEditDisabled(true);
                const No_ChangeValues = {
                    ...values,
                    isRevisionStatus: true,
                    isRevised: false
                };
                props.saveCosting(No_ChangeValues);
            } else {
            }
        });
    };
    const edit_Costing = () => {
        setEditDisabled(true);
        const Edit_CostingValues = {
            ...values,
            isRevisionStatus: true,
            isRevised: true
        };
        set_values(Edit_CostingValues);
        setDisabled(false);
        setdatavalid(true);
        setdisableSave(true);
    };

    const handleClick = (event: any) => {
        setline(line);
        event.currentTarget.disabled = true;
        setdisableSave(true);
    };

    useEffect(() => {
        const newVal = line?.length;
        const oldVal = fields.length;

        // if(line.length === 0 && props.costingData !== undefined && props.costingData !== ''){
        //     if (props.costingData !== undefined && props.costingData !== '') {
        //         console.log('in 2');
        //         if (props.costingData?.rcId > 0) {
        //             setDisabled(true);
        //         }
        //         else{
        //             setDisabled(false);
        //         }

        //     } else {
        //         console.log('Ashish 1')
        //         setDisabled(false);
        //     }

        // }
        if (additionalField.length > 0) {
            if (newVal > oldVal) {
                if (line.length > 0) {
                    for (let i = 0; i < line.length; i++) {
                        for (let j = 0; j < additionalField.length; j++) {
                            if (Number(line[i].costingTypeId) === additionalField[j].id) {
                                additionalField[j].isActive = false;
                            }
                        }
                    }
                }

                for (let i = oldVal; i < newVal; i++) {
                    append(line[newVal - 1], { shouldFocus: false });
                    setValue(`additionalfield[${i}].costingTypeId`, line[i].costingTypeId, { shouldValidate: true });
                    setValue(`additionalfield[${i}].amount`, line[i].amount, { shouldValidate: true });
                    setValue(`additionalfield[${i}].costingTypeName`, line[i].costingTypeName, { shouldValidate: true });
                }
            } else if (newVal === oldVal) {
                let stotal: any = 0;
                for (let i = 0; i < line.length; i++) {
                    setValue(`additionalfield[${i}].costingTypeId`, line[i].costingTypeId, { shouldValidate: true });
                    setValue(`additionalfield[${i}].amount`, line[i].amount, { shouldValidate: true });
                    setValue(`additionalfield[${i}].costingTypeName`, line[i].costingTypeName, { shouldValidate: true });
                }
            }
        }
    }, [line]);

    const addRow = () => {
        if (line.length === 0) {
            setdisableSave(false);
            setline([
                ...line,
                {
                    costingTypeId: '',
                    amount: '',
                    costingTypeName: null
                }
            ]);
        } else {
            for (let i = 0; i < line.length; i++) {
                if (line[line.length - 1].costingTypeId !== '' && line[line.length - 1].amount !== '') {
                    trigger(`additionalfield.${i}.costingTypeId`);
                    setdisableSave(false);
                    setline([
                        ...line,
                        {
                            costingTypeId: '',
                            amount: '',
                            costingTypeName: null
                        }
                    ]);
                } else {
                }
            }
        }
    };

    const updateData = (e: any, index: number) => {
        let temporaryarray = line.slice();
        additionalField.filter((data: any) => {
            if (e.target.name === `additionalfield.${index}.costingTypeId`) {
                if (data.id === Number(e.target.value)) {
                    temporaryarray[index]['costingTypeId'] = Number(e.target.value);
                }
            } else if (e.target.name === `additionalfield.${index}.amount`) {
                if (e.target.value.includes('.')) {
                    var splitVal = e.target.value.split('.');
                    if (splitVal.length == 2 && splitVal[1].length > 2) {
                        // user entered invalid input
                        temporaryarray[index]['amount'] = splitVal[0] + '.' + splitVal[1].substr(0, 2);
                    } else {
                        temporaryarray[index]['amount'] = splitVal[0] + '.' + splitVal[1].substr(0, 2);
                    }
                }
                else {
                    temporaryarray[index]['amount'] = e.target.value;
                }
            }
        });
        if (temporaryarray[index]['costingTypeId'] !== '' && temporaryarray[index]['amount'] !== '') {
            setdisableSave(true);
        } else {
            setdisableSave(false);
        }
        setadditionalvalues(temporaryarray);
        setline(temporaryarray);
    };

    const removeline = (lineindex: any, data: any) => {
        swal({
            title: '',
            text: 'Are you sure you want to delete the field?',
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                for (let i = 0; i < additionalField.length; i++) {
                    if (data.costingTypeId === additionalField[i].id) {
                        additionalField[i].isActive = true;
                    }
                }
                const newSelectedValues = selectedValue.filter((a: any, index: any) => index !== lineindex);
                setSelectedValue(newSelectedValues);
                remove(lineindex);
                const deletedLines = line.filter((data: any, index: number) => index !== lineindex);
                setline(deletedLines);
                setadditionalvalues(deletedLines);
                setdisableSave(false);
                // let hasData = props.invoicedata?.invoiceModel?.invoiceLineModel.some((val: any) => val.productId === data.product);
                // if (props.invoicedata?.invoiceModel?.invoiceLineModel !== undefined && hasData && props.invoicedata?.invoiceModel?.invoiceLineModel?.length > 0) {
                //     props.deletedlines(props.invoicedata?.invoiceModel?.invoiceLineModel?.at(lineindex));
                // }
            } else {
            }
        });
    };

    useEffect(() => {
        if (props?.viewCosting?.costingLists) {
            const initialComments = props?.viewCosting?.costingLists.reduce((acc: any, list: any) => {
                acc[list.costingTypeId] = list.costingTypeName || '';
                return acc;
            }, {});
            // setCommentText(initialComments);
            setCommentText((prevCommentText) => ({
                ...prevCommentText,
                ...initialComments,
            }));
        }
    }, [props?.viewCosting?.costingLists]);

    useEffect(() => {
        let temporaryarray = line.slice();
        additionalField.forEach((data: any) => {
            const index = temporaryarray.findIndex((item: any) => item.costingTypeId === data.id);
            if (commentText[data.id]) {
                if (index === -1) {
                    temporaryarray.push({ costingTypeId: data.id, costingTypeName: commentText[data.id] });
                } else {
                    temporaryarray[index]['costingTypeName'] = commentText[data.id];
                }
            }
        });

        setadditionalvalues(temporaryarray);
    }, [commentText])

    useEffect(() => {
        setadditionalvalues(getValues(`additionalfield`))
    }, [getValues(`additionalfield`)]);

    const handleCommentStateChange = (payload: { id: any, value: string, i: any }) => {
        const comment = {
            ...commentText,
            [commentId]: payload.value !== '' ? payload.value : '',
        };
        setCommentText(comment);
        let temporaryarray = [...line];
        if (temporaryarray[costingTypeNameIndex]) {
            temporaryarray[costingTypeNameIndex] = {
                ...temporaryarray[costingTypeNameIndex],
                costingTypeName: payload.value,
            };
        } else {
            temporaryarray[costingTypeNameIndex] = {
                costingTypeId: commentId,
                costingTypeName: payload.value,
            };
        }
        setadditionalvalues(getValues(`additionalfield`))
        // setadditionalvalues(temporaryarray);
        setline(temporaryarray);
    };

    useEffect(() => {
        eventEmitter.on('commentStateChange', handleCommentStateChange);

        return () => {
            eventEmitter.off('commentStateChange', handleCommentStateChange);
        };
    });

    useEffect(() => {
        const handleupdateBtnDisable = (value: boolean | null) => {
            if (value !== null) {
                setDisabled(value)
            }
        };
        eventEmitter.on('updateBtnDisable', handleupdateBtnDisable);
        return () => {
            eventEmitter.off('updateBtnDisable', handleupdateBtnDisable);
        };
    });

    const commentLinkName = (id: any, i: any) => {
        const hasCostingTypeName = getValues(`additionalfield[${i}].costingTypeName`);
        if (!isDisabled) {
            if (!hasCostingTypeName) {
                return t('Referral_Costing.Add_Comment');
            } else {
                return t('Referral_Costing.Edit_Comment');
            }
        } else {
            return t('Referral_Costing.View_Comment');
        }
    };
    const handleAddComment = (e: any, id: any, i: any) => {
        e.stopPropagation();
        setShowAddCommentModal(true);
        setCommentId(id)
        setCostingTypeNameIndex(i)
    }

    const toggleComment = () => {
        setShowAddCommentModal(false);
    };

    return (
        <div>
            {(props.costingDetails?.costingStatusName.includes(t('Referral_Costing.Rejected')) ||
                props.costingDetails?.costingStatusName.includes(t('Referral_Costing.Accepted')) ||
                props.costingDetails?.costingStatusName.includes(t('Referral_Costing.Cancelled'))) && (
                    <div className="status-box mb-3">
                        <div className="d-flex justify-content-end mb-2">
                            <span
                                className={`referral-status ${props.costingDetails?.costingStatusName.includes(t('Referral_Costing.Rejected')) || props.costingDetails?.costingStatusName.includes(t('Referral_Costing.Cancelled'))
                                    ? 'red-status'
                                    : 'green-status'
                                    }`}
                            >
                                {props.costingDetails.costingStatusName}
                            </span>
                        </div>

                        <p className="mt-1">
                            <i>
                                {costingStatusDetails?.logMessage}
                                {(props.costingDetails?.costingStatusName.includes(t('Referral_Costing.Rejected')) ||
                                    props.costingDetails?.costingStatusName.includes(t('Referral_Costing.Cancelled'))) && (
                                        <i
                                            className="fa-solid fa-circle-info ms-1"
                                            data-tooltip-id={`${props.costingDetails?.costingStatusName.includes(t('Referral_Costing.Rejected')) ? 'my-tooltip-rejected' : 'my-tooltip-cancelled'}`}
                                            data-tooltip-content={`${costingStatusDetails?.reason} - ${costingStatusDetails?.comment}`}
                                        ></i>
                                    )}
                            </i>
                        </p>
                    </div>)
            }
            {props.costingDetails?.costingStatusName === t('Referral_Costing.Revise_Costing_Initiated') &&
                !props.costingDetails?.isAddedByRm &&
                <div className='status-box mb-3'>
                    <div className='d-flex justify-content-end mb-2'>
                        <span className='referral-status gray-status'>{t('SM_Costing.Revise_Costing_Request')}</span>
                    </div>
                    <p className="mt-1">
                        <i>{costingStatusDetails?.logMessage}</i>
                    </p>
                    <p className="bold mt-2">
                        {t('SM_Costing.Expected_Costing_Value')}{' '}
                        <span className="bolder">
                            £ {costingStatusDetails?.expectedCost}{' '}
                            <i
                                className="fa-solid fa-circle-info ms-1"
                                data-tooltip-id="my-tooltip-rejected"
                                data-tooltip-content={`${costingStatusDetails?.reason} - ${costingStatusDetails?.comment}`}
                            ></i>
                        </span>
                    </p>
                    <div className="text-center mt-2">
                        <button className="btn btn-primary btn-sm me-2" onClick={edit_Costing} disabled={iseditDisabled}>
                            {t('SM_Costing.Edit_Costing')}
                        </button>
                        <button className="btn btn-secondary btn-sm" onClick={no_Change} disabled={iseditDisabled}>
                            {t('SM_Costing.No_Change')}
                        </button>
                    </div>
                </div>
            }
            {(props.costingDetails?.costingStatusName.includes(t('Referral_Costing.Rejected')) ||
                props.costingDetails?.costingStatusName.includes(t('Referral_Costing.Accepted')) ||
                props.costingDetails?.costingStatusName.includes(t('Referral_Costing.Cancelled')) ||
                props.costingDetails?.costingStatusName === t('Referral_Costing.Revise_Costing_Initiated')) && <hr className="dash-style" />}
            <p className="bold mb-2">Comprehensive {props.costingData?.serviceTypeName} Costing</p>
            <input type="text" placeholder="Service User Name" disabled {...register('serviceUserName')} name={'serviceUserName' as const} className="form-control white mb-2 disabled" />
            <input type="text" placeholder="Service User ID" disabled {...register('serviceUserId')} name={'serviceUserId' as const} className="form-control white mb-2 disabled" />
            <textarea
                rows={2}
                disabled={isDisabled}
                style={{ pointerEvents: isDisabled ? 'none' : 'all' }}
                {...register('serviceLocation', {
                    onChange: (e: any) => {
                        props.updateData(e);
                    }
                })}
                className="form-control white mb-2"
                placeholder="Proposed Location"
            ></textarea>

            <div className="table-radius mt-3">
                <table className="table table-costing table-progress border-0 mb-0 ">
                    <tr>
                        <td width="65%">
                            <p className="purple-text bold p-2">
                                {' '}
                                {t('SM_Costing.Accommodation')}
                                <span className="ms-2" data-tooltip-id="my-tooltip-accomodation" data-tooltip-content="e.g., rent and rates, vehicle access, utilities, refuse, catering, and laundry">
                                    <i className="fa-solid fa-circle-info"></i>
                                </span>
                            </p>
                        </td>
                        <td width="35%">
                            <div className="d-flex align-items-center">
                                <span className="me-2">£ </span>
                                <input
                                    type="number"
                                    min="0"
                                    {...register('accommodation')}
                                    style={{ pointerEvents: isDisabled ? 'none' : 'all' }}
                                    disabled={isDisabled}
                                    name={'accommodation' as const}
                                    onInput={(e) => values_handler(e, 0)}
                                    className={`form-control white input-border mb-0 ${errors.accommodation ? 'is-invalid' : ''}`}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className="purple-text bold p-2">
                                {t('SM_Costing.Baseline_Staff_Cost')}
                                <span className="ms-2" data-tooltip-id="my-tooltip-baseline" data-tooltip-content="e.g., direct care staff and including domestic services, maintenance, driver, etc.">
                                    <i className="fa-solid fa-circle-info"></i>
                                </span>
                            </p>
                        </td>
                        <td>
                            <div className="d-flex align-items-center">
                                <span className="me-2">£ </span>
                                <input
                                    type="number"
                                    min="0"
                                    style={{ pointerEvents: isDisabled ? 'none' : 'all' }}
                                    disabled={isDisabled}
                                    {...register('baselineStaffCost')}
                                    name={'baselineStaffCost' as const}
                                    onInput={(e) => values_handler(e, 0)}
                                    className={`form-control white input-border mb-0 ${errors.baselineStaffCost ? 'is-invalid' : ''}`}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className="purple-text bold p-2">{t('SM_Costing.Enhanced_Staffing_Costs')}</p>
                        </td>
                        <td>
                            <div className="d-flex align-items-center">
                                <span className="me-2">£ </span>
                                <input
                                    type="number"
                                    min="0"
                                    style={{ pointerEvents: isDisabled ? 'none' : 'all' }}
                                    disabled={isDisabled}
                                    {...register('enhancedStaffingCost')}
                                    name={'enhancedStaffingCost' as const}
                                    onInput={(e) => values_handler(e, 0)}
                                    className={`form-control white input-border mb-0 ${errors.enhancedStaffingCost ? 'is-invalid' : ''}`}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className="purple-text bold p-2">
                                {t('SM_Costing.MDT')}
                                <span className="ms-2" data-tooltip-id="my-tooltip-MDT" data-tooltip-content="O.T, Psychology, Psychiatry">
                                    <i className="fa-solid fa-circle-info"></i>
                                </span>
                            </p>
                        </td>
                        <td>
                            <div className="d-flex align-items-center">
                                <span className="me-2">£ </span>
                                <input
                                    type="number"
                                    min="0"
                                    style={{ pointerEvents: isDisabled ? 'none' : 'all' }}
                                    disabled={isDisabled}
                                    {...register('mdt')}
                                    name={'mdt' as const}
                                    onInput={(e) => values_handler(e, 0)}
                                    className={`form-control white input-border mb-0 ${errors.mdt ? 'is-invalid' : ''}`}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className="purple-text bold p-2">
                                {t('SM_Costing.Transport')}
                                <span className="ms-2" data-tooltip-id="my-tooltip-transport" data-tooltip-content="Dedicated transport">
                                    <i className="fa-solid fa-circle-info"></i>
                                </span>
                            </p>
                        </td>
                        <td>
                            <div className="d-flex align-items-center">
                                <span className="me-2">£ </span>
                                <input
                                    type="number"
                                    min="0"
                                    style={{ pointerEvents: isDisabled ? 'none' : 'all' }}
                                    disabled={isDisabled}
                                    {...register('transport')}
                                    name={'transport' as const}
                                    onInput={(e) => values_handler(e, 0)}
                                    className={`form-control white input-border mb-0 ${errors.transport ? 'is-invalid' : ''}`}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className="purple-text bold pt-1 pb-1 ps-2 pe-2">
                                {t('SM_Costing.Management_Administration')}
                                <span className="ms-2" data-tooltip-id="my-tooltip-management" data-tooltip-content="Inclusive of training, professional fees, insurance, recruitment etc.">
                                    <i className="fa-solid fa-circle-info"></i>
                                </span>
                            </p>
                        </td>
                        <td>
                            <div className="d-flex align-items-center">
                                <span className="me-2">£ </span>
                                <input
                                    type="number"
                                    min="0"
                                    style={{ pointerEvents: isDisabled ? 'none' : 'all' }}
                                    disabled={isDisabled}
                                    {...register('managementAndAdministration')}
                                    name={'managementAndAdministration' as const}
                                    onInput={(e) => values_handler(e, 0)}
                                    className={`form-control white input-border mb-0 ${errors.managementAndAdministration ? 'is-invalid' : ''}`}
                                />
                            </div>
                        </td>
                    </tr>

                    {line?.length > 0 &&
                        fields?.map((data: any, i: number) => {
                            return (
                                <tr>
                                    <td>
                                        <div className='position-relative p-0'>
                                            <select
                                                {...register(`additionalfield.${i}.costingTypeId`, {
                                                    onChange: (e: any) => {
                                                        const newSelectedValues = [...selectedValue];
                                                        newSelectedValues[i] = e.target.value;
                                                        setSelectedValue(newSelectedValues);
                                                        updateData(e, i);
                                                    }
                                                })}
                                                name={`additionalfield.${i}.costingTypeId` as const}
                                                // disabled={data.action}
                                                id="product"
                                                style={{ pointerEvents: isDisabled ? 'none' : 'all' }}
                                                disabled={isDisabled}
                                                //@ts-ignore
                                                className={`form-control white ${errors.additionalfield?.[i]?.costingTypeId ? 'is-invalid' : ''}`}
                                            >
                                                <option disabled={true} value="">
                                                    Select Type
                                                </option>
                                                {additionalField !== undefined &&
                                                    additionalField.map((prod: any) => {
                                                        return (
                                                            <option key={prod.id} value={prod.id} disabled={prod.isActive === false ? true : false}>
                                                                {prod.value}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                            {isDisabled ? !(errors?.additionalfield as any)?.[i]?.costingTypeId?.message?.includes(t('Referral_Costing.Error_Check')) &&
                                                <a href="#" onClick={(e) => handleAddComment(e, data.costingTypeId, i)} className='add-comments-SM'>
                                                   {props?.costingLists?.find((item: any) => item?.costingTypeId === data.costingTypeId)?.costingTypeName && <i className="fa-solid fa-comment-dots" data-tooltip-id="my-tooltip-comment" data-tooltip-content={props?.costingLists?.find((item: any) => item?.costingTypeId === data.costingTypeId)?.costingTypeName ? "View Comment" : ""}></i>}
                                                    <Tooltip id="my-tooltip-comment" className="my-tooltip tooltip-custom-width" />
                                                </a> : !(errors?.additionalfield as any)?.[i]?.costingTypeId?.message?.includes(t('Referral_Costing.Error_Check')) &&
                                            <a href="#" onClick={(e) => handleAddComment(e, data.costingTypeId, i)} className='add-comments-SM'>
                                                <i className="fa-solid fa-comment-dots" data-tooltip-id="my-tooltip-comment" data-tooltip-content={commentLinkName(data.costingTypeId, i)}></i>
                                                <Tooltip id="my-tooltip-comment" className="my-tooltip tooltip-custom-width" />

                                            </a>}

                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="me-2">£ </span>
                                            <input
                                                type="number"
                                                min={0}
                                                //@ts-ignore
                                                className={`form-control white input-border mb-0 ${errors.additionalfield?.[i]?.amount ? 'is-invalid' : ''}`}
                                                style={{ pointerEvents: isDisabled ? 'none' : 'all' }}
                                                disabled={isDisabled}
                                                {...register(`additionalfield.${i}.amount`, {
                                                    onChange: (e: any) => {
                                                        updateData(e, i);
                                                    }
                                                })}
                                                // onKeyDown={(e: any) => {
                                                //     const cursorPosition = e.target.selectionStart;
                                                //     if (
                                                //         e.key === 'ArrowLeft' ||
                                                //         e.key === 'ArrowRight' ||
                                                //         e.key === 'Backspace' ||
                                                //         e.key === 'Delete' ||
                                                //         e.key === 'Tab' ||
                                                //         (e.key === 'a' && e.ctrlKey) // Ctrl+A
                                                //     ) {
                                                //         // Allow the default behavior for these keys
                                                //         return;
                                                //     } else {
                                                //         if (
                                                //             window.$utils.onKeypressValidateCosting(
                                                //                 'number',
                                                //                 e.target.value.substring(cursorPosition) + e.key + e.target.value.substring(0,cursorPosition)
                                                //             ) === false
                                                //         ) {
                                                //             e.preventDefault();
                                                //         }
                                                //     }
                                                // }}
                                                name={`additionalfield.${i}.amount`}
                                            />
                                        </div>
                                    </td>
                                    {isDisabled === false && (
                                        <>
                                            <td>
                                                <a onClick={(e: any) => removeline(i, data)} className="">
                                                    <i className="fa-solid fa-trash danger-text"></i>
                                                </a>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            );
                        })}
                    {(isDisabled === false && line.length !== additionalField.length) && (
                        <>
                            <tr>
                                <td className="p-0 border-bottom-0">
                                    <div>
                                        <button onClick={addRow} className="add_left_content">
                                            <i className="fa-solid fa-plus"></i>{' '}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </>
                    )}
                    <tr>
                        <td className="purple-background no-right-border">
                            <p className="white-text bold p-2">{t('SM_Costing.Total_per_week')}</p>
                        </td>
                        <td className="purple-background">
                            <div className="d-flex align-items-center">
                                <span className="me-2 white-text bold">£ </span>
                                <span className="white-text bold"> {total?.toFixed(2)}</span>
                            </div>
                        </td>
                    </tr>
                </table>

                <Tooltip id="my-tooltip-accomodation" className="my-tooltip tooltip-custom-width" />
                <Tooltip id="my-tooltip-baseline" className="my-tooltip tooltip-custom-width" />
                <Tooltip id="my-tooltip-MDT" className="my-tooltip tooltip-custom-width" />
                <Tooltip id="my-tooltip-transport" className="my-tooltip tooltip-custom-width" />
                <Tooltip id="my-tooltip-management" className="my-tooltip tooltip-custom-width" />
                <Tooltip id="my-tooltip-rejected" className="my-tooltip tooltip-custom-width" />
                <Tooltip id="my-tooltip-cancelled" className="my-tooltip" />
            </div>
            <div>
                <RenderModalContainer>
                    {showAddCommentModal && <AddYourCommentModalContainer
                        title={commentLinkName(commentId, costingTypeNameIndex)}
                        onClose={toggleComment}
                        costingLists={props?.costingLists}
                        ommentText={commentText}
                        isFromRM={false}
                        line={line}
                        isDisabled={isDisabled}
                        commentId={commentId}
                        costingTypeNameIndex={costingTypeNameIndex}
                        formAdditionalValues={getValues('additionalfield')}
                    />}
                </RenderModalContainer>
            </div>
            <div className="d-flex justify-content-center  right_content_btn">
                {!props.iseditable && (
                    <button
                        disabled={!datavalid || disableSave}
                        className="btn btn-primary"
                        onClick={(e) => {
                            saveUpdateCosting();
                            handleClick(e);
                        }}
                    >
                        {t('SM_Costing.Submit_btn')}
                    </button>
                )}
                {props.iseditable && (
                    <button
                        disabled={!datavalid || isDisabled || !props.costingDetails?.canEdit || disableSave}
                        className="btn btn-primary"
                        onClick={(e) => {
                            saveUpdateCosting();
                            handleClick(e);
                        }}
                    >
                        {t('SM_Costing.Update_btn')}
                    </button>
                )}
            </div>
        </div>
    );
};

export default CostingView;
