import React, { useEffect, useMemo, useState } from 'react';
import swal from 'sweetalert';
import eventEmitter from '../../../../utils/eventEmitter';
import { rightNavigationPage } from '../../../../utils/constant';
import { useDispatch } from 'react-redux';
import { setNavigationAction } from '../../../../store/dashboard/navigationReducer';
import { removeLeadInformationAction } from '../../../../store/lead/getLeaddataReducer';
import { getLeaddata } from '../../../../service/leads';
import { FilterType, PatientInquiryResponseType, PropsType } from '../types';
import { usePagination, DOTS } from '../../../../hooks/usePagination';
import { Tooltip } from 'react-tooltip';

const PatientInquiryTabView = (props: PropsType) => {
    const dispatch = useDispatch();

    const { currPage, filter, limit, patientInquiryData, patientInquiryError, setCurrPage, setFilter, setSkip, setTotalPages, skip, totalCount, totalPages, getLeadListData, setPatientInquiryData, search } =
        props;
    let currentPage = currPage;
    let siblingCount = 1;
    let pageSize = limit;
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    useEffect(() => {
        setTotalPages(Math.ceil(totalCount / limit));
    }, [totalCount]);

    useEffect(() => {
        getLeadListData(search);
    }, [filter, skip]);

    const paginationList = useMemo(() => {
        const ONE = 1;

        if (totalPages <= 3) {
            return Array.from({ length: totalPages }, (_, i) => i + ONE);
        }

        if (currPage > ONE && currPage < totalPages) {
            return [currPage - ONE, currPage, currPage + ONE];
        } else if (currPage === 1) {
            return [1, 2, 3];
        } else if (currPage === totalPages) {
            return [currPage - 2, currPage - 1, currPage];
        }
    }, [totalPages, currPage]);

    const handlePatientPooling = async (data: PatientInquiryResponseType) => {
        let obj: any = {
            ShowClass: true,
            FormName: rightNavigationPage.ServiceSidebar,
            Data: '',
            ShowToggle: false
        };
        dispatch(setNavigationAction(obj));
        dispatch(removeLeadInformationAction());
        let res;
        if (data.patientDetail.leadActivityStatusId === 2) {
            res = await getLeaddata(data.patientDetail.leadId, data.patientDetail.leadActivityStatusId - 1);
        } else {
            res = await getLeaddata(data.patientDetail.leadId, data.patientDetail.leadActivityStatusId);
        }
        let leadstage = {
            leadstatus: data.patientDetail.leadActivityStatusId,
            fleadstatus: data.patientDetail.leadActivityStatusId,
            servicetype: data.patientDetail.serviceTypeName,
            serviceid: data.patientDetail.serviceTypeId,
            leadid: data.patientDetail.leadId,
            patientname: data.patientDetail.patientName,
            referralId: data.patientDetail.referralID
        };
        if (res.status) {
            let obj: any = {
                ShowClass: true,
                FormName: rightNavigationPage.ServiceSidebar,
                Data: leadstage,
                ShowToggle: false
            };
            dispatch(setNavigationAction(obj));
        } else {
            swal(res?.message, {
                icon: 'error'
            });
        }

        eventEmitter.emit('eventOpenNHSContainerStateChanger', true);
        eventEmitter.emit('eventOpenLeadContainer', true);
        
        setTimeout(() => {
            eventEmitter.emit('eventOpenNHSContainer', data);
        }, 100);
    };

    const handleNextPage = () => {
        setCurrPage(currPage + 1);
        setSkip(skip + limit);
    };

    const handlePrevPage = () => {
        setSkip(Math.max(0, skip - limit));
        setCurrPage(currPage - 1);
    };

    const handlePageNumClick = (page: number) => {
        const skip = (page - 1) * limit;
        setCurrPage(page);
        setSkip(skip);
    };

    const handleNavClick = (filter: FilterType) => {
        setFilter(filter);
        setSkip(0);
        setCurrPage(1);
        setPatientInquiryData([]);
    };

    return (
        <div>
            <Tooltip className="service-name tooltip-custom-width1" id="service-name" />
            <div className="patient-tabs mt-3">
                <ul className="nav nav-tabs mb-3 d-flex justify-content-between" id="nav-tab" role="tablist">
                    <li className="nav-item">
                        <button
                            className="nav-link active"
                            onClick={() => handleNavClick({ leadOptionId: 1, leadOption: 'all' })}
                            id="nav-all-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-all"
                            type="button"
                            role="tab"
                            aria-controls="nav-all"
                            aria-selected="true"
                        >
                            All
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className="nav-link"
                            onClick={() => handleNavClick({ leadOptionId: 2, leadOption: 'pending' })}
                            id="nav-pending-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-pending"
                            type="button"
                            role="tab"
                            aria-controls="nav-pending"
                            aria-selected="false"
                        >
                            Pending
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className="nav-link"
                            onClick={() => handleNavClick({ leadOptionId: 3, leadOption: 'referred' })}
                            id="nav-referred-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-referred"
                            type="button"
                            role="tab"
                            aria-controls="nav-referred"
                            aria-selected="false"
                        >
                            Referred
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className="nav-link"
                            onClick={() => handleNavClick({ leadOptionId: 4, leadOption: 'approval' })}
                            id="nav-approval-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-approval"
                            type="button"
                            role="tab"
                            aria-controls="nav-approval"
                            aria-selected="false"
                        >
                            Approval
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className="nav-link"
                            onClick={() => handleNavClick({ leadOptionId: 5, leadOption: 'completed' })}
                            id="nav-completed-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-completed"
                            type="button"
                            role="tab"
                            aria-controls="nav-completed"
                            aria-selected="false"
                        >
                            Completed
                        </button>
                    </li>
                </ul>
                <div className="tab-content p-3 border bg-light custom-tab-content" id="nav-tabContent">
                    {!patientInquiryError ? (
                        patientInquiryData?.length > 0 ? (
                            patientInquiryData?.map((ptData, i) => (
                                <div
                                    key={i}
                                    className="mb-3"
                                    id={`nav-${filter.leadOption}`}
                                    aria-labelledby={`nav-${filter.leadOption}-tab`}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handlePatientPooling(ptData)}
                                >
                                    <div className="status-box patient-status-box">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <p className="bold patient-box-title">{ptData.patientDetail.patientName}</p>
                                            <span className="referral-status green-status">{filter.leadOption === 'pending' ? 'Pending' : ptData.patientDetail.currentStage}</span>
                                        </div>
                                        <p className="patient-inquiry-length">
                                            {filter.leadOption === 'pending' ? (
                                                <p>Length Of Inquiry - {ptData.patientDetail.length_of_inquiry}</p>
                                            ) : ptData.patientDetail.serviceCodeDetails && ptData.patientDetail.serviceCodeDetails?.length > 0 ? (
                                                <div className="d-flex flex-wrap align-items-center">
                                                    {ptData.patientDetail.serviceCodeDetails.map((sc, i) => (
                                                        <div className="me-2 mt-2 patient-inquiry-serviceCode" key={i} data-tooltip-id="service-name"
                                                        data-tooltip-content={sc.name}>
                                                            {sc.code.substring(0,3)}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : null}
                                        </p>
                                    </div>
                                </div>
                            ))
                        ) : !props.isLoading ? (
                            <div className="mt-1" id={`nav-${filter.leadOption}`} aria-labelledby={`nav-${filter.leadOption}-tab`}>
                                <div className="no-record">
                                    <p>No records Found !</p>
                                </div>
                            </div>
                        ) : (
                            <div className="mt-1" id={`nav-${filter.leadOption}`} aria-labelledby={`nav-${filter.leadOption}-tab`}>
                                <div className="no-record">
                                    <p>Loading...</p>
                                </div>
                            </div>
                        )
                    ) : (
                        <div className="no-record">
                            <p>{patientInquiryError}</p>
                        </div>
                    )}
                </div>
            </div>

            <div className="d-flex justify-content-center align-items-center right_content_btn">
                <div>
                    {patientInquiryData?.length > 0 ? (
                        <nav aria-label="Page navigation example" className="pagination-list">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <button className={`page-link ${skip === 0 ? 'page-link-disabled' : ''}`} disabled={skip === 0} onClick={() => handlePageNumClick(1)} aria-label="Previous">
                                        <i className="fa-solid fa-angles-left"></i>
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button className={`page-link ${skip === 0 ? 'page-link-disabled' : ''}`} disabled={skip === 0} onClick={handlePrevPage} aria-label="Previous">
                                        {/* <span aria-hidden="true">&laquo;</span> */}
                                        <i className="fa-solid fa-angle-left"></i>
                                    </button>
                                </li>

                                {paginationRange?.map((page: any, i) => {
                                    if (page === DOTS) {
                                        return (
                                            <li className="page-item" key={page}>
                                                <span className="page-link disabled">&#8230;</span>
                                            </li>
                                        );
                                    }
                                    return (
                                        <li className={`page-item ${currPage === page ? 'active' : ''}`} onClick={() => handlePageNumClick(page)} key={i}>
                                            <span className="page-link">{page}</span>
                                        </li>
                                    );
                                })}

                                <li className="page-item">
                                    <button
                                        className={`page-link ${currPage === totalPages ? 'page-link-disabled' : ''}`}
                                        disabled={currPage === totalPages ? true : false}
                                        onClick={handleNextPage}
                                        aria-label="Next"
                                    >
                                        {/* <span aria-hidden="true">&raquo;</span> */}
                                        <i className="fa-solid fa-angle-right"></i>
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button
                                        className={`page-link ${currPage === totalPages ? 'page-link-disabled' : ''}`}
                                        disabled={currPage === totalPages ? true : false}
                                        onClick={() => handlePageNumClick(totalPages)}
                                        aria-label="Next"
                                    >
                                        <i className="fa-solid fa-angles-right"></i>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default PatientInquiryTabView;
