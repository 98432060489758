import React from 'react'
import OnboardingHeaderView from './OnboardingHeaderView'

interface OnboardingHeaderProps {
  manageProfileValidationType: "none" | "onboarding" | "edit";
}

const OnboardingHeaderContainer = ({manageProfileValidationType}: OnboardingHeaderProps) => {
  return (
    <OnboardingHeaderView manageProfileValidationType={manageProfileValidationType}/>
  )
}

export default OnboardingHeaderContainer