import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

const DownloadReferralView = (props: any) => {
    const { t } = useTranslation();
    return (
        <div data-tooltip-id="my-tooltip" data-tooltip-content={`Download`}>
            <a href="#" onClick={props.onClickHandlerDownloadReferral} className='btn btn-white bold me-2'><i className="fa-sharp fa-solid fa-download"></i></a>
            <Tooltip id="my-tooltip" className="my-tooltip" />
        </div>
        
    );
};

export default DownloadReferralView;