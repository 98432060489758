import React, { useEffect, useMemo, useState, useRef, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import {
    MaterialReactTable,
    MRT_TableInstance,
    MRT_FullScreenToggleButton,
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_TablePagination,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToolbarAlertBanner,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_VisibilityState,
    type MRT_RowSelectionState,
    type MRT_GroupingState,
    MRT_Localization
} from 'material-react-table';
import { Box } from '@mui/material';
import PaginationContainer from '../../../components/PaginationComponent/indexContainer';
import SmartSearchDropdownContainer from '../../../components/SmartSearchDropdownComponent/SmartSearchDropdownContainer';
import { Tooltip } from 'react-tooltip';
import ExportIntegrationContainer from '../../IntegrationInterface/IntegrationBottom/ExportIntegration/ExportIntegrationContainer';
import RenderModalContainer from '../../../components/RenderModal/indexContainer';
import ExportReportsModalContainer from '../../../components/ExportReportsModal/ExportReportsModalContainer';
import ExportReportsContainer from '../ExportReports/ExportReportsContainer';

export enum Source {
    Integration = 'integration',
    Admission = 'admission',
    Referral = 'referral'
}

const PatientTransactionHistoryReportsView = (props: any) => {
    const { t } = useTranslation();
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            
            {
                accessorKey: 'Organization', //access nested data with dot notation
                header: 'Organization',
                size: 100
            },
            {
                accessorKey: 'ServiceType',
                header: 'Service Type',
                size: 100
            },
            {
                accessorKey: 'ServiceName',
                header: 'Service Name',
                size: 100
            },
            {
                accessorKey: 'WardName',
                header: 'Ward Name | Bed Name',
                size: 200
            },
            {
                accessorKey: 'nhsId',
                header: 'NHS ID',
                size: 100,
                Cell: ({ cell }) => (
                    <span>
                        {cell.getValue()}
                    </span>
                )
            },
            {
                accessorKey: 'patientName',
                header: 'Patient Name',
                size: 100
            },
            {
                accessorKey: 'patientType',
                header: 'Patient Type',
                size: 100
            },
            {
                accessorKey: 'TransactionType', //normal accessorKey
                header: 'Transaction Type',
                size: 100
            },
            {
                accessorKey: 'TransactionDate', //normal accessorKey
                header: 'Transaction Date',
                size: 100
            },
            {
                accessorKey: 'FunderName', //normal accessorKey
                header: 'Funder Name',
                size: 100
            },
            {
                accessorKey: 'FunderContactNo', //normal accessorKey
                header: 'Funder Contact No',
                size: 100
            },
            {
                accessorKey: 'FunderEmail', //normal accessorKey
                header: 'Funder Email',
                size: 100
            },
            {
                accessorKey: 'TotalWeeklyFees', //normal accessorKey
                header: 'Total Weekly Fees',
                size: 100
            },
            {
                accessorKey: 'PercentageChange', //normal accessorKey
                header: '% Change',
                size: 100,
                Cell: ({ cell }) => <span className="bolder table-green-color">{cell.getValue()}</span>
            },
            {
                accessorKey: 'PlanName',
                header: 'Plan Name',
                size: 100
            },
            {
                accessorKey: 'PlanStartDate',
                header: 'Plan Start Date',
                size: 100
            },
            {
                accessorKey: 'PlanEndDate',
                header: 'Plan End Date',
                size: 100
            },
            {
                accessorKey: 'WeeklyShareValue',
                header: 'Weekly Share Value',
                size: 100
            },
        ],
        []
    );
    const Data = [
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A | 23',
            nhsId: '8676777777',
            patientName: 'Robert Little',
            patientType: 'Respite',
            TransactionType: 'Active Admission',
            TransactionDate: '20/01/2023',
            FunderName:'Funder 1',
            FunderContactNo:'0123456789',
            FunderEmail:'xyz@gmail.com',
            AdmissionDate: '20/01/2023',
            TotalWeeklyFees: '427.00',
            PercentageChange: '100',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            WeeklyShareValue: '427'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A | 23',
            nhsId: '8676777777',
            patientName: 'Robert Little',
            patientType: 'Respite',
            TransactionType: 'Reservation',
            TransactionDate: '20/01/2023',
            FunderName:'Funder 1',
            FunderContactNo:'0123456789',
            FunderEmail:'xyz@gmail.com',
            AdmissionDate: '20/01/2023',
            TotalWeeklyFees: '427.00',
            PercentageChange: '100',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            WeeklyShareValue: '427'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A | 23',
            nhsId: '8676777777',
            patientName: 'Robert Little',
            patientType: 'Respite',
            TransactionType: 'Reservation',
            TransactionDate: '20/01/2023',
            FunderName:'Funder 1',
            FunderContactNo:'0123456789',
            FunderEmail:'xyz@gmail.com',
            AdmissionDate: '20/01/2023',
            TotalWeeklyFees: '427.00',
            PercentageChange: '100',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            WeeklyShareValue: '427'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A | 23',
            nhsId: '8676777777',
            patientName: 'Robert Little',
            patientType: 'Respite',
            TransactionType: 'Discharge',
            TransactionDate: '20/01/2023',
            FunderName:'Funder 1',
            FunderContactNo:'0123456789',
            FunderEmail:'xyz@gmail.com',
            AdmissionDate: '20/01/2023',
            TotalWeeklyFees: '427.00',
            PercentageChange: '100',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            WeeklyShareValue: '427'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A | 23',
            nhsId: '8676777777',
            patientName: 'Robert Little',
            patientType: 'Respite',
            TransactionType: 'Transfer',
            TransactionDate: '20/01/2023',
            FunderName:'Funder 1',
            FunderContactNo:'0123456789',
            FunderEmail:'xyz@gmail.com',
            AdmissionDate: '20/01/2023',
            TotalWeeklyFees: '427.00',
            PercentageChange: '100',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            WeeklyShareValue: '427'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A | 23',
            nhsId: '8676777777',
            patientName: 'Robert Little',
            patientType: 'Respite',
            TransactionType: 'Transfer',
            TransactionDate: '20/01/2023',
            FunderName:'Funder 1',
            FunderContactNo:'0123456789',
            FunderEmail:'xyz@gmail.com',
            AdmissionDate: '20/01/2023',
            TotalWeeklyFees: '427.00',
            PercentageChange: '100',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            WeeklyShareValue: '427'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A | 23',
            nhsId: '8676777777',
            patientName: 'Robert Little',
            patientType: 'Respite',
            TransactionType: 'Discharge',
            TransactionDate: '20/01/2023',
            FunderName:'Funder 1',
            FunderContactNo:'0123456789',
            FunderEmail:'xyz@gmail.com',
            AdmissionDate: '20/01/2023',
            TotalWeeklyFees: '427.00',
            PercentageChange: '100',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            WeeklyShareValue: '427'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A | 23',
            nhsId: '8676777777',
            patientName: 'Robert Little',
            patientType: 'Respite',
            TransactionType: 'Discharge',
            TransactionDate: '20/01/2023',
            FunderName:'Funder 1',
            FunderContactNo:'0123456789',
            FunderEmail:'xyz@gmail.com',
            AdmissionDate: '20/01/2023',
            TotalWeeklyFees: '427.00',
            PercentageChange: '100',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            WeeklyShareValue: '427'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A | 23',
            nhsId: '8676777777',
            patientName: 'Robert Little',
            patientType: 'Respite',
            TransactionType: 'Reservation',
            TransactionDate: '20/01/2023',
            FunderName:'Funder 1',
            FunderContactNo:'0123456789',
            FunderEmail:'xyz@gmail.com',
            AdmissionDate: '20/01/2023',
            TotalWeeklyFees: '427.00',
            PercentageChange: '100',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            WeeklyShareValue: '427'
        },
        {
            Organization: 'Valorum Care',
            ServiceType: 'Hospital',
            ServiceName: 'Mangna House',
            WardName: 'Ward A | 23',
            nhsId: '8676777777',
            patientName: 'Robert Little',
            patientType: 'Respite',
            TransactionType: 'Active Admission',
            TransactionDate: '20/01/2023',
            FunderName:'Funder 1',
            FunderContactNo:'0123456789',
            FunderEmail:'xyz@gmail.com',
            AdmissionDate: '20/01/2023',
            TotalWeeklyFees: '427.00',
            PercentageChange: '100',
            PlanName: 'Plan 1',
            PlanStartDate: '20/01/2023',
            PlanEndDate: '20/01/2023',
            WeeklyShareValue: '427'
        }
    ];
    let [showModel, setShowModel] = useState<boolean>(false);

    const handleClickExport = () => {
        setShowModel(true);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row p-0">
                    <div className="col-xl-12 col-lg-12 mb-4">
                        <div className="box-shadow p-0">
                            <div>
                                <div className="administrator-header ">
                                    <div className="row align-items-center justify-content-between h-100">
                                        <div className="col-md-6">
                                            <p className="bold lead-text purple-text">Patient Transaction History Report</p>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <a href="/reports">
                                                <button className="btn btn-white btn-sm">
                                                    <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="administrator-content">
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-4">
                                            <label className="bold mb-2">Transaction Type*:</label>
                                            {/* <select className="form-control white">
                                                <option><input type="check" />All</option>
                                                <option>Active Admissions</option>
                                                <option>Reservation</option>
                                                <option>Discharge</option>
                                                <option>Transfer</option>
                                            </select>  */}
                                            {/* Dropdown with multiselect box needs to be use here / react-dropdown-tree-select this needs to implement*/}
                                            {/* <div className="dropdown filter-dropdown me-2">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <div className="">
                                                        Transaction Type
                                                    </div>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-columns multiselectDropdown" aria-labelledby="navbarDropdownMenuLink">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="transactionType" 
                                                                    className="me-1" /> All
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="transactionType" 
                                                                    className="me-1" /> Active Admissions
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="transactionType" 
                                                                    className="me-1" /> Reservation
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="transactionType" 
                                                                    className="me-1" /> Discharge
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox" name="transactionType" 
                                                                    className="me-1" /> Transfer
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>  */}
                                            <div className="auto-complete-dropdown auto-complete-dropdown-cus mb-0">
                                                <SmartSearchDropdownContainer
                                                        smartSearchLabel={'Active Admissions'}
                                                            defaultValue={'Helllo'}
                                                            onChangeSmartSearch={(value: any) => {
                                                                const event = new Event('change');
                                                                let obj = {
                                                                    name: 'messageCode',
                                                                    value: value?.id
                                                                };
                                                            }}
                                                            smartSearch={'Role'}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="col-xl-4 col-lg-4">
                                            <div className="d-flex mb-3 flex-column">
                                                    <label className="bold mb-2">Service</label>
                                                    <div className="auto-complete-dropdown auto-complete-dropdown-cus mb-0">
                                                        <SmartSearchDropdownContainer
                                                            smartSearchLabel={'Select Service'}
                                                            defaultValue={'Helllo'}
                                                            onChangeSmartSearch={(value: any) => {
                                                                const event = new Event('change');
                                                                let obj = {
                                                                    name: 'messageCode',
                                                                    value: value?.id
                                                                };
                                                            }}
                                                            smartSearch={'Role'}
                                                        />
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4">
                                            <div className="d-flex mb-3 flex-column">
                                                <label className="bold mb-2">NHS ID/Local ID/Patient Name</label>
                                                <select className="form-control white">
                                                    <option>Select NHS ID/Local ID/Patient Name</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4">
                                            <div className="d-flex mb-3 flex-column">
                                                <label className="bold mb-2">Date Range*</label>
                                                <input type="text" className="form-control white " placeholder="Select Date Range" />
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-center">
                                            <button className="btn btn-secondary me-2">Clear</button>
                                            <button className="btn btn-primary me-2">Generate Report</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12 col-lg-12">
                        <div className="box-shadow p-0">
                            <div className="pt-3">
                                <div className="row pe-2 ps-2 d-flex justify-content-end">
                                    <div className="col-md-4 mb-3 d-flex justify-content-end">
                                        <div className="dropdown filter-dropdown me-2">
                                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="">
                                                    <i className="fa fa-cog"></i> {t('TableManageColumns.Manage_Columns')}
                                                </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-columns report-dropdownmenu" aria-labelledby="navbarDropdownMenuLink">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="NHS_Id" className="me-1" /> Client ID
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="Admission_date" className="me-1" /> Transaction Done By
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="Est_Discharge_Date" className="me-1" /> Trx Performed On
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="Period_From" className="me-1" /> Length of Trx Type
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="Period_To" className="me-1" /> Est. Discharge Date
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="Due_Date" className="me-1" /> Est. Admission Date
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="Payment_Reference" className="me-1" /> Transferred To Ward Name | Bed Name
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="Product_Code" className="me-1" /> Patient DOB
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="Quantity" className="me-1" /> Gender
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="PaymentStatus" className="me-1" /> Patient Address
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="PaymentStatus" className="me-1" /> Patient Contact No
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="PaymentStatus" className="me-1" /> Patient Email
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="PaymentStatus" className="me-1" /> Funder Type
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="PaymentStatus" className="me-1" /> Funder Address
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="PaymentStatus" className="me-1" /> Commissioner Name
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="PaymentStatus" className="me-1" /> Commissioner Contact No
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="PaymentStatus" className="me-1" /> Commissioner Email
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input type="checkbox" name="PaymentStatus" className="me-1" /> Commissioner Address
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="d-flex justify-content-end">
                                                            <label className='purple-text bold'><i className="fa fa-undo me-1" aria-hidden="true"></i> Reset</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="dropdown filter-dropdown me-2">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div className="" onClick={() => props.onExportClick()}>
                                                <i className="fa-solid fa-file-export"></i> Export
                                            </div> 
                                        </a>
                                    </div> */}
                                        <ExportReportsContainer onExportClick={handleClickExport} />
                                    </div>
                                </div>
                                <div className="invoice-table reports-table">
                                    <MaterialReactTable
                                        columns={columns}
                                        data={Data}
                                        enableGrouping
                                        enableColumnActions={false}
                                        enableBottomToolbar={false}
                                        enableTopToolbar={false}
                                        enableColumnDragging={false}
                                        // getRowId={(row) => row.integrationId}
                                        manualPagination
                                        manualSorting
                                        // rowCount={rowCount}
                                        //localization={{ actions: 'Actions' }}
                                        // enableRowActions
                                        // positionActionsColumn="last"
                                        // renderRowActions={({
                                        //     row
                                        // }) => <div className='d-flex'>
                                        //         <a className="status-icon justify-content-center">
                                        //             <i className="fa-solid fa-trash danger-text"></i>
                                        //         </a>
                                        //     </div>
                                        // }
                                    />
                                </div>
                                <div className="administrator-content">
                                    <div className="row align-items-center mt-3 invoice-table-pagination">
                                        <div className="col-lg-6">
                                            <div className="d-flex align-items-center">
                                                <p className="purple-text bold">Displaying 1 - 2 of 10</p>
                                                <div className="d-flex align-items-center ms-3">
                                                    <label className="mb-0 bold nowrap me-2">Page Size:</label>
                                                    <select className={`form-control white page-select`} id="supportedPersoIntegrationPageSize">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 ">
                                            <PaginationContainer currentPage={1} totalCount={100} pageSize={10} onPageChange={(page: any) => {}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    <RenderModalContainer>
                        {showModel && <ExportReportsModalContainer title="Export Report Details" onClose={() => setShowModel(false)}></ExportReportsModalContainer>}
                    </RenderModalContainer>
                }
            </div>
        </>
    );
};
export default PatientTransactionHistoryReportsView;
