import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ModalWrapperContainer from '../ModalWrapper/indexContainer';
import eventEmitter from '../../utils/eventEmitter';

interface ModalProps {
    onClose: () => any;
    title: string;
    mode?: any;
    costingLists: any;
    commentText: string;
    isFromRM: boolean;
    line: any;
    isDisabled: any;
    commentId: any;
    costingTypeNameIndex: any;
    formAdditionalValues: any;
}

const AddYourCommentModalView = (props: ModalProps) => {
    const [comment, setComment] = useState<string>('');

    useEffect(() => {
        setComment(props?.formAdditionalValues.find((item: any, index: any) => index === props?.costingTypeNameIndex)?.costingTypeName);
    }, [props?.formAdditionalValues]);

    const handleComment = (e: any) => {
        e.stopPropagation();
        const nameValue = e.target.value;
        setComment(nameValue);
    };

    const onSaveComment = () => {
        eventEmitter.emit('commentStateChange', { id: props?.commentId, value: comment, index: props.costingTypeNameIndex });
        eventEmitter.emit('updateBtnDisable', false);
        props.onClose();
    };

    const onModalClose = () => {
        props.onClose();
        eventEmitter.emit('updateBtnDisable', null);
        const hasComment = props?.formAdditionalValues.find((item: any, index: any) => index === props?.costingTypeNameIndex)?.costingTypeName;
        if (!hasComment) {
            eventEmitter.emit('commentStateChange', { id: props?.commentId, value: '', index: props.costingTypeNameIndex });
        }
    };

    const isSaveButtonDisabled = () => {
        const hasComment = props?.formAdditionalValues.find((item: any, index: any) => index === props?.costingTypeNameIndex)?.costingTypeName;
        if (props?.isFromRM) {
            if (props?.mode === 'edit' && !hasComment && !comment) {
                return true;
            } else if (props?.mode !== 'edit') {
                return true;
            } else {
                return false;
            }
        } else {
            if (!props?.isDisabled && !hasComment && !comment) {
                return true;
            } else if (props?.isDisabled) {
                return true;
            } else {
                return false;
            }
        }
    };
    const handleClear = () => {
        setComment('');
    };
    return (
        <div>
            <ModalWrapperContainer
                title={props.title}
                onClose={onModalClose}
                className="modal w-300"
                content={
                    <div>
                        <div className="row">
                            <div>
                                {props?.isFromRM ? (
                                    <textarea
                                        className="form-control white mb-2 overview-input"
                                        placeholder="Description"
                                        value={comment}
                                        onChange={(e) => handleComment(e)}
                                        disabled={props?.mode !== 'edit'}
                                        style={{ resize: 'none', overflowY: 'auto' }}
                                    />
                                ) : (
                                    <textarea
                                        className="form-control white mb-2 overview-input"
                                        placeholder="Description"
                                        value={comment}
                                        onChange={(e) => handleComment(e)}
                                        disabled={props?.isDisabled}
                                        style={{ resize: 'none', overflowY: 'auto' }}
                                    />
                                )}
                            </div>
                        </div>
                        {!props?.isDisabled && !props?.isFromRM && (
                            <div className="d-flex justify-content-center">
                                <div>
                                    <button className="btn btn-primary me-2" disabled={isSaveButtonDisabled()} onClick={onSaveComment}>
                                        Save
                                    </button>
                                    <button onClick={handleClear} className="btn btn-secondary">
                                        Clear
                                    </button>
                                </div>
                            </div>
                        )}
                        {props?.isFromRM && props?.mode === 'edit' &&  (
                            <div className="d-flex justify-content-center">
                                <div>
                                    <button className="btn btn-primary me-2" disabled={isSaveButtonDisabled()} onClick={onSaveComment}>
                                        Save
                                    </button>
                                    <button onClick={handleClear} className="btn btn-secondary">
                                        Clear
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                }
            />
        </div>
    );
};

export default AddYourCommentModalView;
