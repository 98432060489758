import React, { useEffect, useMemo, useState, useRef, useReducer } from 'react';
import {
    MaterialReactTable,
    MRT_TableInstance,
    MRT_FullScreenToggleButton,
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_TablePagination,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToolbarAlertBanner,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
    type MRT_VisibilityState,
    type MRT_RowSelectionState,
    type MRT_GroupingState,
    MRT_Localization
} from 'material-react-table';
import { Box } from '@mui/material';
import PaginationContainer from '../../../components/PaginationComponent/indexContainer';
import { useSelector, useDispatch } from 'react-redux';
import AddLeadButtonContainer from '../../ReferralManagementPage/ReferralManager/ReferralTable/AddLeadButton/AddLeadButtonContainer';
import TableFilterContainer from '../../ReferralManagementPage/ReferralManager/ReferralTable/TableFilter/TableFilterContainer';
import TableGroupByContainer from '../../ReferralManagementPage/ReferralManager/ReferralTable/TableGroupBy/TableGroupByContainer';
import TableSearchContainer from '../../ReferralManagementPage/ReferralManager/ReferralTable/TableSearch/TableSearchContainer';

const FunderManagementBottomView = () => {
    const [showToolbar, setShowToolbar] = useState<any>(false);
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'name', //access nested data with dot notation
                header: 'Name',
                size: 100
            },
            {
                accessorKey: 'type',
                header: 'Type',
                size: 100
            },
            {
                accessorKey: 'email', //normal accessorKey
                header: 'Email',
                size: 100
            },
            {
                accessorKey: 'contactnumber',
                header: 'Contact Number',
                size: 100
            },
            {
                accessorKey: 'service_name',
                header: 'Service Name | Bill Frequency',
                size: 220,
                Cell: ({ cell, row }) => {
                    const value = cell.getValue();

                    return (
                        <div className="verification-cell">
                            {value !== "Pending" && value !== "In Review" ? (
                                <>
                                    <div className="d-flex">
                                        <span>{value}</span>
                                        <div className="infoIcon ms-1 cursor-pointer" data-tooltip-id="my-tooltip" data-tooltip-content="info content" data-tooltip-place="top">
                                            <i className="fa-solid fa-circle-info"></i>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <span>{value}</span>
                            )}
                        </div>
                    );
                }
            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 50
            },
            {
                accessorKey: 'created_by',
                header: 'Created By',
                size: 100
            }
        ],
        []
    );
    const Data = [
        {
            id: 2,
            name: 'Tom Clancy',
            type: 'ABC',
            email: 'T@gmail.com',
            contactnumber: '9090909090',
            service_name: 'View Services',
            status: 'Active',
            created_by: 'Dhruti Bhatt'
        },
        {
            id: 3,
            name: 'Tom Clancy',
            type: 'ABC',
            email: 'T@gmail.com',
            contactnumber: '9090909090',
            service_name: 'View Services',
            status: 'Active',
            created_by: 'Dhruti Bhatt'
        },
        {
            id: 4,
            name: 'Tom Clancy',
            type: 'ABC',
            email: 'T@gmail.com',
            contactnumber: '9090909090',
            service_name: 'View Services',
            status: 'Active',
            created_by: 'Dhruti Bhatt'
        },
        {
            id: 5,
            name: 'Tom Clancy',
            type: 'ABC',
            email: 'T@gmail.com',
            contactnumber: '9090909090',
            service_name: 'View Services',
            status: 'Active',
            created_by: 'Dhruti Bhatt'
        },
        {
            id: 6,
            name: 'Tom Clancy',
            type: 'ABC',
            email: 'T@gmail.com',
            contactnumber: '9090909090',
            service_name: 'View Services',
            status: 'Active',
            created_by: 'Dhruti Bhatt'
        },
        {
            id: 7,
            name: 'Tom Clancy',
            type: 'ABC',
            email: 'T@gmail.com',
            contactnumber: '9090909090',
            service_name: 'View Services',
            status: 'Active',
            created_by: 'Dhruti Bhatt'
        },
        {
            id: 8,
            name: 'Tom Clancy',
            type: 'ABC',
            email: 'T@gmail.com',
            contactnumber: '9090909090',
            service_name: 'View Services',
            status: 'Active',
            created_by: 'Dhruti Bhatt'
        },
        {
            id: 9,
            name: 'Tom Clancy',
            type: 'ABC',
            email: 'T@gmail.com',
            contactnumber: '9090909090',
            service_name: 'View Services',
            status: 'Active',
            created_by: 'Dhruti Bhatt'
        },
        {
            id: 10,
            name: 'Tom Clancy',
            type: 'ABC',
            email: 'T@gmail.com',
            contactnumber: '9090909090',
            service_name: 'View Services',
            status: 'Active',
            created_by: 'Dhruti Bhatt'
        }
    ];
    return (
        <div>
            <div className="service-title text-center">Funder Management</div>
            <div className="box-shadow new-box-shadow">
                <div className="row">
                    <div className="col-xl-9 col-lg-12">
                        <div className="d-flex flex-wrap">
                            <a href="create-funder">
                                <button className="btn btn-primary btn-create me-2 mb-3">
                                    <i className="fa-solid fa-plus"></i> Add Funder
                                </button>
                            </a>
                            <div className="me-2 position-relative">
                                <input type="text" placeholder="Search" name="leadSmartSearch" className="form-control white search-invoice" />
                                <i className="fa-sharp fa-solid fa-magnifying-glass search-icon"></i>
                            </div>
                            <div className="dropdown filter-dropdown me-2">
                                <a className={`nav-link dropdown-toggle`} href="#" id="navbarDropdownMenuLink5" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div className="">
                                        <i className="fa-solid fa-filter"></i> Filter
                                    </div>
                                </a>
                                <div className="dropdown-menu profile-dw-css" aria-labelledby="navbarDropdownMenuLink">
                                    <div>
                                        <label>
                                            <input type="checkbox" name="open" className="me-1" /> ICB

                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <input type="checkbox" name="closed" className="me-1" />LA
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <input type="checkbox" name="closed" className="me-1" />Charity
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <input type="checkbox" name="closed" className="me-1" />Self
                                        </label>
                                    </div>                               
                                    <div className="border-bottom mt-1 mb-1"></div>
                                    <div>
                                        <label>
                                            <input type="checkbox" name="closed" className="me-1" />Active
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            <input type="checkbox" name="closed" className="me-1" />Inactive
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="me-2 organization-dropdown">
                                <div>
                                    <div id="rdts2" className="react-dropdown-tree-select">
                                        <div className="dropdown">
                                            <a className="dropdown-trigger arrow bottom" id="rdts2_trigger" role="button" aria-haspopup="tree" aria-expanded="false">
                                                <ul className="tag-list">
                                                    <li className="tag-item">
                                                        <span className="placeholder">Select Services</span>
                                                    </li>
                                                </ul>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="me-2">
                                <a href="#" className="btn btn-white bold " data-tooltip-id="my-tooltip-export" data-tooltip-content="Export Referrals">
                                    <i className="fa-sharp fa-solid fa-download"></i> Export
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="invoice-table">
                            <MaterialReactTable
                                columns={columns}
                                data={Data}
                                enableGrouping
                                enableColumnActions={false}
                                enableBottomToolbar={false}
                                enableTopToolbar={false}
                                enableColumnDragging={false}
                                // getRowId={(row) => row.integrationId}
                                manualPagination
                                manualSorting
                                // rowCount={rowCount}
                                localization={{ actions: 'Actions' }}
                                enableRowActions
                                positionActionsColumn="last"
                                renderRowActions={({ row }) => (
                                    <div className="d-flex">
                                        <a className="status-icon justify-content-center">
                                            <i className="fa-regular fa-pen-to-square"></i>
                                        </a>
                                        <a className="status-icon justify-content-center">
                                            <i className="fa-solid fa-trash"></i>
                                        </a>
                                        <a className="align-items-center justify-content-start d-flex grid-switch status-toggle">
                                            <label className="switch-custom">
                                                <input title="switch" type="checkbox" />
                                                <span className="slider round"></span>
                                            </label>
                                        </a>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-3 invoice-table-pagination">
                    <div className="col-lg-6">
                        <div className="d-flex align-items-center">
                            <p className="purple-text bold">Displaying 1 - 2 of 10</p>
                            <div className="d-flex align-items-center ms-3">
                                <label className="mb-0 bold nowrap me-2">Page Size:</label>
                                <select className={`form-control white page-select`} id="supportedPersoIntegrationPageSize">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <PaginationContainer currentPage={1} totalCount={100} pageSize={10} onPageChange={(page: any) => {}} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FunderManagementBottomView;
