import React from 'react';
import AccessRoleBottomView from './AccessRoleBottomView';

const AccessRoleBottomContainer = () => {
    return (
        <div>
           <AccessRoleBottomView/>
        </div>
    );
};

export default AccessRoleBottomContainer;