'use client';
import React, { useEffect, useRef, useState } from 'react';
import cloud_computing from '../../../../assets/images/cloud-computing.svg';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
// import { downloadAccreditationFile, submitAccreditationData, uploadAccreditationFileToServer } from '../../../../service/onboarding-service';
import { DeleteOnboardingAccreditation, downloadAccreditationFile, submitAccreditationData, uploadAccreditationFileToServer } from '../../../../service/onboarding-accreditation-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import swal from 'sweetalert';
import { useLocation } from 'react-router-dom';
import warning from "../../../../assets/images/warning.png"
import { Tooltip } from 'react-tooltip';
import eventEmitter from '../../../../utils/eventEmitter';

const AccreditationsDetailView = ({ accreditations, setAccreditations, item, setIsloading, currentSlide, sliderRef, index, cqcRating }: any) => {
    const [openBox, setOpenBox] = useState(false);
    const [dragActive, setDragActive] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [remaingData, setRemainingData] = useState({
        certificatePath: '',
        fileName: ''
    });
    const [fileLoader, setFileLoader] = useState(false)
    const [isFinalScreen, setIsFinalScreen] = useState(false);

    const location = useLocation();


    const MAX_FILE_SIZE_MB = 10;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

    const inputRef: any = useRef();

    const validationSchema = Yup.object().shape({
        issuedOn: Yup.string().required(),
        validity: index === 0 ? Yup.string() : Yup.string().required()
    });

    const { control, handleSubmit, setValue } = useForm({ mode: 'all', resolver: yupResolver(validationSchema) });

    const { serviceId, serviceName } = useSelector((state: RootState) => state.onboardingService);

    useEffect(() => {
        if (index === 0) {
            setValue('issuedOn', accreditations[0].issuedOn!)
        }
    }, [accreditations, index])

    useEffect(() => {
        if (location.pathname.includes('preview') || location.pathname.includes('publish')) {
            setIsFinalScreen(true);
        } else {
            setIsFinalScreen(false);
        }
    }, [location]);

    const handleBox = (event: any) => {
        event.preventDefault();
        setOpenBox((prevState) => !prevState);
        setFile('');
    };

    const handleDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files[0]);
        }
    };

    const handleChangeFile = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files[0]);
        }
    };

    const handleFile = async (file: any) => {
        if (file.size > MAX_FILE_SIZE_BYTES) {
            swal(`File size should not exceed ${MAX_FILE_SIZE_MB}MB.`, {
                icon: 'error',
            });
            setFile(null);
            return
        } else {
            // setFileError('');
            // setFile(file);
            if (file) {
                const formData = new FormData();
                formData.append('formFile', file);
                // setIsloading(true)
                setFileLoader(true)
                const response = await uploadAccreditationFileToServer(formData);
                if (response?.status) {
                    // setIsloading(false)
                    setFileLoader(false)
                    setRemainingData((prevObj) => ({ ...prevObj, certificatePath: response?.data?.certificatePath, fileName: response?.data?.fileName }));
                    setFile(file);
                    swal("File uploaded successfully", {
                        icon: 'success',
                    });
                    // setFileError('');
                } else {
                    swal(response?.message, {
                        icon: 'error',
                    });
                    setFileLoader(false)
                }
            }

        }
    };

    const onSubmit = async (data: any) => {
        if (index !== 0) {
            data.issuedOn = moment(data.issuedOn).format('DD/MM/YYYY');
            data.validity = moment(data.validity).format('DD/MM/YYYY');
        } else {
            data.validity = data.issuedOn;
        }
        const accreditationId = item.accreditationId;
        const totalData = {
            Service_Id: serviceId,
            accreditationId,
            ...data,
            ...remaingData,

        };
        const response = await submitAccreditationData(totalData);
        setIsloading(true)
        if (response.status === true) {
            setIsloading(false)
            swal(response.message, {
                icon: 'success'
            }).then(async (confirm: any) => {
                // setFileLoader(false)
                const updatedArr = accreditations.map((accred: any) => {
                    if (accred.accreditationId === accreditationId) {
                        if (index === 0) {
                            return {
                                ...accred,
                                issuedOn: data.issuedOn,
                                validity: data.validity,
                                isSelected: remaingData.fileName === '' ? false : true,
                                certificatePath: remaingData.certificatePath,
                                fileName: remaingData.fileName
                            };
                        } else {
                            return {
                                ...accred,
                                issuedOn: data.issuedOn,
                                validity: data.validity,
                                isSelected: true,
                                certificatePath: remaingData.certificatePath,
                                fileName: remaingData.fileName
                            };
                        }
                    }
                    return accred;
                });

                if (sliderRef.current) {
                    sliderRef.current.slickGoTo(currentSlide);
                }
                // eventEmitter.emit('hidePreviewPortal');
                setAccreditations(updatedArr);
                setOpenBox(false);
                // setFileLoader(false)
                // setFileError('');
            });
        } else {
            setIsloading(false)
            swal(response.message, {
                icon: 'error'
            }).then(async (confirm: any) => {
                setFileLoader(false)
                // eventEmitter.emit('hidePreviewPortal');
            });;
        }

    };

    const handleViewFile = async () => {
        const filePath = item?.certificatePath;
        if (filePath) {
            // setIsloading(true)
            const response = await downloadAccreditationFile(filePath);
            if (response?.status === true) {
                // setIsloading(false)
                const a = document.createElement('a');
                a.target = '_blank';
                a.href = response.data;
                document.body.append(a);
                a.click();
                document.body.removeChild(a);
            }
            // setIsloading(false)
        }
    };

    const handleDeleteAccreditation = async (id: number) => {
        const accreditationId = item.accreditationId;
        const payload = {
            serviceId: serviceId,
            accreditationId
        }

        swal({
            title: "",
            text: `Are you sure you want delete?`,
            icon: warning,
            buttons: ["No", "Yes"],
            dangerMode: true
        }).then(async (confirm: boolean) => {
            if (confirm) {
                const response = await DeleteOnboardingAccreditation(payload)
                if (response.status === true) {
                    swal(response?.message, {
                        icon: 'success',
                    });
                    const updatedArr = accreditations?.map((accred: any) => {
                        if (accred.accreditationId === accreditationId) {
                            return {
                                ...accred,
                                issuedOn: "",
                                validity: "",
                                isSelected: false,
                                certificatePath: "",
                                fileName: ""
                            };
                        }
                        return accred;
                    })

                    setAccreditations(updatedArr)
                } else {
                    swal(response?.message, {
                        icon: 'error',
                    });
                }
            }
        })
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-end mt-4">
                <div>
                    <ul className="list-unstyled mb-0 accred-list d-flex flex-column align-items-start">
                        {item?.overall && <li>Overall: <span className="lightblue">{item.overall ? item.overall : ""} <span className="dot-round acc-dot me-1"></span></span></li>}
                        <li>
                            Issue on : <span className="lightblue underline">{item?.issuedOn ? item?.issuedOn : ''}</span>
                        </li>
                        {index !== 0 && <li>
                            Validity : <span className="lightblue underline">{item?.validity ? item?.validity : ''}</span>
                        </li>}
                        {index === 0 && <li className='rate-div-onboarding d-flex'>
                            CQC Rating :&nbsp;<span className="lightblue underline d-flex align-items-center">
                                <span data-tooltip-id="cqc-acc-rating" data-tooltip-content={cqcRating} data-tooltip-place="top">
                                    {cqcRating?.length > 10 ? `${cqcRating?.slice(0, 10)}..` : cqcRating}
                                </span>
                                <span
                                    className={`rate-dot ${cqcRating?.toLowerCase() === "good"
                                        ? "good"
                                        : cqcRating?.toLowerCase() === "requires improvement"
                                            ? "requiresImprovement"
                                            : cqcRating?.toLowerCase() === "inadequate"
                                                ? "inadequate"
                                                : cqcRating?.toLowerCase() === "outstanding"
                                                    ? "outstanding"
                                                    : "na"
                                        } ms-1`}
                                ></span></span>
                        </li>}

                        {item?.certificatePath && (
                            <li>
                                <a className="lightblue underline" onClick={handleViewFile}>
                                    View Certificate
                                </a>
                            </li>
                        )}
                    </ul>
                </div>
                {openBox && (
                    <div className="plusmore-box">
                        <div className="accreditations-data">
                            <h6 className="no-min-height">{item?.title}</h6>
                            <div className="neumorphic-fieldset mb-2">
                                <label htmlFor="issuedOn">
                                    <span className="label-style">Date of Issue*</span>
                                </label>
                                <Controller
                                    control={control}
                                    name="issuedOn"
                                    render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                        const isDisabled = index === 0;
                                        return isDisabled ? (
                                            <input
                                                className="form-control white"
                                                value={value}
                                                disabled
                                            />
                                        ) : (
                                            <DatePicker
                                                className={`form-control white ${error ? 'is-invalid' : ''}`}
                                                format="DD/MM/YYYY"
                                                {...fieldProps}
                                                onChange={(date: any) => {
                                                    fieldProps.onChange(date);
                                                }}
                                                disableFuture
                                                views={['year', 'month', 'day']}
                                                slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="neumorphic-fieldset mb-2">
                                <label htmlFor="validity">
                                    <span className="label-style">Date of Expiry*</span>
                                </label>
                                <Controller
                                    control={control}
                                    name="validity"
                                    render={({ field: { value, ...fieldProps }, fieldState: { error } }) => {
                                        const isDisabled = index === 0;
                                        return isDisabled ? (
                                            <input
                                                className="form-control white"
                                                value="NA"
                                                disabled
                                            />
                                        ) : (
                                            <DatePicker
                                                className={`form-control white ${error ? 'is-invalid' : ''}`}
                                                format="DD/MM/YYYY"
                                                {...fieldProps}
                                                onChange={(date: any) => {
                                                    fieldProps.onChange(date);
                                                }}
                                                disablePast
                                                views={['year', 'month', 'day']}
                                                slotProps={{ actionBar: { actions: ['clear', 'today'] } }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className={`accreditations-file-input ${dragActive ? 'drag-active' : ''}`} onDragEnter={handleDrag} onDragOver={handleDrag} onDragLeave={handleDrag} onDrop={handleDrop}>
                                <input type="file" name="file" id="file" ref={inputRef} onChange={handleChangeFile} />
                                <label htmlFor="file">
                                    <div>
                                        <img src={cloud_computing} className="img-fluid" alt="cloud-image" width={30} />
                                        <span className="accreditations-drop-file"> Drag and Drop files here </span>
                                        <span className="accreditations-or"> or </span>
                                        <button className="accreditations-browse" onClick={() => inputRef.current.click()} disabled={fileLoader}>

                                            {fileLoader ? "...loading" : "Browse Files"}
                                        </button>
                                        {/* {fileError && <p className="file-error">{fileError}</p>} */}
                                        <span className="accreditations-or mt-2"> Maximum file size is 10MB. </span>
                                    </div>
                                </label>
                                {file && <p className="file-name" data-tooltip-id="custom-name"
                                    data-tooltip-content={remaingData.fileName}>{remaingData.fileName}</p>}
                            </div>
                            <Tooltip className="my-tooltip tooltip-custom-width1 text-center" id="custom-name" />
                            <div className="confirm-btn d-flex justify-content-center">
                                <button className="classic-btn" onClick={handleSubmit(onSubmit)}>
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {
                    item.isSelected ? (<><div className={`popup-plus-icon-box mt-3 lightblue-bg ${openBox ? 'open' : ''} `}>
                        <a aria-labelledby="Click Text" className={`popup-plus-icon-btn ${openBox ? 'rotate' : ''}`}>
                            <i className="fa fa-check"></i>
                        </a>
                    </div></>) : (<>
                        <div className={`popup-plus-icon-box mt-3 ${openBox ? 'open' : ''} `}>
                            <a onClick={handleBox} aria-labelledby="Click Text" className={`popup-plus-icon-btn cursor-pointer ${openBox ? 'rotate' : ''}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                            </a>
                        </div>
                    </>)
                }

            </div>
            {
                (item.isSelected && isFinalScreen) && (
                    <div className="delete-ward">
                        <button
                            title="Delete Accreditation"
                            className="classic-btn delete-button"
                            data-tooltip-id="delete"
                            data-tooltip-content="Delete Ward"
                            data-tooltip-place="top"
                            onClick={() => handleDeleteAccreditation(item?.accreditationId)}
                        // disabled={fields?.length === 1}
                        >
                            <span>
                                <i className="fa-solid fa-xmark"></i>
                            </span>
                        </button>
                    </div>
                )
            }
            <Tooltip className="my-tooltip tooltip-custom-width1" id="my-tooltip-filename" />
            <Tooltip id="cqc-acc-rating" className="my-tooltip" />
        </div>
    );
};

export default AccreditationsDetailView;
