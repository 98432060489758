import { API_ENDPOINT, BASEURLDEV } from './ApiConstants';
import instance from './axiosinstance';
import { AxiosError, AxiosResponse } from 'axios';
import { DataResponseModel } from '../utils/model';
import { DescribeFacilityType } from '../utils/model';

export const getOnboardingDescribeFacility = (id: number) => {
    return (
        instance
            .get(`${BASEURLDEV}${API_ENDPOINT.GetOnboardingDescribeFacility}${id}`)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    return errorData;
                }
            })
    );
};

export const generateOpenAiDescription = (payload: DescribeFacilityType, data?: any) => {
    const params = new URLSearchParams();

    if (payload.serviceId !== undefined) {
        params.append('serviceId', payload.serviceId.toString());
    }
    params.append('pageId', payload.pageId.toString());
    return (
        instance
            .post(`${BASEURLDEV}${API_ENDPOINT.GenerateOpenAiDescription}${params.toString()}`, data ? data : {})
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    return errorData;
                }
            })
    );
}

export const submitGenerateOpenAiDescription = (payload: any) => {
    return (
        instance
            .post(`${BASEURLDEV}${API_ENDPOINT.SaveOpenAiDescriptions}`,payload)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    return errorData;
                }
            })
    );
}

export const getGlobalConfiguration = (configName: string) => {
    return (
        instance
            .get(`${BASEURLDEV}${API_ENDPOINT.GetGlobalConfiguration}${configName}`)
            .then((response: AxiosResponse) => {
                const resultData: any = response.data as DataResponseModel;
                return resultData;
            })
            .catch((error: AxiosError) => {
                if (error.code === 'ERR_NETWORK') {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: error.message,
                        totalCount: 0
                    };
                    const errorData: any = errorResponse as DataResponseModel;
                    return errorData;
                } else {
                    const errorResponse = {
                        status: false,
                        data: [],
                        message: 'Something went wrong!',
                        totalCount: 0
                    };
                    const errorData: any = error.response ? (error.response.data as DataResponseModel) : (errorResponse as DataResponseModel);
                    return errorData;
                }
            })
    );
};