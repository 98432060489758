    import React from 'react';
import UserManagementBottomView from './UserManagementBottomView';
    
    const UserManagementBottomContainer = () => {
        return (
            <div>
                <UserManagementBottomView/>
            </div>
        );
    };
    
    export default UserManagementBottomContainer;