import React, { useEffect, useState } from 'react';
import PatientTransactionHistoryReportsView from './PatientTransactionHistoryReportView';

const ReportsContainer  = (props: any) => {
return (
    <>
        <PatientTransactionHistoryReportsView/>
    </>
);
};


export default ReportsContainer;