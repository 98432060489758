    import React from 'react';
import AccessRoleTopView from './AccessRoleTopView';
    
    const AccessRoleTopContainer = () => {
        return (
            <div>
                <AccessRoleTopView/>
            </div>
        );
    };
    
    export default AccessRoleTopContainer;