import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import OnboardingParentContainer from './OnboardingParent/OnboardingParentContainer';
import OnboardingProfileNameView from './OnboardingProfileName/OnboardingProfileNameView';
import { useEffect } from 'react';
import OnboardingHeaderContainer from '../../layout/OnboardingHeader/OnboardingHeaderContainer';

const OnboardingView = () => {
    const { stageNumber } = useSelector((state: RootState) => state.onboardingProgress);
    const { onboardingStages } = useSelector((state: RootState) => state.onboardingProgressStages);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [stageNumber]);

    return (
        <div className="nhs-body">
            <OnboardingHeaderContainer manageProfileValidationType='onboarding'/>
            {stageNumber && (stageNumber > 0 || stageNumber < onboardingStages.length) ? <OnboardingParentContainer /> : <OnboardingProfileNameView />}
        </div>
    );
};

export default OnboardingView;
