import React, { useState, useEffect } from 'react';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InvoiceFilterInvoiceType } from '../../../../utils/constant';

const TableGroupByView = (props: any) => {
    const { t } = useTranslation();

    const { StatusGridFilterState, DataGridFilterState } = useSelector((state: RootState) => state.gridFilterState);

    let [selected, setSelected] = useState<any>('');
    let [typeOfInvoice, setTypeOfInvoice] = useState<any>(false);
    let [funder, setFunder] = useState<any>(false);
    let [count, setCount] = useState<any>(0);
    let [stateMaintain, setStateMaintain] = useState<any>(true);
    let [patientName, setPatientName] = useState<any>(false);
    let [groupByInterimInvoice, setGroupByInterimInvoice] = useState<any>(false);
    let [groupByDischargeInvoice, setGroupByDischargeInvoice] = useState<any>(false);
    let [groupByReserveInvoice, setGroupByReserveInvoice] = useState<any>(false);
    let [typeOfInvoiceArray, setTypeOfInvoiceArray] = useState<any>([]);
    let [typeOfInvoiceID, setTypeOfInvoiceID] = useState<any>([]);

    const handleclick = (event: any, value: any) => {
        event.stopPropagation();
        setSelected(value);
    };

    const eventHandler = (event: any) => {
        event.stopPropagation();
    };

    const onChangeGroupByEvent = (event: any) => {
        setStateMaintain(false)
        if (event.target.checked === true && event.target.name === 'groupByFunder') {
            setFunder(true);
            setCount(count + 1);
        } else if (event.target.checked === false && event.target.name === 'groupByFunder') {
            setFunder(false);
            setCount(count - 1);
        }

        if (event.target.checked === true && event.target.name === 'groupByPatient') {
            setPatientName(true);
            setCount(count + 1);
        } else if (event.target.checked === false && event.target.name === 'groupByPatient') {
            setPatientName(false);
            setCount(count - 1);
        }
    };

    const onlyUnique = (value: any, index: any, array: any) => {
        return array.indexOf(value) === index;
    }

    const onChangeTypeOfInvoice = (event: any) => {
        setStateMaintain(false)
        if (event.target.checked === true && (event.target.name === 'groupByInterimInvoice' || event.target.name === 'groupByDischargeInvoice' || event.target.name === 'groupByReserveInvoice')) {
            let temptypeOfInvoiceArray: any = typeOfInvoiceArray;
            let temptypeOfInvoiceID: any = typeOfInvoiceID;
            let obj: any = {};
            let id: Number = 0;
            if (event.target.name === 'groupByInterimInvoice') {
                obj = {
                    groupByInterimInvoice: event.target.checked
                };
                id = InvoiceFilterInvoiceType.Interim;

                if(!temptypeOfInvoiceArray.some((val: any) => val.groupByInterimInvoice === obj.groupByInterimInvoice)) {
                    temptypeOfInvoiceArray.push(obj);
                }
                if(!temptypeOfInvoiceID.some((val: any) => val === id)) {
                    temptypeOfInvoiceID.push(id);
                }
                setGroupByInterimInvoice(true);
            } else if (event.target.name === 'groupByDischargeInvoice') {
                obj = {
                    groupByDischargeInvoice: event.target.checked
                };
                id = InvoiceFilterInvoiceType.Discharge;
                if(!temptypeOfInvoiceArray.some((val: any) => val.groupByDischargeInvoice === obj.groupByDischargeInvoice)) {
                    temptypeOfInvoiceArray.push(obj);
                }
                if(!temptypeOfInvoiceID.some((val: any) => val === id)) {
                    temptypeOfInvoiceID.push(id);
                }
                setGroupByDischargeInvoice(true);
            } else if (event.target.name === 'groupByReserveInvoice') {
                obj = {
                    groupByReserveInvoice: event.target.checked
                };
                id = InvoiceFilterInvoiceType.Reserve;

                if(!temptypeOfInvoiceArray.some((val: any) => val.groupByReserveInvoice === obj.groupByReserveInvoice)) {
                    temptypeOfInvoiceArray.push(obj);
                }
                if(!temptypeOfInvoiceID.some((val: any) => val === id)) {
                    temptypeOfInvoiceID.push(id);
                }
                setGroupByReserveInvoice(true);
            }
            setTypeOfInvoiceArray(temptypeOfInvoiceArray);
            setTypeOfInvoiceID(temptypeOfInvoiceID);
        } else {
            if (event.target.name === 'groupByInterimInvoice') {
                const index = typeOfInvoiceArray.map((x: any) => x.groupByInterimInvoice).indexOf(true);
                if (index > -1) {
                    typeOfInvoiceArray.splice(index, 1);
                    setTypeOfInvoiceArray(typeOfInvoiceArray);
                }

                const indexNew = typeOfInvoiceID.map((x: any) => x === InvoiceFilterInvoiceType.Interim).indexOf(true);
                if (indexNew > -1) {
                    typeOfInvoiceID.splice(indexNew, 1);
                    setTypeOfInvoiceID(typeOfInvoiceID);
                }
                setGroupByInterimInvoice(false);
            } else if (event.target.name === 'groupByDischargeInvoice') {
                const index = typeOfInvoiceArray.map((x: any) => x.groupByDischargeInvoice).indexOf(true);
                if (index > -1) {
                    typeOfInvoiceArray.splice(index, 1);
                    setTypeOfInvoiceArray(typeOfInvoiceArray);
                }
                const indexNew = typeOfInvoiceID.map((x: any) => x === InvoiceFilterInvoiceType.Discharge).indexOf(true);
                if (indexNew > -1) {
                    typeOfInvoiceID.splice(indexNew, 1);
                    setTypeOfInvoiceID(typeOfInvoiceID);
                }
                setGroupByDischargeInvoice(false);
            } else if (event.target.name === 'groupByReserveInvoice') {
                const index = typeOfInvoiceArray.map((x: any) => x.groupByReserveInvoice).indexOf(true);
                if (index > -1) {
                    typeOfInvoiceArray.splice(index, 1);
                    setTypeOfInvoiceArray(typeOfInvoiceArray);
                }

                const indexNew = typeOfInvoiceID.map((x: any) => x === InvoiceFilterInvoiceType.Reserve).indexOf(true);
                if (indexNew > -1) {
                    typeOfInvoiceID.splice(indexNew, 1);
                    setTypeOfInvoiceID(typeOfInvoiceID);
                }
                setGroupByReserveInvoice(false);
            }
        }
        if (typeOfInvoiceArray.length > 0) {
            if(typeOfInvoice === false) {
                setCount(count + 1);
            }
            setTypeOfInvoice(true);
            let obj = {
                typeInvoice: true
            };
            props.onChangeTypeInvoiceGroupByHandler(obj, typeOfInvoiceID);
        } else {
            setCount(count - 1);
            let obj = {
                typeInvoice: false
            };
            props.onChangeTypeInvoiceGroupByHandler(obj, typeOfInvoiceID);
            setTypeOfInvoice(false);
        }
    };

    const onClickRemoveAllGroupBy = () => {
        setTypeOfInvoice(false);
        setPatientName(false);
        setGroupByInterimInvoice(false);
        setGroupByDischargeInvoice(false);
        setGroupByReserveInvoice(false);
        setFunder(false);
        setCount(0);
        props.onRemoveAllGroupBy(false);
    };

    useEffect(() => {
        if (StatusGridFilterState && stateMaintain) {
            if (
                DataGridFilterState?.grouping?.typeOfInvoice?.groupByInterimInvoice ||
                DataGridFilterState?.grouping?.typeOfInvoice?.groupByDischargeInvoice ||
                DataGridFilterState?.grouping?.typeOfInvoice?.groupByReserveInvoice
            ) {
                setTypeOfInvoice(true);
                if (DataGridFilterState?.grouping?.typeOfInvoice?.groupByInterimInvoice) {
                    setGroupByInterimInvoice(true);
                } else {
                    setGroupByInterimInvoice(false);
                }
                if (DataGridFilterState?.grouping?.typeOfInvoice?.groupByDischargeInvoice) {
                    setGroupByDischargeInvoice(true);
                } else {
                    setGroupByDischargeInvoice(false);
                }
                if (DataGridFilterState?.grouping?.typeOfInvoice?.groupByReserveInvoice) {
                    setGroupByReserveInvoice(true);
                } else {
                    setGroupByReserveInvoice(false);
                }
                setCount(count + 1);
                let obj: any = {
                    status: true,
                    name: 'groupByInvoiceType'
                };
                setTypeOfInvoice(true);
                props.onStateGroupByHandler(obj);
            } else {
                // setCount(count - 1)
                setTypeOfInvoice(false);
                setGroupByInterimInvoice(false);
                setGroupByDischargeInvoice(false);
                setGroupByReserveInvoice(false);
            }
            // else {
            //     setRMGroupByServiceType(false);
            //     setCount(count - 1)
            // }
            if (DataGridFilterState?.grouping?.groupByFunder) {
                setFunder(true);
                // if (count === 0) {
                //     setCount(count + 1);
                // }
                setCount(count + 1);
                let obj: any = {
                    status: true,
                    name: 'groupByFunder'
                };
                props.onStateGroupByHandler(obj);
            }
            // else {
            //     setCount(count - 1)
            // }
            if (DataGridFilterState?.grouping?.groupByPatient) {
                setPatientName(true);
                // if (count === 0) {
                //     setCount(count + 1);
                // }
                // if (count === 1) {
                //     setCount(count + 1);
                // }
                setCount(count + 1);
                let obj: any = {
                    status: true,
                    name: 'groupByPatient'
                };
                props.onStateGroupByHandler(obj);
            } 
            // else {
            //     setCount(count - 1)
            // }

            
        }else if(StatusGridFilterState && !stateMaintain)
        {
            if(DataGridFilterState?.grouping?.typeOfInvoice?.groupByInterimInvoice == false &&
                DataGridFilterState?.grouping?.typeOfInvoice?.groupByDischargeInvoice == false &&
                DataGridFilterState?.grouping?.typeOfInvoice?.groupByReserveInvoice == false &&
                DataGridFilterState?.grouping?.groupByFunder == false && 
                DataGridFilterState?.grouping?.groupByPatient == false) 
                {                    
                    setTypeOfInvoice(false);
                    setPatientName(false);
                    setGroupByInterimInvoice(false);
                    setGroupByDischargeInvoice(false);
                    setGroupByReserveInvoice(false);
                    setFunder(false);
                    setCount(0);
            }
        }
    }, [StatusGridFilterState, DataGridFilterState]);

    return (
        <div>
            <div className="dropdown filter-dropdown me-2 mb-3">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="legend-label">
                        <i className="fa-solid fa-layer-group"></i> {t('TableGroupby.Group_By')}
                        {count > 0 && (typeOfInvoice === true || funder === true || patientName === true) && (
                            <span className="applied-filter">
                                {typeOfInvoice === true && <>Type Of Invoice</>} {count > 1 && (typeOfInvoice === true && funder === true) && <i className="fa-solid fa-chevron-right"></i>}
                                {funder === true && <> Funder</>} {(count > 1) && patientName === true && <i className="fa-solid fa-chevron-right"></i>} {patientName === true && <>Patient</>}
                                <a href="#" className="ms-2">
                                    <i className="fa-solid fa-xmark white-text" onClick={onClickRemoveAllGroupBy}></i>
                                </a>
                            </span>
                        )}
                    </div>
                </a>
                <div className="dropdown-menu profile-dw-css" onClick={eventHandler} aria-labelledby="navbarDropdownMenuLink">
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                name="groupByFunder"
                                checked={funder}
                                onChange={(event: any) => {
                                    props.onChangeGroupByHandler(event);
                                    onChangeGroupByEvent(event);
                                }}
                                className="me-1"
                            />{' '}
                            {t('TableGroupby.Funder')}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                name="groupByPatient"
                                checked={patientName}
                                onChange={(event: any) => {
                                    props.onChangeGroupByHandler(event);
                                    onChangeGroupByEvent(event);
                                }}
                                className="me-1"
                            />{' '}
                            {t('TableGroupby.Patient')}
                        </label>
                    </div>

                    <div className="dropdown sub-dropdown">
                        <a
                            className={`nav-link dropdown-toggle  ${selected === 'invoice' ? 'show' : ''}`}
                            href="#"
                            onClick={(event) => handleclick(event, 'invoice')}
                            id="navbarDropdownMenuLink1"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <label>
                                <input type="checkbox" name="typeOfInvoice" checked={typeOfInvoice} onChange={() => {}} className="me-1" /> {t('TableGroupby.Type_Invoice')}{' '}
                                <i className="fa-solid fa-caret-right ms-auto"></i>
                            </label>
                        </a>
                        <div className={`dropdown-menu dropdown-submenu  ${selected === 'invoice' ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLink1">
                            <div>
                                <label>
                                    <input type="checkbox" name="groupByInterimInvoice" checked={groupByInterimInvoice} onChange={(event: any) => onChangeTypeOfInvoice(event)} className="me-1" />{' '}
                                    {t('TableGroupby.Interim_Invoice')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="groupByDischargeInvoice" checked={groupByDischargeInvoice} onChange={(event: any) => onChangeTypeOfInvoice(event)} className="me-1" />{' '}
                                    {t('TableGroupby.Discharge_Invoice')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="groupByReserveInvoice" checked={groupByReserveInvoice} onChange={(event: any) => onChangeTypeOfInvoice(event)} className="me-1" />{' '}
                                    {t('TableGroupby.Reserve_Invoice')}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableGroupByView;
