    import React from 'react';
import CreateProductView from './CreateProductView';
    
    const CreateProductContainer = () => {
        return (
            <div>
                <CreateProductView/>
            </div>
        );
    };
    
    export default CreateProductContainer;