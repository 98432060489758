import React, { useEffect, useState } from 'react';
import UserDetailView from './UserDetailView';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import eventEmitter from '../../../../../../utils/eventEmitter';

const UserDetailContainer = (props:any) => {

    const { StatusPatientInfo, DataPatientInfo, MessagePatientInfo, ErrorPatientInfo } = useSelector((state: RootState) => state.patientInformation);
    const { StatusCityInfo, DataCityInfo, MessageCityInfo, ErrorCityInfo } = useSelector((state: RootState) => state.cityInformation);
    const { StatusServiceInfo, DataServiceInfo, MessageServiceInfo, ErrorServiceInfo } = useSelector((state: RootState) => state.serviceInformation);
    const { StatusLeadInfo, DataLeadInfo, MessageLeadInfo, ErrorLeadInfo } = useSelector((state: RootState) => state.leadInformation);
    const [userdata, setuserdata] = useState<any>();
    const [sdob,setdob] =useState<any>(null);
    const [sleaddate,setleaddate] =useState<any>(null);
    const [estDateOfArrival, setEstDateOfArrival] = useState<any>(null);

    const [clearpatient, setclearpatient] = useState<any>(undefined);
    const [clearservice, setclearservice] = useState<any>(undefined);
    const [clearcity, setclearcity] = useState<any>(undefined);


    useEffect(()=>{
        if(DataLeadInfo?.data?.patientDetail !== undefined){
            let obj = {
                leaddate:DataLeadInfo?.data?.patientDetail?.leadDate,
                NhsId:DataLeadInfo?.data?.patientDetail?.nhsId,
                LocalId:DataLeadInfo?.data?.patientDetail?.localId,
                patienttype:DataLeadInfo?.data?.patientDetail?.patientTypeId,
                servicetype:DataLeadInfo?.data?.patientDetail?.serviceTypeId,
                name:DataLeadInfo?.data?.patientDetail?.patientName,
                dob:DataLeadInfo?.data?.patientDetail?.dateOfBirth,
                Address1:DataLeadInfo?.data?.patientDetail?.addressLine1,
                Address2:DataLeadInfo?.data?.patientDetail?.addressLine2,
                estDateOfArrival: DataLeadInfo?.data?.patientDetail?.estDateOfArrival,
                City:DataLeadInfo?.data?.patientDetail?.countyId,
                gender:DataLeadInfo?.data?.patientDetail?.gender,
                PoBox:DataLeadInfo?.data?.patientDetail?.pobox,
                ContactNo:DataLeadInfo?.data?.patientDetail?.contactNo,
                EmailId:DataLeadInfo?.data?.patientDetail?.email
            }
            
            props.getuserdata(obj);
            setuserdata(obj);
          }   
    },[DataLeadInfo])
    const updateData = (e: any) => {
        
        if(e.target.name === "patienttype"){
           setclearpatient('yes');
        }
        if(e.target.name === "servicetype"){
            setclearservice('yes');
        }
        if(e.target.name === "City"){
            setclearcity('yes');
        }
        if (e.target.type === 'radio') {
            setuserdata({
                ...userdata,
                gender: e.target.value
            });
            let obj = {
                ...userdata,
                ...sdob,
                ...estDateOfArrival,
                ...sleaddate,
                gender: e.target.value
            };
                props.getuserdata(obj);
        } else {
            setuserdata({
                ...userdata,
                [e.target.name]: e.target.value
            });
            let obj = {
                ...userdata,
                ...sdob,
                ...estDateOfArrival,
                ...sleaddate,
                [e.target.name]: e.target.value
            };
                props.getuserdata(obj);
        }
        eventEmitter.emit("eventCloseEditLeadSideMenu");
    };

    const getleadDate = (date:Date) => {
        let leaddate = new Date(date);
        setleaddate({ leaddate: leaddate });
        let obj = {
            ...userdata,
            ...sdob,
            ...estDateOfArrival,
            ...sleaddate,
            leaddate: leaddate
        };
            props.getuserdata(obj);
    };

    const getDate = (date:Date) => {
        let admidate = new Date(date);
        setdob({ dob: admidate });
        let obj = {
            ...userdata,
            ...sdob,
            ...estDateOfArrival,
            ...sleaddate,
            dob: admidate
        };
            props.getuserdata(obj);
    };

    const getEstDate = (date: Date) => {
        let estDate = new Date(date);
        setEstDateOfArrival({ estDateOfArrival: estDate });
        let obj = {
            ...userdata,
            ...sdob,
            ...estDateOfArrival,
            ...sleaddate,
            estDateOfArrival: estDate
        };
        props.getuserdata(obj);
    }

    const seterror = (data:any) =>{
        props.getuserdataerror(data);
        props.getuserformerror(data);
    }

    return (
        <div>
            <UserDetailView updateData={updateData} getEstDate={getEstDate} getleadDate={getleadDate} getDate={getDate} patientType={DataPatientInfo} citylist={DataCityInfo} servicelist={DataServiceInfo} iserror={seterror} leaddata={DataLeadInfo} clearpatient={clearpatient} clearcity={clearcity} clearservice={clearservice}/>  
        </div>
    );
};

export default UserDetailContainer;