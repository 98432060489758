import ExportReportsModalView from './ExportReportsModalView';

const ExportReportsModalContainer = (props: any) => {
    return (
        <div>
            <ExportReportsModalView {...props} onClose={props.onClose} />
        </div>
    );
};

export default ExportReportsModalContainer;