import React, { useEffect, useState } from 'react';
import profile_photo from '../../../../../../../assets/images/profile-photo.png';
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
import * as Yup from 'yup';
import swal from 'sweetalert';
import { GetServiceProvierEmails, SendServiceCoordinatorEmails } from '../../../../../../../service/service-details-service';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import RenderModalContainer from '../../../../../../../components/RenderModal/indexContainer';
import WYSIWYGEditor from '../../../../../../../components/WYSIWYGEditor/WYSIWYGEditor';
import ClientPortal from '../../../../../../../components/ClientPortal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';

const initialValues = {
    name: '',
    subject: '',
    from: '',
    body: ''
};

const WORD_LIMIT = 500;

function calculateWordCount(text: string) {
    if (text.trim().length === 0) {
        return 0;
    }
    return text?.trim().split(/\s+/).length;
}

export const serviceCoordinatorMail = Yup.object().shape({
    //below line is commented for the future possibilities

    // to: Yup.array().min(1, "Email address required").of(Yup.object().shape({
    //     name: Yup.string().notRequired(),
    //     email: Yup.string(),
    // })).required("Please enter email"),
    name: Yup.string().required('Please enter name*'),
    subject: Yup.string().required('Please enter subject*'),
    from: Yup.string()
        .trim()
        .matches(/^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Email is invalid')
        .email('Email is Invalid*')
        .required(),
    body: Yup.string()
        .test('wordCount', `Body cannot exceed ${WORD_LIMIT} words`, function (value) {
            if (!value) return true;
            return calculateWordCount(value) <= WORD_LIMIT;
        })
        .required('')
});

const CoordinatorDetailsView = (props: any) => {
    const [showPortal, setShowPortal] = useState(false);
    const [initialBody, setInitialBody] = useState<string>('');
    const [showFullMessage, setShowFullMessage] = useState<boolean>(false);
    const { serviceSlug } = useParams();
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);

    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        reset,
        watch,
        setValue,
        control,
        trigger
    } = useForm<any>({
        defaultValues: initialValues,
        resolver: yupResolver(serviceCoordinatorMail)
    });
    const { t } = useTranslation();

    const handleModal = (event: any) => {
        event.preventDefault();
        setShowPortal((prevState) => !prevState);
    };
    useEffect(() => {
        if (showPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showPortal]);

    useEffect(() => {
        if (showFullMessage) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showFullMessage]);

    const splitFullName = (fullName: any) => {
        if (!fullName || typeof fullName !== 'string') {
            return { firstName: '', lastName: '' };
        }

        const nameParts = fullName.split(' ');
        const firstName = nameParts[0];
        const lastName = nameParts.slice(1).join(' ');

        return { firstName, lastName };
    };

    const { firstName, lastName } = splitFullName(props?.serviceCoordinatorDetails?.name);

    const validateEmail = (email: any) => {
        var re = /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email?.toLowerCase());
    };
    useEffect(() => {
        AOS.init();
    }, []);

    const serviceProviderEmailSubmit = async (data: any) => {
        const payload = {
            serviceSlug: serviceSlug,
            subject: data.subject,
            fromEmail: {
                email: data.from,
                name: data.name
            },
            body: data.body + `<br><p><a href=mailto:${data.from}>Click here to reply</a> ${data.name}.</p>`
        };
        const response = await SendServiceCoordinatorEmails(payload);
        if (response.status === true) {
            setShowPortal(false);
            swal(response.message, {
                icon: 'success'
            });
            reset(initialValues);
        } else {
            swal(response.message, {
                icon: 'error',
                dangerMode: true
            });
            reset(initialValues);
        }
    };
    const viewMore = () => {
        setShowFullMessage(!showFullMessage);
    };

    const handleSeeMoreModal = (event: any) => {
        event.preventDefault();
        setShowFullMessage((prevState) => !prevState);
    };

    useEffect(() => {
        setValue('name', DataLogin?.userModel?.userName, { shouldValidate: true });
        setValue('from', DataLogin?.userModel?.email, { shouldValidate: true });
    }, [showPortal]);
    return (
        <div>
            {/* <h4 className="small-title indigo-text bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                {t("Overview.Meet_Your_Coordinator")}
            </h4> */}
            <div className="bg-gray coordinator-box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="400" data-aos-once="true">
                <div>
                    <div className="row align-items-center">
                        {/* <div className="col-lg-6">
                            <div className="text-center">
                                <div className="overview-profile-img">
                                    {props?.serviceCoordinatorDetails?.imageUrl &&<img src={props?.serviceCoordinatorDetails?.imageUrl} alt="Profile Image" width={100} />}
                                </div>
                                <h6 className="mt-2 mb-1 lead bold">{firstName} {lastName}</h6>
                                <p className="bold">{props?.serviceCoordinatorDetails?.designation}</p>

                            </div>
                        </div> */}
                        <div className="col-lg-12 col-md-12">
                            <div className="text-center">
                                <div className="overview-profile-img">
                                    {/* {props?.serviceCoordinatorDetails?.imageUrl && <img src={props?.serviceCoordinatorDetails?.imageUrl} alt="Profile Image" width={100} />} */}
                                    <img src={props?.serviceCoordinatorDetails?.imageUrl ? props?.serviceCoordinatorDetails?.imageUrl : profile_photo} alt="Profile Image" width={100} />
                                </div>
                                <h6 className="mt-2 mb-1 lead bold">
                                    {firstName} {lastName}
                                </h6>
                                <p className="bold">{props?.serviceCoordinatorDetails?.designation}</p>
                                <p className="mt-4 hanzipen-font thanksmsg">{props?.serviceCoordinatorDetails?.serviceCoordinatorMessage}</p>
                                {/* <p className="mt-3 hanzipen-font thanksmsg">Feel free to contact me anytime between 9am to 5 pm (Mon – Fri) on <a href="mailto:julia@cloud.com">julia@cloud.com</a> or give me a shout on <a href="tel:9827405968">9827405968</a></p> */}
                            </div>
                        </div>
                        {/* <div className="col-lg-6"> */}
                        {/* <div className="coordinator-text border-bottom ">
                                <span>23</span>
                                <p className="bold">{t("ServiceDetail.Reviews")}</p>
                            </div>
                            <div className="coordinator-text border-bottom ">
                                <span>4.5 <i className="fa-solid fa-star"></i></span>
                                <p className="bold">{t("ServiceDetail.Rating")}</p>
                            </div> */}
                        {/* <div className="coordinator-text border-bottom mb-0 font15">
                                {props?.serviceCoordinatorDetails?.yearsAt ? <span>{props?.serviceCoordinatorDetails?.yearsAt}</span> : <span>Less than 1</span>}
                                {props?.serviceCoordinatorDetails?.yearsAt ? <p className="bold">Years at {props?.serviceCoordinatorDetails?.serviceName}</p> : <p className="bold">Year at {props?.serviceCoordinatorDetails?.serviceName}</p>}
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                    <a href="#" onClick={handleModal} className="btn primary-btn btn-overview mb-0">
                        {/* Message {firstName} */}
                        Message Me
                    </a>
                    {/* <p className="bolder coordinator-title">Message from your Coordinator</p> */}
                    {/* {props?.serviceCoordinatorDetails?.serviceCoordinatorMessage && props?.serviceCoordinatorDetails?.serviceCoordinatorMessage !== null &&
                        <p className="border-top pt-3 font15">{props?.serviceCoordinatorDetails?.serviceCoordinatorMessage.substring(0, 200) + (props?.serviceCoordinatorDetails?.serviceCoordinatorMessage?.length > 200 ? '...' : '')}
                            {props?.serviceCoordinatorDetails?.serviceCoordinatorMessage?.length > 200 && <a className="view-more-text bold hand-icon" onClick={viewMore}>view more</a>}
                        </p>} */}
                    {/* <p className="coordinator-img"><img src={openIcon.src} alt="open 24/7" width={20} className="me-2" />We are open 24/7 and 365 days</p> */}
                </div>
            </div>
            <ClientPortal selector="myportal" show={showPortal}>
                <div className="overlay" onClick={handleModal}></div>
                <div className="modal modal-550 ">
                    <div className="header mb-4">
                        <h4 className="mb-0">
                            Send a message to {firstName} from {props?.serviceCoordinatorDetails?.serviceName}
                        </h4>
                        <a onClick={handleModal} className="removeButton">
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="body message-popup p-4">
                        <form onSubmit={handleSubmit(serviceProviderEmailSubmit)}>
                            <div className="row">
                                <div className="col-md-6">
                                    {/* <p className="mb-2 label">Name*</p> */}
                                    <div className="floating-field w-100">
                                        <input
                                            // placeholder="Enter your Name*"
                                            className={`${errors.name ? 'form-control white mb-4 error-field' : 'form-control white mb-4'}`}
                                            {...register('name', { required: 'Name is required' })}
                                            onBlur={() => trigger('name')}
                                            name="name"
                                            placeholder=" "
                                        />
                                        <label className="floating-form-label">Name*</label>
                                    </div>
                                    {/* {errors.name && <p className="error-message">{errors.name?.message as String}</p>} */}
                                </div>
                                <div className="col-md-6">
                                    {/* <p className="mb-2 label">Email Address*</p> */}
                                    <div className="floating-field w-100">
                                        <input
                                            type="text"
                                            // placeholder="Enter Your Email Address*"
                                            className={`${errors.from ? 'form-control white mb-4 overview-input error-field' : 'form-control white mb-4 overview-input'}`}
                                            {...register('from', { required: 'From email is required*' })}
                                            onBlur={() => trigger('from')}
                                            name="from"
                                            placeholder=" "
                                        />
                                        <label className="floating-form-label">Email Address*</label>
                                    </div>
                                    {/* {errors.from && <p className="error-message">{errors.from?.message as String}</p>} */}
                                </div>
                            </div>
                            <div className="floating-field w-100">
                                <input
                                    className={`${errors.subject ? 'form-control white mb-4 error-field' : 'form-control white mb-4'}`}
                                    {...register('subject', { required: 'Subject is required' })}
                                    placeholder=" "
                                    onBlur={() => trigger('subject')}
                                    name="subject"
                                />
                                <label className="floating-form-label">Subject*</label>
                            </div>
                            <div className="floating-field w-100">
                                <textarea
                                    rows={8}
                                    className={`${
                                        errors.body ? `form-control white error-field pt-4 ${calculateWordCount(watch('body')) > WORD_LIMIT ? '' : ''}` : 'form-control white pt-4'
                                    }`}
                                    // {...register("body", { required: "Body is required" })}
                                    onChange={(e) => setValue('body', e.target.value?.split('<p></p>')?.join('')?.trim(), { shouldValidate: true })}
                                    onBlur={() => trigger('body')}
                                    placeholder=" "
                                />
                                <label className="floating-form-label">Your Message*</label>
                            </div>

                            <div className='d-flex justify-content-end'>
                                {errors.body && calculateWordCount(watch('body')) > WORD_LIMIT && <p className="error-message mb-4 ps-0">{errors.body?.message as String}</p>}
                                <span>
                                    {calculateWordCount(watch('body'))}/{WORD_LIMIT}
                                </span>
                            </div>
                            <div className="w-full text-center">
                                <button disabled={!isValid} type="submit" className="btn primary-btn mt-1 px-3">
                                    Send
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </ClientPortal>
        </div>
    );
};

export default CoordinatorDetailsView;
