import React from 'react';
import CreateUserView from './CreateUserView';

const CreateUserContainer = () => {
    return (
        <div>
            <CreateUserView/>
        </div>
    );
};

export default CreateUserContainer;