import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OwlCarousel from 'react-owl-carousel';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import EditPencil from '../../../../assets/images/edit.svg';
import ClientPortal from '../../../../components/ClientPortal';
import { getAmenitiesByServiceId } from '../../../../service/onboarding-preview';
import { RootState } from '../../../../store';
import { ModulesAccessRights } from '../../../../utils/constant';
import AmenitiesTabContentContainer from '../../../NHSPage/NHSServiceDetail/ServiceTab/Amenities/AmenitiesSlider/AmenitiesTabContent/AmenitiesTabContentContainer';
import NameWardsContainer from '../../NameWards/NameWardsContainer';
import SetupWardContainer from '../../SetupWard/SetupWardContainer';
import swal from 'sweetalert';
import warning from '../../../../assets/images/warning.png';
import OnboardingPreviewEditModalFooter from '../OnboardingPreviewEditModalFooter';
import OnboardingHeaderContainer from '../../../../layout/OnboardingHeader/OnboardingHeaderContainer';
import eventEmitter from '../../../../utils/eventEmitter';

export interface PreviewAmenitiesType {
    amenityCategoryId: number;
    amenityCategoryName: string;
    amenityCategoryIcon: string;
    header: string;
    amenityMasters: {
        amenityId: number;
        amenityMasterName: string;
        amenityMasterIcon: string;
        amenityCategoryId: number;
        isCommonAreaFacilities: boolean;
        serviceId: number;
    }[];
}

const OnboardingPreviewAmenitiesView = () => {
    const { t } = useTranslation();

    const setupWardPage = useRef<any>();
    // const nameWardPage = useRef<any>();
    const targetElementRef = useRef<HTMLDivElement>(null);

    const { serviceId, serviceName, slugName } = useSelector((state: RootState) => state.onboardingService);
    const { isForPublished } = useSelector((state: RootState) => state.onboardingIsForPublished);
    const { StatusLogin, DataLogin } = useSelector((state: RootState) => state.login);

    const [showEditPage, setShowEditPage] = useState<any>(DataLogin?.userModel?.modules?.find((item:any)=>(item.moduleName === 'OnBoarding')))

    const [stagePadding, setStagePadding] = useState(0);
    // const [refreshWards, setRefreshWards] = useState(1);
    const [amenities, setAmenities] = useState<PreviewAmenitiesType[] | null>(null);
    const [selectedTab, setSelectedTab] = useState(null);
    const [filteredCategories, setFilteredCategories] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [showSetupWards, setShowSetupWards] = useState(false);
    // const [showNameWards, setShowNameWards] = useState(false);
    const [showAmenities, setShowAmenities] = useState(false);

    const handleTabClick = (tabId: any) => {
        setSelectedTab(tabId);
    };

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }, []);

    const handleShowSetupWards = (event: any) => {
        event.preventDefault();
        setShowSetupWards((prevState) => !prevState);
    };

    // const handleShowNameWards = (event: any) => {
    //     event.preventDefault();
    //     setShowNameWards((prevState) => !prevState);
    // };

    const handlePreviewAmenities = (event: any, id: any) => {
        event.preventDefault();
        setSelectedTab(id);
        setShowAmenities((prevState) => !prevState);
    };

    const handleShowAmenities = (event: any) => {
        event.preventDefault();
        setShowAmenities((prevState) => !prevState);
    };

    const getAmenities = async () => {
        const result = await getAmenitiesByServiceId(slugName!);
        if (result.status === true) {
            setAmenities(result?.data);
        }
    };

    const handleSave = async (event: any) => {
        swal({
            title: '',
            text: `Do you want to save the changes done to the amenities/ bed board?`,
            icon: warning,
            buttons: ['No', 'Yes'],
            dangerMode: true
        }).then(async (confirm: any) => {
            if (confirm) {
                if ((await setupWardPage.current.triggerValidation()) === false) {
                    return;
                }
                setupWardPage.current.nextActionHandler!(true);
                setTimeout(async () => {
                    await getAmenities();
                    handleShowSetupWards(event);
                }, 1500);
            }
        });
    };

    // const handleSaveWards = async (event: any) => {
    //     if ((await nameWardPage.current.triggerValidation()) === false) {
    //         return;
    //     }
    //     nameWardPage.current.nextActionHandler!(true);
    //     setTimeout(async () => {
    //         setRefreshWards((prev)=> prev+1)
    //         handleShowNameWards(event);
    //     }, 1500);
    // };

    useEffect(() => {
        if (showSetupWards) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showSetupWards]);

    useEffect(() => {
        const filtered = amenities
            ?.map((amenityCategory: any) => {
                const filteredAmenities = amenityCategory.amenityMasters.filter((amenity: any) => amenity.amenityMasterName.toLowerCase().includes(searchTerm.toLowerCase()));
                return { ...amenityCategory, amenityMasters: filteredAmenities };
            })
            .filter((category: any) => category.amenityMasters.length > 0);

        setFilteredCategories(filtered || []);

        if (filtered && filtered.length > 0) {
            setSelectedTab(filtered[0].amenityCategoryId);
        } else {
            setSelectedTab(null);
        }
    }, [amenities, searchTerm]);

    useEffect(() => {
        const calculateSpace = () => {
            const targetElement = targetElementRef.current;
            if (targetElement) {
                const rect = targetElement.getBoundingClientRect();
                const spaceToLeft = rect.left;
                return spaceToLeft;
            }
            return 0;
        };

        const calculatedPadding = calculateSpace();
        setStagePadding(calculatedPadding);

        getAmenities();
        eventEmitter.on('getServiceAmenitiesOnboardingPreview', async ()=>{ await getAmenities();});
        return () => {
            eventEmitter.off('getServiceAmenitiesOnboardingPreview', async ()=>{ await getAmenities();})
        }
    }, []);

    useEffect(() => {
        if (showAmenities) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showAmenities]);

    // useEffect(() => {
    //     if (showNameWards) {
    //         document.body.classList.add('modal-open');
    //     } else {
    //         document.body.classList.remove('modal-open');
    //     }
    //     return () => {
    //         document.body.classList.remove('modal-open');
    //     };
    // }, [showNameWards]);

    return (
        <div id="onboarding">
            <div id="amenities-section" className="service-detail-padding amenities-section bg-gray">
                <div className="service-section-title">
                    <div className="container-fluid container-fluid-service p-0">
                        <div className="row">
                            <div className="col-lg-12 p-0">
                                <div>
                                    <h4 className="text-center section-title mb-3 bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                                        {amenities ? amenities[0].header : t('Amenities.Comfort_Safety_in_every_corner')}
                                    </h4>
                                    <div className="d-flex align-items-start justify-content-center preview-small-title">
                                        <p className="section-sub-title text-center bolder mb-0" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300" data-aos-once="true">
                                            {t('Amenities.Amenities')}
                                        </p>
                                        {isForPublished ? (
                                            showEditPage?.userAccess?.some((a: any) => a.accessCode === ModulesAccessRights.Onboarding.AmenitiesEditAccess.accessCode) ? (
                                                <div
                                                    className="edit-icon-preview ms-2 cursor-pointer"
                                                    onClick={handleShowSetupWards}
                                                    data-aos="fade-up"
                                                    data-aos-duration="1500"
                                                    data-aos-delay="300"
                                                    data-aos-once="true"
                                                >
                                                    <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        ) : (
                                            <div
                                                className="edit-icon-preview ms-2 cursor-pointer"
                                                onClick={handleShowSetupWards}
                                                data-aos="fade-up"
                                                data-aos-duration="1500"
                                                data-aos-delay="300"
                                                data-aos-once="true"
                                            >
                                                <img src={EditPencil} className="img-fluid" alt="edit" width={20} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid service-slider">
                    <div className="row">
                        <div className="col-lg-12 p-0">
                            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600" data-aos-once="true">
                                {amenities && amenities?.length > 0 && (
                                    <OwlCarousel
                                        className="owl-theme amenities-owl all-owl"
                                        dots={false}
                                        margin={20}
                                        mouseDrag={false}
                                        smartSpeed={800}
                                        touchDrag={false}
                                        nav={true}
                                        // stagePadding={stagePadding}
                                        responsive={{
                                            // "0": { items: 2 },
                                            // "768": { items: 3 },
                                            '1200': { items: 4 },
                                            '1400': { items: 4 }
                                        }}
                                    >
                                        {amenities &&
                                            amenities?.length > 0 &&
                                            amenities.map((am: any) => {
                                                return (
                                                    <div className="item">
                                                        <div className="amenities-box">
                                                            <img style={{ width: '33px' }} src={am?.amenityCategoryIcon} className="img-fluid" alt={am?.amenityCategoryName} />
                                                            <div className="d-flex justify-content-between align-items-center mt-3">
                                                                <p className="lead-text bold">{t(am?.amenityCategoryName)}</p>
                                                            </div>
                                                            <ul className="list-unstyled amenities-list mb-0">
                                                                {am &&
                                                                    am.amenityMasters &&
                                                                    am.amenityMasters.length > 0 &&
                                                                    am.amenityMasters.map((master: any, index: any) => {
                                                                        if (index <= 4) {
                                                                            return (
                                                                                <li
                                                                                    data-tooltip-id={master?.amenityMasterName.length > 25 ? 'my-tooltip' : ''}
                                                                                    data-tooltip-content={master?.amenityMasterName}
                                                                                >
                                                                                    {master?.amenityMasterName.length > 25
                                                                                        ? master?.amenityMasterName.substring(0, 25) + '...'
                                                                                        : master?.amenityMasterName}
                                                                                    {master?.isCommonAreaFacilities && (
                                                                                        <div className="common-icon ms-auto">
                                                                                            <i className="fa-solid fa-house"></i>
                                                                                        </div>
                                                                                    )}
                                                                                </li>
                                                                            );
                                                                        }
                                                                    })}
                                                            </ul>
                                                            {am?.amenityMasters?.length > 5 && (
                                                                <>
                                                                    <a href="#" onClick={(e) => handlePreviewAmenities(e, am?.amenityCategoryId)} className="see-more-modal">
                                                                        See more
                                                                    </a>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </OwlCarousel>
                                )}
                            </div>

                            <Tooltip id="my-tooltip" className="my-tooltip" />
                            <div className="amenities-section-btn">
                                <a
                                    href="#"
                                    onClick={handleShowAmenities}
                                    className="btn primary-btn btn-amenities"
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                    data-aos-delay="600"
                                    data-aos-once="true"
                                >
                                    Show all Amenities <span className="arrow"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <ClientPortal selector="myportal" show={showAmenities}>
                    <div className="overlay" onClick={handleShowAmenities}></div>
                    <div className="modal modal-amenities">
                        <div className="header mb-3">
                            <h4 className="mb-0">{t('Amenities.Amenities')}</h4>

                            <div className="search-btn-group w-25 mx-auto position-relative">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    aria-label="Search"
                                    aria-describedby="Search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    name="leadSmartSearch"
                                    className="form-control white search-onboarding"
                                />
                                <i className="fa-sharp fa-solid fa-magnifying-glass onboarding-search-icon"></i>
                            </div>
                            <a onClick={handleShowAmenities} className="removeButton">
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                    <path
                                        fill="#ffffff"
                                        d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                    />
                                </svg>
                            </a>
                        </div>

                        <div className="body">
                            <ul className="nav nav-pills amenities-pill mb-2 pb-2 border-bottom" id="pills-tab" role="tablist">
                                {filteredCategories?.map((am: any) => (
                                    <li key={am.amenityCategoryId} className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${selectedTab === am.amenityCategoryId ? 'active' : ''}`}
                                            id={`pills-${am.amenityCategoryName.toLowerCase()}-tab`}
                                            data-bs-toggle="pill"
                                            data-bs-target={`#pills-${am.amenityCategoryName.toLowerCase()}`}
                                            type="button"
                                            role="tab"
                                            aria-controls={`pills-${am.amenityCategoryName.toLowerCase()}`}
                                            aria-selected={selectedTab === am.amenityCategoryId}
                                            onClick={() => handleTabClick(am.amenityCategoryId)}
                                        >
                                            {am.amenityCategoryName}
                                            <div className="icon ms-2">
                                                <img style={{ width: '22px' }} src={am.amenityCategoryIcon} className="img-fluid" alt={am.amenityCategoryName} />
                                            </div>
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <div className="tab-content amenities-pill-content setup-ward-amenities-popup" id="pills-tabContent">
                                {filteredCategories &&
                                    filteredCategories.length > 0 &&
                                    filteredCategories.map((am: any) => {
                                        return (
                                            <div
                                                key={am.amenityCategoryId}
                                                className={`tab-pane fade ${selectedTab === am.amenityCategoryId ? 'show active' : ''}`}
                                                id={`pills-${am.amenityCategoryName.toLowerCase()}`}
                                                role="tabpanel"
                                                aria-labelledby={`pills-${am.amenityCategoryName.toLowerCase()}-tab`}
                                            >
                                                <AmenitiesTabContentContainer amenities={am.amenityMasters} selectedTab={selectedTab} />
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </ClientPortal>
            </div>
            <ClientPortal selector="myportal" show={showSetupWards}>
                <div className="overlay" onClick={handleShowSetupWards}></div>
                <div className={`modal modal-collage-image p-0 nhs-body`}>                    
                    <div>
                        <OnboardingHeaderContainer manageProfileValidationType='edit'/>
                    </div>
                    <div className="body service-detail-padding py-5">
                        <div className="container-fluid p-0">
                            {/* <SetupWardContainer ref={setupWardPage} previewAddWards={handleShowNameWards} key={refreshWards} /> */}
                            <SetupWardContainer ref={setupWardPage} />
                        </div>
                    </div>
                    <OnboardingPreviewEditModalFooter backOnClick={handleShowSetupWards} saveOnClick={handleSave} saveDisabled={false} saveType='submit'/>
                </div>
            </ClientPortal>
            {/* <ClientPortal selector="myportal" show={showNameWards}>
                <div className="overlay" onClick={handleShowNameWards}></div>
                <div className="modal modal-collage-image p-0 nhs-body">
                    <div className="header">
                        <a onClick={handleShowNameWards} className="d-flex align-items-center">
                            <i className="fa-solid fa-angle-left back-collage-btn"></i>
                            <h4 className="bolder mb-0 ms-2">Add Wards</h4>
                        </a>
                    </div>
                    <div className="body service-detail-padding">
                        <div className="container-fluid preview-service-fieldset-text float-button-sec-margin">
                            <NameWardsContainer ref={nameWardPage} />
                        </div>
                    </div>
                    <div className="modal-footer--sticky border-top pt-2 pb-2">
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="btn primary-btn px-5" onClick={handleSaveWards}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </ClientPortal> */}
            <Tooltip id="edit-ward-tooltip" />
        </div>
    );
};

export default OnboardingPreviewAmenitiesView;
