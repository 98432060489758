    import React from 'react';
import CreateServiceCardsView from './CreateServiceCardsView';
    
    const CreateServiceCardsContainer = () => {
        return (
            <div>
                <CreateServiceCardsView/>
            </div>
        );
    };
    
    export default CreateServiceCardsContainer;