import React, { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import SmartSearchDropdownView from './SmartSearchDropdownView';
//@ts-ignore
import { debounce } from 'lodash';
import { getCommonDropdownList } from '../../service/common-service';

const SmartSearchDropdownContainer = (props: any) => {
    const limit = 10;
    let squery = "";
    const [users, setUsers] = useState([]);
    const [ishasmore, setmore] = useState<boolean>(true);
    let paginationRef = useRef({
        skip: 0,
        total: 0,
        hasMore: true
    });

    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);


    const fetchGetUserApi = async (url: any, payload: any) => {
        setLoading(true);
        const response = await getCommonDropdownList(url, payload);
        setLoading(false);
        return {
            list: response.data?.result,
            pagination: {
                total: response.data?.total,
                skip: response.data?.skip + limit,
                limit: limit,
                hasMore: response.data?.skip + limit < response.data?.total
            }
        };
    };

    const getqueryvalue = (val: any) => {
        if (val === null) {
            setSearchQuery('')
        }
    }
    useEffect(() => {
        if (props.smartSearch !== undefined) {
            setUsers([]);
            (async () => {
                let payload: any = {};
                if (props?.smartSearch?.extraPayload !== undefined) {
                    payload = props?.smartSearch?.extraPayload;
                }
                payload = {
                    ...payload,
                    limit: limit,
                    skip: 0,
                    query: searchQuery
                };
                let { list, pagination: resPagination } = await fetchGetUserApi(props.smartSearch.url, payload);
                const users = list?.map((x: any) => ({
                    ...x,
                    label: x.label
                }));
                setUsers(users || []);
                paginationRef.current = resPagination;
                setmore(resPagination.hasMore);
            })();
        } else {
            setUsers([])
        }
    }, [searchQuery, props.smartSearch]);

    const loadmoredata = async (squery: any) => {
        if (props.smartSearch === undefined) return;
        if (!paginationRef.current.hasMore) return;
        setLoading(true);
        let payload: any = {};
        if (props?.smartSearch?.extraPayload !== undefined) {
            payload = props?.smartSearch?.extraPayload;
        }
        payload = {
            ...payload,
            limit: limit,
            skip: paginationRef.current.skip,
            query: squery
        };
        let { list, pagination: resPagination } = await fetchGetUserApi(props.smartSearch.url, payload);
        const users = list.map((x: any) => ({
            ...x,
            label: x.label
        }));
        //@ts-ignore
        setUsers((prev) => [...prev, ...users.filter((x) => !prev.map((u) => u.label).includes(x.label))]);
        paginationRef.current = resPagination;
        setmore(resPagination.hasMore);
        setLoading(false);
    }

    return (
        <SmartSearchDropdownView
            id={props?.id}
            disabled={props.disabled}
            onChangeSmartSearch={props.onChangeSmartSearch}
            options={users}
            defaultValue={props.defaultValue}
            hasMore={ishasmore}
            loading={loading}
            errors={props.errors}
            getqueryvalue={getqueryvalue}
            squery={searchQuery}
            onBlurSmartSearch={props?.onBlurSmartSearch}
            onFetchMore={(squery: any) => {
                loadmoredata(squery)
            }}
            sx={{ width: 300 }}
            nhs={props.nhs}
            valFromMap={props.valFromMap}
            renderInput={(params: any) => (
                <TextField
                    {...params}
                    label={props.smartSearchLabel}
                    onChange={debounce((e: any) => {
                        setSearchQuery(e.target.value);
                    }, 1000)}
                    onBlur={debounce((e: any) => {
                        setSearchQuery(e.target.value);
                    }, 1000)}

                />
            )}
        />
    );
};

export default SmartSearchDropdownContainer;
