import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

const LocateAdminTableManageColumnView = ({ onChangeManageColumnHandler, managedColumnsList }: any) => {
    const { t } = useTranslation();
    let [columns, setColumns] = useState<any>({
        GroupName: false,
        CompanyName: false,
        ProviderId: false,
        ProfileStatus: false,
        Completion: false,
        UpdatedBy: false
    });

    useEffect(() => {
        if (managedColumnsList?.length > 0) {
            managedColumnsList?.map((value: any) => {
                if (value.columnName === "ProviderId") {
                    setColumns((state: any) => ({
                        ...state,
                        ProviderId: true
                    }))
                }

                if (value.columnName === "ProfileStatus") {
                    setColumns((state: any) => ({
                        ...state,
                        ProfileStatus: true
                    }))
                }

                if (value.columnName === "Completion") {
                    setColumns((state: any) => ({
                        ...state,
                        Completion: true
                    }))
                }

                if (value.columnName === "UpdatedBy") {
                    setColumns((state: any) => ({
                        ...state,
                        UpdatedBy: true
                    }))
                }
                if (value.columnName === "GroupName") {
                    setColumns((state: any) => ({
                        ...state,
                        GroupName: true
                    }))
                }
                if (value.columnName === "CompanyName") {
                    setColumns((state: any) => ({
                        ...state,
                        CompanyName: true
                    }))
                }
            })
        }

    }, [managedColumnsList])


    const onChangeColumnHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, name } = event.target;
        const columnsMapping: any = {
            ProviderId: "ProviderId",
            ProfileStatus: "ProfileStatus",
            Completion: "Completion",
            UpdatedBy: "UpdatedBy",
            GroupName: "GroupName",
            CompanyName: "CompanyName"
        }
        if (columnsMapping[name]) {
            setColumns((state: any) => ({
                ...state,
                [name]: checked
            }))
        }
        const obj = {
            ColumnName: columnsMapping[name],
            IsActive: checked
        }

        onChangeManageColumnHandler(obj)
    }

    const eventHandler = (event: any) => {
        event.stopPropagation();
    };

    return (
        <div>
            <div className="dropdown filter-dropdown me-2">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="">
                        <i className="fa fa-cog"></i> {t('LocateAdminTableManageColumns.Manage_Columns')}
                    </div>
                </a>
                <div className="dropdown-menu dropdown-menu-columns" onClick={eventHandler} aria-labelledby="navbarDropdownMenuLink">
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <label>
                                    <input type="checkbox" name="GroupName" checked={columns.GroupName} className="me-1" onChange={(e) => onChangeColumnHandler(e)} /> {t('LocateAdminTableManageColumns.GroupName')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="CompanyName" checked={columns.CompanyName} className="me-1" onChange={(e) => onChangeColumnHandler(e)} /> {t('LocateAdminTableManageColumns.CompanyName')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="ProviderId" checked={columns.ProviderId} className="me-1" onChange={(e) => onChangeColumnHandler(e)} /> {t('LocateAdminTableManageColumns.CQC_Provider_Id')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="ProfileStatus" checked={columns.ProfileStatus} className="me-1" onChange={(e) => onChangeColumnHandler(e)} /> {t('LocateAdminTableManageColumns.Profile_Status')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="Completion" checked={columns.Completion} className="me-1" onChange={(e) => onChangeColumnHandler(e)} /> {t('LocateAdminTableManageColumns.Percentage_Completion')}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="checkbox" name="UpdatedBy" checked={columns.UpdatedBy} className="me-1" onChange={(e) => onChangeColumnHandler(e)} /> {t('LocateAdminTableManageColumns.Updated_By')}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LocateAdminTableManageColumnView