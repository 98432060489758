import React from 'react';
import ExportReportsView from './ExportReportsView';

const ExportReportsContainer = (props:any) => {
    return (
        <div>
            <ExportReportsView {...props}/>
        </div>
    );
};

export default ExportReportsContainer;