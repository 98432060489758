import React from 'react';

interface ModalProps {
    onClose: any;
    title: string;
    content: any;
    footer: any;
    className: any;
    extraButton?: any;
    isChildModal?: boolean;
}

const ModalWrapperView = (props: ModalProps) => {
    return (
        <div>
            <div className="overlay" style={props.isChildModal ? { opacity: "0.1" } : {}} onClick={props.onClose}></div>
            <div className={props.className}>
                <div className="modal-content">
                    <div className="modal-header">
                        <div className='d-flex align-items-center'>
                            <p className="modal-title">{props.title}</p>
                            {props.extraButton}
                        </div>
                        <a href="#" onClick={props.onClose} className='removeButton'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path
                                    fill="#ffffff"
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                />
                            </svg>
                        </a>
                    </div>
                    <div className="modal-body">{props.content}</div>
                    <div className="modal-footer justify-content-center">{props.footer}</div>
                </div>
            </div>
        </div>
    );
};

export default ModalWrapperView;
