import React, { useEffect, useRef, useState } from 'react';
import service_image from "../../../../../../assets/images/service-1.png";
import service_image1 from "../../../../../../assets/images/service1.png";
import service_image2 from "../../../../../../assets/images/service2.png";
import service_image3 from "../../../../../../assets/images/service3.png";
import service_image4 from "../../../../../../assets/images/service-1.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ServicesDetailsContainer from '../ServiceDetails/ServicesDetailsContainer';
import { GetProviderServiceSection } from '../../../../../../service/service-details-service';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const ServicesSliderView = (props: any) => {
    const [serviceDetails, SetServiceDetails] = useState<any>(null);
    const [showPortal, setShowPortal] = useState(false);
    const { t } = useTranslation();
    const params = useParams();
    const [stagePadding, setStagePadding] = useState(0);
    const handleModal = (event: any) => {
        event.preventDefault();
        setShowPortal((prevState) => !prevState);
    };
    useEffect(() => {
        if (showPortal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [showPortal]);
    const targetElementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const calculateSpace = () => {
            const targetElement = targetElementRef.current;
            if (targetElement) {
                const rect = targetElement.getBoundingClientRect();
                const spaceToLeft = rect.left;
                console.log("Space to the left of the target element:", spaceToLeft);
                return spaceToLeft;
            }
            return 0;
        };

        const calculatedPadding = calculateSpace();
        setStagePadding(calculatedPadding);
    }, []);
    useEffect(() => {
        GetProviderServiceDetailsList()
    }, [])

    const GetProviderServiceDetailsList = async () => {
        const result = await GetProviderServiceSection(params?.serviceSlug, "")
        SetServiceDetails(result?.data)
    }

    const imgArr = [service_image1, service_image2, service_image3, service_image];

    const handleReadMore = (incomingObj: { index: number; desc: string; }) => {
        const { index, desc } = incomingObj;
        const parentCarousel = document.getElementById("service-cards-carousel");
        const carouselItems = parentCarousel?.getElementsByClassName("service-card-item");

        if (carouselItems && carouselItems.length > 0) {
            const currentItem = carouselItems[index];
            const contentElem = currentItem?.getElementsByTagName("p");

            if (contentElem && contentElem.length > 0) {
                const item = contentElem.item(0);
                if (item) {
                    const isReadMoreActive = item.innerHTML.includes("Read More");
                    if (isReadMoreActive) {
                        item.innerHTML = `<p>${desc} <span class="bold read-less" style="cursor: pointer;">Read Less</span></p>`;

                        const readLessElem = currentItem?.getElementsByClassName("read-less").item(0);
                        if (readLessElem) {
                            readLessElem.addEventListener("click", () => {
                                handleReadLess({ index, desc });
                            });
                        }
                    }
                }
            }
        }
    };

    const handleReadLess = (incomingObj: { index: number; desc: string; }) => {
        const { index, desc } = incomingObj;

        const parentCarousel = document.getElementById("service-cards-carousel");
        const carouselItems = parentCarousel?.getElementsByClassName("service-card-item");

        if (carouselItems && carouselItems.length > 0) {
            const currentItem = carouselItems[index];
            const contentElem = currentItem?.getElementsByTagName("p");

            if (contentElem && contentElem.length > 0) {
                const item = contentElem.item(0);
                if (item) {
                    const isReadLessActive = item.innerHTML.includes("Read Less");
                    if (isReadLessActive) {
                        item.innerHTML = `<p>${desc.slice(0, 50)}... <span class="bold read-more" style="cursor: pointer;">Read More</span></p>`;

                        const readMoreElem = currentItem?.getElementsByClassName("read-more").item(0);
                        if (readMoreElem) {
                            readMoreElem.addEventListener("click", () => {
                                handleReadMore({ index, desc });
                            });
                        }
                    }
                }
            }
        }
    };

    return (
        <div>
            <div className="service-section-title">
                <div className="container-fluid container-fluid-service p-0">
                    <div className="row">
                        <div className="col-lg-12 p-0">
                            <div>
                                <h4 className="text-center section-title mb-3 bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-once="true">
                                    {t(serviceDetails?.serviceHeader)}
                                </h4>
                                <p className="section-sub-title text-center mb-4 bolder" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300" data-aos-once="true">
                                    {t("ServiceDetail.Services")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid service-slider" >
                <div className="row">
                    <div className="col-lg-12 p-0" id='caraousel-patent-component'>
                        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600" data-aos-once="true">
                            {props?.serviceDetails && props?.serviceDetails?.services &&
                                <OwlCarousel
                                    id="service-cards-carousel"
                                    className="owl-theme all-owl"
                                    dots={false}
                                    margin={20}
                                    mouseDrag={false}
                                    key={Math.random()}
                                    smartSpeed={800}
                                    touchDrag={false}
                                    //   autoWidth={true}
                                    // stagePadding={stagePadding}
                                    nav={true}
                                // responsive={{
                                //   "0": { items: 1 },
                                //   "768": { items: 2 },
                                //   "1200": { items: 3 },
                                // }}
                                >  {(props?.serviceDetails?.services && props?.serviceDetails?.services.length > 0) && props?.serviceDetails?.services.map((sd: any, index: any) => {
                                    return (
                                        <div key={index} className="service-image item service-card-item">
                                            <img
                                                src={imgArr[index % 4]}
                                                className="img-fluid"
                                                alt="list-image"
                                            />
                                            <span className="overlay-details"></span>
                                            <div className="services-details">
                                                <div className={index % 4 == 2 ? "service-content-black" : "service-content "}>
                                                    <h5>{sd?.serviceSectionName}</h5>
                                                    {sd?.description?.length > 50 ? (
                                                        <p>{sd?.description?.slice(0, 50) + "... "}
                                                            <span className="bold" style={{ cursor: "pointer" }} onClick={() => handleReadMore({ desc: sd?.description, index })}>Read More</span>
                                                        </p>
                                                    ) : (
                                                        <p>{sd?.description}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <ServicesDetailsContainer serviceDetails={sd} />
                                        </div>
                                    )
                                })}
                                </OwlCarousel>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesSliderView;