import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import ClientPortal from '../../../../components/ClientPortal';
import GalleryContainer from '../../Gallery/GalleryContainer';
import warning from '../../../../assets/images/warning.png';
import OnboardingPreviewEditModalFooter from '../OnboardingPreviewEditModalFooter';
import OnboardingHeaderContainer from '../../../../layout/OnboardingHeader/OnboardingHeaderContainer';

const EditGalleryModalView = (props: any) => {
    const editGalleryRef = useRef<any>();
    const { showEditModal, handleShowEditModal, getServiceImages, saveFn } = props;

    const handleSave = async () => {
        saveFn(async () => {
            if ((await editGalleryRef.current.triggerValidation()) === false) {
                return;
            }
            editGalleryRef.current.nextActionHandler!(true);
            setTimeout(async () => {
                handleShowEditModal();
                await getServiceImages();
            }, 2000);
        });
    };

    return (
        <ClientPortal selector="myportal" show={showEditModal}>
            <div className="overlay" onClick={handleShowEditModal}></div>
            <div className="modal modal-collage-image p-0 nhs-body">
                <div>
                    <OnboardingHeaderContainer manageProfileValidationType='edit'/>
                </div>
                <div className="body service-detail-padding py-5">
                    <div className='container-fluid'>
                    <GalleryContainer ref={editGalleryRef} />
                    </div>
                </div>
                <OnboardingPreviewEditModalFooter 
                    backOnClick={handleShowEditModal} 
                    saveOnClick={async (event: any) => {
                        event?.preventDefault();
                        event?.stopPropagation();
                        await handleSave();
                    }}
                    saveDisabled={false}
                    saveType="submit"
                />
            </div>
            <Tooltip id="my-tooltip" className="my-tooltip" />
        </ClientPortal>
    );
};

export default EditGalleryModalView;
