import React, { useRef, useEffect, useState, useMemo } from 'react';
import report1 from '../../assets/images/patient-transaction-history-report.svg';
import report2 from '../../assets/images/patient-detail-report.svg';
import report3 from '../../assets/images/report-current-bed-pricing.svg';
import report4 from '../../assets/images/report-bed-transaction.svg';
import report5 from '../../assets/images/report-active-admissions-with-expired-or-no-plans.svg';
import report6 from '../../assets/images/current-bed-occupancy-report.svg';
import report7 from '../../assets/images/admission-report.svg';
import report8 from '../../assets/images/discharge-report.svg';
import report9 from '../../assets/images/draft-patients-report.svg';
import report10 from '../../assets/images/reservation-report.svg';
import report11 from '../../assets/images/referral-report.svg';

const ReportsView = () => {
    return (
        <>
            <div className='container-fluid'>
                <div className='row mt-4 position-relative report-cards'>
                    <div className='col-xl-2 col-3 mb-4'>
                        <div className='reports-box'>
                            <a href="/PatientTransactionHistoryReportView">
                                <div className='report-box-inn'>
                                    <div className='report-icon mb-3'>
                                        <img src={report1} alt='report icon' className='img-fluid' />
                                    </div>
                                    <p>Patient Transaction History Report</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-xl-2 col-3 mb-4'>
                        <div className='reports-box'>
                            <a href='/PatientDetailsReportView'>
                                <div className='report-box-inn'>
                                    <div className='report-icon mb-3'>
                                        <img src={report2} alt='report icon' className='img-fluid' />
                                    </div>
                                    <p>Patient Detail Report</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-xl-2 col-3 mb-4'>
                        <div className='reports-box'>
                            <a href="/CurrentBedStatusWithPricingView">
                                <div className='report-box-inn'>
                                    <div className='report-icon mb-3'>
                                        <img src={report3} alt='report icon' className='img-fluid' />
                                    </div>
                                    <p>Current Bed Status with Pricing Report</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-xl-2 col-3 mb-4'>
                        <div className='reports-box'>
                            <a href="/BedTransactionHistoryReportsView">
                                <div className='report-box-inn'>
                                    <div className='report-icon mb-3'>
                                        <img src={report4} alt='report icon' className='img-fluid' />
                                    </div>
                                    <p>Bed Transaction History Report</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-xl-2 col-3 mb-4'>
                        <div className='reports-box'>
                            <a href='/ActiveAdmissionsView'>
                                <div className='report-box-inn'>
                                    <div className='report-icon mb-3'>
                                        <img src={report5} alt='report icon' className='img-fluid' />
                                    </div>
                                    <p>Active Admissions with Expired or No Plans</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-xl-2 col-3 mb-4'>
                        <div className='reports-box'>
                            <a href='/CurrentBedOccupancyReportView'>
                                <div className='report-box-inn'>
                                    <div className='report-icon mb-3'>
                                        <img src={report6} alt='report icon' className='img-fluid' />
                                    </div>
                                    <p>Current Bed Occupancy Report</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-xl-2 col-3 mb-4'>
                        <div className='reports-box'>
                            <a href='/AdmissionsReportView'>
                                <div className='report-box-inn'>
                                    <div className='report-icon mb-3'>
                                        <img src={report7} alt='report icon' className='img-fluid' />
                                    </div>
                                    <p>Admissions Report</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-xl-2 col-3 mb-4'>
                        <div className='reports-box'>
                            <a href='/DischargeReportView'>
                                <div className='report-box-inn'>
                                    <div className='report-icon mb-3'>
                                        <img src={report8} alt='report icon' className='img-fluid' />
                                    </div>
                                    <p>Discharge Report</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-xl-2 col-3 mb-4'>
                        <div className='reports-box'>
                            <a href='/DraftPatientsReportView'>
                                <div className='report-box-inn'>
                                    <div className='report-icon mb-3'>
                                        <img src={report9} alt='report icon' className='img-fluid' />
                                    </div>
                                    <p>Draft Patients Report</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-xl-2 col-3 mb-4'>
                        <div className='reports-box'>
                            <a href="/ReservationReportView">
                                <div className='report-box-inn'>
                                    <div className='report-icon mb-3'>
                                        <img src={report10} alt='report icon' className='img-fluid' />
                                    </div>
                                    <p>Reservation Report</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-xl-2 col-3 mb-4'>
                        <div className='reports-box'>
                            <a href='/ReferralReportView'>
                                <div className='report-box-inn'>
                                    <div className='report-icon mb-3'>
                                        <img src={report11} alt='report icon' className='img-fluid' />
                                    </div>
                                    <p>Referral Report</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReportsView;  